import instance, {getUrl, getToken, getLocale} from './instance';

export const createForm = async (payload) => {
     const { eventId, organizationId, ...values } = payload;

     try {
          return await instance.post(
               `${getUrl()}/form-builder/${organizationId}/${eventId}`,
               values,
               {
                    headers: {
                         Authorization:
                              `Bearer ${getToken()}` || 'Bearer token',
                    },
               }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const getEventForms = async (payload) => {
     try {
          if (payload.query) {
               return await instance.post(
                    `${getUrl()}/form-builder/${payload.organizationId}/${payload.eventId}/search`,
                    { query: payload.query },
                    {
                         headers: {
                              Authorization:
                                   `Bearer ${getToken()}` || 'Bearer token',
                         },
                         params: {
                              per_page: payload.per_page,
                              page: payload.page
                         },
                    }
               );
          }

          const response = await instance.get(
               `${getUrl()}/form-builder/${payload.organizationId}/${payload.eventId}`,
               {
                    headers: {
                         common: {
                              Authorization:
                                   `Bearer ${getToken()}` || 'Bearer token',
                         },
                    },
                    params: {
                         per_page: payload.per_page,
                         page: payload.page,
                    },
               }
          );

          return response;
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const searchForms = async (payload) => {
     const { eventId, organizationId, query } = payload;

     try {
          return await instance.post(
               `${getUrl()}/form-builder/${organizationId}/${eventId}/search`,
               { query },
               {
                    headers: {
                         Authorization:
                              `Bearer ${getToken()}` || 'Bearer token',
                    },
               }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const removeForm = async ({ eventId, organizationId, id }) => {
     try {
          const response = await instance.delete(
               `${getUrl()}/form-builder/${organizationId}/${eventId}/${id}`,
               {
                    headers: {
                         common: {
                              Authorization:
                                   `Bearer ${getToken()}` || 'Bearer token',
                         },
                    },
               }
          );

          return response;
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};
export const getFormFields = async (payload) => {
     const { id, locale } = payload;

     try {
          const response = await instance.get(
               `${getUrl()}/form-builder/${id}/fields`,
               {
                    headers: {
                         'A-Locale': `${getLocale(locale)}`,
                         common: {
                              Authorization: `Bearer ${getToken()}` || 'Bearer token'
                         },
                    },
               }
          );

          return response;
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const getFormDetails = async (payload) => {
     const { eventId, organizationId, id } = payload;

     try {
          return await instance.get(
               `${getUrl()}/form-builder/${organizationId}/${eventId}/${id}`,
               {
                    headers: {
                         Authorization:
                              `Bearer ${getToken()}` || 'Bearer token',
                    },
               }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const updateForm = async (payload) => {
     const { eventId, id, organizationId, ...rest } = payload;

     try {
          return await instance.post(
               `${getUrl()}/form-builder/${organizationId}/${eventId}/${id}`,
               rest,
               {
                    headers: {
                         Authorization:
                              `Bearer ${getToken()}` || 'Bearer token',
                    },
               }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};
