import styled from 'styled-components';
import { COLORS, FONT, SIZE } from '../../../constants/style.constants';

const LeftBlockWrapper = styled.div`
     width: 605px;
     background-color: ${COLORS.BLUE};
     padding: 45px 50px;
     display: flex;
     flex-direction: column;
     align-items: flex-start;
     justify-content: space-between;
     z-index: 1;

     .logo {
          margin-left: 2px;
     }

     .msg-header,
     .msg-about,
     .copyright {
          font-weight: ${FONT.REGULAR};
          color: ${COLORS.WHITE};
          margin-top: 20px;
          margin-bottom: 20px;
     }

     .msg-header {
          font-size: ${SIZE.GIGANTIC};
          line-height: 36px;
          margin-bottom: 40px;
     }

     .msg-about {
          font-size: ${SIZE.BIG};
          line-height: 22px;
     }

     .copyright,
     a {
          font-size: ${SIZE.SMALL};
          line-height: 17px;
          color: ${COLORS.LIGHTER_BLUE};
     }

     @media only screen and (max-width: 1366px) {
          display: none;
     }
`;

export default LeftBlockWrapper;
