import React from 'react';

export const DotImage = () => {
     return (
          <svg
               className="kt-svg-icon-sub"
               xmlns="http://www.w3.org/2000/svg"
               width="4"
               height="4"
               fill="none"
          >
               <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <circle
                         cx="2"
                         cy="2"
                         r="2"
                         fill="#000000"
                         opacity="0.3"
                    ></circle>
               </g>
          </svg>
     );
};
