import React from 'react';
import { useHover } from '../../hooks';

export default function FacebookIcon({ color, activeColor }) {
     const [hovered, handleHover] = useHover();

     return (
         <svg
             width="25"
             height="25"
             viewBox="0 0 25 25"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
             onMouseEnter={handleHover}
             onMouseLeave={handleHover}
         >
              <path
                  fill={hovered ? activeColor : color}
                  d="M3.66211 0C1.63958 0 0 1.63958 0 3.66211V21.3379C0 23.3604 1.63958 25 3.66211 25H21.3379C23.3604 25 25 23.3604 25 21.3379V3.66211C25 1.63958 23.3604 0 21.3379 0H3.66211ZM13.624 13.1257V19.6289H11.0174V13.1257H8.83789V10.5906H11.0174V8.72116C11.0174 6.55233 12.3368 5.37109 14.264 5.37109C15.187 5.37109 15.9799 5.44021 16.2109 5.47035V7.73788L14.8752 7.73844C13.8261 7.73844 13.624 8.23848 13.624 8.97224V10.5906H16.123L15.797 13.1257H13.624Z"
              />
         </svg>
     );
}
