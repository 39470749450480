import React, { memo, useState, useEffect, useRef } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

import CustomToggle from '../../../common/custom-toggle';
import ChevronDownIcon from '../../../../assets/icons/metronic/chevron-down-icon';
import {isEmpty} from "underscore";
import {DEFAULT_ELEMENT_LENGTH} from "../../../../utils/formBuilder.utils";
import {Autosugestion} from "../../../common/autosugestion";

export const FileUploadSettings = memo(
     ({ selectedElement, handleSettingChange, handleRemoveElement, getMasterValue, fieldsList }) => {
          const [state, setState] = useState();
          const ref = useRef();
          const { t } = useTranslation();

          useEffect(() => {
               setState(selectedElement);
          }, [selectedElement]);

          const DEFAULT_TYPE_OPTION = i18n.t(
               'form_builder.select_element_type'
          );

          const [innerState, setInnerState] = useState({
               fileTypeOptions: [
                    { value: DEFAULT_TYPE_OPTION },
                    { value: i18n.t('form_builder.presentation') },
               ],
               currentTypeOption: DEFAULT_TYPE_OPTION,
          });

          const handleElementTypeChange = (value) => {
               if (value === DEFAULT_TYPE_OPTION) {
                    value = '';
               }

               setState({ ...state, elementType: value });
               handleSettingChange({
                    id: state.id,
                    name: 'elementType',
                    value,
               });
          };

          const handleChange = (name, { target: { value } }) => {
               setState({ ...state, [name]: value });
               handleSettingChange({
                    id: state.id,
                    name,
                    value,
               });
          };

          const handleCheckboxChange = ({ target: { checked } }) => {
               setState({ ...state, required: checked });
               handleSettingChange({
                    id: state.id,
                    name: 'required',
                    value: checked,
               });
          };

          const handleRemoveItself = () => {
               setState(selectedElement);
               handleRemoveElement(state.id);
          };

          return (
               <>
                    <div className="mb-20">
                         <label>{t('form_builder.element_label')}</label>
                         <input
                              ref={ref}
                              className={`form-control ${state && ((!state.is_valid && isEmpty(state.label)) || (!state.is_translated && state.missing_translations.includes('label'))) ? 'is-warning' : ''}`}
                              type="text"
                              field="label"
                              value={state && state.label}
                              placeholder={state && getMasterValue(state.internal_id, 'label', null, t(
                                  'form_builder.setting_label_placeholder'
                              ))}
                              onChange={(e) => handleChange('label', e)}
                              maxLength={DEFAULT_ELEMENT_LENGTH}
                         />
                    </div>
                   {state && state.is_master && (
                       <>
                           <div className="mb-20">
                               <label>{t('form_builder.element_code')}</label>
                               <Autosugestion
                                   field="elementCode"
                                   handleChange={(value) => handleChange('elementCode', { target: { value } })}
                                   maxLength={DEFAULT_ELEMENT_LENGTH}
                                   placeholder={t('form_builder.setting_element_code_placeholder')}
                                   value={state && state.elementCode}
                                   suggestions={fieldsList}
                                   className={`form-control ${!state.is_valid && isEmpty(state.elementCode) ? 'is-warning' : ''} ${!state.is_valid_code && !isEmpty(selectedElement.elementCode) ? 'is-invalid' : ''}`}
                               />
                               {!state.is_valid_code && !isEmpty(selectedElement.elementCode) && (
                                   <div className="invalid-feedback" style={{display: 'block'}}>{t('validations.element_code_unique')}</div>
                               )}
                           </div>
                           <div className="mb-20">
                               <label>{t('form_builder.element_type')}</label>
                               <div className="input-group">
                                   <Dropdown>
                                       <Dropdown.Toggle
                                           className="select-dropdown"
                                           as={CustomToggle}
                                       >
                                           {(state && state.elementType) ||
                                           innerState.currentTypeOption}
                                           <span className="suffix">
                                             <ChevronDownIcon />
                                        </span>
                                       </Dropdown.Toggle>
                                       <Dropdown.Menu>
                                           {innerState.fileTypeOptions.map(
                                               ({ value }) => (
                                                   <Dropdown.Item
                                                       key={value}
                                                       onClick={() => {
                                                           handleElementTypeChange(
                                                               value
                                                           );
                                                           setInnerState({
                                                               ...innerState,
                                                               currentTypeOption: value,
                                                           });
                                                       }}
                                                   >
                                                       {value}
                                                   </Dropdown.Item>
                                               )
                                           )}
                                       </Dropdown.Menu>
                                   </Dropdown>
                                   <div className="info-block">
                                       {t('form_builder.optional')}
                                   </div>
                               </div>
                           </div>
                       </>
                   )}
                    <div className="mb-20">
                         <label>
                              {t('form_builder.additional_information')}
                         </label>
                         <input
                              ref={ref}
                              className={`form-control ${state && !state.is_translated && state.missing_translations.includes('additionalInformation') ? 'is-warning' : ''}`}
                              type="text"
                              field="additionalInformation"
                              value={state && state.additionalInformation}
                              placeholder={state && getMasterValue(state.internal_id, 'additionalInformation', null, t(
                                  'form_builder.setting_additional_information_placeholder'
                              ))}
                              onChange={(e) =>
                                   handleChange('additionalInformation', e)
                              }
                         />
                         <div className="info-block">
                              {t('form_builder.optional')}
                         </div>
                    </div>
                   {state && state.is_master && (
                       <>
                           <label className="checkbox-solid-blue">
                               <input
                                   type="checkbox"
                                   checked={state && state.required}
                                   onChange={handleCheckboxChange}
                               />
                               <span />
                               <div className="label">
                                   {t('form_builder.required')}
                               </div>
                           </label>
                           <hr className="setting-divider" />
                           <Button variant="danger" onClick={handleRemoveItself}>
                               {t('form_builder.delete_element')}
                           </Button>
                       </>
                   )}
               </>
          );
     }
);
