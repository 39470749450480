import React, { memo, useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {DEFAULT_ELEMENT_LENGTH} from "../../../../utils/formBuilder.utils";

export const DividerSettings = memo(
     ({ selectedElement, handleSettingChange, handleRemoveElement, getMasterValue }) => {
          const { t } = useTranslation();

          const [state, setState] = useState();
          const ref = useRef();

          useEffect(() => {
               setState(selectedElement);
          }, [selectedElement]);

          const handleChange = (name, { target: { value } }) => {
               setState({ ...state, [name]: value });
               handleSettingChange({
                    id: state.id,
                    name,
                    value,
               });
          };

          const handleCheckboxChange = ({ target: { checked } }) => {
               setState({ ...state, invert: checked });
               handleSettingChange({
                    id: state.id,
                    name: 'invert',
                    value: checked,
               });
          };

          const handleRemoveItself = () => {
               setState(selectedElement);
               handleRemoveElement(state.id);
          };

          return (
               <>
                    <div className="mb-20">
                         <label>{t('form_builder.elements.title')}</label>
                         <input
                              ref={ref}
                              className={`form-control ${state && !state.is_translated && state.missing_translations.includes('title') ? 'is-warning' : ''}`}
                              type="text"
                              field="title"
                              value={state && state.title}
                              placeholder={state && getMasterValue(state.internal_id, 'title', null, t(
                                  'form_builder.title_placeholder'
                              ))}
                              onChange={(e) => handleChange('title', e)}
                              maxLength={DEFAULT_ELEMENT_LENGTH}
                         />
                         <div className="info-block">
                              {t('form_builder.optional')}
                         </div>
                    </div>
                    <div className="mb-20">
                         <label>{t('form_builder.text')}</label>
                         <textarea
                              ref={ref}
                              rows="5"
                              className={`form-control ${state && !state.is_translated && state.missing_translations.includes('text') ? 'is-warning' : ''}`}
                              type="text"
                              field="text"
                              value={state && state.text}
                              placeholder={state && getMasterValue(state.internal_id, 'text', null, t(
                                  'form_builder.text_placeholder'
                              ))}
                              onChange={(e) => handleChange('text', e)}
                         />
                         <div className="info-block">
                              {t('form_builder.optional')}
                         </div>
                    </div>
                   {state && state.is_master && (
                       <>
                           <label className="checkbox-solid-blue">
                               <input
                                   type="checkbox"
                                   checked={state && state.invert}
                                   onChange={handleCheckboxChange}
                               />
                               <span />
                               <div className="label">{t('form_builder.invert')}</div>
                           </label>
                           <hr className="setting-divider" />
                           <Button variant="danger" onClick={handleRemoveItself}>
                               {t('form_builder.delete_element')}
                           </Button>
                       </>
                   )}
               </>
          );
     }
);
