import React, { memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useLocation } from 'react-router-dom';
import { requestUserAuthorized } from '../../redux/reducers/userReducer';
import { LeftBlock } from './LeftBlock';
import { RightBlock } from './RightBlock';

import Header from '../main/templates/Header';
import Subfooter from '..//main/templates/Footer/SubFooter';
import FooterWrapper from '../main/templates/Footer/Footer.style';
import AuthWrapper from './auth-styles/AuthPage.style';

export const AuthPage = memo(() => {
     const dispatch = useDispatch();
     const authorized = useSelector((state) => state.userReducer.authorized);
     const { pathname } = useLocation();

     useEffect(() => {
          if (pathname === '/auth/login') {
               dispatch(requestUserAuthorized());
          }
     }, [pathname]);

     const isAuthReady = useMemo(() => {
          if (pathname === '/auth/login') {
               return authorized !== null;
          }

          return true;
     }, [pathname, authorized]);

     return (
          isAuthReady && (
               <Switch>
                    <Route path="/auth">
                         <Header />
                         <AuthWrapper>
                              <LeftBlock />
                              <RightBlock alreadyLoggedIn={authorized} />
                         </AuthWrapper>
                         <FooterWrapper>
                              <Subfooter />
                         </FooterWrapper>
                    </Route>
                    <Route path="/registration">
                         <AuthWrapper>
                              <LeftBlock />
                              <RightBlock alreadyLoggedIn={authorized} />
                         </AuthWrapper>
                         <FooterWrapper>
                              <Subfooter />
                         </FooterWrapper>
                    </Route>
               </Switch>
          )
     );
});
