import instance, {getToken, getUrl} from './instance';

export const getEventEmailTemplates = async (payload) => {
     try {
          return await instance.get(
              `${getUrl()}/email-template/${payload}`,
              {
                   headers: {
                        common: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                   },
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const createEmailTemplate = async (payload) => {
     const { eventId, ...rest } = payload;

     try {
          return await instance.post(
               `${getUrl()}/email-template/${eventId}`,
               { ...rest },
               {
                    headers: {
                         Authorization:
                              `Bearer ${getToken()}` || 'Bearer token',
                    },
               }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const removeEmailTemplate = async (payload) => {
     const { eventId, emailTemplateId } = payload;

     try {
          return await instance.delete(
               `${getUrl()}/email-template/${eventId}/${emailTemplateId}`,
               {
                    headers: {
                         Authorization:
                              `Bearer ${getToken()}` || 'Bearer token',
                    },
               }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const uploadFile = async (payload) => {
     try {
          const { files } = payload;

          const promises = [];

          files.forEach((file) => {
               const formData = new FormData();
               formData.set('file', file);

               promises.push(
                   instance.post(
                       `${getUrl()}/media/email-template`,
                       formData,
                       {
                            headers: {
                                 Authorization:
                                     `Bearer ${getToken()}` || 'Bearer token',
                                 'Content-Type': 'multipart/form-data',
                            },
                       }
                   )
               );
          });

          const values = await Promise.all(promises);

          return values.map(({ status, data }) => ({ status, data }));
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const getFilesData = async (payload) => {
     try {
          const { files } = payload;

          return await instance.post(
              `${getUrl()}/media/email-template/multiple`,
              {files},
              {
                   headers: {
                        common: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                   },
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

// export const uploadMulitpleFiles = async (payload) => {
//      try {
//           return await instance.post(
//                `${process.env.REACT_APP_STAGE_API_URL}/media/email-template/multiple`,
//                payload,
//                {
//                     headers: {
//                          Authorization:
//                               `Bearer ${getToken()}` || 'Bearer token',
//                     },
//                }
//           );
//      } catch (error) {
//           if (error.response) {
//                return error.response.data;
//           }

//           throw Error(error.message);
//      }
// };
