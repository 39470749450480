export const getRandomId = () => Math.floor(Math.random(9) * 1000000);

export const constructCheckboxObject = (str) => ({
     id: getRandomId(),
     name: str,
     type: str,
     label: '',
     preChecked: false,
});

export const constructDropdownObject = (str) => ({
     id: getRandomId(),
     name: str,
     type: str,
     value: '',
     default: false,
});

export const capitalize = ({ value, split }) => {
     let s = value.split(split);
     let [first, ...rest] = s;
     first = first.toUpperCase();

     return [first, ...rest].join('');
};

export const transformOption = (option) => {
     if (!option) return '';

     let [first, ...rest] = option.split('_');
     let [f, ...r] = first;
     f = f.toUpperCase();
     first = [f, ...r].join('');

     return [first, ...rest].join(' ');
};

export const toSnakeCase = (str) =>
     str
          .split(/(?=[A-Z])/)
          .map((w) => w.toLowerCase())
          .join('_');

// export const checkValuesLengths = (arr) => {
//      let test = arr.map((l) => {
//           return { lang: l.lang, length: Object.keys(l).length };
//      });

//      if (test[0].length === test[1].length) {
//           return null;
//      }

//      if (test[0].length < test[1].length) {
//           return { lang: 1, error: true };
//      }

//      if (test[0].length > test[1].length) {
//           return { lang: 2, error: true };
//      }
// };

export const checkValueIsNonEmpty = (arr = []) => {
     return arr.map((l) => {
          const error = Object.values(l).some((v) => v === '');
          return { lang: l.lang, error };
     });
};

// export const checkKeysAreEqual = (arr) => {
//      return arr.reduce((en, lt) => {
//           const { lang: eLang, ...e } = en;
//           const { lang: lLang, ...l } = lt;

//           let first = Object.keys(e).join('');
//           let second = Object.keys(l).join('');

//           if (first !== second) {
//                return [
//                     { lang: 1, error: true },
//                     { lang: 2, error: true },
//                ];
//           }

//           return false;
//      });
// };

export const collect = (arr) => {
     if (!arr || !arr.length) return;

     return arr
          .map((i, idx) => {
               if (idx === 0) {
                    return arr.slice(idx, idx + 2);
               }

               return arr.slice(idx + idx, idx + idx + 2);
          })
          .filter((i) => !!i.length);
};

// const hasError =
//      checkValuesLengths(data) ||
//      checkKeysAreEqual(data) ||
//      checkValueIsNonEmpty(data);
