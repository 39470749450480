import React, {
     memo,
     lazy,
     Suspense,
     useEffect,
     useRef,
} from 'react';
import { connect } from 'react-redux';
import { Switch, Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { Helmet } from 'react-helmet';
import Breadcrumb from '../../common/breadcrumb';
import LoadingBar from '../../common/loading-bar';
import SideBar from '../../common/sidebar';
import { Drawer } from '../../common/drawer/MainDrawer';
import { ListItem } from '../../common/collapsible-list';
import { useOrganizationPhotos } from '../../../hooks';
import { AdminRoute } from '../../../utils/router.utils';

import { OrganizationSettingsForm } from './sub-components/OrganizationSettingsForm';
import BriefcaseIcon from '../../../assets/icons/metronic/brief-case-icon';
import MailIcon from '../../../assets/icons/metronic/mail-icon';
import SpeakersIcon from '../../../assets/icons/metronic/speakers-icon';
import { SettingsImage } from '../../../assets/icons/metronic/settings-image';
import { DotImage } from '../../../assets/icons/metronic/dot-image';
import { GroupChatImage } from '../../../assets/icons/metronic/group-chat-image';
import { OrganizationDropdown } from './sub-components/OrganizationDropdown';
import { requestOrganizationDetails } from '../../../redux/reducers/organizationReducer';
import { requestOrganizationEvents } from '../../../redux/reducers/eventReducer';

import OrganizationWrapper from './Organization.style';

const Portlet = lazy(() => import('../../common/portlet'));
const SidebarToggler = lazy(() => import('../../common/sidebar-toggler'));
const MainDrawer = lazy(() => import('../../common/drawer'));
const CollapsibleList = lazy(() => import('../../common/collapsible-list'));
const TeamMembers = lazy(() => import('./sub-components/TeamMembers'));
const EmailSendersTable = lazy(() => import('./sub-components/EmailSendersTable'));

const mapStateToProps = (state) => ({
     eventsList: state.eventReducer.eventsList,
     pageDetails: state.eventReducer.organizationEventsPageDetails,
     organization: state.organizationReducer.organization,
     organizationList: state.organizationReducer.organizationList,
     organizationDetailsUpdateRequested: state.organizationReducer.organizationDetailsUpdateRequested,
     organizationDetailsUpdateSucceeded: state.organizationReducer.organizationDetailsUpdateSucceeded,
     message: state.organizationReducer.message,
     uploadSucceeded: state.organizationReducer.organizationPhotoUploadSucceeded,
     removeAvatarSucceeded: state.organizationReducer.removeOrganizationAvatarSucceeded,
     refreshOrganizationAvatar: state.organizationReducer.refreshOrganizationAvatar,
     drawer: state.ui,
});

const mapDispatchToProps = (dispatch) => ({
     requestOrganizationDetails: (id) =>
          dispatch(requestOrganizationDetails(id)),
     requestOrganizationEvents: (id) => dispatch(requestOrganizationEvents(id)),
});

export const _OrganizationSettings = memo((props) => {
     const { t } = useTranslation();

     const {
          pageDetails,
          requestOrganizationEvents,
          requestOrganizationDetails,
          organization,
          organizationList,
          removeAvatarSucceeded,
          refreshOrganizationAvatar,
          drawer: { drawerShown },
     } = props;

     const { id: organizationId } = useParams();
     const photosRef = useRef();

     const isCurrentUserAdmin = organization && organization.member_scope === 'admin';

     useEffect(() => {
          requestOrganizationDetails(organizationId);
          requestOrganizationEvents({ id: organizationId });
     }, []);

     const links = [
          {
               to: `/organization/${organizationId}/settings`,
               text: `${organization && organization.name}`,
          },
          {
               to: `/organization/${organizationId}/settings`,
               text: i18n.t('organization_settings.organization_settings'),
          },
     ];

     const sidebarLinks = [
          {
               to: `/organization/${organizationId}/settings`,
               icon: <BriefcaseIcon />,
               text: i18n.t('organization_settings.organization_settings'),
          },
          {
               to: `/organization/${organizationId}/members`,
               icon: <SpeakersIcon />,
               text: i18n.t('organization_settings.team_members'),
          },
          {
               to: `/organization/${organizationId}/email-senders`,
               icon: <MailIcon />,
               text: i18n.t('organization_settings.email_senders'),
          },
     ];

     const { photos } = useOrganizationPhotos({
          organizationList,
          organizationId,
          organization,
          dependencies: [refreshOrganizationAvatar, removeAvatarSucceeded],
     });

     const handleSetOrganizationPhoto = (photo) => {
               photosRef.current = photo;
          };

     if (!photos) return <></>;

     return (
          <>
               <Helmet>
                    <title>
                         {t('organization_settings.organization_settings')} |{' '}
                         {t('avovent')}
                    </title>
               </Helmet>
               <MainDrawer>
                    <ListItem to="/dashboard">
                         {t('breadcrumb.dashboard')}
                    </ListItem>
                    <CollapsibleList
                         listHeader={organization && organization.name}
                         expanded={
                              window.location.pathname ===
                              `/organization/${organizationId}/settings`
                         }
                         listContent={
                              <>
                                   <ListItem
                                        to={`/organization/${organizationId}/events`}
                                   >
                                        <div className="collapsible-list-item">
                                             <GroupChatImage />
                                             <div className="sub-item">
                                                  {t('events')}
                                             </div>
                                        </div>
                                   </ListItem>
                                   <CollapsibleList
                                        listHeader={
                                             <div className="collapsible-list-item">
                                                  <SettingsImage />
                                                  <div className="sub-item">
                                                       {t(
                                                            'organization_settings.organization_settings'
                                                       )}
                                                  </div>
                                             </div>
                                        }
                                        expanded={
                                             window.location.pathname ===
                                             `/organization/${organizationId}/settings`
                                        }
                                        listContent={
                                             <>
                                                  <ListItem
                                                       subItem
                                                       to={`/organization/${organizationId}/settings`}
                                                  >
                                                       <div className="collapsible-list-item">
                                                            <DotImage />
                                                            <div className="sub-item">
                                                                 {t(
                                                                      'organization_settings.organization_settings'
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </ListItem>
                                                  <ListItem
                                                       subItem
                                                       to={`/organization/${organizationId}/members`}
                                                  >
                                                       <div className="collapsible-list-item">
                                                            <DotImage />
                                                            <div className="sub-item">
                                                                 {t(
                                                                      'organization_settings.team_members'
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </ListItem>
                                                  <ListItem
                                                       subItem
                                                       to={`/organization/${organizationId}/email-senders`}
                                                  >
                                                       <div className="collapsible-list-item">
                                                            <DotImage />
                                                            <div className="sub-item">
                                                                 {t(
                                                                      'organization_settings.email_senders'
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </ListItem>
                                             </>
                                        }
                                   />
                              </>
                         }
                    />
               </MainDrawer>
               {drawerShown && (
                    <Drawer>
                         <div style={{ padding: '35px 15px 15px 15px' }}>
                              <SideBar
                                   style={{
                                        boxShadow:
                                             '0px 0px 30px 0px rgba(82, 63, 105, 0.1)',
                                   }}
                                   picture={
                                        <img
                                             src={
                                                  photosRef.current ||
                                                  photos['180x180']
                                             }
                                             alt={t(
                                                  'organization_settings.alts.organization_avatar'
                                             )}
                                             style={{
                                                  height: '90px',
                                                  width: '90px',
                                             }}
                                        />
                                   }
                                   title={organization && organization.name}
                                   subtitle={`${
                                        organization && organization.members
                                   } People, ${
                                        pageDetails.total || '0'
                                   } Events`}
                                   links={sidebarLinks}
                              />
                         </div>
                    </Drawer>
               )}
               <div className="content--border">
                    <div
                         className="container-standard"
                         style={{ display: 'flex', alignItems: 'center' }}
                    >
                         <div className="nav">
                              <Link to="/dashboard" className="inactive">
                                   {t('breadcrumb.dashboard')}
                              </Link>
                              <OrganizationDropdown organization={organization}/>
                         </div>
                    </div>
                    <LoadingBar finish={!!organization} />
               </div>
               <div
                    className="container-standard"
                    style={{ display: 'flex', alignItems: 'center' }}
               >
                    <SidebarToggler />
                    {organization && (
                         <Breadcrumb
                              title="Organization settings"
                              links={links}
                         />
                    )}
               </div>
               <OrganizationWrapper>
                    <div className="container-standard">
                         <div className="app-grid">
                              <div className="not-sr">
                                   <SideBar
                                        picture={
                                             <img
                                                  src={
                                                       photosRef.current ||
                                                       photos['180x180']
                                                  }
                                                  alt={t(
                                                       'organization_settings.alts.organization_avatar'
                                                  )}
                                                  style={{
                                                       height: '90px',
                                                       width: '90px',
                                                  }}
                                             />
                                        }
                                        title={
                                             organization && organization.name
                                        }
                                        subtitle={`${
                                             organization &&
                                             organization.members
                                        } People, ${
                                             pageDetails.total || '0'
                                        } ${pageDetails.total === 1 ? (t('common.event')) : (t('common.events'))}`}
                                        links={sidebarLinks}
                                   />
                              </div>
                              <Switch>
                                   <AdminRoute
                                        isAdmin={isCurrentUserAdmin}
                                        path="/organization/:id/settings"
                                   >
                                        <Portlet
                                             title={
                                                  organization &&
                                                  organization.name
                                             }
                                             content={
                                                  <OrganizationSettingsForm
                                                       organization={
                                                            organization
                                                       }
                                                       handleSetOrganizationPhoto={
                                                            handleSetOrganizationPhoto
                                                       }
                                                  />
                                             }
                                        />
                                   </AdminRoute>
                                   <AdminRoute
                                       isAdmin={isCurrentUserAdmin}
                                       path="/organization/:id/email-senders/verify/:token"
                                   >
                                        <Suspense fallback={<></>}>
                                             <Portlet
                                                 title={t(
                                                     'organization_settings.email_senders'
                                                 )}
                                                 content={
                                                      <EmailSendersTable />
                                                 }
                                             />
                                        </Suspense>
                                   </AdminRoute>
                                   <AdminRoute
                                        isAdmin={isCurrentUserAdmin}
                                        path="/organization/:id/email-senders"
                                   >
                                        <Suspense fallback={<></>}>
                                             <Portlet
                                                  title={t(
                                                       'organization_settings.email_senders'
                                                  )}
                                                  content={
                                                       <EmailSendersTable />
                                                  }
                                             />
                                        </Suspense>
                                   </AdminRoute>
                                   <AdminRoute
                                        isAdmin={isCurrentUserAdmin}
                                        path="/organization/:id/members"
                                   >
                                        <Suspense fallback={<></>}>
                                             <Portlet
                                                  title={t(
                                                       'organization_settings.team_members'
                                                  )}
                                                  content={<TeamMembers />}
                                             />
                                        </Suspense>
                                   </AdminRoute>
                              </Switch>
                         </div>
                    </div>
               </OrganizationWrapper>
          </>
     );
});

export const OrganizationSettings = connect(
     mapStateToProps,
     mapDispatchToProps
)(_OrganizationSettings);
