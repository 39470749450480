import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom';
import {Alert, Button, Card, Row} from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import DashboardWrapper from './Dashboard.style';
import Breadcrumb from '../../common/breadcrumb';
import LoadingBar from '../../common/loading-bar';
import MainDrawer from '../../common/drawer';
import { ListItem } from '../../common/collapsible-list';
import { requestUserOrganization } from '../../../redux/reducers/organizationReducer';
import { requestSpeakerEvents } from '../../../redux/reducers/speakerReducer';
import { formatDate } from '../../../utils/date.utils';
import { OrganizationPhoto } from '../../common/avatar/OrganizationPhoto';

const mapStateToProps = (state, ownProps) => ({
     ...ownProps,
     organizationList: state.organizationReducer.organizationList,
     eventsList: state.eventReducer.eventsList,
     speakerEventsData: state.speakerReducer.speakerEventsData,
});

const mapDispatchToProps = (dispatch) => ({
     requestUserOrganization: () => dispatch(requestUserOrganization()),
     requestSpeakerEvents: () => dispatch(requestSpeakerEvents()),
});

const _OrganizationComponent = ({
     organization,
     organizationPhoto
}) => {
     const { t } = useTranslation();

     return (
          <div className="col-md-6 col-lg-4 col-xl-3">
               <Card>
                    <Card.Body className="text-center text-lg-left">
                         <div className="info-block text-left">
                              <OrganizationPhoto src={organizationPhoto ? organizationPhoto['180x180'] : null} showLoader={true} />
                              <div className="text-block text-left">
                                   <Card.Title>{organization.name}</Card.Title>
                                   <Card.Subtitle>
                                        {organization.members} <Trans i18nKey='common.person' count={organization.members} />
                                        {organization.member_events_count > 0 ? [(`, ${organization.member_events_count} `), <Trans i18nKey='common.event' count={organization.member_events_count} />] :  ''}
                                   </Card.Subtitle>
                              </div>
                         </div>
                         <Link to={`/organization/${organization.id}/events`}>
                              <Button variant="outline-primary">
                                   {t('dashboard.button')}
                              </Button>
                         </Link>
                    </Card.Body>
               </Card>
          </div>
     );
};

const OrganizationComponent = connect(
     mapStateToProps,
     mapDispatchToProps
)(_OrganizationComponent);

export const _Dashboard = memo((props) => {
     const {
          requestUserOrganization,
          organizationList,
          eventsList,
          speakerEventsData,
          requestSpeakerEvents,
     } = props;
     const { id, eventId } = useParams();

     const { t } = useTranslation();

     const links = [{ to: '/dashboard', text: t('breadcrumb.dashboard') }];

     useEffect(() => {
          requestSpeakerEvents();
          requestUserOrganization();
     }, []);

     return (
          <DashboardWrapper>
               <Helmet>
                    <title>
                         {t('breadcrumb.dashboard')} | {t('avovent')}
                    </title>
               </Helmet>
               <MainDrawer>
                    <ListItem to="/dashboard">
                         {t('breadcrumb.dashboard')}
                    </ListItem>
               </MainDrawer>
               <div className="content--border">
                    <div className="container-standard">
                         <Link to="/dashboard">
                              <Button className="btn-themed-blue btn-navigational">
                                   {t('breadcrumb.dashboard')}
                              </Button>
                         </Link>
                    </div>
                    <LoadingBar finish={!!organizationList} />
               </div>
               <div className="container-standard">
                    <Breadcrumb
                         title={t('breadcrumb.dashboard')}
                         links={links}
                    />
               </div>

               {
                    organizationList &&
                    speakerEventsData &&
                    organizationList.length === 0
                    && speakerEventsData.data.length === 0
                    && (
                        <div className="container-standard">
                             <Row>
                                  <Alert
                                      key={'no-speakers-and-organizations'}
                                      variant={'outline-primary'}
                                  >
                                       {
                                            t('dashboard.no_speakers_and_organizations')
                                       }
                                  </Alert>
                             </Row>
                        </div>
                    )
               }

               {organizationList && organizationList.length > 0 && (
                    <div className="organization-block">
                         <div className="container-standard">
                              <h3>{t('dashboard.organization')}</h3>
                              <div className="dashboard-items">
                                   <div className="row">
                                        {organizationList.map((organization) => {
                                             const { photos } = organization;

                                             return (
                                                  <OrganizationComponent
                                                       key={organization.id}
                                                       organization={organization}
                                                       organizationPhoto={photos}
                                                  />
                                             );
                                        })}
                                   </div>
                              </div>
                         </div>
                    </div>
               )}

               {speakerEventsData && speakerEventsData.data.length > 0 && (
                    <div className="speaker-block">
                         <div className="container-standard">
                              <h3>{t('dashboard.speaker')}</h3>
                              <div className="dashboard-items">
                                   <div className="row">
                                   {speakerEventsData.data.map((item) => (
                                        <div
                                             key={item.event.id}
                                             className="col-md-6 col-lg-4 col-xl-3"
                                        >
                                             <Card className={item.status === 'incomplete' ? 'incomplete': ''}>
                                                  <Card.Body className="text-center text-lg-left">
                                                       <div className="info-block text-left">
                                                            <img
                                                                 src={
                                                                      item.event
                                                                           .photos[
                                                                           '180x180'
                                                                      ]
                                                                 }
                                                                 alt={t(
                                                                      'dashboard.alts.event_logo'
                                                                 )}
                                                                 style={{
                                                                      height:
                                                                           '90px',
                                                                      width:
                                                                           '90px',
                                                                 }}
                                                            />
                                                            <div className="text-block">
                                                                 <Card.Title>
                                                                      {
                                                                           item
                                                                                .event
                                                                                .name
                                                                      }
                                                                 </Card.Title>
                                                                 <Card.Subtitle>
                                                                      {formatDate(
                                                                           {
                                                                                startDate:
                                                                                     item
                                                                                          .event
                                                                                          .start,
                                                                                endDate:
                                                                                     item
                                                                                          .event
                                                                                          .end,
                                                                           }
                                                                      )}
                                                                 </Card.Subtitle>
                                                            </div>
                                                       </div>
                                                       <Link
                                                            to={`/events/${item.event_id}/speaker-form-loggedin/${item.id}`}
                                                       >
                                                            <Button variant="outline-primary">
                                                                 {t(
                                                                      'dashboard.button'
                                                                 )}
                                                            </Button>
                                                       </Link>
                                                  </Card.Body>
                                             </Card>
                                        </div>
                                   ))}
                                   </div>
                              </div>
                         </div>
                    </div>
               )}
          </DashboardWrapper>
     );
});

export const Dashboard = connect(
     mapStateToProps,
     mapDispatchToProps
)(_Dashboard);
