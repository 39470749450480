import * as ACTIONS from '../actions';

export const eventClearMessages = () => ({
     type: ACTIONS.EVENT_CLEAR_MESSAGES,
});

export const requestOrganizationEvents = (payload) => ({
     type: ACTIONS.ORGANIZATION_EVENTS_REQUEST,
     payload,
});

export const successOrganizationEvents = (payload) => ({
     type: ACTIONS.ORGANIZATION_EVENTS_SUCCESS,
     payload,
});

export const failOrganizationEvents = (error) => ({
     type: ACTIONS.ORGANIZATION_EVENTS_FAILURE,
     error,
});

export const getOrganizationEventsPageDetails = (payload) => ({
     type: ACTIONS.GET_ORGANIZATION_EVENTS_PAGE_DETAILS,
     payload,
});

export const requestCreateNewEvent = (payload) => ({
     type: ACTIONS.CREATE_NEW_EVENT_REQUEST,
     payload,
});

export const successCreateNewEvent = (payload) => ({
     type: ACTIONS.CREATE_NEW_EVENT_SUCCESS,
     payload,
});

export const failCreateNewEvent = (error) => ({
     type: ACTIONS.CREATE_NEW_EVENT_FAILURE,
     error,
});

export const requestEventDetails = (payload) => ({
     type: ACTIONS.EVENT_DETAILS_REQUEST,
     payload,
});

export const successEventDetails = (payload) => ({
     type: ACTIONS.EVENT_DETAILS_SUCCESS,
     payload,
});

export const failEventDetails = (error) => ({
     type: ACTIONS.EVENT_DETAILS_FAILURE,
     error,
});

export const requestUpdateEventDetails = (payload) => ({
     type: ACTIONS.UPDATE_EVENT_DETAILS_REQUEST,
     payload,
});

export const successUpdateEventDetails = (payload) => ({
     type: ACTIONS.UPDATE_EVENT_DETAILS_SUCCESS,
     payload,
});

export const failUpdateEventDetails = (error) => ({
     type: ACTIONS.UPDATE_EVENT_DETAILS_FAILURE,
     error,
});

export const requestEventSearch = (payload) => ({
     type: ACTIONS.SEARCH_EVENT_REQUEST,
     payload,
});

export const successEventSearch = (payload) => ({
     type: ACTIONS.SEARCH_EVENT_SUCCESS,
     payload,
});

export const failEventSearch = (error) => ({
     type: ACTIONS.SEARCH_EVENT_FAILURE,
     error,
});

export const requestCancelEvent = (payload) => ({
     type: ACTIONS.CANCEL_EVENT_REQUEST,
     payload,
});

export const successCancelEvent = (payload) => ({
     type: ACTIONS.CANCEL_EVENT_SUCCESS,
     payload,
});

export const failCancelEvent = (error) => ({
     type: ACTIONS.CANCEL_EVENT_FAILURE,
     error,
});

export const requestEventPhotoUpload = (payload) => ({
     type: ACTIONS.UPLOAD_EVENT_PHOTO_REQUEST,
     payload,
});

export const successEventPhotoUpload = (payload) => ({
     type: ACTIONS.UPLOAD_EVENT_PHOTO_SUCCESS,
     payload,
});

export const failEventPhotoUpload = (error) => ({
     type: ACTIONS.UPLOAD_EVENT_PHOTO_FAILURE,
     error,
});

export const requestEventPhotoTemporaryUpload = (payload) => ({
     type: ACTIONS.UPLOAD_EVENT_PHOTO_TEMPORARY_REQUEST,
     payload,
});

export const successEventPhotoTemporaryUpload = (payload) => ({
     type: ACTIONS.UPLOAD_EVENT_PHOTO_TEMPORARY_SUCCESS,
     payload,
});

export const failEventPhotoTemporaryUpload = (error) => ({
     type: ACTIONS.UPLOAD_EVENT_PHOTO_TEMPORARY_FAILURE,
     error,
});

export const requestTemporaryPhotoReset = () => ({
     type: ACTIONS.TEMPORARY_PHOTO_RESET
});

export const refreshEventAvatars = (payload) => ({
     type: ACTIONS.REFRESH_EVENT_AVATAR,
     payload,
});

export const requestRemoveEventAvatar = (payload) => ({
     type: ACTIONS.REMOVE_EVENT_AVATAR_REQUEST,
     payload,
});

export const successRemoveEventAvatar = (payload) => ({
     type: ACTIONS.REMOVE_EVENT_AVATAR_SUCCESS,
     payload,
});

export const failRemoveEventAvatar = (error) => ({
     type: ACTIONS.REMOVE_EVENT_AVATAR_FAILURE,
     error,
});

export const moduleDown = () => ({
     type: ACTIONS.MODULE_DOWN,
});

export const INITIAL_STATE = {
     organizationEventsRequested: false,
     organizationEventsSucceeded: false,
     organizationEventsFailed: false,
     organizationEventsPageDetails: {},
     createNewEventRequested: false,
     createNewEventSucceeded: false,
     createNewEventFailed: false,
     eventDetailsRequested: false,
     eventDetailsSucceeded: false,
     eventDetailsFailed: false,
     eventDetailsUpdateRequested: false,
     eventDetailsUpdateSucceeded: false,
     eventDetailsUpdateFailed: false,
     eventSearchRequested: false,
     eventSearchSucceeded: false,
     eventSearchFailed: false,
     cancelEventRequested: false,
     cancelEventSucceeded: false,
     cancelEventFailed: false,
     eventPhotoUploadRequested: false,
     eventPhotoUploadSucceeded: false,
     eventPhotoUploadFailed: false,
     eventPhotoTemporaryUploadRequested: false,
     eventPhotoTemporaryUploadSucceeded: false,
     eventPhotoTemporaryUploadFailed: false,
     refreshEventAvatar: false,
     removeEventAvatarRequested: false,
     removeEventAvatarSucceeded: false,
     removeEventAvatarFailed: false,
     moduleDown: false,
     photoTemporary: null,

     eventSearchResult: null,
     eventsList: null,
     eventData: null,
     message: null,
     error: null,
};

export default function eventsReducer(state = INITIAL_STATE, action) {
     switch (action.type) {
          case ACTIONS.EVENT_CLEAR_MESSAGES:
               return {
                    ...state,
                    message: null,
                    error: null,
               };
          case ACTIONS.ORGANIZATION_EVENTS_REQUEST:
               return {
                    ...state,
                    organizationEventsRequested: true,
                    organizationEventsSucceeded: false,
                    organizationEventsFailed: false,
               };
          case ACTIONS.ORGANIZATION_EVENTS_SUCCESS:
               return {
                    ...state,
                    organizationEventsRequested: false,
                    organizationEventsSucceeded: true,
                    organizationEventsFailed: false,
                    eventsList: action.payload,
                    error: null,
                    moduleDown: false,
               };
          case ACTIONS.ORGANIZATION_EVENTS_FAILURE:
               return {
                    ...state,
                    organizationEventsRequested: false,
                    organizationEventsSucceeded: false,
                    organizationEventsFailed: true,
                    error: action.error,
                    eventsList: null,
               };
          case ACTIONS.GET_ORGANIZATION_EVENTS_PAGE_DETAILS:
               return {
                    ...state,
                    organizationEventsPageDetails: action.payload,
               };
          case ACTIONS.CREATE_NEW_EVENT_REQUEST:
               return {
                    ...state,
                    createNewEventRequested: true,
                    createNewEventSucceeded: false,
                    createNewEventFailed: false,
                    message: null,
                    error: null,
               };
          case ACTIONS.CREATE_NEW_EVENT_SUCCESS:
               return {
                    ...state,
                    createNewEventRequested: false,
                    createNewEventSucceeded: true,
                    createNewEventFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.CREATE_NEW_EVENT_FAILURE:
               return {
                    ...state,
                    createNewEventRequested: false,
                    createNewEventSucceeded: false,
                    createNewEventFailed: true,
                    error: action.error,
                    message: null,
               };
          case ACTIONS.EVENT_DETAILS_REQUEST:
               return {
                    ...state,
                    eventDetailsRequested: true,
                    eventDetailsSucceeded: false,
                    eventDetailsFailed: false,
               };
          case ACTIONS.EVENT_DETAILS_SUCCESS:
               return {
                    ...state,
                    eventDetailsRequested: false,
                    eventDetailsSucceeded: true,
                    eventDetailsFailed: false,
                    eventData: action.payload,
                    error: null,
               };
          case ACTIONS.EVENT_DETAILS_FAILURE:
               return {
                    ...state,
                    eventDetailsRequested: false,
                    eventDetailsSucceeded: false,
                    eventDetailsFailed: true,
                    error: action.error,
                    eventData: null,
               };
          case ACTIONS.UPDATE_EVENT_DETAILS_REQUEST:
               return {
                    ...state,
                    eventDetailsUpdateRequested: true,
                    eventDetailsUpdateSucceeded: false,
                    eventDetailsUpdateFailed: false,
                    message: null,
                    error: null,
               };
          case ACTIONS.UPDATE_EVENT_DETAILS_SUCCESS:
               return {
                    ...state,
                    eventDetailsUpdateRequested: false,
                    eventDetailsUpdateSucceeded: true,
                    eventDetailsUpdateFailed: false,
                    refreshEventAvatar: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.UPDATE_EVENT_DETAILS_FAILURE:
               return {
                    ...state,
                    eventDetailsUpdateRequested: false,
                    eventDetailsUpdateSucceeded: false,
                    eventDetailsUpdateFailed: true,
                    refreshEventAvatar: false,
                    error: action.error,
                    message: null,
               };
          case ACTIONS.SEARCH_EVENT_REQUEST:
               return {
                    ...state,
                    eventSearchRequested: true,
                    eventSearchSucceeded: false,
                    eventSearchFailed: false,
                    eventSearchResult: null,
                    error: null,
               };
          case ACTIONS.SEARCH_EVENT_SUCCESS:
               return {
                    ...state,
                    eventSearchRequested: false,
                    eventSearchSucceeded: true,
                    eventSearchFailed: false,
                    eventSearchResult: action.payload,
                    error: null,
               };
          case ACTIONS.SEARCH_EVENT_FAILURE:
               return {
                    ...state,
                    eventSearchRequested: false,
                    eventSearchSucceeded: false,
                    eventSearchFailed: true,
                    error: action.error,
                    eventSearchResult: null,
               };
          case ACTIONS.CANCEL_EVENT_REQUEST:
               return {
                    ...state,
                    cancelEventRequested: true,
                    cancelEventSucceeded: false,
                    cancelEventFailed: false,
               };
          case ACTIONS.CANCEL_EVENT_SUCCESS:
               return {
                    ...state,
                    cancelEventRequested: false,
                    cancelEventSucceeded: true,
                    cancelEventFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.CANCEL_EVENT_FAILURE:
               return {
                    ...state,
                    cancelEventRequested: false,
                    cancelEventSucceeded: false,
                    cancelEventFailed: true,
                    error: action.error,
                    message: null,
               };
          case ACTIONS.UPLOAD_EVENT_PHOTO_REQUEST:
               return {
                    ...state,
                    eventPhotoUploadRequested: true,
                    eventPhotoUploadSucceeded: false,
                    eventPhotoUploadFailed: false,
               };
          case ACTIONS.UPLOAD_EVENT_PHOTO_SUCCESS:
               return {
                    ...state,
                    eventPhotoUploadRequested: false,
                    eventPhotoUploadSucceeded: true,
                    eventPhotoUploadFailed: false,
                    eventData: {...state.eventData, photos: action.payload, is_photo_default: false},
                    error: null,
               };
          case ACTIONS.UPLOAD_EVENT_PHOTO_FAILURE:
               return {
                    ...state,
                    eventPhotoUploadRequested: false,
                    eventPhotoUploadSucceeded: false,
                    eventPhotoUploadFailed: true,
                    error: action.error,
                    message: null,
               };
          case ACTIONS.UPLOAD_EVENT_PHOTO_TEMPORARY_REQUEST:
               return {
                    ...state,
                    eventPhotoTemporaryUploadRequested: true,
                    eventPhotoTemporaryUploadSucceeded: false,
                    eventPhotoTemporaryUploadFailed: false,
               };
          case ACTIONS.UPLOAD_EVENT_PHOTO_TEMPORARY_SUCCESS:
               return {
                    ...state,
                    eventPhotoTemporaryUploadRequested: false,
                    eventPhotoTemporaryUploadSucceeded: true,
                    eventPhotoTemporaryUploadFailed: false,
                    photoTemporary: action.payload,
                    error: null,
               };
          case ACTIONS.TEMPORARY_PHOTO_RESET:
               return {
                    ...state,
                    photoTemporary: null
               };
          case ACTIONS.UPLOAD_EVENT_PHOTO_TEMPORARY_FAILURE:
               return {
                    ...state,
                    eventPhotoTemporaryUploadRequested: false,
                    eventPhotoTemporaryUploadSucceeded: false,
                    eventPhotoTemporaryUploadFailed: true,
                    error: action.error,
                    photoTemporary: null,
                    message: null,
               };
          case ACTIONS.REFRESH_EVENT_AVATAR:
               return {
                    ...state,
                    refreshEventAvatar: true,
                    eventData: action.payload,
                    eventsList:
                         state.eventsList &&
                         state.eventsList.map((event) => {
                              if (event.id === action.payload.id) {
                                   return {
                                        ...event,
                                        photos: action.payload.photos
                                   };
                              }

                              return event;
                         }),
               };
          case ACTIONS.REMOVE_EVENT_AVATAR_REQUEST:
               return {
                    ...state,
                    removeEventAvatarRequested: true,
                    removeEventAvatarSucceeded: false,
                    removeEventAvatarFailed: false,
               };
          case ACTIONS.REMOVE_EVENT_AVATAR_SUCCESS:
               return {
                    ...state,
                    removeEventAvatarRequested: false,
                    removeEventAvatarSucceeded: true,
                    removeEventAvatarFailed: false,
                    eventData: {...state.eventData, photos: action.payload, is_photo_default: true},
                    error: null,
               };
          case ACTIONS.REMOVE_EVENT_AVATAR_FAILURE:
               return {
                    ...state,
                    removeEventAvatarRequested: false,
                    removeEventAvatarSucceeded: false,
                    removeEventAvatarFailed: true,
                    error: action.error,
                    message: null,
               };
          case ACTIONS.MODULE_DOWN:
               return {
                    ...state,
                    moduleDown: true,
               };
          default:
               return state;
     }
}
