import React from 'react';

function IconImage() {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="18"
               height="18"
               fill="none"
               viewBox="0 0 18 18"
          >
               <path
                    fill="currentColor"
                    d="M15.3 0H2.7A2.7 2.7 0 000 2.7v12.6A2.7 2.7 0 002.7 18h12.6a2.7 2.7 0 002.7-2.7V2.7A2.7 2.7 0 0015.3 0zm0 16.2H2.7a.9.9 0 01-.9-.9v-1.467l4.635-5.4 9.675 7.245a.9.9 0 01-.81.522zm.9-2.7l-5.4-4.086 1.908-1.908 3.492 2.826V13.5zm0-5.472l-3.042-2.43a.9.9 0 00-1.197.063l-2.7 2.7L6.84 6.48a.9.9 0 00-1.224.135L1.8 11.07V2.7a.9.9 0 01.9-.9h12.6a.9.9 0 01.9.9v5.328z"
               ></path>
          </svg>
     );
}

export default IconImage;
