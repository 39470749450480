import React, {useMemo, useEffect, useState, useCallback, useRef} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useTable, usePagination } from 'react-table';
import { Pagination, Dropdown, Button } from 'react-bootstrap';

import MoreIcon from '../../../../assets/icons/metronic/more-icon';
import ChevronDownIcon from '../../../../assets/icons/metronic/chevron-down-icon';
import EditIcon from '../../../../assets/icons/metronic/edit-icon';
import TrashCanIcon from '../../../../assets/icons/metronic/trash-can-icon';
import { PreviewFormModal } from './PreviewFormModal';
import { COLORS, FONT } from '../../../../constants/style.constants';
import { TablePanel } from '../../../common/filterable-table/TablePanel';
import { PAGE_SIZES } from '../../../../constants/page.constants';

import FilterableTableWrapper from '../../../common/filterable-table/FilterableTable.style';
import IconExclamation from "../../../../assets/icons/form-builder/IconExclamation";

import {
     StyledDropdown,
     TableDropdownWrapper,
} from '../../../common/dropdown/Dropdown.style';
import _ from "underscore";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const ActionsDropdown = ({ organizationId, eventId, formId, handleAction, handleEditAction }) => {
     const [dropdownShown, setDropdownShown] = useState(false);

     const toggleDropdown = useCallback(() => {
          setDropdownShown(!dropdownShown);
     }, [dropdownShown]);

     return (
          <TableDropdownWrapper>
               <StyledDropdown
                    onToggle={toggleDropdown}
                    show={dropdownShown}
                    alignRight
               >
                    <StyledDropdown.Toggle variant="default">
                         <MoreIcon className="icon" />
                    </StyledDropdown.Toggle>
                    <StyledDropdown.Menu>
                         <StyledDropdown.Item
                              as="div"
                              style={{
                                   margin: 0,
                                   padding: 0,
                              }}
                         >
                              <Link
                                   to={{
                                        pathname: `/organization/${organizationId}/events/${eventId}/form-builder/add-element`,
                                        search: `?builder-id=${formId}`,
                                   }}
                                   className="dropdown-item"
                                   onClick={() => {
                                       handleEditAction(formId)
                                   }}
                              >
                                   <EditIcon />
                                   <span className="edit">
                                        {i18n.t('buttons.edit')}
                                   </span>
                              </Link>
                         </StyledDropdown.Item>
                         <StyledDropdown.Item
                              as="div"
                              style={{
                                   margin: 0,
                                   padding: 0,
                              }}
                         >
                              <Link
                                   to="#"
                                   className="dropdown-item"
                                   onClick={() =>
                                        handleAction({
                                             id: formId,
                                             type: 'remove',
                                        })
                                   }
                              >
                                   <TrashCanIcon />
                                   <span className="remove">
                                        {i18n.t('buttons.delete')}
                                   </span>
                              </Link>
                         </StyledDropdown.Item>
                    </StyledDropdown.Menu>
               </StyledDropdown>
          </TableDropdownWrapper>
     );
};

const mapStateToProps = (state) => ({
     router: state.router,
     pageDetails: state.formBuilderReducer.eventFormsPageDetails,
     languages: state.userReducer.languages,
});

export const _InvitationFormTable = ({
     data,
     handleAction,
     id: organizationId,
     eventId,
     updateCallback,
     templateId,
     pageDetails,
     handlePageSizeChange,
     handlePageChange,
     handleSearch,
     languages,
     handleNewFormAction,
     handleEditFormAction,
     selectedForms,
     handleFormSelect,
     ...props
}) => {
     const {
          router: {
               location: { pathname },
          },
     } = props;

     const { t } = useTranslation();

     const [transformedData, setTransformedData] = useState([]);
     const [showModal, setShowModal] = useState(false);
     const [formPreview, setFormPreview] = useState(null);
     const [formLanguage, setFormLanguage] = useState(null);
     const [allFormLanguage, setAllFormLanguages] = useState(null);

     const formatLanguages = (item) => {
         if (languages.length > 0) {
             const langs = item.language
                 .map((l) => (languages.find((lang) => (lang.id === l)).code.toUpperCase()))
                 .join(' / ');

             return ` (${langs})`;
         }
     };

     const handleCheckboxSelect = (item) => {
         handleFormSelect(item);
     };

     const isSelected = (id) => {
         const selectedIds = _.pluck(selectedForms, 'id');

         return selectedIds.includes(id);
     };

     const openPreview = (id, language) => {
         const firstLanguage = languages.find((lang) => lang.id === language[0]);
         if (firstLanguage) {
             setFormLanguage(firstLanguage);
         }

         const allLanguages = languages.filter((lang) => language.includes(lang.id));
         setAllFormLanguages(allLanguages);

         setFormPreview(id);
         setShowModal(true);
     };

     const transformData = (data) =>
          data.map((item, idx) => ({
               ...item,
               selection: (
                   <>
                       {(item.is_valid && item.is_translated) ? (
                           <div className="checkbox-solid">
                               <input
                                   type="checkbox"
                                   checked={isSelected(item.id)}
                                   onChange={() => handleCheckboxSelect(item)}
                               />
                               <span />
                           </div>
                       ) : (
                           <OverlayTrigger
                               triger={['hover', 'focus']}
                               placement={'bottom-start'}
                               overlay={
                                   <Tooltip>
                                       {t('form_builder.form_contains_errors')}
                                   </Tooltip>
                               }
                           >
                               <div className="checkbox-solid">
                                   <input
                                       type="checkbox"
                                       checked={false}
                                       disabled={true}
                                       style={{ pointerEvents: 'none' }}
                                   />
                                   <span />
                               </div>
                           </OverlayTrigger>
                       ) }
                   </>

               ),
               formName: (
                   <>
                       {!(item.is_valid && item.is_translated) && (
                           <OverlayTrigger
                               triger={['hover', 'focus']}
                               placement={'bottom-start'}
                               overlay={
                                   <Tooltip>
                                       {t('form_builder.form_contains_errors')}
                                   </Tooltip>
                               }
                           >
                               <span
                                   style={{
                                       display: 'inline-block',
                                       margin: 'auto',
                                       marginRight: '10px',
                                       width: '19px'
                                   }}
                               >
                                   {<IconExclamation color={COLORS.YELLOW}/>}
                               </span>
                           </OverlayTrigger>
                       )}
                        <span
                             style={{
                                  fontWeight: `${FONT.MEDIUM}`,
                                  paddingLeft: 0,
                             }}
                        >
                             {item.name}
                             {formatLanguages(item)}
                        </span>
                   </>
               ),
               access: (
                    <span
                         style={{
                              marginRight: '7px',
                              textTransform: 'capitalize',
                         }}
                    >
                         {t(`form_builder.access.${item.access_type}`)}
                    </span>
               ),
               actions: (
                    <div style={{ display: 'flex' }}>
                         <Button
                              variant="outline-primary"
                              onClick={() => {
                                  openPreview(item.id, item.language)
                              }}
                         >
                              {t('reminders.preview')}
                         </Button>
                         <ActionsDropdown
                              organizationId={organizationId}
                              eventId={eventId}
                              formId={item.id}
                              handleAction={handleAction}
                              handleEditAction={handleEditFormAction}
                              updateCallback={updateCallback}
                         />
                    </div>
               ),
          }));

     useEffect(() => {
          setTransformedData(transformData(data));

          if (!transformedData) return;
     }, [data, selectedForms]);

     const columns = useMemo(
          () => [
               {
                    Header: 'selection',
                    accessor: 'selection',
               },
               {
                    Header: t('form_builder.form_name'),
                    accessor: 'formName',
               },
               {
                    Header: t('speakers.access'),
                    accessor: 'access',
               },
               {
                    Header: t('organization_settings.actions'),
                    accessor: 'actions',
               },
          ],
          []
     );

     const {
          getTableProps,
          getTableBodyProps,
          headerGroups,
          rows,
          prepareRow,
          page,
          canPreviousPage,
          canNextPage,
          pageOptions,
          pageCount,
          gotoPage,
          nextPage,
          previousPage,
          setPageSize,
          state: { pageIndex, pageSize }
     } = useTable(
          {
               columns,
               data: transformedData,
               manualPagination: true,
               pageCount: pageDetails.last_page,
               initialState: {
                    pageSize: pageDetails.per_page,
               },
          },
          usePagination
     );

     const form = transformedData.find(({ id }) => id === formPreview);
     const formId = form && form.id;

    const alignText = useCallback((id) => {
        const config = {
            actions: 'center',
        };

        return config[id] || 'left';
    }, []);

    const transformColumnWidth = useCallback(
        (id) => {
            const config = {
                actions: '100px',
                selection: '24px'
            };

            return config[id] || 'inherit';
        },
        []);

     return (
          <FilterableTableWrapper>
               <PreviewFormModal
                    formId={formId}
                    language={formLanguage}
                    allLangauges={allFormLanguage}
                    show={showModal}
                    handleClose={() => setShowModal(false)}
               />
               <TablePanel
                    title={t('reminders.templates')}
                    total={pageDetails.total}
                    handleSearch={handleSearch}
                    actions={
                         <div style={{ display: 'flex' }}>
                               <Button variant="success" onClick={handleNewFormAction}>
                                    {t('reminders.new_form')}
                               </Button>
                         </div>
                    }
               />

               <div
                    className="table-container"
                    style={{ marginBottom: '30px' }}
               >
                    <table
                         {...getTableProps()}
                         columns={columns}
                         data={data}
                         className="table"
                         style={{ width: '100%' }}
                    >
                         <thead>
                              {headerGroups.map((headerGroup) => (
                                   <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(
                                             (column, i) => {
                                                  return i !== 0 ? (
                                                       <th
                                                            key={i}
                                                            className="th"
                                                            {...column.getHeaderProps()}
                                                            style={{
                                                                 padding: '20px 30px',
                                                                 verticalAlign: 'middle',
                                                                 textAlign: alignText(column.id),
                                                                 width: transformColumnWidth(column.id)
                                                            }}
                                                       >
                                                            {column.render(
                                                                 'Header'
                                                            )}
                                                       </th>
                                                  ) : (
                                                       <th
                                                           key={i}
                                                           style={{
                                                               padding: '20px 30px',
                                                               verticalAlign: 'middle',
                                                               textAlign: alignText(column.id),
                                                               width: transformColumnWidth(column.id)
                                                           }}
                                                       />
                                                  );
                                             }
                                        )}
                                   </tr>
                              ))}
                         </thead>
                         <tbody {...getTableBodyProps()}>
                              {page.slice(0, pageSize).map((row, i) => {
                                   prepareRow(row);
                                   return (
                                        <tr
                                             className={
                                                   isSelected(row.original.id)
                                                       ? 'selected-row'
                                                       : ''
                                             }
                                             {...row.getRowProps()}
                                             style={{
                                                  borderTop: `1px solid ${COLORS.PALE_BLUE}`,
                                             }}
                                        >
                                             {row.cells.map((cell) => {
                                                  return (
                                                       <td
                                                            {...cell.getCellProps()}
                                                            className="td"
                                                            style={{
                                                                 width:
                                                                      'inherit',
                                                                 padding:
                                                                      '20px 30px',
                                                                 verticalAlign:
                                                                      'middle',
                                                            }}
                                                       >
                                                            {cell.render(
                                                                 'Cell'
                                                            )}
                                                       </td>
                                                  );
                                             })}
                                        </tr>
                                   );
                              })}
                         </tbody>
                    </table>
                    <div className="datatable__pager">
                         <div className="pagination">
                              <Pagination.First
                                   onClick={() => {
                                        gotoPage(0);
                                        handlePageChange({
                                             page: 1,
                                             pageSize,
                                        });
                                   }}
                                   disabled={!canPreviousPage}
                              />
                              <Pagination.Prev
                                   onClick={() => {
                                        previousPage();
                                        handlePageChange({
                                             page: pageDetails.current_page - 1,
                                             pageSize,
                                        });
                                   }}
                                   disabled={!canPreviousPage}
                              />
                              <ul className="pagination">
                                   {pageOptions.map((option) => (
                                        <li
                                             key={option}
                                             className={`page-item ${
                                                  pageIndex === option
                                                       ? 'active'
                                                       : ''
                                             }`}
                                             style={{ cursor: 'pointer' }}
                                             onClick={() =>
                                                  handlePageChange({
                                                       page: option + 1,
                                                       pageSize,
                                                  })
                                             }
                                        >
                                             <span
                                                  className="page-link"
                                                  onClick={() =>
                                                       gotoPage(option)
                                                  }
                                             >
                                                  <span>{option + 1}</span>
                                             </span>
                                        </li>
                                   ))}
                              </ul>
                              <Pagination.Next
                                   onClick={() => {
                                        nextPage();
                                        handlePageChange({
                                             page: pageDetails.current_page + 1,
                                             pageSize,
                                        });
                                   }}
                                   disabled={!canNextPage}
                              />
                              <Pagination.Last
                                   onClick={() => {
                                        gotoPage(pageCount - 1);
                                        handlePageChange({
                                             page: pageDetails.last_page,
                                             pageSize,
                                        });
                                   }}
                                   disabled={!canNextPage}
                              />
                         </div>
                         <div className="tabel__pager-info">
                              <Dropdown>
                                   <Dropdown.Toggle variant="light">
                                        <span>{pageDetails.per_page}</span>
                                        <ChevronDownIcon />
                                   </Dropdown.Toggle>
                                   <Dropdown.Menu style={{ minWidth: '0' }}>
                                        {PAGE_SIZES.map((i) => (
                                             <Dropdown.Item
                                                  key={i}
                                                  onClick={() => {
                                                       setPageSize(i);
                                                       handlePageSizeChange(i);
                                                  }}
                                             >
                                                  <span>{i}</span>
                                             </Dropdown.Item>
                                        ))}
                                   </Dropdown.Menu>
                              </Dropdown>
                              <span className="tabel__pager-info--text">
                                   {`${i18n.t(
                                        'organization_settings.showing'
                                   )} ${pageDetails.from} - ${
                                        pageDetails.to
                                   } ${i18n.t('organization_settings.of')} ${
                                        pageDetails.total
                                   }`}
                              </span>
                         </div>
                    </div>
               </div>
          </FilterableTableWrapper>
     );
};

export const InvitationFormTable = connect(
     mapStateToProps,
     null
)(_InvitationFormTable);
