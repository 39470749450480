import React, {useEffect, useRef, useState} from 'react';
import { useDrag, useDrop } from 'react-dnd';

import IconDropdown from '../../../../../assets/icons/form-builder/IconDropdown';
import { DND_TYPES } from '../../ElementTypes';
import { Placeholder } from '../Placeholder';
import { COLORS } from '../../../../../constants/style.constants';
import CustomToggle from '../../../../common/custom-toggle';
import i18n from 'i18next';
import ChevronDownIcon from '../../../../../assets/icons/metronic/chevron-down-icon';
import { Button, Dropdown } from 'react-bootstrap';
import TimesIcon from '../../../../../assets/icons/metronic/times-icon';
import { Overlay } from '../Overlay';
import {getEmptyImage} from "react-dnd-html5-backend";

export const DropdownElement = ({
     id,
     name,
     type,
     label,
     elementCode,
     choices,
     additionalInformation,
     required,
     isMaster,
     isValid,
     index,
     language,
     inBuilder,
     forceDisplay,
     handleElementSelect,
     isSelectedItem,
     handleRemoveElement,
     setActiveSpan,
     activeSpan,
     moveElement
}) => {
     const dropdownRef = useRef();
     const defaultDropdownChoice = (choices) => {
          const foundItem = choices.find((c) => !!c.preChecked);

          return foundItem && foundItem.value;
     };

     const DEFAULT_TEMPLATE_OPTION = i18n.t('form_builder.select_something', { lng: language?.code });

     const [innerState, setInnerState] = useState({
          templateOptions: [
               { value: DEFAULT_TEMPLATE_OPTION },
               { value: 1 },
               { value: 2 },
               { value: 3 },
               { value: 4 },
               { value: 5 },
          ],
          currentTemplateOption: DEFAULT_TEMPLATE_OPTION,
     });

     useEffect(() => {
          if (language) {
               setInnerState({
                    ...innerState,
                    currentTemplateOption: i18n.t('form_builder.select_something', { lng: language?.code })
               });
          }
     }, [language]);

     const ref = useRef(null);

     const [, drop] = useDrop({
          accept: DND_TYPES,
          hover(item, monitor) {
               if (!ref.current || item.index === undefined) {
                    return;
               }

               const dragIndex = item.index;
               const hoverIndex = index;

               if (dragIndex === hoverIndex) {
                    return;
               }

               const hoverBoundingRect = ref.current?.getBoundingClientRect();
               const hoverMiddleY =
                    (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
               const clientOffset = monitor.getClientOffset();
               const hoverClientY = clientOffset.y - hoverBoundingRect.top;

               if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                    return;
               }

               if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                    return;
               }

               moveElement(dragIndex, hoverIndex);

               item.index = hoverIndex;
          },
     });

     const [{ isDragging }, drag, preview] = useDrag({
          item: {
               id,
               name,
               type,
               label,
               elementCode,
               choices,
               additionalInformation,
               required,
               index,
          },
          isDragging(monitor) {
               return monitor.getItem() && monitor.getItem().id === id;
          },
          collect: (monitor) => ({
               isDragging: monitor.isDragging(),
          }),
     });

     useEffect(() => {
          preview(getEmptyImage(), { captureDraggingState: false });
     }, []);

     drag(drop(ref));

     if (!inBuilder) {
          return (
               <div ref={ref} className="elements-grid--item" role="button">
                    <span className="icon">
                         <IconDropdown />
                    </span>
                    <span className="text">{name}</span>
               </div>
          );
     }

     if (isDragging && !forceDisplay) {
          return <Placeholder />;
     }

     return (
          <div
               ref={ref}
               className={`selection ${!isValid && !isDragging && !isSelectedItem(id) ? 'is-warning' : ''}`}
               onClick={(e) => handleElementSelect(e, id)}
               style={{
                    backgroundColor: isSelectedItem(id)
                         ? 'rgba(24, 112, 244, 0.05)'
                         : '#fff',
                    position: 'relative',
               }}
          >
               <Overlay />

               <label
                    htmlFor={label}
                    style={{
                         display: 'grid',
                         gridTemplateColumns: '195px auto',
                         placeItems: 'start start',
                         margin: 0,
                    }}
               >
                    <span
                         style={{
                              maxWidth: '160px',
                              placeSelf: 'start start',
                         }}
                    >
                         {label || name}
                         {required && (
                              <span
                                   style={{
                                        marginLeft: '10px',
                                        color: `${COLORS.RED}`,
                                   }}
                              >
                                   *
                              </span>
                         )}
                    </span>
                    <div className="input-group" style={{ width: '100%' }} tabIndex={-1}>
                         <Dropdown
                              ref={dropdownRef}
                              style={{
                                   pointerEvents: 'none',
                                   boxShadow: 'none',
                              }}
                         >
                              <Dropdown.Toggle
                                   className="select-dropdown"
                                   as={CustomToggle}
                              >
                                   {defaultDropdownChoice(choices) ||
                                        innerState.currentTemplateOption}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                   {choices.map(({ value, id }) => (
                                        <Dropdown.Item
                                             key={id}
                                             style={{
                                                  pointerEvents: 'none',
                                             }}
                                             onClick={() =>
                                                  setInnerState({
                                                       templateOptions: [
                                                            ...choices,
                                                       ],
                                                       currentTemplateOption:
                                                            value ||
                                                            DEFAULT_TEMPLATE_OPTION,
                                                  })
                                             }
                                        >
                                             {value ||
                                                  i18n.t('form_builder.choice')}
                                        </Dropdown.Item>
                                   ))}
                              </Dropdown.Menu>
                         </Dropdown>
                         <span className="suffix">
                              <ChevronDownIcon />
                         </span>
                         {additionalInformation && (
                              <div className="info-block">
                                   {additionalInformation}
                              </div>
                         )}
                    </div>
               </label>
               {isMaster && (
                   <Button
                       autoFocus={isSelectedItem(id)}
                       variant="danger"
                       onClick={() => handleRemoveElement(id)}
                       onMouseEnter={() => setActiveSpan(true)}
                       onMouseLeave={() => setActiveSpan(false)}
                       style={{
                            visibility: isSelectedItem(id) ? 'visible' : 'hidden',
                            position: 'relative',
                            zIndex: 3,
                       }}
                   >
                        <TimesIcon
                            color={`${COLORS.PLACEHOLDER_GREY}`}
                            activeColor={`${COLORS.WHITE}`}
                            hovered={activeSpan}
                        />
                   </Button>
               )}
          </div>
     );
};
