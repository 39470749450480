import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import * as yup from 'yup';
import {Button, Form, Row, Col, Dropdown, Alert} from 'react-bootstrap';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { Formik, useFormikContext } from 'formik';
import TagField from '../../common/tagfield';
import CustomToggle from '../../common/custom-toggle';
import { CustomDatePicker } from '../../common/pickers/CustomDatePicker';
import ChevronDownIcon from '../../../assets/icons/metronic/chevron-down-icon';
import TimesIcon from '../../../assets/icons/metronic/times-icon';
import UserRegularIcon from '../../../assets/icons/metronic/user-regular';
import EmailIcon from '../../../assets/icons/metronic/email-icon';
import { COLORS } from '../../../constants/style.constants';
import _, {isEmpty} from "underscore";
import SweetAlert from "../../common/sweet-alert";
import {mixed as yupMixed} from "yup";
import toastr from "toastr";
import Spinner from "react-bootstrap/Spinner";

const DEFAULT_TEMPLATE_OPTION = i18n.t('reminders.select_reminder_template');
const DEFAULT_FOR_SELECT_TEMPLATE_OPTION = i18n.t('speakers.you_dont_have_saved_template');

const ReminderConditionRadioGroup = ({ currentItem, setCurrentItem }) => {
     return (
          <div className="radio-group">
               <label className="form-label">
                    <input
                         type="radio"
                         value="daysAfterInvitation"
                         checked={currentItem === 'daysAfterInvitation'}
                         onChange={() => {}}
                         onClick={() => setCurrentItem('daysAfterInvitation')}
                    />
                    <div className="circle-outer">
                         <div className="circle-inner" />
                    </div>
                    {i18n.t('reminders.days_after_invitation')}
               </label>
               <label className="form-label">
                    <input
                         type="radio"
                         value="specificDate"
                         checked={currentItem === 'specificDate'}
                         onChange={() => {}}
                         onClick={() => setCurrentItem('specificDate')}
                    />
                    <span className="circle-outer">
                         <span className="circle-inner" />
                    </span>
                    {i18n.t('reminders.specific_date')}
               </label>
          </div>
     );
};

export const ReminderForm =
     ({
          handleSubmit,
          handleRemoveTemplate,
          emailSendersList,
          currentUser,
          handleMultilingual,
          handleQuillMessage,
          handleLanguageSelect,
          handleSelectEmailTemplate,
          handleSetAccessType,
          handleSaveTemplate,
          emailTemplatesList,
          handleAttachFiles,
          details = null,
          saveAsTemplate = false,
          languages,
          handleMultilingualSelected,
          mandatoryLanguage,
          multilingualSelected,
          multilingual,
          currentLanguage,
          language,
          handleMandatoryLanguage,
          isValidReminderData,
          handleSubjectChange,
          handleCustomSender,
          handleSenderName,
          handleSenderEmail,
          exportReminderData,
          isEnteredLinks,
          fileData,
          filesData,
          handleRemoveAttachedFile,
          shouldFilesBeTranslated,
          title,
          handleSetNameOfTemplate,
          uploadFileRequested,
          copyReminderRequested
     }) => {
          const { t } = useTranslation();
          const { eventId } = useParams();

          const quillRef = useRef();
          const form = useRef();

          const [quillValue, setQuillValue] = useState(
             exportReminderData().message
          );
          const [activeSpan, setActiveSpan] = useState(false);
          const [currentItem, setCurrentItem] = useState('daysAfterInvitation');
          const [enableReinitialize, setEnableReinitialize] = useState(true);

          const [files, setFiles] = useState([]);

          const [innerState, setInnerState] = useState({
               templateOptions: [{ value: DEFAULT_TEMPLATE_OPTION }],
               languageOptions: languages.map(lang => ({...lang, value: lang.name})),
               sendersOptions: [{ value: i18n.t('reminders.you') }],
               accessLevelOptions: [
                    { value: i18n.t('form_builder.select_access_level'), key: '' },
                    { value: i18n.t('dashboard.organization'), key: 'organization' },
                    { value: i18n.t('form_builder.event_access_level'), key: 'event' },
                    // { value: t('form_builder.me') },
               ],
               currentTemplate: null,
               currentTemplateOption: DEFAULT_TEMPLATE_OPTION,
               saveAsTemplate,
               multiLanguage: false,
               multiLanguageSelected: multilingualSelected,
               mandatoryLanguage: mandatoryLanguage,
               templateResetShown: false,
               currentLanguage: currentLanguage,
               currentSender: i18n.t('reminders.you'),
               currentSenderEmail: '',
               currentAccessLevel: i18n.t('form_builder.select_access_level'),
               conditionAfter: '',
               conditionOn: '',
               nameOfTemplate: ''
          });

          let defaultTemplate = {
               data: [],
               name: i18n.t('speakers.invitation_email_no_template')
          };

          languages.forEach((language) => {
               if (language === currentLanguage) {
                    defaultTemplate.data.push({
                         language_id: language.id,
                         message: '',
                         subject: '',
                         sender: {name: `${currentUser.firstname} ${currentUser.lastname}`, email: currentUser.email },
                         files: []
                    });
               }
          });

          const isDefaultTemplate = () => {
               return innerState.currentTemplateOption === DEFAULT_TEMPLATE_OPTION ||
                   innerState.currentTemplateOption === i18n.t('speakers.invitation_email_no_template');
          };

          useEffect(() => {
               if (details && languages.length > 0) {
                   const detailsLanguageIds = _.pluck(details.data, 'language_id');

                    setInnerState({
                         templateOptions: [{ value: DEFAULT_TEMPLATE_OPTION }],
                         languageOptions: languages.map(lang => ({...lang, value: lang.name})),
                         sendersOptions: [
                              { value: i18n.t('reminders.you') },
                         ],
                         accessLevelOptions: [
                              { value: i18n.t('form_builder.select_access_level'), key: '' },
                              { value: i18n.t('dashboard.organization'), key: 'organization' },
                              { value: i18n.t('form_builder.event_access_level'), key: 'event' },
                              // { value: t('form_builder.only_me') },
                         ],
                         currentTemplate: null,
                         currentTemplateOption: DEFAULT_TEMPLATE_OPTION,
                         saveAsTemplate: false,
                         multiLanguage: details.multilingual,
                         multiLanguageSelected: languages.filter((lang) => detailsLanguageIds.includes(lang.id)),
                         mandatoryLanguage: languages.find((lang) => lang.id === details.data[0].language_id),
                         templateResetShown: false,
                         currentLanguage: languages.find(lang => lang.id === details.data[0].language_id),
                         currentSender: i18n.t('reminders.you'),
                         currentSenderEmail: '',
                         currentAccessLevel: i18n.t(
                              'form_builder.select_access_level'
                         ),
                         conditionAfter: details.condition_after || details.condition_after === 0 ? details.condition_after : '',
                         conditionOn: details.condition_on ? new Date(details.condition_on) : '',
                         subject: details.data[0].subject,
                    });

                    setQuillValue(details.data[0].message);
                    setCurrentItem(
                         details.condition_after || details.condition_after === 0
                              ? 'daysAfterInvitation'
                              : 'specificDate'
                    );

                   setTimeout(() => {
                       setFiles(exportReminderData().files_data);
                   }, 100);
               }
          }, [details]);

          useEffect(() => {
              if (filesData) {
                  setTimeout(() => {
                      setFiles(exportReminderData().files_data);
                  }, 100);
              }
          }, [filesData, language]);

          useEffect(() => {
               if (fileData) {
                    setTimeout(() => {
                         setFiles(exportReminderData().files_data);
                    }, 100);
               }
          }, [fileData, language]);

          const fileUploadRef = useRef();

          const transformValues = useCallback(() => {
               const values = [];
               innerState.multiLanguage &&
               innerState.multiLanguageSelected.forEach((lang) => {
                    values.push(
                        lang.name
                    );
               });

               return values.join(', ');

          }, [innerState]);

          const handleRemoveFile = (code) => {
              SweetAlert({
                  title: i18n.t('sweet_alerts.attention'),
                  text: i18n.t('sweet_alerts.confirm_removing_attached_file'),
                  confirmButtonText: i18n.t('buttons.continue'),
                  cancelButtonText: i18n.t('buttons.cancel'),
                  showCancelButton: true,
                  callback: () => {
                      handleRemoveAttachedFile(code);
                      setFiles(files.filter(file => file.code !== code));
                  },
              });
          };

          const handleMultiLanguage = () => {
               setInnerState({
                    ...innerState,
                    multiLanguage: !innerState.multiLanguage,
               });
          };

         const handleChangeEmailTemplate = (value, id) => {
             const exportedData = exportReminderData(language);
             const cleanMessage = exportedData.message.replace(/<\/?[^>]+(>|$)/g, "");

             if (
                 multilingual ||
                 !_.isEmpty(exportedData.subject) ||
                 !_.isEmpty(cleanMessage)
             ) {
                 SweetAlert({
                     title: i18n.t('sweet_alerts.attention'),
                     text: i18n.t('sweet_alerts.started_building_email_template'),
                     confirmButtonText: i18n.t('buttons.continue'),
                     cancelButtonText: i18n.t('buttons.cancel'),
                     showCancelButton: true,
                     callback: () => {
                         setEmailTemplate(value, id);
                     },
                 });
             } else {
                 setEmailTemplate(value, id);
             }
         };

         const setEmailTemplate = (value, id) => {
               let template = emailTemplatesList.find((el) => el.id === id);
               template = !template && id === 0 ? defaultTemplate : template;
               let languageForUsage = languages.find((l) => l.id === template.data[0].language_id);
               languageForUsage = !languageForUsage ? innerState.currentLanguage : languageForUsage;
             
             const data = template.data.find((d) => d.language_id === languageForUsage.id);
             const selectedLanguages = [];
             let mult = false;

             if (template.data.length > 1) {
                 mult = true;

                 template.data.forEach((data) => {
                     const foundLanguage = languages.find((l) => l.id === data.language_id);
                     if (foundLanguage) {
                         selectedLanguages.push(foundLanguage);
                     }
                 });
             }

             setInnerState(
                 {
                     ...innerState,
                     currentTemplateOption: value,
                     currentTemplate: id,
                     multiLanguage: mult,
                     multiLanguageSelected: mult ? selectedLanguages : innerState.multiLanguageSelected,
                     currentLanguage: languageForUsage,
                     mandatoryLanguage: languageForUsage,
                     newQuillValue: data.message
                 }
             );
         };

         useEffect(() => {
             form.current.validationSchema = schema();
         }, [currentItem]);

         useEffect(() => {
             if (innerState.currentLanguage) {
                 setQuillValue(exportReminderData(innerState.currentLanguage.id).message);
             }
         }, [innerState.currentLanguage]);

          useEffect(() => {
               if (languages.length > 0 && multilingualSelected.length > 0) {
                    setInnerState({
                         ...innerState,
                         currentLanguage: currentLanguage,
                         mandatoryLanguage: mandatoryLanguage,
                         multiLanguage: multilingual,
                         multiLanguageSelected: multilingualSelected,
                         languageOptions: languages.map(lang => ({...lang, value: lang.name}))
                    });
               }
          }, [languages, language]);

          useEffect(() => {
               if (innerState.currentLanguage) {
                    handleLanguageSelect(innerState.currentLanguage.id);
               }
               handleMultilingual(innerState.multiLanguage);
               if (innerState.multiLanguageSelected && innerState.multiLanguageSelected.length > 0) {
                    handleMultilingualSelected(innerState.multiLanguageSelected);
               }
               if (innerState.mandatoryLanguage) {
                   handleMandatoryLanguage(innerState.mandatoryLanguage);
               }
               if (innerState.currentTemplateOption === DEFAULT_TEMPLATE_OPTION) {
                    handleSelectEmailTemplate(null);
               } else if (innerState.currentTemplate === 0) {
                    handleSelectEmailTemplate(0, defaultTemplate);
               } else {
                    handleSelectEmailTemplate(innerState.currentTemplate);
               }
               handleSetAccessType(innerState.currentAccessLevel);
               handleSaveTemplate(innerState.saveAsTemplate);
               handleSetNameOfTemplate(innerState.nameOfTemplate);
          }, [innerState]);

          const handleLanguageTag = ({ target: { value } }) => {
               const ids = value.split(',').map((i) => Number(i));
               const selectedLanguages = languages.filter(({id}) => ids.includes(id));

               setInnerState({
                    ...innerState,
                    multiLanguageSelected: selectedLanguages
               });
          };

          const { currentSender } = innerState;

          useEffect(() => {
               setEnableReinitialize(true);
          }, [currentSender, enableReinitialize]);

          useEffect(() => {
               if (quillValue) {
                    const cleanMessage = quillValue.replace(/<\/?[^>]+(>|$)/g, "");

                    if (!isEmpty(cleanMessage)) {
                         handleQuillMessage(quillValue);
                    }
               }
          }, [quillValue]);

          useEffect(() => {
               if (innerState.newQuillValue !== null && innerState.newQuillValue !== undefined) {
                    setQuillValue(innerState.newQuillValue);
                    setInnerState({
                         ...innerState,
                         newQuillValue: null
                    });
               }
          }, [innerState.newQuillValue]);

          useEffect(() => {
               if (emailTemplatesList && emailSendersList) {
                    setInnerState({
                         ...innerState,
                         templateOptions: [
                              ...groupTemplates(emailTemplatesList),
                         ],
                         currentTemplateOption: DEFAULT_TEMPLATE_OPTION,
                         currentSender: i18n.t('reminders.you'),
                         sendersOptions: [
                              { value: i18n.t('reminders.you') },
                              ...formatSenders(emailSendersList),
                         ],
                    });
               }
          }, [emailTemplatesList, emailSendersList]);

         const groupTemplates = (emailTemplatesList) => {
             const organizationScope = emailTemplatesList
                 .filter((t) => t.access_type === 'organization')
                 .map((t) => ({
                     id: t.id,
                     value: t.name,
                     access_type: t.access_type,
                 }));
             const eventScope = emailTemplatesList
                 .filter((t) => t.access_type === 'event')
                 .map((t) => ({
                     id: t.id,
                     value: t.name,
                     access_type: t.access_type,
                 }));
             const onlyMeScope = emailTemplatesList
                 .filter((t) => t.access_type === 'me')
                 .map((t) => ({
                     id: t.id,
                     value: t.name,
                     access_type: t.access_type,
                 }));

             let result = [];
             result.push({
                    id: 0,
                    value: i18n.t('speakers.invitation_email_no_template')
             });

             if (organizationScope.length > 0) {
                 result.push({
                     value: i18n.t('dashboard.organization'),
                     divider: true,
                 });
                 result = result.concat(organizationScope);
             }

             if (eventScope.length > 0) {
                 result.push({
                     value: i18n.t('form_builder.event_access_level'),
                     divider: true,
                 });
                 result = result.concat(eventScope);
             }

             if (onlyMeScope.length > 0) {
                 result.push({
                     value: i18n.t('form_builder.me'),
                     divider: true,
                 });
                 result = result.concat(onlyMeScope);
             }

             if (result.length === 0) {
                 result = result.concat({value: DEFAULT_FOR_SELECT_TEMPLATE_OPTION});
             }

             return result;
         };

          const formatSenders = (senders) =>
               senders.map(({ id, name, email }) => ({
                    id,
                    value: name,
                    email,
               }));

          const handleCondition = (name, { target: { value } }) =>
               setInnerState({ ...innerState, [name]: value });

          const handleConditionOnChange = (date) =>
               setInnerState({ ...innerState, conditionOn: date });

          const handleFileUploadClick = () => fileUploadRef.current.click();
          const handleSaveAsTemplate = () =>
               setInnerState({
                    ...innerState,
                    saveAsTemplate: !innerState.saveAsTemplate,
               });

          const handleDynamicLink = ({ linkName, linkTo, title }) => {
               quillRef.current.focus();

               const quill = quillRef.current.getEditor();

               if (quill.getSelection()) {
                    quill.insertText(
                         quill.getSelection().index,
                        ` {${linkName}${title ? ` title="${title}"` : ''}} `,
                         'link',
                         `${linkTo}`
                    );
               }
          };

          const displayAttachedFiles = () => {
              return (<div className="attached-files">
                  {files && files.length > 0 && (
                      files.map(
                          (file) => (
                              <div className="item" key={file.code}>
                                  <div className="file col-md-10">
                                      {file.name}
                                  </div>
                                  <div className="file-toolbar col-md-2">
                                      <Button
                                          variant={'link'}
                                          onClick={() => {
                                              handleRemoveFile(file.code)
                                          }}
                                          onMouseEnter={() =>
                                              setActiveSpan(true)
                                          }
                                          onMouseLeave={() =>
                                              setActiveSpan(false)
                                          }
                                      >
                                          ×
                                      </Button>
                                  </div>
                              </div>
                          )
                      )
                  )}
              </div>);
          };

          const schema = () => {
              const conditions = {
                  name: yup
                      .string()
                      .required(i18n.t('validations.name_required')),
                  email: yup
                      .string()
                      .email()
                      .required(i18n.t('validations.email_required')),
                  subject: yup
                      .string()
                      .required(i18n.t('speakers.subject_required')),
                  message: yup
                      .string()
                      .required(i18n.t('speakers.message_required')),
                  language: yup
                      .string()
                      .required(i18n.t('validations.language_required')),
              };

              if (innerState.saveAsTemplate) {
                  conditions.nameOfTemplate = yup
                      .string()
                      .required(
                          i18n.t(
                              'validations.name_of_template_required'
                          )
                      );
                  conditions.accessLevel = yupMixed()
                      .oneOf(['organization', 'event'], i18n.t('validations.access_level_required'))
                      .required(i18n.t('validations.access_level_required'));
              }

              if (currentItem === 'daysAfterInvitation') {
                  conditions.daysAfterInvitation = yup.number().required(
                      i18n.t(
                          'validations.reminder_condition_after_required'
                      )
                  )
                      .min(0, i18n.t(
                          'validations.reminder_condition_after_min'
                      ))
                      .max(365, i18n.t(
                          'validations.reminder_condition_after_max'
                      ));
              } else {
                  const yesterday = new Date(Date.now() - 86400000);
                  const plusOneYear = new Date();
                  plusOneYear.setFullYear(plusOneYear.getFullYear() + 1);
                  conditions.specificDate = yup.date().required(
                      i18n.t(
                          'validations.reminder_condition_on_required'
                      )
                  )
                      .min(yesterday, i18n.t('validations.reminder_condition_on_min'))
                      .max(plusOneYear, i18n.t('validations.reminder_condition_on_max'));
              }

              return yup.object(conditions);
          };

          const DisplayCustomValidationMessage = () => {
               const formik = useFormikContext();
               React.useEffect(() => {
                    if (formik.isSubmitting && !formik.isValid) {
                         toastr.error(i18n.t('alerts.error.please_check_all_fields'));
                         document
                              .querySelector('.form-control.is-invalid')
                              .scrollIntoView({ behavior: 'smooth' });
                    }
               }, [
                 formik.isValid,
                 formik.isSubmitting
               ]);
               return null;
             };

          const getCurrentAccessLevel = key => {
               if (key === 'organization') {

                    return i18n.t('dashboard.organization');
               }

               if (key === 'event') {

                    return i18n.t('form_builder.event_access_level');
               }

               return i18n.t('form_builder.select_access_level');
          };

          return (
               <div className="tab-panel">
                    <div className="headline">
                        {title}
                    </div>
                    <span className="hint">{t('reminders.reminder_hint')}</span>
                    <Formik
                         enableReinitialize={true}
                         validationSchema={schema()}
                         onSubmit={(values) =>
                              handleSubmit({
                                   ...values,
                                   ...innerState,
                                   ...quillValue,
                                   currentItem,
                              })
                         }
                         initialValues={{
                              name:
                                  innerState.currentSender ===
                                  t('reminders.you')
                                      ? exportReminderData(language).sender.name
                                      : innerState.currentSender,
                              email:
                                  innerState.currentSender ===
                                  t('reminders.you')
                                      ? exportReminderData(language).sender.email
                                      : innerState.currentSenderEmail,
                              language: innerState.currentLanguage && innerState.currentLanguage.name,
                              subject: exportReminderData(language).subject,
                              message: exportReminderData().message,
                              nameOfTemplate: innerState.nameOfTemplate,
                              daysAfterInvitation: innerState.conditionAfter,
                              specificDate: innerState.conditionOn,
                              accessLevel: innerState.currentAccessLevel
                         }}
                         validateOnBlur={false}
                         validateOnMount={true}
                         innerRef={form}
                    >
                         {({
                              handleSubmit,
                              handleChange,
                              values,
                              touched,
                              errors,
                              setFieldValue,
                              isValid
                         }) => (
                              <Form
                                   className="mt-30"
                                   onSubmit={handleSubmit}
                                   noValidate
                              >
                                   <DisplayCustomValidationMessage/>
                                   <Form.Group as={Col} controlId="template">
                                        <Row sm={3}>
                                             <Form.Label>
                                                  {t(
                                                       'reminders.reminder_template'
                                                  )}
                                             </Form.Label>
                                        </Row>
                                        <Row sm={9}>
                                             <div className="input-group zi10">
                                                  <Dropdown>
                                                       <Dropdown.Toggle
                                                            className="select-dropdown"
                                                            as={CustomToggle}
                                                       >
                                                            {
                                                                 innerState.currentTemplateOption
                                                            }
                                                       </Dropdown.Toggle>
                                                       <Dropdown.Menu>
                                                            {innerState.templateOptions.map(
                                                                 ({
                                                                      id,
                                                                      value,
                                                                      divider,
                                                                 }) => (
                                                                      <Dropdown.Item
                                                                           key={
                                                                                id
                                                                           }
                                                                           disabled={
                                                                                divider
                                                                           }
                                                                           className={
                                                                                divider
                                                                                     ? 'dropdown-optgroup'
                                                                                     : ''
                                                                           }
                                                                           onClick={() => {
                                                                                handleChangeEmailTemplate(value, id)
                                                                           }
                                                                           }
                                                                      >
                                                                           {
                                                                                value
                                                                           }
                                                                      </Dropdown.Item>
                                                                 )
                                                            )}
                                                       </Dropdown.Menu>
                                                  </Dropdown>
                                                  <span className="suffix">
                                                       <ChevronDownIcon />
                                                  </span>
                                             </div>
                                             <Button
                                                  variant="danger"
                                                  onClick={() =>
                                                       handleRemoveTemplate(
                                                            innerState.currentTemplate,
                                                            eventId
                                                       )
                                                  }
                                                  onMouseEnter={() =>
                                                       setActiveSpan(true)
                                                  }
                                                  onMouseLeave={() =>
                                                       setActiveSpan(false)
                                                  }
                                                  disabled={isDefaultTemplate()}
                                                  style={{
                                                       display: isDefaultTemplate() ? 'none' : 'flex'
                                                  }}
                                             >
                                                  <TimesIcon
                                                       color={`${COLORS.PLACEHOLDER_GREY}`}
                                                       activeColor={`${COLORS.WHITE}`}
                                                       hovered={activeSpan}
                                                  />
                                             </Button>
                                        </Row>

                                        <Row>
                                             <div className="info-block">
                                                  {t(
                                                       'reminders.reminder_template_info'
                                                  )}
                                             </div>
                                        </Row>
                                   </Form.Group>
                                   <Form.Group as={Col} controlId="condition">
                                        <Row>
                                             <Form.Label>
                                                  {t(
                                                       'reminders.reminder_condition'
                                                  )}
                                             </Form.Label>
                                        </Row>
                                        <Row
                                             style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                             }}
                                        >
                                             <Col
                                                  sm={6}
                                                  className="rg"
                                                  style={{ padding: 0 }}
                                             >
                                                  <ReminderConditionRadioGroup
                                                       currentItem={currentItem}
                                                       setCurrentItem={
                                                            setCurrentItem
                                                       }
                                                  />
                                             </Col>
                                             {currentItem ===
                                                  'daysAfterInvitation' && (
                                                  <Col
                                                       sm={6}
                                                       style={{ padding: 0 }}
                                                  >
                                                       <Form.Control
                                                            type="number"
                                                            field="daysAfterInvitation"
                                                            name="daysAfterInvitation"
                                                            min={1}
                                                            value={
                                                                 innerState.conditionAfter
                                                            }
                                                            placeholder={t(
                                                                 'reminders.specify_number_of_days'
                                                            )}
                                                            isInvalid={
                                                                touched.daysAfterInvitation &&
                                                                !!errors.daysAfterInvitation
                                                            }
                                                            onChange={(e) =>
                                                                 handleCondition(
                                                                      'conditionAfter',
                                                                      e
                                                                 )
                                                            }
                                                       />
                                                      <Form.Control.Feedback type="invalid" style={
                                                          {position: 'absolute'}
                                                      }>
                                                          {touched.daysAfterInvitation && errors.daysAfterInvitation}
                                                      </Form.Control.Feedback>
                                                  </Col>
                                             )}
                                             {currentItem ===
                                                  'specificDate' && (
                                                  <Col
                                                       sm={6}
                                                       style={{
                                                            padding: 0,
                                                            zIndex: 9,
                                                       }}
                                                  >
                                                       <CustomDatePicker
                                                            name='specificDate'
                                                            isInvalid={
                                                                touched.specificDate &&
                                                                !!errors.specificDate
                                                            }
                                                            date={
                                                                 innerState.conditionOn
                                                            }
                                                            handleChange={(date) => {
                                                                if (date) {
                                                                    handleConditionOnChange(date);
                                                                    setFieldValue('specificDate', date);
                                                                }
                                                            }}
                                                            minDate={new Date()}
                                                            required={true}
                                                       />
                                                      <Form.Control.Feedback type="invalid" style={{
                                                          position: 'absolute',
                                                          display: touched.specificDate && !!errors.specificDate
                                                              ? 'block'
                                                              : 'none'
                                                      }}>
                                                          {touched.specificDate && errors.specificDate}
                                                      </Form.Control.Feedback>
                                                  </Col>
                                             )}
                                        </Row>
                                   </Form.Group>
                                   {innerState.multiLanguage ? (
                                       <>
                                           <Form.Group
                                               as={Col}
                                               controlId="mandatory-language"
                                           >
                                               <Row sm={3}>
                                                   <Form.Label>
                                                       {t(
                                                           'form_builder.mandatory_language'
                                                       )}
                                                   </Form.Label>
                                               </Row>
                                               <Row sm={9}>
                                                   <div className="input-group zi3">
                                                       <Dropdown>
                                                           <Dropdown.Toggle
                                                               className="select-dropdown"
                                                               as={CustomToggle}
                                                           >
                                                               {innerState.mandatoryLanguage && innerState.mandatoryLanguage.name}
                                                           </Dropdown.Toggle>
                                                           <Dropdown.Menu>
                                                               {innerState.multiLanguageSelected.map(
                                                                   (lang) => (
                                                                       <Dropdown.Item
                                                                           key={
                                                                               lang.id
                                                                           }
                                                                           onClick={() =>
                                                                               setInnerState(
                                                                                   {
                                                                                       ...innerState,
                                                                                       mandatoryLanguage: lang,
                                                                                   }
                                                                               )
                                                                           }
                                                                       >
                                                                           {
                                                                               lang.name
                                                                           }
                                                                       </Dropdown.Item>
                                                                   )
                                                               )}
                                                           </Dropdown.Menu>
                                                       </Dropdown>
                                                       <span className="suffix">
                                                                <ChevronDownIcon />
                                                           </span>
                                                   </div>
                                               </Row>
                                           </Form.Group>
                                             <div className="form-group col">
                                                  <Row>
                                                       <Form.Label>
                                                            {t(
                                                                 'form_builder.languages'
                                                            )}
                                                       </Form.Label>
                                                  </Row>
                                                  <div
                                                       style={{
                                                            margin: '0 -15px',
                                                       }}
                                                  >
                                                       <TagField
                                                            withIds={true}
                                                            suggestions={
                                                                 innerState.languageOptions
                                                            }
                                                            value={transformValues()}
                                                            readOnlyValues={
                                                                innerState.mandatoryLanguage && innerState.mandatoryLanguage.name
                                                            }
                                                            onChange={handleLanguageTag}
                                                       />
                                                  </div>
                                             </div>
                                        </>
                                   ) : (
                                        <Form.Group
                                             as={Col}
                                             controlId="language"
                                        >
                                             <Row sm={3}>
                                                  <Form.Label>
                                                       {t(
                                                            'form_builder.language'
                                                       )}
                                                  </Form.Label>
                                             </Row>
                                             <Row sm={9}>
                                                  <div className="input-group zi2">
                                                       <Dropdown>
                                                            <Dropdown.Toggle
                                                                 className="select-dropdown"
                                                                 as={
                                                                      CustomToggle
                                                                 }
                                                            >
                                                                 {
                                                                      innerState.currentLanguage && innerState.currentLanguage.name
                                                                 }
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                 {innerState.languageOptions.map(
                                                                      (lang) => (
                                                                           <Dropdown.Item
                                                                           disabled={innerState.currentLanguage && innerState.currentTemplate && innerState.currentLanguage.id !== lang.id}
                                                                                key={
                                                                                     lang.id
                                                                                }
                                                                                onClick={() =>
                                                                                     setInnerState(
                                                                                          {
                                                                                               ...innerState,
                                                                                               currentLanguage: lang,
                                                                                               mandatoryLanguage: lang,
                                                                                               multiLanguageSelected: [lang]
                                                                                          }
                                                                                     )
                                                                                }
                                                                           >
                                                                                {
                                                                                     lang.name
                                                                                }
                                                                           </Dropdown.Item>
                                                                      )
                                                                 )}
                                                            </Dropdown.Menu>
                                                       </Dropdown>
                                                       <span className="suffix">
                                                            <ChevronDownIcon />
                                                       </span>
                                                  </div>
                                             </Row>
                                        </Form.Group>
                                   )}
                                   <Form.Group sm={3} controlId="multiLanguage">
                                        <div
                                             className="checkbox-solid"
                                             onClick={handleMultiLanguage}
                                        >
                                             <input
                                                  type="checkbox"
                                                  checked={
                                                       innerState.multiLanguage
                                                  }
                                                  onChange={handleMultiLanguage}
                                             />
                                             <span />

                                             <Form.Label>
                                                  {t(
                                                       'reminders.multi_language_reminder'
                                                  )}
                                             </Form.Label>
                                        </div>
                                   </Form.Group>
                                   <Row>
                                        <Form.Group
                                             as={Col}
                                             sm={4}
                                             controlId="sender"
                                        >
                                             <Form.Label>
                                                  {t('reminders.sender')}
                                             </Form.Label>
                                             <div className="input-group">
                                                  <Dropdown>
                                                       <Dropdown.Toggle
                                                            className="select-dropdown"
                                                            as={CustomToggle}
                                                       >
                                                            {
                                                                 innerState.currentSender
                                                            }
                                                       </Dropdown.Toggle>
                                                       <Dropdown.Menu>
                                                            {innerState.sendersOptions.map(
                                                                 ({
                                                                      value,
                                                                      email,
                                                                 }) => (
                                                                      <Dropdown.Item
                                                                           key={
                                                                                value
                                                                           }
                                                                           onClick={() => {
                                                                               if (value === t('reminders.you')) {
                                                                                   setInnerState(
                                                                                       {
                                                                                           ...innerState,
                                                                                           currentSender: t('reminders.you'),
                                                                                           currentSenderEmail: currentUser && currentUser.email,
                                                                                           subject:  values.subject,
                                                                                       }
                                                                                   );
                                                                                   handleCustomSender({
                                                                                       name: currentUser && `${currentUser.firstname} ${currentUser.lastname}`,
                                                                                       email: currentUser && currentUser.email
                                                                                   });
                                                                               } else {
                                                                                   setInnerState(
                                                                                       {
                                                                                           ...innerState,
                                                                                           currentSender: value,
                                                                                           currentSenderEmail:
                                                                                           email,
                                                                                           subject:
                                                                                           values.subject,
                                                                                       }
                                                                                   );

                                                                                   handleCustomSender({
                                                                                       name: value,
                                                                                       email: email
                                                                                   });
                                                                               }
                                                                           }}
                                                                      >
                                                                           {
                                                                                value
                                                                           }
                                                                      </Dropdown.Item>
                                                                 )
                                                            )}
                                                       </Dropdown.Menu>
                                                  </Dropdown>
                                                  <span className="suffix">
                                                       <ChevronDownIcon />
                                                  </span>
                                             </div>
                                        </Form.Group>
                                        <Form.Group
                                             as={Col}
                                             sm={4}
                                             controlId="name"
                                        >
                                             <Form.Label>
                                                  {t(
                                                       'organization_settings.from_name'
                                                  )}
                                             </Form.Label>
                                             <div className="input-group">
                                                  <Form.Control
                                                       disabled={true}
                                                       type="text"
                                                       name="name"
                                                       value={values.name}
                                                       onChange={(e) => {
                                                            handleSenderName(e.target.value, language);
                                                            handleChange(e);
                                                       }}
                                                       isInvalid={
                                                            touched.name &&
                                                            !!errors.name
                                                       }
                                                       className={
                                                            innerState.multiLanguage &&
                                                            isEmpty(exportReminderData(language).sender.name)
                                                                ? 'is-warning' : ''
                                                       }
                                                  />
                                                  <Form.Control.Feedback type="invalid">
                                                       {touched.name &&
                                                            errors.name}
                                                  </Form.Control.Feedback>
                                                 <span className="suffix">
                                                        <UserRegularIcon />
                                                   </span>
                                             </div>
                                        </Form.Group>
                                        <Form.Group
                                             as={Col}
                                             sm={4}
                                             controlId="email"
                                        >
                                             <Form.Label>
                                                  {t(
                                                       'organization_settings.from_email'
                                                  )}
                                             </Form.Label>
                                             <div className="input-group">
                                                  <Form.Control
                                                       disabled={true}
                                                       type="email"
                                                       name="email"
                                                       value={values.email}
                                                       onChange={(e) => {
                                                            handleSenderEmail(e.target.value, language);
                                                            handleChange(e);
                                                       }}
                                                       isInvalid={
                                                            touched.email &&
                                                            !!errors.email
                                                       }
                                                       className={
                                                            innerState.multiLanguage &&
                                                            isEmpty(exportReminderData(language).sender.email)
                                                                ? 'is-warning' : ''
                                                       }
                                                  />
                                                  <Form.Control.Feedback type="invalid">
                                                       {touched.email &&
                                                            errors.email}
                                                  </Form.Control.Feedback>
                                                 <span className="suffix">
                                                        <EmailIcon />
                                                   </span>
                                             </div>
                                        </Form.Group>
                                   </Row>
                                   <Form.Group as={Col} controlId="subject">
                                        <Row sm={3}>
                                             <Form.Label>
                                                  {t('reminders.subject_line')}
                                             </Form.Label>
                                        </Row>
                                        <Row sm={9}>
                                             <Form.Control
                                                  type="text"
                                                  default=""
                                                  name="subject"
                                                  value={values.subject}
                                                  placeholder={t(
                                                       'reminders.subject_placeholder'
                                                  )}
                                                  onChange={(e) => {
                                                       handleSubjectChange(e.target.value);
                                                       handleChange(e);
                                                  }}
                                                  isInvalid={
                                                       touched.subject &&
                                                       !!errors.subject
                                                  }
                                                  className={
                                                       innerState.multiLanguage &&
                                                       isEmpty(exportReminderData().subject)
                                                           ? 'is-warning' : ''
                                                  }
                                             />
                                             <Form.Control.Feedback type="invalid">
                                                  {touched.subject &&
                                                       errors.subject}
                                             </Form.Control.Feedback>
                                        </Row>
                                        <Row>
                                             <div className="info-block">
                                                  {t('reminders.subject_hint')}
                                             </div>
                                        </Row>
                                   </Form.Group>
                                   <Form.Group
                                        as={Col}
                                        controlId="dinamicContent"
                                   >
                                        <Row sm={3}>
                                             <Form.Label>
                                                  {t(
                                                       'reminders.dynamic_content'
                                                  )}
                                             </Form.Label>
                                        </Row>
                                        <Row sm={9} className="button-group">
                                             <Button
                                                  className="dynamic-content"
                                                  variant="outline-primary"
                                                  onClick={() =>
                                                       handleDynamicLink({
                                                            linkName:
                                                                 'edit_button',
                                                            linkTo: '',
                                                            title: t('common.click_here')
                                                       })
                                                  }
                                             >
                                                  {t(
                                                       'reminders.buttons.edit_button'
                                                  )}
                                             </Button>
                                             <Button
                                                  className="dynamic-content"
                                                  variant="outline-primary"
                                                  onClick={() =>
                                                       handleDynamicLink({
                                                            linkName:
                                                                 'edit_link',
                                                            linkTo: '',
                                                            title: t('common.click_here')
                                                       })
                                                  }
                                             >
                                                  {t(
                                                       'reminders.buttons.edit_link'
                                                  )}
                                             </Button>
                                             <Button
                                                  className="dynamic-content"
                                                  variant="outline-primary"
                                                  onClick={() =>
                                                       handleDynamicLink({
                                                            linkName:
                                                                 'full_name',
                                                            linkTo: '',
                                                       })
                                                  }
                                             >
                                                  {t(
                                                       'reminders.buttons.full_name'
                                                  )}
                                             </Button>
                                             <Button
                                                  className="dynamic-content"
                                                  variant="outline-primary"
                                                  onClick={() =>
                                                       handleDynamicLink({
                                                            linkName:
                                                                 'first_name',
                                                            linkTo: '',
                                                       })
                                                  }
                                             >
                                                  {t(
                                                       'reminders.buttons.first_name'
                                                  )}
                                             </Button>
                                             <Button
                                                  className="dynamic-content"
                                                  variant="outline-primary"
                                                  onClick={() =>
                                                       handleDynamicLink({
                                                            linkName:
                                                                 'last_name',
                                                            linkTo: '',
                                                       })
                                                  }
                                             >
                                                  {t(
                                                       'reminders.buttons.last_name'
                                                  )}
                                             </Button>
                                        </Row>
                                        <Row>
                                            <div className="info-block">
                                                {t(
                                                    'reminders.dynamic_content_hint'
                                                )}
                                            </div>
                                        </Row>
                                   </Form.Group>
                                   <Form.Group>
                                        <ReactQuill
                                             ref={quillRef}
                                             theme="snow"
                                             value={quillValue}
                                             placeholder={t(
                                                  'reminders.type_reminder_message'
                                             )}
                                             formats={['background', 'bold', 'color', 'font', 'code', 'italic', 'link', 'size', 'strike', 'script', 'underline', 'blockquote', 'header', 'indent', 'list', 'align', 'direction', 'code-block']}
                                             onChange={setQuillValue}
                                             className={
                                                 innerState.multiLanguage &&
                                                 isEmpty(exportReminderData().message.replace(/<\/?[^>]+(>|$)/g, ""))
                                                     ? 'is-warning'
                                                     : (touched.message && errors.message ? 'quill-is-invalid' : '')
                                             }
                                        />
                                       {touched.message && errors.message && (
                                           <Form.Control.Feedback type="invalid" style={{display: 'block'}}>
                                               {errors.message}
                                           </Form.Control.Feedback>
                                       )}
                                   </Form.Group>
                                   <Form.Group
                                        as={Col}
                                        controlId="fileAttachments"
                                   >
                                        <Row sm={3}>
                                             <Form.Label>
                                                  {t(
                                                       'reminders.file_attachments'
                                                  )}
                                             </Form.Label>
                                        </Row>
                                        <Row sm={9}>
                                             <Button
                                                  className="btn-themed-blue"
                                                  onClick={
                                                       handleFileUploadClick
                                                  }
                                                  style={{
                                                       margin: 0,
                                                  }}
                                             >
                                                  {t(
                                                       'form_builder.attach_files'
                                                  )}
                                                  {uploadFileRequested && (<Spinner animation="border" className="m-0 ml-2 p-0" size="sm" />)}
                                             </Button>
                                             <input
                                                  multiple
                                                  type="file"
                                                  value=""
                                                  ref={fileUploadRef}
                                                  onChange={({
                                                       target: { files },
                                                  }) =>
                                                       handleAttachFiles(files)
                                                  }
                                                  style={{
                                                       display: 'none',
                                                  }}
                                             />
                                        </Row>
                                        <Row>
                                            {displayAttachedFiles()}
                                        </Row>
                                        <Row>
                                             <div className="info-block">
                                                  {t(
                                                       'reminders.file_attachments_hint'
                                                  )}
                                             </div>
                                        </Row>
                                   </Form.Group>
                                   <Row
                                        style={{
                                             marginBottom: '14px',
                                             alignItems: 'center',
                                        }}
                                   >
                                        <Form.Group
                                             as={Col}
                                             sm={4}
                                             controlId="saveAsTemplate"
                                        >
                                             <div
                                                  className="checkbox-solid"
                                                  style={{
                                                       marginBottom: '0',
                                                  }}
                                                  onClick={handleSaveAsTemplate}
                                             >
                                                  <input
                                                       type="checkbox"
                                                       checked={
                                                            innerState.saveAsTemplate
                                                       }
                                                       onChange={
                                                            handleSaveAsTemplate
                                                       }
                                                  />
                                                  <span />

                                                  <Form.Label>
                                                       {t(
                                                            'reminders.save_as_template'
                                                       )}
                                                  </Form.Label>
                                             </div>
                                        </Form.Group>
                                        <Form.Group
                                             as={Col}
                                             sm={4}
                                             controlId="nameOfTemplate"
                                             style={{
                                                  position: 'relative',
                                             }}
                                        >
                                             <Form.Control
                                                  type="text"
                                                  default=""
                                                  name="nameOfTemplate"
                                                  values={values.nameOfTemplate}
                                                  placeholder={t(
                                                       'reminders.name_your_template'
                                                  )}
                                                  onChange={(event) => {
                                                      handleChange(event);
                                                      setInnerState({
                                                          ...innerState,
                                                          nameOfTemplate: event.target.value
                                                      });
                                                  }}
                                                  isInvalid={
                                                       touched.nameOfTemplate &&
                                                       !!errors.nameOfTemplate
                                                  }
                                                  disabled={
                                                       !innerState.saveAsTemplate
                                                  }
                                             />
                                             <Form.Control.Feedback
                                                  type="invalid"
                                                  style={{
                                                       position: 'absolute',
                                                       top: '41px',
                                                  }}
                                             >
                                                  {touched.nameOfTemplate &&
                                                       errors.nameOfTemplate}
                                             </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group
                                             as={Col}
                                             sm={4}
                                             controlId="accessLevel"
                                        >
                                             <div className="input-group">
                                                  <Dropdown
                                                      className={`select-dropdown ${
                                                          !innerState.saveAsTemplate
                                                              ? 'disabled'
                                                              : ''
                                                      } ${touched.accessLevel && errors.accessLevel
                                                          ? 'is-invalid'
                                                          : ''
                                                      }`}
                                                       style={{
                                                            pointerEvents: !innerState.saveAsTemplate
                                                                 ? 'none'
                                                                 : '',
                                                       }}
                                                  >
                                                       <Dropdown.Toggle
                                                            as={CustomToggle}
                                                       >
                                                            {getCurrentAccessLevel(innerState.currentAccessLevel)}
                                                       </Dropdown.Toggle>
                                                       <Dropdown.Menu>
                                                            {innerState.accessLevelOptions.map(
                                                                 ({value, key}) => (
                                                                      <Dropdown.Item
                                                                           key={key}
                                                                           onClick={() =>
                                                                                setInnerState(
                                                                                     {
                                                                                          ...innerState,
                                                                                          currentAccessLevel: key,
                                                                                     }
                                                                                )
                                                                           }
                                                                      >
                                                                           {
                                                                                value
                                                                           }
                                                                      </Dropdown.Item>
                                                                 )
                                                            )}
                                                       </Dropdown.Menu>
                                                      <div className="invalid-feedback" style={{
                                                          display: touched.accessLevel && errors.accessLevel
                                                              ? 'block'
                                                              : 'none',
                                                          position: 'absolute'
                                                      }}>
                                                          {touched.accessLevel && errors.accessLevel}
                                                      </div>
                                                  </Dropdown>
                                                  <span
                                                       className={`suffix ${
                                                            !innerState.saveAsTemplate
                                                                 ? 'disabled'
                                                                 : ''
                                                       }`}
                                                  >
                                                       <ChevronDownIcon />
                                                  </span>
                                             </div>
                                        </Form.Group>
                                   </Row>
                                   <div className="actions-section">
                                       {innerState.multiLanguage && !isValidReminderData() && (
                                           <Row>
                                               <Alert
                                                   key={'missing-translations'}
                                                   variant={'outline-danger'}
                                               >
                                                   {t('speakers.invitation_email_missing_translations')}
                                               </Alert>
                                           </Row>
                                       )}
                                       {!isEnteredLinks() && (
                                           <Row>
                                               <Alert
                                                   key={'missing-links'}
                                                   variant={'outline-danger'}
                                               >
                                                   {
                                                       innerState.multiLanguage ?
                                                           t('validations.dynamic_content_reminder_multilingual') :
                                                        t('validations.dynamic_content_reminder')
                                                   }
                                               </Alert>
                                           </Row>
                                       )}
                                       {shouldFilesBeTranslated() && (
                                           <Row>
                                               <Alert
                                                   key={'missing-files'}
                                                   variant={'outline-warning'}
                                               >
                                                   {
                                                       t('validations.dynamic_content_reminder_files')
                                                   }
                                               </Alert>
                                           </Row>
                                       )}
                                       <Row className='buttons'>
                                            <Button variant="success" type="submit" disabled={!isEnteredLinks() || uploadFileRequested || copyReminderRequested}>
                                                 {t('reminders.set_reminder')}
                                            </Button>
                                       </Row>
                                   </div>
                              </Form>
                         )}
                    </Formik>
               </div>
          );
     };
