import React from 'react';

function IconCalendar() {
     return (
         <svg
             width="15"
             height="15"
             viewBox="0 0 15 15"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
              <path
                  fill="currentColor"
                  d="M6.87501 12.3437C6.86311 12.3437 6.85121 12.3437 6.83931 12.3424C6.70312 12.3319 6.57815 12.2624 6.4969 12.1519L4.77812 9.80813C4.62499 9.59939 4.67066 9.30619 4.8794 9.15307C5.08745 9.00063 5.38122 9.04561 5.53434 9.25435L6.92994 11.1575L10.1375 7.94995C10.3206 7.76685 10.6174 7.76685 10.8006 7.94995C10.9837 8.13306 10.9837 8.42992 10.8006 8.61314L7.20689 12.2068C7.11808 12.2944 6.99872 12.3437 6.87501 12.3437Z"
              />
              <path
                  fill="currentColor"
                  d="M13.2812 15H1.71879C0.770645 15 0 14.2294 0 13.2812V2.96873C0 2.02059 0.770645 1.25006 1.71879 1.25006H13.2812C14.2294 1.25006 15 2.02059 15 2.96873V13.2812C15 14.2294 14.2294 15 13.2812 15ZM1.71879 2.18756C1.28815 2.18756 0.9375 2.53809 0.9375 2.96873V13.2812C0.9375 13.7119 1.28815 14.0625 1.71879 14.0625H13.2812C13.7119 14.0625 14.0625 13.7119 14.0625 13.2812V2.96873C14.0625 2.53809 13.7119 2.18756 13.2812 2.18756H1.71879Z" />
              <path
                  fill="currentColor"
                  d="M14.5312 5.93756H0.46875C0.209999 5.93756 0 5.72756 0 5.46881C0 5.21006 0.209999 5.00006 0.46875 5.00006H14.5312C14.79 5.00006 15 5.21006 15 5.46881C15 5.72756 14.79 5.93756 14.5312 5.93756Z"
              />
              <path
                  fill="currentColor"
                  d="M3.59375 3.75C3.335 3.75 3.125 3.54 3.125 3.28125V0.46875C3.125 0.209999 3.335 0 3.59375 0C3.8525 0 4.0625 0.209999 4.0625 0.46875V3.28125C4.0625 3.54 3.8525 3.75 3.59375 3.75Z"
              />
              <path
                  fill="currentColor"
                  d="M11.4062 3.75C11.1475 3.75 10.9375 3.54 10.9375 3.28125V0.46875C10.9375 0.209999 11.1475 0 11.4062 0C11.665 0 11.875 0.209999 11.875 0.46875V3.28125C11.875 3.54 11.665 3.75 11.4062 3.75Z"
              />
         </svg>

     );
}

export default IconCalendar;
