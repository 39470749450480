import React, {useState, useEffect, memo} from 'react';
import { Button, Dropdown, Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../../../../constants/style.constants';
import UserRegularIcon from '../../../../assets/icons/metronic/user-regular';
import EmailIcon from '../../../../assets/icons/metronic/email-icon';
import TimesIcon from '../../../../assets/icons/metronic/times-icon';
import ChevronDownIcon from '../../../../assets/icons/metronic/chevron-down-icon';
import CustomToggle from '../../../common/custom-toggle';
import {connect} from "react-redux";

const mapStateToProps = (state) => ({
     languages: state.userReducer.languages,
});

export const _SpeakerRow = memo(({
     speakerID,
     name,
     email,
     language,
     handleFieldChange,
     onSpeakerRemove,
     showRemoveButton,
     organizationLanguage,
     languages,
     zIndex,
     index,
     errors
}) => {
     const { t } = useTranslation();

     const [activeSpan, setActiveSpan] = useState(false);

     const [rowState, setRowState] = useState({
          id: speakerID,
          name,
          email,
          language_id: language,
          errors,
     });

     const [innerState, setInnerState] = useState({
          languageOptions: [],
          currentLanguage: {
               id: null,
               name: null,
               code: null
          },
     });

     useEffect(() => {
          if (languages.length > 0 && organizationLanguage) {
               setInnerState({
                    ...innerState,
                    languageOptions: languages.map(lang => ({...lang, value: lang.name})),
                    currentLanguage: languages.find(lang => lang.id === language)
               });
          }
     }, [languages, organizationLanguage]);

     const handleInputChange = ({ target: { name, value } }) => {
          setRowState({ ...rowState, [name]: value });
          handleFieldChange({ id: speakerID, newSpeaker: {
                    ...rowState,
                    [name]: value
               }, index });
     };

     const nameError = errors['name'];
     const emailError = errors['email'];

     return (
          <Row
               className="speaker-row"
               style={{ marginBottom: '20px', position: 'relative', zIndex }}
          >
               <Form.Group as={Col} sm={4} controlId="name">
                    <Form.Label className="mobile-label">
                         {t('form_builder.type_options.full_name')}
                    </Form.Label>
                    <div className="input-group">
                         <Form.Control
                              isInvalid={nameError}
                              type="text"
                              className="form-control"
                              placeholder={t('speakers.name_of_speaker')}
                              onChange={handleInputChange}
                              value={rowState.name}
                              name="name"
                         />

                         {!nameError && (
                              <span className="suffix">
                                   <UserRegularIcon />
                              </span>
                         )}
                         <Form.Control.Feedback
                              type="invalid"
                              style={{ marginTop: '2px' }}
                         >
                              {nameError}
                         </Form.Control.Feedback>
                    </div>
               </Form.Group>
               <Form.Group as={Col} sm={4} controlId="email">
                    <Form.Label className="mobile-label">
                         {t('user_settings.settings_form.email_address')}
                    </Form.Label>
                    <div className="input-group">
                         <Form.Control
                              isInvalid={emailError}
                              type="email"
                              className="form-control"
                              placeholder={t('speakers.email_of_speaker')}
                              onChange={handleInputChange}
                              value={rowState.email}
                              name="email"
                         />
                         {!emailError && (
                              <span className="suffix">
                                   <EmailIcon />
                              </span>
                         )}
                         <Form.Control.Feedback
                              type="invalid"
                              style={{ marginTop: '2px' }}
                         >
                              {emailError}
                         </Form.Control.Feedback>
                    </div>
               </Form.Group>
               <Form.Group as={Col} sm={4} controlId="name">
                    <Form.Label className="mobile-label">
                         {t('form_builder.language')}
                    </Form.Label>
                    <div className="input-group">
                         <Dropdown flip={false}>
                              <Dropdown.Toggle
                                   className="select-dropdown"
                                   as={CustomToggle}
                              >
                                   {innerState.currentLanguage && innerState.currentLanguage.name}
                              </Dropdown.Toggle>
                              <Dropdown.Menu flip={false}>
                                   {innerState.languageOptions.map(
                                        (lang) => (
                                             <Dropdown.Item
                                                  key={lang.id}
                                                  onClick={() => {
                                                       setInnerState({
                                                            ...innerState,
                                                            currentLanguage: lang,
                                                       });

                                                       handleInputChange({
                                                            target: {
                                                                 name:
                                                                      'language_id',
                                                                 value: lang.id
                                                            },
                                                       });
                                                  }}
                                             >
                                                  {lang.name}
                                             </Dropdown.Item>
                                        )
                                   )}
                              </Dropdown.Menu>
                         </Dropdown>

                         <span className="suffix">
                              <ChevronDownIcon />
                         </span>
                    </div>
               </Form.Group>
               {showRemoveButton && (
                    <Button
                         variant="danger"
                         onMouseEnter={() => setActiveSpan(true)}
                         onMouseLeave={() => setActiveSpan(false)}
                         onClick={() => onSpeakerRemove(speakerID)}
                         style={{
                              position: 'absolute',
                              right: '-30px',
                         }}
                    >
                         <TimesIcon
                              color={`${COLORS.PLACEHOLDER_GREY}`}
                              activeColor={`${COLORS.WHITE}`}
                              hovered={activeSpan}
                         />
                    </Button>
               )}
          </Row>
     );
});

export const SpeakerRow = connect(mapStateToProps)(_SpeakerRow);
