import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SettingsImage } from '../../../../assets/icons/metronic/settings-image';
import SpeakersIcon from '../../../../assets/icons/metronic/speakers-icon';
import {
     StyledDropdown,
     NavigationalDropdownWrapper,
} from '../../../common/dropdown/Dropdown.style';
import {ReminderIcon} from "../../../../assets/icons/metronic/reminders-icon";

const mapStateToProps = (state) => ({
     eventData: state.eventReducer.eventData,
});

export const _EventDropdown = (props) => {
     const { eventData } = props;

     const { t } = useTranslation();
     const { id, eventId } = useParams();

     const [show, setShow] = useState(false);
     const [inverseIcon, setInverseIcon] = useState(false);

     return (
          <NavigationalDropdownWrapper>
               <StyledDropdown>
                    <StyledDropdown.Toggle
                         className="btn-themed-blue btn-navigational"
                         onToggle={() => setShow(true)}
                    >
                         {eventData && eventData.name}
                    </StyledDropdown.Toggle>
                    <StyledDropdown.Menu show={show}>
                         <StyledDropdown.Item
                              href={`/organization/${id}/events/${eventId}/speakers`}
                         >
                              <SpeakersIcon />
                              <div className="text">{t('event.speakers')}</div>
                         </StyledDropdown.Item>
                         <StyledDropdown.Item
                             href={`/organization/${id}/events/${eventId}/reminders`}
                             onMouseEnter={() => setInverseIcon(true)}
                             onMouseLeave={() => setInverseIcon(false)}
                         >
                              <ReminderIcon inverse={inverseIcon} />
                              <div className="text">{t('reminders.reminders')}</div>
                         </StyledDropdown.Item>
                         <StyledDropdown.Item
                              href={`/organization/${id}/events/${eventId}/event-edit`}
                         >
                              <SettingsImage />
                              <div className="text">
                                   {t('event.event_settings')}
                              </div>
                         </StyledDropdown.Item>
                    </StyledDropdown.Menu>
               </StyledDropdown>
          </NavigationalDropdownWrapper>
     );
};

export const EventDropdown = connect(mapStateToProps, null)(_EventDropdown);
