import * as ACTIONS from '../actions';

export const resetToInitialState = () => ({
     type: ACTIONS.RESET_USER_TO_INITIAL_STATE,
});

export const userClearMessages = () => ({
     type: ACTIONS.USER_CLEAR_MESSAGES,
});

export const requestUserAuthorized = () => ({
     type: ACTIONS.USER_AUTHORIZED_REQUEST,
});

export const successUserAuthorized = (payload) => ({
     type: ACTIONS.USER_AUTHORIZED_SUCCESS,
     payload,
});

export const failUserAuthorized = (error) => ({
     type: ACTIONS.USER_AUTHORIZED_FAILURE,
     error,
});

export const requestUserProfile = () => ({
     type: ACTIONS.USER_PROFILE_REQUEST,
});

export const successUserProfile = (payload) => ({
     type: ACTIONS.USER_PROFILE_SUCCESS,
     payload,
});

export const failUserProfile = (error) => ({
     type: ACTIONS.USER_PROFILE_FAILURE,
     error,
});

export const requestUserAvatarUpload = (payload) => ({
     type: ACTIONS.USER_AVATAR_UPLOAD_REQUEST,
     payload,
});

export const successUserAvatarUpload = (payload) => ({
     type: ACTIONS.USER_AVATAR_UPLOAD_SUCCESS,
     payload,
});

export const failUserAvatarUpload = (error) => ({
     type: ACTIONS.USER_AVATAR_UPLOAD_FAILURE,
     error,
});

export const requestUserAvatarGenerateTemp = (payload) => ({
     type: ACTIONS.USER_AVATAR_GENERATE_TEMP_REQUEST,
     payload,
});

export const successUserAvatarGenerateTemp = (payload) => ({
     type: ACTIONS.USER_AVATAR_GENERATE_TEMP_SUCCESS,
     payload,
});

export const failUserAvatarGenerateTemp = (error) => ({
     type: ACTIONS.USER_AVATAR_GENERATE_TEMP_FAILURE,
     error,
});

export const requestUserProfileUpdate = (payload) => ({
     type: ACTIONS.UPDATE_USER_PROFILE_REQUEST,
     payload,
});

export const successUserProfileUpdate = (payload) => ({
     type: ACTIONS.UPDATE_USER_PROFILE_SUCCESS,
     payload,
});

export const failUserProfileUpdate = (error) => ({
     type: ACTIONS.UPDATE_USER_PROFILE_FAILURE,
     error,
});

export const requestChangeUserPassword = (payload) => ({
     type: ACTIONS.CHANGE_USER_PASSWORD_REQUEST,
     payload,
});

export const successChangeUserPassword = (payload) => ({
     type: ACTIONS.CHANGE_USER_PASSWORD_SUCCESS,
     payload,
});

export const failChangeUserPassword = (error) => ({
     type: ACTIONS.CHANGE_USER_PASSWORD_FAILURE,
     error,
});

export const requestChangeUserEmail = (payload) => ({
     type: ACTIONS.CHANGE_USER_EMAIL_REQUEST,
     payload,
});

export const successChangeUserEmail = (payload) => ({
     type: ACTIONS.CHANGE_USER_EMAIL_SUCCESS,
     payload,
});

export const failChangeUserEmail = (error) => ({
     type: ACTIONS.CHANGE_USER_EMAIL_FAILURE,
     error,
});

export const refreshCurrentUserAvatar = (payload) => ({
     type: ACTIONS.REFRESH_CURRENT_USER_AVATAR,
     payload,
});

export const requestRemoveAvatar = () => ({
     type: ACTIONS.REMOVE_AVATAR_REQUEST,
});

export const successRemoveAvatar = (payload) => ({
     type: ACTIONS.REMOVE_AVATAR_SUCCESS,
     payload,
});

export const failRemoveAvatar = (error) => ({
     type: ACTIONS.REMOVE_AVATAR_FAILURE,
     error,
});

export const requestAvailableLanguages = () => ({
     type: ACTIONS.AVAILABLE_LANGUAGES_REQUEST,
});

export const successAvailableLanguages = (payload) => ({
     type: ACTIONS.AVAILABLE_LANGUAGES_SUCCESS,
     payload,
});

export const failAvailableLanguages = (error) => ({
     type: ACTIONS.AVAILABLE_LANGUAGES_FAILURE,
     error,
});

export const requestContactUs = (payload) => ({
     type: ACTIONS.CONTACT_US_REQUEST,
     payload,
});

export const successContactUs = (payload) => ({
     type: ACTIONS.CONTACT_US_SUCCESS,
     payload,
});

export const failContactUs = (error) => ({
     type: ACTIONS.CONTACT_US_FAILURE,
     error,
});

export const INITIAL_STATE = {
     userProfileRequested: false,
     userProfileSucceeded: false,
     userProfileFailed: false,
     userProfileUpdateRequested: false,
     userProfileUpdateSucceeded: false,
     userProfileUpdateFailed: false,
     changeUserPasswordRequested: false,
     changeUserPasswordSucceeded: false,
     changeUserPasswordFailed: false,
     changeUserEmailRequested: false,
     changeUserEmailSucceeded: false,
     changeUserEmailFailed: false,
     userAuthorizedRequested: false,
     userAuthorizedSucceeded: false,
     userAuthorizedFailed: false,
     userAvatarUploadRequested: false,
     userAvatarUploadSucceeded: false,
     userAvatarUploadFailed: false,
     userAvatarGenerateTempRequested: false,
     userAvatarGenerateTempSucceeded: false,
     userAvatarGenerateTempFailed: false,
     refreshCurrentUserAvatar: false,
     removeAvatarRequested: false,
     removeAvatarSucceeded: false,
     removeAvatarFailed: false,
     availableLanguagesRequested: false,
     availableLanguagesSucceeded: false,
     availableLanguagesFailed: false,
     contactUsRequested: false,
     contactUsSucceeded: false,
     contactUsFailed: false,
     authorized: null,
     message: null,
     error: null,
     currentUser: null,
     userGeneratedAvatar: null,
     languages: [],
};

export default function userReducer(state = INITIAL_STATE, action) {
     switch (action.type) {
          case ACTIONS.RESET_USER_TO_INITIAL_STATE: {
               return { ...INITIAL_STATE, languages: state.languages };
          }
          case ACTIONS.USER_CLEAR_MESSAGES:
               return {
                    ...state,
                    message: null,
                    error: null,
               };
          case ACTIONS.USER_AUTHORIZED_REQUEST:
               return {
                    ...state,
                    userAuthorizedRequested: true,
                    userAuthorizedSucceeded: false,
                    userAuthorizedFailed: false,
               };
          case ACTIONS.USER_AUTHORIZED_SUCCESS:
               return {
                    ...state,
                    userAuthorizedRequested: false,
                    userAuthorizedSucceeded: true,
                    userAuthorizedFailed: false,
                    authorized: true,
                    error: null,
               };
          case ACTIONS.USER_AUTHORIZED_FAILURE:
               return {
                    ...state,
                    userAuthorizedRequested: false,
                    userAuthorizedSucceeded: false,
                    userAuthorizedFailed: true,
                    error: action.error,
                    authorized: false,
               };
          case ACTIONS.USER_PROFILE_REQUEST:
               return {
                    ...state,
                    userProfileRequested: true,
                    userProfileSucceeded: false,
                    userProfileFailed: false,
               };
          case ACTIONS.USER_PROFILE_SUCCESS:
               return {
                    ...state,
                    userProfileRequested: false,
                    userProfileSucceeded: true,
                    userProfileFailed: false,
                    currentUser: action.payload,
                    error: null,
               };
          case ACTIONS.USER_PROFILE_FAILURE:
               return {
                    ...state,
                    userProfileRequested: false,
                    userProfileSucceeded: false,
                    userProfileFailed: true,
                    error: action.error,
                    currentUser: null,
               };
          case ACTIONS.UPDATE_USER_PROFILE_REQUEST:
               return {
                    ...state,
                    userProfileUpdateRequested: true,
                    userProfileUpdateSucceeded: false,
                    userProfileUpdateFailed: false,
                    message: null,
                    error: null,
               };
          case ACTIONS.UPDATE_USER_PROFILE_SUCCESS:
               return {
                    ...state,
                    userProfileUpdateRequested: false,
                    userProfileUpdateSucceeded: true,
                    refreshCurrentUserAvatar: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.UPDATE_USER_PROFILE_FAILURE:
               return {
                    ...state,
                    userProfileUpdateRequested: false,
                    userProfileUpdateSucceeded: false,
                    userProfileUpdateFailed: true,
                    refreshCurrentUserAvatar: false,
                    message: null,
                    error: action.error,
               };
          case ACTIONS.CHANGE_USER_PASSWORD_REQUEST:
               return {
                    ...state,
                    changeUserPasswordRequested: true,
                    changeUserPasswordSucceeded: false,
                    changeUserPasswordFailed: false,
                    message: null,
                    error: null,
               };
          case ACTIONS.CHANGE_USER_PASSWORD_SUCCESS:
               return {
                    ...state,
                    changeUserPasswordRequested: false,
                    changeUserPasswordSucceeded: true,
                    changeUserPasswordFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.CHANGE_USER_PASSWORD_FAILURE:
               return {
                    ...state,
                    changeUserPasswordRequested: false,
                    changeUserPasswordSucceeded: false,
                    changeUserPasswordFailed: true,
                    message: null,
                    errors: action.error,
               };
          case ACTIONS.CHANGE_USER_EMAIL_REQUEST:
               return {
                    ...state,
                    changeUserEmailRequested: true,
                    changeUserEmailSucceeded: false,
                    changeUserEmailFailed: false,
                    message: null,
                    error: null,
               };
          case ACTIONS.CHANGE_USER_EMAIL_SUCCESS:
               return {
                    ...state,
                    changeUserEmailRequested: false,
                    changeUserEmailSucceeded: true,
                    changeUserEmailFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.CHANGE_USER_EMAIL_FAILURE:
               return {
                    ...state,
                    changeUserEmailRequested: false,
                    changeUserEmailSucceeded: false,
                    changeUserEmailFailed: true,
                    message: null,
                    errors: action.error,
               };
          case ACTIONS.USER_AVATAR_UPLOAD_REQUEST:
               return {
                    ...state,
                    userAvatarUploadRequested: true,
                    userAvatarUploadSucceeded: false,
                    userAvatarUploadFailed: false,
                    message: null,
                    error: null,
               };
          case ACTIONS.USER_AVATAR_UPLOAD_SUCCESS:
               return {
                    ...state,
                    userAvatarUploadRequested: false,
                    userAvatarUploadSucceeded: true,
                    userAvatarUploadFailed: false,
                    message: action.payload,
                    currentUser: {...state.currentUser, is_avatar_default: false},
                    error: null,
               };
          case ACTIONS.USER_AVATAR_UPLOAD_FAILURE:
               return {
                    ...state,
                    userAvatarUploadRequested: false,
                    userAvatarUploadSucceeded: false,
                    userAvatarUploadFailed: true,
                    message: null,
                    error: action.error,
               };
          case ACTIONS.USER_AVATAR_GENERATE_TEMP_REQUEST:
               return {
                    ...state,
                    userAvatarGenerateTempRequested: true,
                    userAvatarGenerateTempSucceeded: false,
                    userAvatarGenerateTempFailed: false,
                    userGeneratedAvatar: null,
                    message: null,
                    error: null,
               };
          case ACTIONS.USER_AVATAR_GENERATE_TEMP_SUCCESS:
               return {
                    ...state,
                    userAvatarGenerateTempRequested: false,
                    userAvatarGenerateTempSucceeded: true,
                    userAvatarGenerateTempFailed: false,
                    userGeneratedAvatar: action.payload,
                    error: null,
               };
          case ACTIONS.USER_AVATAR_GENERATE_TEMP_FAILURE:
               return {
                    ...state,
                    userAvatarGenerateTempRequested: false,
                    userAvatarGenerateTempSucceeded: false,
                    userAvatarGenerateTempFailed: true,
                    message: null,
                    error: action.error,
               };
          case ACTIONS.REFRESH_CURRENT_USER_AVATAR:
               return {
                    ...state,
                    refreshCurrentUserAvatar: true,
                    currentUser: {
                         ...state.currentUser,
                         avatars: action.payload.urls,
                         is_avatar_default:
                             action.payload.is_generated
                                 ? action.payload.is_generated
                                 : state.currentUser.is_avatar_default
                    },
               };
          case ACTIONS.REMOVE_AVATAR_REQUEST:
               return {
                    ...state,
                    removeAvatarRequested: true,
                    removeAvatarSucceeded: false,
                    removeAvatarFailed: false,
               };
          case ACTIONS.REMOVE_AVATAR_SUCCESS:
               return {
                    ...state,
                    removeAvatarRequested: false,
                    removeAvatarSucceeded: true,
                    removeAvatarFailed: false,
                    currentUser: {...state.currentUser, is_avatar_default: true},
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.REMOVE_AVATAR_FAILURE:
               return {
                    ...state,
                    removeAvatarRequested: false,
                    removeAvatarSucceeded: false,
                    removeAvatarFailed: true,
                    error: action.error,
                    message: null,
               };
          case ACTIONS.AVAILABLE_LANGUAGES_REQUEST:
               return {
                    ...state,
                    availableLanguagesRequested: true,
                    availableLanguagesSucceeded: false,
                    availableLanguagesFailed: false,
               };
          case ACTIONS.AVAILABLE_LANGUAGES_SUCCESS:
               return {
                    ...state,
                    availableLanguagesRequested: false,
                    availableLanguagesSucceeded: true,
                    availableLanguagesFailed: false,
                    languages: action.payload,
                    error: null,
               };
          case ACTIONS.AVAILABLE_LANGUAGES_FAILURE:
               return {
                    ...state,
                    availableLanguagesRequested: false,
                    availableLanguagesSucceeded: false,
                    availableLanguagesFailed: true,
                    error: action.error,
                    languages: null,
               };
          case ACTIONS.CONTACT_US_REQUEST:
               return {
                    ...state,
                    contactUsRequested: true,
                    contactUsSucceeded: false,
                    contactUsFailed: false,
                    message: null,
                    error: null,
               };
          case ACTIONS.CONTACT_US_SUCCESS:
               return {
                    ...state,
                    contactUsRequested: false,
                    contactUsSucceeded: true,
                    contactUsFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.CONTACT_US_FAILURE:
               return {
                    ...state,
                    contactUsRequested: false,
                    contactUsSucceeded: false,
                    contactUsFailed: true,
                    message: null,
                    error: action.error,
               };
          default:
               return state;
     }
}
