import * as ACTIONS from '../actions';

export const formBuilderClearMessages = () => ({
     type: ACTIONS.FORM_BUILDER_CLEAR_MESSAGES,
});

export const requestCreateForm = (payload) => ({
     type: ACTIONS.CREATE_FORM_REQUEST,
     payload,
});

export const successCreateForm = (payload) => ({
     type: ACTIONS.CREATE_FORM_SUCCESS,
     payload,
});

export const failCreateForm = (error) => ({
     type: ACTIONS.CREATE_FORM_FAILURE,
     error,
});

export const cleanCreatedSavedForm = () => ({
     type: ACTIONS.CLEAN_CREATED_FORM_DATA
});

export const requestEventForms = (payload) => ({
     type: ACTIONS.EVENT_FORMS_REQUEST,
     payload,
});

export const successEventForms = (payload) => ({
     type: ACTIONS.EVENT_FORMS_SUCCESS,
     payload,
});

export const failEventForms = (error) => ({
     type: ACTIONS.EVENT_FORMS_FAILURE,
     error,
});

export const getEventFormsPageDetails = (payload) => ({
     type: ACTIONS.GET_EVENT_fORMS_PAGE_DETAILS,
     payload,
});

export const requestFormsSearch = (payload) => ({
     type: ACTIONS.SEARCH_FORMS_REQUEST,
     payload,
});

export const successFormsSearch = (payload) => ({
     type: ACTIONS.SEARCH_FORMS_SUCCESS,
     payload,
});

export const failFormsSearch = (error) => ({
     type: ACTIONS.SEARCH_FORMS_FAILURE,
     error,
});

export const requestRemoveForm = (payload) => ({
     type: ACTIONS.REMOVE_FORM_REQUEST,
     payload,
});

export const successRemoveForm = (payload) => ({
     type: ACTIONS.REMOVE_FORM_SUCCESS,
     payload,
});

export const failRemoveForm = (error) => ({
     type: ACTIONS.REMOVE_FORM_FAILURE,
     error,
});

export const requestFormFields = (payload) => ({
     type: ACTIONS.FORM_FIELDS_REQUEST,
     payload,
});

export const successFormFields = (payload) => ({
     type: ACTIONS.FORM_FIELDS_SUCCESS,
     payload,
});

export const failFormFields = (error) => ({
     type: ACTIONS.FORM_FIELDS_FAILURE,
     error,
});

export const requestFormDetails = (payload) => ({
     type: ACTIONS.FORM_DETAILS_REQUEST,
     payload,
});

export const successFormDetails = (payload) => ({
     type: ACTIONS.FORM_DETAILS_SUCCESS,
     payload,
});

export const failFormDetails = (error) => ({
     type: ACTIONS.FORM_DETAILS_FAILURE,
     error,
});

export const requestFormBasicDetails = (payload) => ({
     type: ACTIONS.FORM_BASIC_DETAILS_REQUEST,
     payload,
});

export const successFormBasicDetails = (payload) => ({
     type: ACTIONS.FORM_BASIC_DETAILS_SUCCESS,
     payload,
});

export const failFormBasicDetails = (error) => ({
     type: ACTIONS.FORM_BASIC_DETAILS_FAILURE,
     error,
});

export const requestUpdateForm = (payload) => ({
     type: ACTIONS.UPDATE_FORM_REQUEST,
     payload,
});

export const successUpdateForm = (payload) => ({
     type: ACTIONS.UPDATE_FORM_SUCCESS,
     payload,
});

export const failUpdateForm = (error) => ({
     type: ACTIONS.UPDATE_FORM_FAILURE,
     error,
});

export const requestSaveCustomForm = (payload) => ({
     type: ACTIONS.SAVE_CUSTOM_FORM_REQUEST,
     payload,
});

export const successSaveCustomForm = (payload) => ({
     type: ACTIONS.SAVE_CUSTOM_FORM_SUCCESS,
     payload,
});

export const failSaveCustomForm = (error) => ({
     type: ACTIONS.SAVE_CUSTOM_FORM_FAILURE,
     error,
});

export const INITIAL_STATE = {
     createFormRequested: false,
     createFormSucceeded: false,
     createFormFailed: false,
     eventFormsRequested: false,
     eventFormsSucceeded: false,
     eventFormsFailed: false,
     formsSearchRequested: false,
     formsSearchSucceeded: false,
     formsSearchFailed: false,
     removeFormRequested: false,
     removeFormSucceeded: false,
     removeFormFailed: false,
     formFieldsRequested: false,
     formFieldsSucceeded: false,
     formFieldsFailed: false,
     formDetailsRequested: false,
     formDetailsSucceeded: false,
     formDetailsFailed: false,
     formBasicDetailsRequested: false,
     formBasicDetailsSucceeded: false,
     formBasicDetailsFailed: false,
     updateFormRequested: false,
     updateFormSucceeded: false,
     updateFormFailed: false,
     saveCustomFormRequested: false,
     saveCustomFormSucceeded: false,
     saveCustomFormFailed: false,
     eventFormsPageDetails: {},
     savedForm: {},
     forms: null,
     formDetails: null,
     formBasicDetails: null,
     formsSearchResult: null,
     formPreview: {
          id: null,
          fields: []
     },
     message: null,
     error: null,
};

export default function formBuilderReducer(state = INITIAL_STATE, action) {
     switch (action.type) {
          case ACTIONS.FORM_BUILDER_CLEAR_MESSAGES:
               return {
                    ...state,
                    message: null,
                    error: null,
               };
          case ACTIONS.CREATE_FORM_REQUEST:
               return {
                    ...state,
                    createFormRequested: true,
                    createFormSucceeded: false,
                    createFormFailed: false,
                    message: null,
                    error: null,
               };
          case ACTIONS.CREATE_FORM_SUCCESS:
               return {
                    ...state,
                    createFormRequested: false,
                    createFormSucceeded: true,
                    createFormFailed: false,
                    savedForm: action.payload,
                    error: null,
               };
          case ACTIONS.CREATE_FORM_FAILURE:
               return {
                    ...state,
                    createFormRequested: false,
                    createFormSucceeded: false,
                    createFormFailed: true,
                    error: action.error,
                    message: null,
               };
          case ACTIONS.CLEAN_CREATED_FORM_DATA:
               return {
                    ...state,
                    createFormRequested: false,
                    createFormSucceeded: false,
                    createFormFailed: false,
                    savedForm: null,
                    formDetails: null
               };
          case ACTIONS.EVENT_FORMS_REQUEST:
               return {
                    ...state,
                    eventFormsRequested: true,
                    eventFormsSucceeded: false,
                    eventFormsFailed: false,
               };
          case ACTIONS.EVENT_FORMS_SUCCESS:
               return {
                    ...state,
                    eventFormsRequested: false,
                    eventFormsSucceeded: true,
                    eventFormsFailed: false,
                    forms: action.payload,
                    error: null,
               };
          case ACTIONS.EVENT_FORMS_FAILURE:
               return {
                    ...state,
                    eventFormsRequested: false,
                    eventFormsSucceeded: false,
                    eventFormsFailed: true,
                    error: action.error,
                    forms: null,
               };
          case ACTIONS.GET_EVENT_fORMS_PAGE_DETAILS:
               return {
                    ...state,
                    eventFormsPageDetails: action.payload,
               };
          case ACTIONS.SEARCH_FORMS_REQUEST:
               return {
                    ...state,
                    formsSearchRequested: true,
                    formsSearchSucceeded: false,
                    formsSearchFailed: false,
                    formsSearchResult: null,
                    error: null,
               };
          case ACTIONS.SEARCH_FORMS_SUCCESS:
               return {
                    ...state,
                    formsSearchRequested: false,
                    formsSearchSucceeded: true,
                    formsSearchFailed: false,
                    formsSearchResult: action.payload,
                    error: null,
               };
          case ACTIONS.SEARCH_FORMS_FAILURE:
               return {
                    ...state,
                    formsSearchRequested: false,
                    formsSearchSucceeded: false,
                    formsSearchFailed: true,
                    error: action.error,
                    formsSearchResult: null,
               };
          case ACTIONS.REMOVE_FORM_REQUEST:
               return {
                    ...state,
                    removeFormRequested: true,
                    removeFormSucceeded: false,
                    removeFormFailed: false,
               };
          case ACTIONS.REMOVE_FORM_SUCCESS:
               return {
                    ...state,
                    removeFormRequested: false,
                    removeFormSucceeded: true,
                    removeFormFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.REMOVE_FORM_FAILURE:
               return {
                    ...state,
                    removeFormRequested: false,
                    removeFormSucceeded: false,
                    removeFormFailed: true,
                    error: action.error,
                    message: null,
               };
          case ACTIONS.FORM_FIELDS_REQUEST:
               return {
                    ...state,
                    formFieldsRequested: true,
                    formFieldsSucceeded: false,
                    formFieldsFailed: false,
                    formPreview: {
                         id: action.payload.id,
                         fields: []
                    }
               };
          case ACTIONS.FORM_FIELDS_SUCCESS:
               return {
                    ...state,
                    formFieldsRequested: false,
                    formFieldsSucceeded: true,
                    formFieldsFailed: false,
                    formPreview: {
                         ...state.formPreview,
                         fields: action.payload
                    },
                    error: null,
               };
          case ACTIONS.FORM_FIELDS_FAILURE:
               return {
                    ...state,
                    formFieldsRequested: false,
                    formFieldsSucceeded: false,
                    formFieldsFailed: true,
                    error: action.error,
                    formPreview: {
                         id: null,
                         fields: []
                    },
               };
          case ACTIONS.FORM_DETAILS_REQUEST:
               return {
                    ...state,
                    formDetailsRequested: true,
                    formDetailsSucceeded: false,
                    formDetailsFailed: false,
               };
          case ACTIONS.FORM_DETAILS_SUCCESS:
               return {
                    ...state,
                    formDetailsRequested: false,
                    formDetailsSucceeded: true,
                    formDetailsFailed: false,
                    formDetails: action.payload,
                    error: null,
               };
          case ACTIONS.FORM_DETAILS_FAILURE:
               return {
                    ...state,
                    formDetailsRequested: false,
                    formDetailsSucceeded: false,
                    formDetailsFailed: true,
                    error: action.error,
                    formDetails: null,
               };
          case ACTIONS.FORM_BASIC_DETAILS_REQUEST:
               return {
                    ...state,
                    formBasicDetailsRequested: true,
                    formBasicDetailsSucceeded: false,
                    formBasicDetailsFailed: false,
               };
          case ACTIONS.FORM_BASIC_DETAILS_SUCCESS:
               return {
                    ...state,
                    formBasicDetailsRequested: false,
                    formBasicDetailsSucceeded: true,
                    formBasicDetailsFailed: false,
                    formBasicDetails: action.payload,
                    error: null,
               };
          case ACTIONS.FORM_BASIC_DETAILS_FAILURE:
               return {
                    ...state,
                    formBasicDetailsRequested: false,
                    formBasicDetailsSucceeded: false,
                    formBasicDetailsFailed: true,
                    error: action.error,
                    formDetails: null,
               };
          case ACTIONS.UPDATE_FORM_REQUEST:
               return {
                    ...state,
                    updateFormRequested: true,
                    updateFormSucceeded: false,
                    updateFormFailed: false,
                    message: null,
                    error: null,
               };
          case ACTIONS.UPDATE_FORM_SUCCESS:
               return {
                    ...state,
                    updateFormRequested: false,
                    updateFormSucceeded: true,
                    updateFormFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.UPDATE_FORM_FAILURE:
               return {
                    ...state,
                    updateFormRequested: false,
                    updateFormSucceeded: false,
                    updateFormFailed: true,
                    error: action.error,
                    message: null,
               };
          case ACTIONS.SAVE_CUSTOM_FORM_REQUEST:
               return {
                    ...state,
                    saveCustomFormRequested: true,
                    saveCustomFormSucceeded: false,
                    saveCustomFormFailed: false,
                    message: null,
                    error: null,
               };
          case ACTIONS.SAVE_CUSTOM_FORM_SUCCESS:
               return {
                    ...state,
                    saveCustomFormRequested: false,
                    saveCustomFormSucceeded: true,
                    saveCustomFormFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.SAVE_CUSTOM_FORM_FAILURE:
               return {
                    ...state,
                    saveCustomFormRequested: false,
                    saveCustomFormSucceeded: false,
                    saveCustomFormFailed: true,
                    error: action.error,
                    message: null,
               };
          default:
               return state;
     }
}
