import styled from 'styled-components';
import {
     COLORS,
     FONT,
     FORM_PLACEHOLDER,
     SIZE,
} from '../../../constants/style.constants';

const SpeakersWrapper = styled.div`
     .mobile-label {
          display: none;
     }

     .disabled {
          background-color: #e9ecef !important;
     }

     .selected-row {
          background-color: rgba(24, 112, 244, 0.05);
     }

     .content {
          display: flex;
          align-items: start;
          justify-content: space-between;
          min-height: 85vh;
     }

     .speakers {
          .table {
               .td-checkbox {
                    padding: 31px 0 31px 24px;
                    width: 24px;
               }
               
               td {
                    max-width: 200px;
                    overflow-wrap: break-word;
               }
          }
          
          .checkbox-solid {
            padding: 0;
            margin: 0;
            width: 20px;
            height: 20px;
        
                 span {
                      background-color: ${COLORS.WHITE};
                      border: 1px solid ${COLORS.LIGHT_BLUE};
                 }
                 
             &:hover {
                
                span {
                    background-color: ${COLORS.LIGHTER_BLUE};
                }
              }
            }
          
            .selected-row {
                .checkbox-solid {
                     span {
                          background-color: ${COLORS.BLUE};
                          border: 0;
                          
                          &:after {
                               border: solid ${COLORS.WHITE};
                          }
                     }
                }
            }
     }
     
     div.react-datepicker-popper {
        z-index: 999 !important;
     }

     .input-group {
          background-color: transparent;
          z-index: 1;
          
          input:disabled {
            background-color: ${COLORS.LIGHT_GREY};
          }

          input,
          a {
               border-radius: 4px !important;
               z-index: 1;
          }

          background-color: transparent;
          /* z-index: 1; */

          input,
          .dropdown-item {
               border-radius: 4px !important;
               background-color: transparent;
               z-index: 1;
          }
          
          .is-invalid.dropdown {
               .form-control {
                   border-color: ${COLORS.RED};
               }
               
               .suffix {
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
                    background-repeat: no-repeat;
                    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
                    background-position: center;
                    right: 12px;
               }
               
               .suffix svg {
                    color: transparent;
                    width: 15px;
                    height: 15px;
               }
          }

          .suffix {
               background-color: transparent;
               border-radius: 4px !important;
               display: flex;
               align-items: center;
               position: absolute;
               top: 1px;
               right: 15px;
               height: 39px;
               /* z-index: -1; */

               svg {
                    color: ${COLORS.PLACEHOLDER_GREY};
               }
          }
     }

     input::placeholder {
          ${FORM_PLACEHOLDER};
          color: ${COLORS.PLACEHOLDER_GREY};
     }

     .form-control {
          background-color: transparent;
          border-radius: 4px !important;
          padding: 12px 42px 12px 15px !important;
          height: 41px;
          font-weight: ${FONT.REGULAR} !important;
          color: ${COLORS.TEXT_GREY} !important;
          font-size: ${SIZE.NORMAL} !important;
     }

     .select-dropdown-toggle {
          display: flex;
          align-items: center;
          background-color: transparent;
          text-decoration: none;
          border-radius: 4px;
          border: 1px solid #ced4da;
          width: 100%;
     }
     
     .language-holder {
        border-bottom: 1px solid #dee1eb;
        padding: 10px 265px;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        background-color: ${COLORS.WHITE};
        z-index: 9;
     }
     
     .language-selector {
        text-align: right;
     }
     
     .nav-border-bottom {
        border-bottom: 3px solid ${COLORS.BORDER_GREY};
     }
     
      .speaker-details-board {
        margin-bottom: 42px;
      }

     .wizard-form {
          width: 100%;
          border: 1px solid #dee1eb;
          border-radius: 4px;
          margin-bottom: 42px;

          .nav-tabs,
          .nav-link {
               border: none;
          }

          .nav-tabs {
               display: flex;
               justify-content: space-between;
               padding: 32px 62px;
               border-bottom: 1px solid #dee1eb;

               .nav-item {
                    width: calc((100% / 3) - 14px);

                    .label {
                         font-weight: ${FONT.MEDIUM};
                         font-size: ${SIZE.INCREASED};
                         line-height: 19px;
                         color: ${COLORS.LAVANDER};
                         margin: 0 !important;
                    }

                    .step {
                         font-weight: ${FONT.MEDIUM};
                         font-size: ${SIZE.GIGANTIC};
                         line-height: 36px;
                         color: ${COLORS.LAVANDER};
                         padding-right: 12px;
                    }

                    [role='tab'] {
                         padding: 0;

                         .border-bottom {
                              background-color: #dee1eb;
                              height: 4px;
                              width: 100%;
                              margin-top: 14px;
                         }

                         &:focus {
                              outline: none;
                         }

                         &.active {
                              .label,
                              .step {
                                   color: ${COLORS.BLUISH};
                              }

                              .nav-border-bottom {
                                   border-bottom-color: ${COLORS.BLUISH};
                              }
                         }
                         
                         &.warning {
                              .label,
                              .step {
                                   color: ${COLORS.YELLOW};
                              }

                              .nav-border-bottom {
                                   border-bottom-color: ${COLORS.YELLOW};
                              }
                         }
                    }
               }
          }

          .tab-pane {
               padding: 60px 265px;
               .headline {
                    font-weight: ${FONT.MEDIUM};
                    font-size: ${SIZE.GREAT};
                    /* line-height: 26px; */
                    color: ${COLORS.DARK_BLUE};
                    margin-bottom: 30px;
               }

               .hint {
                    font-weight: ${FONT.REGULAR};
                    font-size: ${SIZE.NORMAL};
                    line-height: 18px;
                    color: ${COLORS.DARK_BLUE};
               }

               .form-label {
                    font-weight: ${FONT.REGULAR};
                    font-size: ${SIZE.NORMAL};
                    line-height: 18px;
                    color: ${COLORS.DARK_BLUE};
                    margin-bottom: 10px;
               }

               .actions-section {
                    border-top: 1px solid ${COLORS.LIGHTER_BLUE};
                    max-width: 790px;
                    
                    .row {
                        margin-top: 20px;
                        margin-left: 0;
                        margin-right: 0;
                        
                        &.buttons {
                            margin-top: 30px;
                            display: flex;
                            justify-content: space-between;
                        }
                    }
                    
                    .alert {
                        margin: 0;
                    }
                    
                    .btn-default {
                         padding: 15px 23px;
                    }
                    .btn-success {
                         padding: 15px 41px;
                    }
                    
                    .btn {
                        &:disabled {
                            background-color: ${COLORS.LIGHTER_BLUE};
                        }
                    }
               }

               .btn-danger {
                    padding: 7px;
                    margin-top: 9px;
                    margin-left: 5px;
                    height: 25px;
                    width: 25px;
                    display: flex;
                    align-content: center;

                    background-color: transparent;
                    border: 1px solid ${COLORS.LIGHTER_BLUE};
                    border-radius: 2px;

                    svg {
                         height: 10px;
                         width: 10px;
                    }

                    &:hover {
                         background-color: ${COLORS.RED};
                         border: 1px solid ${COLORS.RED};
                    }
               }

               .invite-speakers,
               .invitation-form {
                    .row {
                         &.mt-30 {
                              margin-top: 30px;
                         }

                         .show,
                         .dropdown {
                              box-shadow: 0 0 0 2px #fff;
                              width: 100%;

                              .dropdown-item {
                                   color: ${COLORS.DARK_BLUE};
                                   font-size: ${SIZE.NORMAL};

                                   &.disabled {
                                        color: ${COLORS.PLACEHOLDER_GREY};
                                   }

                                   &:active {
                                        color: ${COLORS.DARK_BLUE};
                                        background-color: ${COLORS.PALE_BLUE};
                                   }
                              }
                         }

                         select {
                              display: block;
                              width: 100%;
                              font-weight: ${FONT.REGULAR};
                              color: #495057;
                              background-color: #fff;
                              background-clip: padding-box;
                         }
                    }
                    .form-actions {
                         margin-bottom: 30px !important;
                         .btn-default {
                              padding: 9px 20px;
                              span {
                                   margin-right: 10px;
                              }
                         }
                         .btn:not(:last-child) {
                              margin-right: 10px;
                         }
                         .btn-primary {
                              padding: 9.5px 47px;
                         }
                    }
               }

               .invitation-form {
                    .checkbox-solid {
                        padding: 0;
                        margin: 0;
                        width: 20px;
                        height: 20px;
                    
                             span {
                                  background-color: ${COLORS.WHITE};
                                  border: 1px solid ${COLORS.LIGHTER_BLUE};
                             }
                             
                         &:hover {
                            span {
                                background-color: ${COLORS.LIGHTER_BLUE};
                            }
                          }
                    }
               
                    .selected-row {
                        .checkbox-solid {
                             span {
                                  background-color: ${COLORS.BLUE};
                                  border: 0;

                                  &:after {
                                       border: solid ${COLORS.WHITE};
                                  }
                             }
                        }
                    }
                    
                    .table-actions {
                         margin-top: 30px;
                    }
                    .table-container {
                         margin-bottom: 30px;
                    }

                    .btn-outline-primary {
                         border: 1px solid #e1e4ec;
                         border-radius: 4px;
                         color: ${COLORS.TEXT_GREY};
                         padding: 5px 10px;

                         &:hover {
                              color: ${COLORS.WHITE};
                              border: 1px solid ${COLORS.BLUE};
                              background-color: ${COLORS.BLUE};
                         }
                         &:not(:last-child) {
                              margin-right: 10px;
                         }
                    }

                    td,
                    th {
                         vertical-align: middle;
                         &:first-child {
                              padding-right: 0px !important;
                              padding-left: 30 !important;
                         }
                         &:nth-child(2) {
                              font-weight: ${FONT.MEDIUM};
                              padding-left: 0px;
                         }
                    }
                    
                    .actions-section {
                        label {
                            color: ${COLORS.DARK_BLUE};
                        }
                        
                        .select-dropdown-toggle {
                            font-size: 13px !important;
                            color: ${COLORS.TEXT_GREY} !important;
                            font-weight: normal !important;
                        }
                    
                        .form-group {
                            padding-left: 0;
                            padding-right: 0;
                            margin-bottom: 0;
                            
                            div {
                                padding-left: 0;
                                padding-right: 0;
                            }
                        }
                    }
               }

               .invitation-email {
                    form {
                         margin-top: 30px;
                    }

                    .checkbox-solid {
                         display: flex;
                         align-content: center;
                         cursor: pointer;
                         position: relative;
                         padding-left: 30px;
                         transition: all 0.15s ease;

                         span {
                              background-color: ${COLORS.LIGHTER_BLUE};
                              border-radius: 2px;
                              position: absolute;
                              top: 1px;
                              left: 0;
                              height: 18px;
                              width: 18px;
                              padding-right: 10px;

                              &:after {
                                   display: none;
                              }
                         }

                         .form-label {
                              line-height: 1.5 !important;
                              margin-bottom: 0 !important;
                         }
                    }

                    input[type='checkbox'] {
                         position: absolute;
                         z-index: -1;
                         opacity: 0;
                    }

                    input[type='checkbox']:checked ~ span {
                         background-color: ${COLORS.BLUE};

                         &:after {
                              content: '';
                              position: absolute;
                              display: none;
                              top: 50%;
                              left: 50%;
                              margin-left: -2px;
                              margin-top: -6px;
                              width: 5px;
                              height: 10px;
                              border-width: 0 2px 2px 0 /*rtl:ignore*/ !important;
                              transform: rotate(45deg) /*rtl:ignore*/;
                              border: solid ${COLORS.WHITE};
                              display: block;
                         }
                    }

                    .button-group {
                         .btn {
                              &:not(:last-child) {
                                   margin-right: 10px;
                              }
                         }
                         .dynamic-content {
                              height: 32px;
                              line-height: 12px;
                              padding: 9px 20px;
                         }
                         .btn-outline-primary {
                              border: 1px solid #e1e4ec;
                              border-radius: 4px;
                              color: ${COLORS.TEXT_GREY};

                              &:hover {
                                   background-color: ${COLORS.BLUE};
                                   border: 1px solid ${COLORS.BLUE};
                                   color: ${COLORS.WHITE};
                              }
                         }
                    }
                    .btn-danger {
                         position: absolute;
                         right: -40px;

                         &:disabled {
                              background-color: ${COLORS.PALE_BLUE} !important;
                         }

                         &:disabled:hover {
                              border: 1px solid ${COLORS.LIGHTER_BLUE};
                         }
                    }

                    .ql-snow {
                         border: 1px solid ${COLORS.LIGHTER_BLUE};
                         color: ${COLORS.DARK_BLUE};

                         .ql-picker-label:before,
                         .ql-picker-item:before {
                              color: ${COLORS.DARK_BLUE};
                         }

                         .ql-picker-label:hover:before,
                         .ql-picker-item:hover:before,
                         .ql-selected:before {
                              color: ${COLORS.BLUISH};
                         }

                         .ql-picker-label {
                              .ql-fill {
                                   fill: ${COLORS.DARK_BLUE};
                              }

                              .ql-stroke {
                                   stroke: ${COLORS.DARK_BLUE};
                              }

                              &:hover {
                                   .ql-fill {
                                        fill: ${COLORS.BLUISH};
                                   }
                                   .ql-stroke {
                                        stroke: ${COLORS.BLUISH};
                                   }
                              }
                         }

                         button {
                              .ql-fill {
                                   fill: ${COLORS.DARK_BLUE};
                              }
                              .ql-stroke {
                                   stroke: ${COLORS.DARK_BLUE};
                              }
                         }

                         button:hover {
                              .ql-fill {
                                   fill: ${COLORS.BLUISH};
                              }
                              .ql-stroke {
                                   stroke: ${COLORS.BLUISH};
                              }
                         }

                         &:first-child {
                              border-top-left-radius: 4px;
                              border-top-right-radius: 4px;
                              border-bottom: none;
                         }

                         &:last-child {
                              height: 250px;
                              border-bottom-left-radius: 4px;
                              border-bottom-right-radius: 4px;
                         }
                    }

                    .quill > .ql-container > .ql-editor.ql-blank::before {
                         color: ${COLORS.PLACEHOLDER_GREY};
                         text-decoration: none;
                         font-style: normal;
                         font-size: ${SIZE.NORMAL};
                    }

                    .show,
                    .dropdown {
                         box-shadow: 0 0 0 2px #fff;
                         width: 100%;

                         .dropdown-item {
                              color: ${COLORS.DARK_BLUE};
                              font-size: ${SIZE.NORMAL};

                              &:disabled {
                                   color: ${COLORS.PALE_BLUE};
                              }

                              &:active {
                                   color: ${COLORS.DARK_BLUE};
                                   background-color: ${COLORS.PALE_BLUE};
                              }
                         }

                         .dropdown-optgroup {
                              border-top: 1px solid #e2e5ed;
                              color: #606d80;
                              font-size: ${SIZE.TINY};
                              padding: 7px 21px;
                         }
                    }
               }
          }
     }

     .speaker-form {
          .card-body {
               padding: 30px;

               img {
                    margin-right: 20px;
               }

               .card-title {
                    font-weight: ${FONT.MEDIUM};
                    font-size: ${SIZE.BIG};
                    line-height: 22px;
                    color: ${COLORS.DARK_BLUE};
                    flex-wrap: wrap;
                    /* margin: 0 !important; */
               }

               .card-subtitle {
                    font-weight: ${FONT.REGULAR};
                    font-size: ${SIZE.INCREASED};
                    line-height: 19px;
                    color: ${COLORS.TEXT_GREY};
               }

               .info-block {
                    display: flex;
                    align-items: center;
                    margin-top: 0px !important;
                    word-break: break-word;
               }
          }
          .form-label {
               font-weight: ${FONT.REGULAR};
               font-size: ${SIZE.NORMAL};
               line-height: 18px;
               color: ${COLORS.DARK_BLUE};
               margin-bottom: 10px;
          }

          .form-actions {
               display: flex;
               flex-direction: row-reverse;
               margin-top: 30px;
          }

          form {
               margin: 39px 55px;
          }

          .row {
               align-items: start;
               margin-bottom: 20px;

               .col-3 {
                    margin-top: 7px;
               }
          }

          .file_upload {
               align-items: start !important;

               .col-3 {
                    margin-top: 11px;
               }
          }
     }

     .speaker-form-anonymous {
          max-width: 791px;
          margin: 0 auto;
     
          .event-block {
               display: flex;
               align-items: center;
               justify-content: center;
               flex-direction: column;
               margin-top: 60px;
               padding-bottom: 30px;
               margin-bottom: 30px;
               border-bottom: 1px solid ${COLORS.LIGHTER_BLUE};
               img {
                    margin-bottom: 20px;
               }

               h1 {
                    font-weight: ${FONT.MEDIUM};
                    font-size: ${SIZE.ENORMOUS};
                    line-height: 31px;
                    color: ${COLORS.DARK_BLUE};
                    margin-bottom: 10px;
               }

               span {
                    font-weight: ${FONT.MEDIUM};
                    font-size: ${SIZE.INCREASED};
                    line-height: 19px;
                    color: ${COLORS.TEXT_GREY};
               }
          }
          .heading {
               font-weight: ${FONT.MEDIUM};
               font-size: ${SIZE.BIG};
               line-height: 22px;
               color: ${COLORS.DARK_BLUE};
               margin-bottom: 20px;
          }
          .text {
               font-weight: ${FONT.REGULAR};
               font-size: ${SIZE.NORMAL};
               line-height: 21px;
               color: ${COLORS.DARK_BLUE};
          }

          .form-label {
               font-weight: ${FONT.REGULAR};
               font-size: ${SIZE.NORMAL};
               line-height: 18px;
               color: ${COLORS.DARK_BLUE};
               margin-bottom: 10px;
          }

          .form-actions {
               display: flex;
               flex-direction: row-reverse;
               margin-top: 30px;
          }

          form {
               margin-top: 30px;
               margin-bottom: 107px;
          }

          .row {
               align-items: start;
               margin-bottom: 20px;

               .col-3 {
                    margin-top: 7px;
               }
          }

          .file_upload {
               align-items: start !important;

               .col-3 {
                    margin-top: 11px;
               }
          }
     }
     
     .is-warning {
        border: 1px solid ${COLORS.YELLOW};
        border-radius: 4px;
     }
     
     .quill-is-invalid {
        border: 1px solid ${COLORS.RED};
        border-radius: 4px;
     }
     
     @media only screen and (max-width: 1320px) {
          form {
               margin: auto;
          }
          .nav-tabs {
               flex-direction: column;
               padding: 24px !important;
          }
          .nav-item {
               width: 100% !important;
          }
          .nav-link {
               background-color: transparent !important;
          }

          .tab-pane {
               padding: 24px !important;
          }
          .language-holder {
               padding: 10px;
          }
          .content {
               flex-direction: column;
               align-items: unset;
          }
     }

     @media only screen and (max-width: 1023px) {
          .info-block {
               display: flex !important;
               align-items: center !important;
          }

          img {
               margin-right: 10px;
          }

          .card-title {
               font-size: 13.2px;
          }

          .card-subtitle {
               font-size: ${SIZE.TINY};
          }
     }

     @media only screen and (max-width: 1023px) {
          .headline {
               font-size: ${SIZE.LARGER} !important;
               margin-bottom: 15px !important;
          }

          .row {
               height: auto !important;
          }

          .mobile-label {
               display: block;
          }

          .btn-danger {
               top: -10px !important;
               right: 15px !important;
               margin: 0 !important;
          }

          .btn-delete {
               right: 0 !important;
          }

          p,
          .form-control,
          .mobile-label,
          input::placeholder,
          .hint {
               font-size: ${SIZE.TINY} !important;
          }

          .speaker-row {
               margin-top: 15px;
          }

          .button-group {
               flex-direction: column;

               .btn {
                    &:not(:last-child) {
                         margin-right: 0 !important;
                         margin-bottom: 10px;
                    }
               }
          }
     }

     .speaker-table-header {
          display: flex;

          @media only screen and (max-width: 440px) {
               display: flex;
               flex-wrap: wrap;
               justify-content: center;

               .btn-success {
                    margin-top: 20px;
               }
          }
     }
`;

export default SpeakersWrapper;
