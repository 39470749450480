import React, {memo, useState, useRef, useEffect, useCallback} from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Form } from 'react-bootstrap';
import PictureUploader from '../../../common/picture-uploader';
import CustomToggle from '../../../common/custom-toggle';
import { CustomDatePicker } from '../../../common/pickers/CustomDatePicker';
import { CustomTimePicker } from '../../../common/pickers/CustomTimePicker';
import { COLORS } from '../../../../constants/style.constants';
import ChevronDownIcon from '../../../../assets/icons/metronic/chevron-down-icon';
import DefaultPhoto from '../../../../assets/images/default-photo.svg';
import { ElementTypes } from '../../FormBuilder/ElementTypes';
import FormBuilderWrapper from '../../FormBuilder/FormBuilder.style';
import moment from 'moment';
import {connect} from "react-redux";
import {FileUploader} from "../../../common/file-uploader/FileUploader";
import {MIME_PHOTOS, MIME_SPEAKER_FILES} from "../../../../utils/mime.utils";
import {AVATAR_FILE_LIMIT, SPEAKER_FILE_LIMIT} from "../../../../utils/file.utils";
import _ from "underscore";
import Spinner from "react-bootstrap/Spinner";
import toastr from "toastr";
import { alphaNumReg } from '../../../../utils/string.utils';

const DEFAULT_PHOTO = DefaultPhoto;

const mapStateToProps = (state) => ({
    uploadSpeakerPhotoRequested: state.speakerReducer.uploadSpeakerPhotoRequested,
    speakerPhotoSucceeded: state.speakerReducer.uploadSpeakerPhotoSucceeded,
    uploadSpeakerFileRequested: state.speakerReducer.uploadSpeakerFileRequested,
    speakerPhotoRemoveSucceeded: state.speakerReducer.speakerPhotoRemoveSucceeded,
    speakerFileRemoveSucceeded: state.speakerReducer.speakerFileRemoveSucceeded,
    uploadSpeakerFileSucceeded: state.speakerReducer.uploadSpeakerFileSucceeded,
    uploadSpeakerPhoto: state.speakerReducer.uploadSpeakerPhoto,
    uploadSpeakerFile: state.speakerReducer.uploadSpeakerFile
});


export const _SpeakerForm = memo(
     ({
          data,
          handleValueChange,
          handleMultipleChange,
          handleInitialFormValues,
          handleSubmit,
          style = {},
          uploadSpeakerPhoto,
          uploadSpeakerFile,
          speakerPhotoSucceeded,
          uploadSpeakerFileSucceeded,
          uploadSpeakerPhotoRequested,
          uploadSpeakerFileRequested,
          languageCode
     }) => {
          const { t } = useTranslation();
          const dropdownRef = useRef();
          const screenWidth = window.screen.width;

          const [speakerId, setSpeakerId] = useState(null);
          const [formId, setFormId] = useState(null);
          const [innerFormState, setInnerFormState] = useState([...data.form]);
          const [values, setValues] = useState({});
          const [errors, setErrors] = useState({});
          const [displayErrors, setDisplayErrors] = useState(false);
          const [media, setMedia] = useState({});
          const [mediaPreview, setMediaPreview] = useState({});
          const [mediaForUpload, setMediaForUpload] = useState([]);
          const [mediaForRemove, setMediaForRemove] = useState([]);
          const [loading, setLoading] = useState({});
          const [loadedForm, setLoadedForm] = useState(false);

          useEffect(() => {
               const newState = [...data.form];
               const newMedia = data.media ? data.media : {};

               const preCheckedValues = newState.map((el, idx) => {
                    // Mapping the value according to the data label value
                   if (el.type === 'text_area') {
                       el.text = data && data.data && data.data[el.code] ? data.data[el.code] : el.text;
                   } else if (el.option === 'first_name' || el.option === 'last_name' || el.option === 'email') {
                       const initialValue = data.is_initial && el.value ? el.value : null;
                       el.value =  initialValue ? initialValue : (data && data.data && data.data[el.code] ? data.data[el.code] : el.value);
                   } else {
                       el.value = data && data.data && data.data[el.code] ? data.data[el.code] : el.value;
                   }

                    if (el.choices) {
                        el.choices.map((c) => {
                                if (el.type === 'dropdown') {
                                    c.preChecked = data.data[el.code] === c.value || (el.value ? false : c.preChecked);
                                    if (c.preChecked) {
                                        setInnerState((previousState) => {
                                            return {dropdowns: {...previousState.dropdowns, [el.code]: c.value}};
                                        });
                                    }

                                } else if (el.type === 'checkbox') {
                                    if (el.multiple_choices) {
                                        c.preChecked = (data.data[el.code] && data.data[el.code].includes(c.label)) || (el.value ? false : c.preChecked);
                                    } else {
                                        c.preChecked = data.data[el.code] === c.label || (el.value ? false : c.preChecked);
                                    }
                                } else if (el.type === 'radio_selection') {
                                    c.preChecked = data.data[el.code] === c.label || (el.value ? false : c.preChecked);
                                }

                                return c;
                            }
                        );
                    }

                    return el;
               });

               const initialValues = [];
               preCheckedValues.forEach(el => {
                   let value = null;
                   if (el.multiple_choices) {
                       value = [];
                       el.choices.forEach((c) => {
                           if (c.preChecked) {
                               value = [...value, c.label];
                           }
                       });
                   } else if (el.choices) {
                       const found = el.choices.find((c) => c.preChecked === true);
                       if (found) {
                           value = found.value ? found.value : found.label;
                       }
                   } else {
                       if (el.type === 'text_area') {
                           value = el.text;
                       } else {
                           value = el.value;
                       }
                   }

                   if (value) {
                       initialValues[el.code] = value;
                   }
               });

               setInnerFormState(preCheckedValues);
              handleInitialFormValues(initialValues);
              setValues(initialValues);
              setMedia(newMedia);
              setSpeakerId(data.id);
              setFormId(data.form_id);
              setLoadedForm(true);
          }, [data]);

          useEffect( () => {
              validateData(data.form, values);
          }, [values, mediaForUpload, mediaForRemove]);

          const DEFAULT_TEMPLATE_OPTION = i18n.t('form_builder.select_something', { lng: languageCode });

          const encodeFieldName = (name) => {
              return name.split(' ').join('_');
          };

          const getDefaultPhoto = (field) => {
              const time = moment();
              const encodedFieldName = encodeFieldName(field);

              if (media && media[encodedFieldName]) {

                  return media[encodedFieldName]['180x180'] + '?t=' + time.unix();
              }

              if (mediaPreview && mediaPreview[encodedFieldName]) {
                  return mediaPreview[encodedFieldName];
              }

              return DEFAULT_PHOTO;
          };

          const getPreviewPhoto = (field) => {
              const encodedFieldName = encodeFieldName(field);

              if (mediaPreview && mediaPreview[encodedFieldName]) {
                  return mediaPreview[encodedFieldName];
              }

              return null;
          };

          const getFullPhoto = (field) => {
              const encodedFieldName = encodeFieldName(field);

              if (media && media[encodedFieldName]) {

                  return media[encodedFieldName];
              }

              if (mediaPreview && mediaPreview[encodedFieldName]) {

                  return mediaPreview[encodedFieldName];
              }

              return null;
          };

          const getPhotoName = (field) => {
              const encodedFieldName = encodeFieldName(field);

              if (media && media[encodedFieldName]) {

                  return media[encodedFieldName]?.meta?.name;
              }

              return null;
          };

          const handleUploadPhoto = (photo, crop, field) => {
              const encodedFieldName = encodeFieldName(field);

              setMediaPreview({
                  ...mediaPreview,
                  [encodedFieldName]: crop.canvas
              });

              const currentFields = [...mediaForUpload];
              const fieldForUpload = currentFields.filter((c) => c.field !== encodedFieldName);

              setMediaForUpload([
                  ...fieldForUpload,
                  {
                      field: encodedFieldName,
                      file: photo,
                      crop: {
                          width: crop.width,
                          height: crop.height,
                          x: crop.x,
                          y: crop.y
                      },
                      speakerId,
                      formId,
                      type: 'photo'
                  }
              ]);
          };

          const handleUploadFile = (field, file) => {
              const encodedFieldName = encodeFieldName(field);

              setMediaPreview({
                  ...mediaPreview,
                  [encodedFieldName]: file
              });

              const currentFields = [...mediaForUpload];
              const fieldForUpload = currentFields.filter((c) => c.field !== encodedFieldName);

              setMediaForUpload([
                  ...fieldForUpload,
                  {
                      field: encodedFieldName,
                      file,
                      speakerId,
                      formId,
                      type: 'file'
                  }
              ]);
          };

          const handleRemovePhoto = (field) => {
              const encodedName = encodeFieldName(field);
              setMedia({
                  ...media,
                  [encodedName]: null
              });

              setMediaForRemove([
                  ...mediaForRemove,
                  {
                      field: encodedName,
                      speakerId,
                      formId,
                      type: 'photo'
                  }
              ]);
          };

          const handleRemovePhotoPreview = (field) => {
              const encodedName = encodeFieldName(field);

              setMediaPreview({
                  ...mediaPreview,
                  [encodedName]: null
              });
          };

          const handleRemoveFile = (field) => {
              const encodedName = encodeFieldName(field);
              setMedia({
                  ...media,
                  [encodedName]: null
              });

              setMediaForRemove([
                  ...mediaForRemove,
                  {
                      field: encodedName,
                      speakerId,
                      formId,
                      type: 'file'
                  }
              ]);
          };

          const handlePreviewRemoveFile = (field) => {
              const encodedName = encodeFieldName(field);

              setMediaPreview({
                  ...mediaPreview,
                  [encodedName]: null
              });
          };

          useEffect(() => {
              if (speakerPhotoSucceeded && uploadSpeakerPhoto) {
                  const fields = Object.keys(uploadSpeakerPhoto);
                  setMediaPreview({});

                  fields.forEach(field => {
                      if (uploadSpeakerPhoto[field]) {
                          setMedia({
                              ...media,
                              [field]: uploadSpeakerPhoto[field]
                          });
                          setLoading({
                              ...loading,
                              [field]: false
                          });
                      }
                  });
              }
          }, [uploadSpeakerPhoto, speakerPhotoSucceeded]);

          useEffect(() => {
            if (uploadSpeakerFile && uploadSpeakerFileSucceeded) {

                const fields = Object.keys(uploadSpeakerFile);
                setMediaPreview({});

                fields.forEach(field => {
                    if (uploadSpeakerFile[field]) {
                        setMedia({
                            ...media,
                            [field]: uploadSpeakerFile[field]
                        });
                        setLoading({
                            ...loading,
                            [field]: false
                        });
                    }
                });
            }

          }, [uploadSpeakerFile, uploadSpeakerFileSucceeded]);

          const displayDropdown = (item) => {
              if (!_.isEmpty(innerState.dropdowns[item.code])) {

                  return innerState.dropdowns[item.code];
              }

              const fieldValue = item.choices.find((c) => !!c.default);

              if (fieldValue) {

                  return fieldValue.value;
              }

              return t('form_builder.select_something', { lng: languageCode });
          };

          const displayTimeValue = (item) => {

              return item.value || item.placeholder;
          };

          const [innerState, setInnerState] = useState({
               templateOptions: [{ value: DEFAULT_TEMPLATE_OPTION }],
               dropdowns: {}
          });

          const syncUpdateDropdown = (name, value) => {
               handleValueChange(name, value);
              setValues({
                  ...values,
                  [name]: value
              });

               setInnerState({
                    ...innerState,
                    dropdowns: {
                        ...innerState.dropdowns,
                        [name]: value
                    }
               });
          };

          const displayLayout = (layout) => {
               switch (layout) {
                    case 'one_column':
                         return '1fr';
                    case 'two_column':
                         return '1fr 1fr';
                    case 'three_column':
                         return '1fr 1fr 1fr';
                    default:
                         return '1fr';
               }
          };

          const handleInputChange = ({ inputType, code, value }) => {
               const newState = innerFormState.map((el) => {
                    if (el.code === code) {
                         return { ...el, value };
                    }

                    return el;
               });

               setValues({
                   ...values,
                   [code]: value
                });
               setInnerFormState(newState);
          };

          const handleInputTextChange = ({ inputType, code, text }) => {
              const newState = innerFormState.map((el) => {
                  if (el.code === code) {
                      return { ...el, text };
                  }

                  return el;
              });

              setValues({
                  ...values,
                  [code]: text
              });
              setInnerFormState(newState);
          };

          const handleCheckboxChange = (type, elementId, choiceId, value) => {
               const newState = innerFormState.map((element) => {
                    if (element.type === type && element.id === elementId) {
                         const choice = element.choices.find(
                              ({ id: _id }) => choiceId === _id
                         );

                         if (
                              element.type === 'radio_selection' ||
                              !element.multiple_choices
                         ) {
                              element.choices.map((choice) => {
                                   if (choice.id === choiceId) {
                                        const isPrechecked = !choice.preChecked;

                                        return isPrechecked
                                             ? (choice.preChecked = false)
                                             : (choice.preChecked = true);
                                   }

                                   return (choice.preChecked = false);
                              });
                         }

                         choice.preChecked = !choice.preChecked;
                    }

                    return element;
               });

               setInnerFormState(newState);
          };

          const isPrecheckedRadio = (id) => {
              let foundRadio = null;

               innerFormState
                    .find((el) => {
                        if (el.type === 'radio_selection') {
                            foundRadio = el.choices.find(({ id: _id }) => id === _id);

                            return !!foundRadio;
                        }

                       return false;
                    });


               return foundRadio ? foundRadio.preChecked : false;
          };

          const isValidField = (code) => {
              return !errors[code];
          };

          const getFieldErrors = (code) => {
              return errors[code];
          };

          const isUploading = () => {
              return !!(uploadSpeakerPhotoRequested || uploadSpeakerFileRequested);
          };

          const drawElements = (item, zIndex) => {
               let context;

               switch (item.type) {
                    case ElementTypes.TITLE:
                         context = (
                              <h3 className="title mb-20">
                                   {item.value}
                              </h3>
                         );
                         break;
                    case ElementTypes.PARAGRAPH:
                         context = (
                              <div className="paragraph mb-20"  dangerouslySetInnerHTML={{
                                  __html: item.text.replace(/\n/g, '<br>\n') || item.code
                              }} />
                         );
                         break;
                    case ElementTypes.TEXT_FIELD:
                         context = (
                              <div className="mb-20">
                                   <label
                                        htmlFor={item.label}
                                        style={{
                                             display: 'grid',
                                             gridTemplateColumns: screenWidth > 960 ? '195px auto' : '1fr',
                                             gridGap: screenWidth > 960 ? '' : '10px',
                                             placeItems: 'start start'
                                        }}
                                   >
                                        <span
                                             style={{
                                                  maxWidth: '160px',
                                             }}
                                        >
                                             {item.label || item.code}
                                             {item.required && (
                                                  <span
                                                       style={{
                                                            marginLeft: '10px',
                                                            color: `${COLORS.RED}`,
                                                       }}
                                                  >
                                                       *
                                                  </span>
                                             )}
                                        </span>
                                        <div style={{ width: '100%' }}>
                                            <input
                                                required={item.required}
                                                className={`form-control ${displayErrors && !isValidField(item.code) ? 'is-invalid' : ''}`}
                                                id={item.label}
                                                name={item.elementCode}
                                                type={item.elementType}
                                                placeholder={item.placeholder}
                                                value={item.value}
                                                onChange={({
                                                               target: { value },
                                                           }) => {
                                                    handleInputChange({
                                                        inputType: 'text',
                                                        code: item.code,
                                                        value,
                                                    });

                                                    handleValueChange(
                                                        item.code,
                                                        value
                                                    );
                                                }}
                                            />
                                            <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
                                                {displayErrors && !isValidField(item.code) && getFieldErrors(item.code)}
                                            </Form.Control.Feedback>
                                            <div className="info-block">
                                                {item.additional_information}
                                            </div>
                                        </div>
                                   </label>
                              </div>
                         );
                         break;
                    case ElementTypes.TEXT_AREA:
                         context = (
                              <div className="mb-20">
                                   <label
                                        htmlFor={item.label}
                                        style={{
                                             display: 'grid',
                                             gridTemplateColumns: screenWidth > 960 ? '195px auto' : '1fr',
                                             gridGap: screenWidth > 960 ? '' : '10px',
                                             placeItems: 'start start',
                                        }}
                                   >
                                        <span
                                             style={{
                                                  maxWidth: '160px',
                                                  placeSelf: 'start',
                                             }}
                                        >
                                             {item.label || item.code}
                                             {item.required && (
                                                  <span
                                                       style={{
                                                            marginLeft: '10px',
                                                            color: `${COLORS.RED}`,
                                                       }}
                                                  >
                                                       *
                                                  </span>
                                             )}
                                        </span>
                                        <div style={{ width: '100%' }}>
                                            <textarea
                                                required={item.required}
                                                className={`form-control textarea ${displayErrors && !isValidField(item.code) ? 'is-invalid' : ''}`}
                                                style={{ height: 'auto' }}
                                                type="textarea"
                                                rows="9"
                                                cols="5"
                                                id={item.label}
                                                name={item.elementCode}
                                                placeholder={item.placeholder}
                                                value={item.text}
                                                onChange={({
                                                               target: { value },
                                                           }) => {
                                                    handleInputTextChange({
                                                        inputType: 'text_area',
                                                        code: item.code,
                                                        text: value,
                                                    });

                                                    handleValueChange(
                                                        item.code,
                                                        value
                                                    );
                                                }}
                                            />
                                            <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
                                                {displayErrors && !isValidField(item.code) && getFieldErrors(item.code)}
                                            </Form.Control.Feedback>
                                            <div className="info-block">
                                                {item.additional_information}
                                            </div>
                                        </div>
                                   </label>
                              </div>
                         );
                         break;
                    case ElementTypes.CHECKBOX:
                         context = (
                              <div
                                   className="mb-20"
                                   style={{
                                        display: 'grid',
                                        gridTemplateColumns: screenWidth > 460 ? '195px 1fr 37px' : '1fr',
                                        gridGap: screenWidth > 460 ? '' : '10px',
                                        placeItems: 'start',
                                        position: 'relative',
                                   }}
                              >
                                   <label
                                        style={{
                                             maxWidth: '160px',
                                             justifySelf: 'start',
                                        }}
                                   >
                                        {item.label || item.code}
                                        {item.required && (
                                             <span
                                                  style={{
                                                       marginLeft: '10px',
                                                       color: `${COLORS.RED}`,
                                                  }}
                                             >
                                                  *
                                             </span>
                                        )}
                                   </label>

                                  <div>
                                      <div
                                          className="checkbox-group"
                                          style={{
                                              display: 'grid',
                                              width: '100%',
                                              gridTemplateColumns: displayLayout(
                                                  item.layout
                                              ),
                                              gridColumnGap: '12px',
                                              gap: '5px',
                                              gridTemplateRows: '1fr',
                                              placeItems: 'start',
                                          }}
                                      >
                                          {item.choices.map((choice, idx) => (
                                              <label
                                                  key={idx}
                                                  className="checkbox-solid-blue"
                                                  style={{
                                                      pointerEvents: 'auto'
                                                  }}
                                              >
                                                  <input
                                                      style={{marginBottom: '10px'}}
                                                      required={item.required}
                                                      type="checkbox"
                                                      className="checkbox-input"
                                                      checked={
                                                          choice.preChecked
                                                      }
                                                      onChange={() => {}}
                                                      onClick={() => {
                                                          handleCheckboxChange(
                                                              'checkbox',
                                                              item.id,
                                                              choice.id
                                                          );

                                                          if (item.multiple_choices) {
                                                              handleMultipleChange(
                                                                  item.code,
                                                                  choice.preChecked &&
                                                                  choice.label,
                                                                  choice.label
                                                              );

                                                              if (Boolean(choice.preChecked) === false) {
                                                                  const filtered = values[item.code].filter((v) => v !== choice.label);
                                                                  setValues({
                                                                      ...values,
                                                                      [item.code]: filtered
                                                                  });
                                                              } else {
                                                                  setValues({
                                                                      ...values,
                                                                      [item.code]: [...values[item.code], choice.label],
                                                                  });
                                                              }
                                                          } else {
                                                              if (Boolean(choice.preChecked) === false) {
                                                                  handleValueChange(
                                                                      item.code,
                                                                      false
                                                                  );
                                                                  setValues({
                                                                      ...values,
                                                                      [item.code]: null
                                                                  });
                                                              } else {
                                                                  handleValueChange(
                                                                      item.code,
                                                                      choice.preChecked &&
                                                                      choice.label
                                                                  );
                                                                  setValues({
                                                                      ...values,
                                                                      [item.code]: choice.label
                                                                  });
                                                              }
                                                          }
                                                      }}
                                                  />

                                                  <span />
                                                  <div className="label-text">
                                                      {choice.label ||
                                                      t('form_builder.choice', { lng: languageCode })}
                                                  </div>
                                              </label>
                                          ))}
                                      </div>
                                      <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
                                          {displayErrors && !isValidField(item.code) && getFieldErrors(item.code)}
                                      </Form.Control.Feedback>
                                      <div className="info-block">
                                          {item.additional_information}
                                      </div>
                                  </div>
                              </div>
                         );
                         break;
                    case ElementTypes.RADIO:
                         context = (
                              <div
                                   className="mb-20"
                                   style={{
                                        display: 'grid',
                                        gridTemplateColumns: screenWidth > 460 ? '195px 1fr 37px' : '1fr',
                                        gridGap: screenWidth > 460 ? '' : '10px',
                                        placeItems: 'start',
                                        position: 'relative',
                                   }}
                              >
                                   <label
                                        style={{
                                             maxWidth: '160px',
                                             justifySelf: 'start',
                                        }}
                                   >
                                        {item.label || item.name}
                                        {item.required && (
                                             <span
                                                  style={{
                                                       marginLeft: '10px',
                                                       color: `${COLORS.RED}`,
                                                  }}
                                             >
                                                  *
                                             </span>
                                        )}
                                   </label>
                                  <div>
                                   <div
                                        style={{
                                             display: 'grid',
                                             width: '100%',
                                             gridTemplateColumns: displayLayout(
                                                  item.layout
                                             ),
                                             gridColumnGap: '12px',
                                             gap: '5px',
                                             gridTemplateRows: '1fr',
                                             placeItems: 'start',
                                        }}
                                   >
                                        {item.choices.map((choice, idx) => (
                                             <label
                                                  key={idx}
                                                  className="form-label"
                                                  style={{
                                                       pointerEvents: 'auto',
                                                       margin: 0
                                                  }}
                                             >
                                                  <input
                                                       required={item.required}
                                                       type="radio"
                                                       checked={isPrecheckedRadio(
                                                            choice.id
                                                       )}
                                                       onChange={() => {}}
                                                       onClick={({
                                                            target: { value },
                                                       }) => {
                                                            handleCheckboxChange(
                                                                 'radio_selection',
                                                                 item.id,
                                                                 choice.id,
                                                                 value
                                                            );
                                                            if (Boolean(choice.preChecked) === false) {
                                                                handleValueChange(
                                                                    item.code,
                                                                    false
                                                                );

                                                                setValues({
                                                                    ...values,
                                                                    [item.code]: null
                                                                });
                                                            } else {
                                                                handleValueChange(
                                                                    item.code,
                                                                    choice.preChecked &&
                                                                    choice.label
                                                                );

                                                                setValues({
                                                                    ...values,
                                                                    [item.code]: value
                                                                });
                                                            }
                                                       }}
                                                  />
                                                  <div
                                                       className="circle-outer"
                                                       style={{ minWidth: 18 }}
                                                  >
                                                       <div className="circle-inner" />
                                                  </div>
                                                  <div
                                                       style={{
                                                            wordBreak:
                                                                 'break-word',
                                                       }}
                                                  >
                                                       {choice.label ||
                                                            t('form_builder.choice', { lng: languageCode })}
                                                  </div>
                                             </label>
                                        ))}
                                   </div>
                                      <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
                                          {displayErrors && !isValidField(item.code) && getFieldErrors(item.code)}
                                      </Form.Control.Feedback>
                                      <div className="info-block">
                                          {item.additional_information}
                                      </div>
                                   </div>
                              </div>
                         );
                         break;
                    case ElementTypes.IMAGE:
                         context = (
                              <label
                                   className="mb-20"
                                   htmlFor={item.label}
                                   style={{
                                        pointerEvents: 'auto',
                                        display: 'grid',
                                        gridTemplateColumns: screenWidth > 460 ? '195px auto' : '1fr',
                                        gridGap: screenWidth > 460 ? '' : '10px',
                                   }}
                              >
                                   <span
                                        style={{
                                             maxWidth: '160px',
                                             placeSelf: 'start',
                                        }}
                                   >
                                        {item.label || item.code}
                                        {item.required && (
                                             <span
                                                  style={{
                                                       marginLeft: '10px',
                                                       color: `${COLORS.RED}`,
                                                  }}
                                             >
                                                  *
                                             </span>
                                        )}
                                   </span>
                                   <div>
                                        <PictureUploader
                                             required={item.required}
                                             picture={getDefaultPhoto(item.code)}
                                             preview={getPreviewPhoto(item.code)}
                                             getFile={(file, crop) => handleUploadPhoto(file, crop, item.code) }
                                             getDefault={() => { getDefaultPhoto(item.code) }}
                                             handleRemovePicture={ () => { handleRemovePhoto(item.code) } }
                                             handleRemovePreview={ () => { handleRemovePhotoPreview(item.code) }}
                                             isDefault={getDefaultPhoto(item.code) === DEFAULT_PHOTO}
                                             isLoading={loading && loading[encodeFieldName(item.code)]}
                                             accept={MIME_PHOTOS.join(',')}
                                             fileLimit={AVATAR_FILE_LIMIT}
                                             fullPreview={getFullPhoto(item.code)}
                                             fullPreviewName={getPhotoName(item.code)}
                                             infoText={' '}
                                        />
                                       <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
                                           {displayErrors && !isValidField(item.code) && getFieldErrors(item.code)}
                                       </Form.Control.Feedback>
                                        {item.additional_information && (
                                             <div
                                                  className="info-block"
                                                  style={{ width: '100%' }}
                                             >
                                                  {item.additional_information}
                                             </div>
                                        )}
                                   </div>
                              </label>
                         );
                         break;
                    case ElementTypes.FILE_UPLOAD:
                         context = (
                              <div
                                   style={{
                                        display: 'grid',
                                        placeItems: 'center start',
                                        position: 'relative'
                                   }}
                              >
                                   <label
                                        className="mb-20"
                                        htmlFor={item.label}
                                        style={{
                                             pointerEvents: 'auto',
                                             display: 'grid',
                                             gridTemplateColumns: screenWidth > 460 ? '195px auto' : '1fr',
                                             gridGap: screenWidth > 460 ? '' : '10px',
                                        }}
                                   >
                                        <span
                                             style={{
                                                  maxWidth: '160px',
                                                  placeSelf: 'start',
                                             }}
                                        >
                                             {item.label || item.code}
                                             {item.required && (
                                                  <span
                                                       style={{
                                                            marginLeft: '10px',
                                                            color: `${COLORS.RED}`,
                                                       }}
                                                  >
                                                       *
                                                  </span>
                                             )}
                                        </span>
                                       <div>
                                       <FileUploader
                                           field={item.code}
                                           file={media && media[encodeFieldName(item.code)]}
                                           preview={mediaPreview && mediaPreview[encodeFieldName(item.code)]}
                                           handleUploadFile={handleUploadFile}
                                           handleRemoveFile={handleRemoveFile}
                                           handlePreviewRemoveFile={handlePreviewRemoveFile}
                                           isLoading={loading && loading[encodeFieldName(item.code)]}
                                           accept={MIME_SPEAKER_FILES.join(',')}
                                           fileLimit={SPEAKER_FILE_LIMIT}
                                           allFiles={false}
                                       />
                                       <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
                                           {displayErrors && !isValidField(item.code) && getFieldErrors(item.code)}
                                       </Form.Control.Feedback>
                                       {item.additional_information && (
                                           <div
                                               className="info-block"
                                               style={{ width: '100%' }}
                                           >
                                               {item.additional_information}
                                           </div>
                                       )}
                                       </div>
                                   </label>
                              </div>
                         );
                         break;
                    case ElementTypes.DATE:
                         context = (
                                  <label
                                       className="mb-20"
                                       htmlFor={item.label}
                                       style={{
                                            pointerEvents: 'auto',
                                            display: 'grid',
                                            gridTemplateColumns: screenWidth > 960 ? '195px auto' : '1fr',
                                            gridGap: screenWidth > 960 ? '' : '10px',
                                            placeItems: 'start start',
                                       }}
                                  >
                                       <span
                                            style={{
                                                 maxWidth: '160px',
                                                 placeSelf: 'start start',
                                            }}
                                       >
                                            {item.label || item.code}
                                            {item.required && (
                                                 <span
                                                      style={{
                                                           marginLeft: '10px',
                                                           color: `${COLORS.RED}`,
                                                      }}
                                                 >
                                                      *
                                                 </span>
                                            )}
                                       </span>
                                      <div
                                          style={{ width: '100%', zIndex: zIndex }}
                                      >
                                            <CustomDatePicker
                                                 required={item.required}
                                                 isInvalid={displayErrors && !isValidField(item.code)}
                                                 placeholderText={item.placeholder}
                                                 date={item.value && new Date(item.value)}
                                                 languageCode={languageCode}
                                                 handleChange={(date) => {
                                                     handleValueChange(
                                                         item.code,
                                                         date
                                                     );
                                                     setValues({
                                                         ...values,
                                                         [item.code]: date
                                                     });
                                                 }
                                                 }
                                                 style={{
                                                      zIndex: '2',
                                                 }}
                                            />
                                            <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
                                                  {displayErrors && !isValidField(item.code) && getFieldErrors(item.code)}
                                            </Form.Control.Feedback>
                                            <div className="info-block">
                                                 {item.additional_information}
                                            </div>
                                       </div>
                                  </label>
                         );
                         break;
                    case ElementTypes.TIME:
                         context = (
                              <label
                                   className="mb-20"
                                   htmlFor={item.label}
                                   style={{
                                        pointerEvents: 'auto',
                                        display: 'grid',
                                        gridTemplateColumns: screenWidth > 960 ? '195px auto' : '1fr',
                                        gridGap: screenWidth > 960 ? '' : '10px',
                                        placeItems: 'start start',
                                   }}
                              >
                                   <span
                                        style={{
                                             maxWidth: '160px',
                                             placeSelf: 'start start',
                                        }}
                                   >
                                        {item.label || item.code}
                                        {item.required && (
                                             <span
                                                  style={{
                                                       marginLeft: '10px',
                                                       color: `${COLORS.RED}`,
                                                  }}
                                             >
                                                  *
                                             </span>
                                        )}
                                   </span>
                                   <div style={{ width: '100%', zIndex: zIndex }}>
                                        <CustomTimePicker
                                             required={item.required}
                                             timeFormat={parseInt(item.format)}
                                             value={item.value}
                                             isInvalid={displayErrors && !isValidField(item.code)}
                                             className="is-invalid"
                                             languageCode={languageCode}
                                             handleChange={(time) => {
                                                 handleValueChange(
                                                     item.code,
                                                     time
                                                 );
                                                 setValues({
                                                     ...values,
                                                     [item.code]: time
                                                 });
                                                }
                                             }
                                        />
                                       <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
                                           {displayErrors && !isValidField(item.code) && getFieldErrors(item.code)}
                                       </Form.Control.Feedback>
                                        <div className="info-block">
                                             {item.additional_information}
                                        </div>
                                   </div>
                              </label>
                         );
                         break;
                   case ElementTypes.DROPDOWN:
                         context = (
                              <label
                                   className="mb-20"
                                   htmlFor={item.label}
                                   style={{
                                        pointerEvents: 'auto',
                                        display: 'grid',
                                        gridTemplateColumns: screenWidth > 960 ? '195px auto' : '1fr',
                                        gridGap: screenWidth > 960 ? '' : '10px',
                                        placeItems: 'start start',
                                   }}
                              >
                                   <span
                                        style={{
                                             maxWidth: '160px',
                                             placeSelf: 'start start',
                                        }}
                                   >
                                        {item.label || item.code}
                                        {item.required && (
                                             <span
                                                  style={{
                                                       marginLeft: '10px',
                                                       color: `${COLORS.RED}`,
                                                  }}
                                             >
                                                  *
                                             </span>
                                        )}
                                   </span>
                                   <div
                                        className="input-group"
                                        style={{ width: '100%', zIndex: zIndex }}
                                   >
                                        <Dropdown
                                            ref={dropdownRef}
                                            className={displayErrors && !isValidField(item.code) ? 'is-invalid' : ''}>
                                             <Dropdown.Toggle
                                                  className="select-dropdown"
                                                  as={CustomToggle}
                                             >
                                                  {
                                                      displayDropdown(item)
                                                  }
                                             </Dropdown.Toggle>
                                             <Dropdown.Menu flip={false}>
                                                  {item.choices.map(
                                                       ({ value, id }) => (
                                                            <Dropdown.Item
                                                                 key={id}
                                                                 onClick={() =>
                                                                      syncUpdateDropdown(
                                                                           item.code,
                                                                           value
                                                                      )
                                                                 }
                                                            >
                                                                 {value ||
                                                                      t('form_builder.choice', { lng: languageCode })}
                                                            </Dropdown.Item>
                                                       )
                                                  )}
                                             </Dropdown.Menu>
                                        </Dropdown>
                                        <span className="suffix">
                                             <ChevronDownIcon />
                                        </span>
                                        <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
                                           {displayErrors && !isValidField(item.code) && getFieldErrors(item.code)}
                                        </Form.Control.Feedback>
                                        <div className="info-block">
                                             {item.additional_information}
                                        </div>
                                   </div>
                              </label>
                         );
                         break;
                    case ElementTypes.DIVIDER:
                         context = (
                              <div
                                   className="mb-20"
                                   style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                   }}
                              >
                                   {item.invert ? <hr className="divider" /> : ''}
                                   <h3 className="title">
                                        {item.title || item.code}
                                   </h3>
                                   <div className="paragraph">
                                        {item.text || item.code}
                                   </div>
                                   {!item.invert ? <hr className="divider" /> : ''}
                              </div>
                         );
                         break;
               }

               return context;
          };

          const validateData = (fields, data) => {
              let fieldErrors = {};
              const textFields = [
                  ElementTypes.TEXT_FIELD,
                  ElementTypes.TEXT_AREA,
                  ElementTypes.DROPDOWN,
                  ElementTypes.RADIO,
                  ElementTypes.CHECKBOX,
                  ElementTypes.TIME
              ];
              const dateFields = [ElementTypes.DATE];
              const mediaFields = [ElementTypes.IMAGE, ElementTypes.FILE_UPLOAD];

              fields.forEach((field) => {
                  if (textFields.includes(field.type)) {
                      if (field.required) {
                          if (_.isEmpty(data[field.code])) {
                              fieldErrors[field.code] = [i18n.t('validations.field_is_required')];
                          }
                      }

                      if (field.option === 'first_name') {
                          if (data[field.code] !== undefined && !alphaNumReg.test(data[field.code])) {
                              fieldErrors[field.code] = [i18n.t('validations.alpha_custom')];
                          }
                      }

                      if (field.option === 'last_name') {
                          if (data[field.code] !== undefined && !alphaNumReg.test(data[field.code])) {
                              fieldErrors[field.code] = [i18n.t('validations.alpha_custom')];
                          }
                      }

                      if (field.option === 'email') {
                          const reg = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

                          if (data[field.code] && !data[field.code].match(reg)) {
                              fieldErrors[field.code] = [i18n.t('validations.field_must_be_valid_email')];
                          }
                      }
                  }

                  if (dateFields.includes(field.type)) {
                      if (field.required) {
                          if (
                              data[field.code] === undefined ||
                              data[field.code] === '' ||
                              data[field.code] === null
                          ) {
                              fieldErrors[field.code] = [i18n.t('validations.field_is_required')];
                          }
                      }
                  }

                  if (mediaFields.includes(field.type)) {
                      if (field.required) {
                          const fieldCode = encodeFieldName(field.code);
                          const found = mediaForUpload.find((media) => media.field === fieldCode);
                          if (
                              _.isEmpty(media[fieldCode]) &&
                              _.isEmpty(found)
                          ) {
                              fieldErrors[field.code] = [i18n.t('validations.field_is_required')];
                          }
                      }
                  }
              });

              if (_.isEmpty(fieldErrors)) {
                  setErrors([]);

                  return true;
              }

              setErrors(fieldErrors);
              return false;
          };

          const saveData = async (e) => {
              e.preventDefault();

              if (errors.length === 0) {
                  handleSubmit(e, mediaForUpload, mediaForRemove);
              } else {
                  toastr.error(i18n.t('alerts.error.please_check_all_fields'));
                  setDisplayErrors(true);
              }
          };

          return (
              loadedForm && (
               <FormBuilderWrapper>
                    <Form
                         noValidate
                         onSubmit={(e) => saveData(e)}
                         style={style}
                    >
                         {innerFormState.map((element, idx) => (
                              <React.Fragment key={idx}>
                                   {drawElements(element, innerFormState.length - idx)}
                              </React.Fragment>
                         ))}
                        <>
                            <hr className="body-divider" />
                            <div className="form-actions">
                                <Button
                                    variant="success"
                                    size="sm"
                                    type="submit"
                                    disabled={isUploading()}
                                >
                                    {t('buttons.save')}
                                    {
                                        (isUploading())
                                        ? (<Spinner animation="border" className="m-0 ml-2 p-0" size="sm" />)
                                        : ''
                                    }
                                </Button>
                            </div>
                        </>
                    </Form>
               </FormBuilderWrapper>
              )
          );
     }
);


export const SpeakerForm = connect(
    mapStateToProps
)(_SpeakerForm);
