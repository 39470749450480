import React from "react";

export const Overlay = () => {
    return (
        <div
            className="selection-element"
            style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 2
            }}
        >

        </div>
    );
};
