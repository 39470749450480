import React, { memo, lazy, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link, useParams, useLocation } from 'react-router-dom';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { formatDate } from '../../../../utils/date.utils';
import { getFullName } from '../../../../utils/string.utils';
import Breadcrumb from '../../../common/breadcrumb';
import LoadingBar from '../../../common/loading-bar';
import { ListItem } from '../../../common/collapsible-list/CollapsibleList';
import EventForm from './EventForm';
import { EventDropdown } from './EventDropdown';
import { OrganizationDropdown } from '../../Organization/sub-components/OrganizationDropdown';
import { SettingsImage } from '../../../../assets/icons/metronic/settings-image';
import SpeakersIcon from '../../../../assets/icons/metronic/speakers-icon';
import { GroupChatImage } from '../../../../assets/icons/metronic/group-chat-image';
import { DotImage } from '../../../../assets/icons/metronic/dot-image';
import { requestOrganizationDetails } from '../../../../redux/reducers/organizationReducer';
import { requestEventDetails } from '../../../../redux/reducers/eventReducer';

import EventsWrapper from '../Event.style';

const MainDrawer = lazy(() => import('../../../common/drawer'));
const CollapsibleList = lazy(() => import('../../../common/collapsible-list'));

const mapStateToProps = (state) => ({
     organization: state.organizationReducer.organization,
     eventData: state.eventReducer.eventData,
});

const mapDispatchToProps = (dispatch) => ({
     requestOrganizationDetails: (id) =>
          dispatch(requestOrganizationDetails(id)),
     requestEventDetails: (payload) => dispatch(requestEventDetails(payload)),
});

export const _EditEvent = memo((props) => {
     const {
          requestOrganizationDetails,
          requestEventDetails,
          organization,
          eventData,
     } = props;

     const { id, eventId } = useParams();
     const { pathname } = useLocation();
     const { t } = useTranslation();

     useEffect(() => {
          requestOrganizationDetails(id);
          requestEventDetails({ organizationId: id, eventId });
     }, [pathname]);

     const links = [
          {
               to: `/organization/${id}/settings`,
               text: `${organization && organization.name}`,
          },
          {
               to: `/organization/${id}/events`,
               text: i18n.t('events'),
          },
          {
               to: `/organization/${id}/events/${eventId}/speakers`,
               text: `${eventData && eventData.name}`,
          },
          {
               to: `/organization/${id}/events/${eventId}/event-edit`,
               text: i18n.t('event.edit_event'),
          },
     ];

     const eventName = eventData && eventData.name;
     const eventDate =
          eventData &&
          formatDate({
               startDate: eventData.start,
               endDate: eventData.end,
          });
     const eventManagers =
          eventData &&
          eventData.managers.map(
               ({ user_id, firstname, lastname, is_owner }) => ({
                    is_owner,
                    value: getFullName(firstname, lastname),
                    id: user_id,
               })
          );
     const start = eventData && moment(eventData.start).format('YYYY-MM-DD');
     const end = eventData && moment(eventData.end).format('YYYY-MM-DD');

     const eventPhoto = eventData && eventData.photos && eventData.photos['240x240'];

     const isPhotoDefault = eventData&& eventData.is_photo_default;

     return (
          <EventsWrapper>
               <Helmet>
                    <title>
                         {t('event.edit_event')} | {t('avovent')}
                    </title>
               </Helmet>
               <MainDrawer>
                    <ListItem to="/dashboard">
                         {t('breadcrumb.dashboard')}
                    </ListItem>
                    <CollapsibleList
                         listHeader={organization && organization.name}
                         listContent={
                              <>
                                   <ListItem
                                        to={`/organization/${id}/events/${eventId}/event-edit`}
                                   >
                                        <div className="collapsible-list-item">
                                             <GroupChatImage />
                                             <div className="sub-item">
                                                  {t('events')}
                                             </div>
                                        </div>
                                   </ListItem>
                                   <CollapsibleList
                                        listHeader={
                                             <div className="collapsible-list-item">
                                                  <SettingsImage />
                                                  <div className="sub-item">
                                                       {t(
                                                            'organization_settings.organization_settings'
                                                       )}
                                                  </div>
                                             </div>
                                        }
                                        listContent={
                                             <>
                                                  <ListItem
                                                       subItem
                                                       to={`/organization/${id}/settings`}
                                                  >
                                                       <div className="collapsible-list-item">
                                                            <DotImage />
                                                            <div className="sub-item">
                                                                 {t(
                                                                      'organization_settings.organization_settings'
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </ListItem>
                                                  <ListItem
                                                       subItem
                                                       to={`/organization/${id}/members`}
                                                  >
                                                       <div className="collapsible-list-item">
                                                            <DotImage />
                                                            <div className="sub-item">
                                                                 {t(
                                                                      'organization_settings.team_members'
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </ListItem>
                                                  <ListItem
                                                       subItem
                                                       to={`/organization/${id}/email-senders`}
                                                  >
                                                       <div className="collapsible-list-item">
                                                            <DotImage />
                                                            <div className="sub-item">
                                                                 {t(
                                                                      'organization_settings.email_senders'
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </ListItem>
                                             </>
                                        }
                                   />
                              </>
                         }
                    />
                    <CollapsibleList
                         listHeader={eventData && eventData.name}
                         expanded={
                              window.location.pathname ===
                              `/organization/${id}/events/${eventId}/event-edit`
                         }
                         listContent={
                              <>
                                   <ListItem
                                        to={`/organization/${id}/events/${eventId}/speakers`}
                                   >
                                        <div className="collapsible-list-item">
                                             <SpeakersIcon />
                                             <div className="sub-item">
                                                  {t('event.speakers')}
                                             </div>
                                        </div>
                                   </ListItem>
                                   <ListItem
                                        to={`/organization/${id}/events/${eventId}/event-edit`}
                                   >
                                        <div className="collapsible-list-item">
                                             <SettingsImage />
                                             <div className="sub-item">
                                                  {t('event.event_settings')}
                                             </div>
                                        </div>
                                   </ListItem>
                              </>
                         }
                    />
               </MainDrawer>
               <div className="content--border">
                    <div
                         className="container-standard"
                         style={{ display: 'flex', alignItems: 'center' }}
                    >
                         <div className="nav">
                              <Link to="/dashboard" className="inactive">
                                   {t('breadcrumb.dashboard')}
                              </Link>
                              <OrganizationDropdown organization={organization} inactive/>
                              <EventDropdown />
                         </div>
                    </div>
                    <LoadingBar finish={!!eventData} />
               </div>
               <div className="container-standard">
                    {organization && eventData && (
                         <Breadcrumb
                              title={t('event.edit_event')}
                              links={links}
                         />
                    )}
               </div>
               <div className="container-standard">
                    <div className="user-content-body card">
                         <h3 className="card-title">
                              {eventData && eventData.id === parseInt(eventId) && eventData.name}
                         </h3>
                         <section>
                              <div className="card-body">
                                   {eventData && eventData.id === parseInt(eventId) && (
                                       <EventForm
                                           dangerZone
                                           btnText={t('buttons.save')}
                                           eventName={eventName}
                                           eventDate={eventDate}
                                           eventManagers={eventManagers}
                                           eventPhoto={eventPhoto}
                                           allPhotos={eventData && eventData.photos}
                                           start={start}
                                           end={end}
                                           isPhotoDefault={isPhotoDefault}
                                       />
                                   )}
                              </div>
                         </section>
                    </div>
               </div>
          </EventsWrapper>
     );
});

export const EditEvent = connect(
     mapStateToProps,
     mapDispatchToProps
)(_EditEvent);
