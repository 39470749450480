import React from 'react';

function IconFile() {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="15"
               height="18"
               fill="none"
               viewBox="0 0 15 18"
          >
               <path
                    fill="currentColor"
                    d="M10.273 0H1.8C.807 0 0 .808 0 1.8v14.4c0 .993.807 1.8 1.8 1.8h10.8c.993 0 1.8-.807 1.8-1.8V4.127L10.273 0zM1.8 16.2V1.8H9v3.6h3.6v10.8H1.8z"
               ></path>
          </svg>
     );
}

export default IconFile;
