import i18n from 'i18next';
import toastr from 'toastr';
import {call, put, all, takeEvery, delay} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
     successUserOrganization,
     failUserOrganization,
     successOrganizationDetails,
     failOrganizationDetails,
     successOrganizationDetailsUpdate,
     successOrganizationCreation,
     failOrganizationCreation,
     failOrganizationDetailsUpdate,
     successOrganizationPhotoUpload,
     failOrganizationPhotoUpload,
     successOrganizationMembers,
     failOrganizationMembers,
     successUpdateMemberScope,
     failUpdateMemberScope,
     successMembersInvite,
     failMembersInvite,
     successRemoveMember,
     failRemoveMember,
     successOrganizationEmailSenders,
     failOrganizationEmailSenders,
     successCreateEmailSender,
     failCreateEmailSender,
     successRemoveEmailSender,
     failRemoveEmailSender,
     getMembersPageDetails,
     getEmailSendersPageDetails,
     successDisplayJoinInvitation,
     failDisplayJoinInvitation,
     successJoinOrganization,
     failJoinOrganization,
     refreshOrganizationAvatars,
     successRemoveOrganizationAvatar,
     failRemoveOrganizationAvatar,
     successVerifyEmailSender,
     failVerifyEmailSender,
     successVerifyResendEmailSender,
     failVerifyResendEmailSender,
     successOrganizationEmailSendersAll,
     failOrganizationEmailSendersAll,
     successOrganizationMembersInvitations,
     getMembersInvitationsPageDetails,
     failOrganizationMembersInvitations,
     successOrganizationMembersInvitationsResend,
     failOrganizationMembersInvitationsResend,
     successOrganizationMembersInvitationsRemove, failOrganizationMembersInvitationsRemove,
} from '../reducers/organizationReducer';
import * as ACTIONS from '../actions';
import * as Service from '../../services';
import {
     // refreshAvatars,
     refreshAvatarsWholeList,
} from '../../services/organizationService';
import { handleErrors } from '../../utils/sagaUtils';
import {errorsDisplay} from "../reducers/errorsReducer";

export function* userOrganizationSaga() {
     const response = yield call(Service.getUserOrganization);

     if (response.status === 200) {
          const organizations = refreshAvatarsWholeList(response.data.data);

          return yield put(successUserOrganization(organizations));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     if (response.status === 404) {
          return yield put(push('/not-found-404'));
     }

     yield put(failUserOrganization(response.message));
}

export function* organizationDetailsSaga({ payload }) {
     const response = yield call(Service.getOrganizationDetails, payload);

     if (response.status === 200) {
          return yield put(successOrganizationDetails(response.data.data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     if (response.status === 403 || response.status === 404) {
          return yield put(push('/not-found-404'));
     }

     yield put(failOrganizationDetails(response.data.message));
}

export function* organizationDetailsUpdateSaga({ payload }) {
     const response = yield call(Service.updateOrganizationDetails, payload);

     if (response.status === 200) {
          toastr.success(i18n.t('alerts.success.changes_saved'));
          yield put(successOrganizationDetails(response.data.data));

          return yield put(
               successOrganizationDetailsUpdate(
                    i18n.t('alerts.success.changes_saved')
               )
          );
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }
     response.errors && handleErrors(response.errors);
     yield put(failOrganizationDetailsUpdate(response.message));
}

export function* organizationCreationSaga({ payload }) {
     const response = yield call(Service.createNewOrganization, payload);
     yield delay(1000);

     if (response.status === 201) {
          yield put(successOrganizationCreation());

          return window.location.replace('/');
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     response.errors && handleErrors(response.errors);
     yield put(failOrganizationCreation(response.message));
}

export function* organizationPhotoUploadSaga({ payload }) {
     const response = yield call(Service.organizationPhotoUpload, payload);

     if (response.status === 200) {
          yield put(refreshOrganizationAvatars({
               id: payload.organizationId,
               photos: response.data.data,
               is_photo_default: false
          }));

          return yield put(
               successOrganizationPhotoUpload(response.data.message)
          );
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }
     response.errors && handleErrors(response.errors);
     yield put(failOrganizationPhotoUpload(response.message));
}

export function* organizationMembersSaga({ payload }) {
     const response = yield call(Service.getOrganizationMembers, payload);

     if (response.status === 200) {
          const { data, ...pageDetails } = response.data;
          yield put(getMembersPageDetails(pageDetails));
          return yield put(successOrganizationMembers(data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     if (response.status === 404) {
          return yield put(push('/not-found-404'));
     }

     yield put(failOrganizationMembers(response.message));
}

export function* organizationMembersInvitationsSaga({ payload }) {
     const response = yield call(Service.getOrganizationMembersInvitations, payload);

     if (response.status === 200) {
          const { data, ...pageDetails } = response.data;
          yield put(getMembersInvitationsPageDetails(pageDetails));
          return yield put(successOrganizationMembersInvitations(data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     if (response.status === 404) {
          return yield put(push('/not-found-404'));
     }

     yield put(failOrganizationMembersInvitations(response.message));
}

export function* organizationMembersInvitationsResendSaga({ payload }) {
     const response = yield call(Service.organizationMembersInvitationsResend, payload);

     if (response.status === 200) {
          toastr.success(i18n.t('alerts.success.member_invitation_resend'));
          return yield put(successOrganizationMembersInvitationsResend('success'));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     yield put(failOrganizationMembersInvitationsResend(response.message));
}

export function* organizationMembersInvitationsRemoveSaga({ payload }) {
     const response = yield call(Service.organizationMembersInvitationsRemove, payload);

     if (response.status === 200) {
          const { message } = payload;

          toastr.success(i18n.t('alerts.success.member_invitation_removed'));
          return yield put(successOrganizationMembersInvitationsRemove(message));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     yield put(failOrganizationMembersInvitationsRemove(response.message));
}

export function* updateMemberScopeSaga({ payload }) {
     const response = yield call(Service.updateMemberScope, payload);

     if (response.status === 200) {
          return yield put(successUpdateMemberScope(response.data.data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     yield put(failUpdateMemberScope(response.message));
}

export function* inviteMembersSaga({ payload }) {
     const response = yield call(Service.inviteMembers, payload);

     if (response.status === 200) {
          toastr.success(i18n.t('alerts.success.invitations_send'));

          if (response.data.data.duplicated) {
               toastr.warning(
                   i18n.t('alerts.warning.found_duplicated',
                       {duplicated: response.data.data.duplicated}
                       )
               );
          }

          return yield put(successMembersInvite('success'));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     if (response.status === 402) {
          toastr.error(i18n.t('alerts.error.tier_max_team_members_reached'));
     }

     if (response.status === 423) {
          toastr.error(i18n.t('organization_settings.your_organization_have_been_suspended'));
     }

     yield put(failMembersInvite(response.message));
}

export function* removeMemberSaga({ payload }) {
     const response = yield call(Service.removeMember, payload);

     if (response.status === 200) {
          const { message } = payload;

          return yield put(successRemoveMember(message));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     yield put(failRemoveMember(response.message));
}

export function* organizationEmailSendersSaga({ payload }) {
     const response = yield call(Service.getOrganizationEmailSenders, payload);

     if (response.status === 200) {
          const { data, ...pageDetails } = response.data;
          yield put(getEmailSendersPageDetails(pageDetails));
          return yield put(successOrganizationEmailSenders(data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     if (response.status === 404) {
          return yield put(push('/not-found-404'));
     }

     yield put(failOrganizationEmailSenders(response.message));
}

export function* organizationEmailSendersAllSaga({ payload }) {
     const response = yield call(Service.getAllOrganizationEmailSenders, payload);

     if (response.status === 200) {
          const { data } = response.data;
          return yield put(successOrganizationEmailSendersAll(data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     if (response.status === 404) {
          return yield put(push('/not-found-404'));
     }

     yield put(failOrganizationEmailSendersAll(response.message));
}

export function* createEmailSenderSaga({ payload }) {
     const response = yield call(Service.createEmailSender, payload);

     if (response.status === 201) {
          toastr.success(i18n.t('alerts.success.sender_created'));
          return yield put(successCreateEmailSender('success'));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     if (response.status === 400 || response.status === 422) {
          yield put(errorsDisplay({ errors: response.data.errors, message: response.data.message }));
     }

     yield put(failCreateEmailSender(response.message));
}

export function* verifyEmailSenderSaga({ payload }) {
     const response = yield call(Service.verifyEmailSender, payload);

     if (response.status === 200) {
          toastr.success(i18n.t('alerts.success.sender_verify'));
          yield put(successVerifyEmailSender('success'));

          return yield put(push(`/organization/${payload.organizationId}/email-senders`));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     toastr.error(i18n.t('alerts.error.sender_verify'));

     yield put(failVerifyEmailSender(response.message));
     return yield put(push(`/organization/${payload.organizationId}/email-senders`));
}

export function* verifyResendEmailSenderSaga({ payload }) {
     const response = yield call(Service.verifyResendEmailSender, payload);

     if (response.status === 200) {
          toastr.success(i18n.t('alerts.success.sender_verify_resend'));
          return yield put(successVerifyResendEmailSender('success'));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }


     yield put(failVerifyResendEmailSender(response.message));
}

export function* removeEmailSenderSaga({ payload }) {
     const response = yield call(Service.removeEmailSender, payload);

     if (response.status === 200) {
          toastr.success(i18n.t('alerts.success.sender_removed'));
          return yield put(successRemoveEmailSender('success'));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     yield put(failRemoveEmailSender(response.message));
}

export function* displayJoinInvitationSaga({ payload }) {
     const response = yield call(Service.displayJoinInvitation, payload);

     if (response.status === 200) {
          return yield put(successDisplayJoinInvitation(response.data.data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     if (response.status === 400) {
          toastr.error(i18n.t('alerts.error.organization_invitation_used'));

          return yield put(push('/dashboard'));
     }

     if (response.status === 404) {
          toastr.error(i18n.t('alerts.error.organization_invitation_not_found'));
          
          return yield put(push('/dashboard'));
     }

     yield put(failDisplayJoinInvitation(response.message));
}

export function* joinOrganizationSaga({ payload }) {
     const response = yield call(Service.joinOrganization, payload);

     if (response.status === 200) {
          yield put(successJoinOrganization(response.data.data));

          return yield put(push('/dashboard'));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     if (response.status === 404) {
          return yield put(push('/not-found-404'));
     }

     if (response.status === 400 || response.status === 422) {
          toastr.error(i18n.t('alerts.error.invitation_doesnt_exists_or_expired'));
     }

     response.errors && handleErrors(response.errors);
     yield put(failJoinOrganization(response.message));
}

export function* removeOrganizationAvatarSaga({ payload }) {
     const response = yield call(Service.removeOrganizationAvatar, payload);

     if (response.status === 200) {
          yield put(refreshOrganizationAvatars({
               id: payload.organizationId,
               photos: response.data.data,
               is_photo_default: true
          }));

          return yield put(successRemoveOrganizationAvatar(response.message));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     yield put(failRemoveOrganizationAvatar(response.message));
}

export function* watchOrganization() {
     yield all([
          takeEvery(ACTIONS.USER_ORGANIZATION_REQUEST, userOrganizationSaga),
          takeEvery(ACTIONS.ORGANIZATION_CREATION_REQUEST, organizationCreationSaga),
          takeEvery(ACTIONS.ORGANIZATION_DETAILS_REQUEST, organizationDetailsSaga),
          takeEvery(ACTIONS.UPDATE_ORGANIZATION_DETAILS_REQUEST, organizationDetailsUpdateSaga),
          takeEvery(ACTIONS.ORGANIZATION_PHOTO_UPLOAD_REQUEST, organizationPhotoUploadSaga),
          takeEvery(ACTIONS.ORGANIZATION_MEMBERS_REQUEST, organizationMembersSaga),
          takeEvery(ACTIONS.ORGANIZATION_MEMBERS_INVITATIONS_REQUEST, organizationMembersInvitationsSaga),
          takeEvery(ACTIONS.ORGANIZATION_MEMBERS_INVITATIONS_RESEND_REQUEST, organizationMembersInvitationsResendSaga),
          takeEvery(ACTIONS.ORGANIZATION_MEMBERS_INVITATIONS_REMOVE_REQUEST, organizationMembersInvitationsRemoveSaga),
          takeEvery(ACTIONS.UPDATE_MEMBER_SCOPE_REQUEST, updateMemberScopeSaga),
          takeEvery(ACTIONS.INVITE_MEMBERS_REQUEST, inviteMembersSaga),
          takeEvery(ACTIONS.REMOVE_MEMBER_REQUEST, removeMemberSaga),
          takeEvery(ACTIONS.ORGANIZATION_EMAIL_SENDERS_REQUEST, organizationEmailSendersSaga),
          takeEvery(ACTIONS.ORGANIZATION_EMAIL_SENDERS_ALL_REQUEST, organizationEmailSendersAllSaga),
          takeEvery(ACTIONS.CREATE_EMAIL_SENDER_REQUEST, createEmailSenderSaga),
          takeEvery(ACTIONS.VERIFY_EMAIL_SENDER_REQUEST, verifyEmailSenderSaga),
          takeEvery(ACTIONS.VERIFY_RESEND_EMAIL_SENDER_REQUEST, verifyResendEmailSenderSaga),
          takeEvery(ACTIONS.REMOVE_EMAIL_SENDER_REQUEST, removeEmailSenderSaga),
          takeEvery(ACTIONS.DISPLAY_JOIN_INVITATION_REQUEST, displayJoinInvitationSaga),
          takeEvery(ACTIONS.JOIN_ORGANIZATION_REQUEST, joinOrganizationSaga),
          takeEvery(ACTIONS.REMOVE_ORGANIZATION_AVATAR_REQUEST, removeOrganizationAvatarSaga),
     ])
}
