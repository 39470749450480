import React, { memo, lazy, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { Switch, Route, Link, useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Breadcrumb from '../../common/breadcrumb';
import { SettingsForm } from './forms/SettingsForm';
import { ChangePasswordForm } from './forms/ChangePasswordForm';
import {cropString, getFullName} from '../../../utils/string.utils';
import { ShieldUser } from '../../../assets/icons/metronic/shield-user';
import { User } from '../../../assets/icons/metronic/user';
import Avatar from '../../common/avatar';
import LoadingBar from '../../common/loading-bar';
import SideBar from '../../common/sidebar';
import Portlet from '../../common/portlet';
import { Drawer } from '../../common/drawer/MainDrawer';
import { ListItem } from '../../common/collapsible-list';

import UserSettingsWrapper from './UserSettings.style';
import MailIcon from "../../../assets/icons/metronic/mail-icon";
import {ChangeEmailForm} from "./forms/ChangeEmailForm";

const SidebarToggler = lazy(() => import('../../common/sidebar-toggler'));

const MainDrawer = lazy(() => import('../../common/drawer'));

const mapStateToProps = (state) => ({
     router: state.router,
     user: state.userReducer,
     drawer: state.ui,
});

const mapDispatchToProps = (dispatch) => ({
     push: (location) => dispatch(replace(location)),
     setDefaultLoaction: () => dispatch(replace('/user-settings/settings')),
});

export const _UserSettings = memo((props) => {
     const {
          setDefaultLoaction,
          user: { currentUser, refreshCurrentUserAvatar },
          router: {
               location: { pathname },
          },
          drawer: { drawerShown },
     } = props;

     const avatars = useRef();

     useEffect(() => {
          if (currentUser) {
               avatars.current = currentUser.avatars && currentUser.avatars['180x180'];
          }
     }, [currentUser, refreshCurrentUserAvatar]);

     const { t } = useTranslation();
     const history = useHistory();

     const links = [
          { to: '/user-settings', text: t('breadcrumb.account_settings') },
     ];

     useEffect(() => {
          if (pathname === '/user-settings/change-password') {
               return history.replace(pathname);
          }

          if (pathname === '/user-settings') {
               setDefaultLoaction();
          }
     }, [setDefaultLoaction]);

     const sidebarLinks = [
          {
               to: '/user-settings/settings',
               icon: <User className="kt-svg-icon" />,
               text: t('user_settings.account_settings'),
          },
          {
               to: '/user-settings/email',
               icon: <MailIcon />,
               text: t('user_settings.email_setting'),
          },
          {
               to: '/user-settings/change-password',
               icon: <ShieldUser className="kt-svg-icon" />,
               text: t('user_settings.change_password'),
          },
     ];

     return (
          <UserSettingsWrapper>
               <Helmet>
                    <title>
                         {t('settings')} | {t('avovent')}
                    </title>
               </Helmet>
               <MainDrawer>
                    <ListItem to="/dashboard">
                         {t('breadcrumb.dashboard')}
                    </ListItem>
               </MainDrawer>
               {drawerShown && (
                    <Drawer>
                         <div style={{ padding: '35px 15px 15px 15px' }}>
                              <SideBar
                                   style={{
                                        boxShadow:
                                             '0px 0px 30px 0px rgba(82, 63, 105, 0.1)',
                                   }}
                                   picture={
                                        <Avatar
                                             src={
                                                  avatars.current
                                                       ? avatars.current
                                                       : currentUser.avatars[
                                                              '180x180'
                                                         ]
                                             }
                                             height={90}
                                             width={90}
                                        />
                                   }
                                   title={getFullName(
                                        currentUser.firstname,
                                        currentUser.lastname
                                   )}
                                   subtitle={
                                        currentUser ? currentUser.email : ''
                                   }
                                   links={sidebarLinks}
                              />
                         </div>
                    </Drawer>
               )}
               <div className="content--border">
                    <div className="container-standard">
                         <Link to="/dashboard">
                              <Button className="btn-themed-blue btn-navigational">
                                   {t('breadcrumb.dashboard')}
                              </Button>
                         </Link>
                    </div>
                    <LoadingBar finish={!!currentUser} />
               </div>
               <div
                    className="container-standard"
                    style={{ display: 'flex', alignItems: 'center' }}
               >
                    <SidebarToggler />
                    <Breadcrumb
                         title={t('breadcrumb.account_settings')}
                         links={links}
                    />
               </div>
               <div className="container-standard">
                    <div className="app-grid">
                         <div className="not-sr">
                              <SideBar
                                   picture={
                                        <Avatar
                                             src={
                                                  avatars.current
                                                       ? avatars.current
                                                       : currentUser.avatars && currentUser.avatars[
                                                              '180x180'
                                                         ]
                                             }
                                             height={90}
                                             width={90}
                                             style={{marginRight: '22.3px'}}
                                        />
                                   }
                                   title={getFullName(
                                        currentUser.firstname,
                                        currentUser.lastname
                                   )}
                                   subtitle={
                                        currentUser ? cropString(currentUser.email, 25) : ''
                                   }
                                   links={sidebarLinks}
                              />
                         </div>
                         <Portlet
                              title={t('user_settings.account_settings')}
                              content={
                                   <Switch>
                                        <Route path="/user-settings/settings">
                                             <SettingsForm />
                                        </Route>
                                        <Route path="/user-settings/email">
                                             <ChangeEmailForm />
                                        </Route>
                                        <Route path="/user-settings/change-password">
                                             <ChangePasswordForm />
                                        </Route>
                                   </Switch>
                              }
                         />
                    </div>
               </div>
          </UserSettingsWrapper>
     );
});

export const UserSettings = connect(
     mapStateToProps,
     mapDispatchToProps
)(_UserSettings);
