import styled from 'styled-components';
import {
     COLORS,
     FONT,
     FORM_PLACEHOLDER,
     SIZE,
} from '../../../constants/style.constants';

const RemindersWrapper = styled.div`
     .mt-30 {
          margin-top: 30px;
     }

     .disabled {
          background-color: #e9ecef !important;
     }

     .content {
          display: flex;
          align-items: start;
          justify-content: space-between;
          min-height: 85vh;
     }
     .table-container {
          margin-bottom: 30px;
     }

     .btn-outline-primary {
          border: 1px solid #e1e4ec;
          border-radius: 4px;
          color: ${COLORS.TEXT_GREY};

          &:hover {
               color: ${COLORS.WHITE};
               border: 1px solid ${COLORS.BLUE};
               background-color: ${COLORS.BLUE};
          }
          &:not(:last-child) {
               margin-right: 10px;
          }
     }

     input::-webkit-outer-spin-button,
     input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
     }
     input[type='number'] {
          -moz-appearance: textfield;
     }

     .new-reminder {
          .card {
               margin-bottom: 50px;
          }

          .card-body {
               padding: 0 !important;
          }

          .headline {
               font-weight: ${FONT.MEDIUM};
               font-size: ${SIZE.GREAT};
               line-height: 26px;
               color: ${COLORS.DARK_BLUE};
               margin-bottom: 30px;
          }

          .hint {
               font-weight: ${FONT.REGULAR};
               font-size: ${SIZE.NORMAL};
               line-height: 18px;
               color: ${COLORS.DARK_BLUE};
          }

          .form-label {
               font-weight: ${FONT.REGULAR};
               font-size: ${SIZE.NORMAL};
               line-height: 18px;
               color: ${COLORS.DARK_BLUE};
               margin-bottom: 10px;
          }

          .actions-section {
               border-top: 1px solid ${COLORS.LIGHTER_BLUE};
               max-width: 790px;
               
               .row {
                    margin-top: 20px;
                    margin-left: 0;
                    margin-right: 0;
                    
                    &.buttons {
                        margin-top: 30px;
                        display: flex;
                        flex-direction: row-reverse;
                        justify-content: space-between;
                    }
                }
                
                .alert {
                    margin: 0;
                }
                
                .btn-default {
                     padding: 15px 23px;
                }
                .btn-success {
                     padding: 15px 41px;
                }
                
                .btn {
                    &:disabled {
                        background-color: ${COLORS.LIGHTER_BLUE};
                    }
                }
          }

          .btn-danger {
               padding: 7px;
               margin-top: 9px;
               margin-left: 5px;
               height: 25px;
               width: 25px;
               display: flex;
               align-content: center;

               background-color: transparent;
               border: 1px solid ${COLORS.LIGHTER_BLUE};
               border-radius: 2px;
               position: absolute;
               right: -40px;

               svg {
                    height: 10px;
                    width: 10px;
               }

               &:hover {
                    background-color: ${COLORS.RED};
                    border: 1px solid ${COLORS.RED};
               }
               &:disabled {
                    background-color: ${COLORS.PALE_BLUE} !important;
               }

               &:disabled:hover {
                    border: 1px solid ${COLORS.LIGHTER_BLUE};
               }
          }
          .tab-panel {
               padding: 60px 265px;
          }
          form {
               margin-top: 30px;
          }

          .form-control {
               border-radius: 4px !important;
               padding: 12px 42px 12px 15px !important;
               height: 41px;
               font-weight: ${FONT.REGULAR} !important;
               color: ${COLORS.TEXT_GREY} !important;
               font-size: ${SIZE.NORMAL};
               /* line-height: 18px !important; */
          }

          input::placeholder {
               ${FORM_PLACEHOLDER};
               color: ${COLORS.PLACEHOLDER_GREY};
          }

          .input-group {
               background-color: transparent;
               z-index: 1;

               input {
                    border-radius: 4px !important;
                    background-color: transparent;
                    z-index: 1;
               }
               
               input:disabled {
                    background-color: ${COLORS.LIGHT_GREY};
               }

               .suffix {
                    border-radius: 4px !important;
                    display: flex;
                    align-items: center;
                    position: absolute;
                    top: 1px;
                    right: 15px;
                    height: 39px;
                    /* width: 20px; */
                    z-index: -1;

                    svg,
                    .la-calendar-check:before {
                         color: ${COLORS.PLACEHOLDER_GREY};
                    }
               }
          }

          .checkbox-solid {
               display: flex;
               align-content: center;
               cursor: pointer;
               position: relative;
               padding-left: 30px;
               transition: all 0.15s ease;

               span {
                    background-color: ${COLORS.LIGHTER_BLUE};
                    border-radius: 2px;
                    position: absolute;
                    top: 1px;
                    left: 0;
                    height: 18px;
                    width: 18px;
                    padding-right: 10px;

                    &:after {
                         display: none;
                    }
               }

               .form-label {
                    line-height: 1.5 !important;
                    margin-bottom: 0 !important;
               }
          }

          input[type='checkbox'] {
               position: absolute;
               z-index: -1;
               opacity: 0;
          }

          input[type='checkbox']:checked ~ span {
               background-color: ${COLORS.BLUE};

               &:after {
                    content: '';
                    position: absolute;
                    display: none;
                    top: 50%;
                    left: 50%;
                    margin-left: -2px;
                    margin-top: -6px;
                    width: 5px;
                    height: 10px;
                    border-width: 0 2px 2px 0 /*rtl:ignore*/ !important;
                    transform: rotate(45deg) /*rtl:ignore*/;
                    border: solid ${COLORS.WHITE};
                    display: block;
               }
          }

          .button-group {
               .btn {
                    &:not(:last-child) {
                         margin-right: 10px;
                    }
               }
               .dynamic-content {
                    height: 32px;
                    line-height: 12px;
                    padding: 9px 20px;
               }
               .btn-outline-primary {
                    border: 1px solid #e1e4ec;
                    border-radius: 4px;
                    color: ${COLORS.TEXT_GREY};

                    &:hover {
                         background-color: ${COLORS.BLUE};
                         border: 1px solid ${COLORS.BLUE};
                         color: ${COLORS.WHITE};
                    }
               }
          }

          .ql-snow {
               border: 1px solid ${COLORS.LIGHTER_BLUE};
               color: ${COLORS.DARK_BLUE};

               &.ql-toolbar {
                    border-bottom: 1px solid ${COLORS.LIGHTER_BLUE};
               }

               .ql-picker-label:before,
               .ql-picker-item:before {
                    color: ${COLORS.DARK_BLUE};
               }

               .ql-picker-label:hover:before,
               .ql-picker-item:hover:before,
               .ql-selected:before {
                    color: ${COLORS.BLUISH};
               }

               .ql-picker-label {
                    .ql-fill {
                         fill: ${COLORS.DARK_BLUE};
                    }

                    .ql-stroke {
                         stroke: ${COLORS.DARK_BLUE};
                    }

                    &:hover {
                         .ql-fill {
                              fill: ${COLORS.BLUISH};
                         }
                         .ql-stroke {
                              stroke: ${COLORS.BLUISH};
                         }
                    }
               }

               button {
                    .ql-fill {
                         fill: ${COLORS.DARK_BLUE};
                    }
                    .ql-stroke {
                         stroke: ${COLORS.DARK_BLUE};
                    }
               }

               button:hover {
                    .ql-fill {
                         fill: ${COLORS.BLUISH};
                    }
                    .ql-stroke {
                         stroke: ${COLORS.BLUISH};
                    }
               }

               &:first-child {
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
               }

               &:last-child {
                    height: 250px;
                    border-bottom-left-radius: 4px;
                    border-bottom-right-radius: 4px;
               }
          }

          .quill > .ql-container > .ql-editor.ql-blank::before {
               color: ${COLORS.PLACEHOLDER_GREY};
               text-decoration: none;
               font-style: normal;
               font-size: ${SIZE.NORMAL};
          }

          .show,
          .dropdown {
               box-shadow: 0 0 0 2px #fff;
               width: 100%;

               .dropdown-item {
                    color: ${COLORS.DARK_BLUE};
                    font-size: ${SIZE.NORMAL};

                    &:active {
                         color: ${COLORS.DARK_BLUE};
                         background-color: ${COLORS.PALE_BLUE};
                    }
               }
          }

          .select-dropdown-toggle {
               display: flex;
               align-items: center;
               background-color: transparent;
               text-decoration: none;
               border-radius: 4px;
               border: 1px solid #ced4da;
               width: 100%;
          }

          .radio-group {
               transition: all 0.15s ease;
               display: flex;
               align-items: center;
               flex-wrap: wrap;

               .form-label {
                    cursor: pointer;
                    display: flex;
                    position: relative;
                    margin: 0 !important;

                    &:not(:first-child) {
                         margin-left: 15px !important;
                    }

                    .circle-outer {
                         max-height: 18px;
                         min-width: 18px;
                         margin-right: 5px;
                         background-color: ${COLORS.LIGHTER_BLUE};
                         border-radius: 50%;
                         z-index: 1;

                         .circle-inner {
                              position: absolute;
                              background-color: ${COLORS.LIGHTER_BLUE};
                              border-radius: 50%;
                              height: 6px;
                              width: 6px;
                              top: 6px;
                              left: 6px;
                         }
                    }

                    input:checked ~ .circle-outer {
                         position: relative;
                         background-color: ${COLORS.BLUISH};

                         .circle-inner {
                              background-color: ${COLORS.WHITE};
                         }
                    }
               }

               input[type='radio'] {
                    position: absolute;
                    opacity: 0;
                    z-index: -1;
               }
          }
          
          .language-holder {
                border-bottom: 1px solid #dee1eb;
                padding: 10px 265px;
                position: -webkit-sticky;
                position: sticky;
                top: 0;
                background-color: ${COLORS.WHITE};
                z-index: 999;
          }
     
         .language-selector {
            text-align: right;
         }
     
        .language-buttons {

           .btn-outline-primary {
                border: 1px solid #e1e4ec;
                border-radius: 3px;
                color: ${COLORS.TEXT_GREY};
                padding: 9px 20px;

                &:hover {
                     color: ${COLORS.WHITE};
                     border: 1px solid ${COLORS.BLUE};
                     background-color: ${COLORS.BLUE};
                }
                &:not(:first-child) {
                     margin-left: 7px;
                }
           }

           .btn-primary {
                padding: 9px 20px;

                &:not(:first-child) {
                     margin-left: 7px;
                }
           }
           .btn-warning {
                background-color: ${COLORS.YELLOW};
                border: 1px solid ${COLORS.YELLOW};
                border-radius: 3px;
                color: ${COLORS.WHITE};
                padding: 9px 20px;
                &:hover {
                     color: ${COLORS.WHITE};
                     border: 1px solid ${COLORS.DARKER_YELLOW};
                     background-color: ${COLORS.DARKER_YELLOW};
                }
                &:not(:first-child) {
                     margin-left: 7px;
                }
           }

           .btn-outline-warning {
                background-color: ${COLORS.LIGHTER_YELLOW};
                border: 1px solid ${COLORS.LIGHTER_YELLOW};
                border-radius: 3px;
                color: ${COLORS.YELLOW};
                padding: 9px 20px;
                &:hover {
                     color: ${COLORS.WHITE};
                     border: 1px solid ${COLORS.YELLOW};
                     background-color: ${COLORS.YELLOW};
                }
                &:not(:first-child) {
                     margin-left: 7px;
                }
           }
        }
     }
     
     .is-warning {
        border: 1px solid ${COLORS.YELLOW};
        border-radius: 4px;
     }
     
     .quill-is-invalid {
        border: 1px solid ${COLORS.RED};
        border-radius: 4px;
     }
     
     .is-invalid.dropdown {
           .form-control {
               border-color: ${COLORS.RED};
           }
           
           .suffix {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
                background-repeat: no-repeat;
                background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
                background-position: center;
                right: 12px;
           }
           
           .suffix svg {
                color: transparent;
                width: 15px;
                height: 15px;
           }
      }

     @media only screen and (max-width: 1320px) {
          .tab-panel {
               padding: 15px !important;
          }
          form {
               margin: auto;
          }

          .content {
               flex-direction: column;
               align-items: unset;
               padding: 0;
          }
          .language-holder {
               padding: 10px;
          }
     }

     @media only screen and (max-width: 1023px) {
          .headline {
               font-size: ${SIZE.LARGER} !important;
               margin-bottom: 15px !important;
          }

          .row {
               height: auto !important;
          }

          .mobile-label {
               display: block;
          }

          .btn-danger {
               top: -10px !important;
               right: 0 !important;
               margin: 0 !important;
          }

          p,
          a,
          .form-control,
          .form-label,
          input::placeholder,
          .hint {
               font-size: ${SIZE.TINY} !important;
          }

          .speaker-row {
               margin-top: 15px;
          }

          .button-group {
               flex-direction: column;

               .btn {
                    &:not(:last-child) {
                         margin-right: 0 !important;
                         margin-bottom: 10px;
                    }
               }
          }

          .card-body {
               form {
                    margin-top: 30px !important;
                    min-width: 100%;
               }
          }

          .rg {
               margin-bottom: 10px;
          }
     }
`;

export default RemindersWrapper;
