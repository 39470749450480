import i18n from 'i18next';
import toastr from 'toastr';
import { takeEvery, call, put, all, delay } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
     successLogin,
     failLogin,
     successLogout,
     failLogout,
     successSignup,
     failSignup,
     successForgottenPassword,
     failForgottenPassword,
     successResetPassword,
     failResetPassword,
} from '../reducers/authReducer';
import { resetToInitialState} from '../reducers/userReducer';
import * as ACTIONS from '../actions';
import * as Service from '../../services';
import {errorsDisplay} from "../reducers/errorsReducer";

export function* loginSaga({ payload }) {
     const response = yield call(Service.authenticateUser, payload);

     if (response.data.success && response.data.data.token) {
          yield put(successLogin(response.data.data.token));
          const afterLoginRedirect = localStorage.getItem('after_login_redirect');
          const redirect = afterLoginRedirect ? afterLoginRedirect : '/';
          localStorage.removeItem('after_login_redirect');

          return yield put(push(redirect));
     }

     toastr.error(response.data.message);
     yield put(failLogin(response.data.message));

     if (payload.join_token) {
          yield put(push('/auth/invitation/' + payload.join_token));
     } else {
          yield put(push('/auth/login'));
     }
}

export function* logoutSaga() {
     const response = yield call(Service.logoutUser);

     if (response.status === 200) {
          toastr.success(i18n.t('alerts.success.logged_out'));
          yield put(successLogout());
          yield put(resetToInitialState());
          yield put({ type: ACTIONS.HIDE_TOOLBAR });
          return yield put(push('/auth/login'));
     }

     yield put(failLogout(response.message));
}

export function* signupSaga({payload}) {
     const response = yield call(Service.signupUser, payload);
     yield delay(1000);

     if (response.status === 200) {
          yield put(successSignup());
          yield put(successLogin(response.data.data.token));

          if (!payload.tier_id) {
               return window.location.replace('/');
          }
     }

     if (response.status >= 400 && response.status <= 422) {
          yield put(errorsDisplay({ errors: response.data.errors, message: response.data.message }));

          return yield put(failSignup(response.message));
     }
}

export function* forgottenPasswordSaga({ payload }) {
     const response = yield call(Service.forgottenPasswordUser, payload);

     if (response.status === 200) {
          toastr.success(
               i18n.t('alerts.success.password_instructions_emailed')
          );
          return yield put(successForgottenPassword(response.data.message));
     }

     toastr.error(response.data.message);
     yield put(failForgottenPassword(response.message));
}

export function* resetPasswordSaga({ payload }) {
     const response = yield call(Service.resetPasswordUser, payload);

     if (response.data.success && response.data.data.token) {
          toastr.success(i18n.t('alerts.success.your_password_changed'));
          yield put(successLogin(response.data.data.token));
          yield put(successResetPassword(
                    response.data.data.token && response.data.message
               ));
          return yield put(push('/'));
     }

     if (response.status === 404) {
          toastr.error(i18n.t('alerts.error.reset_token_already_used'));
     }

     if (response.status === 422 || response.status === 400) {
          yield put(errorsDisplay({ errors: response.data.errors, message: response.data.message }));
     }

     yield put(failResetPassword(response.message));
}

export function* watchAuth() {
     yield all([
          takeEvery(ACTIONS.LOGIN_REQUEST, loginSaga),
          takeEvery(ACTIONS.LOGOUT_REQUEST, logoutSaga),
          takeEvery(ACTIONS.SIGNUP_REQUEST, signupSaga),
          takeEvery(ACTIONS.FORGOTTEN_PASSWORD_REQUEST, forgottenPasswordSaga),
          takeEvery(ACTIONS.RESET_PASSWORD_REQUEST, resetPasswordSaga),
     ])
}
