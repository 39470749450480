import React, { memo, useState, useRef, useEffect } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import CustomToggle from '../../../common/custom-toggle';
import ChevronDownIcon from '../../../../assets/icons/metronic/chevron-down-icon';
import { constructCheckboxObject } from '../helpers';
import {isEmpty} from "underscore";
import {DEFAULT_ELEMENT_LENGTH} from "../../../../utils/formBuilder.utils";
import {Autosugestion} from "../../../common/autosugestion";

export const RadioSelectionSettings = memo(
     ({ selectedElement, handleSettingChange, handleRemoveElement, getMasterValue, fieldsList }) => {
          const { t } = useTranslation();

          const [state, setState] = useState();
          const [currentItem, setCurrentItem] = useState();
          const ref = useRef();
          const choices = state && state.choices;

          useEffect(() => {
              setState(selectedElement);
              const layoutOption = innerState.layoutOptions.find(layout => layout.option === selectedElement.layout);
              setInnerState({
                  ...innerState,
                  currentLayout: layoutOption,
              });
          }, [selectedElement]);

          useEffect(() => {
               if (state) {
                    handleSettingChange({
                         id: state.id,
                         name: 'choices',
                         value: state.choices,
                    });
               }
          }, [choices]);

          const [innerState, setInnerState] = useState({
               layoutOptions: [
                    { value: i18n.t('form_builder.one_column'), option: 'one_column' },
                    { value: i18n.t('form_builder.two_column'), option: 'two_column' },
                    { value: i18n.t('form_builder.three_column'), option: 'three_column' },
               ],
               currentLayout: { value: i18n.t('form_builder.one_column'), option: 'one_column' },
          });

          const handleChange = (name, { target: { value } }) => {
               setState({ ...state, [name]: value });
               handleSettingChange({
                    id: state.id,
                    name,
                    value,
               });
          };

          const handleLayoutChange = (value) => {
               setState({ ...state, layout: value });
               handleSettingChange({
                    id: state.id,
                    name: 'layout',
                    value,
               });
          };

          const handleCheckboxChange = ({ target: { checked } }) => {
               setState({ ...state, required: checked });
               handleSettingChange({
                    id: state.id,
                    name: 'required',
                    value: checked,
               });
          };

          const syncUpdateStates = (choicesList) => {
               setState({ ...state, choices: choicesList });

               handleSettingChange({
                    id: state.id,
                    name: 'choices',
                    value: choicesList,
               });
          };

          const handleChoiceChange = (id, { target: { value } }) => {
               let choicesList = [...state.choices];

               choicesList = choicesList.map((choice) => {
                    if (choice.id === id) {
                         return {
                              ...choice,
                              label: value,
                         };
                    }

                    return choice;
               });

               setState({ ...state, choices: choicesList });
          };

          const handleChoicePreCheck = (id, { target: { checked } }, same) => {
               let choicesList = [...state.choices];
               choicesList = choicesList.map((choice) => {
                    if (same) {
                         return {
                              ...choice,
                              preChecked: false,
                         };
                    }

                    if (choice.id === id) {
                         return {
                              ...choice,
                              preChecked: checked,
                         };
                    }
                    return state.multipleSelections
                         ? choice
                         : { ...choice, preChecked: false };
               });
               return syncUpdateStates(choicesList);
          };

          const handleAddChoiceRow = () => {
               let choicesList = [...state.choices];
               choicesList.push(constructCheckboxObject('Checkbox'));
               syncUpdateStates(choicesList);
          };

          const handleRemoveChoiceRow = (id) => {
               if (state.choices.length === 1) return;
               let choicesList = [...state.choices];
               choicesList = choicesList.filter(({ id: _id }) => _id !== id);
               syncUpdateStates(choicesList);
          };

          const handleRemoveItself = () => {
               setState(selectedElement);
               handleRemoveElement(state.id);
          };

          return (
               <>
                    <div className="mb-20">
                         <label>{t('form_builder.element_label')}</label>
                         <input
                              ref={ref}
                              className={`form-control ${state && ((!state.is_valid && isEmpty(state.label)) || (!state.is_translated && state.missing_translations.includes('label'))) ? 'is-warning' : ''}`}
                              type="text"
                              field="label"
                              value={state && state.label}
                              placeholder={state && getMasterValue(state.internal_id, 'label', null, t(
                                  'form_builder.setting_label_placeholder'
                              ))}
                              onChange={(e) => handleChange('label', e)}
                              maxLength={DEFAULT_ELEMENT_LENGTH}
                         />
                    </div>
                   {state && state.is_master && (
                       <>
                           <div className="mb-20">
                               <label>{t('form_builder.element_code')}</label>
                               <Autosugestion
                                   field="elementCode"
                                   handleChange={(value) => handleChange('elementCode', { target: { value } })}
                                   maxLength={DEFAULT_ELEMENT_LENGTH}
                                   placeholder={t('form_builder.setting_element_code_placeholder')}
                                   value={state && state.elementCode}
                                   suggestions={fieldsList}
                                   className={`form-control ${!state.is_valid && isEmpty(state.elementCode) ? 'is-warning' : ''} ${!state.is_valid_code && !isEmpty(selectedElement.elementCode) ? 'is-invalid' : ''}`}
                               />
                               {!state.is_valid_code && !isEmpty(selectedElement.elementCode) && (
                                   <div className="invalid-feedback" style={{display: 'block'}}>{t('validations.element_code_unique')}</div>
                               )}
                           </div>
                           <div className="mb-20">
                               <label>{t('form_builder.element_layout')}</label>
                               <div className="input-group zi5">
                                   <Dropdown>
                                       <Dropdown.Toggle
                                           className="select-dropdown"
                                           as={CustomToggle}
                                       >
                                           {innerState.currentLayout && innerState.currentLayout.value}
                                           <span className="suffix">
                                             <ChevronDownIcon />
                                        </span>
                                       </Dropdown.Toggle>
                                       <Dropdown.Menu>
                                           {innerState.layoutOptions.map(
                                               (layout) => (
                                                   <Dropdown.Item
                                                       key={layout.option}
                                                       onClick={() => {
                                                           handleLayoutChange(
                                                               layout.option
                                                           );
                                                           setInnerState({
                                                               ...innerState,
                                                               currentLayout: layout,
                                                           });
                                                       }}
                                                   >
                                                       {layout.value}
                                                   </Dropdown.Item>
                                               )
                                           )}
                                       </Dropdown.Menu>
                                   </Dropdown>

                                   <div className="info-block">
                                       {t('form_builder.optional')}
                                   </div>
                               </div>
                           </div>
                       </>
                   )}
                    <div className="mb-20">
                         <label>{t('form_builder.choices')}</label>
                         {state &&
                              state.choices.map((choice, idx) => (
                                   <div key={choice.id} className="choice">
                                       {state && state.is_master && (
                                           <label className="form-label">
                                               <input
                                                   type="radio"
                                                   value={idx}
                                                   checked={
                                                       choice.preChecked
                                                   }
                                                   onChange={() => {}}
                                                   onClick={(e) => {
                                                       handleChoicePreCheck(
                                                           choice.id,
                                                           e,
                                                           currentItem === idx
                                                       );
                                                       setCurrentItem(
                                                           currentItem === idx
                                                               ? null
                                                               : idx
                                                       );
                                                   }}
                                                   maxLength={DEFAULT_ELEMENT_LENGTH}
                                               />
                                               <div className="circle-outer">
                                                   <div className="circle-inner" />
                                               </div>
                                           </label>
                                       )}
                                        <input
                                             style={{width: state && state.is_master ? '' : '100%'}}
                                             ref={ref}
                                             className={`form-control ${state && !state.is_valid && isEmpty(choice.label) ? 'is-warning' : ''}`}
                                             type="text"
                                             value={choice.label}
                                             placeholder={state && getMasterValue(state.internal_id, 'choices', choice.id, t(
                                                 'form_builder.choice'
                                             ))}
                                             onChange={(e) =>
                                                  handleChoiceChange(
                                                       choice.id,
                                                       e
                                                  )
                                             }
                                             maxLength={DEFAULT_ELEMENT_LENGTH}
                                        />
                                       {state && state.is_master && (
                                           <>
                                               <button
                                                   className="plus-button plus-button--small"
                                                   onClick={() =>
                                                       handleAddChoiceRow()
                                                   }
                                               />
                                               <button
                                                   className="minus-button minus-button--small"
                                                   disabled={
                                                       state &&
                                                       state.choices.length === 1
                                                   }
                                                   onClick={() =>
                                                       handleRemoveChoiceRow(
                                                           choice.id
                                                       )
                                                   }
                                               />
                                           </>
                                       )}
                                   </div>
                              ))}
                    </div>
                    <div className="mb-20">
                         <label>
                              {t('form_builder.additional_information')}
                         </label>
                         <input
                              ref={ref}
                              className={`form-control ${state && !state.is_translated && state.missing_translations.includes('additionalInformation') ? 'is-warning' : ''}`}
                              type="text"
                              field="additionalInformation"
                              value={state && state.additionalInformation}
                              placeholder={state && getMasterValue(state.internal_id, 'additionalInformation', null, t(
                                  'form_builder.setting_additional_information_placeholder'
                              ))}
                              onChange={(e) =>
                                   handleChange('additionalInformation', e)
                              }
                         />
                         <div className="info-block">
                              {t('form_builder.optional')}
                         </div>
                    </div>
                   {state && state.is_master && (
                       <>
                           <label className="checkbox-solid-blue">
                               <input
                                   type="checkbox"
                                   checked={state && state.required}
                                   onChange={handleCheckboxChange}
                               />
                               <span />
                               <div className="label">
                                   {t('form_builder.required')}
                               </div>
                           </label>
                           <hr className="setting-divider" />
                           <Button variant="danger" onClick={handleRemoveItself}>
                               {t('form_builder.delete_element')}
                           </Button>
                       </>
                   )}
               </>
          );
     }
);
