import * as yup from 'yup';
import i18n from 'i18next';

const emailValidation = {
     email: yup
          .string()
          .email(i18n.t('validations.invalid_email'))
          .max(256, i18n.t('validations.email_is_to_big'))
          .required(i18n.t('validations.email_required')),
};

const emailNewValidation = {
     email_new: yup
         .string()
         .email(i18n.t('validations.invalid_email'))
         .max(256, i18n.t('validations.email_is_to_big'))
         .required(i18n.t('validations.email_required')),
};

const emailNewConfirmationValidation = {
     email_new_confirmation: yup
         .mixed()
         .oneOf(
             [yup.ref('email_new')],
             i18n.t('validations.email_must_match')
         )
         .required(i18n.t('validations.email_new_confirmation_required')),
};

const passwordValidation = {
     password: yup
          .string()
          .min(6, i18n.t('validations.password_too_short'))
          .max(256, i18n.t('validations.field_too_long', { limit: 256 }))
          .required(i18n.t('validations.password_required')),
};

const passwordConfirmationValidation = {
     password_confirmation: yup
          .mixed()
          .oneOf(
               [yup.ref('password')],
               i18n.t('validations.passwords_must_match')
          )
          .required(i18n.t('validations.password_confirmation_required')),
};

const currentPasswordValidation = {
     current: yup
          .string()
          .min(6, i18n.t('validations.password_too_short'))
          .max(256, i18n.t('validations.field_too_long', { limit: 256 }))
          .required(i18n.t('validations.password_required')),
};

const nameValidation = {
     name: yup.string().required(i18n.t('validations.name_required')),
};

const eventNameValidation = {
     eventName: yup.string()
         .max(64, i18n.t('validations.field_too_long', { limit: 64 }))
         .required(i18n.t('validations.name_required')),
};

const eventDateValidation = {
     eventDate: yup.string().required(i18n.t('validations.date_required')),
};

const subjectValidation = {
     subject: yup.string().required(i18n.t('validations.subject_required')),
};

const messageValidation = {
     message: yup.string().required(i18n.t('validations.message_required')),
};

const firstNameValidation = {
     firstname: yup
          .string()
          .max(44, i18n.t('validations.field_too_long', { limit: 44 }))
          .required(i18n.t('validations.first_name_required')),
};

const lastNameValidation = {
     lastname: yup
         .string()
         .max(44, i18n.t('validations.field_too_long', { limit: 44 }))
         .required(i18n.t('validations.last_name_required')),
};

const organizationNameValidation = {
     organizationName: yup
          .string()
          .required(i18n.t('validations.organization_name_required')),
};

const languageValidation = {
     language_id: yup
          .number()
          .typeError(i18n.t('validations.language_required'))
          .required(i18n.t('validations.language_required'))
};

export const SIGNUP = {
     INITIAL_VALUES: {
          firstname: '',
          lastname: '',
          email: '',
          password: '',
          password_confirmation: '',
     },
     VALIDATION: yup.object({
          ...firstNameValidation,
          ...lastNameValidation,
          ...emailValidation,
          ...passwordValidation,
          ...passwordConfirmationValidation,
     }),
};

export const LOGIN = {
     INITIAL_VALUES: {
          email: '',
          password: '',
     },
     VALIDATION: yup.object({
          ...emailValidation,
          ...passwordValidation,
     }),
};

export const FORGOT_PASSWORD = {
     INITIAL_VALUES: { email: '' },
     VALIDATION: yup.object({
          ...emailValidation,
     }),
};

export const RESET_PASSWORD = {
     INITIAL_VALUES: {
          password: '',
          password_confirmation: '',
     },
     VALIDATION: yup.object({
          ...passwordValidation,
          ...passwordConfirmationValidation,
     }),
};

export const CONTACT_US = {
     INITIAL_VALUES: {
          name: '',
          email: '',
          subject: '',
          message: '',
     },
     VALIDATION: yup.object({
          ...nameValidation,
          ...emailValidation,
          ...subjectValidation,
          ...messageValidation,
     }),
};

export const SETTINGS = {
     INITIAL_VALUES: {
          firstName: '',
          lastName: '',
          email: '',
     },
     VALIDATION: yup.object({
          ...firstNameValidation,
          ...lastNameValidation
     }),
};

export const ORGANIZATION_SETTINGS = {
     INITIAL_VALUES: { organizationName: '' },
     VALIDATION: yup.object({
          ...organizationNameValidation,
     }),
};

export const ORGANIZATION = {
     INITIAL_VALUES: {
          name: '',
          language_id: null
     },
     VALIDATION: yup.object({
          ...nameValidation,
          ...languageValidation
     })
};

export const CHANGE_PASSWORD = {
     INITIAL_VALUES: {
          current: '',
          password: '',
          password_confirmation: '',
     },
     VALIDATION: yup.object({
          ...currentPasswordValidation,
          ...passwordValidation,
          ...passwordConfirmationValidation,
     }),
};

export const CHANGE_EMAIL = {
     INITIAL_VALUES: {
          email_new: '',
          email_new_confirmation: '',
     },
     VALIDATION: yup.object({
          ...emailValidation,
          ...emailNewValidation,
          ...emailNewConfirmationValidation,
     }),
};

export const SENDERS_INVITE = {
     INITIAL_VALUES: {
          name: '',
          email: '',
     },
     VALIDATION: yup.object({
          ...nameValidation,
          ...emailValidation,
     }),
};

export const EVENT = {
     INITIAL_VALUES: {
          eventName: '',
          eventDate: '',
     },
     VALIDATION: yup.object({
          ...eventNameValidation,
          ...eventDateValidation,
     }),
};
