import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Logo from '../common/logo';
import PolicyLinks from '../common/policy-links';
import { CURRENT_YEAR } from '../../utils/date.utils';
import LeftBlockWrapper from './auth-styles/LeftBlock.style';

export const LeftBlock = memo(() => {
     const { t } = useTranslation();
     return (
          <LeftBlockWrapper>
               <Logo white />
               <div className="msg-wrap">
                    <div className="msg-header">{t('left_block.header')}</div>
                    <div className="msg-about">{t('left_block.text')}</div>
               </div>
               <div className="flex-right">
                   <div className="copyright">
                       &copy; {CURRENT_YEAR} {t('left_block.copyright')}
                   </div>
                   <PolicyLinks />
               </div>
          </LeftBlockWrapper>
     );
});
