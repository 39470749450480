import i18n from 'i18next';
import toastr from 'toastr';
import {call, put, all, takeEvery} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
     successSpeakerInvite,
     failSpeakerInvite,
     successInvitationDetailsBasic,
     failInivitationDetailsBasic,
     successInvitationDetails,
     failInivitationDetails,
     successSpeakerConnect,
     failSpeakerConnect,
     successSpeakerEvents,
     failSpeakerEvents,
     getSpeakersPageDetails,
     successEventSpeakers,
     failEventSpeakers,
     successUpdateSpeakerAnonymously,
     failUpdateSpeakerAnonymously,
     successSpeakerDetails,
     failSpeakerDetails,
     successUpdateSpeakerDetails,
     failUpdateSpeakerDetails,
     successFieldsSpeakerView,
     failFieldsSpeakerView,
     successFilterSpeakersView,
     failFilterSpeakersView,
     successExportSpeakers,
     failExportSpeakers,
     successSearchSpeakersFilteredWithView,
     failSearchSpeakersFilteredWithView,
     successDeleteSpeakerFromEvent,
     failDeleteSpeakerFromEvent,
     successSpeakerPhotoUpload,
     failSpeakerPhotoUpload,
     successSpeakerFileUpload,
     failSpeakerFileUpload,
     successSpeakerPhoto,
     failSpeakerPhoto,
     successSpeakerFile,
     failSpeakerFile,
     successSpeakerPhotoRemove,
     failSpeakerPhotoRemove,
     successSpeakerFileRemove,
     failSpeakerFileRemove,
     successDownloadMediaFile,
     failDownloadMediaFile,
     successArchiveSpeakers,
     failArchiveSpeakers,
     successArchiveDownloadSpeakers,
     failArchiveDownloadSpeakers,
     successResendSpeakerInvitation,
     failResendSpeakerInvitation,
} from '../reducers/speakerReducer';
import * as ACTIONS from '../actions';
import * as Service from '../../services';
import {refreshAvatarsForEvents} from '../../services/speakerService';
import { handleErrors } from '../../utils/sagaUtils';

export function* speakerInviteSaga({ payload }) {
     const response = yield call(Service.speakerInvite, payload);

     if (response.status === 200) {
          const numOfSpeakers = payload.invitationPayload.speakers.length;
          toastr.success(
              numOfSpeakers === 1
                  ? i18n.t('alerts.success.invitation_is_send')
                  : i18n.t('alerts.success.invitations_were_send')
          );

          if (response.data.data.duplicated) {
               toastr.warning(
                   i18n.t('alerts.warning.found_duplicated_speakers',
                       {duplicated: response.data.data.duplicated}
                   )
               );
          }

          yield put(successSpeakerInvite(response.data.message));

          return yield put(
               push(
                    `/organization/${payload.id}/events/${payload.eventId}/speakers/stop-redirect`
               )
          );
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     if (response.status === 402) {
          toastr.error(i18n.t('alerts.error.tier_max_speakers_reached'));
     }

     if (response.status === 423) {
          toastr.error(i18n.t('organization_settings.your_organization_have_been_suspended'));
     }

     response.errors && handleErrors(response.errors);
     yield put(failSpeakerInvite(response.message));
}

export function* invitationDetailsSaga({ payload }) {
     const response = yield call(Service.getInvitationDetails, payload);

     if (response.status === 200) {
          return yield put(successInvitationDetails(response.data));
     }

     if (response.status === 400) {
          toastr.error(i18n.t('alerts.error.speaker_invitation_expired'));

          return yield put(push('/dashboard'));
     }

     if (response.status === 404) {
          toastr.error(i18n.t('alerts.error.speaker_invitation_expired'));

          return yield put(push('/dashboard'));
     }

     if (response.status === 403) {
          return yield put(push('/not-found-404'));
     }

     yield put(failInivitationDetails(response.message));
}

export function* invitationDetailsBasicSaga({ payload }) {
     const response = yield call(Service.getInvitationDetailsBasic, payload);

     if (response.status === 200) {
          return yield put(successInvitationDetailsBasic(response.data.data));
     }

     yield put(failInivitationDetailsBasic(response.message));
}

export function* connectSpeakerSaga({ payload }) {
     const response = yield call(Service.connectLoggedInSpeaker, payload);

     if (response.status === 200) {
          yield put(successSpeakerConnect(response.data.data));

          return yield put(push('/dashboard'));
     }
     response.errors && handleErrors(response.errors);
     yield put(failSpeakerConnect(response.message));
}

export function* speakerEventsSaga({ payload }) {
     const response = yield call(Service.getSpeakerEvent, payload);

     if (response.status === 200) {
          response.data.data = refreshAvatarsForEvents(response.data.data);

          yield put(successSpeakerEvents(response.data));
          return yield put(push(`/dashboard`));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     if (response.status === 403 || response.status === 404) {
          return yield put(push('/not-found-404'));
     }

     yield put(failSpeakerEvents(response.message));
}

export function* eventSpeakersSaga({ payload }) {
     const response = yield call(Service.getEventSpeakers, payload);

     if (response.status === 200) {
          const { data, ...pageDetails } = response.data;
          yield put(getSpeakersPageDetails(pageDetails));
          return yield put(successEventSpeakers(data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     if (response.status === 403 || response.status === 404) {
          return yield put(push('/not-found-404'));
     }

     yield put(failEventSpeakers(response.message));
}

export function* updateSpeakerAnonymouslySaga({ payload }) {
     const response = yield call(Service.updateSpeakerAnonymously, payload);

     if (response.status === 200) {
          return yield put(successUpdateSpeakerAnonymously(payload.message));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }
     response.errors && handleErrors(response.errors);
     yield put(failUpdateSpeakerAnonymously(response.message));
}

export function* speakerDetailsSaga({ payload }) {
     const response = yield call(Service.getSpeakerDetails, payload);

     if (response.status === 200) {
          return yield put(successSpeakerDetails(response.data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     if (response.status === 403 || response.status === 404) {
          return yield put(push('/not-found-404'));
     }

     yield put(failSpeakerDetails(response.data.message));
}

export function* updateSpeakerDetailsSaga({ payload }) {
     const response = yield call(Service.updateSpeakerDetails, payload);

     if (response.status === 200) {
          toastr.success(i18n.t('alerts.success.speaker_details_have_been_updated'));
          return yield put(successUpdateSpeakerDetails(response.data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }
     response.errors && handleErrors(response.errors);
     yield put(failUpdateSpeakerDetails(response.message));
}

export function* resendSpeakerInvitationSaga({ payload }) {
     const response = yield call(Service.resendSpeakerInvitation, payload);

     if (response.status === 200) {
          toastr.success(i18n.t('alerts.success.speaker_invitation_has_been_resend'));
          return yield put(successResendSpeakerInvitation(response.data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }
     response.errors && handleErrors(response.errors);
     yield put(failResendSpeakerInvitation(response.message));
}

export function* fieldsSpeakerViewSaga({ payload }) {
     const response = yield call(Service.getFieldsSpeakerView, payload);

     if (response.status === 200) {
          return yield put(successFieldsSpeakerView(response.data.data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     yield put(failFieldsSpeakerView(response.message));
}

export function* filterSpeakersViewSaga({ payload }) {
     const response = yield call(Service.filterSpeakersView, payload);

     if (response.status === 200) {
          return yield put(successFilterSpeakersView(response.data.data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     yield put(failFilterSpeakersView(response.message));
}

export function* exportSpeakersSaga({ payload }) {
     const response = yield call(Service.exportSpeakers, payload);

     if (response.status === 200) {
          return yield put(successExportSpeakers(response.data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }
     response.errors && handleErrors(response.errors);
     yield put(failExportSpeakers(response.message));
}

export function* archiveSpeakersSaga({ payload }) {
     const response = yield call(Service.generateArchive, payload);

     if (response.status === 200) {
          toastr.success(i18n.t('alerts.success.speakers_archive_download_email'));
          return yield put(successArchiveSpeakers(response.data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     response.errors && handleErrors(response.errors);
     yield put(failArchiveSpeakers(response.message));
}

export function* archiveDownloadSpeakersSaga({ payload }) {
     const response = yield call(Service.downloadArchive, payload);

     if (response.status === 200) {
          toastr.success(i18n.t('alerts.success.speakers_archive_download'));
          yield put(successArchiveDownloadSpeakers(response.data));

          return yield put(push(`/organization/${payload.organizationId}/events/${payload.eventId}/speakers`));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     if (response.status === 404) {
          toastr.error(i18n.t('alerts.error.speakers_archive_download'));
     }

     response.errors && handleErrors(response.errors);
     yield put(failArchiveDownloadSpeakers(response.message));
}

export function* downloadMediaFile({ payload }) {
     const response = yield call(Service.downloadMediaFile, payload);

     if (response.status === 200) {
          return yield put(successDownloadMediaFile());
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     response.errors && handleErrors(response.errors);
     yield put(failDownloadMediaFile(response.message));
}

export function* searchSpeakersFilteredWithViewSaga({ payload }) {
     const response = yield call(
          Service.searchSpeakersFilteredWithView,
          payload
     );

     if (response.status === 200) {
          return yield put(
               successSearchSpeakersFilteredWithView(response.data.data)
          );
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     yield put(failSearchSpeakersFilteredWithView(response.message));
}

export function* deleteSpeakerFromEventSaga({ payload }) {
     const response = yield call(Service.deleteSpeakerFromEvent, payload);

     if (response.status === 200) {
          toastr.success(i18n.t('alerts.success.speaker_removed'));
          return yield put(successDeleteSpeakerFromEvent(i18n.t('success')));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     yield put(failDeleteSpeakerFromEvent(response.message));
}

export function* bulkDeleteSpeakerFromEventSaga({ payload }) {
     const response = yield call(Service.bulkDeleteSpeakerFromEvent, payload);

     if (response.status === 200) {
          toastr.success(i18n.t('alerts.success.speakers_removed'));
          return yield put(successDeleteSpeakerFromEvent(i18n.t('success')));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     yield put(failDeleteSpeakerFromEvent(response.message));
}

export function* speakerPhotoUploadSaga({ payload }) {
     const response = yield call(Service.speakerPhotoUpload, payload);

     if (response.status === 200) {
          return yield put(successSpeakerPhotoUpload(response.data.data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     if (response.status === 400 || response.status === 422) {
          toastr.error(i18n.t('alerts.error.media_upload_invalid', { field: payload.field }));
     }

     response.errors && handleErrors(response.errors);
     yield put(failSpeakerPhotoUpload(response.message));
}

export function* speakerPhotoRemoveSaga({ payload }) {
     const response = yield call(Service.speakerPhotoRemove, payload);

     if (response.status === 200) {
          return yield put(successSpeakerPhotoRemove(response.data.data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }
     response.errors && handleErrors(response.errors);
     yield put(failSpeakerPhotoRemove(response.message));
}

export function* speakerFileRemoveSaga({ payload }) {
     const response = yield call(Service.speakerFileRemove, payload);

     if (response.status === 200) {
          return yield put(successSpeakerFileRemove(response.data.data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }
     response.errors && handleErrors(response.errors);
     yield put(failSpeakerFileRemove(response.message));
}

export function* speakerFileUploadSaga({ payload }) {
     const response = yield call(Service.speakerFileUpload, payload);

     if (response.status === 200) {
          return yield put(successSpeakerFileUpload(response.data.data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     if (response.status === 400 || response.status === 422) {
          toastr.error(i18n.t('alerts.error.media_upload_invalid', { field: payload.field }));
     }

     response.errors && handleErrors(response.errors);
     yield put(failSpeakerFileUpload(response.message));
}

export function* speakerPohotoSaga({ payload }) {
     const response = yield call(Service.getSpeakerPhoto, payload);

     if (response.status === 200) {
          return yield put(successSpeakerPhoto(response.data));
     }

     yield put(failSpeakerPhoto(response.message));
}

export function* speakerFileSaga({ payload }) {
     const response = yield call(Service.getSpeakerFile, payload);

     if (response.status === 200) {
          return yield put(successSpeakerFile(response.data));
     }

     yield put(failSpeakerFile(response.message));
}

export function* watchSpeaker() {
     yield all([
          takeEvery(ACTIONS.SPEAKER_INVITE_REQUEST, speakerInviteSaga),
          takeEvery(ACTIONS.INVITATION_DETAILS_REQUEST, invitationDetailsSaga),
          takeEvery(ACTIONS.INVITATION_DETAILS_BASIC_REQUEST, invitationDetailsBasicSaga),
          takeEvery(ACTIONS.CONNECT_SPEAKER_REQUEST, connectSpeakerSaga),
          takeEvery(ACTIONS.SPEAKER_EVENTS_REQUEST, speakerEventsSaga),
          takeEvery(ACTIONS.EVENT_SPEAKERS_REQUEST, eventSpeakersSaga),
          takeEvery(ACTIONS.UPDATE_SPEAKER_ANONYMOUSLY_REQUEST, updateSpeakerAnonymouslySaga),
          takeEvery(ACTIONS.SPEAKER_DETAILS_REQUEST, speakerDetailsSaga),
          takeEvery(ACTIONS.UPDATE_SPEAKER_DETAILS_REQUEST, updateSpeakerDetailsSaga),
          takeEvery(ACTIONS.RESEND_SPEAKER_INVITATION_REQUEST, resendSpeakerInvitationSaga),
          takeEvery(ACTIONS.FIELDS_SPEAKER_VIEW_REQUEST, fieldsSpeakerViewSaga),
          takeEvery(ACTIONS.FILTER_SPEAKERS_VIEW_REQUEST, filterSpeakersViewSaga),
          takeEvery(ACTIONS.EXPORT_SPEAKERS_REQUEST, exportSpeakersSaga),
          takeEvery(ACTIONS.ARCHIVE_SPEAKERS_REQUEST, archiveSpeakersSaga),
          takeEvery(ACTIONS.ARCHIVE_DOWNLOAD_SPEAKERS_REQUEST, archiveDownloadSpeakersSaga),
          takeEvery(ACTIONS.DOWNLOAD_MEDIA_FILE_REQUEST, downloadMediaFile),
          takeEvery(ACTIONS.SEARCH_SPEAKERS_FILTERED_WITH_VIEW_REQUEST, searchSpeakersFilteredWithViewSaga),
          takeEvery(ACTIONS.DELETE_SPEAKER_FROM_EVENT_REQUEST, deleteSpeakerFromEventSaga),
          takeEvery(ACTIONS.BULK_DELETE_SPEAKER_FROM_EVENT_REQUEST, bulkDeleteSpeakerFromEventSaga),
          takeEvery(ACTIONS.UPLOAD_SPEAKER_PHOTO_REQUEST, speakerPhotoUploadSaga),
          takeEvery(ACTIONS.SPEAKER_PHOTO_REMOVE_REQUEST, speakerPhotoRemoveSaga),
          takeEvery(ACTIONS.UPLOAD_SPEAKER_FILE_REQUEST, speakerFileUploadSaga),
          takeEvery(ACTIONS.SPEAKER_FILE_REMOVE_REQUEST, speakerFileRemoveSaga),
          takeEvery(ACTIONS.SPEAKER_PHOTO_REQUEST, speakerPohotoSaga),
          takeEvery(ACTIONS.SPEAKER_FILE_REQUEST, speakerFileSaga),
     ])
}
