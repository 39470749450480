import React, { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import LoadingBar from '../../../common/loading-bar';
import OrganizationBlock from '../../../common/organization-block';
import { formatDate } from '../../../../utils/date.utils';
import { SpeakerForm } from './SpeakerForm';
import {
     requestInvitationDetails,
     requestUpdateSpeakerAnonymously,
     requestSpeakerPhotoUpload,
     requestSpeakerFileUpload, requestSpeakerPhotoRemove, requestSpeakerFileRemove,
} from '../../../../redux/reducers/speakerReducer';
import SpeakerWrapper from '../Speakers.style';
import {Alert} from "react-bootstrap";
import {setLocale} from "../../../../services/instance";

const mapStateToProps = (state) => ({
     uploadSpeakerPhotoRequested: state.speakerReducer.uploadSpeakerPhotoRequested,
     uploadSpeakerFileRequested: state.speakerReducer.uploadSpeakerFileRequested,
     invitationDetailsData: state.speakerReducer.invitationDetailsData,
     updateSpeakerAnonymouslySucceeded: state.speakerReducer.updateSpeakerAnonymouslySucceeded,
     languages: state.userReducer.languages,
});

const mapDispatchToProps = (dispatch) => ({
     requestInvitationDetails: (token) => dispatch(requestInvitationDetails(token)),
     requestUpdateSpeakerAnonymously: (token) => dispatch(requestUpdateSpeakerAnonymously(token)),
     requestSpeakerPhotoUpload: (payload) => dispatch(requestSpeakerPhotoUpload(payload)),
     requestSpeakerFileUpload: (payload) => dispatch(requestSpeakerFileUpload(payload)),
     requestSpeakerPhotoRemove: (payload) => dispatch(requestSpeakerPhotoRemove(payload)),
     requestSpeakerFileRemove: (payload) => dispatch(requestSpeakerFileRemove(payload)),
});

export const _SpeakerFormAnonymous = memo(
     ({
          requestInvitationDetails,
          invitationDetailsData,
          requestUpdateSpeakerAnonymously,
          requestSpeakerPhotoUpload,
          requestSpeakerFileUpload,
          requestSpeakerPhotoRemove,
          requestSpeakerFileRemove,
          updateSpeakerAnonymouslySucceeded,
          languages,
          uploadSpeakerPhotoRequested,
          uploadSpeakerFileRequested
     }) => {
          const { t } = useTranslation();
          const { invitationToken } = useParams();
          const [invitationDetails, setInvitationDetails] = useState();
          const [formState, setFormState] = useState({});
          const [displayConfirmation, setDisplayConfirmation] = useState(false);
          const [invitationLang, setInvitationLang] = useState(null);

          useEffect(() => {
               if (!invitationDetailsData) {
                    requestInvitationDetails(invitationToken);
               }
          }, [invitationDetailsData, invitationToken]);

          useEffect(() => {
               if (invitationDetailsData && languages) {
                    if (
                         invitationDetailsData.data &&
                         invitationDetailsData.data.length
                    ) {
                         return setInvitationDetails(
                              invitationDetailsData.data
                         );
                    }

                    const invitationLanguage = languages.find((lang) => lang.id === invitationDetailsData.language_id);

                    setLocale(invitationLanguage);
                    setInvitationDetails(invitationDetailsData);
                    setInvitationLang(invitationLanguage);
               }
          }, [invitationDetailsData, languages]);

          useEffect(() => {
               if (updateSpeakerAnonymouslySucceeded) {
                    setDisplayConfirmation(true);
               }
          }, [updateSpeakerAnonymouslySucceeded]);

          useEffect(() => {
               if (displayConfirmation && !(uploadSpeakerPhotoRequested || uploadSpeakerFileRequested)) {
                    document
                        .querySelector('.App')
                        .scrollIntoView({ behavior: 'smooth' });
               }
          }, [displayConfirmation, uploadSpeakerPhotoRequested, uploadSpeakerFileRequested]);

          const handleValueChange = (code, value, type = null) => {
               if (Boolean(value) === false) {
                    const newState = {...formState};
                    delete newState[code];

                    setFormState(newState);
               } else {
                    setFormState({
                         ...formState,
                         [code]: type ? { type, value } : value,
                    });
               }
          };

          const handleMultipleChange = (code, value, label) => {
               if (Boolean(value) === false) {
                    const values = [...formState[code]];
                    const filtered = values.filter((v) => v !== label);

                    setFormState({
                         ...formState,
                         [code]: filtered,
                    });
               } else {
                    setFormState({
                         ...formState,
                         [code]: [...formState[code], value],
                    });
               }
          };

          const handleInitialFormValues = (values) => {
               setFormState(values);
          };

          const handleSubmit = (e, mediaForUpload, mediaForRemove) => {
               e.preventDefault();
               const invitationLanguage = languages.find((lang) => lang.id === invitationDetailsData.language_id);

               requestUpdateSpeakerAnonymously({
                    token: invitationToken,
                    locale: invitationLanguage.code,
                    ...formState,
               });

               mediaForUpload.forEach(({field, file, crop, speakerId, formId, type}) => {
                    if (type === 'photo') {
                         requestSpeakerPhotoUpload({field, photo: file, crop, speakerId, formId});
                    } else if (type === 'file') {
                         requestSpeakerFileUpload({field, files: file, speakerId, formId});
                    }
               });

               mediaForRemove.forEach(({field, speakerId, formId, type}) => {
                    if (type === 'photo') {
                         requestSpeakerPhotoRemove({field, speakerId, formId});
                    } else if (type === 'file') {
                         requestSpeakerFileRemove({field, speakerId, formId});
                    }
               });
          };

          if (!invitationDetails && !invitationLang) return <></>;

          return (
               <SpeakerWrapper>
                    <Helmet>
                         <title>
                              {t('invitation_title')} | {t('avovent')}
                         </title>
                    </Helmet>
                    <LoadingBar finish={!!invitationDetailsData} />
                    {invitationDetailsData && (
                         <div className="speaker-form-anonymous">
                              <div className="container-standard">
                                   <div className="event-block">
                                        <OrganizationBlock
                                             picture={
                                                  invitationDetails.event
                                                       .photos['180x180']
                                             }
                                             pictureAltText={t(
                                                  'linked_form.logo'
                                             )}
                                             title={
                                                  invitationDetails.event.name
                                             }
                                             subtitle={formatDate({
                                                  startDate:
                                                       invitationDetailsData
                                                            .event.start,
                                                  endDate:
                                                       invitationDetailsData
                                                            .event.end,
                                             })}
                                        />

                                        {
                                             displayConfirmation && !(uploadSpeakerPhotoRequested || uploadSpeakerFileRequested)
                                             && (
                                            <>
                                            <Alert
                                                 key={'confirmation-message'}
                                                 variant={'outline-info'}
                                                 className={'mt-4'}
                                             >
                                             {invitationDetailsData.confirmation_message.message}
                                             </Alert>
                                             <Alert
                                                 key={'confirmation-message-info'}
                                                 variant={'outline-info'}
                                                 className={'mt-1 mb-0'}
                                             >
                                                  {t('speakers.invitation_form_confirmation_info', { lng: invitationLang.code })}
                                             </Alert>
                                            </>
                                        )}
                                   </div>
                                   <SpeakerForm
                                        data={invitationDetailsData}
                                        handleValueChange={handleValueChange}
                                        handleMultipleChange={handleMultipleChange}
                                        handleInitialFormValues={handleInitialFormValues}
                                        handleSubmit={handleSubmit}
                                        languageCode={invitationLang && invitationLang.code}
                                   />
                              </div>
                         </div>
                    )}
               </SpeakerWrapper>
          );
     }
);

export const SpeakerFormAnonymous = connect(
     mapStateToProps,
     mapDispatchToProps
)(_SpeakerFormAnonymous);
