import { useState, useCallback } from 'react';

export const useHover = () => {
     const [hovered, setHovered] = useState(false);

     const handleHover = useCallback(() => {
          setHovered(!hovered);
     }, [hovered]);

     return [hovered, handleHover];
};
