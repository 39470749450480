import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SettingsImage } from '../../../../assets/icons/metronic/settings-image';
import { GroupChatImage } from '../../../../assets/icons/metronic/group-chat-image';
import { DotImage } from '../../../../assets/icons/metronic/dot-image';
import {
     StyledDropdown,
     NavigationalDropdownWrapper,
} from '../../../common/dropdown/Dropdown.style';

const mapStateToProps = (state, ownProps) => ({
     ...ownProps,
     router: state.router
});

const _OrganizationDropdown = (props) => {
     const {
          inactive = false,
          organization,
     } = props;

     const { t } = useTranslation();

     const [showSubmenu, setShowSubmenu] = useState(false);
     const [show, setShow] = useState(false);

     const { id } = useParams();

     const toggleSubmenu = () => setShowSubmenu(!showSubmenu);

     const classes = `${inactive ? 'btn-inactive' : 'btn-themed-blue'} btn-navigational`;

     return (
          organization && (
               <NavigationalDropdownWrapper>
                    <StyledDropdown>
                         <StyledDropdown.Toggle
                              className={classes}
                              onToggle={() => setShow(true)}
                         >
                              {organization.name}
                         </StyledDropdown.Toggle>
                         <StyledDropdown.Menu show={show}>
                              <StyledDropdown.Item
                                   href={`/organization/${id}/events`}
                              >
                                   <GroupChatImage />
                                   <div className="text">{t('events')}</div>
                              </StyledDropdown.Item>
                              {organization.member_scope === 'admin' && (
                                   <div
                                        className="dropdown-item"
                                        onMouseEnter={toggleSubmenu}
                                        onMouseLeave={toggleSubmenu}
                                        onClick={() => setShow(true)}
                                   >
                                        <SettingsImage />
                                        <div className="text">
                                             {t(
                                                  'organization_settings.organization_settings'
                                             )}
                                        </div>
                                        {showSubmenu && (
                                             <div className="dropdown-sub-menu">
                                                  <Link
                                                       to={`/organization/${id}/settings`}
                                                  >
                                                       <div className="submenu-item">
                                                            <DotImage />
                                                            <span>
                                                                 {t(
                                                                      'organization_settings.organization_settings'
                                                                 )}
                                                            </span>
                                                       </div>
                                                  </Link>
                                                  <Link
                                                       to={`/organization/${id}/members`}
                                                  >
                                                       <div className="submenu-item">
                                                            <DotImage />
                                                            <span>
                                                                 {t(
                                                                      'organization_settings.team_members'
                                                                 )}
                                                            </span>
                                                       </div>
                                                  </Link>
                                                  <Link
                                                       to={`/organization/${id}/email-senders`}
                                                  >
                                                       <div className="submenu-item">
                                                            <DotImage />
                                                            <span>
                                                                 {t(
                                                                      'organization_settings.email_senders'
                                                                 )}
                                                            </span>
                                                       </div>
                                                  </Link>
                                             </div>
                                        )}
                                   </div>
                              )}
                         </StyledDropdown.Menu>
                    </StyledDropdown>
               </NavigationalDropdownWrapper>
          )
     );
};

export const OrganizationDropdown = connect(mapStateToProps, null)(_OrganizationDropdown);
