import styled from 'styled-components';

export const RemovalNotificationsWrapper = styled.div`
     .speaker-notification-block,
     .person-notification-block {
          display: flex;
     }

     .speaker-notification-block {
          padding-bottom: 302px;
          padding-top: 40px;
          justify-content: space-between;

          img {
               max-width: 50%;
          }
     }

     .person-notification-block {
          flex-direction: column;

          .content {
               display: flex;
          }

          .content {
               align-items: start;
               justify-content: space-between;
          }
     }

     @media only screen and (max-width: 1320px) {
          .person-notification-block {
               .content {
                    flex-direction: column;
                    align-items: unset;
               }
          }
          .speaker-notification-block {
               flex-direction: column;
               padding: 15px !important;
               img {
                    min-width: 100% !important;
               }
          }
     }
     @media only screen and (max-width: 1023px) {
          .not-sr {
               display: none;
          }
     }
`;
