import React from 'react';

function IconTextField() {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="18"
               height="12"
               fill="none"
               viewBox="0 0 18 12"
          >
               <path
                    fill="currentColor"
                    d="M15.89 0H2.11C.945 0 0 .946 0 2.11v7.453c0 1.163.946 2.109 2.11 2.109h13.78c1.164 0 2.11-.946 2.11-2.11V2.11C18 .946 17.054 0 15.89 0zm.704 9.563a.704.704 0 01-.703.703H2.109a.704.704 0 01-.703-.704V2.11c0-.387.316-.703.703-.703h13.782c.387 0 .703.316.703.703v7.454zM6.223 4.253V8.86H4.816V4.254H2.813V2.848h5.343v1.406H6.223zm1.406 3.2h1.406V8.86H7.63V7.453zm2.812 0h1.407V8.86H10.44V7.453zm2.813 0h1.406V8.86h-1.406V7.453z"
               ></path>
          </svg>
     );
}

export default IconTextField;
