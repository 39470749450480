import React, {
     useRef,
     useEffect,
     useState,
     useCallback,
} from 'react';
import { Link, useParams } from 'react-router-dom';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import {
     useTable,
     useRowSelect,
     usePagination,
     useColumnOrder,
} from 'react-table';
import {Pagination, Dropdown, Button, Image} from 'react-bootstrap';

import { ViewSettingsModal } from './ViewSettingsModal';
import { SpeakersExportModal } from './SpeakersExportModal';
import ChevronDownIcon from '../../../../assets/icons/metronic/chevron-down-icon';
import ExportIcon from '../../../../assets/icons/metronic/export-icon';
import EditIcon from '../../../../assets/icons/metronic/edit-icon';
import TrashCanIcon from '../../../../assets/icons/metronic/trash-can-icon';
import MoreIcon from '../../../../assets/icons/metronic/more-icon';
import { COLORS, FONT } from '../../../../constants/style.constants';
import Badge from '../../../common/badge';
import { TablePanel } from '../../../common/filterable-table/TablePanel';
import { PAGE_SIZES } from '../../../../constants/page.constants';
import moment from 'moment';
import _ from 'underscore';
import { push } from 'connected-react-router';

import FilterableTableWrapper from '../../../common/filterable-table/FilterableTable.style';

import {
     StyledDropdown,
     TableDropdownWrapper,
} from '../../../common/dropdown/Dropdown.style';
import {Sorting} from "../../../common/sorting/Sorting";
import {AvatarCircleLoader} from "../../../common/loader";
import EmailIcon from "../../../../assets/icons/metronic/email-icon";
import {getLocale} from "../../../../services/instance";
import {ViewMoreModal} from "./ViewMoreModal";

const IndeterminateCheckbox = ({handleChange, id, checked, page}) => {

     return (
          <div className="checkbox-solid">
               <input
                    key={id}
                    type="checkbox"
                    checked={checked}
                    onChange={() => {
                         handleChange(id, page)
                    }}
               />
               <span />
          </div>
     );
};

const IndeterminateCheckboxAll = ({handleChange, page, checked}) => {

     return (
         <div className="checkbox-solid">
              <input
                  key={`page-${page}`}
                  type="checkbox"
                  checked={checked}
                  onChange={() => {
                       handleChange(page)
                  }}
              />
              <span />
         </div>
     );
};

const ActionsDropdown = ({
     handleSpeakersAction,
     handleShowExportModal,
     handlePreselectSpeaker,
     organizationId,
     eventId,
     id,
}) => {
     const [dropdownShown, setDropdownShown] = useState(false);

     const toggleDropdown = useCallback(() => {
          setDropdownShown(!dropdownShown);
     }, [dropdownShown]);

     return (
          <TableDropdownWrapper>
               <StyledDropdown
                    onToggle={toggleDropdown}
                    show={dropdownShown}
                    alignRight
                    flip={false}
               >
                    <StyledDropdown.Toggle variant="default" flip={false}>
                         <MoreIcon className="icon" />
                    </StyledDropdown.Toggle>
                    <StyledDropdown.Menu flip={false} popperConfig={{ strategy: "fixed" }}>
                        <StyledDropdown.Item
                            as="div"
                            style={{
                                margin: 0,
                                padding: 0,
                            }}
                        >
                            <div className="dropdown-item">
                                <EmailIcon />
                                <span
                                    className="resend"
                                    onClick={() =>
                                        handleSpeakersAction({
                                            id,
                                            type: 'resend',
                                        })
                                    }
                                >
                                        {i18n.t('buttons.resend')}
                                   </span>
                            </div>
                        </StyledDropdown.Item>
                        <StyledDropdown.Item
                              as="div"
                              style={{
                                   margin: 0,
                                   padding: 0,
                              }}
                         >
                              <Link
                                   to={{
                                        pathname: `/organization/${organizationId}/events/${eventId}/speaker/${id}`,
                                   }}
                                   className="dropdown-item"
                              >
                                   <EditIcon />
                                   <span className="edit">
                                        {i18n.t('buttons.edit_speaker')}
                                   </span>
                              </Link>
                         </StyledDropdown.Item>
                         <StyledDropdown.Item
                              as="div"
                              style={{
                                   margin: 0,
                                   padding: 0,
                              }}
                         >
                              <Link
                                   to={{
                                        pathname: `/organization/${organizationId}/events/${eventId}/form-builder/add-element`,
                                        search: `?speaker-id=${id}`,
                                   }}
                                   className="dropdown-item"
                              >
                                   <EditIcon />
                                   <span className="edit">
                                        {i18n.t('buttons.edit_speaker_form')}
                                   </span>
                              </Link>
                         </StyledDropdown.Item>
                         <StyledDropdown.Item
                              as="div"
                              style={{
                                   margin: 0,
                                   padding: 0,
                              }}
                         >
                              <>
                                   <div
                                        className="dropdown-item"
                                        onClick={() => {
                                            handlePreselectSpeaker(id);
                                            handleShowExportModal();
                                        }}
                                   >
                                        <ExportIcon />
                                        <span>{i18n.t('speakers.export')}</span>
                                   </div>
                              </>
                         </StyledDropdown.Item>
                         <StyledDropdown.Item
                              as="div"
                              style={{
                                   margin: 0,
                                   padding: 0,
                              }}
                         >
                              <div className="dropdown-item">
                                   <TrashCanIcon />
                                   <span
                                        className="remove"
                                        onClick={() =>
                                             handleSpeakersAction({
                                                  id,
                                                  type: 'remove',
                                             })
                                        }
                                   >
                                        {i18n.t('buttons.remove')}
                                   </span>
                              </div>
                         </StyledDropdown.Item>
                    </StyledDropdown.Menu>
               </StyledDropdown>
          </TableDropdownWrapper>
     );
};

export const SpeakersTable = ({
     data,
     columns,
     sorting,
     settings,
     handleSaveSettings,
     order,
     handleSaveColumnOrder,
     handleSpeakersAction,
     handleSearch,
     speakersPageDetails,
     handlePageSizeChange,
     handlePageChange,
     handleSort,
     pageDetails,
     isAllSelected,
     handleSelectAll,
     selectedSpeakers,
     handleSelectSpeaker,
     isSpeakerSelected,
     handlePreselectSpeaker,
     handleRefreshSpeakers,
     handleDownloadMediaFile,
     handleBulkRemove
}) => {
     const { t } = useTranslation();
     const { id, eventId } = useParams();

     const dragged = useRef(null);

     const [tableData, setTableData] = useState([]);
     const [tableColumns, seTableColumns] = useState([]);
     const [showModal, setShowModal] = useState(false);
     const [showViewSettingsModal, setShowViewSettingsModal] = useState(false);
     const [showViewMoreModal, setShowViewMoreModal] = useState(false);
     const [dropdownShown, setDropdownShown] = useState(false);

     const [viewMoreField, setViewMoreField] = useState({ key: null, value: null });

     const getColumns = useCallback(
          () => [
               ...columns,
               {
                    Header: i18n.t('organization_settings.actions'),
                    accessor: 'actions',
               },
          ],
          [data]
     );

     const toggleDropdown = useCallback(() => {
          setDropdownShown(!dropdownShown);
     }, [dropdownShown]);

     const handleShowExportModal = useCallback(() => setShowModal(true), [
          showModal,
     ]);

     useEffect(() => {
         if (viewMoreField.key) {
             setShowViewMoreModal(true);
         }
     }, [viewMoreField]);

     const isRealColumn = (name) => {
         const found = columns.filter(({ Header }) => Header === name);

         return found.length === 1;
     };

    const encodeFieldName = (name) => {
        return name.split(' ').join('_');
    };

    const displaySpeakerAvatar = (avatar, speaker) => {
        if (avatar) {

            return displayAvatar(avatar);
        }

        if (speaker.user) {

            return displayAvatar(speaker.user.avatars);
        }

        return displayAvatar(speaker.generated ? speaker.generated.profile_photo : null);
    };

    const displayAvatar = (avatar) => {
        if (avatar) {
            return (
                <Image
                    src={avatar['76x76']}
                    height={40}
                    width={40}
                    roundedCircle
                />
            );
        }

        return (<AvatarCircleLoader width={40} height={40} />);
    };

     const transformData = (data) =>
         data.map((item) => {

             let avatar = null;
             let companyLogo = null;

             if (item.fields) {
                 for (const [key, value] of Object.entries(item.fields)) {
                     if (item[key]) {
                         if (value.option === 'company_name') {
                             for (const [key, value] of Object.entries(item.fields)) {
                                 if (value.option === 'Company logo') {
                                     companyLogo = item.media[encodeFieldName(key)];
                                 }
                             }

                             item[key] = (
                                 <>
                                     <span style={{ marginRight: '7px' }}>
                                         {displayAvatar(companyLogo ? companyLogo : item.generated.company_logo)}
                                     </span>

                                     <span
                                         style={{
                                             fontWeight: `${FONT.MEDIUM}`,
                                             paddingLeft: 0,
                                         }}
                                     >
                                     {item[key]}
                                </span>
                                 </>
                             );
                         }
                         if (value.option === 'phone') {
                             item[key] = (<a href={"tel:" + item[key]}>{item[key]}</a>);
                         }
                         if (value.option === 'website') {
                             item[key] = (<a href={item[key]} target="_blank" rel="noopener noreferrer">{item[key]}</a>);
                         }

                         if (value.type === 'date') {
                             item[key] = moment(item[key]).format('MMM DD, YYYY');
                         }
                     }

                     if (item.media && item.media[encodeFieldName(key)]) {
                         if (value.type === 'image') {
                             item[key] = (
                                 <>
                                     <span
                                         style={{
                                             fontWeight: `${FONT.MEDIUM}`,
                                             paddingLeft: 0,
                                         }}
                                     >
                                        <a href="#null"
                                           onClick={() => {
                                               handleDownloadMediaFile(
                                                   item.media[encodeFieldName(key)]['original'],
                                                   item.media[encodeFieldName(key)]['meta']['name']
                                               )
                                           }}
                                        >

                                            {i18n.t('buttons.download')}
                                        </a>
                                    </span>
                                 </>

                             );
                         }
                         if (value.type === 'file') {
                             item[key] = (
                                 <a href="#null"
                                    onClick={() => {
                                        handleDownloadMediaFile(
                                            item.media[encodeFieldName(key)]['original'],
                                            item.media[encodeFieldName(key)]['meta']['name']
                                        )
                                    }} style={{fontWeight: `${FONT.MEDIUM}`}}>
                                     {i18n.t('buttons.download')}
                                 </a>
                             );
                         }
                     }

                     // set avatar and company logo
                     if (value.option === 'Profile photo' && item.media && item.media[encodeFieldName(key)]) {
                         avatar = item.media[encodeFieldName(key)];
                     }

                     // check for long fields
                     if (value.type === 'text_area' && item[key] && item[key].length > 140) {
                         const fullValue = _.clone(item[key]);
                         item[key] = [
                             `${item[key].slice(0, 140)}... `,
                             <a
                                 href="#null"
                                 onClick={() => {
                                     setViewMoreField({key, value: fullValue});
                                 }}
                             >
                                 {i18n.t('buttons.view_more')}
                             </a>
                         ];
                     }
                 }
             }

             const locale = getLocale();

            // translatable default fields
             item[i18n.t('speakers.default_fields.name')] = (
                 <>
                   <span style={{ marginRight: '7px' }}>
                    {
                        displaySpeakerAvatar(avatar, item)
                    }
                   </span>
                   <Link
                        to={`/organization/${id}/events/${eventId}/speaker/${item.id}`}
                        style={{
                             color: 'inherit',
                             textDecoration: 'none',
                        }}
                   >
                        <span
                             style={{
                                  fontWeight: `${FONT.MEDIUM}`,
                                  paddingLeft: 0,
                             }}
                        >
                             {item[i18n.t('speakers.default_fields.name')]}
                        </span>
                   </Link>
              </>
             );
             item[i18n.t('speakers.default_fields.status')] = (
                 <Badge
                       variant={
                            item[i18n.t('speakers.default_fields.status')] === 'complete' ? 'success' : 'danger'
                       }
                  >
                       {t(`status.${item[i18n.t('speakers.default_fields.status')]}`)}
                  </Badge>
             );
             item[i18n.t('speakers.default_fields.invited_at')] = (
                 moment(item[i18n.t('speakers.default_fields.invited_at')])
                     .format('MMM DD, YYYY')
             );

             return {
                 ...item,
                 actions: (
                      <ActionsDropdown
                           handleSpeakersAction={handleSpeakersAction}
                           handleShowExportModal={handleShowExportModal}
                           handlePreselectSpeaker={handlePreselectSpeaker}
                           organizationId={id}
                           eventId={eventId}
                           id={item.id}
                      />
                 )
             };
         });

     const configureHooks = (hooks) => {
          hooks.visibleColumns.push((columns) => [
               {
                    id: 'selection',
                    Header: () => (
                        <IndeterminateCheckboxAll
                            checked={isAllSelected(currentPage)}
                            handleChange={handleSelectAll}
                            page={currentPage}
                         />
                    ),
                    Cell: ({ row }) => (
                         <IndeterminateCheckbox
                             handleChange={handleSelectSpeaker}
                             id={row.original.id}
                             checked={isSpeakerSelected(row.original.id)}
                             page={currentPage}
                         />
                    ),
               },
               ...columns,
          ]);
     };

     useEffect(() => {
          setTableData(transformData(data));
          seTableColumns(getColumns);
     }, [data, columns]);

     const {
          isAllRowsSelected,
          toggleAllRowsSelected,
          getTableProps,
          getTableBodyProps,
          headerGroups,
          // rows,
          prepareRow,
          page,
          canPreviousPage,
          canNextPage,
          pageOptions,
          pageCount,
          gotoPage,
          nextPage,
          previousPage,
          setPageSize,
          visibleColumns,
          setColumnOrder,
          state: { pageIndex, pageSize, columnOrder }
     } = useTable(
          {
               columns: tableColumns,
               data: tableData,
               manualPagination: true,
               pageCount: speakersPageDetails ? speakersPageDetails.last_page : 1,
               initialState: {
                    pageSize: speakersPageDetails ? speakersPageDetails.per_page : 5,
               },
          },
          usePagination,
          useRowSelect,
          (hooks) => configureHooks(hooks),
          useColumnOrder
     );

     useEffect(() => {
          if (order.current) {
               setColumnOrder(order.current);
          }
     }, [columnOrder, order.current]);

     const DNDEvents = (headers) => {
          headers.forEach((header, i) => {
               header.setAttribute('draggable', true);
               header.ondrag = (e) => e.stopPropagation();
               header.ondragend = (e) => e.stopPropagation();
               header.ondragover = (e) => e.preventDefault();

               header.ondragstart = (e) => {
                    e.stopPropagation();

                    dragged.current = i;
                    e.dataTransfer.setData('text', 'fix firefox dragevents');
               };

               header.ondrop = (e) => {
                    e.preventDefault();
                    // Remove item from array and stick it in a new position.
                    visibleColumns.splice(
                         i,
                         0,
                         visibleColumns.splice(dragged.current, 1)[0]
                    );
                    const newColLayoutIds = visibleColumns.map(({ id }) => id);

                    handleSaveColumnOrder(newColLayoutIds);
                    setTableData(tableData);
                    seTableColumns(getColumns);
                    setColumnOrder(newColLayoutIds);
               };
          });
     };

     const headers = document.querySelectorAll('.th');

     useEffect(() => {
          // DNDEvents(headers);

          return () => (dragged.current = null);
     }, [headers]);

     const transformColumnWidth = useCallback(
          (id) => {
               const config = {
                    selection: '100px',
                   actions: '100px'
               };

               return config[id] || '250px';
          },
          [id]
     );

     const alignText = useCallback(
          (id) => {
               const config = {
                    actions: 'center',
               };

               return config[id] || 'left';
          },
          [id]
     );

     const currentPage = speakersPageDetails ? speakersPageDetails.current_page : 1;

     const isAtLeastOneSelected = () => {
          return selectedSpeakers.length > 0;
     };

     return (
          <FilterableTableWrapper>
               <TablePanel
                    title={t('speakers.event_speakers')}
                    total={pageDetails ? pageDetails.total : 0}
                    customTotal={
                         isAllRowsSelected &&
                         `${pageDetails ? pageDetails.total : 0} ${t('speakers.selected')}`
                    }
                    customOptions={
                         isAtLeastOneSelected() && (
                              <TableDropdownWrapper>
                                   <StyledDropdown
                                        onToggle={toggleDropdown}
                                        show={dropdownShown}
                                        alignRight
                                   >
                                        <StyledDropdown.Toggle variant="outline-primary">
                                             <span>
                                                  {t(
                                                       'organization_settings.actions'
                                                  )}
                                             </span>
                                             <ChevronDownIcon />
                                        </StyledDropdown.Toggle>
                                        <StyledDropdown.Menu>
                                             <StyledDropdown.Item
                                                  as="div"
                                                  style={{
                                                       margin: 0,
                                                       padding: 0,
                                                  }}
                                             >
                                                  <div
                                                       className="dropdown-item"
                                                       onClick={() =>
                                                            setShowModal(true)
                                                       }
                                                  >
                                                       <ExportIcon />
                                                       <span>
                                                            {i18n.t(
                                                                 'speakers.export'
                                                            )}
                                                       </span>
                                                  </div>
                                             </StyledDropdown.Item>
                                             <StyledDropdown.Item
                                                  as="div"
                                                  style={{
                                                       margin: 0,
                                                       padding: 0,
                                                  }}
                                             >
                                                  <div
                                                      className="dropdown-item"
                                                      onClick={() => {
                                                          handleBulkRemove();
                                                      }}
                                                  >
                                                       <TrashCanIcon />
                                                       <span className="remove">
                                                            {i18n.t(
                                                                 'buttons.remove'
                                                            )}
                                                       </span>
                                                  </div>
                                             </StyledDropdown.Item>
                                        </StyledDropdown.Menu>
                                   </StyledDropdown>
                              </TableDropdownWrapper>
                         )
                    }
                    handleSearch={handleSearch}
                    actions={
                         <div className="speaker-table-header">
                              <div
                                   className="extra-table-actions btn-group"
                                   style={{ marginRight: '15px' }}
                              >
                                   <>
                                        <Button
                                             variant="outline-primary"
                                             onClick={() =>
                                                  setShowViewSettingsModal(true)
                                             }
                                        >
                                             {t('speakers.view_settings')}
                                        </Button>
                                        <ViewSettingsModal
                                             show={showViewSettingsModal}
                                             settings={settings.current}
                                             handleSaveSettings={
                                                  handleSaveSettings
                                             }
                                             handleClose={() => {
                                                 setShowViewSettingsModal(
                                                     false
                                                 );
                                                 handleRefreshSpeakers();
                                                }
                                             }
                                        />
                                        <ViewMoreModal
                                            show={showViewMoreModal}
                                             field={viewMoreField.key}
                                             text={viewMoreField.value}
                                             handleClose={() => {
                                                 setShowViewMoreModal(false);
                                             }}
                                            />
                                   </>
                                   <Link
                                        className="btn btn-outline-primary"
                                        to={`/organization/${id}/events/${eventId}/reminders`}
                                        style={{
                                             borderRadius: 0,
                                        }}
                                   >
                                        {t('reminders.reminders')}
                                   </Link>
                                   <>
                                        <Button
                                            // disabled={!isAtLeastOneSelected()}
                                             variant="outline-primary"
                                             onClick={() => setShowModal(true)}
                                             style={{
                                                  borderTopLeftRadius: 0,
                                                  borderBottomLeftRadius: 0,
                                             }}
                                        >
                                             {t('speakers.export')}
                                        </Button>
                                        <SpeakersExportModal
                                             show={showModal}
                                             settings={settings.current}
                                             handleClose={() =>
                                                  setShowModal(false)
                                             }
                                             ids={selectedSpeakers}
                                             organizationId={id}
                                        />
                                   </>
                              </div>
                              <Link
                                   to={`/organization/${id}/events/${eventId}/invite-speakers/step-1`}
                              >
                                   <Button variant="success">
                                        {t('speakers.invite_speakers')}
                                   </Button>
                              </Link>
                         </div>
                    }
               />
               <div
                    className="table-container"
                    style={{ marginBottom: '30px' }}
               >
                   <div className="table-responsive">
                    <table
                         id='resizableSpeaker'
                         {...getTableProps()}
                         columns={columns}
                         data={data}
                         className="table"
                         style={{ width: '100%' }}
                    >
                         <thead>
                              {headerGroups.map((headerGroup) => (
                                   <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => {
                                             return (
                                                  <th
                                                      className={
                                                          isAllSelected(currentPage)
                                                              ? 'th selected-row'
                                                              : 'th'
                                                      }
                                                       {...column.getHeaderProps()}
                                                       style={{
                                                            textTransform: 'capitalize',
                                                            padding: '20px 30px',
                                                            width: transformColumnWidth(column.id),
                                                            minWidth: transformColumnWidth(column.id),
                                                            textAlign: alignText(column.id),
                                                            verticalAlign: 'middle',
                                                       }}
                                                  >
                                                       {column.render('Header')}
                                                      {
                                                          isRealColumn(column.id) &&
                                                          (<Sorting
                                                              field={column.id}
                                                              activeField={sorting.column}
                                                              direction={sorting.direction}
                                                              onSort={handleSort}
                                                          />)
                                                      }
                                                  </th>
                                             );
                                        })}
                                   </tr>
                              ))}
                         </thead>
                         <tbody {...getTableBodyProps()}>
                              {page.slice(0, pageSize).map((row, i) => {
                                   prepareRow(row);
                                   return (
                                        <tr
                                             {...row.getRowProps()}
                                             style={{
                                                  borderTop: `1px solid ${COLORS.PALE_BLUE}`,
                                             }}
                                        >
                                             {row.cells.map((cell) => {
                                                  return (
                                                       <td
                                                            {...cell.getCellProps()}
                                                            className={
                                                                isSpeakerSelected(row.original.id)
                                                                    ? 'td selected-row'
                                                                    : 'td'
                                                            }
                                                            style={{
                                                                 width:
                                                                      'inherit',
                                                                 padding:
                                                                      '20px 30px',
                                                                 verticalAlign:
                                                                      'middle',
                                                                 textAlign: alignText(
                                                                      cell
                                                                           .column
                                                                           .id
                                                                 ),
                                                            }}
                                                       >
                                                            {cell.render(
                                                                 'Cell'
                                                            )}
                                                       </td>
                                                  );
                                             })}
                                        </tr>
                                   );
                              })}
                         </tbody>
                    </table>
               </div>
                    <div className="datatable__pager">
                         <div className="pagination">
                              <Pagination.First
                                   onClick={() => {
                                        gotoPage(0);
                                        handlePageChange({
                                             page: 1,
                                             pageSize,
                                        });
                                   }}
                                   disabled={!canPreviousPage}
                              />
                              <Pagination.Prev
                                   onClick={() => {
                                        previousPage();
                                        handlePageChange({
                                             page:
                                                 currentPage - 1,
                                             pageSize,
                                        });
                                   }}
                                   disabled={!canPreviousPage}
                              />
                              <ul className="pagination">
                                   {pageOptions.map((option) => (
                                        <li
                                             key={option}
                                             className={`page-item ${
                                                 currentPage - 1 ===
                                                  option
                                                       ? 'active'
                                                       : ''
                                             }`}
                                             style={{ cursor: 'pointer' }}
                                             onClick={() =>
                                                  handlePageChange({
                                                       page: option + 1,
                                                       pageSize,
                                                  })
                                             }
                                        >
                                             <span
                                                  className="page-link"
                                                  onClick={() =>
                                                       gotoPage(option)
                                                  }
                                             >
                                                  <span>{option + 1}</span>
                                             </span>
                                        </li>
                                   ))}
                              </ul>
                              <Pagination.Next
                                   onClick={() => {
                                        nextPage();
                                        handlePageChange({
                                             page:
                                                 currentPage + 1,
                                             pageSize,
                                        });
                                   }}
                                   disabled={!canNextPage}
                              />
                              <Pagination.Last
                                   onClick={() => {
                                        gotoPage(pageCount - 1);
                                        handlePageChange({
                                             page:
                                             speakersPageDetails ? speakersPageDetails.last_page : 1,
                                             pageSize,
                                        });
                                   }}
                                   disabled={!canNextPage}
                              />
                         </div>
                         <div className="tabel__pager-info">
                              <Dropdown>
                                   <Dropdown.Toggle variant="light">
                                        <span>{pageSize}</span>
                                        <ChevronDownIcon />
                                   </Dropdown.Toggle>
                                   <Dropdown.Menu style={{ minWidth: '0' }}>
                                        {PAGE_SIZES.map((i) => (
                                             <Dropdown.Item
                                                  key={i}
                                                  onClick={() => {
                                                       setPageSize(i);
                                                       handlePageSizeChange(i);
                                                  }}
                                             >
                                                  <span>{i}</span>
                                             </Dropdown.Item>
                                        ))}
                                   </Dropdown.Menu>
                              </Dropdown>
                              <span className="tabel__pager-info--text">
                                   {`${i18n.t(
                                        'organization_settings.showing'
                                   )} ${speakersPageDetails ? speakersPageDetails.from : 1} - ${
                                       speakersPageDetails ? speakersPageDetails.to : 1
                                   } ${i18n.t('organization_settings.of')} ${
                                        speakersPageDetails ? speakersPageDetails.total : 0
                                   }`}
                              </span>
                         </div>
                    </div>
               </div>
          </FilterableTableWrapper>
     );
};
