import React, { memo } from 'react';
import SubFooter from './SubFooter';
import { BottomNav } from './BottomNav';
import FooterWrapper from './Footer.style';

export const Footer = memo(() => {
     return (
          <FooterWrapper>
               <BottomNav />
               <SubFooter />
          </FooterWrapper>
     );
});
