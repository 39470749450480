import i18n from 'i18next';

export const DefaultFieldsTypes = ['first_name', 'last_name', 'email'];

export const DefaultFields = [
    {
        additionalInformation: '',
        elementCode: 'form_builder.default_fields.first_name',
        elementType: 'first_name',
        label: 'form_builder.default_fields.first_name',
        placeholder: '',
        required: true,
        type: 'text',
        value: '',
        is_default: true
    },
    {
        additionalInformation: '',
        elementCode: 'form_builder.default_fields.last_name',
        elementType: 'last_name',
        label: 'form_builder.default_fields.last_name',
        placeholder: '',
        required: true,
        type: 'text',
        value: '',
        is_default: true
    },
    {
        additionalInformation: '',
        elementCode: 'form_builder.default_fields.email',
        elementType: 'email',
        label: 'form_builder.default_fields.email',
        placeholder: '',
        required: true,
        type: 'text',
        value: '',
        is_default: true
    }
];

export const DefaultFieldsToTrans = {
    'first_name': i18n.t('form_builder.default_fields.first_name'),
    'last_name': i18n.t('form_builder.default_fields.last_name'),
    'email': i18n.t('form_builder.default_fields.email'),
    'company_name': i18n.t('form_builder.type_options.company_name'),
    'phone': i18n.t('form_builder.type_options.phone'),
    'website': i18n.t('form_builder.type_options.website'),
    'select': i18n.t('form_builder.select_element_type')
};
