import i18n from 'i18next';
import toastr from 'toastr';
import {call, put, select, all, takeEvery} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
     successUserAuthorized,
     failUserAuthorized,
     successUserProfile,
     failUserProfile,
     successUserAvatarUpload,
     failUserAvatarUpload,
     successUserProfileUpdate,
     failUserProfileUpdate,
     successChangeUserPassword,
     failChangeUserPassword,
     refreshCurrentUserAvatar,
     successRemoveAvatar,
     failRemoveAvatar,
     successAvailableLanguages,
     failAvailableLanguages,
     successContactUs,
     failContactUs,
     successUserAvatarGenerateTemp,
     failUserAvatarGenerateTemp,
     successChangeUserEmail,
     failChangeUserEmail,
} from '../reducers/userReducer';
import * as ACTIONS from '../actions';
import * as Service from '../../services';
import { handleErrors } from '../../utils/sagaUtils';
import {errorsDisplay} from "../reducers/errorsReducer";

const locationPathname = (state) => state.router.location.pathname;

export function* userAuthorizedSaga() {
     const response = yield call(Service.isAuthorized);

     if (response.status === 200) {
          return yield put(successUserAuthorized(response.data.message));
     }

     yield put(failUserAuthorized(response.message));
}

export function* userProfileSaga() {
     const response = yield call(Service.getUserProfile);

     if (response.status === 200) {
          return yield put(successUserProfile(response.data.data));
     }

     const paths = new Set(['/speaker-form', '/auth/speaker-form', '/terms', '/contact-us']);

     yield put(failUserProfile(response.message));

     const pathname = yield select(locationPathname);
     localStorage.setItem('after_login_redirect', pathname);

     if (!paths.has(pathname) && !pathname.includes('/auth/speaker-form') && !pathname.includes('/invitation') && !pathname.includes('/register') && !pathname.includes('/registration')) {
          yield put(push('/auth/login'));
     }
}

export function* userAvatarUploadSaga({ payload }) {
     const response = yield call(Service.userAvatarUpload, payload);

     if (response.status === 200) {
          yield put(refreshCurrentUserAvatar(response.data.data));

          return yield put(successUserAvatarUpload(response.data.message));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     response.errors && handleErrors(response.errors);
     yield put(failUserAvatarUpload(response.message));
}

export function* userAvatarGenerateTempSaga({ payload }) {
     const response = yield call(Service.regenerateUserAvatarTemp, payload);

     if (response.status === 200) {
          return yield put(successUserAvatarGenerateTemp(response.data.data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     response.errors && handleErrors(response.errors);
     yield put(failUserAvatarGenerateTemp(response.message));
}

export function* userProfileUpdateSaga({ payload }) {
     const response = yield call(Service.updateUserProfile, payload);

     if (response.status === 200) {
          toastr.success(i18n.t('alerts.success.settings_updated'));

          yield put(successUserProfile(response.data.data));
          return yield put(successUserProfileUpdate(response.data.message));
     }

     if (response.status === 400 || response.status === 422) {
          yield put(errorsDisplay({ errors: response.data.errors, message: response.data.message }));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     yield put(failUserProfileUpdate(response.message));
}

export function* changeUserPasswordSaga({ payload }) {
     const response = yield call(Service.changeUserPassword, payload);

     if (response.status === 200) {
          toastr.success(i18n.t('alerts.success.password_changed'));
          return yield put(successChangeUserPassword(response.data.message));
     }

     yield put(errorsDisplay({ errors: response.data.errors, message: response.data.message }));
     yield put(failChangeUserPassword(response.data.message));
}

export function* changeUserEmailSaga({ payload }) {
     const response = yield call(Service.changeUserEmail, payload);

     if (response.status === 200) {
          toastr.success(i18n.t('alerts.success.email_changed'));
          return yield put(successChangeUserEmail(response.data.message));
     }

     yield put(errorsDisplay({ errors: response.data.errors, message: response.data.message }));
     yield put(failChangeUserEmail(response.data.message));
}

export function* removeAvatarSaga() {
     const response = yield call(Service.removeAvatar);

     if (response.status === 200) {
          yield put(refreshCurrentUserAvatar(response.data.data));

          return yield put(successRemoveAvatar(response.message));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     yield put(failRemoveAvatar(response.message));
}

export function* availableLanguagesSaga() {
     const response = yield call(Service.getAvailableLanguages);

     if (response.status === 200) {
          return yield put(successAvailableLanguages(response.data.data));
     }

     yield put(failAvailableLanguages(response.message));
}

export function* contactUsSaga({ payload }) {
     const response = yield call(Service.contactUsAppeal, payload);

     if (response.status === 200) {
          toastr.success(i18n.t('alerts.success.message_sent'));
          return yield put(successContactUs(response.message));
     }

     yield put(failContactUs(response.message));
}

export function* watchUser() {
     yield all([
          takeEvery(ACTIONS.USER_AUTHORIZED_REQUEST, userAuthorizedSaga),
          takeEvery(ACTIONS.USER_PROFILE_REQUEST, userProfileSaga),
          takeEvery(ACTIONS.UPDATE_USER_PROFILE_REQUEST, userProfileUpdateSaga),
          takeEvery(ACTIONS.CHANGE_USER_PASSWORD_REQUEST, changeUserPasswordSaga),
          takeEvery(ACTIONS.CHANGE_USER_EMAIL_REQUEST, changeUserEmailSaga),
          takeEvery(ACTIONS.USER_AVATAR_UPLOAD_REQUEST, userAvatarUploadSaga),
          takeEvery(ACTIONS.USER_AVATAR_GENERATE_TEMP_REQUEST, userAvatarGenerateTempSaga),
          takeEvery(ACTIONS.REMOVE_AVATAR_REQUEST, removeAvatarSaga),
          takeEvery(ACTIONS.CONTACT_US_REQUEST, contactUsSaga),
          availableLanguagesSaga()
     ])
}
