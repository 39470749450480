import React, { memo, lazy, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Breadcrumb from '../../common/breadcrumb';
import Portlet from '../../common/portlet';
import LoadingBar from '../../common/loading-bar';
import SweetAlert from '../../common/sweet-alert';
import { ListItem } from '../../common/collapsible-list/CollapsibleList';
import { OrganizationDropdown } from '../Organization/sub-components/OrganizationDropdown';
import { EventDropdown } from '../Event/sub-components/EventDropdown';
import { ReminderForm } from './ReminderForm';
import { SettingsImage } from '../../../assets/icons/metronic/settings-image';
import SpeakersIcon from '../../../assets/icons/metronic/speakers-icon';
import { GroupChatImage } from '../../../assets/icons/metronic/group-chat-image';
import { DotImage } from '../../../assets/icons/metronic/dot-image';
import {
     requestReminderDetails,
     requestCopyReminder,
     requestReminderTemplates,
     requestCreateReminderTemplate,
     requestRemoveReminderTemplate,
} from '../../../redux/reducers/reminderReducer';
import {
     requestUploadFile,
     requestFilesData
} from '../../../redux/reducers/emailTemplatesReducer';
import {
     requestOrganizationDetails,
     requestOrganizationEmailSenders,
} from '../../../redux/reducers/organizationReducer';
import { requestEventDetails } from '../../../redux/reducers/eventReducer';
import RemindersWrapper from './Reminders.style';
import _ from "underscore";
import {Button} from "react-bootstrap";
import {replace} from "connected-react-router";
import toastr from 'toastr';
import {convertBytesToMb, isValidFileSize, EMAILS_FILE_LIMIT} from "../../../utils/file.utils";
import {convertMimesToExtensions, isValidMimeType, MIME_EMAILS} from "../../../utils/mime.utils";
import cloneDeep from 'lodash/cloneDeep';

const MainDrawer = lazy(() => import('../../common/drawer'));
const CollapsibleList = lazy(() => import('../../common/collapsible-list'));

const mapStateToProps = (state) => ({
     router: state.router,
     currentUser: state.userReducer.currentUser,
     organization: state.organizationReducer.organization,
     eventData: state.eventReducer.eventData,
     emailSendersList: state.organizationReducer.emailSendersList,
     reminderTemplatesList: state.reminderReducer.reminderTemplatesList,
     reminderDetailsData: state.reminderReducer.reminderDetailsData,
     copyReminderRequested: state.reminderReducer.copyReminderRequested,
     removeReminderTemplateSucceeded: state.reminderReducer.removeReminderTemplateSucceeded,
     languages: state.userReducer.languages,
     fileData: state.emailTemplatesReducer.code,
     filesData: state.emailTemplatesReducer.filesData,
     uploadFileRequested: state.emailTemplatesReducer.uploadFileRequested
});

const mapDispatchToProps = (dispatch) => ({
     redirectToReminders: (location) => dispatch(replace(location)),
     requestOrganizationDetails: (id) =>
          dispatch(requestOrganizationDetails(id)),
     requestEventDetails: (payload) => dispatch(requestEventDetails(payload)),
     requestOrganizationEmailSenders: (payload) =>
          dispatch(requestOrganizationEmailSenders(payload)),
     requestReminderTemplates: (eventId) =>
          dispatch(requestReminderTemplates(eventId)),
     requestCreateReminderTemplate: (payload) =>
          dispatch(requestCreateReminderTemplate(payload)),
     requestRemoveReminderTemplate: (payload) =>
          dispatch(requestRemoveReminderTemplate(payload)),
     requestReminderDetails: (payload) =>
          dispatch(requestReminderDetails(payload)),
          requestCopyReminder: (payload) =>
          dispatch(requestCopyReminder(payload)),
     requestUploadFile: (payload) => dispatch(requestUploadFile(payload)),
     requestFilesData: (payload) => dispatch(requestFilesData(payload)),
});

export const _CopyReminder = memo((props) => {
     const { t } = useTranslation();
     const { id, eventId, reminderId } = useParams();

     const {
          router: {
               location: { pathname },
          },
          organization,
          eventData,
          requestOrganizationDetails,
          requestEventDetails,
          currentUser,
          emailSendersList,
          requestOrganizationEmailSenders,
          reminderTemplatesList,
          requestReminderTemplates,
          requestCreateReminderTemplate,
          requestRemoveReminderTemplate,
          requestReminderDetails,
          reminderDetailsData,
          requestCopyReminder,
          removeReminderTemplateSucceeded,
          languages,
          data,
          requestUploadFile,
          fileData,
          filesData,
          requestFilesData,
          redirectToReminders,
          uploadFileRequested,
          copyReminderRequested
     } = props;

     const [quillMessage, setQuillMessage] = useState('');
     const [quillError, setQuillError] = useState('');
     const [multilingual, setMultilingual] = useState(false);
     const [multilingualSelected, setMultilingualSelected] = useState([]);
     const [language, setLanguage] = useState(
          organization && organization.language.id
     );
     const [mandatoryLanguage, setMandatoryLanguage] = useState();
     const [emailTemplate, setEmailTemplate] = useState(null);
     const [accessType, setAccessType] = useState('');
     const [saveAsTemplate, setSaveAsTemplate] = useState(false);
     const [message, setMessage] = useState('');
     const [loadEmailTemplate, setLoadEmailTemplate] = useState(null);

     const [reminderData, setReminderData] = useState([]);
     const [nameOfTemplate, setNameOfTemplate] = useState('');

     useEffect(() => {
          if (!organization) {
               requestOrganizationDetails(id);
          }
          requestEventDetails({ organizationId: id, eventId });
          requestOrganizationEmailSenders({ id, per_page: 50 });
          requestReminderTemplates(eventId);
          requestReminderDetails({ eventId, reminderId });
          setReminderData([]);
     }, [id, eventId, reminderId, pathname]);

     useEffect(() => {
          if (removeReminderTemplateSucceeded) {
               requestReminderTemplates(eventId);
          }
     }, [removeReminderTemplateSucceeded]);

     useEffect(() => {
          const currentReminderData = reminderData;

          currentReminderData.map((d) => {
               if (d.language_id === language) {
                    d.files = d.files.concat(prepareFilesCode(fileData));
                    d.files_data = d.files_data.concat(prepareFilesData(fileData));
               }

               return validateReminderData(d);
          });

          setReminderData(currentReminderData);
     }, [fileData]);

     useEffect(() => {
          if (reminderDetailsData && filesData !== null) {
               const currentReminderData = reminderData;

               currentReminderData.map((data) => {
                    if (data.files.length > 0) {
                         const dataFilesForUpdate = [];

                         data.files.forEach((file) => {
                              const fileData = filesData.find((fd) => fd.code === file);
                              if (fileData) {
                                   dataFilesForUpdate.push(fileData);
                              }
                         });

                         data.files_data = dataFilesForUpdate;
                    }

                    return data;
               });

               setReminderData(currentReminderData);
          }
     }, [filesData]);

     const links = [
          {
               to: `/organization/${id}/settings`,
               text: `${organization && organization.name}`,
          },
          { to: `/organization/${id}/events`, text: i18n.t('events') },
          {
               to: `/organization/${id}/events/${eventId}/speakers`,
               text: `${eventData && eventData.name}`,
          },
          {
               to: `/organization/${id}/events/${eventId}/speakers`,
               text: i18n.t('event.speakers'),
          },
          {
               to: `/organization/${id}/events/${eventId}/reminders`,
               text: i18n.t('reminders.reminders'),
          },
          {
               to: `/organization/${id}/events/${eventId}/reminders/edit-reminder`,
               text: i18n.t('reminders.copy_reminder'),
          },
     ];

     const prepareFilesCode = (uploadedFiles) => {
          const result = [];

          if (uploadedFiles) {
               uploadedFiles.forEach((file) => {
                    result.push(file.code);
               });
          }

          return result;
     };

     const prepareFilesData = (uploadedFiles) => {
          const result = [];

          if (uploadedFiles) {
               uploadedFiles.forEach((file) => {
                    result.push({
                         code: file.code,
                         name: file.name
                    });
               });
          }

          return result;
     };

     const handleRemoveAttachedFile = (code) => {
          const currentReminderData = reminderData;
          currentReminderData.map((d) => {
               if (d.language_id === language) {
                    d.files = d.files.filter(d => d !== code);
                    d.files_data = d.files_data.filter(d => d.code !== code);
               }

               return validateReminderData(d);
          });

          setReminderData(currentReminderData);
     };

     useEffect(() => {
          if (reminderData.length === 0 && organization && languages.length > 0) {
               const defaultReminderData = [];
               languages.forEach((lang) => {
                    defaultReminderData.push({
                         is_valid: false,
                         subject: '',
                         message: '',
                         language_id: lang.id,
                         sender: {
                              name: currentUser && `${currentUser.firstname} ${currentUser.lastname}`,
                              email: currentUser && currentUser.email
                         },
                         files: [],
                         files_data: []
                    });
               });

               setReminderData(defaultReminderData);
               setMultilingual(false);
               setMultilingualSelected([languages.find(lang => lang.id === organization.language.id)]);
               setMandatoryLanguage(languages.find((lang) => lang.id === organization.language.id));
               setLanguage(organization.language.id);
          }
     }, [organization, languages]);

     useEffect(() => {
          if (reminderDetailsData) {
               const currentReminderData = reminderData;
               const data = reminderDetailsData.data;

               let requestFilesDataPayload = [];
               data.forEach((d) => {
                    if (d.files.length > 0) {
                         requestFilesDataPayload = requestFilesDataPayload.concat(d.files);
                    }
               });

               requestFilesData({files: requestFilesDataPayload});

               data.map(values => {
                    values.is_valid = true;

                    const existingFilesData = currentReminderData.find((cd) => cd.language_id === data.language_id);
                    values.files_data = requestFilesDataPayload.length > 0 && existingFilesData ? existingFilesData.files_data : [];

                    return values;
               });

               languages.forEach((lang) => {
                    const haveData = data.filter((d) => d.language_id === lang.id);

                    if (haveData.length === 0) {
                         data.push({
                              is_valid: false,
                              subject: '',
                              message: '',
                              language_id: lang.id,
                              sender: {
                                   name: currentUser && `${currentUser.firstname} ${currentUser.lastname}`,
                                   email: currentUser && currentUser.email
                              },
                              files: [],
                              files_data: []
                         });
                    }
               });

               setReminderData(data);
          }
     }, [reminderDetailsData]);

     useEffect(() => {
          if (emailTemplate && loadEmailTemplate) {
               const newData = cloneDeep(emailTemplate.data);

               let requestFilesDataPayload = [];
               newData.forEach((d) => {
                    if (d.files.length > 0) {
                         requestFilesDataPayload = requestFilesDataPayload.concat(d.files);
                    }
               });

               requestFilesData({files: requestFilesDataPayload});

               newData.map((data) => {
                    const existingReminderData = reminderData.find((d) => d.language_id === data.language_id);

                   data.is_valid = true;
                   data.sender = existingReminderData ? existingReminderData.sender : data.sender;
                   data.files_data = requestFilesDataPayload.length > 0 && existingReminderData ? existingReminderData.files_data : [];

                   return data;
               });

               const currentData = newData.find((d) => d.language_id === language);

               setReminderData(newData);
               setQuillMessage(currentData?.message);
               setLoadEmailTemplate(false);
          }
     }, [emailTemplate]);

     useEffect(() => {
          if (filesData !== null) {
               const currentReminderData = reminderData;

               currentReminderData.map((data) => {
                    if (data.files.length > 0) {
                         const dataFilesForUpdate = [];

                         data.files.forEach((file) => {
                              const fileData = filesData.find((fd) => fd.code === file);
                              if (fileData) {
                                   dataFilesForUpdate.push(fileData);
                              }
                         });

                         data.files_data = dataFilesForUpdate;
                    }

                    return data;
               });

               setReminderData(currentReminderData);
          }
     }, [filesData]);

     const handleRemoveTemplate = (id) => {
          SweetAlert({
               title: i18n.t('sweet_alerts.warning'),
               text: i18n.t('sweet_alerts.delete_template'),
               confirmButtonText: i18n.t('buttons.remove'),
               cancelButtonText: i18n.t('buttons.cancel'),
               showCancelButton: true,
               callback: () => {
                    requestRemoveReminderTemplate({
                         eventId,
                         templateId: id,
                    });
                    setMessage(i18n.t('alerts.success.template_removed'));

                    const defaultEmailData = [];
                    languages.forEach((lang) => {
                         defaultEmailData.push({
                              is_valid: false,
                              subject: '',
                              message: '',
                              language_id: lang.id,
                              sender: {
                                   name: currentUser && `${currentUser.firstname} ${currentUser.lastname}`,
                                   email: currentUser && currentUser.email
                              },
                              files: [],
                              files_data: []
                         });
                    });

                    setReminderData(defaultEmailData);
                    setQuillMessage('');
               },
          });
     };

     const handleMultilingual = (value) => {
          setMultilingual(value);
     };

     const handleMultilingualSelected = (value) => {
          setMultilingualSelected(value);
     };

     const handleMandatoryLanguage = (value) => {
          setMandatoryLanguage(value);
     };

     const handleQuillMessage = (value) => {
          setQuillMessage(value);

          const currentReminderData = reminderData;
          currentReminderData.map((data) => {
               if (data.language_id === language) {
                    data.message = value;
               }

               return validateReminderData(data);
          });

          setReminderData(currentReminderData);
     };

     const handleSubjectChange = (value) => {
          const currentReminderData = reminderData;
          currentReminderData.map((data) => {
               if (data.language_id === language) {
                    data.subject = value;
               }

               return validateReminderData(data);
          });

          setReminderData(currentReminderData);
     };

     const handleCustomSender = (value) => {
          const currentReminderData = reminderData;
          currentReminderData.map((data) => {
               data.sender.name = value.name;
               data.sender.email = value.email;

               return validateReminderData(data);
          });

          setReminderData(reminderData);
     };

     const handleSenderName = (value, languageId) => {
          const currentReminderData = reminderData;
          currentReminderData.map((data) => {
               if (data.language_id === languageId) {
                    data.sender.name = value;
               }

               return validateReminderData(data);
          });

          setReminderData(reminderData);
     };

     const handleSenderEmail = (value, languageId) => {
          const currentReminderData = reminderData;
          currentReminderData.map((data) => {
               if (data.language_id === languageId) {
                    data.sender.email = value;
               }

               return validateReminderData(data);
          });

          setReminderData(reminderData);
     };

     const handleSetNameOfTemplate = (value) => {
          setNameOfTemplate(value);
     };

     const isValidReminderData = () => {
          let valid = true;
          const languageIds = multilingual ? _.pluck(multilingualSelected, 'id') : [language];

          reminderData.forEach((data) => {
               if (languageIds.includes(data.language_id) && data.is_valid === false) {
                    valid = false;
               }
          });

          return valid;
     };

     const isEnteredLinks = () => {
          let valid = true;
          if (isValidReminderData()) {
               const selectedLanguages = _.pluck(multilingualSelected, 'id');

               reminderData.forEach((data) => {
                    if (
                        selectedLanguages.includes(data.language_id) &&
                        !(data.message.includes('edit_link') || data.message.includes('edit_button'))
                    ) {
                         valid = false;
                    }
               });
          }

          return valid;
     };

     const isValidLanguage = (langId) => {
          return exportReminderData(langId).is_valid
     };

     const getLanguageClassName = (langId) => {
          if (isValidLanguage(langId)) {
               return language === langId ? 'primary' : 'outline-primary';
          }

          return language === langId ? 'warning' : 'outline-warning';
     };

     const shouldFilesBeTranslated = () => {
          if (multilingual && mandatoryLanguage !== language) {
               const mandatoryFiles = exportReminderData(mandatoryLanguage && mandatoryLanguage.id);
               const currentLanguage = exportReminderData(language);

               if (
                   mandatoryFiles.files &&
                   mandatoryFiles.files.length > 0 &&
                   currentLanguage.files &&
                   currentLanguage.files.length === 0
               ) {
                    return true;
               }
          }

          return false;
     };

     const validateReminderData = (data) => {
          const cleanMessage = data.message.replace(/<\/?[^>]+(>|$)/g, ""); // escaped html tags

          if (
              _.isEmpty(data.subject) ||
              _.isEmpty(cleanMessage) ||
              _.isEmpty(data.sender.name) ||
              _.isEmpty(data.sender.email)
          ) {
               data.is_valid = false;

               return data;
          }

          data.is_valid = true;

          return data;
     };

     const exportReminderData = (languageId) => {
          const langId = languageId ? languageId : language;
          if (langId) {
               const existing = reminderData.find(({language_id}) => language_id === langId);
               if (existing) {
                    return existing;
               }
          }

          return {
               is_valid: false,
               subject: '',
               message: '',
               language_id: null,
               sender: {
                    name: '',
                    email: ''
               },
               files: [],
               files_data: []
          };
     };

     const prepareReminderData = () => {
          const languageIds = multilingual ? _.pluck(multilingualSelected, 'id') : [language];
          const mandatoryData = exportReminderData(mandatoryLanguage.id);
          const filtered = reminderData.filter(data => languageIds.includes(data.language_id));

          return filtered.map(data => {
               if (data.language_id !== mandatoryLanguage.id && data.files.length === 0) {
                    data.files = mandatoryData.files;
               }

               return data;
          });
     };

     const handleLanguageSelect = (value) => {
          setLanguage(value);
     };

     const handleSelectEmailTemplate = (value, defaultTemplate) => {
          const template = reminderTemplatesList && reminderTemplatesList.find((t) => t.id === value);
          if (template) {
               setLoadEmailTemplate(true);
               setEmailTemplate(template);
          }
          if (value === 0 && defaultTemplate) {
               setLoadEmailTemplate(true);
               setEmailTemplate(defaultTemplate);
          }
     };

     const handleSetAccessType = (value) => {
          setAccessType(value);
     };

     const handleSaveTemplate = (value) => {
          setSaveAsTemplate(value);
     };

     const handleAttachFiles = (payload) => {
          const files = Array.from(payload);
          const filesForUpload = [];

          files.forEach(file => {
               const isValidSize = isValidFileSize(file, EMAILS_FILE_LIMIT);
               const isValidMime = isValidMimeType(file, MIME_EMAILS);

               if (isValidSize && isValidMime) {
                    filesForUpload.push(file);
               } else {
                    if (!isValidSize) {
                         toastr.error(t('picture_uploader.file_size_limit_hit', {limit: convertBytesToMb(EMAILS_FILE_LIMIT)}));
                    }
                    if (!isValidMime) {
                         toastr.error(t('picture_uploader.file_invalid_mime', {
                              mimes: convertMimesToExtensions(MIME_EMAILS.join(','))
                         }));
                    }
               }
          });

          if (filesForUpload.length > 0) {
               requestUploadFile({ files: filesForUpload });
          }
     };

     const handleCopyReminder = (values) => {
          const condition = values.currentItem === 'daysAfterInvitation'
               ? {
                    condition_after: Number(values.conditionAfter),
                 }
               : {
                      condition_on: moment(values.conditionOn).format(
                           'YYYY-MM-DD'
                      ),
                 };
          let reminderPayload;
          let reminderTemplatePayload;
          reminderPayload = {
               language_id: mandatoryLanguage.id,
               multilingual: values.multiLanguage,
               ...condition,
               data: prepareReminderData(),
          };
          reminderTemplatePayload = {
               language_id: mandatoryLanguage.id,
               name: values.nameOfTemplate,
               ...condition,
               access_type: accessType.toLocaleLowerCase(),
               multilingual,
               data: prepareReminderData()
          };
          requestCopyReminder({
               eventId,
               reminderId,
               organizationId: id,
               saveAsTemplate,
               reminderPayload,
               reminderTemplatePayload,
          });
     };

     const organizationLanguage = organization && organization.language.id;

     return (
          <>
               <Helmet>
                    <title>
                         {t('reminders.copy_reminder')} | {t('avovent')}
                    </title>
               </Helmet>
               <MainDrawer>
                    <ListItem to="/dashboard">
                         {t('breadcrumb.dashboard')}
                    </ListItem>
                    <CollapsibleList
                         listHeader={organization && organization.name}
                         listContent={
                              <>
                                   <ListItem
                                        to={`/organization/${id}/events/${eventId}/event-edit`}
                                   >
                                        <div className="collapsible-list-item">
                                             <GroupChatImage />
                                             <div className="sub-item">
                                                  {t('events')}
                                             </div>
                                        </div>
                                   </ListItem>
                                   <CollapsibleList
                                        listHeader={
                                             <div className="collapsible-list-item">
                                                  <SettingsImage />
                                                  <div className="sub-item">
                                                       {t(
                                                            'organization_settings.organization_settings'
                                                       )}
                                                  </div>
                                             </div>
                                        }
                                        listContent={
                                             <>
                                                  <ListItem
                                                       subItem
                                                       to={`/organization/${id}/settings`}
                                                  >
                                                       <div className="collapsible-list-item">
                                                            <DotImage />
                                                            <div className="sub-item">
                                                                 {t(
                                                                      'organization_settings.organization_settings'
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </ListItem>
                                                  <ListItem
                                                       subItem
                                                       to={`/organization/${id}/members`}
                                                  >
                                                       <div className="collapsible-list-item">
                                                            <DotImage />
                                                            <div className="sub-item">
                                                                 {t(
                                                                      'organization_settings.team_members'
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </ListItem>
                                                  <ListItem
                                                       subItem
                                                       to={`/organization/${id}/email-senders`}
                                                  >
                                                       <div className="collapsible-list-item">
                                                            <DotImage />
                                                            <div className="sub-item">
                                                                 {t(
                                                                      'organization_settings.email_senders'
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </ListItem>
                                             </>
                                        }
                                   />
                              </>
                         }
                    />
                    <CollapsibleList
                         listHeader={eventData && eventData.name}
                         listContent={
                              <>
                                   <ListItem
                                        to={`/organization/${id}/events/${eventId}/speakers`}
                                   >
                                        <div className="collapsible-list-item">
                                             <SpeakersIcon />
                                             <div className="sub-item">
                                                  {t('event.speakers')}
                                             </div>
                                        </div>
                                   </ListItem>
                                   <ListItem
                                        to={`/organization/${id}/events/${eventId}/event-edit`}
                                   >
                                        <div className="collapsible-list-item">
                                             <SettingsImage />
                                             <div className="sub-item">
                                                  {t('event.event_settings')}
                                             </div>
                                        </div>
                                   </ListItem>
                              </>
                         }
                    />
               </MainDrawer>
               {organizationLanguage && (
                    <RemindersWrapper>
                         <div className="content--border">
                              <div
                                   className="container-standard"
                                   style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                   }}
                              >
                                   <div className="nav">
                                        <Link
                                             to="/dashboard"
                                             className="inactive"
                                        >
                                             {t('breadcrumb.dashboard')}
                                        </Link>
                                        <OrganizationDropdown organization={organization} inactive/>
                                        <EventDropdown />
                                   </div>
                              </div>
                              <LoadingBar
                                   finish={!!organization && !!eventData}
                              />
                         </div>
                         <div className="container-standard">
                              {organization && eventData && (
                                   <Breadcrumb
                                        title={t('reminders.copy_reminder')}
                                        links={links}
                                   />
                              )}
                         </div>
                         <div className="new-reminder">
                              <div className="container-standard">
                                   <Portlet
                                        title={t('reminders.copy_reminder')}
                                        additionalSection={
                                             multilingual && (
                                                 <div className="language-holder">
                                                      <div className="language-selector">
                                                           <div className="language-buttons">
                                                                {multilingualSelected.map(
                                                                    (lang) => {
                                                                         return (
                                                                             <Button
                                                                                 variant={getLanguageClassName(lang.id)}
                                                                                 key={
                                                                                      lang.id
                                                                                 }
                                                                                 onClick={() => {
                                                                                      handleLanguageSelect(lang.id)
                                                                                 }}
                                                                             >
                                                                                  {lang.name}
                                                                             </Button>
                                                                         )}
                                                                )}
                                                           </div>
                                                      </div>
                                                 </div>
                                             )
                                        }
                                        content={
                                             <ReminderForm
                                                  details={reminderDetailsData}
                                                  currentUser={currentUser}
                                                  emailSendersList={
                                                       emailSendersList
                                                  }
                                                  emailTemplatesList={
                                                       reminderTemplatesList
                                                  }
                                                  handleRemoveTemplate={
                                                       handleRemoveTemplate
                                                  }
                                                  message={message}
                                                  organizationLanguage={
                                                       organizationLanguage
                                                  }
                                                  handleMultilingual={
                                                       handleMultilingual
                                                  }
                                                  handleQuillMessage={handleQuillMessage}
                                                  handleSubjectChange={handleSubjectChange}
                                                  handleCustomSender={handleCustomSender}
                                                  handleSenderName={handleSenderName}
                                                  handleSenderEmail={handleSenderEmail}
                                                  quillError={quillError}
                                                  handleLanguageSelect={
                                                       handleLanguageSelect
                                                  }
                                                  handleAttachFiles={
                                                       handleAttachFiles
                                                  }
                                                  handleSelectEmailTemplate={
                                                       handleSelectEmailTemplate
                                                  }
                                                  handleSetAccessType={
                                                       handleSetAccessType
                                                  }
                                                  handleSaveTemplate={
                                                       handleSaveTemplate
                                                  }
                                                  handleSubmit={
                                                       handleCopyReminder
                                                  }
                                                  languages={languages}
                                                  multilingualSelected={multilingualSelected}
                                                  handleMultilingualSelected={handleMultilingualSelected}
                                                  mandatoryLanguage={mandatoryLanguage}
                                                  multilingual={multilingual}
                                                  currentLanguage={language && languages.find((lang) => lang.id === language)}
                                                  language={language}
                                                  handleMandatoryLanguage={handleMandatoryLanguage}
                                                  isValidReminderData={isValidReminderData}
                                                  exportReminderData={exportReminderData}
                                                  isEnteredLinks={isEnteredLinks}
                                                  fileData={fileData}
                                                  filesData={filesData}
                                                  handleRemoveAttachedFile={handleRemoveAttachedFile}
                                                  shouldFilesBeTranslated={shouldFilesBeTranslated}
                                                  title={t('reminders.copy_reminder')}
                                                  handleSetNameOfTemplate={handleSetNameOfTemplate}
                                                  uploadFileRequested={uploadFileRequested}
                                                  copyReminderRequested={copyReminderRequested}
                                             />
                                        }
                                   />
                              </div>
                         </div>
                    </RemindersWrapper>
               )}
          </>
     );
});

export const CopyReminder = connect(
     mapStateToProps,
     mapDispatchToProps
)(_CopyReminder);
