import React, { memo } from 'react';

export const OrganizationBlock = memo(
     ({ subtitle = '', picture, pictureAltText, title, children }) => (
          <>
               <img
                    style={{
                         height: '90px',
                         width: '90px',
                    }}
                    src={picture}
                    alt={pictureAltText}
               />
               <h1 style={{ marginTop: '15px', paddingTop: 0, lineHeight: '28px' }}>{title}</h1>
               {subtitle && <span>{subtitle}</span>}
               {children}
          </>
     )
);
