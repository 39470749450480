import * as ACTIONS from '../actions';

export const requestEventEmailTemplates = (payload) => ({
     type: ACTIONS.EVENT_EMAIL_TEMPLATES_REQUEST,
     payload,
});

export const successEventEmailTemplates = (payload) => ({
     type: ACTIONS.EVENT_EMAIL_TEMPLATES_SUCCESS,
     payload,
});

export const failEventEmailTemplates = (error) => ({
     type: ACTIONS.EVENT_EMAIL_TEMPLATES_FAILURE,
     error,
});

export const requestCreateEmailTemplate = (payload) => ({
     type: ACTIONS.CREATE_EMAIL_TEMPLATE_REQUEST,
     payload,
});

export const successCreateEmailTemplate = (payload) => ({
     type: ACTIONS.CREATE_EMAIL_TEMPLATE_SUCCESS,
     payload,
});

export const failCreateEmailTemplate = (error) => ({
     type: ACTIONS.CREATE_EMAIL_TEMPLATE_FAILURE,
     error,
});

export const requestRemoveEmailTemplate = (payload) => ({
     type: ACTIONS.REMOVE_EMAIL_TEMPLATE_REQUEST,
     payload,
});

export const successRemoveEmailTemplate = (payload) => ({
     type: ACTIONS.REMOVE_EMAIL_TEMPLATE_SUCCESS,
     payload,
});

export const failRemoveEmailTemplate = (error) => ({
     type: ACTIONS.REMOVE_EMAIL_TEMPLATE_FAILURE,
     error,
});

export const requestUploadFile = (payload) => ({
     type: ACTIONS.UPLOAD_FILE_REQUEST,
     payload,
});

export const successUploadFile = (payload) => ({
     type: ACTIONS.UPLOAD_FILE_SUCCESS,
     payload,
});

export const failUploadFile = (error) => ({
     type: ACTIONS.UPLOAD_FILE_FAILURE,
     error,
});

export const requestFilesData = (payload) => ({
     type: ACTIONS.FILES_DATA_REQUEST,
     payload,
});

export const successFilesData = (payload) => ({
     type: ACTIONS.FILES_DATA_SUCCESS,
     payload,
});

export const failFilesData = (error) => ({
     type: ACTIONS.FILES_DATA_FAILURE,
     error,
});

export const requestResetOfDataFiles = () => ({
     type: ACTIONS.FILES_RESET_DATA_REQUEST,
});

// export const requestUploadMiltipleFiles = (payload) => ({
//      type: ACTIONS.UPLOAD_MULTIPLE_FILES_REQUEST,
//      payload,
// });

// export const successUploadMiltipleFiles = (payload) => ({
//      type: ACTIONS.UPLOAD_MULITPLE_FILES_SUCCESS,
//      payload,
// });

// export const failUploadMiltipleFiles = (error) => ({
//      type: ACTIONS.UPLOAD_MULTIPLE_FILES_FAILURE,
//      error,
// });

export const INITIAL_STATE = {
     eventEmailTemplatesRequested: false,
     eventEmailTemplatesSucceeded: false,
     eventEmailTemplatesFailed: false,
     createEmailTemplateRequested: false,
     createEmailTemplateSucceeded: false,
     createEmailTemplateFailed: false,
     removeEmailTemplateRequested: false,
     removeEmailTemplateSucceeded: false,
     removeEmailTemplateFailed: false,
     uploadFileRequested: false,
     uploadFileSucceeded: false,
     uploadFileFailed: false,
     filesDataRequested: false,
     filesDataSucceeded: false,
     filesDataFailed: false,
     // uploadMulitpleFilesRequested: false,
     // uploadMulitpleFilesSucceeded: false,
     // uploadMulitpleFilesFailed: false,
     emailTemplatesList: null,
     code: null,
     message: null,
     error: null,
     filesData: null
};

export default function emailTemplatesReducer(state = INITIAL_STATE, action) {
     switch (action.type) {
          case ACTIONS.EVENT_EMAIL_TEMPLATES_REQUEST:
               return {
                    ...state,
                    eventEmailTemplatesRequested: true,
                    eventEmailTemplatesSucceeded: false,
                    eventEmailTemplatesFailed: false,
               };
          case ACTIONS.EVENT_EMAIL_TEMPLATES_SUCCESS:
               return {
                    ...state,
                    eventEmailTemplatesRequested: false,
                    eventEmailTemplatesSucceeded: true,
                    eventEmailTemplatesFailed: false,
                    emailTemplatesList: action.payload,
                    error: null,
               };
          case ACTIONS.EVENT_EMAIL_TEMPLATES_FAILURE:
               return {
                    ...state,
                    eventEmailTemplatesRequested: false,
                    eventEmailTemplatesSucceeded: false,
                    eventEmailTemplatesFailed: true,
                    error: action.error,
                    emailTemplatesList: null,
               };
          case ACTIONS.CREATE_EMAIL_TEMPLATE_REQUEST:
               return {
                    ...state,
                    createEmailTemplateRequested: true,
                    createEmailTemplateSucceeded: false,
                    createEmailTemplateFailed: false,
                    message: null,
                    error: null,
               };
          case ACTIONS.CREATE_EMAIL_TEMPLATE_SUCCESS:
               return {
                    ...state,
                    createEmailTemplateRequested: false,
                    createEmailTemplateSucceeded: true,
                    createEmailTemplateFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.CREATE_EMAIL_TEMPLATE_FAILURE:
               return {
                    ...state,
                    createEmailTemplateRequested: false,
                    createEmailTemplateSucceeded: false,
                    createEmailTemplateFailed: true,
                    error: action.error,
                    message: null,
               };
          case ACTIONS.REMOVE_EMAIL_TEMPLATE_REQUEST:
               return {
                    ...state,
                    removeEmailTemplateRequested: true,
                    removeEmailTemplateSucceeded: false,
                    removeEmailTemplateFailed: false,
                    message: null,
                    error: null,
               };
          case ACTIONS.REMOVE_EMAIL_TEMPLATE_SUCCESS:
               return {
                    ...state,
                    removeEmailTemplateRequested: false,
                    removeEmailTemplateSucceeded: true,
                    removeEmailTemplateFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.REMOVE_EMAIL_TEMPLATE_FAILURE:
               return {
                    ...state,
                    removeEmailTemplateRequested: false,
                    removeEmailTemplateSucceeded: false,
                    removeEmailTemplateFailed: true,
                    error: action.error,
                    message: null,
               };
          case ACTIONS.UPLOAD_FILE_REQUEST:
               return {
                    ...state,
                    uploadFileRequested: true,
                    uploadFileSucceeded: false,
                    uploadFileFailed: false,
                    code: null,
                    error: null,
               };
          case ACTIONS.UPLOAD_FILE_SUCCESS:
               return {
                    ...state,
                    uploadFileRequested: false,
                    uploadFileSucceeded: true,
                    uploadFileFailed: false,
                    code: action.payload,
                    error: null,
               };
          case ACTIONS.UPLOAD_FILE_FAILURE:
               return {
                    ...state,
                    uploadFileRequested: false,
                    uploadFileSucceeded: false,
                    uploadFileFailed: true,
                    error: action.error,
                    code: null,
               };
          case ACTIONS.FILES_DATA_REQUEST:
               return {
                    ...state,
                    filesDataRequested: true,
                    filesDataSucceeded: false,
                    filesDataFailed: false,
                    filesData: [],
                    error: null,
               };
          case ACTIONS.FILES_DATA_SUCCESS:
               return {
                    ...state,
                    filesDataRequested: false,
                    filesDataSucceeded: true,
                    filesDataFailed: false,
                    filesData: action.payload,
                    error: null,
               };
          case ACTIONS.FILES_DATA_FAILURE:
               return {
                    ...state,
                    filesDataRequested: false,
                    filesDataSucceeded: false,
                    filesDataFailed: true,
                    error: action.error,
                    filesData: [],
               };
          case ACTIONS.FILES_RESET_DATA_REQUEST:
               return {
                    ...state,
                    filesDataRequested: false,
                    filesDataSucceeded: false,
                    filesDataFailed: false,
                    code: [],
                    filesData: [],
               };
          // case ACTIONS.UPLOAD_MULTIPLE_FILES_REQUEST:
          //      return {
          //           ...state,
          //           uploadMulitpleFilesRequested: true,
          //           uploadMulitpleFilesSucceeded: false,
          //           uploadMulitpleFilesFailed: false,
          //           message: null,
          //           error: null,
          //      };
          // case ACTIONS.UPLOAD_MULITPLE_FILES_SUCCESS:
          //      return {
          //           ...state,
          //           uploadMulitpleFilesRequested: false,
          //           uploadMulitpleFilesSucceeded: true,
          //           uploadMulitpleFilesFailed: false,
          //           message: action.payload,
          //           error: null,
          //      };
          // case ACTIONS.UPLOAD_MULTIPLE_FILES_FAILURE:
          //      return {
          //           ...state,
          //           uploadMulitpleFilesRequested: false,
          //           uploadMulitpleFilesSucceeded: false,
          //           uploadMulitpleFilesFailed: true,
          //           error: action.error,
          //           message: null,
          //      };
          default:
               return state;
     }
}
