import React, { memo, lazy, useEffect, useState, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Breadcrumb from '../../common/breadcrumb';
import LoadingBar from '../../common/loading-bar';
import SweetAlert from '../../common/sweet-alert';
import { ListItem } from '../../common/collapsible-list/CollapsibleList';
import { RemindersTable } from './RemindersTable';
import { EventDropdown } from '../Event/sub-components/EventDropdown';
import { OrganizationDropdown } from '../Organization/sub-components/OrganizationDropdown';
import { NoActiveReminders } from './NoActiveReminders';
import { SettingsImage } from '../../../assets/icons/metronic/settings-image';
import SpeakersIcon from '../../../assets/icons/metronic/speakers-icon';
import { GroupChatImage } from '../../../assets/icons/metronic/group-chat-image';
import { DotImage } from '../../../assets/icons/metronic/dot-image';
import { requestOrganizationDetails } from '../../../redux/reducers/organizationReducer';
import { requestEventDetails } from '../../../redux/reducers/eventReducer';
import {
     requestEventReminders,
     requestSearchReminders,
     requestRemoveReminder,
} from '../../../redux/reducers/reminderReducer';
import RemidersWrapper from './Reminders.style';
import {ReminderIcon} from "../../../assets/icons/metronic/reminders-icon";

const MainDrawer = lazy(() => import('../../common/drawer'));
const CollapsibleList = lazy(() => import('../../common/collapsible-list'));

const mapStateToProps = (state) => ({
     router: state.router,
     organization: state.organizationReducer.organization,
     eventData: state.eventReducer.eventData,
     remindersList: state.reminderReducer.remindersList,
     remindersPageDetails: state.reminderReducer.remindersPageDetails,
     remindersSearchResult: state.reminderReducer.remindersSearchResult,
     removeReminderSucceeded: state.reminderReducer.removeReminderSucceeded,
     eventRemindersRequested: state.reminderReducer.eventRemindersRequested,
     createReminderRequested: state.reminderReducer.createReminderRequested,
     createReminderSucceeded: state.reminderReducer.createReminderSucceeded,
});

const mapDispatchToProps = (dispatch) => ({
     requestOrganizationDetails: (id) => dispatch(requestOrganizationDetails(id)),
     requestEventDetails: (payload) => dispatch(requestEventDetails(payload)),
     requestEventReminders: (payload) => dispatch(requestEventReminders(payload)),
     requestSearchReminders: (query) => dispatch(requestSearchReminders(query)),
     requestRemoveReminder: (payload) => dispatch(requestRemoveReminder(payload)),
});

export const _Reminders = memo((props) => {
     const {
          router: {
               location: { pathname },
          },
          organization,
          eventData,
          requestOrganizationDetails,
          requestEventDetails,
          remindersList,
          requestEventReminders,
          remindersPageDetails,
          remindersSearchResult,
          requestRemoveReminder,
          removeReminderSucceeded,
          eventRemindersRequested,
          createReminderRequested,
          createReminderSucceeded,
     } = props;
     const { id, eventId } = useParams();
     const { t } = useTranslation();

     const persistedPerPage = JSON.parse(localStorage.getItem(`page_size.speakers.reminders`));
     const [data, setData] = useState([]);
     const [queryString, setQueryString] = useState(null);
     const [sorting, setSorting] = useState({
          column: 'id',
          direction: 'desc',
          is_activated: false,
          columns: ['id', 'subject'],
          tableColumns: ['subjectLine', 'email'],
          mapColumns: {'id': 'id', 'subjectLine': 'subject'}
     });

     useEffect(() => {
          if (remindersList) {
               setData(transformRemindersData(remindersList));
          }
     }, [remindersList, remindersSearchResult]);

     useEffect(() => {
          requestOrganizationDetails(id);
          requestEventDetails({ organizationId: id, eventId });
          requestEventReminders({
               eventId,
               per_page: persistedPerPage ? persistedPerPage : 50,
          });
     }, [
          id,
          eventId,
          pathname,
          removeReminderSucceeded,
          createReminderRequested,
          createReminderSucceeded,
     ]);

     const handlePageSizeChange = (pageSize) => {
          localStorage.setItem(
              `page_size.speakers.reminders`,
              JSON.stringify(pageSize, null, 2)
          );
          requestEventReminders({
               eventId,
               per_page: pageSize,
               query: queryString,
               sort: sorting.column,
               direction: sorting.direction
          });
     };


     const handlePageChange = ({ page, pageSize }) =>
          requestEventReminders({
               eventId,
               per_page: pageSize,
               page,
               query: queryString,
               sort: sorting.column,
               direction: sorting.direction
          });

     const handleSort = (field, direction) => {
          setSorting({
               ...sorting,
               is_activated: true,
               column: field,
               direction
          });
     };

     useEffect(() => {
          if (sorting.is_activated) {
               requestEventReminders({
                    eventId,
                    per_page: remindersPageDetails.per_page,
                    page: remindersPageDetails.current_page,
                    query: queryString,
                    sort: sorting.column,
                    direction: sorting.direction
               });
          }
     }, [sorting]);

     useEffect(() => {
          if (queryString && queryString.length > 0) {
               requestEventReminders({
                    eventId,
                    per_page: remindersPageDetails.per_page,
                    page: 1,
                    query: queryString,
                    sort: sorting.column,
                    direction: sorting.direction
               });
          }
     }, [queryString]);

     const links = [
          {
               to: `/organization/${id}/settings`,
               text: `${organization && organization.name}`,
          },
          { to: `/organization/${id}/events`, text: i18n.t('events') },
          {
               to: `/organization/${id}/events/${eventId}/speakers`,
               text: `${eventData && eventData.name}`,
          },
          {
               to: `/organization/${id}/events/${eventId}/speakers`,
               text: i18n.t('event.speakers'),
          },
          {
               to: `/organization/${id}/events/${eventId}/reminders`,
               text: i18n.t('reminders.reminders'),
          },
     ];

     const displaySentStatus = (reminder) => {
          if (reminder.sending_count > 0 || reminder.sent_count > 0) {
               return ` - ${reminder.sent_count}/${reminder.sending_count}`;
          }

          return '';
     };

     const transformRemindersData = useCallback(
          (remindersList) =>
               remindersList &&
               remindersList.map((reminder) => ({
                    id: reminder.id,
                    subjectLine: reminder.data[0].subject,
                    condition:
                        reminder.condition_after || reminder.condition_after === 0 ?
                            (
                                reminder.condition_after + ' ' +
                                (
                                    parseInt(reminder.condition_after) === 1 ?
                                        i18n.t('reminders.day_after_invitation').toLowerCase() :
                                        i18n.t('reminders.days_after_invitation').toLowerCase())
                            ) :
                              moment(reminder.condition_on).format('MMM DD, YYYY'),
                    languageId: reminder.language_id,
                    multilingual: reminder.multilingual,
                    languages: reminder.data.map((r) => r.language_id),
                    originalStatus: reminder.status,
                    status: `${i18n.t(`reminders.status_${reminder.status}`)}${displaySentStatus(reminder)}`
               })),
          [remindersList]
     );

     const handleRemoveReminders = ({ id }) => {
          if (remindersList) {
               SweetAlert({
                    title: i18n.t('sweet_alerts.attention'),
                    text: i18n.t('sweet_alerts.delete_reminder'),
                    confirmButtonText: i18n.t('buttons.delete'),
                    cancelButtonText: i18n.t('buttons.cancel'),
                    showCancelButton: true,
                    callback: () => {
                         requestRemoveReminder({
                              eventId,
                              reminderId: id,
                         });
                    },
               });
          }
     };

     useEffect(() => {
          if (remindersSearchResult && remindersSearchResult.length) {
               setData(transformRemindersData(remindersSearchResult));
          }
     }, [remindersSearchResult]);


     const handleSearch = ({ target: { value } }) => {
          setQueryString(value);
          if (value.length === 0) {
               requestEventReminders({
                    eventId,
                    per_page: remindersPageDetails.per_page,
                    page: 1,
                    sort: sorting.column,
                    direction: sorting.direction
               });
          }
     };

     return (
          <RemidersWrapper>
               <Helmet>
                    <title>
                         {t('reminders.reminders')} | {t('avovent')}
                    </title>
               </Helmet>
               <MainDrawer>
                    <ListItem to="/dashboard">
                         {t('breadcrumb.dashboard')}
                    </ListItem>
                    <CollapsibleList
                         listHeader={organization && organization.name}
                         listContent={
                              <>
                                   <ListItem
                                        to={`/organization/${id}/events/${eventId}/event-edit`}
                                   >
                                        <div className="collapsible-list-item">
                                             <GroupChatImage />
                                             <div className="sub-item">
                                                  {t('events')}
                                             </div>
                                        </div>
                                   </ListItem>
                                   <CollapsibleList
                                        listHeader={
                                             <div className="collapsible-list-item">
                                                  <SettingsImage />
                                                  <div className="sub-item">
                                                       {t(
                                                            'organization_settings.organization_settings'
                                                       )}
                                                  </div>
                                             </div>
                                        }
                                        listContent={
                                             <>
                                                  <ListItem
                                                       subItem
                                                       to={`/organization/${id}/settings`}
                                                  >
                                                       <div className="collapsible-list-item">
                                                            <DotImage />
                                                            <div className="sub-item">
                                                                 {t(
                                                                      'organization_settings.organization_settings'
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </ListItem>
                                                  <ListItem
                                                       subItem
                                                       to={`/organization/${id}/members`}
                                                  >
                                                       <div className="collapsible-list-item">
                                                            <DotImage />
                                                            <div className="sub-item">
                                                                 {t(
                                                                      'organization_settings.team_members'
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </ListItem>
                                                  <ListItem
                                                       subItem
                                                       to={`/organization/${id}/email-senders`}
                                                  >
                                                       <div className="collapsible-list-item">
                                                            <DotImage />
                                                            <div className="sub-item">
                                                                 {t(
                                                                      'organization_settings.email_senders'
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </ListItem>
                                             </>
                                        }
                                   />
                              </>
                         }
                    />
                    <CollapsibleList
                         listHeader={eventData && eventData.name}
                         listContent={
                              <>
                                   <ListItem
                                        to={`/organization/${id}/events/${eventId}/speakers`}
                                   >
                                        <div className="collapsible-list-item">
                                             <SpeakersIcon />
                                             <div className="sub-item">
                                                  {t('event.speakers')}
                                             </div>
                                        </div>
                                   </ListItem>
                                   <ListItem
                                       to={`/organization/${id}/events/${eventId}/speakers`}
                                   >
                                        <div className="collapsible-list-item">
                                             <ReminderIcon />
                                             <div className="sub-item">
                                                  {t('reminders.reminders')}
                                             </div>
                                        </div>
                                   </ListItem>
                                   <ListItem
                                        to={`/organization/${id}/events/${eventId}/event-edit`}
                                   >
                                        <div className="collapsible-list-item">
                                             <SettingsImage />
                                             <div className="sub-item">
                                                  {t('event.event_settings')}
                                             </div>
                                        </div>
                                   </ListItem>
                              </>
                         }
                    />
               </MainDrawer>
               <div className="content--border">
                    <div
                         className="container-standard"
                         style={{ display: 'flex', alignItems: 'center' }}
                    >
                         <div className="nav">
                              <Link to="/dashboard" className="inactive">
                                   {t('breadcrumb.dashboard')}
                              </Link>
                              <OrganizationDropdown organization={organization} inactive/>
                              <EventDropdown />
                         </div>
                    </div>
                    <LoadingBar finish={!!organization && !!eventData} />
               </div>
               <div className="container-standard">
                    {organization && eventData && remindersList && (
                         <Breadcrumb
                              title={t('reminders.reminders')}
                              links={links}
                         />
                    )}
               </div>
               <div className="container-standard">
                    {!createReminderRequested && !eventRemindersRequested && (
                        <>
                             {(remindersList && remindersList.length === 0 && queryString === null) ? (
                                 <NoActiveReminders/>
                             ) : data && remindersList && (
                                 <div className="table">
                                      <RemindersTable
                                          data={data}
                                          pageDetails={remindersPageDetails}
                                          handlePageChange={handlePageChange}
                                          handlePageSizeChange={handlePageSizeChange}
                                          handleSearch={handleSearch}
                                          handleRemoveReminders={handleRemoveReminders}
                                          sorting={sorting}
                                          handleSort={handleSort}
                                      />
                                 </div>
                             )}
                        </>
                    )}
               </div>
          </RemidersWrapper>
     );
});

export const Reminders = connect(
     mapStateToProps,
     mapDispatchToProps
)(_Reminders);
