import {ElementTypes} from '../ElementTypes';


export const Fields = {
    [ElementTypes.TITLE]: {
        UPDATE_VALUE: [],
        // VALIDATE_TRANSLATIONS: ['value'],
        VALIDATION: {
            MASTER: ['value'],
            CHILD: ['value']
        },
        validateCode: false
    },
    [ElementTypes.PARAGRAPH]: {
        UPDATE_VALUE: [],
        // VALIDATE_TRANSLATIONS: ['value'],
        VALIDATION: {
            MASTER: ['text'],
            CHILD: ['text']
        },
        validateCode: false
    },
    [ElementTypes.TEXT_FIELD]: {
        UPDATE_VALUE: ['elementCode', 'elementType', 'required'],
        // VALIDATE_TRANSLATIONS: ['label', 'placeholder', 'value', 'additionalInformation'],
        VALIDATION: {
            MASTER: ['label', 'elementCode'],
            CHILD: ['label']
        },
        validateCode: true
    },
    [ElementTypes.TEXT_AREA]: {
        UPDATE_VALUE: ['elementCode', 'required'],
        // VALIDATE_TRANSLATIONS: ['label', 'placeholder', 'text', 'additionalInformation'],
        VALIDATION: {
            MASTER: ['label', 'elementCode'],
            CHILD: ['label']
        },
        validateCode: true
    },
    [ElementTypes.CHECKBOX]: {
        UPDATE_VALUE: ['elementCode', 'layout', 'multipleSelections', 'required', 'choices'],
        // VALIDATE_TRANSLATIONS: ['label', 'additionalInformation'],
        VALIDATION: {
            MASTER: ['label', 'elementCode', 'choices'],
            CHILD: ['label', 'choices']
        },
        validateCode: true
    },
    [ElementTypes.RADIO]: {
        UPDATE_VALUE: ['elementCode', 'layout', 'required', 'choices'],
        // VALIDATE_TRANSLATIONS: ['label', 'additionalInformation'],
        VALIDATION: {
            MASTER: ['label', 'elementCode', 'choices'],
            CHILD: ['label', 'choices']
        },
        validateCode: true
    },
    [ElementTypes.IMAGE]: {
        UPDATE_VALUE: ['elementCode', 'elementType', 'required'],
        // VALIDATE_TRANSLATIONS: ['label', 'additionalInformation'],
        VALIDATION: {
            MASTER: ['label', 'elementCode'],
            CHILD: ['label']
        },
        validateCode: true
    },
    [ElementTypes.FILE_UPLOAD]: {
        UPDATE_VALUE: ['elementCode', 'elementType', 'required'],
        // VALIDATE_TRANSLATIONS: ['label', 'additionalInformation'],
        VALIDATION: {
            MASTER: ['label', 'elementCode'],
            CHILD: ['label']
        },
        validateCode: true
    },
    [ElementTypes.DATE]: {
        UPDATE_VALUE: ['elementCode', 'required'],
        // VALIDATE_TRANSLATIONS: ['label', 'placeholder', 'additionalInformation'],
        VALIDATION: {
            MASTER: ['label', 'elementCode'],
            CHILD: ['label']
        },
        validateCode: true
    },
    [ElementTypes.TIME]: {
        UPDATE_VALUE: ['elementCode', 'timeFormat', 'required'],
        // VALIDATE_TRANSLATIONS: ['label', 'placeholder', 'additionalInformation'],
        VALIDATION: {
            MASTER: ['label', 'elementCode'],
            CHILD: ['label']
        },
        validateCode: true
    },
    [ElementTypes.DROPDOWN]: {
        UPDATE_VALUE: ['elementCode', 'required', 'choices'],
        // VALIDATE_TRANSLATIONS: ['label', 'additionalInformation'],
        VALIDATION: {
            MASTER: ['label', 'elementCode', 'choices'],
            CHILD: ['label', 'choices']
        },
        validateCode: true
    },
    [ElementTypes.DIVIDER]: {
        UPDATE_VALUE: ['invert'],
        // VALIDATE_TRANSLATIONS: ['title', 'text'],
        VALIDATION: null,
        validateCode: false
    },
};
