import React, {useRef, useEffect} from 'react';
import { useDrag, useDrop } from 'react-dnd';

import IconCheckbox from '../../../../../assets/icons/form-builder/IconCheckbox';
import { DND_TYPES } from '../../ElementTypes';
import { Placeholder } from '../Placeholder';
import { COLORS } from '../../../../../constants/style.constants';
import i18n from 'i18next';
import { Button } from 'react-bootstrap';
import TimesIcon from '../../../../../assets/icons/metronic/times-icon';
import { Overlay } from '../Overlay';
import {getEmptyImage} from "react-dnd-html5-backend";

export const Checkbox = ({
     id,
     name,
     type,
     label,
     elementCode,
     layout,
     multipleSelections,
     choices,
     additionalInformation,
     isMaster,
     isValid,
     required,
     index,
     displayLayout,
     inBuilder,
     forceDisplay,
     handleElementSelect,
     isSelectedItem,
     handleRemoveElement,
     setActiveSpan,
     activeSpan,
     moveElement
}) => {
     const ref = useRef(null);

     const [{ isDragging }, drag, preview] = useDrag({
          item: {
               id,
               name,
               type,
               label,
               elementCode,
               layout,
               multipleSelections,
               choices,
               additionalInformation,
               required,
               index,
          },
          isDragging(monitor) {
               return monitor.getItem() && monitor.getItem().id === id;
          },
          collect: (monitor) => ({
               isDragging: monitor.isDragging(),
          }),
     });

     const [, drop] = useDrop({
          accept: DND_TYPES,
          hover(item, monitor) {
               if (!ref.current || item.index === undefined) {
                    return;
               }

               const dragIndex = item.index;
               const hoverIndex = index;

               if (dragIndex === hoverIndex) {
                    return;
               }

               const hoverBoundingRect = ref.current?.getBoundingClientRect();
               const hoverMiddleY =
                    (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
               const clientOffset = monitor.getClientOffset();
               const hoverClientY = clientOffset.y - hoverBoundingRect.top;

               if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                    return;
               }

               if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                    return;
               }

               moveElement(dragIndex, hoverIndex);

               item.index = hoverIndex;
          },
     });

     useEffect(() => {
          preview(getEmptyImage(), { captureDraggingState: false });
     }, []);

     drag(drop(ref));

     if (!inBuilder) {
          return (
               <div ref={ref} className="elements-grid--item" role="button">
                    <span className="icon">
                         <IconCheckbox />
                    </span>
                    <span className="text">{name}</span>
               </div>
          );
     }

     if (isDragging && !forceDisplay) {
          return <Placeholder />;
     }

     return (
          <div
               ref={ref}
               className={`selection ${!isValid && !isDragging && !isSelectedItem(id) ? 'is-warning' : ''}`}
               onClick={(e) => handleElementSelect(e, id)}
               style={{
                    display: 'grid',
                    gridTemplateColumns: '195px 1fr 37px',
                    placeItems: 'start',
                    backgroundColor: isSelectedItem(id)
                         ? 'rgba(24, 112, 244, 0.05)'
                         : '#fff',
                    position: 'relative',
               }}
          >
               <Overlay />
               <>
                    <label
                         style={{
                              maxWidth: '160px',
                              justifySelf: 'start',
                              margin: 0,
                         }}
                    >
                         {label || name}
                         {required && (
                              <span
                                   style={{
                                        marginLeft: '10px',
                                        color: `${COLORS.RED}`,
                                   }}
                              >
                                   *
                              </span>
                         )}
                    </label>
                    <div>
                         <div
                              className="checkbox-group"
                              style={{
                                   display: 'grid',
                                   width: '100%',
                                   gridTemplateColumns: displayLayout(layout),
                                   gridColumnGap: 12,
                                   gridRowGap: 8,
                                   gridTemplateRows: '1fr',
                                   placeItems: 'start',
                              }}
                         >
                              {choices.map((choice, idx) => (
                                   <label key={idx} className="checkbox-solid-blue">
                                        <input
                                             required={required}
                                             type="checkbox"
                                             className="checkbox-input"
                                             checked={choice.preChecked}
                                        />

                                        <span />
                                        <div className="label-text">
                                             {choice.label ||
                                                  i18n.t('form_builder.choice')}
                                        </div>
                                   </label>
                              ))}
                         </div>
                         {additionalInformation && (
                             <div className="info-block">
                                  {additionalInformation}
                             </div>
                         )}
                    </div>
               </>
               {isMaster && (
                   <Button
                       autoFocus={isSelectedItem(id)}
                       variant="danger"
                       onClick={() => handleRemoveElement(id)}
                       onMouseEnter={() => setActiveSpan(true)}
                       onMouseLeave={() => setActiveSpan(false)}
                       style={{
                            visibility: isSelectedItem(id) ? 'visible' : 'hidden',
                            position: 'relative',
                            zIndex: 3,
                       }}
                   >
                        <TimesIcon
                            color={`${COLORS.PLACEHOLDER_GREY}`}
                            activeColor={`${COLORS.WHITE}`}
                            hovered={activeSpan}
                        />
                   </Button>
               )}
          </div>
     );
};
