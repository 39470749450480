import React from 'react';
import { useTranslation } from 'react-i18next';
import { ElementTypes } from '../ElementTypes';
import * as Elements from '../Elements/Builder';
import { constructCheckboxObject, constructDropdownObject } from '../helpers';

import FormBuilderWrapper from '../FormBuilder.style';
import {RandomNumber} from "../../../../utils/random.utils";
import i18n from "i18next";
import {Card} from "react-bootstrap";

export const AddElementStep = ({
    isSelectedItem,
    handleElementSelect,
    selectedElement,
    handleRemoveElement,
    setActiveSpan,
    activeSpan,
    moveElement,
    isMandatoryLanguageActive,
    mandatoryLanguage
}) => {
     const { t } = useTranslation();

     return (
         <FormBuilderWrapper>
                 {isMandatoryLanguageActive() && (
                     <section className="elements-grid">
                         <Elements.Title
                             id={RandomNumber(100000000000, 999999999999)}
                             name={t('form_builder.elements.title')}
                             type={ElementTypes.TITLE}
                             value=""
                             isValid={true}
                             handleElementSelect={handleElementSelect}
                             selectedElement={selectedElement}
                             isSelectedItem={isSelectedItem}
                             handleRemoveElement={handleRemoveElement}
                             setActiveSpan={setActiveSpan}
                             activeSpan={activeSpan}
                             moveElement={moveElement}
                         />
                         <Elements.Paragraph
                             id={RandomNumber(100000000000, 999999999999)}
                             name={t('form_builder.elements.paragraph')}
                             type={ElementTypes.PARAGRAPH}
                             text=""
                             isValid={true}
                             handleElementSelect={handleElementSelect}
                             selectedElement={selectedElement}
                             isSelectedItem={isSelectedItem}
                             handleRemoveElement={handleRemoveElement}
                             setActiveSpan={setActiveSpan}
                             activeSpan={activeSpan}
                             moveElement={moveElement}
                         />
                         <Elements.TextField
                             id={RandomNumber(100000000000, 999999999999)}
                             name={t('form_builder.elements.text_field')}
                             type={ElementTypes.TEXT_FIELD}
                             label=""
                             elementCode=""
                             elementType=""
                             placeholder=""
                             value=""
                             additionalInformation=""
                             required={false}
                             isValid={true}
                             handleElementSelect={handleElementSelect}
                             selectedElement={selectedElement}
                             isSelectedItem={isSelectedItem}
                             handleRemoveElement={handleRemoveElement}
                             setActiveSpan={setActiveSpan}
                             activeSpan={activeSpan}
                             moveElement={moveElement}
                         />
                         <Elements.TextArea
                             id={RandomNumber(100000000000, 999999999999)}
                             name={t('form_builder.elements.text_area')}
                             type={ElementTypes.TEXT_AREA}
                             label=""
                             elementCode=""
                             placeholder=""
                             value=""
                             text=""
                             additionalInformation=""
                             required={false}
                             isValid={true}
                             handleElementSelect={handleElementSelect}
                             selectedElement={selectedElement}
                             isSelectedItem={isSelectedItem}
                             handleRemoveElement={handleRemoveElement}
                             setActiveSpan={setActiveSpan}
                             activeSpan={activeSpan}
                             moveElement={moveElement}
                         />
                         <Elements.Checkbox
                             id={RandomNumber(100000000000, 999999999999)}
                             name={t('form_builder.elements.checkbox')}
                             type={ElementTypes.CHECKBOX}
                             label=""
                             elementCode=""
                             layout="one_column"
                             multipleSelections={false}
                             isValid={true}
                             choices={[
                                 constructCheckboxObject(ElementTypes.CHECKBOX),
                                 constructCheckboxObject(ElementTypes.CHECKBOX),
                                 constructCheckboxObject(ElementTypes.CHECKBOX),
                             ]}
                             additionalInformation=""
                             required={false}
                             handleElementSelect={handleElementSelect}
                             selectedElement={selectedElement}
                             isSelectedItem={isSelectedItem}
                             handleRemoveElement={handleRemoveElement}
                             setActiveSpan={setActiveSpan}
                             activeSpan={activeSpan}
                             moveElement={moveElement}
                         />
                         <Elements.RadioSelection
                             id={RandomNumber(100000000000, 999999999999)}
                             name={t('form_builder.elements.radio')}
                             type={ElementTypes.RADIO}
                             label=""
                             elementCode=""
                             layout="one_column"
                             choices={[
                                 constructCheckboxObject(ElementTypes.RADIO),
                                 constructCheckboxObject(ElementTypes.RADIO),
                                 constructCheckboxObject(ElementTypes.RADIO),
                             ]}
                             additionalInformation=""
                             required={false}
                             isValid={true}
                             handleElementSelect={handleElementSelect}
                             selectedElement={selectedElement}
                             isSelectedItem={isSelectedItem}
                             handleRemoveElement={handleRemoveElement}
                             setActiveSpan={setActiveSpan}
                             activeSpan={activeSpan}
                             moveElement={moveElement}
                         />
                         <Elements.Image
                             id={RandomNumber(100000000000, 999999999999)}
                             name={t('form_builder.elements.image')}
                             type={ElementTypes.IMAGE}
                             label=""
                             elementCode=""
                             elementType=""
                             additionalInformation=""
                             required={false}
                             isValid={true}
                             handleElementSelect={handleElementSelect}
                             selectedElement={selectedElement}
                             isSelectedItem={isSelectedItem}
                             handleRemoveElement={handleRemoveElement}
                             setActiveSpan={setActiveSpan}
                             activeSpan={activeSpan}
                             moveElement={moveElement}
                         />
                         <Elements.FileUpload
                             id={RandomNumber(100000000000, 999999999999)}
                             name={t('form_builder.elements.file_upload')}
                             type={ElementTypes.FILE_UPLOAD}
                             label=""
                             elementCode=""
                             elementType=""
                             additionalInformation=""
                             required={false}
                             isValid={true}
                             handleElementSelect={handleElementSelect}
                             selectedElement={selectedElement}
                             isSelectedItem={isSelectedItem}
                             handleRemoveElement={handleRemoveElement}
                             setActiveSpan={setActiveSpan}
                             activeSpan={activeSpan}
                             moveElement={moveElement}
                         />
                         <Elements.Date
                             id={RandomNumber(100000000000, 999999999999)}
                             name={t('form_builder.elements.date')}
                             type={ElementTypes.DATE}
                             label=""
                             elementCode=""
                             placeholder=""
                             additionalInformation=""
                             required={false}
                             isValid={true}
                             handleElementSelect={handleElementSelect}
                             selectedElement={selectedElement}
                             isSelectedItem={isSelectedItem}
                             handleRemoveElement={handleRemoveElement}
                             setActiveSpan={setActiveSpan}
                             activeSpan={activeSpan}
                             moveElement={moveElement}
                         />
                         <Elements.Time
                             id={RandomNumber(100000000000, 999999999999)}
                             name={t('form_builder.elements.time')}
                             type={ElementTypes.TIME}
                             label=""
                             elementCode=""
                             timeFormat={12}
                             placeholder=""
                             additionalInformation=""
                             required={false}
                             isValid={true}
                             handleElementSelect={handleElementSelect}
                             selectedElement={selectedElement}
                             isSelectedItem={isSelectedItem}
                             handleRemoveElement={handleRemoveElement}
                             setActiveSpan={setActiveSpan}
                             activeSpan={activeSpan}
                             moveElement={moveElement}
                         />
                         <Elements.DropdownElement
                             id={RandomNumber(100000000000, 999999999999)}
                             name={t('form_builder.elements.dropdown')}
                             type={ElementTypes.DROPDOWN}
                             label=""
                             elementCode=""
                             choices={[
                                 constructDropdownObject(ElementTypes.DROPDOWN),
                                 constructDropdownObject(ElementTypes.DROPDOWN),
                                 constructDropdownObject(ElementTypes.DROPDOWN),
                             ]}
                             additionalInformation=""
                             required={false}
                             isValid={true}
                             handleElementSelect={handleElementSelect}
                             selectedElement={selectedElement}
                             isSelectedItem={isSelectedItem}
                             handleRemoveElement={handleRemoveElement}
                             setActiveSpan={setActiveSpan}
                             activeSpan={activeSpan}
                             moveElement={moveElement}
                         />
                         <Elements.Divider
                             id={RandomNumber(100000000000, 999999999999)}
                             name={t('form_builder.elements.divider')}
                             type={ElementTypes.DIVIDER}
                             title=""
                             text=""
                             invert={false}
                             isValid={true}
                             handleElementSelect={handleElementSelect}
                             selectedElement={selectedElement}
                             isSelectedItem={isSelectedItem}
                             handleRemoveElement={handleRemoveElement}
                             setActiveSpan={setActiveSpan}
                             activeSpan={activeSpan}
                             moveElement={moveElement}
                         />
                     </section>
                 )}
                 {isMandatoryLanguageActive() === false && mandatoryLanguage && (
                     <Card.Body className="information-box">
                         {i18n.t(
                             'form_builder.switch_to_main_language',
                             { language: mandatoryLanguage.name }
                         )}
                     </Card.Body>
                 )}
         </FormBuilderWrapper>
     );
};
