import React, {useEffect, useRef} from 'react';
import { useDrag, useDrop } from 'react-dnd';

import IconTextField from '../../../../../assets/icons/form-builder/IconTextField';
import { DND_TYPES } from '../../ElementTypes';
import { Placeholder } from '../Placeholder';
import { Overlay } from '../Overlay';
import { COLORS } from '../../../../../constants/style.constants';
import { Button } from 'react-bootstrap';
import TimesIcon from '../../../../../assets/icons/metronic/times-icon';
import { DefaultFieldsTypes } from '../../DefaultFields';
import {getEmptyImage} from "react-dnd-html5-backend";


export const TextField = ({
     id,
     name,
     type,
     label,
     elementCode,
     placeholder,
     value,
     additionalInformation,
     required,
     elementType,
     readonly,
     isMaster,
     isValid,
     index,
     inBuilder,
     forceDisplay,
     handleElementSelect,
     isSelectedItem,
     handleRemoveElement,
     setActiveSpan,
     activeSpan,
     moveElement
}) => {
     const ref = useRef(null);

     const [{ isDragging }, drag, preview] = useDrag({
          item: {
               id,
               name,
               type,
               label,
               elementCode,
               elementType,
               placeholder,
               value,
               additionalInformation,
               required,
               index,
          },
          isDragging(monitor) {
               return monitor.getItem() && monitor.getItem().id === id;
          },
          collect: (monitor) => ({
               isDragging: monitor.isDragging(),
          }),
     });

     const [, drop] = useDrop({
          accept: DND_TYPES,
          hover(item, monitor) {
               if (!ref.current || item.index === undefined) {
                    return;
               }

               const dragIndex = item.index;
               const hoverIndex = index;

               if (dragIndex === hoverIndex) {
                    return;
               }

               const hoverBoundingRect = ref.current?.getBoundingClientRect();
               const hoverMiddleY =
                    (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
               const clientOffset = monitor.getClientOffset();
               const hoverClientY = clientOffset.y - hoverBoundingRect.top;

               if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                    return;
               }

               if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                    return;
               }

               moveElement(dragIndex, hoverIndex);

               item.index = hoverIndex;
          },
     });

     useEffect(() => {
          preview(getEmptyImage(), { captureDraggingState: false });
     }, []);

     drag(drop(ref));

     if (!inBuilder) {
          return (
               <div ref={ref} className="elements-grid--item" role="button">
                    <span className="icon">
                         <IconTextField />
                    </span>
                    <span className="text">{name}</span>
               </div>
          );
     }

     if (isDragging && !forceDisplay) {
          return <Placeholder />;
     }

     return (
          <div
               ref={ref}
               className={`selection ${!isValid && !isDragging && !isSelectedItem(id) ? 'is-warning' : ''}`}
               onClick={(e) => handleElementSelect(e, id)}
               style={{
                    backgroundColor: isSelectedItem(id)
                         ? 'rgba(24, 112, 244, 0.05)'
                         : '#fff',
                    position: 'relative',
               }}
          >
               <Overlay />

               <label
                    htmlFor={label}
                    style={{
                         display: 'grid',
                         gridTemplateColumns: '195px auto',
                         placeItems: 'start start',
                         margin: 0,
                    }}
               >
                    <span
                         style={{
                              maxWidth: '160px',
                         }}
                    >
                         {label || name}
                         {required && (
                              <span
                                   style={{
                                        marginLeft: '10px',
                                        color: `${COLORS.RED}`,
                                   }}
                              >
                                   *
                              </span>
                         )}
                    </span>
                    <div
                         style={{
                              width: '100%',
                         }}
                    >
                         <input
                              readOnly={readonly}
                              required={required}
                              className="form-control"
                              id={label}
                              name={elementCode}
                              type={elementType}
                              placeholder={placeholder}
                              value={value}
                              onChange={() => {}}
                              tabIndex={-1}
                         />
                         {additionalInformation && (
                              <div
                                   className="info-block"
                                   style={{
                                        width: '100%',
                                        minWidth: '400px',
                                        maxWidth: '586px',
                                   }}
                              >
                                   {additionalInformation}
                              </div>
                         )}
                    </div>
               </label>
               {(isMaster && !readonly) && !DefaultFieldsTypes.includes(elementType) && (
                    <Button
                         disabled={DefaultFieldsTypes.includes(elementType)}
                         autoFocus={isSelectedItem(id)}
                         variant="danger"
                         onClick={() => handleRemoveElement(id)}
                         onMouseEnter={() => setActiveSpan(true)}
                         onMouseLeave={() => setActiveSpan(false)}
                         style={{
                              visibility: isSelectedItem(id)
                                   ? 'visible'
                                   : 'hidden',
                              position: 'relative',
                              zIndex: 3,
                         }}
                    >
                         <TimesIcon
                              color={`${COLORS.PLACEHOLDER_GREY}`}
                              activeColor={`${COLORS.WHITE}`}
                              hovered={activeSpan}
                         />
                    </Button>
               )}
          </div>
     );
};
