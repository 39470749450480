import React, { Fragment, useCallback } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import HomeIcon from '../../../assets/icons/metronic/home-icon';
import ChevronLeftIcon from '../../../assets/icons/metronic/chevron-left-icon';
import { BreadcrumbWrapper, StyledBreadcrumb } from './Breadcrumb.style';
import { useTranslation } from 'react-i18next';

export const Breadcrumb = ({ title, links, callback }) => {
     const history = useHistory();
     const { pathname } = useLocation();
     const { t } = useTranslation();

     const goBack = useCallback(() => {
          history.goBack();
     }, [history]);

     return (
          <BreadcrumbWrapper>
               {!pathname.includes('dashboard') && (
                    <Button variant="default" onClick={goBack}>
                         <ChevronLeftIcon /> <span>{ t('buttons.back') }</span>
                    </Button>
               )}
               <StyledBreadcrumb>
                    <StyledBreadcrumb.Item active>
                         {title}
                    </StyledBreadcrumb.Item>
                    <Link to="/">
                         <HomeIcon />
                    </Link>
                    {links.map((link, idx) => (
                         <Fragment key={idx}>
                              <span className="separator" />
                              {
                                   callback ? (
                                       <StyledBreadcrumb.Item onClick={() => { callback(link.to) }}>
                                            {link.text}
                                       </StyledBreadcrumb.Item>
                                   ) : (
                                       <StyledBreadcrumb.Item href={link.to}>
                                            {link.text}
                                       </StyledBreadcrumb.Item>
                                   )
                              }

                         </Fragment>
                    ))}
               </StyledBreadcrumb>
          </BreadcrumbWrapper>
     );
};
