import React, { memo, lazy, useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Breadcrumb from '../../../common/breadcrumb';
import Portlet from '../../../common/portlet';
import LoadingBar from '../../../common/loading-bar';
import { Drawer } from '../../../common/drawer/MainDrawer';
import { ListItem } from '../../../common/collapsible-list';
import { SpeakerForm } from './SpeakerForm';
import { formatDate } from '../../../../utils/date.utils';
import { isValidFilesSize } from '../../../../utils/file.utils';
import {
     requestSpeakerDetails,
     requestUpdateSpeakerDetails,
     requestSpeakerPhoto,
     requestSpeakerPhotoUpload,
     requestSpeakerFileUpload,
     requestSpeakerPhotoRemove,
     requestSpeakerFileRemove,
} from '../../../../redux/reducers/speakerReducer';
import SpeakersWrapper from '../Speakers.style';

const SidebarToggler = lazy(() => import('../../../common/sidebar-toggler'));

const MainDrawer = lazy(() => import('../../../common/drawer'));

const mapStateToProps = (state) => ({
     locationPathname: state.router.location.pathname,
     speakerDetailsData: state.speakerReducer.speakerDetailsData,
     speakerPhoto: state.speakerReducer.speakerPhoto,
     drawer: state.ui,
     languages: state.userReducer.languages
});

const mapDispatchToProps = (dispatch) => ({
     requestSpeakerDetails: (payload) => dispatch(requestSpeakerDetails(payload)),
     requestUpdateSpeakerDetails: (payload) => dispatch(requestUpdateSpeakerDetails(payload)),
     requestSpeakerPhoto: (payload) => dispatch(requestSpeakerPhoto(payload)),
     requestSpeakerPhotoUpload: (payload) => dispatch(requestSpeakerPhotoUpload(payload)),
     requestSpeakerFileUpload: (payload) => dispatch(requestSpeakerFileUpload(payload)),
     requestSpeakerPhotoRemove: (payload) => dispatch(requestSpeakerPhotoRemove(payload)),
     requestSpeakerFileRemove: (payload) => dispatch(requestSpeakerFileRemove(payload)),
});

export const _SpeakerFormLoggedIn = memo(
     ({
          speakerDetailsData,
          requestSpeakerDetails,
          requestUpdateSpeakerDetails,
          locationPathname,
          speakerPhoto,
          requestSpeakerPhoto,
          languages,
          drawer: { drawerShown },
          requestSpeakerPhotoUpload,
          requestSpeakerFileUpload
     }) => {
          const { t } = useTranslation();
          const { eventId, id } = useParams();

          const [formState, setFormState] = useState({});
          const [invitationLanguage, setInvitationLanguage] = useState();
          const [photo, setPhoto] = useState(null);
          const [files, setFiles] = useState([]);

          const getImageName = useCallback(() => {
               if (speakerDetailsData) {
                    const image = speakerDetailsData.form.find(
                         ({ type }) => type === 'image'
                    );

                    return image && image.name;
               }
          }, [speakerDetailsData]);

          const imageName = getImageName();

          useEffect(() => {
               if (speakerDetailsData && imageName) {
                    requestSpeakerPhoto({
                         speakerId: id,
                         field: imageName,
                         size: '240x240',
                    });
               }
          }, [id, imageName]);

          useEffect(() => {
               if (formState) {
                    speakerDetailsData && setFormState({ ...speakerDetailsData.data });

                    if (speakerDetailsData) {
                         const lang = languages.find((lang) => lang.id === speakerDetailsData.language_id);

                         setInvitationLanguage(lang);
                    }
               }

               return () => setFormState(null);
          }, [speakerDetailsData, eventId, id]);

          useEffect(() => {
               requestSpeakerDetails({ eventId, id });
          }, [locationPathname, eventId, id]);

          const handleValueChange = (code, value, type = null) => {
               if (Boolean(value) === false) {
                    const newState = {...formState};
                    delete newState[code];

                    setFormState(newState);
               } else {
                    setFormState({
                         ...formState,
                         [code]: type ? { type, value } : value,
                    });
               }
          };

          const handleMultipleChange = (code, value, label) => {
               if (Boolean(value) === false) {
                    const values = [...formState[code]];
                    const filtered = values.filter((v) => v !== label);

                    setFormState({
                         ...formState,
                         [code]: filtered,
                    });
               } else {
                    setFormState({
                         ...formState,
                         [code]: [...formState[code], value],
                    });
               }
          };

          const handleInitialFormValues = (values) => {
               setFormState(values);
          };

          const handleUploadPhoto = (file) => setPhoto(file);
          const handleAttachFiles = (files) => {
               if (isValidFilesSize(files)) {
                    setFiles(Array.from(files));
               }
          };

          const handleSubmit = (e, mediaForUpload, mediaForRemove) => {
               e.preventDefault();
               const language = languages.find((l) => l.id === speakerDetailsData.language_id);

               requestUpdateSpeakerDetails({
                    eventId,
                    id,
                    locale: language.code,
                    ...formState,
               });

               mediaForUpload.forEach(({field, file, crop, speakerId, formId, type}) => {
                    if (type === 'photo') {
                         requestSpeakerPhotoUpload({field, photo: file, crop, speakerId, formId});
                    } else if (type === 'file') {
                         requestSpeakerFileUpload({field, files: file, speakerId, formId});
                    }
               });

               mediaForRemove.forEach(({field, speakerId, formId, type}) => {
                    if (type === 'photo') {
                         requestSpeakerPhotoRemove({field, speakerId, formId});
                    } else if (type === 'file') {
                         requestSpeakerFileRemove({field, speakerId, formId});
                    }
               });
          };

          const links = [
               {
                    to: `/events/${eventId}/speaker-form-loggedin/${id}`,
                    text: t('dashboard.speaker'),
               },
               {
                    to: `/events/${eventId}/speaker-form-loggedin/${id}`,
                    text: `${
                         speakerDetailsData && speakerDetailsData.event.name
                    }`,
               },
          ];

          return (
               <>
                    <Helmet>
                         <title>
                              {t('invitation_title')} | {t('avovent')}
                         </title>
                    </Helmet>
                    <MainDrawer>
                         <ListItem to="/dashboard">
                              {t('breadcrumb.dashboard')}
                         </ListItem>
                    </MainDrawer>
                    {drawerShown && (
                         <Drawer>
                              <div style={{ padding: '35px 15px 15px 15px' }}>
                                   <SpeakersWrapper>
                                        <Card
                                             style={{
                                                  boxShadow:
                                                       '0px 0px 30px 0px rgba(82, 63, 105, 0.1)',
                                                  // width: '22rem',
                                             }}
                                        >
                                             <Card.Body>
                                                  <div className="info-block">
                                                       <img
                                                            style={{
                                                                 height: '90px',
                                                                 width: '90px',
                                                            }}
                                                            src={
                                                                 speakerDetailsData
                                                                      .event
                                                                      .photos[
                                                                      '180x180'
                                                                 ]
                                                            }
                                                            alt={t(
                                                                 'speakers.event_avatar'
                                                            )}
                                                       />
                                                       <div className="text-block">
                                                            <Card.Title>
                                                                 {
                                                                      speakerDetailsData
                                                                           .event
                                                                           .name
                                                                 }
                                                            </Card.Title>
                                                            <Card.Subtitle>
                                                                 {formatDate({
                                                                      startDate:
                                                                           speakerDetailsData
                                                                                .event
                                                                                .start,
                                                                      endDate:
                                                                           speakerDetailsData
                                                                                .event
                                                                                .end,
                                                                 })}
                                                            </Card.Subtitle>
                                                       </div>
                                                  </div>
                                             </Card.Body>
                                        </Card>
                                   </SpeakersWrapper>
                              </div>
                         </Drawer>
                    )}
                    {speakerDetailsData && (
                         <SpeakersWrapper>
                              <div className="speaker-form">
                                   <div className="content--border">
                                        <div
                                             className="container-standard"
                                             style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                             }}
                                        >
                                             <div className="nav">
                                                  <Link
                                                       to="/dashboard"
                                                       className="inactive"
                                                  >
                                                       {t(
                                                            'breadcrumb.dashboard'
                                                       )}
                                                  </Link>
                                             </div>
                                        </div>
                                        <LoadingBar
                                             finish={!!speakerDetailsData}
                                        />
                                   </div>
                                   <div
                                        className="container-standard"
                                        style={{
                                             display: 'flex',
                                             alignItems: 'center',
                                        }}
                                   >
                                        <SidebarToggler />
                                        <Breadcrumb
                                             title={`${speakerDetailsData.event.name}`}
                                             links={links}
                                        />
                                   </div>
                                   <div className="container-standard">
                                        <div className="app-grid">
                                             <div className="not-sr">
                                                  <Card
                                                       style={{
                                                            width: '22rem',
                                                       }}
                                                  >
                                                       <Card.Body>
                                                            <div className="info-block">
                                                                 <img
                                                                      style={{
                                                                           height:
                                                                                '90px',
                                                                           width:
                                                                                '90px',
                                                                      }}
                                                                      src={
                                                                           speakerDetailsData
                                                                                .event
                                                                                .photos[
                                                                                '180x180'
                                                                           ]
                                                                      }
                                                                      alt={t(
                                                                           'speakers.event_avatar'
                                                                      )}
                                                                 />
                                                                 <div className="text-block">
                                                                      <Card.Title>
                                                                           {
                                                                                speakerDetailsData
                                                                                     .event
                                                                                     .name
                                                                           }
                                                                      </Card.Title>
                                                                      <Card.Subtitle>
                                                                           {formatDate(
                                                                                {
                                                                                     startDate:
                                                                                          speakerDetailsData
                                                                                               .event
                                                                                               .start,
                                                                                     endDate:
                                                                                          speakerDetailsData
                                                                                               .event
                                                                                               .end,
                                                                                }
                                                                           )}
                                                                      </Card.Subtitle>
                                                                 </div>
                                                            </div>
                                                       </Card.Body>
                                                  </Card>
                                             </div>
                                             <Portlet
                                                  title={t(
                                                       'speakers.your_information'
                                                  )}
                                                  content={
                                                       <SpeakerForm
                                                            data={
                                                                 speakerDetailsData
                                                            }
                                                            speakerPhoto={
                                                                 speakerPhoto
                                                            }
                                                            handleValueChange={
                                                                 handleValueChange
                                                            }
                                                            handleUploadPhoto={
                                                                 handleUploadPhoto
                                                            }
                                                            handleAttachFiles={
                                                                 handleAttachFiles
                                                            }
                                                            handleInitialFormValues={handleInitialFormValues}
                                                            handleSubmit={
                                                                 handleSubmit
                                                            }
                                                            handleMultipleChange={handleMultipleChange}
                                                            languageCode={invitationLanguage && invitationLanguage.code}
                                                       />
                                                  }
                                             />
                                        </div>
                                   </div>
                              </div>
                         </SpeakersWrapper>
                    )}
               </>
          );
     }
);

export const SpeakerFormLoggedIn = connect(
     mapStateToProps,
     mapDispatchToProps
)(_SpeakerFormLoggedIn);
