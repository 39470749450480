import React, {useEffect, useRef} from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Button } from 'react-bootstrap';
import TimesIcon from '../../../../../assets/icons/metronic/times-icon';
import { COLORS } from '../../../../../constants/style.constants';
import IconDivider from '../../../../../assets/icons/form-builder/IconDivider';
import { Placeholder } from '../Placeholder';
import { DND_TYPES } from '../../ElementTypes';
import { Overlay } from '../Overlay';
import {getEmptyImage} from "react-dnd-html5-backend";

export const Divider = ({
     index,
     id,
     type,
     title,
     text,
     invert,
     name,
     isMaster,
     isValid,
     inBuilder,
     forceDisplay,
     handleElementSelect,
     selectedElement,
     isSelectedItem,
     handleRemoveElement,
     setActiveSpan,
     activeSpan,
     moveElement
}) => {
     const ref = useRef(null);

     const [{ isDragging }, drag, preview] = useDrag({
          item: { id, name, type, title, text, invert, index },
          isDragging(monitor) {
               return monitor.getItem() && monitor.getItem().id === id;
          },
          collect: (monitor) => ({
               isDragging: monitor.isDragging(),
          }),
     });

     const [, drop] = useDrop({
          accept: DND_TYPES,
          hover(item, monitor) {
               if (!ref.current || item.index === undefined) {
                    return;
               }

               const dragIndex = item.index;
               const hoverIndex = index;

               if (dragIndex === hoverIndex) {
                    return;
               }

               const hoverBoundingRect = ref.current?.getBoundingClientRect();
               const hoverMiddleY =
                    (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
               const clientOffset = monitor.getClientOffset();
               const hoverClientY = clientOffset.y - hoverBoundingRect.top;

               if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                    return;
               }

               if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                    return;
               }

               moveElement(dragIndex, hoverIndex);

               item.index = hoverIndex;
          },
     });

     useEffect(() => {
          preview(getEmptyImage(), { captureDraggingState: false });
     }, []);

     drag(drop(ref));

     if (!inBuilder) {
          return (
               <div ref={ref} className="elements-grid--item" role="button">
                    <span className="icon">
                         <IconDivider />
                    </span>
                    <span className="text">{name}</span>
               </div>
          );
     }

     if (isDragging && !forceDisplay) {
          return <Placeholder />;
     }

     return (
          <div
               ref={ref}
               className={`selection ${!isValid && !isDragging && !isSelectedItem(id) ? 'is-warning' : ''}`}
               onClick={(e) => handleElementSelect(e, id)}
               style={{
                    backgroundColor:
                         selectedElement && selectedElement.id === id
                              ? 'rgba(24, 112, 244, 0.05)'
                              : '#fff',
                    position: 'relative',
               }}
          >
               <Overlay />

               <div
                    style={{
                         display: 'flex',
                         flexDirection: 'column',
                    }}
               >
                    {Boolean(invert) && <hr className="divider" />}
                    <h3 className="title">{title || ''}</h3>
                    <div className="paragraph">{text || ''}</div>
                    {!invert && <hr className="divider" />}
               </div>
               {isMaster && (
                   <Button
                       autoFocus={isSelectedItem(id)}
                       variant="danger"
                       onClick={() => handleRemoveElement(id)}
                       onMouseEnter={() => setActiveSpan(true)}
                       onMouseLeave={() => setActiveSpan(false)}
                       style={{
                            visibility: isSelectedItem(id) ? 'visible' : 'hidden',
                            position: 'relative',
                            zIndex: 3,
                       }}
                   >
                        <TimesIcon
                            color={`${COLORS.PLACEHOLDER_GREY}`}
                            activeColor={`${COLORS.WHITE}`}
                            hovered={activeSpan}
                        />
                   </Button>
               )}
          </div>
     );
};
