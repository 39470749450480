import React, {memo} from 'react';
import {Switch, Route} from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { HISTORY } from '../constants';

import GeneralError from './common/error';
import AuthPage from './auth';
import { MainPage } from './main/MainPage';
import Header from './main/templates/Header';
import Footer from './main/templates/Footer';
import NotFoundPage from './main/templates/NotFound';
import { SpeakerFormAnonymous } from './main/Speakers';
import NotSupportedPage from "./main/templates/NotSupported";
import {validateSupport} from "../utils/browser.utils";
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

export const App = memo(() => {

     validateSupport();

     return (
         <main>
              <div className="App">
                   <ConnectedRouter history={HISTORY}>
                        <GeneralError>
                        <Switch>
                              <Route exact path="/auth/speaker-form/:invitationToken/resend">
                                  <AuthPage />
                             </Route>
                             <Route exact path="/speaker-form/:invitationToken">
                                  <Header />
                                  <div className="content pl-3 pr-3">
                                       <SpeakerFormAnonymous />
                                  </div>
                                  <Footer />
                             </Route>
                             <Route exact path="/not-found-404">
                                  <Header />
                                  <div className="content">
                                       <NotFoundPage />
                                  </div>
                                  <Footer />
                             </Route>
                             <Route exact path="/not-supported">
                                  <Header />
                                  <div className="content">
                                       <NotSupportedPage />
                                  </div>
                                  <Footer />
                             </Route>
                             <Route path="/auth">
                                  <AuthPage />
                             </Route>
                             <Route path="/registration">
                                   <AuthPage />
                             </Route>
                             <Route path="/">
                                  <MainPage />
                             </Route>
                        </Switch>
                        </GeneralError>
                   </ConnectedRouter>
              </div>
         </main>
     );
});
