import styled from 'styled-components';
import { COLORS } from '../../../constants/style.constants';

const PolicyLinksWrapper = styled.div`
     display: flex;
     flex-flow: row wrap;

     a {
          text-decoration: none;

          &:hover {
               color: ${COLORS.WHITE};
          }
     }

     a:not(:last-child) {
          padding-right: 10px;
     }

     @media only screen and (max-width: 580px) and (min-width: 320px) {
          margin-bottom: 20px;
          justify-content: center;
     }
`;

export default PolicyLinksWrapper;
