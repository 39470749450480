import React from 'react';

export function EmailIcon() {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="15"
               height="11"
               fill="none"
               viewBox="0 0 15 11"
          >
               <path
                    fill="#8492A5"
                    d="M13.594 0H1.406C.631 0 0 .617 0 1.375v8.25C0 10.383.63 11 1.406 11h12.188C14.369 11 15 10.383 15 9.625v-8.25C15 .617 14.37 0 13.594 0zm0 .917c.063 0 .124.013.18.035L7.5 6.27 1.227.952a.476.476 0 01.18-.035h12.187zm0 9.166H1.406a.464.464 0 01-.469-.458V1.921l6.256 5.3a.475.475 0 00.614 0l6.255-5.3v7.704c0 .253-.21.458-.468.458z"
               ></path>
          </svg>
     );
}

export default EmailIcon;
