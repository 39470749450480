import { getRandomId } from './string.utils';
import Papa from 'papaparse';
import {isEmpty} from "underscore";
import i18next from "i18next";

export const sortAlphabetically = (options) => {
     let values = options.map(({ value }) => value);
     values = values.sort();

     let result = new Set([]); // default

     for (let i = 0, length = options.length; i !== length; i++) {
          let item = options[i];

          result.add({ ...item, value: values[i] });
     }

     return [...result];
};

export const sortSpeakerDefaultFields = (options) => {
     return options.sort((first, second) => {
          if (first.value === i18next.t('speakers.default_fields.name')) {
               return -1;
          }
          if (second.value === i18next.t('speakers.default_fields.name')) {
               return 1;
          }
          if (first.value === i18next.t('speakers.default_fields.email')) {
               return -1;
          }
          if (second.value === i18next.t('speakers.default_fields.email')) {
               return 1;
          }
          if (first.value === i18next.t('speakers.default_fields.invited_at')) {
               return -1;
          }
          if (second.value === i18next.t('speakers.default_fields.invited_at')) {
               return 1;
          }

          return 0;
     });
};

export const searchText = ({ collection, searchBy, value }) =>
     collection.filter((item) => item[searchBy].toLowerCase().includes(value));

export const bulkAdd = (str, languages) => {
     const parse = Papa.parse(str, {
          delimitersToGuess: [',', '\xa0', ' ', '\t', Papa.RECORD_SEP, Papa.UNIT_SEP]
     });

     let results = [];
     if (parse.data.length > 0) {
          parse.data.forEach(result => {
               let name = [];
               let email = '';
               let langCode = [];
               let emailFound = false;

               let parseResult = [];
               result.forEach(r => {
                    const value = r.trim().replace(/[^a-z0-9.!#$%&*+/=?^_@.\s]/gi,'');
                    if (value.indexOf(' ') !== -1) {
                         const values = value.split(' ');
                         values.forEach(v => parseResult.push(v));
                    } else {
                         parseResult.push(value);
                    }
               });

               parseResult.forEach(column => {
                    if (column.match(/\S+@\S+\.\S+/)) {
                         emailFound = true;
                         email = column;
                    } else {
                         if (emailFound) {
                              langCode.push(column.toLowerCase());
                         } else {
                              name.push(column);
                         }

                    }
               });

               const language = languages.find(l => l.code === langCode.join(''));
               const langId = language ? language.id : languages[0].id;

               if (!isEmpty(name.join(''))) {
                    results.push({
                         id: getRandomId(),
                         name: name.join(' '),
                         email: email,
                         language_id: langId
                    });
               }

          });
     }

     return results;
};

export const inArray = (collection, key) => {
     const contains = collection.filter(item => item === key);

     return contains.length > 0;
};
