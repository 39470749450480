import React from 'react';

function IconCalendar() {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="17"
               height="18"
               fill="none"
               viewBox="0 0 17 18"
          >
               <path
                    fill="currentColor"
                    d="M12.6 8.1h-1.8v1.8h1.8V8.1zM9 8.1H7.2v1.8H9V8.1zM5.4 8.1H3.6v1.8h1.8V8.1z"
               ></path>
               <path
                    fill="currentColor"
                    d="M14.4 1.8h-.9V0h-1.8v1.8H4.5V0H2.7v1.8h-.9C.801 1.8.009 2.61.009 3.6L0 16.2c0 .99.801 1.8 1.8 1.8h12.6c.99 0 1.8-.81 1.8-1.8V3.6c0-.99-.81-1.8-1.8-1.8zm0 14.4H1.8V6.3h12.6v9.9z"
               ></path>
          </svg>
     );
}

export default IconCalendar;
