import React from 'react';

function IconRadio() {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="18"
               height="18"
               fill="none"
               viewBox="0 0 18 18"
          >
               <path
                    fill="currentColor"
                    d="M9 4.5A4.502 4.502 0 004.5 9c0 2.484 2.016 4.5 4.5 4.5s4.5-2.016 4.5-4.5S11.484 4.5 9 4.5z"
               ></path>
               <path
                    fill="currentColor"
                    d="M9 0C4.028 0 0 4.028 0 9s4.028 9 9 9 9-4.027 9-9-4.027-9-9-9zm0 16.2A7.198 7.198 0 011.8 9c0-3.978 3.222-7.2 7.2-7.2s7.2 3.222 7.2 7.2-3.222 7.2-7.2 7.2z"
               ></path>
          </svg>
     );
}

export default IconRadio;
