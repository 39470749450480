import React from "react"
import ContentLoader from "react-content-loader"

export const AvatarCircleLoader = ({ width = 40, height = 40, ...restProps }) => (
    <ContentLoader
        speed={2}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...restProps}
    >
        <circle cx={width / 2} cy={width / 2} r={width / 2} />
    </ContentLoader>
);
