import React from 'react';

function CopyIcon() {
     return (
          <svg 
               width="15" 
               height="18" 
               viewBox="0 0 15 18" 
               fill="none" 
               xmlns="http://www.w3.org/2000/svg"
          >
               <path 
                    fill="#606D80"
                    d="M4.18404 0.280869C3.64584 0.426702 3.25001 0.881563 3.16668 1.45101C3.13195 1.68365 3.13543 1.72184 3.2014 1.84337C3.29168 2.01698 3.4514 2.11073 3.64931 2.10726C3.91668 2.10378 4.09029 1.9267 4.13195 1.60726C4.16668 1.37115 4.29515 1.24615 4.54167 1.2149C4.6389 1.20448 6.57292 1.19754 8.83681 1.20448C13.3437 1.2149 13.0208 1.20101 13.1701 1.41281C13.2257 1.48573 13.2292 1.96837 13.2396 7.48572C13.2465 12.2427 13.2396 13.5065 13.2049 13.6142C13.1528 13.7704 12.9757 13.9059 12.8229 13.9059C12.691 13.9059 12.4618 14.0239 12.4097 14.1177C12.2812 14.3607 12.309 14.5899 12.4826 14.7565C12.5694 14.8399 12.6215 14.8607 12.8056 14.8711C13.3542 14.9059 13.8958 14.5413 14.1215 13.9857L14.2014 13.7843V7.56906C14.2014 1.63156 14.1979 1.34684 14.1389 1.16976C14.0139 0.812119 13.6806 0.468369 13.316 0.319064L13.125 0.242676L8.75 0.235731C4.97917 0.228786 4.34723 0.235731 4.18404 0.280869Z" />
               <path 
                    fill="#606D80"
                    d="M0.937499 3.41282C0.562499 3.54129 0.236111 3.86074 0.079861 4.24268L0 4.44407V10.6593C0 16.5968 0.00347222 16.8816 0.0624999 17.0586C0.1875 17.4163 0.520833 17.76 0.885416 17.9093L1.07639 17.9857L5.43055 17.9961C10.1736 18.0066 10.0104 18.01 10.3507 17.8121C10.6875 17.6142 10.9548 17.2357 11.0243 16.8538C11.0486 16.7253 11.0555 14.6211 11.0521 10.5517L11.0417 4.44407L10.9618 4.24268C10.8021 3.85032 10.4757 3.54477 10.0729 3.40935C9.91666 3.35379 9.51041 3.35032 5.50694 3.35379C1.45486 3.35379 1.09722 3.36074 0.937499 3.41282ZM9.84027 4.37115C9.89235 4.39893 9.96874 4.46838 10.0035 4.52046C10.066 4.61421 10.0694 4.78435 10.0694 10.6593C10.0694 16.3746 10.066 16.7079 10.0069 16.7982C9.97568 16.8503 9.90624 16.9198 9.85416 16.951C9.76388 17.01 9.51388 17.0135 5.53819 17.0135C0.972221 17.0135 1.16667 17.0204 1.03472 16.826C0.975693 16.7392 0.972221 16.4475 0.961805 10.7427C0.95486 5.99268 0.961805 4.71838 0.996527 4.61421C1.0243 4.53088 1.0868 4.45102 1.15972 4.4024L1.27778 4.32254H5.51041C9.13888 4.32254 9.75693 4.32949 9.84027 4.37115Z" />
          </svg>

     );
}

export default CopyIcon;