import React from 'react';

function IconClock() {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="18"
               height="18"
               fill="none"
               viewBox="0 0 18 18"
          >
               <path
                    fill="currentColor"
                    d="M8.996 0A8.994 8.994 0 000 9c0 4.972 4.023 9 8.996 9C13.968 18 18 13.972 18 9s-4.032-9-9.004-9zM9 16.2A7.198 7.198 0 011.8 9c0-3.978 3.222-7.2 7.2-7.2s7.2 3.222 7.2 7.2-3.222 7.2-7.2 7.2z"
               ></path>
               <path
                    fill="currentColor"
                    d="M9.45 9.225V4.5H8.1v5.4l4.72 2.835.68-1.107-4.05-2.403z"
               ></path>
          </svg>
     );
}

export default IconClock;
