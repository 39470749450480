import React from 'react';
import { useHover } from '../../hooks';

export default function LinkedinIcon({ color, activeColor }) {
     const [hovered, handleHover] = useHover();

     return (
         <svg
             width="25"
             height="25"
             viewBox="0 0 25 25"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
             onMouseEnter={handleHover}
             onMouseLeave={handleHover}
         >
              <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.66211 0C1.63958 0 0 1.63958 0 3.66211V21.3379C0 23.3604 1.63958 25 3.66211 25H21.3379C23.3604 25 25 23.3604 25 21.3379V3.66211C25 1.63958 23.3604 0 21.3379 0H3.66211ZM5.81055 7.59882C5.81055 6.74961 6.44879 6.10352 7.42497 6.10352C8.40131 6.10352 9.00209 6.74961 9.0207 7.59882C9.0207 8.42927 8.40131 9.09379 7.40603 9.09379H7.38751C6.42978 9.09379 5.81055 8.42927 5.81055 7.59882ZM8.8888 10.2812H5.98647V18.9002H8.8888V10.2812ZM15.906 10.0615C14.3516 10.0615 13.3094 11.5166 13.3094 11.5166V10.2642H10.428V18.9004H13.3092V14.0776C13.3092 13.8194 13.328 13.5616 13.4041 13.377C13.6124 12.8614 14.0864 12.3273 14.8824 12.3273C15.925 12.3273 16.342 13.1193 16.342 14.2803V18.9004H19.223V13.9485C19.223 11.2959 17.8015 10.0615 15.906 10.0615Z"
                  fill={hovered ? activeColor : color}
              />
         </svg>

     );
}
