import React, {
     memo,
     useRef,
     useState,
     useEffect,
     useCallback
} from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import {
     requestFieldsSpeakerView,
     requestExportSpeakers,
     requestArchiveSpeakers,
} from '../../../../redux/reducers/speakerReducer';
import { COLORS } from '../../../../constants/style.constants';

const SettingsRadioGroup = ({ entries, handleChange }) => {
     const [currentItem, setCurrentItem] = useState(entries > 0 ? 'onlySelectedEntries' : 'everyone');

     useEffect(() => {
          handleChange(currentItem === 'onlySelectedEntries');
     }, [currentItem]);

     return (
          <div className="radio-group">
               <label className="form-label">
                    <input
                         type="radio"
                         value="everyone"
                         checked={currentItem === 'everyone'}
                         onChange={() => setCurrentItem('everyone')}
                    />
                    <div className="circle-outer">
                         <div className="circle-inner" />
                    </div>
                    {i18n.t('speakers.everyone')}
               </label>
               <label className="form-label">
                    <input
                         type="radio"
                         value="onlySelectedEntries"
                         checked={currentItem === 'onlySelectedEntries'}
                         onChange={() => setCurrentItem('onlySelectedEntries')}
                    />
                    <span className="circle-outer">
                         <span className="circle-inner" />
                    </span>
                    {`${i18n.t('speakers.only_selected_entries')}`}
               </label>
          </div>
     );
};

const FormatsRadioGroup = memo(({ handleSetFormat }) => {
     const [currentItem, setCurrentItem] = useState('CSV');

     useEffect(() => {
          handleSetFormat(currentItem.toLowerCase());
     }, [currentItem]);

     return (
          <div className="radio-group">
               <label className="form-label">
                    <input
                         type="radio"
                         value="CSV"
                         checked={currentItem === 'CSV'}
                         onChange={() => setCurrentItem('CSV')}
                    />
                    <div className="circle-outer">
                         <div className="circle-inner" />
                    </div>
                    CSV
               </label>
               <label className="form-label">
                    <input
                         type="radio"
                         value="TXT"
                         checked={currentItem === 'TXT'}
                         onChange={() => setCurrentItem('TXT')}
                    />
                    <div className="circle-outer">
                         <div className="circle-inner" />
                    </div>
                    TXT
               </label>
               <label className="form-label">
                    <input
                         type="radio"
                         value="XLS"
                         checked={currentItem === 'XLS'}
                         onChange={() => setCurrentItem('XLS')}
                    />
                    <div className="circle-outer">
                         <div className="circle-inner" />
                    </div>
                    XLS
               </label>
               <label className="form-label">
                    <input
                         type="radio"
                         value="XLSX"
                         checked={currentItem === 'XLSX'}
                         onChange={() => setCurrentItem('XLSX')}
                    />
                    <div className="circle-outer">
                         <div className="circle-inner" />
                    </div>
                    XLSX
               </label>
          </div>
     );
});

const mapStateToProps = (state) => ({
     fieldsList: state.speakerReducer.textFieldsList,
     mediaFieldsList: state.speakerReducer.mediaFieldsList,
     updating: state.speakerReducer.exportSpeakersRequested,
     succeded: state.speakerReducer.exportSpeakersSucceeded,
     succededArchive: state.speakerReducer.archiveSpeakersSucceeded,
     failed: state.speakerReducer.exportSpeakersFailed,
});

const mapDispatchToProps = (dispatch) => ({
     requestFieldsSpeakerView: (eventId) => dispatch(requestFieldsSpeakerView(eventId)),
     requestExportSpeakers: (payload) => dispatch(requestExportSpeakers(payload)),
     requestArchiveSpeakers: (payload) => dispatch(requestArchiveSpeakers(payload)),
});

export const _SpeakersExportModal = memo(
     ({
          show,
          settings,
          handleClose,
          requestExportSpeakers,
          requestArchiveSpeakers,
          updating,
          succeded,
          succededArchive,
          failed,
          ids,
          fieldsList,
          mediaFieldsList,
          organizationId
     }) => {
          const [modalOptions, setModalOptions] = useState([]);
          const [mediaOptions, setMediaOptions] = useState([]);
          const [exportArchive, setExportArchive] = useState(false);
          const [allSelected, setAllSelected] = useState(false);
          const [allSelectedMedia, setAllSelectedMedia] = useState(false);
          const [onlySelectedEntries, setOnlySelectedEntries] = useState(ids.length > 0);
          const [format, setFormat] = useState('csv');
          const checkboxRef = useRef();
          const { t } = useTranslation();
          const { eventId } = useParams();

          useEffect(() => {
               // Disable scrolling on main page
               document.body.style.overflow = show ? 'hidden' : 'unset';
          }, [show]);

          useEffect(() => {
               if (settings) {
                    const updated = settings.filter(({ value }) => fieldsList.includes(value));
                    setModalOptions(updated);

                    const media = settings.filter(({ value }) => mediaFieldsList.includes(value));
                    setMediaOptions(media);
               }
          }, [settings]);

          useEffect(() => {
               if (succeded || succededArchive) {
                    handleClose();
               }
          }, [succeded, succededArchive]);

          useEffect(() => {
               const selected = mediaOptions.filter(({selected}) => selected === true);

               if (selected.length > 0 && exportArchive === false) {
                    setExportArchive(true);
               } else if (selected.length === 0 && exportArchive === true) {
                    setExportArchive(false);
               }
          }, [mediaOptions]);

          const handleSetFormat = useCallback(
               (f) => {
                    setFormat(f);
               },
               [format]
          );

          const handleSelectedEntries = (allSelected) => {
               setOnlySelectedEntries(allSelected);
          };

          const handleSubmit = (e) => {
               e.preventDefault();

               let payload = {
                    format,
                    fields: modalOptions
                        .filter((item) => !!item.selected && item.id !== 1)
                        .map(({ value }) => value),
                    ids: onlySelectedEntries ? ids : [],
               };

               if (exportArchive) {
                    payload.files = mediaOptions
                        .filter((item) => !!item.selected)
                        .map(({ value }) => value);

                    requestArchiveSpeakers({ eventId, organization_id: organizationId, ...payload });
               } else {
                    requestExportSpeakers({ eventId, ...payload });
               }
          };

          const handleCheckboxClick = (code) => {
               const updated = modalOptions.map(option =>
                   ({
                        ...option,
                        selected: option.value === code ? !option.selected : option.selected
                   })
               );

               const filterFields = updated.filter(option => fieldsList.includes(option.value) && option.selected);
               const updateSelectedAll = filterFields.length === fieldsList.length;

               setModalOptions(updated);
               setAllSelected(updateSelectedAll);
          };

          const handleSelectAllFields = () => {
               const isAllSelected = allSelected ? allSelected : false;

               const updated = modalOptions.map(option => {

                    if (fieldsList.includes(option.value)) {
                         return {
                              ...option,
                              selected: !isAllSelected
                         };
                    } else {

                         return option;
                    }
               });

               setModalOptions(updated);
               setAllSelected(!allSelected);
          };

          const handleMediaCheckboxClick = (code) => {
               const updated = mediaOptions.map(option =>
                   ({
                        ...option,
                        selected: option.value === code ? !option.selected : option.selected
                   })
               );

               const filterFields = updated.filter(option => mediaFieldsList.includes(option.value) && option.selected);
               const updateSelectedAll = filterFields.length === mediaFieldsList.length;

               setMediaOptions(updated);
               setAllSelectedMedia(updateSelectedAll);
               setExportArchive(filterFields.length > 0)
          };

          const handleMediaSelectAllFields = () => {
               const isAllSelected = allSelectedMedia ? allSelectedMedia : false;

               const updated = mediaOptions.map(option => {

                    if (mediaFieldsList.includes(option.value)) {
                         return {
                              ...option,
                              selected: !isAllSelected
                         };
                    } else {

                         return option;
                    }
               });

               setMediaOptions(updated);
               setAllSelectedMedia(!isAllSelected);
               setExportArchive(!isAllSelected);
          };

          return (
               <Modal
                    show={show}
                    onHide={handleClose}
                    container={document.getElementById('root')}
               >
                    <Modal.Header>
                         <Modal.Title>{t('speakers.export')}</Modal.Title>
                         <button className="close" type="button">
                              <i
                                   className="las la-times"
                                   onClick={handleClose}
                              />
                         </button>
                    </Modal.Header>
                    <Form noValidate onSubmit={handleSubmit}>
                         <Modal.Body>
                              <SettingsRadioGroup entries={ids.length} handleChange={handleSelectedEntries} />

                              <p>{t('speakers.fields_to_export')}</p>
                              <Row
                                   sm={12}
                                   style={{
                                        borderBottom: `1px dashed ${COLORS.LIGHTER_BLUE}`,
                                   }}
                              >
                                   <Col
                                       key={`shared-select-all`}
                                       md="auto"
                                       sm={3}
                                   >
                                        <Form.Group
                                            key={'select-all'}
                                            ref={checkboxRef}
                                        >
                                             <div
                                                 className="checkbox-solid"
                                                 onClick={() => handleSelectAllFields()}
                                             >
                                                  <input
                                                      type="checkbox"
                                                      checked={allSelected}
                                                      onChange={() => handleSelectAllFields()}
                                                  />
                                                  <span />
                                                  <Form.Label
                                                      style={{
                                                           textTransform:
                                                               'capitalize',
                                                      }}
                                                  >
                                                       {
                                                            t('common.all')
                                                       }
                                                  </Form.Label>
                                             </div>
                                        </Form.Group>
                                   </Col>
                                   {modalOptions.length &&
                                        modalOptions.map(
                                             ({ id, value, selected }) => {
                                                  return (
                                                       <Col
                                                            key={id}
                                                            md="auto"
                                                            sm={3}
                                                       >
                                                            <Form.Group
                                                                 key={value}
                                                                 ref={
                                                                      checkboxRef
                                                                 }
                                                            >
                                                                 <div
                                                                      className="checkbox-solid"
                                                                      onClick={() =>
                                                                           handleCheckboxClick(value)
                                                                      }
                                                                 >
                                                                      <input
                                                                           type="checkbox"
                                                                           checked={selected}
                                                                           onChange={() =>
                                                                                handleCheckboxClick(value)
                                                                           }
                                                                      />
                                                                      <span />
                                                                      <Form.Label>
                                                                           {
                                                                                value
                                                                           }
                                                                      </Form.Label>
                                                                 </div>
                                                            </Form.Group>
                                                       </Col>
                                                  );
                                             }
                                        )}
                              </Row>
                              <p style={{ marginTop: '20px' }}>
                                   {t('speakers.file_format')}
                              </p>
                              <FormatsRadioGroup
                                   handleSetFormat={handleSetFormat}
                              />
                              {mediaOptions.length > 0 && (
                                  <>
                                   <Row sm={12} style={{
                                        marginTop: '20px',
                                        paddingTop: '20px',
                                        borderTop: `1px dashed ${COLORS.LIGHTER_BLUE}`
                                   }}>
                                        <Col md="auto" sm={3}>
                                             <Form.Label>
                                             {t('speakers.download_as_archive')}
                                             </Form.Label>
                                        </Col>
                                   </Row>
                                       <Row
                                           sm={12}
                                       >
                                            <Col
                                                key={`shared-select-all`}
                                                md="auto"
                                                sm={3}
                                            >
                                                 <Form.Group
                                                     key={'select-all'}
                                                     ref={checkboxRef}
                                                 >
                                                      <div
                                                          className="checkbox-solid"
                                                          onClick={() => handleMediaSelectAllFields()}
                                                      >
                                                           <input
                                                               type="checkbox"
                                                               checked={allSelectedMedia}
                                                               onChange={() => handleMediaSelectAllFields()}
                                                           />
                                                           <span />
                                                           <Form.Label
                                                               style={{
                                                                    textTransform:
                                                                        'capitalize',
                                                               }}
                                                           >
                                                                {
                                                                     t('common.all')
                                                                }
                                                           </Form.Label>
                                                      </div>
                                                 </Form.Group>
                                            </Col>
                                            {mediaOptions.map(
                                                ({ id, value, selected }) => {
                                                     return (
                                                         <Col
                                                             key={id}
                                                             md="auto"
                                                             sm={3}
                                                         >
                                                              <Form.Group
                                                                  key={value}
                                                                  ref={
                                                                       checkboxRef
                                                                  }
                                                              >
                                                                   <div
                                                                       className="checkbox-solid"
                                                                       onClick={() =>
                                                                           handleMediaCheckboxClick(value)
                                                                       }
                                                                   >
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={selected}
                                                                            onChange={() =>
                                                                                handleMediaCheckboxClick(value)
                                                                            }
                                                                        />
                                                                        <span />
                                                                        <Form.Label>
                                                                             {
                                                                                  value
                                                                             }
                                                                        </Form.Label>
                                                                   </div>
                                                              </Form.Group>
                                                         </Col>
                                                     );
                                                }
                                            )}
                                       </Row>
                                  </>
                              )}
                         </Modal.Body>
                         <Modal.Footer>
                              <Button variant="secondary" onClick={handleClose}>
                                   {t('buttons.close')}
                              </Button>
                              <Button
                                   variant="primary"
                                   type="submit"
                                   disabled={updating && !(succeded || failed)}
                              >
                                   {t('speakers.export')}
                              </Button>
                         </Modal.Footer>
                    </Form>
               </Modal>
          );
     }
);

export const SpeakersExportModal = connect(
     mapStateToProps,
     mapDispatchToProps
)(_SpeakersExportModal);
