import i18n from 'i18next';
import toastr from 'toastr';
import {call, put, all, takeEvery} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
     successEventEmailTemplates,
     failEventEmailTemplates,
     successCreateEmailTemplate,
     failCreateEmailTemplate,
     successRemoveEmailTemplate,
     failRemoveEmailTemplate,
     successUploadFile,
     failUploadFile,
     successFilesData,
     failFilesData, requestEventEmailTemplates
} from '../reducers/emailTemplatesReducer';
import * as ACTIONS from '../actions';
import * as Service from '../../services';
import { handleErrors } from '../../utils/sagaUtils';

export function* eventEmailTemplatesSaga({ payload }) {
     const response = yield call(Service.getEventEmailTemplates, payload);

     if (response.status === 200) {
          return yield put(successEventEmailTemplates(response.data.data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     if (response.status === 404) {
          return yield put(push('/not-found-404'));
     }

     yield put(failEventEmailTemplates(response.message));
}

export function* createEmailTemplateSaga({ payload }) {
     const response = yield call(Service.createEmailTemplate, payload);

     if (response.status === 201) {
          return yield put(successCreateEmailTemplate(payload.message));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     if (response.status === 404) {
          return yield put(push('/not-found-404'));
     }

     response.errors && handleErrors(response.errors);
     yield put(failCreateEmailTemplate(response.message));
}

export function* removeEmailTemplateSaga({ payload }) {
     const response = yield call(Service.removeEmailTemplate, payload);

     if (response.status === 200) {
          toastr.success(i18n.t('alerts.success.template_removed'));
          yield put(requestEventEmailTemplates(payload.eventId));

          return yield put(successRemoveEmailTemplate(payload.message));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     if (response.status === 404) {
          return yield put(push('/not-found-404'));
     }

     yield put(failRemoveEmailTemplate(response.message));
}

export function* uploadFileSaga({ payload }) {
     const response = yield call(Service.uploadFile, payload);

     const allCreated = response.every(({ status }) => status === 201);

     if (allCreated) {
          return yield put(
               successUploadFile(response.map(({ data: { data } }) => data))
          );
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     if (response.status === 404) {
          return yield put(push('/not-found-404'));
     }

     response.errors && handleErrors(response.errors);
     yield put(failUploadFile(response.message));
}

export function* getFilesData({ payload }) {
     const response = yield call(Service.getFilesData, payload);

     if (response.status === 200) {
          return yield put(successFilesData(response.data.data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     yield put(failFilesData(response.message));
}

// export function* uploadMultipleFilesSaga({ payload }) {
//      const response = yield call(Service.uploadMulitpleFiles, payload);

//      if (response.status === 200) {
//           return yield put(successUploadMiltipleFiles(response.data.message));
//      }

//      if (response.status === 401) {
//           return yield put(push('/auth/login'));
//      }

//      yield put(failUploadMiltipleFiles(response.message));
// }

export function* watchEmailTemplates() {
     yield all([
          takeEvery(ACTIONS.EVENT_EMAIL_TEMPLATES_REQUEST, eventEmailTemplatesSaga),
          takeEvery(ACTIONS.CREATE_EMAIL_TEMPLATE_REQUEST, createEmailTemplateSaga),
          takeEvery(ACTIONS.REMOVE_EMAIL_TEMPLATE_REQUEST, removeEmailTemplateSaga),
          takeEvery(ACTIONS.UPLOAD_FILE_REQUEST, uploadFileSaga),
          takeEvery(ACTIONS.FILES_DATA_REQUEST, getFilesData),
     ])
}
