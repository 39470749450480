import React, { forwardRef, useState } from 'react';
import i18n from 'i18next';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { StyledCustomDatePicker } from './CustomDatePicker.style';
import IconCalendar from "../../../assets/icons/IconCalendar";

export const CustomDatePicker = forwardRef(
     (
          {
               name = null,
               date = null,
               handleChange = null,
               placeholderText = null,
               minDate = null,
               required = false,
               showDisabledMonthNavigation = false,
               isInvalid = false,
               languageCode
          },
          ref
     ) => {
          const [value, setValue] = useState(date);

          return (
               <StyledCustomDatePicker>
                    <div className="input-group">
                         <DatePicker
                              popperPlacement="bottom-end"
                              popperModifiers={{
                                   flip: {
                                       behavior: ["bottom"] // don't allow it to flip to be above
                                   },
                                   preventOverflow: {
                                       enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                   },
                                   hide: {
                                       enabled: false // turn off since needs preventOverflow to be enabled
                                   }
                               }}
                              name={name}
                              minDate={minDate}
                              showDisabledMonthNavigation={
                                   showDisabledMonthNavigation
                              }
                              required={required}
                              className={`form-control ${isInvalid ? "is-invalid" : ""}`}
                              selected={value}
                              onChange={(date) => {
                                  setValue(date);
                                  handleChange(date);
                              }}
                              // popperClassName="datepicker-popper"
                              popperPlacement="bottom-start"
                              placeholderText={
                                   placeholderText ||
                                   i18n.t('date_picker.select_date', { lng: languageCode })
                              }
                              renderCustomHeader={({
                                   prevMonthButtonDisabled,
                                   nextMonthButtonDisabled,
                                   decreaseMonth,
                                   increaseMonth,
                                   date
                              }) => (
                                   <div
                                        className="header"
                                        style={{ display: 'flex' }}
                                   >
                                        <button
                                             onClick={(event) => {
                                                 event.preventDefault();
                                                 decreaseMonth();
                                             }}
                                             disabled={prevMonthButtonDisabled}
                                        >
                                             {
                                                  <i className="la la-angle-left" />
                                             }
                                        </button>
                                        <div className="react-datepicker__current-month">
                                             {`${moment(date).format(
                                                  'MMMM'
                                             )}, ${moment(date).format('YYYY')}`}
                                        </div>
                                        <button
                                             onClick={(event) => {
                                                 event.preventDefault();
                                                 increaseMonth();
                                             }}
                                             disabled={nextMonthButtonDisabled}
                                        >
                                             {
                                                  <i className="la la-angle-right" />
                                             }
                                        </button>
                                   </div>
                              )}
                         />
                         <span className="suffix">
                              <IconCalendar/>
                         </span>
                    </div>
               </StyledCustomDatePicker>
          );
     }
);
