import React, {useEffect, useRef, useState} from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import OrganizationBlock from '../../common/organization-block';
import { useTransition } from '../../../hooks';
import { requestUserProfile } from '../../../redux/reducers/userReducer';
import { requestDisplayJoinInvitation } from '../../../redux/reducers/organizationReducer';

import { InfoWrapper } from '../auth-styles/RightBlock.style';
import {SignUpForm} from "../../main/UserSettings/forms/SignUpForm";
import {LoginForm} from "../forms/LoginForm";
import {ForgotPasswordForm} from "../forms/ForgotPasswordForm";
import { setLocale } from '../../../services/instance';

const mapStateToProps = (state) => ({
     auth: state.authReducer,
     router: state.router,
     organizationInvite: state.organizationReducer.organizationInvite,
     user: state.userReducer.currentUser,
     userProfileFailed: state.userReducer.userProfileFailed
});

const mapDispatchToProps = (dispatch) => ({
     requestUserProfile: () => dispatch(requestUserProfile()),
     requestDisplayJoinInvitation: (token) => dispatch(requestDisplayJoinInvitation(token)),
});

export const _Invitation = (props) => {
     const { transition, toggleTransition } = useTransition();
     const [displayRegistration, setDisplayRegistration] = useState(false);
     const [displayForgotPassword, setDisplayForgotPassword] = useState(false);

     const {
          user,
          organizationInvite,
          requestDisplayJoinInvitation,
          requestUserProfile,
          userProfileFailed,
     } = props;

     const { joinToken } = useParams();
     const { t } = useTranslation();
     const history = useHistory();

     useEffect(() => {
          requestUserProfile();

          if (user) {
               history.push(`/auth/logged-in-user/${joinToken}`);
          }
     }, [user, joinToken, history]);

     useEffect(() => {
          if (joinToken) {
               requestDisplayJoinInvitation(joinToken);
          }
     }, [joinToken]);

     useEffect(() => {
          if (organizationInvite) {
               setLocale(organizationInvite.organization.language);
          }
     }, [organizationInvite]);

     const displayFirstBlock = () => {

          return (
               <>
                    <Helmet>
                         <title>
                              {t('login.header')} | {t('avovent')}
                         </title>
                    </Helmet>
                    <InfoWrapper>
                         <OrganizationBlock
                         picture={
                              organizationInvite &&
                              organizationInvite.organization.photos['180x180']
                         }
                         pictureAltText={t('invitation.avatar')}
                         title={
                              organizationInvite &&
                              organizationInvite.organization.name
                         }
                         >
                              <p>
                                   <b>
                                        {organizationInvite &&
                                        `${organizationInvite.user.firstname} ${organizationInvite.user.lastname}`}
                                   </b>{' '}
                                   {t('invitation.invite')}{' '}
                                   <b>
                                        {organizationInvite &&
                                        organizationInvite.organization.name}
                                   </b>{' '}
                                   {t('invitation.organization')}
                              </p>
                         </OrganizationBlock>
                         <div className="link-block">
                              <LoginForm toggleTransition={() => {
                                   setDisplayRegistration(true);
                                   toggleTransition();
                              }} registerButton />
                         </div>
                         <div className="link-block" style={{ marginTop: '30px', paddingTop: '29px' }}>
                              <a href="#forgot-password" onClick={() => {
                                   setDisplayForgotPassword(true);
                                   toggleTransition();
                              }}>
                                   {t('login.forgot_password')}
                              </a>
                         </div>
                    </InfoWrapper>
               </>
          );
     };

     const displaySecondBlock = () => {

          return (
               <>
               {displayRegistration && (
                         <>
                              {window.location.hash === '#sign-up' && (
                                   <Helmet>
                                        <title>
                                             {t('signup')} | {t('avovent')}
                                        </title>
                                   </Helmet>
                              )}
                              <SignUpForm
                                   joinToken={joinToken}
                                   backLink={`/auth/invitation/${joinToken}`}
                                   toggleTransition={() => {
                                        toggleTransition();
                                        setTimeout(() => {
                                             setDisplayRegistration(false);
                                        }, 500);
                                   }}
                              />
                         </>
                    )}
                    {displayForgotPassword && (
                         <ForgotPasswordForm toggleTransition={() => {
                              toggleTransition();
                              setTimeout(() => {
                                   setDisplayForgotPassword(false);
                              }, 500);
                         }} />
                    )}
               </>
          );
     };


     return (
          userProfileFailed &&
          !user && (
               <div className="transition-group-wrapper center">
                    <div className={transition ? 'from-hidden' : 'from-visible'}>
                         {displayFirstBlock()}
                    </div>
                    <div className={transition ? 'to-visible' : 'to-hidden'}>
                         {displaySecondBlock()}
                    </div>
               </div>
          )
     );
};

export const Invitation = connect(
     mapStateToProps,
     mapDispatchToProps
)(_Invitation);
