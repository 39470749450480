import React, { memo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BulkAddModal } from './BulkAddModal';
import PasteIcon from '../../../../assets/icons/metronic/paste-icon';

import { SpeakerRow } from './SpeakerRow';
import {isEmpty} from "underscore";

export const InviteSpeakers = memo(
     ({
          id,
          eventId,
          speakers,
          handleAddSpeaker,
          handleBulkAddSpeakers,
          handleFieldChange,
          handleRemoveSpeaker,
          setRunValidations,
          organizationLanguage,
          handleChangeCurrentStep,
          languages
     }) => {
          const [showModal, setShowModal] = useState(false);

          const { t } = useTranslation();

         useEffect(() => {
             handleChangeCurrentStep(1);
         });

          return (
               <div className="invite-speakers">
                    <div className="headline">{t('event.speakers')}</div>
                    <span className="hint">
                         {t('speakers.invite_speakers_hint')}
                    </span>
                    <Row className="mt-30 not-sr">
                         <Col>
                              <Form.Label>
                                   {t('form_builder.type_options.full_name')}
                              </Form.Label>
                         </Col>
                         <Col>
                              <Form.Label>
                                   {t(
                                        'user_settings.settings_form.email_address'
                                   )}
                              </Form.Label>
                         </Col>
                         <Col>
                              <Form.Label>
                                   {t('form_builder.language')}
                              </Form.Label>
                         </Col>
                    </Row>
                    {speakers.map((speaker, index) => (
                         <SpeakerRow
                              key={speaker.id}
                              speakerID={speaker.id}
                              organizationLanguage={organizationLanguage}
                              handleFieldChange={handleFieldChange}
                              name={speaker.name}
                              email={speaker.email}
                              language={speaker.language_id}
                              onSpeakerAdd={handleAddSpeaker}
                              onSpeakerRemove={() => handleRemoveSpeaker(speaker.id)}
                              showRemoveButton={speakers.length > 1}
                              zIndex={speakers.length - index}
                              index={index}
                              errors={speaker.errors}
                         />
                    ))}
                    <div className="form-actions">
                         <>
                              <Button
                                   variant="default"
                                   onClick={() => setShowModal(true)}
                                   style={{ marginTop: '5px' }}
                              >
                                   <span>{t('speakers.bulk_add')}</span>
                                   <PasteIcon />
                              </Button>
                              <BulkAddModal
                                   show={showModal}
                                   handleBulkAddSpeakers={handleBulkAddSpeakers}
                                   handleClose={() => setShowModal(false)}
                                   languages={languages}
                              />
                         </>
                        <Button
                            variant="primary"
                            disabled={!(speakers.length >= 1 && !isEmpty(speakers[0].name) && !isEmpty(speakers[0].email))}
                            onClick={handleAddSpeaker}
                            style={{ marginTop: '5px' }}
                        >
                            {t('buttons.add_more')}
                        </Button>
                    </div>
                    <div className="actions-section">
                        <Row className={'buttons'}>
                            <Link
                                to={`/organization/${id}/events/${eventId}/speakers`}
                            >
                                <Button variant="default">
                                    {t('buttons.close')}
                                </Button>
                            </Link>
                            <Button
                                variant="success"
                                onClick={() => setRunValidations(true)}
                            >
                                {t('buttons.next')}
                            </Button>
                        </Row>
                    </div>
               </div>
          );
     }
);
