import instance, {getToken, getUrl} from './instance';
import {refreshAvatars} from './commonService';

export const getUserOrganization = async () => {
     try {
          return await instance.get(`${getUrl()}/organization`, {
               headers: {
                    common: {
                         Authorization:
                             `Bearer ${getToken()}` || 'Bearer token',
                    },
               },
          });
     } catch (error) {
          if (error.response) {
               return error.response.data;
          }

          throw Error(error.message);
     }
};

export const getOrganizationDetails = async (organizationId) => {
     try {
          return await instance.get(
              `${getUrl()}/organization/${organizationId}`,
              {
                   headers: {
                        common: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                   },
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const updateOrganizationDetails = async (payload) => {
     const { organizationId, ...rest } = payload;

     try {
          return await instance.post(
               `${getUrl()}/organization/${organizationId}`,
               rest,
               {
                    headers: {
                         Authorization:
                              `Bearer ${getToken()}` || 'Bearer token',
                    },
               }
          );
     } catch (error) {
          if (error.response) {
               return error.response.data;
          }

          throw Error(error.message);
     }
};

export const createNewOrganization = async (payload) => {
     try {
          return await instance.post(
               `${getUrl()}/organization/create`,
               payload,
               {
                    headers: {
                         Authorization:
                              `Bearer ${getToken()}` || 'Bearer token',
                    },
               }
          );
     } catch (error) {
          if (error.response) {
               return error.response.data;
          }

          throw Error(error.message);
     }
};

export const organizationPhotoUpload = async (payload) => {
     const formData = new FormData();
     try {
          formData.append('photo', payload.organizationPhoto);
          return await instance.post(
               `${getUrl()}/media/organization/${payload.organizationId}`,
               formData,
               {
                    headers: {
                         Authorization:
                              `Bearer ${getToken()}` || 'Bearer token',
                         accept: 'image/*',
                    },
                    params: payload.crop
               }
          );
     } catch (error) {
          if (error.response) {
               return error.response.data;
          }

          throw Error(error.message);
     }
};

export const getOrganizationMembers = async (payload) => {
     try {
          if (payload.query) {
               return await instance.post(
                   `${getUrl()}/organization/${payload.id}/members/search`,
                   { query: payload.query },
                   {
                        headers: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                        params: {
                             per_page: payload.per_page,
                             page: payload.page,
                             sort: payload.sort,
                             direction: payload.direction
                        }
                   }
               );
          }

          return await instance.get(
              `${getUrl()}/organization/${payload.id}/members`,
              {
                   headers: {
                        common: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                   },
                   params: {
                        per_page: payload.per_page,
                        page: payload.page,
                        sort: payload.sort,
                        direction: payload.direction
                   },
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response.data;
          }

          throw Error(error.message);
     }
};

export const getOrganizationMembersInvitations = async (payload) => {
     try {
          return await instance.get(
              `${getUrl()}/organization/${payload.id}/members/invitations`,
              {
                   headers: {
                        common: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                   },
                   params: {
                        per_page: payload.per_page,
                        page: payload.page,
                        sort: payload.sort,
                        direction: payload.direction
                   },
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response.data;
          }

          throw Error(error.message);
     }
};

export const organizationMembersInvitationsResend = async (payload) => {
     try {
          return await instance.post(
              `${getUrl()}/organization/${payload.organization_id}/members/invitations/${payload.id}`,
              {},
              {
                   headers: {
                        common: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                   }
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response.data;
          }

          throw Error(error.message);
     }
};

export const organizationMembersInvitationsRemove = async (payload) => {
     try {
          return await instance.delete(
              `${getUrl()}/organization/${payload.organization_id}/members/invitations/${payload.id}`,
              {
                   headers: {
                        common: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                   }
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response.data;
          }

          throw Error(error.message);
     }
};

export const updateMemberScope = async (payload) => {
     const { organizationId, userId, scope } = payload;

     try {
          return await instance.post(
               `${getUrl()}/organization/${organizationId}/members/${userId}/scope`,
               { scope },
               {
                    headers: {
                         Authorization:
                              `Bearer ${getToken()}` || 'Bearer token',
                    },
               }
          );
     } catch (error) {
          if (error.response) {
               return error.response.data;
          }

          throw Error(error.message);
     }
};

export const inviteMembers = async (payload) => {
     const { organizationId, emails } = payload;

     try {
          return await instance.post(
               `${getUrl()}/organization/${organizationId}/members/invite`,
               { emails },
               {
                    headers: {
                         Authorization:
                              `Bearer ${getToken()}` || 'Bearer token',
                    },
               }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const removeMember = async ({ organizationId, userId }) => {
     try {
          return await instance.delete(
              `${getUrl()}/organization/${organizationId}/members/${userId}`,
              {
                   headers: {
                        common: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                   },
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response.data;
          }

          throw Error(error.message);
     }
};

export const getOrganizationEmailSenders = async (payload) => {
     try {
          if (payload.query) {
               return await instance.post(
                   `${getUrl()}/organization/${payload.id}/email-sender/search`,
                   { query: payload.query },
                   {
                        headers: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                        params: {
                             per_page: payload.per_page,
                             page: payload.page,
                             sort: payload.sort,
                             direction: payload.direction
                        }
                   }
               );
          }

          return await instance.get(
              `${getUrl()}/organization/${payload.id}/email-sender`,
              {
                   headers: {
                        common: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                   },
                   params: {
                        per_page: payload.per_page,
                        page: payload.page,
                        sort: payload.sort,
                        direction: payload.direction
                   }
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response.data;
          }

          throw Error(error.message);
     }
};

export const getAllOrganizationEmailSenders = async (payload) => {
     try {
          return await instance.get(
              `${getUrl()}/organization/${payload.id}/email-sender/all`,
              {
                   headers: {
                        common: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                   }
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response.data;
          }

          throw Error(error.message);
     }
};

export const createEmailSender = async (payload) => {
     const { organizationId, ...rest } = payload;

     try {
          return await instance.post(
               `${getUrl()}/organization/${organizationId}/email-sender`,
               rest,
               {
                    headers: {
                         Authorization:
                              `Bearer ${getToken()}` || 'Bearer token',
                    },
               }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const verifyEmailSender = async (payload) => {
     const { organizationId, token } = payload;

     try {
          return await instance.get(
              `${getUrl()}/organization/${organizationId}/email-sender/verify/${token}`,
              {
                   headers: {
                        Authorization:
                            `Bearer ${getToken()}` || 'Bearer token',
                   },
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response.data;
          }

          throw Error(error.message);
     }
};

export const verifyResendEmailSender = async (payload) => {
     const { organizationId, id } = payload;

     try {
          return await instance.get(
              `${getUrl()}/organization/${organizationId}/email-sender/verify/${id}/resend`,
              {
                   headers: {
                        Authorization:
                            `Bearer ${getToken()}` || 'Bearer token',
                   },
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response.data;
          }

          throw Error(error.message);
     }
};

export const removeEmailSender = async ({ organizationId, emailSenderId }) => {
     try {
          return await instance.delete(
              `${getUrl()}/organization/${organizationId}/email-sender/${emailSenderId}`,
              {
                   headers: {
                        common: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                   },
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const displayJoinInvitation = async (token) => {
     try {
          return await instance.get(`${getUrl()}/organization/join/${token}`);
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const joinOrganization = async (token) => {
     try {
          return await instance.post(`${getUrl()}/organization/join`, { token },
              {
               headers: {
                    Authorization: `Bearer ${getToken()}` || 'Bearer token',
               },
          });
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const refreshAvatarsWholeList = (list) => {
     return list.map((organization) => {
          if (organization.photos) {
               organization.photos = refreshAvatars(organization.photos);
          }

          return organization;
     });
};

export const removeOrganizationAvatar = async ({ organizationId }) => {
     try {
          return await instance.delete(
              `${getUrl()}/media/organization/${organizationId}`,
              {
                   headers: {
                        common: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                   },
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

// export const refreshAvatars = (photos) => {
//      const keys = Object.keys(photos);
//      const time = moment();

//      keys.forEach((key, index) => {
//           photos[key] = photos[key] + '?t=' + time.unix();
//      });

//      return photos;
// };
