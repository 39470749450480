import * as ACTIONS from '../actions';

export const speakersClearMessages = () => ({
     type: ACTIONS.SPEAKERS_CLEAR_MESSAGES,
});

export const requestSpeakerInvite = (payload) => ({
     type: ACTIONS.SPEAKER_INVITE_REQUEST,
     payload,
});

export const successSpeakerInvite = (payload) => ({
     type: ACTIONS.SPEAKER_INVITE_SUCCESS,
     payload,
});

export const failSpeakerInvite = (error) => ({
     type: ACTIONS.SPEAKER_INVITE_FAILURE,
     error,
});

export const requestInvitationDetails = (payload) => ({
     type: ACTIONS.INVITATION_DETAILS_REQUEST,
     payload,
});

export const successInvitationDetails = (payload) => ({
     type: ACTIONS.INVITATION_DETAILS_SUCCESS,
     payload,
});

export const failInivitationDetails = (error) => ({
     type: ACTIONS.INVITATION_DETAILS_FAILURE,
     error,
});

export const requestInvitationDetailsBasic = (payload) => ({
     type: ACTIONS.INVITATION_DETAILS_BASIC_REQUEST,
     payload,
});

export const successInvitationDetailsBasic = (payload) => ({
     type: ACTIONS.INVITATION_DETAILS_BASIC_SUCCESS,
     payload,
});

export const failInivitationDetailsBasic = (error) => ({
     type: ACTIONS.INVITATION_DETAILS_BASIC_FAILURE,
     error,
});

export const requestSpeakerConnect = (payload) => ({
     type: ACTIONS.CONNECT_SPEAKER_REQUEST,
     payload,
});

export const successSpeakerConnect = (payload) => ({
     type: ACTIONS.CONNECT_SPEAKER_SUCCESS,
     payload,
});

export const failSpeakerConnect = (error) => ({
     type: ACTIONS.CONNECT_SPEAKER_FAILURE,
     error,
});

export const requestSpeakerEvents = () => ({
     type: ACTIONS.SPEAKER_EVENTS_REQUEST,
});

export const successSpeakerEvents = (payload) => ({
     type: ACTIONS.SPEAKER_EVENTS_SUCCESS,
     payload,
});

export const failSpeakerEvents = (error) => ({
     type: ACTIONS.SPEAKER_EVENTS_FAILURE,
     error,
});

export const requestEventSpeakers = (payload) => ({
     type: ACTIONS.EVENT_SPEAKERS_REQUEST,
     payload,
});

export const successEventSpeakers = (payload) => ({
     type: ACTIONS.EVENT_SPEAKERS_SUCCESS,
     payload,
});

export const failEventSpeakers = (error) => ({
     type: ACTIONS.EVENT_SPEAKERS_FAILURE,
     error,
});

export const getSpeakersPageDetails = (payload) => ({
     type: ACTIONS.GET_SPEAKERS_PAGE_DETAILS,
     payload,
});

export const requestUpdateSpeakerAnonymously = (payload) => ({
     type: ACTIONS.UPDATE_SPEAKER_ANONYMOUSLY_REQUEST,
     payload,
});

export const successUpdateSpeakerAnonymously = (payload) => ({
     type: ACTIONS.UPDATE_SPEAKER_ANONYMOUSLY_SUCCESS,
     payload,
});

export const failUpdateSpeakerAnonymously = (error) => ({
     type: ACTIONS.UPDATE_SPEAKER_ANONYMOUSLY_FAILURE,
     error,
});

export const requestSpeakerDetails = (payload) => ({
     type: ACTIONS.SPEAKER_DETAILS_REQUEST,
     payload,
});

export const successSpeakerDetails = (payload) => ({
     type: ACTIONS.SPEAKER_DETAILS_SUCCESS,
     payload,
});

export const failSpeakerDetails = (error) => ({
     type: ACTIONS.SPEAKER_DETAILS_FAILURE,
     error,
});

export const requestUpdateSpeakerDetails = (payload) => ({
     type: ACTIONS.UPDATE_SPEAKER_DETAILS_REQUEST,
     payload,
});

export const successUpdateSpeakerDetails = (payload) => ({
     type: ACTIONS.UPDATE_SPEAKER_DETAILS_SUCCESS,
     payload,
});

export const failUpdateSpeakerDetails = (error) => ({
     type: ACTIONS.UPDATE_SPEAKER_DETAILS_FAILURE,
     error,
});

export const requestResendSpeakerInvitation = (payload) => ({
     type: ACTIONS.RESEND_SPEAKER_INVITATION_REQUEST,
     payload,
});

export const successResendSpeakerInvitation = (payload) => ({
     type: ACTIONS.RESEND_SPEAKER_INVITATION_SUCCESS,
     payload,
});

export const failResendSpeakerInvitation = (error) => ({
     type: ACTIONS.RESEND_SPEAKER_INVITATION_FAILURE,
     error,
});

export const requestFieldsSpeakerView = (payload) => ({
     type: ACTIONS.FIELDS_SPEAKER_VIEW_REQUEST,
     payload,
});

export const successFieldsSpeakerView = (payload) => ({
     type: ACTIONS.FIELDS_SPEAKER_VIEW_SUCCESS,
     payload,
});

export const failFieldsSpeakerView = (error) => ({
     type: ACTIONS.FIELDS_SPEAKER_VIEW_FAILURE,
     error,
});

export const requestFilterSpeakersView = (payload) => ({
     type: ACTIONS.FILTER_SPEAKERS_VIEW_REQUEST,
     payload,
});

export const successFilterSpeakersView = (payload) => ({
     type: ACTIONS.FILTER_SPEAKERS_VIEW_SUCCESS,
     payload,
});

export const failFilterSpeakersView = (error) => ({
     type: ACTIONS.FILTER_SPEAKERS_VIEW_FAILURE,
     error,
});

export const requestExportSpeakers = (payload) => ({
     type: ACTIONS.EXPORT_SPEAKERS_REQUEST,
     payload,
});

export const successExportSpeakers = (payload) => ({
     type: ACTIONS.EXPORT_SPEAKERS_SUCCESS,
     payload,
});

export const failExportSpeakers = (error) => ({
     type: ACTIONS.EXPORT_SPEAKERS_FAILURE,
     error,
});

export const requestArchiveSpeakers = (payload) => ({
     type: ACTIONS.ARCHIVE_SPEAKERS_REQUEST,
     payload,
});

export const successArchiveSpeakers = (payload) => ({
     type: ACTIONS.ARCHIVE_SPEAKERS_SUCCESS,
     payload,
});

export const failArchiveSpeakers = (error) => ({
     type: ACTIONS.ARCHIVE_SPEAKERS_FAILURE,
     error,
});

export const requestArchiveDownloadSpeakers = (payload) => ({
     type: ACTIONS.ARCHIVE_DOWNLOAD_SPEAKERS_REQUEST,
     payload,
});

export const successArchiveDownloadSpeakers = (payload) => ({
     type: ACTIONS.ARCHIVE_DOWNLOAD_SPEAKERS_SUCCESS,
     payload,
});

export const failArchiveDownloadSpeakers = (error) => ({
     type: ACTIONS.ARCHIVE_DOWNLOAD_SPEAKERS_FAILURE,
     error,
});

export const requestDownloadMediaFile = (payload) => ({
     type: ACTIONS.DOWNLOAD_MEDIA_FILE_REQUEST,
     payload,
});

export const successDownloadMediaFile = (payload) => ({
     type: ACTIONS.DOWNLOAD_MEDIA_FILE_SUCCESS,
     payload,
});

export const failDownloadMediaFile = (error) => ({
     type: ACTIONS.DOWNLOAD_MEDIA_FILE_FAILURE,
     error,
});

export const requestSearchSpeakersFilteredWithView = (payload) => ({
     type: ACTIONS.SEARCH_SPEAKERS_FILTERED_WITH_VIEW_REQUEST,
     payload,
});

export const successSearchSpeakersFilteredWithView = (payload) => ({
     type: ACTIONS.SEARCH_SPEAKERS_FILTERED_WITH_VIEW_SUCCESS,
     payload,
});

export const failSearchSpeakersFilteredWithView = (error) => ({
     type: ACTIONS.SEARCH_SPEAKERS_FILTERED_WITH_VIEW_FAILURE,
     error,
});

export const requestDeleteSpeakerFromEvent = (payload) => ({
     type: ACTIONS.DELETE_SPEAKER_FROM_EVENT_REQUEST,
     payload,
});

export const successDeleteSpeakerFromEvent = (payload) => ({
     type: ACTIONS.DELETE_SPEAKER_FROM_EVENT_SUCCESS,
     payload,
});

export const failDeleteSpeakerFromEvent = (error) => ({
     type: ACTIONS.DELETE_SPEAKER_FROM_EVENT_FAILURE,
     error,
});

export const requestBulkDeleteSpeakerFromEvent = (payload) => ({
     type: ACTIONS.BULK_DELETE_SPEAKER_FROM_EVENT_REQUEST,
     payload,
});

export const successBulkDeleteSpeakerFromEvent = (payload) => ({
     type: ACTIONS.BULK_DELETE_SPEAKER_FROM_EVENT_SUCCESS,
     payload,
});

export const failBulkDeleteSpeakerFromEvent = (error) => ({
     type: ACTIONS.BULK_DELETE_SPEAKER_FROM_EVENT_FAILURE,
     error,
});

export const requestSpeakerPhotoUpload = (payload) => ({
     type: ACTIONS.UPLOAD_SPEAKER_PHOTO_REQUEST,
     payload,
});

export const successSpeakerPhotoUpload = (payload) => ({
     type: ACTIONS.UPLOAD_SPEAKER_PHOTO_SUCCESS,
     payload,
});

export const failSpeakerPhotoUpload = (error) => ({
     type: ACTIONS.UPLOAD_SPEAKER_PHOTO_FAILURE,
     error,
});

export const requestSpeakerPhotoRemove = (payload) => ({
     type: ACTIONS.SPEAKER_PHOTO_REMOVE_REQUEST,
     payload,
});

export const successSpeakerPhotoRemove = (payload) => ({
     type: ACTIONS.SPEAKER_PHOTO_REMOVE_SUCCESS,
     payload,
});

export const failSpeakerPhotoRemove = (error) => ({
     type: ACTIONS.SPEAKER_PHOTO_REMOVE_FAILURE,
     error,
});

export const requestSpeakerFileUpload = (payload) => ({
     type: ACTIONS.UPLOAD_SPEAKER_FILE_REQUEST,
     payload,
});

export const successSpeakerFileUpload = (payload) => ({
     type: ACTIONS.UPLOAD_SPEAKER_FILE_SUCCESS,
     payload,
});

export const failSpeakerFileUpload = (error) => ({
     type: ACTIONS.UPLOAD_SPEAKER_FILE_FAILURE,
     error,
});

export const requestSpeakerPhoto = (payload) => ({
     type: ACTIONS.SPEAKER_PHOTO_REQUEST,
     payload,
});

export const successSpeakerPhoto = (payload) => ({
     type: ACTIONS.SPEAKER_PHOTO_SUCCESS,
     payload,
});

export const failSpeakerPhoto = (error) => ({
     type: ACTIONS.SPEAKER_PHOTO_FAILURE,
     error,
});

export const requestSpeakerFile = (payload) => ({
     type: ACTIONS.SPEAKER_FILE_REQUEST,
     payload,
});

export const successSpeakerFile = (payload) => ({
     type: ACTIONS.SPEAKER_FILE_SUCCESS,
     payload,
});

export const failSpeakerFile = (error) => ({
     type: ACTIONS.SPEAKER_FILE_FAILURE,
     error,
});
export const requestSpeakerFileRemove = (payload) => ({
     type: ACTIONS.SPEAKER_FILE_REMOVE_REQUEST,
     payload,
});

export const successSpeakerFileRemove = (payload) => ({
     type: ACTIONS.SPEAKER_FILE_REMOVE_SUCCESS,
     payload,
});

export const failSpeakerFileRemove = (error) => ({
     type: ACTIONS.SPEAKER_FILE_REMOVE_FAILURE,
     error,
});

export const speakerGeneratedData = (payload) => ({
     type: ACTIONS.SPEAKER_GENERATED_DATA,
     payload
});

export const INITIAL_STATE = {
     speakerInviteRequested: false,
     speakerInviteSucceeded: false,
     speakerInviteFailed: false,
     invitationDetailsRequested: false,
     invitationDetailsSucceeded: false,
     invitationDetailsFailed: false,
     invitationDetailsBasicRequested: false,
     invitationDetailsBasicSucceeded: false,
     invitationDetailsBasicFailed: false,
     connectSpeakerRequested: false,
     connectSpeakerSucceeded: false,
     connectSpeakerFailed: false,
     speakerEventsRequested: false,
     speakerEventsSucceeded: false,
     speakerEventsFailed: false,
     eventSpeakersRequested: false,
     eventSpeakersSucceeded: false,
     eventSpeakersFailed: false,
     updateSpeakerAnonymouslyRequested: false,
     updateSpeakerAnonymouslySucceeded: false,
     updateSpeakerAnonymouslyFailed: false,
     speakerDetailsRequested: false,
     speakerDetailsSucceeded: false,
     speakerDetailsFailed: false,
     updateSpeakerDetailsRequested: false,
     updateSpeakerDetailsSucceeded: false,
     updateSpeakerDetailsFailed: false,
     resendSpeakerInvitationRequested: false,
     resendSpeakerInvitationSucceeded: false,
     resendSpeakerInvitationFailed: false,
     fieldsSpeakerViewRequested: false,
     fieldsSpeakerViewSucceeded: false,
     fieldsSpeakerViewFailed: false,
     filterSpeakersViewRequested: false,
     filterSpeakersViewSucceeded: false,
     filterSpeakersViewFailed: false,
     exportSpeakersRequested: false,
     exportSpeakersSucceeded: false,
     exportSpeakersFailed: false,
     archiveSpeakersRequested: false,
     archiveSpeakersSucceeded: false,
     archiveSpeakersFailed: false,
     archiveDownloadSpeakersRequested: false,
     archiveDownloadSpeakersSucceeded: false,
     archiveDownloadSpeakersFailed: false,
     downloadMediaFileRequested: false,
     downloadMediaFileSucceeded: false,
     downloadMediaFileFailed: false,
     searchSpeakersRequested: false,
     searchSpeakersSucceeded: false,
     searchSpeakersFailed: false,
     searchSpeakersFilteredWithViewRequested: false,
     searchSpeakersFilteredWithViewSucceeded: false,
     searchSpeakersFilteredWithViewFailed: false,
     deleteSpeakerFromEventRequested: false,
     deleteSpeakerFromEventSucceeded: false,
     deleteSpeakerFromEventFailed: false,
     bulkDeleteSpeakerFromEventRequested: false,
     bulkDeleteSpeakerFromEventSucceeded: false,
     bulkDeleteSpeakerFromEventFailed: false,
     speakerPhotoRequested: false,
     speakerPhotoSucceeded: false,
     speakerPhotoFailed: false,
     speakerPhotoRemoveRequested: false,
     speakerPhotoRemoveSucceeded: false,
     speakerPhotoRemoveFailed: false,
     speakerFilesRequested: false,
     speakerFilesSucceeded: false,
     speakerFilesFailed: false,

     invitationDetailsBasicData: null,
     invitationDetailsData: null,
     speakerConnected: null,
     speakerEventsData: null,
     speakersList: null,
     speakersPageDetails: null,
     speakerDetailsData: null,
     fieldsList: null,
     defaultFieldsList: [],
     textFieldsList: [],
     mediaFieldsList: [],
     groupedFieldsList: null,
     sharedFieldsList: null,
     uniqueFieldsList: null,
     filteredData: null,
     exportedData: null,
     speakersSearchResult: null,
     speakerPhoto: null,
     speakerFiles: null,
     message: null,
     error: null,
};

export default function speakerReducer(state = INITIAL_STATE, action) {
     switch (action.type) {
          case ACTIONS.SPEAKERS_CLEAR_MESSAGES:
               return {
                    ...state,
                    message: null,
                    error: null,
               };
          case ACTIONS.SPEAKER_INVITE_REQUEST:
               return {
                    ...state,
                    speakerInviteRequested: true,
                    speakerInviteSucceeded: false,
                    speakerInviteFailed: false,
                    message: null,
                    error: null,
               };
          case ACTIONS.SPEAKER_INVITE_SUCCESS:
               return {
                    ...state,
                    speakerInviteRequested: false,
                    speakerInviteSucceeded: true,
                    speakerInviteFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.SPEAKER_INVITE_FAILURE:
               return {
                    ...state,
                    speakerInviteRequested: false,
                    speakerInviteSucceeded: false,
                    speakerInviteFailed: true,
                    error: action.error,
                    message: null,
               };
          case ACTIONS.INVITATION_DETAILS_REQUEST:
               return {
                    ...state,
                    invitationDetailsRequested: true,
                    invitationDetailsSucceeded: false,
                    invitationDetailsFailed: false,
               };
          case ACTIONS.INVITATION_DETAILS_SUCCESS:
               return {
                    ...state,
                    invitationDetailsRequested: false,
                    invitationDetailsSucceeded: true,
                    invitationDetailsFailed: false,
                    invitationDetailsData: action.payload,
                    error: null,
               };
          case ACTIONS.INVITATION_DETAILS_FAILURE:
               return {
                    ...state,
                    invitationDetailsRequested: false,
                    invitationDetailsSucceeded: false,
                    invitationDetailsFailed: true,
                    error: action.error,
                    invitationDetailsData: null,
               };
          case ACTIONS.INVITATION_DETAILS_BASIC_REQUEST:
               return {
                    ...state,
                    invitationDetailsBasicRequested: true,
                    invitationDetailsBasicSucceeded: false,
                    invitationDetailsBasicFailed: false,
               };
          case ACTIONS.INVITATION_DETAILS_BASIC_SUCCESS:
               return {
                    ...state,
                    invitationDetailsBasicRequested: false,
                    invitationDetailsBasicSucceeded: true,
                    invitationDetailsBasicFailed: false,
                    invitationDetailsBasicData: action.payload,
                    error: null,
               };
          case ACTIONS.INVITATION_DETAILS_BASIC_FAILURE:
               return {
                    ...state,
                    invitationDetailsBasicRequested: false,
                    invitationDetailsSBasicucceeded: false,
                    invitationDetailsBasicFailed: true,
                    error: action.error,
                    invitationDetailsBasicData: null,
               };
          case ACTIONS.CONNECT_SPEAKER_REQUEST:
               return {
                    ...state,
                    connectSpeakerRequested: true,
                    connectSpeakerSucceeded: false,
                    connectSpeakerFailed: false,
               };
          case ACTIONS.CONNECT_SPEAKER_SUCCESS:
               return {
                    ...state,
                    connectSpeakerRequested: false,
                    connectSpeakerSucceeded: true,
                    connectSpeakerFailed: false,
                    speakerConnected: action.payload,
                    error: null,
               };
          case ACTIONS.CONNECT_SPEAKER_FAILURE:
               return {
                    ...state,
                    connectSpeakerRequested: false,
                    connectSpeakerSucceeded: false,
                    connectSpeakerFailed: true,
                    error: action.error,
                    speakerConnected: null,
               };
          case ACTIONS.SPEAKER_EVENTS_REQUEST:
               return {
                    ...state,
                    speakerEventsRequested: true,
                    speakerEventsSucceeded: false,
                    speakerEventsFailed: false,
               };
          case ACTIONS.SPEAKER_EVENTS_SUCCESS:
               return {
                    ...state,
                    speakerEventsRequested: false,
                    speakerEventsSucceeded: true,
                    speakerEventsFailed: false,
                    speakerEventsData: action.payload,
                    error: null,
               };
          case ACTIONS.SPEAKER_EVENTS_FAILURE:
               return {
                    ...state,
                    speakerEventsRequested: false,
                    speakerEventsSucceeded: false,
                    speakerEventsFailed: true,
                    error: action.error,
                    speakerEventsData: null,
               };
          case ACTIONS.EVENT_SPEAKERS_REQUEST:
               return {
                    ...state,
                    eventSpeakersRequested: true,
                    eventSpeakersSucceeded: false,
                    eventSpeakersFailed: false,
                    error: null,
               };
          case ACTIONS.EVENT_SPEAKERS_SUCCESS:
               return {
                    ...state,
                    eventSpeakersRequested: false,
                    eventSpeakersSucceeded: true,
                    eventSpeakersFailed: false,
                    speakersList: action.payload,
                    error: null,
               };
          case ACTIONS.EVENT_SPEAKERS_FAILURE:
               return {
                    ...state,
                    eventSpeakersRequested: false,
                    eventSpeakersSucceeded: false,
                    eventSpeakersFailed: true,
                    error: action.error,
                    speakersList: [],
               };
          case ACTIONS.GET_SPEAKERS_PAGE_DETAILS:
               return {
                    ...state,
                    speakersPageDetails: action.payload,
               };
          case ACTIONS.UPDATE_SPEAKER_ANONYMOUSLY_REQUEST:
               return {
                    ...state,
                    updateSpeakerAnonymouslyRequested: true,
                    updateSpeakerAnonymouslySucceeded: false,
                    updateSpeakerAnonymouslyFailed: false,
                    message: null,
                    error: null,
               };
          case ACTIONS.UPDATE_SPEAKER_ANONYMOUSLY_SUCCESS:
               return {
                    ...state,
                    updateSpeakerAnonymouslyRequested: false,
                    updateSpeakerAnonymouslySucceeded: true,
                    updateSpeakerAnonymouslyFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.UPDATE_SPEAKER_ANONYMOUSLY_FAILURE:
               return {
                    ...state,
                    updateSpeakerAnonymouslyRequested: false,
                    updateSpeakerAnonymouslySucceeded: false,
                    updateSpeakerAnonymouslyFailed: true,
                    error: action.error,
                    message: null,
               };
          case ACTIONS.SPEAKER_DETAILS_REQUEST:
               return {
                    ...state,
                    speakerDetailsRequested: true,
                    speakerDetailsSucceeded: false,
                    speakerDetailsFailed: false,
               };
          case ACTIONS.SPEAKER_DETAILS_SUCCESS:
               return {
                    ...state,
                    speakerDetailsRequested: false,
                    speakerDetailsSucceeded: true,
                    speakerDetailsFailed: false,
                    speakerDetailsData: action.payload,
                    error: null,
               };
          case ACTIONS.SPEAKER_DETAILS_FAILURE:
               return {
                    ...state,
                    speakerDetailsRequested: false,
                    speakerDetailsSucceeded: false,
                    speakerDetailsFailed: true,
                    error: action.error,
                    speakerDetailsData: null,
               };
          case ACTIONS.UPDATE_SPEAKER_DETAILS_REQUEST:
               return {
                    ...state,
                    updateSpeakerDetailsRequested: true,
                    updateSpeakerDetailsSucceeded: false,
                    updateSpeakerDetailsFailed: false,
                    message: null,
                    error: null,
               };
          case ACTIONS.UPDATE_SPEAKER_DETAILS_SUCCESS:
               return {
                    ...state,
                    updateSpeakerDetailsRequested: false,
                    updateSpeakerDetailsSucceeded: true,
                    updateSpeakerDetailsFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.UPDATE_SPEAKER_DETAILS_FAILURE:
               return {
                    ...state,
                    updateSpeakerDetailsRequested: false,
                    updateSpeakerDetailsSucceeded: false,
                    updateSpeakerDetailsFailed: true,
                    error: action.error,
                    message: null,
               };
          case ACTIONS.RESEND_SPEAKER_INVITATION_REQUEST:
               return {
                    ...state,
                    resendSpeakerInvitationRequested: true,
                    resendSpeakerInvitationSucceeded: false,
                    resendSpeakerInvitationFailed: false,
                    message: null,
                    error: null,
               };
          case ACTIONS.RESEND_SPEAKER_INVITATION_SUCCESS:
               return {
                    ...state,
                    resendSpeakerInvitationRequested: false,
                    resendSpeakerInvitationSucceeded: true,
                    resendSpeakerInvitationFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.RESEND_SPEAKER_INVITATION_FAILURE:
               return {
                    ...state,
                    resendSpeakerInvitationRequested: false,
                    resendSpeakerInvitationSucceeded: false,
                    resendSpeakerInvitationFailed: true,
                    error: action.error,
                    message: null,
               };
          case ACTIONS.FIELDS_SPEAKER_VIEW_REQUEST:
               return {
                    ...state,
                    fieldsSpeakerViewRequested: true,
                    fieldsSpeakerViewSucceeded: false,
                    fieldsSpeakerViewFailed: false,
               };
          case ACTIONS.FIELDS_SPEAKER_VIEW_SUCCESS:
               return {
                    ...state,
                    fieldsSpeakerViewRequested: false,
                    fieldsSpeakerViewSucceeded: true,
                    fieldsSpeakerViewFailed: false,
                    fieldsList: action.payload.all,
                    defaultFieldsList: action.payload.default,
                    textFieldsList: action.payload.text,
                    mediaFieldsList: action.payload.media,
                    groupedFieldsList: action.payload.grouped,
                    sharedFieldsList: action.payload.shared,
                    uniqueFieldsList: action.payload.unique,
                    error: null,
               };
          case ACTIONS.FIELDS_SPEAKER_VIEW_FAILURE:
               return {
                    ...state,
                    fieldsSpeakerViewRequested: false,
                    fieldsSpeakerViewSucceeded: false,
                    fieldsSpeakerViewFailed: true,
                    error: action.error,
                    fieldsList: null,
                    defaultFieldsList: [],
                    textFieldsList: [],
                    mediaFieldsList: [],
                    groupedFieldsList: null,
                    sharedFieldsList: null,
                    uniqueFieldsList: null
               };
          case ACTIONS.FILTER_SPEAKERS_VIEW_REQUEST:
               return {
                    ...state,
                    filterSpeakersViewRequested: true,
                    filterSpeakersViewSucceeded: false,
                    filterSpeakersViewFailed: false,
                    filteredData: null,
                    error: null,
               };
          case ACTIONS.FILTER_SPEAKERS_VIEW_SUCCESS:
               return {
                    ...state,
                    filterSpeakersViewRequested: false,
                    filterSpeakersViewSucceeded: true,
                    filterSpeakersViewFailed: false,
                    filteredData: action.payload,
                    error: null,
               };
          case ACTIONS.FILTER_SPEAKERS_VIEW_FAILURE:
               return {
                    ...state,
                    filterSpeakersViewRequested: false,
                    filterSpeakersViewSucceeded: false,
                    filterSpeakersViewFailed: true,
                    filteredData: null,
                    error: action.error,
               };
          case ACTIONS.EXPORT_SPEAKERS_REQUEST:
               return {
                    ...state,
                    exportSpeakersRequested: true,
                    exportSpeakersSucceeded: false,
                    exportSpeakersFailed: false,
                    exportedData: null,
                    error: null,
               };
          case ACTIONS.EXPORT_SPEAKERS_SUCCESS:
               return {
                    ...state,
                    exportSpeakersRequested: false,
                    exportSpeakersSucceeded: true,
                    exportSpeakersFailed: false,
                    exportedData: action.payload,
                    error: null,
               };
          case ACTIONS.EXPORT_SPEAKERS_FAILURE:
               return {
                    ...state,
                    exportSpeakersRequested: false,
                    exportSpeakersSucceeded: false,
                    exportSpeakersFailed: true,
                    error: action.error,
                    exportedData: null,
               };
          case ACTIONS.ARCHIVE_SPEAKERS_REQUEST:
               return {
                    ...state,
                    archiveSpeakersRequested: true,
                    archiveSpeakersSucceeded: false,
                    archiveSpeakersFailed: false,
                    error: null,
               };
          case ACTIONS.ARCHIVE_SPEAKERS_SUCCESS:
               return {
                    ...state,
                    archiveSpeakersRequested: false,
                    archiveSpeakersSucceeded: true,
                    archiveSpeakersFailed: false,
                    error: null,
               };
          case ACTIONS.ARCHIVE_SPEAKERS_FAILURE:
               return {
                    ...state,
                    archiveSpeakersRequested: false,
                    archiveSpeakersSucceeded: false,
                    archiveSpeakersFailed: true,
                    error: action.error,
               };
          case ACTIONS.ARCHIVE_DOWNLOAD_SPEAKERS_REQUEST:
               return {
                    ...state,
                    archiveDownloadSpeakersRequested: true,
                    archiveDownloadSpeakersSucceeded: false,
                    archiveDownloadSpeakersFailed: false,
                    error: null,
               };
          case ACTIONS.ARCHIVE_DOWNLOAD_SPEAKERS_SUCCESS:
               return {
                    ...state,
                    archiveDownloadSpeakersRequested: false,
                    archiveDownloadSpeakersSucceeded: true,
                    archiveDownloadSpeakersFailed: false,
                    error: null,
               };
          case ACTIONS.ARCHIVE_DOWNLOAD_SPEAKERS_FAILURE:
               return {
                    ...state,
                    archiveDownloadSpeakersRequested: false,
                    archiveDownloadSpeakersSucceeded: false,
                    archiveDownloadSpeakersFailed: true,
                    error: action.error,
               };
          case ACTIONS.DOWNLOAD_MEDIA_FILE_REQUEST:
               return {
                    ...state,
                    downloadMediaFileRequested: true,
                    downloadMediaFileSucceeded: false,
                    downloadMediaFileFailed: false,
                    exportedData: null,
                    error: null,
               };
          case ACTIONS.DOWNLOAD_MEDIA_FILE_SUCCESS:
               return {
                    ...state,
                    downloadMediaFileRequested: false,
                    downloadMediaFileSucceeded: true,
                    downloadMediaFileFailed: false,
                    exportedData: action.payload,
                    error: null,
               };
          case ACTIONS.DOWNLOAD_MEDIA_FILE_FAILURE:
               return {
                    ...state,
                    downloadMediaFileRequested: false,
                    downloadMediaFileSucceeded: false,
                    downloadMediaFileFailed: true,
                    error: action.error,
                    exportedData: null,
               };
          case ACTIONS.SEARCH_SPEAKERS_FILTERED_WITH_VIEW_REQUEST:
               return {
                    ...state,
                    searchSpeakersFilteredWithViewRequested: true,
                    searchSpeakersFilteredWithViewSucceeded: false,
                    searchSpeakersFilteredWithViewFailed: false,
                    speakersSearchResult: null,
                    error: null,
               };
          case ACTIONS.SEARCH_SPEAKERS_FILTERED_WITH_VIEW_SUCCESS:
               return {
                    ...state,
                    searchSpeakersFilteredWithViewRequested: false,
                    searchSpeakersFilteredWithViewSucceeded: true,
                    searchSpeakersFilteredWithViewFailed: false,
                    speakersSearchResult: action.payload,
                    error: null,
               };
          case ACTIONS.SEARCH_SPEAKERS_FILTERED_WITH_VIEW_FAILURE:
               return {
                    ...state,
                    searchSpeakersFilteredWithViewRequested: false,
                    searchSpeakersFilteredWithViewSucceeded: false,
                    searchSpeakersFilteredWithViewFailed: true,
                    error: action.error,
                    speakersSearchResult: null,
               };
          case ACTIONS.DELETE_SPEAKER_FROM_EVENT_REQUEST:
               return {
                    ...state,
                    deleteSpeakerFromEventRequested: true,
                    deleteSpeakerFromEventSucceeded: false,
                    deleteSpeakerFromEventFailed: false,
               };
          case ACTIONS.DELETE_SPEAKER_FROM_EVENT_SUCCESS:
               return {
                    ...state,
                    deleteSpeakerFromEventRequested: false,
                    deleteSpeakerFromEventSucceeded: true,
                    deleteSpeakerFromEventFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.DELETE_SPEAKER_FROM_EVENT_FAILURE:
               return {
                    ...state,
                    deleteSpeakerFromEventRequested: false,
                    deleteSpeakerFromEventSucceeded: false,
                    deleteSpeakerFromEventFailed: true,
                    error: action.error,
                    message: null,
               };
          case ACTIONS.BULK_DELETE_SPEAKER_FROM_EVENT_REQUEST:
               return {
                    ...state,
                    bulkDeleteSpeakerFromEventRequested: true,
                    bulkDeleteSpeakerFromEventSucceeded: false,
                    bulkDeleteSpeakerFromEventFailed: false,
               };
          case ACTIONS.BULK_DELETE_SPEAKER_FROM_EVENT_SUCCESS:
               return {
                    ...state,
                    bulkDeleteSpeakerFromEventRequested: false,
                    bulkDeleteSpeakerFromEventSucceeded: true,
                    bulkDeleteSpeakerFromEventFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.BULK_DELETE_SPEAKER_FROM_EVENT_FAILURE:
               return {
                    ...state,
                    bulkDeleteSpeakerFromEventRequested: false,
                    bulkDeleteSpeakerFromEventSucceeded: false,
                    bulkDeleteSpeakerFromEventFailed: true,
                    error: action.error,
                    message: null,
               };
          case ACTIONS.UPLOAD_SPEAKER_PHOTO_REQUEST:
               return {
                    ...state,
                    uploadSpeakerPhotoRequested: true,
                    uploadSpeakerPhotoSucceeded: false,
                    uploadSpeakerPhotoFailed: false,
               };
          case ACTIONS.UPLOAD_SPEAKER_PHOTO_SUCCESS:
               return {
                    ...state,
                    uploadSpeakerPhotoRequested: false,
                    uploadSpeakerPhotoSucceeded: true,
                    uploadSpeakerPhotoFailed: false,
                    uploadSpeakerPhoto: action.payload,
                    error: null,
               };
          case ACTIONS.UPLOAD_SPEAKER_PHOTO_FAILURE:
               return {
                    ...state,
                    uploadSpeakerPhotoRequested: false,
                    uploadSpeakerPhotoSucceeded: false,
                    uploadSpeakerPhotoFailed: true,
                    error: action.error,
                    speakerPhoto: null,
               };
          case ACTIONS.UPLOAD_SPEAKER_FILE_REQUEST:
               return {
                    ...state,
                    uploadSpeakerFileRequested: true,
                    uploadSpeakerFileSucceeded: false,
                    uploadSpeakerFileFailed: false,
               };
          case ACTIONS.UPLOAD_SPEAKER_FILE_SUCCESS:
               return {
                    ...state,
                    uploadSpeakerFileRequested: false,
                    uploadSpeakerFileSucceeded: true,
                    uploadSpeakerFileFailed: false,
                    uploadSpeakerFile: action.payload,
                    error: null,
               };
          case ACTIONS.UPLOAD_SPEAKER_FILE_FAILURE:
               return {
                    ...state,
                    uploadSpeakerFileRequested: false,
                    uploadSpeakerFileSucceeded: false,
                    uploadSpeakerFileFailed: true,
                    error: action.error,
                    speakerPhoto: null,
               };
          case ACTIONS.SPEAKER_PHOTO_REQUEST:
               return {
                    ...state,
                    speakerPhotoRequested: true,
                    speakerPhotoSucceeded: false,
                    speakerPhotoFailed: false,
               };
          case ACTIONS.SPEAKER_PHOTO_SUCCESS:
               return {
                    ...state,
                    speakerPhotoRequested: false,
                    speakerPhotoSucceeded: true,
                    speakerPhotoFailed: false,
                    speakerPhoto: action.payload,
                    error: null,
               };
          case ACTIONS.SPEAKER_PHOTO_FAILURE:
               return {
                    ...state,
                    speakerPhotoRequested: false,
                    speakerPhotoSucceeded: false,
                    speakerPhotoFailed: true,
                    error: action.error,
                    speakerPhoto: null,
               };
          case ACTIONS.SPEAKER_PHOTO_REMOVE_REQUEST:
               return {
                    ...state,
                    speakerPhotoRemoveRequested: true,
                    speakerPhotoRemoveSucceeded: false,
                    speakerPhotoRemoveFailed: false,
               };
          case ACTIONS.SPEAKER_PHOTO_REMOVE_SUCCESS:
               return {
                    ...state,
                    speakerPhotoRemoveRequested: false,
                    speakerPhotoRemoveSucceeded: true,
                    speakerPhotoRemoveFailed: false,
                    speakerPhoto: action.payload,
                    error: null,
               };
          case ACTIONS.SPEAKER_PHOTO_REMOVE_FAILURE:
               return {
                    ...state,
                    speakerPhotoRemoveRequested: false,
                    speakerPhotoRemoveSucceeded: false,
                    speakerPhotoRemoveFailed: true,
                    error: action.error,
                    speakerPhoto: null,
               };
          case ACTIONS.SPEAKER_FILE_REMOVE_REQUEST:
               return {
                    ...state,
                    speakerFileRemoveRequested: true,
                    speakerFileRemoveSucceeded: false,
                    speakerFileRemoveFailed: false,
               };
          case ACTIONS.SPEAKER_FILE_REMOVE_SUCCESS:
               return {
                    ...state,
                    speakerFileRemoveRequested: false,
                    speakerFileRemoveSucceeded: true,
                    speakerFileRemoveFailed: false,
                    speakerFile: action.payload,
                    error: null,
               };
          case ACTIONS.SPEAKER_FILE_REMOVE_FAILURE:
               return {
                    ...state,
                    speakerFileRemoveRequested: false,
                    speakerFileRemoveSucceeded: false,
                    speakerFileRemoveFailed: true,
                    error: action.error,
                    speakerFile: null,
               };
          case ACTIONS.SPEAKER_FILE_REQUEST:
               return {
                    ...state,
                    speakerFilesRequested: true,
                    speakerFilesSucceeded: false,
                    speakerFilesFailed: false,
               };
          case ACTIONS.SPEAKER_FILE_SUCCESS:
               return {
                    ...state,
                    speakerFilesRequested: false,
                    speakerFilesSucceeded: true,
                    speakerFilesFailed: false,
                    speakerFiles: action.payload,
                    error: null,
               };
          case ACTIONS.SPEAKER_FILE_FAILURE:
               return {
                    ...state,
                    speakerFilesRequested: false,
                    speakerFilesSucceeded: false,
                    speakerFilesFailed: true,
                    error: action.error,
                    speakerFiles: null,
               };
          case ACTIONS.SPEAKER_GENERATED_DATA:
               return {
                    ...state,
                    speakersList: state.speakersList && state.speakersList.length > 0 ? state.speakersList.map((speaker) => {
                         if (speaker.id === action.payload.speaker_id) {
                              speaker.generated = action.payload.generated;
                         }

                         return speaker;
                    }) : []
               };
          default:
               return state;
     }
}
