import React, { memo, useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {isEmpty} from "underscore";

export const ParagraphSettings = memo(
     ({ selectedElement, handleSettingChange, handleRemoveElement, getMasterValue }) => {
          const { t } = useTranslation();

          const [state, setState] = useState();
          const ref = useRef();

          useEffect(() => {
               setState(selectedElement);
          }, [selectedElement]);

          const handleInputChange = ({ target: { value } }) => {
               handleSettingChange({
                    id: selectedElement.id,
                    name: 'text',
                    value,
               });
          };

          const handleRemoveItself = () => {
               setState(null);
               handleRemoveElement(selectedElement.id);
          };

          return (
               <>
                    <label htmlFor="paragraph">{t('form_builder.value')}</label>
                    <textarea
                         ref={ref}
                         id="paragraph"
                         className={`form-control ${state && ((!state.is_valid && isEmpty(state.value)) || (!state.is_translated && state.missing_translations.includes('value'))) ? 'is-warning' : ''}`}
                         rows="5"
                         type="text"
                         value={state && state.text}
                         placeholder={state && getMasterValue(state.internal_id, 'text', null, t(
                             'form_builder.text_placeholder'
                         ))}
                         onChange={handleInputChange}
                    />

                   {state && state.is_master && (
                       <>
                           <hr className="setting-divider" />
                           <Button
                               variant="danger"
                               onClick={handleRemoveItself}
                           >
                               {t('form_builder.delete_element')}
                           </Button>
                       </>
                   )}
               </>
          );
     }
);
