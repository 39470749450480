import React, {useEffect, useRef, useState} from 'react';
import { connect } from 'react-redux';
import {errorsRefresh} from "../../../redux/reducers/errorsReducer";
import {requestOrganizationCreation} from "../../../redux/reducers/organizationReducer";
import {ORGANIZATION} from "../../../constants/form.constants";
import {FormWrapper} from "../../auth/auth-styles/RightBlock.style";
import {Button, Form, Dropdown} from "react-bootstrap";
import {Formik} from "formik";
import {useTranslation} from "react-i18next";
import {Input} from "../../common/input/Input";
import Spinner from "react-bootstrap/Spinner";
import CustomToggle from '../../common/custom-toggle';
import ChevronDownIcon from '../../../assets/icons/metronic/chevron-down-icon';

const mapStateToProps = (state) => ({
     organizationCreationRequested: state.organizationReducer.organizationCreationRequested,
     router: state.router,
     user: state.userReducer
});

const mapDispatchToProps = (dispatch) => ({
     requestOrganizationCreation: (values) => dispatch(requestOrganizationCreation(values)),
     refreshErrors: () => dispatch(errorsRefresh())
});

const _CreateUserOrganizationForm = ({
     requestOrganizationCreation,
      organizationCreationRequested,
      languages,
      tierId
     }) => {
     const organizationForm = useRef();
     const { t } = useTranslation();

     const [innerState, setInnerState] = useState({
          languageOptions: [],
          currentLanguage: {
               id: null,
               name: t('organizations.language_placeholder'),
               code: null
          }
     });

     useEffect(() => {
          if (languages) {
               setInnerState({
                    ...innerState,
                    languageOptions: languages,
                    currentLanguage: {
                         id: null,
                         name: t('organizations.language_placeholder'),
                         code: null
                    }
               });
          }
     }, [languages]);

     const handleCreation = (values) => {
          const parameters = {
               name: values.name,
               language_id: values.language_id,
               tier_id: tierId
          };

          requestOrganizationCreation(parameters);
     };

     return (
         <Formik
             innerRef={organizationForm}
             validationSchema={ORGANIZATION.VALIDATION}
             onSubmit={handleCreation}
             initialValues={ORGANIZATION.INITIAL_VALUES}
             validateOnBlur={false}
         >
              {({
                     handleSubmit,
                     handleChange,
                     setFieldValue,
                     values,
                     touched,
                     errors,
                }) => (
                  <FormWrapper>
                       <h1>{t('organizations.create_organization')}</h1>
                       <Form noValidate onSubmit={(e) => { e.preventDefault(); handleSubmit(e); }}>
                            <Form.Group controlId="name">
                                 <Input
                                     type="text"
                                     field="name"
                                     values={values}
                                     placeholder={t('organizations.name_placeholder')}
                                     handleChange={handleChange}
                                     touched={touched}
                                     errors={errors}
                                     disabled={organizationCreationRequested}
                                 />
                            </Form.Group>
                            <Form.Group controlId="language_id">
                                   <div className="input-group zi10">
                                             <Dropdown
                                                  style={{ width: '100%' }}
                                                  className={errors['language_id'] ? 'is-invalid' : ''}
                                             >
                                                  <Dropdown.Toggle
                                                       className={'select-dropdown-toggle form-control'}
                                                       as={CustomToggle}
                                                       disabled={organizationCreationRequested}
                                                  >
                                                       {
                                                            innerState.currentLanguage.name
                                                       }
                                                  </Dropdown.Toggle>
                                                  <Dropdown.Menu
                                                       style={{ width: '100%' }}
                                                  >
                                                       {innerState.languageOptions.map(
                                                            (lang) => (
                                                                 <Dropdown.Item
                                                                      key={
                                                                           lang.id
                                                                      }
                                                                      onClick={() => {
                                                                           setInnerState(
                                                                                {
                                                                                     ...innerState,
                                                                                     currentLanguage: lang,
                                                                                }
                                                                           )
                                                                           setFieldValue('language_id', lang.id);
                                                                      }}
                                                                 >
                                                                      {lang.name}
                                                                 </Dropdown.Item>
                                                            )
                                                       )}
                                                  </Dropdown.Menu>
                                             </Dropdown>
                                             <Form.Control.Feedback
                                                  type="invalid"
                                                  style={{ marginTop: '2px', display: 'block' }}
                                             >
                                                  {touched['language_id'] && errors['language_id']}
                                             </Form.Control.Feedback> 
                                             <span className="suffix">
                                                  <ChevronDownIcon />
                                             </span>
                                        </div>
                            </Form.Group>
                            
                            <hr className="divider" />
                            <div
                                className="form-actions"
                                style={{ justifyContent: 'flex-end' }}
                            >
                                 <Button
                                     type="submit"
                                     disabled={organizationCreationRequested}
                                 >
                                      {t('sign_up.buttons.create')}
                                      {organizationCreationRequested && (<Spinner animation="border" className="m-0 ml-2 p-0" size="sm" />)}
                                 </Button>
                            </div>
                       </Form>
                  </FormWrapper>
              )}
         </Formik>
     );
};

export const CreateUserOrganizationForm = connect(mapStateToProps, mapDispatchToProps)(_CreateUserOrganizationForm);
