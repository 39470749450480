import moment from 'moment';

export const refreshAvatars = (photos) => {
     const keys = Object.keys(photos);
     const time = moment();

     keys.forEach((key, index) => {
          photos[key] = photos[key] + '?t=' + time.unix();
     });

     return photos;
};
