import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';
import {
     FORM_PLACEHOLDER,
     COLORS,
     FONT,
     SIZE,
} from '../../../constants/style.constants';

export const StyledCustomDatePicker = styled.div`
     .react-datepicker__triangle {
          display: none;
     }
     .react-datepicker-wrapper {
          background: transparent;
          min-width: 100%;
          z-index: 1;
          .react-datepicker__input-container {
               background-color: transparent;
               z-index: 1;
               input {
                    background-color: transparent;
                    z-index: 1;
               }
               input::placeholder {
                    ${FORM_PLACEHOLDER};
                    color: ${COLORS.PLACEHOLDER_GREY} !important;
               }
          }
     }
     .form-control,
     .react-datepicker-ignore-onclickoutside {
          background: transparent;
     }

     .react-datepicker-popper {
          margin-top: 0;
     }

     .react-datepicker {
          min-width: 260px;
          border: 0 !important;
          margin: 0;
          box-shadow: 0px 0px -20px 0px rgba(82, 63, 105, 0.15);
     }
     .react-datepicker__month-container {
          padding: 10px;
          border: 0 !important;
          border-radius: 4px;
          margin: 0;
          box-shadow: 0px 0px 20px 0px rgba(82, 63, 105, 0.15);
          min-width: 100%;
          z-index: 2;

          .react-datepicker__month {
               margin: 0;
          }
     }

     .react-datepicker__header {
          background-color: transparent;
          border: none;
          vertical-align: middle;
     }

     .header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          button {
               border: none;
               background-color: transparent;
               font-weight: ${FONT.MEDIUM};
               color: ${COLORS.PLACEHOLDER_GREY};
               width: 30px;
               &:focus {
                    outline: none;
               }
               i {
                    height: 17px;
                    width: 17px;
                    font-size: ${SIZE.BIG};
               }
          }
     }

     .react-datepicker__current-month {
          font-size: ${SIZE.NORMAL};
          font-weight: ${FONT.MEDIUM};
          color: ${COLORS.PLACEHOLDER_GREY};
          cursor: pointer;
     }
     .react-datepicker__day-names {
          font-size: ${SIZE.NORMAL};
          text-align: center;
          color: ${COLORS.DARK_BLUE};
          font-weight: ${FONT.MEDIUM};

          .react-datepicker__day-name {
               margin-bottom: 0;
          }
     }

     .react-datepicker__day-name,
     .react-datepicker__day {
          width: 30px;
          height: 30px;
          padding: 1px;
     }

     .react-datepicker__day {
          border-radius: 4px;
          font-size: ${SIZE.NORMAL};
          color: ${COLORS.DARK_BLUE};

          &:focus {
               outline: none;
          }
          &:hover {
               background: ${COLORS.BLUISH_WHITE};
               color: ${COLORS.DARK_BLUE};
          }
          &:active {
               background: ${COLORS.BLUISH};
               color: ${COLORS.WHITE};
          }
     }

     .react-datepicker__day--disabled {
          color: ${COLORS.PLACEHOLDER_GREY};
     }

     .react-datepicker__day--today {
          position: relative;
          background: rgba(14, 108, 247, 0.7) !important;
          color: ${COLORS.WHITE} !important;
          &:before {
               content: '';
               display: inline-block;
               border: solid transparent;
               border-width: 0 0 7px 7px;
               border-bottom-color: ${COLORS.WHITE};
               border-top-color: ${COLORS.BLUISH_WHITE};
               position: absolute;
               bottom: 4px;
               right: 4px;
          }
     }

     .react-datepicker__day--selected {
          background: ${COLORS.BLUISH};
          color: ${COLORS.WHITE};
     }

     .react-datepicker__day--keyboard-selected {
          background-color: transparent;
     }

     .react-datepicker__navigation {
          &:focus {
               outline: none;
          }
     }
`;
