import React, {lazy, memo, useCallback, useEffect, useRef, useState,} from 'react';
import {Link, useParams} from 'react-router-dom';
import i18n from 'i18next';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {replace} from 'connected-react-router';
import {Helmet} from 'react-helmet';
import Breadcrumb from '../../common/breadcrumb';
import SweetAlert from '../../common/sweet-alert';
import LoadingBar from '../../common/loading-bar';
import {ListItem} from '../../common/collapsible-list/CollapsibleList';
import {SpeakersTable} from './sub-components/SpeakersTable';
import {EventDropdown} from '../Event/sub-components/EventDropdown';
import {OrganizationDropdown} from '../Organization/sub-components/OrganizationDropdown';
import {SpeakersEmpty} from './sub-components/SpeakersEmpty';
import { sortSpeakerDefaultFields } from '../../../utils/array.utils';
import {SettingsImage} from '../../../assets/icons/metronic/settings-image';
import SpeakersIcon from '../../../assets/icons/metronic/speakers-icon';
import {GroupChatImage} from '../../../assets/icons/metronic/group-chat-image';
import {DotImage} from '../../../assets/icons/metronic/dot-image';
import {requestOrganizationDetails} from '../../../redux/reducers/organizationReducer';
import {requestEventDetails} from '../../../redux/reducers/eventReducer';
import {
     requestArchiveDownloadSpeakers,
     requestBulkDeleteSpeakerFromEvent,
     requestDeleteSpeakerFromEvent,
     requestDownloadMediaFile,
     requestEventSpeakers,
     requestFieldsSpeakerView,
     requestResendSpeakerInvitation
} from '../../../redux/reducers/speakerReducer';

import SpeakersWrapper from './Speakers.style';
import {getLocale} from "../../../services/instance";
import {ReminderIcon} from "../../../assets/icons/metronic/reminders-icon";

const MainDrawer = lazy(() => import('../../common/drawer'));
const CollapsibleList = lazy(() => import('../../common/collapsible-list'));

const mapStateToProps = (state) => ({
     router: state.router,
     organization: state.organizationReducer.organization,
     eventData: state.eventReducer.eventData,
     speakersList: state.speakerReducer.speakersList,
     speakersPageDetails: state.speakerReducer.speakersPageDetails,
     fieldsList: state.speakerReducer.fieldsList,
     defaultFieldsList: state.speakerReducer.defaultFieldsList,
     updating: state.speakerReducer.filterSpeakersViewRequested,
     filterSucceeded: state.speakerReducer.filterSpeakersViewSucceeded,
     exportSucceeded: state.speakerReducer.exportSpeakersSucceeded,
     eventSpeakersRequested: state.speakerReducer.eventSpeakersRequested,
     failed: state.speakerReducer.filterSpeakersViewFailed,
     removeSucceeded: state.speakerReducer.deleteSpeakerFromEventSucceeded,
     speakersSearchResult: state.speakerReducer.speakersSearchResult,
     pageDetails: state.speakerReducer.speakersPageDetails,
     languages: state.userReducer.languages
});

const mapDispatchToProps = (dispatch) => ({
     requestOrganizationDetails: (id) => dispatch(requestOrganizationDetails(id)),
     requestEventDetails: (payload) => dispatch(requestEventDetails(payload)),
     requestEventSpeakers: (payload) => dispatch(requestEventSpeakers(payload)),
     redirectToSpeakersEmpty: (location) => dispatch(replace(location)),
     requestFieldsSpeakerView: (eventId) => dispatch(requestFieldsSpeakerView(eventId)),
     requestDeleteSpeakerFromEvent: (payload) => dispatch(requestDeleteSpeakerFromEvent(payload)),
     requestBulkDeleteSpeakerFromEvent: (payload) => dispatch(requestBulkDeleteSpeakerFromEvent(payload)),
     requestDownloadMediaFile: (payload) => dispatch(requestDownloadMediaFile(payload)),
     requestArchiveDownloadSpeakers: (payload) => dispatch(requestArchiveDownloadSpeakers(payload)),
     requestResendSpeakerInvitation: (payload) => dispatch(requestResendSpeakerInvitation(payload))
});

export const _Speakers = memo((props) => {
     const {
          router: {
               location: { pathname },
          },
          requestOrganizationDetails,
          requestEventDetails,
          organization,
          eventData,
          speakersList,
          speakersPageDetails,
          requestEventSpeakers,
          redirectToSpeakersEmpty,
          requestDeleteSpeakerFromEvent,
          requestFieldsSpeakerView,
          fieldsList,
          defaultFieldsList,
          removeSucceeded,
          filterSucceeded,
          exportSucceeded,
          pageDetails,
          languages,
          requestDownloadMediaFile,
          requestArchiveDownloadSpeakers,
          requestResendSpeakerInvitation,
          requestBulkDeleteSpeakerFromEvent,
          eventSpeakersRequested
     } = props;

     const { t } = useTranslation();
     const { id: organizationId, eventId, token } = useParams();
     const currentLocale = getLocale();

     const getPersistedSettings = JSON.parse(localStorage.getItem(`view_settings.${currentLocale}.${eventId}`));
     const getPersistedSorting = JSON.parse(localStorage.getItem(`sorting.${currentLocale}.${eventId}`));
     const persistedPerPage = JSON.parse(localStorage.getItem(`page_size.speakers.${eventId}`));
     const defaultSorting = getPersistedSorting ? getPersistedSorting : {
          column: 'id',
          direction: 'desc',
          is_activated: false
     };

     const [state, setState] = useState({ data: [], columns: [] });
     const [options, setOptions] = useState([]);
     const [showDefaultValues, setShowDefaultValues] = useState(true);
     const [selectedSpeakersState, setSelectedSpeakersState] = useState([]);
     const [allSelectedState, setAllSelectedState] = useState([]);
     const [queryString, setQueryString] = useState('');
     const [sorting, setSorting] = useState(defaultSorting);

     const settings = useRef();
     const order = useRef();
     const stateRef = useRef();
     const allSelected = useRef();
     const selectedSpeakers = useRef();

     useEffect(() => {
          if (token && eventId) {
               requestArchiveDownloadSpeakers({ token, eventId, organizationId });
          }
     }, [token, eventId]);

     useEffect(() => {
          if (getPersistedSettings || filterSucceeded || exportSucceeded) {
               setShowDefaultValues(false);
          }
     }, [filterSucceeded, exportSucceeded, getPersistedSettings]);

     const transformOptions = (data) => {
          return data.map((o) => {
               const persisted = getPersistedSettings && getPersistedSettings.find(f => f.value === o.value);
               const isAlreadySelected = persisted && persisted.selected;
               if (defaultFieldsList.includes(o.value) || isAlreadySelected) {
                    return {
                         ...o,
                         selected: true,
                    };
               }

               return {
                    ...o,
                    selected: false,
               };
          });
     };

     const mapData = (data) => data.map((o) => ({ value: o }));

     useEffect(() => {
          requestFieldsSpeakerView({organizationId, eventId});
     }, [currentLocale]);

     useEffect(() => {
          if (getPersistedSettings) {
               setOptions(getPersistedSettings);
               settings.current = getPersistedSettings;
          }

          if (fieldsList) {
               const sorted = sortSpeakerDefaultFields(mapData(fieldsList));
               const transformed = transformOptions(sorted);

               setOptions([...transformed]);
               settings.current = [...transformed];

               // Persist settings on client side
               localStorage.setItem(
                    `view_settings.${currentLocale}.${eventId}`,
                    JSON.stringify(transformed, null, 2)
               );
          }
     }, [fieldsList, currentLocale]);

     useEffect(() => {
          requestOrganizationDetails(organizationId);
          requestEventDetails({ organizationId, eventId });
     }, [organizationId, eventId, pathname]);

     useEffect(() => {
          requestEventSpeakers({
               eventId,
               page: pageDetails ? pageDetails.page : 1,
               per_page: pageDetails ? pageDetails.per_page : (persistedPerPage ? persistedPerPage : 50),
               query: queryString,
               sort: sorting.column,
               direction: sorting.direction
          });
     }, [removeSucceeded, currentLocale]);

     const handlePageSizeChange = (pageSize) => {
          localStorage.setItem(
              `page_size.speakers.${eventId}`,
              JSON.stringify(pageSize, null, 2)
          );

          requestEventSpeakers({
               eventId,
               per_page: pageSize,
               query: queryString,
               sort: sorting.column,
               direction: sorting.direction
          });
     };


     const handlePageChange = ({ page, pageSize }) =>
          requestEventSpeakers({
               eventId,
               per_page: pageSize,
               page,
               query: queryString,
               sort: sorting.column,
               direction: sorting.direction
          });

     const handleSort = (field, direction) => {
          setSorting({
               ...sorting,
               is_activated: true,
               column: field,
               direction
          });
     };

     const handleRefreshSpeakers = () => {
          requestEventSpeakers({
               eventId,
               per_page: pageDetails.per_page,
               query: queryString,
               sort: sorting.column,
               direction: sorting.direction
          });
     };

     useEffect(() => {
          let data = [];
          let cols = [];

          if (speakersList !== null && getPersistedSettings && !showDefaultValues) {
               data = speakersList && speakersList.length > 0 ? speakersList.map((s) => ({
                    ...s.data,
                    id: s.id,
                    status: s.status,
                    fields: s.fields_input[0],
                    media: s.media,
                    generated: s.generated,
                    user: s.user
               })) : [];

               const keys = speakersList && speakersList.length > 0 ? [...Object.keys(speakersList[0].data)] : [];
               cols = keys.map((item) => ({
                    Header: String(item),
                    accessor: String(item),
               }));

               if (settings.current) {
                    settings.current = getPersistedSettings;
                    const formattedSettings = settings.current.filter(
                         ({ id }) => id !== 1
                    );

                    cols = formattedSettings
                         .filter(({ selected }) => !!selected)
                         .map((item) => ({
                              Header: String(item.value),
                              accessor: String(item.value),
                         }));
               }

               settings.current && setOptions(settings.current);

               stateRef.current = {
                    data,
                    columns: cols,
               };

               return setState({
                    data,
                    columns: cols,
               });
          }
     }, [speakersList, showDefaultValues, currentLocale]);

     const handleSpeakersAction = useCallback(
          ({ id, type }) => {
               if (speakersList) {
                    if (type === 'remove') {
                         SweetAlert({
                              title: i18n.t('sweet_alerts.attention'),
                              text: i18n.t('sweet_alerts.remove_speaker'),
                              confirmButtonText: i18n.t('buttons.remove'),
                              cancelButtonText: i18n.t('buttons.cancel'),
                              showCancelButton: true,
                              callback: () => {
                                   requestDeleteSpeakerFromEvent({eventId,id});
                              },
                         });
                    } else if (type === 'resend') {
                         requestResendSpeakerInvitation({id, eventId});
                    }
               }
          },
          [speakersList]
     );

     const handleBulkRemove = () => {
          SweetAlert({
               title: i18n.t('sweet_alerts.attention'),
               text: i18n.t('sweet_alerts.remove_speakers'),
               confirmButtonText: i18n.t('buttons.remove'),
               cancelButtonText: i18n.t('buttons.cancel'),
               showCancelButton: true,
               callback: () => {
                    requestBulkDeleteSpeakerFromEvent({ eventId, ids: selectedSpeakers.current });
                    selectedSpeakers.current = [];
                    setSelectedSpeakersState([]);
                    requestEventSpeakers({
                         eventId,
                         per_page: pageDetails.per_page,
                         page: pageDetails.current_page,
                         query: queryString,
                         sort: sorting.column,
                         direction: sorting.direction
                    });
               }
          });
     };

     const links = [
          {
               to: `/organization/${organizationId}/settings`,
               text: `${organization && organization.name}`,
          },
          {
               to: `/organization/${organizationId}/events`,
               text: i18n.t('events'),
          },
          {
               to: `/organization/${organizationId}/events/${eventId}/speakers`,
               text: `${eventData && eventData.name}`,
          },
          {
               to: `/organization/${organizationId}/events/${eventId}/speakers`,
               text: i18n.t('event.speakers'),
          },
     ];

     useEffect(() => {
          if (queryString.length > 0) {
               requestEventSpeakers({
                    eventId,
                    per_page: pageDetails.per_page,
                    page: 1,
                    query: queryString
               });
          }
     }, [queryString]);

     useEffect(() => {
          if (sorting.is_activated && pageDetails) {
               requestEventSpeakers({
                    eventId,
                    per_page: pageDetails.per_page,
                    page: pageDetails.current_page,
                    query: queryString,
                    sort: sorting.column,
                    direction: sorting.direction
               });

               localStorage.setItem(
                   `sorting.${currentLocale}.${eventId}`,
                   JSON.stringify(sorting, null, 2)
               );
          }
     }, [sorting]);

     const handleSearch = ({ target: { value } }) => {
          setQueryString(value);
          if (value.length === 0) {
               requestEventSpeakers({
                    eventId,
                    per_page: pageDetails.per_page,
                    page: 1
               });
          }
     };

     const { data, columns } = state;

     const handleSaveSettings = useCallback(
          (config) => {
               settings.current = config;
          },
          [settings]
     );

     const handleSaveColumnOrder = useCallback(
          (newOrder) => {
               order.current = newOrder;
          },
          [order]
     );

     const handleDownloadMediaFile = (file, name) => {
          requestDownloadMediaFile({file, name});
     };

     const handleSelectAll = useCallback(
         (page) => {
              const currentSelected = allSelected.current ? allSelected.current : [];
              const speakers = stateRef.current ? stateRef.current.data : data;
              const currentSpeakers = selectedSpeakers.current ? selectedSpeakers.current : [];

              if (speakers.length > 0) {

                   let forUpdate = currentSpeakers;
                   let forUpdateCurrentSelected = [];
                   if (currentSelected.includes(page)) {
                        forUpdateCurrentSelected = currentSelected.filter((pageId) => pageId !== page);

                        speakers.forEach((speaker) => {
                             forUpdate = forUpdate.filter((id) => id !== speaker.id);
                        });

                   } else {
                        forUpdateCurrentSelected = [...currentSelected, page];

                        speakers.forEach((speaker) => {
                             if (!forUpdate.includes(speaker.id)) {
                                  forUpdate = [...forUpdate, speaker.id];
                             }
                        });
                   }

                   allSelected.current = forUpdateCurrentSelected;
                   selectedSpeakers.current = forUpdate;
                   setSelectedSpeakersState(forUpdate);
                   setAllSelectedState(forUpdateCurrentSelected);
              }
         },
         [allSelected]
     );

     const checkSelectAll = (page) => {
          const currentSelected = allSelected.current ? allSelected.current : [];

          if (!currentSelected.includes(page)) {
               const forUpdateCurrentSelected = [...currentSelected, page];

               allSelected.current = forUpdateCurrentSelected;
               setAllSelectedState(forUpdateCurrentSelected);
          }
     };

     const uncheckAll = (page) => {
          const currentSelected = allSelected.current ? allSelected.current : [];

          if (currentSelected.includes(page)) {
               const forUpdateCurrentSelected = currentSelected.filter((pageId) => pageId !== page);

               allSelected.current = forUpdateCurrentSelected;
               setAllSelectedState(forUpdateCurrentSelected);
          }
     };

     const handleSelectSpeaker = useCallback((speakerId, page) => {
          const currentSpeakers = selectedSpeakers.current ? selectedSpeakers.current : [];

          let forUpdate = [];
          if (currentSpeakers.includes(speakerId)) {
               forUpdate = currentSpeakers.filter((id) => id !== speakerId);
          } else {
               forUpdate = [...currentSpeakers, speakerId];
          }

          areAllSpeakersSelected(page, forUpdate);

          selectedSpeakers.current = forUpdate;
          setSelectedSpeakersState(forUpdate);
     }, [selectedSpeakers]);

     const areAllSpeakersSelected = (page, currentSelectedSpeakers) => {
          const speakers = stateRef.current ? stateRef.current.data : data;
          const totalSpeakersNumber = speakers.length;

          let count = 0;
          speakers.forEach((speaker) => {
               if (currentSelectedSpeakers.includes(speaker.id)) {
                    count++;
               }
          });

          if (count < totalSpeakersNumber) {
               uncheckAll(page);
          }

          if (count === totalSpeakersNumber) {
               checkSelectAll(page);
          }
     };

     const isSpeakerSelected = (id) => {
          const selectedIds = selectedSpeakers.current ? selectedSpeakers.current : [];

          return selectedIds.includes(id);
     };

     const isAllSelected = (page) => {
          const currentSelected = allSelected.current ? allSelected.current : [];

          return currentSelected.includes(page);
     };

     const handlePreselectSpeaker = (id) => {
          selectedSpeakers.current = [id];
          setSelectedSpeakersState([id]);
     };

     const tableProps = {
          data: stateRef.current ? stateRef.current.data : data,
          columns: stateRef.current ? stateRef.current.columns : columns,
          sorting,
          settings,
          handleSaveSettings,
          order,
          handleSaveColumnOrder,
          redirectToSpeakersEmpty,
          handleSpeakersAction,
          handleSearch,
          queryString,
          speakersPageDetails,
          handlePageSizeChange,
          handlePageChange,
          handleSort,
          pageDetails,
          isAllSelected,
          allSelected: allSelectedState,
          handleSelectAll,
          selectedSpeakers: selectedSpeakersState,
          handleSelectSpeaker,
          isSpeakerSelected,
          handlePreselectSpeaker,
          languages,
          handleRefreshSpeakers,
          handleDownloadMediaFile,
          handleBulkRemove
     };

     if (speakersList && !eventSpeakersRequested && speakersList.length === 0 && queryString.length === 0) {
          return <SpeakersEmpty />;
     }

     return (speakersList && eventData && eventData.id === parseInt(eventId) &&
          <SpeakersWrapper>
               <Helmet>
                    <title>
                         {t('event.speakers')} | {t('avovent')}
                    </title>
               </Helmet>
               <MainDrawer>
                    <ListItem to="/dashboard">
                         {t('breadcrumb.dashboard')}
                    </ListItem>
                    <CollapsibleList
                         listHeader={organization && organization.name}
                         listContent={
                              <>
                                   <ListItem
                                        to={`/organization/${organizationId}/events/${eventId}/event-edit`}
                                   >
                                        <div className="collapsible-list-item">
                                             <GroupChatImage />
                                             <div className="sub-item">
                                                  {t('events')}
                                             </div>
                                        </div>
                                   </ListItem>
                                   <CollapsibleList
                                        listHeader={
                                             <div className="collapsible-list-item">
                                                  <SettingsImage />
                                                  <div className="sub-item">
                                                       {t(
                                                            'organization_settings.organization_settings'
                                                       )}
                                                  </div>
                                             </div>
                                        }
                                        listContent={
                                             <>
                                                  <ListItem
                                                       subItem
                                                       to={`/organization/${organizationId}/settings`}
                                                  >
                                                       <div className="collapsible-list-item">
                                                            <DotImage />
                                                            <div className="sub-item">
                                                                 {t(
                                                                      'organization_settings.organization_settings'
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </ListItem>
                                                  <ListItem
                                                       subItem
                                                       to={`/organization/${organizationId}/members`}
                                                  >
                                                       <div className="collapsible-list-item">
                                                            <DotImage />
                                                            <div className="sub-item">
                                                                 {t(
                                                                      'organization_settings.team_members'
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </ListItem>
                                                  <ListItem
                                                       subItem
                                                       to={`/organization/${organizationId}/email-senders`}
                                                  >
                                                       <div className="collapsible-list-item">
                                                            <DotImage />
                                                            <div className="sub-item">
                                                                 {t(
                                                                      'organization_settings.email_senders'
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </ListItem>
                                             </>
                                        }
                                   />
                              </>
                         }
                    />
                    <CollapsibleList
                         listHeader={eventData && eventData.name}
                         expanded={
                              window.location.pathname ===
                              `/organization/${organizationId}/events/${eventId}/speakers`
                         }
                         listContent={
                              <>
                                   <ListItem
                                        to={`/organization/${organizationId}/events/${eventId}/speakers`}
                                   >
                                        <div className="collapsible-list-item">
                                             <SpeakersIcon />
                                             <div className="sub-item">
                                                  {t('event.speakers')}
                                             </div>
                                        </div>
                                   </ListItem>
                                   <ListItem
                                       to={`/organization/${organizationId}/events/${eventId}/speakers`}
                                   >
                                        <div className="collapsible-list-item">
                                             <ReminderIcon />
                                             <div className="sub-item">
                                                  {t('reminders.reminders')}
                                             </div>
                                        </div>
                                   </ListItem>
                                   <ListItem
                                        to={`/organization/${organizationId}/events/${eventId}/event-edit`}
                                   >
                                        <div className="collapsible-list-item">
                                             <SettingsImage />
                                             <div className="sub-item">
                                                  {t('event.event_settings')}
                                             </div>
                                        </div>
                                   </ListItem>
                              </>
                         }
                    />
               </MainDrawer>
               <div className="speakers">
                    <div className="content--border">
                         <div
                              className="container-standard"
                              style={{ display: 'flex', alignItems: 'center' }}
                         >
                              <div className="nav">
                                   <Link to="/dashboard" className="inactive">
                                        {t('breadcrumb.dashboard')}
                                   </Link>
                                   <OrganizationDropdown organization={organization} inactive/>
                                   <EventDropdown />
                              </div>
                         </div>
                         <LoadingBar
                              finish={
                                   !!organization &&
                                   !!eventData &&
                                   !!speakersList
                              }
                         />
                    </div>
                    <div className="container-standard">
                         {organization && eventData && (
                              <Breadcrumb
                                   title={t('event.speakers')}
                                   links={links}
                              />
                         )}
                    </div>
                    <div className="container-standard">
                          <SpeakersTable {...tableProps} />
                    </div>
               </div>
          </SpeakersWrapper>
     );
});

export const Speakers = connect(mapStateToProps, mapDispatchToProps)(_Speakers);
