import React, { memo, useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {getLocale} from "../../../../services/instance";
import {COLORS} from "../../../../constants/style.constants";
import i18n from "i18next";

const mapStateToProps = (state) => ({
     sharedFieldsList: state.speakerReducer.sharedFieldsList,
     uniqueFieldsList: state.speakerReducer.uniqueFieldsList,
     updating: state.speakerReducer.filterSpeakersViewRequested,
     succeded: state.speakerReducer.filterSpeakersViewSucceeded,
     failed: state.speakerReducer.filterSpeakersViewFailed,
});

export const _ViewSettingsModal = memo(
     ({
          show,
          settings,
          handleSaveSettings,
          handleClose,
          updating,
          succeded,
          failed,
          sharedFieldsList,
          uniqueFieldsList
     }) => {
          const { t } = useTranslation();
          const { eventId } = useParams();
          const currentLocale = getLocale();

          const [modalOptions, setModalOptions] = useState([]);
          const [allSelectedShared, setAllSelectedShared] = useState(false);
          const [allSelected, setAllSelected] = useState({});
          const [selectedEverything, setSelectedEverything] = useState(false);
          const checkboxRef = useRef();

          const blockedFields = [
              i18n.t('speakers.default_fields.status', { lng: currentLocale }),
               i18n.t('speakers.default_fields.email', { lng: currentLocale })
          ];

          useEffect(() => {
               // Disable scrolling on main page
               document.body.style.overflow = show ? 'hidden' : 'unset';
          }, [show]);

          useEffect(() => {
               if (settings) {
                    setModalOptions(settings);
               }
          }, [settings]);

          const handleSubmit = (e) => {
               e.preventDefault();

               // Persist settings on client side
               localStorage.setItem(
                   `view_settings.${currentLocale}.${eventId}`,
                    JSON.stringify(modalOptions, null, 2)
               );

               handleClose();
          };

          const isSelected = code => {
            const found =  modalOptions.find(option => option.value === code);

            return !!(found && found.selected);
          };

          const handleCheckboxClick = (code, form) => {
               if (blockedFields.includes(code)) {

                    return false;
               }

               const updated = modalOptions.map(option =>
                   ({
                        ...option,
                        selected: option.value === code ? !option.selected : option.selected
                   })
               );

               let updateSharedAll = false;
               let updateFormAll = false;

               if (form) {
                    const filteredSharedFields = updated.filter(option => uniqueFieldsList[form].includes(option.value) && option.selected);
                    updateFormAll = filteredSharedFields.length === uniqueFieldsList[form].length;
               } else {
                    const filteredSharedFields = updated.filter(option => sharedFieldsList.includes(option.value) && option.selected);
                    updateSharedAll = filteredSharedFields.length === sharedFieldsList.length;
               }

               if (form) {
                    setAllSelected({...allSelected, [form]: updateFormAll});
               } else {
                    setAllSelectedShared(updateSharedAll);
               }

               setModalOptions(updated);
               handleSaveSettings(updated);
          };

          const handleSelectAll = (form) => {
               const isAllSelected = allSelected[form] ? allSelected[form] : false;
               const fields = uniqueFieldsList[form] ? uniqueFieldsList[form] : [];

               const updated = modalOptions.map(option => {

                    if (fields.includes(option.value)) {
                         return {
                              ...option,
                              selected: !isAllSelected
                         };
                    } else {

                         return option;
                    }
               });

               setAllSelected({...allSelected, [form]: !isAllSelected});
               setModalOptions(updated);
               handleSaveSettings(updated);
          };

          const handleSelectAllShared = () => {
               const isAllSelected = allSelectedShared ? allSelectedShared : false;
               const fields = sharedFieldsList;

               const updated = modalOptions.map(option => {

                    if (fields.includes(option.value) && !blockedFields.includes(option.value)) {
                         return {
                              ...option,
                              selected: !isAllSelected
                         };
                    } else {

                         return option;
                    }
               });

               setAllSelectedShared(!isAllSelected);
               setModalOptions(updated);
               handleSaveSettings(updated);
          };

          const handleSelectEverything = () => {
               const isAllSelected = selectedEverything ? selectedEverything : false;

               const updated = modalOptions.map(option =>
                   ({
                        ...option,
                        selected: !isAllSelected
                   })
               );

               let uniqueForms = {};
               Object.keys(uniqueFieldsList).forEach(name => { uniqueForms[name] = !isAllSelected; });

               setAllSelectedShared(!isAllSelected);
               setAllSelected(uniqueForms);
               setModalOptions(updated);
               handleSaveSettings(updated);
               setSelectedEverything(!isAllSelected);
          };

          return (
               <Modal
                    show={show}
                    onHide={handleClose}
                    dialogClassName="selection-modal"
                    container={document.getElementById('root')}
               >
                    <Modal.Header>
                         <Modal.Title>
                              {t('speakers.view_settings')}
                         </Modal.Title>
                         <button className="close" type="button">
                              <i
                                   className="las la-times"
                                   onClick={handleClose}
                              />
                         </button>
                    </Modal.Header>
                    <Form noValidate onSubmit={handleSubmit}>
                         <Modal.Body>
                              <Row
                                  sm={12}
                              >
                                   <p style={{ display: 'block', width: '100%' }}>{t('common.shared_elements')}</p>
                                   <Col
                                       key={`shared-select-all`}
                                       md="auto"
                                       sm={3}
                                   >
                                        <Form.Group
                                            key={'select-all'}
                                            ref={checkboxRef}
                                        >
                                             <div
                                                 className="checkbox-solid"
                                                 onClick={() => handleSelectAllShared()}
                                             >
                                                  <input
                                                      type="checkbox"
                                                      checked={allSelectedShared}
                                                      onChange={() => handleSelectAllShared()}
                                                  />
                                                  <span />
                                                  <Form.Label
                                                      style={{
                                                           textTransform: 'capitalize',
                                                      }}
                                                  >
                                                       {
                                                            t('common.all')
                                                       }
                                                  </Form.Label>
                                             </div>
                                        </Form.Group>
                                   </Col>
                                   {sharedFieldsList && sharedFieldsList.map(field => {
                                        return !blockedFields.includes(field) && (
                                            <Col
                                                key={`shared-${field}`}
                                                md="auto"
                                                sm={3}
                                            >
                                                 <Form.Group
                                                     key={field}
                                                     ref={
                                                          checkboxRef
                                                     }
                                                 >
                                                      <div
                                                          className="checkbox-solid"
                                                          onClick={() =>
                                                              handleCheckboxClick(field)
                                                          }
                                                      >
                                                           <input
                                                               type="checkbox"
                                                               checked={isSelected(field)}
                                                               onChange={() =>
                                                                   handleCheckboxClick(field)
                                                               }
                                                           />
                                                           <span />
                                                           <Form.Label
                                                               style={{textTransform: 'capitalize'}}
                                                           >
                                                                {field}
                                                           </Form.Label>
                                                      </div>
                                                 </Form.Group>
                                            </Col>
                                        )
                                   })}
                              </Row>

                              {uniqueFieldsList && Object.keys(uniqueFieldsList).map(name => (
                                  <Row sm={12}
                                       style={{
                                            borderTop: `1px dashed ${COLORS.LIGHTER_BLUE}`,
                                            marginTop: '20px',
                                            paddingTop: '20px'
                                       }}>
                                       <p style={{ display: 'block', width: '100%' }}>{name}</p>
                                       <Col
                                           key={`${name}-select-all`}
                                           md="auto"
                                           sm={3}
                                       >
                                            <Form.Group
                                                key={'select-all'}
                                                ref={checkboxRef}
                                            >
                                                 <div
                                                     className="checkbox-solid"
                                                     onClick={() => handleSelectAll(name)}
                                                 >
                                                      <input
                                                          type="checkbox"
                                                          checked={allSelected[name]}
                                                          onChange={() => handleSelectAll(name)}
                                                      />
                                                      <span />
                                                      <Form.Label
                                                          style={{
                                                               textTransform:
                                                                   'capitalize',
                                                          }}
                                                      >
                                                           {
                                                                t('all')
                                                           }
                                                      </Form.Label>
                                                 </div>
                                            </Form.Group>
                                       </Col>
                                       {uniqueFieldsList[name].length &&
                                                 uniqueFieldsList[name].map(
                                                      field => {
                                                           return (
                                                                <Col
                                                                     key={`${name}-${field}`}
                                                                     md="auto"
                                                                     sm={3}
                                                                >
                                                                     <Form.Group
                                                                          key={field}
                                                                          ref={
                                                                               checkboxRef
                                                                          }
                                                                     >
                                                                          <div
                                                                               className="checkbox-solid"
                                                                               onClick={() =>
                                                                                    handleCheckboxClick(field, name)
                                                                               }
                                                                          >
                                                                               <input
                                                                                    type="checkbox"
                                                                                    checked={isSelected(field)}
                                                                                    onChange={() =>
                                                                                         handleCheckboxClick(field, name)
                                                                                    }
                                                                               />
                                                                               <span />
                                                                               <Form.Label
                                                                                    style={{
                                                                                         textTransform:
                                                                                              'capitalize',
                                                                                    }}
                                                                               >
                                                                                    {
                                                                                         field
                                                                                    }
                                                                               </Form.Label>
                                                                          </div>
                                                                     </Form.Group>
                                                                </Col>
                                                           );
                                                      }
                                                 )}
                                  </Row>
                              ))}
                         </Modal.Body>
                         <Modal.Footer>
                              <Button
                                  variant="secondary"
                                  style={{ justifySelf: 'flex-start', marginRight: 'auto' }}
                                  onClick={() => { handleSelectEverything() }}
                              >
                                   {t('buttons.select_all')}
                              </Button>
                              <Button variant="secondary" onClick={handleClose}>
                                   {t('buttons.close')}
                              </Button>
                              <Button
                                   variant="primary"
                                   type="submit"
                                   disabled={updating && !(succeded || failed)}
                              >
                                   {t('buttons.save')}
                              </Button>
                         </Modal.Footer>
                    </Form>
               </Modal>
          );
     }
);

export const ViewSettingsModal = connect(mapStateToProps)(_ViewSettingsModal);
