import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import authReducer from './authReducer';
import userReducer from './userReducer';
import organizationReducer from './organizationReducer';
import eventReducer from './eventReducer';
import formBuilderReducer from './formBuilderReducer';
import speakerReducer from './speakerReducer';
import reminderReducer from './reminderReducer';
import emailTemplatesReducer from './emailTemplatesReducer';
import uiReducer from './uiReducer';
import errorsReducer from './errorsReducer';

const rootReducer = (history) =>
     combineReducers({
          router: connectRouter(history),
          authReducer,
          userReducer,
          organizationReducer,
          eventReducer,
          formBuilderReducer,
          speakerReducer,
          reminderReducer,
          emailTemplatesReducer,
          ui: uiReducer,
          errorsReducer
     });

export default rootReducer;
