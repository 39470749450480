import i18n from 'i18next';
import toastr from 'toastr';
import {call, put, all, takeEvery} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
     successCreateForm,
     failCreateForm,
     successEventForms,
     failEventForms,
     getEventFormsPageDetails,
     successFormsSearch,
     failFormsSearch,
     successRemoveForm,
     failRemoveForm,
     successFormFields,
     failFormFields,
     successFormDetails,
     failFormDetails,
     successUpdateForm,
     failUpdateForm, successFormBasicDetails, failFormBasicDetails, successSaveCustomForm, failSaveCustomForm,
} from '../reducers/formBuilderReducer';
import * as ACTIONS from '../actions';
import * as Service from '../../services';

export function* createFormSaga({ payload }) {
     const { redirect, organizationId, eventId, ...rest } = payload;
     const response = yield call(Service.createForm, { eventId, organizationId, ...rest });

     if (response.status === 200 || response.status === 201) {
          if (redirect) {
               toastr.success(i18n.t('alerts.success.form_saved'));
               yield put(successCreateForm(response.data.data));

               return yield put(
                    push(
                         `/organization/${organizationId}/events/${eventId}/invite-speakers/step-2`
                    )
               );
          }

          toastr.success(i18n.t('alerts.success.form_saved'));
          return yield put(successCreateForm(response.data.data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     response.errors && toastr.error(response.message);

     yield put(failCreateForm(response.message));
}

export function* eventFormsSaga({ payload }) {
     const response = yield call(Service.getEventForms, payload);

     if (response.status === 200) {
          const { data, ...pageDetails } = response.data;
          yield put(getEventFormsPageDetails(pageDetails));
          return yield put(successEventForms(data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     if (response.status === 404) {
          return yield put(push('/not-found-404'));
     }

     yield put(failEventForms(response.message));
}

export function* searchFormsSaga({ payload }) {
     const response = yield call(Service.searchForms, payload);

     if (response.status === 200) {
          return yield put(successFormsSearch(response.data.data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     yield put(failFormsSearch(response.message));
}

export function* removeFormSaga({ payload }) {
     const response = yield call(Service.removeForm, payload);

     if (response.status === 200) {
          toastr.success(i18n.t('alerts.success.invitation_form_removed'));
          return yield put(successRemoveForm('success'));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     yield put(failRemoveForm(response.message));
}

export function* formFieldsSaga({ payload }) {
     const response = yield call(Service.getFormFields, payload);

     if (response.status === 200) {
          return yield put(successFormFields(response.data.data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     if (response.status === 404) {
          return yield put(push('/not-found-404'));
     }

     yield put(failFormFields(response.message));
}

export function* formDetailsSaga({ payload }) {
     const response = yield call(Service.getFormDetails, payload);

     if (response.status === 200) {
          return yield put(successFormDetails(response.data.data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     if (response.status === 403 || response.status === 404) {
          return yield put(push('/not-found-404'));
     }

     yield put(failFormDetails(response.data.message));
}

export function* formBasicDetailsSaga({ payload }) {
     const response = yield call(Service.getFormDetails, payload);

     if (response.status === 200) {
          return yield put(successFormBasicDetails(response.data.data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     if (response.status === 403 || response.status === 404) {
          return yield put(push('/not-found-404'));
     }

     yield put(failFormBasicDetails(response.data.message));
}

export function* formUpdateSaga({ payload }) {
     const { redirect, organizationId, eventId, id, ...rest } = payload;
     const response = yield call(Service.updateForm, { eventId, organizationId, id, ...rest });

     if (response.status === 200 || response.status === 201) {
          toastr.success(i18n.t('alerts.success.form_saved'));

          if (redirect) {
               yield put(successUpdateForm(response.data.data));

               return yield put(
                    push(
                         `/organization/${organizationId}/events/${eventId}/invite-speakers/step-2`
                    )
               );
          }

          return yield put(successUpdateForm(response.data.data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     response.errors && toastr.error(response.message);

     yield put(failUpdateForm(response.message));
}

export function* saveCustomForm({payload}) {
     const { redirect, eventId, organizationId, id, speakerId, shouldCreate, ...rest } = payload;

     let response;
     if (shouldCreate) {
          response = yield call(Service.createForm, {eventId, organizationId, speaker_id: speakerId, ...rest});
     } else {
          response = yield call(Service.updateForm, {eventId, organizationId, speaker_id: speakerId, id, ...rest});
     }

     if (response.status >= 200 && response.status <= 209) {
          yield put(successSaveCustomForm(i18n.t('alerts.success.changes_saved')));
          toastr.success(i18n.t('alerts.success.changes_saved'));

          if (redirect) {
               return yield put(
                   push(
                       `/organization/${organizationId}/events/${eventId}/speakers`
                   )
               );
          }
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     response.errors && toastr.error(response.message);
     yield put(failSaveCustomForm(response.message));
}

export function* watchFormBuilder() {
     yield all([
          takeEvery(ACTIONS.CREATE_FORM_REQUEST, createFormSaga),
          takeEvery(ACTIONS.EVENT_FORMS_REQUEST, eventFormsSaga),
          takeEvery(ACTIONS.SEARCH_FORMS_REQUEST, searchFormsSaga),
          takeEvery(ACTIONS.REMOVE_FORM_REQUEST, removeFormSaga),
          takeEvery(ACTIONS.FORM_FIELDS_REQUEST, formFieldsSaga),
          takeEvery(ACTIONS.FORM_DETAILS_REQUEST, formDetailsSaga),
          takeEvery(ACTIONS.FORM_BASIC_DETAILS_REQUEST, formBasicDetailsSaga),
          takeEvery(ACTIONS.UPDATE_FORM_REQUEST, formUpdateSaga),
          takeEvery(ACTIONS.SAVE_CUSTOM_FORM_REQUEST, saveCustomForm),
     ])
}
