import React, { memo } from 'react';
import { Button, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const _ViewMoreModal = memo(
     ({
          show,
          handleClose,
          field,
          text
     }) => {
          const { t } = useTranslation();

          return (
               <Modal
                    show={show}
                    onHide={handleClose}
                    dialogClassName="selection-modal"
                    container={document.getElementById('root')}
               >
                    <Modal.Header>
                         <Modal.Title>
                              {field}
                         </Modal.Title>
                         <button className="close" type="button">
                              <i
                                   className="las la-times"
                                   onClick={handleClose}
                              />
                         </button>
                    </Modal.Header>
                         <Modal.Body>
                              <Row sm={12}>
                                   {text && <p style={{ display: 'block', width: '100%' }} dangerouslySetInnerHTML={{
                                        __html: text.replace(/\n/g, '<br>\n')
                                   }}/>}
                              </Row>
                         </Modal.Body>
                         <Modal.Footer>
                              <Button variant="secondary" onClick={handleClose}>
                                   {t('buttons.close')}
                              </Button>
                         </Modal.Footer>
               </Modal>
          );
     }
);

export const ViewMoreModal = _ViewMoreModal;
