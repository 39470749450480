import React, { memo, useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {isEmpty} from "underscore";
import {ELEMENT_TITLE_LENGTH} from "../../../../utils/formBuilder.utils";

export const TitleSettings = memo(
     ({ selectedElement, handleSettingChange, handleRemoveElement, getMasterValue }) => {
          const { t } = useTranslation();

          const [state, setState] = useState();
          const ref = useRef();

          useEffect(() => {
               setState(selectedElement);
          }, [selectedElement]);

          const handleInputChange = ({ target: { value } }) => {
               setState({ ...state, value });
               handleSettingChange({
                    id: selectedElement.id,
                    name: 'value',
                    value,
               });
          };

          const handleRemoveItself = () => {
              setState(selectedElement);
              handleRemoveElement(selectedElement.id);
          };

          return (
               state && (
                    <>
                         <label htmlFor="title">
                              {t('form_builder.value')}
                         </label>
                         <input
                              ref={ref}
                              id="title"
                              className={`form-control ${state && ((!state.is_valid && isEmpty(state.value)) || (!state.is_translated && state.missing_translations.includes('value'))) ? 'is-warning' : ''}`}
                              type="text"
                              value={state.value}
                              placeholder={state && getMasterValue(state.internal_id, 'value', null, t(
                                  'form_builder.title_placeholder'
                              ))}
                              onChange={handleInputChange}
                              maxLength={ELEMENT_TITLE_LENGTH}
                         />
                        {state.is_master && (
                            <>
                                <hr className="setting-divider" />
                                <Button
                                    variant="danger"
                                    onClick={handleRemoveItself}
                                >
                                    {t('form_builder.delete_element')}
                                </Button>
                            </>
                        )}
                     </>
                )
          );
     }
);
