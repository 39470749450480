import i18n from 'i18next';
import toastr from 'toastr';
import {call, put, all, takeEvery} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
     getRemindersPageDetails,
     successEventReminders,
     failEventReminders,
     successCreateReminder,
     failCreateReminder,
     successReminderDetails,
     failReminderDetails,
     successRemoveReminder,
     failRemoveReminder,
     successPreviewReminder,
     failPreviewReminder,
     successReminderTemplates,
     failReminderTemplates,
     successCreateReminderTemplate,
     failCreateReminderTemplate,
     successRemoveReminderTemplate,
     failRemoveReminderTemplate,
     refreshPreviewReminder,
     successCopyReminder,
     failCopyReminder
} from '../reducers/reminderReducer';
import * as ACTIONS from '../actions';
import * as Service from '../../services';
import { handleErrors } from '../../utils/sagaUtils';

export function* eventRemindersSaga({ payload }) {
     const response = yield call(Service.getEventReminders, payload);

     if (response.status === 200) {
          const { data, ...pageDetails } = response.data;
          yield put(getRemindersPageDetails(pageDetails));
          return yield put(successEventReminders(data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     if (response.status === 404) {
          return yield put(push('/not-found-404'));
     }

     yield put(failEventReminders(response.message));
}

export function* createReminderSaga({ payload }) {
     const response = yield call(Service.createReminder, payload);

     if (response.status === 201) {
          toastr.success(i18n.t('alerts.success.reminder_created'));

          return yield put(successCreateReminder(response.data.message));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }
     response.errors && handleErrors(response.errors);
     yield put(failCreateReminder(response.message));
}

export function* reminderDetailsSaga({ payload }) {
     const response = yield call(Service.getReminderDetails, payload);

     if (response.status === 200) {
          return yield put(successReminderDetails(response.data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     if (response.status === 403 || response.status === 404) {
          return yield put(push('/not-found-404'));
     }

     yield put(failReminderDetails(response.data.message));
}

export function* copyReminderSaga({ payload }) {
     const { organizationId, eventId } = payload;
     const response = yield call(Service.copyReminder, payload);

     if (response.status === 201) {
          toastr.success(i18n.t('alerts.success.reminder_updated'));

          yield put(successCopyReminder(response.data.message));

          return yield put(push(`/organization/${organizationId}/events/${eventId}/reminders`));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }
     response.errors && handleErrors(response.errors);
     yield put(failCopyReminder(response.message));
}

export function* removeReminderSaga({ payload }) {
     const response = yield call(Service.removeReminder, payload);

     if (response.status === 200) {
          toastr.success(i18n.t('alerts.success.reminder_removed'));
          return yield put(successRemoveReminder('success'));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     yield put(failRemoveReminder(response.message));
}

export function* previewReminderSaga({ payload }) {
     const response = yield call(Service.getReminderPreview, payload);

     if (response.status === 200) {
          return yield put(successPreviewReminder(response.data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     if (response.status === 404) {
          return yield put(push('/not-found-404'));
     }

     yield put(failPreviewReminder(response.message));
}

export function* reminderTemplatesSaga({ payload }) {
     const response = yield call(Service.getReminderTemplates, payload);

     if (response.status === 200) {
          return yield put(successReminderTemplates(response.data.data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     if (response.status === 404) {
          return yield put(push('/not-found-404'));
     }

     yield put(failReminderTemplates(response.message));
}

export function* createReminderTemplateSaga({ payload }) {
     const response = yield call(Service.createReminderTemplate, payload);

     if (response.status === 201) {
          return yield put(successCreateReminderTemplate(payload.message));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }
     response.errors && handleErrors(response.errors);
     yield put(failCreateReminderTemplate(response.message));
}

export function* removeReminderTemplateSaga({ payload }) {
     const response = yield call(Service.removeReminderTemplate, payload);

     if (response.status === 200) {
          return yield put(successRemoveReminderTemplate(payload.message));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     yield put(failRemoveReminderTemplate(response.message));
}

export function* watchReminder() {
     yield all([
          takeEvery(ACTIONS.EVENT_REMINDERS_REQUEST, eventRemindersSaga),
          takeEvery(ACTIONS.CREATE_REMINDER_REQUEST, createReminderSaga),
          takeEvery(ACTIONS.REMINDER_DETAILS_REQUEST, reminderDetailsSaga),
          takeEvery(ACTIONS.COPY_REMINDER_REQUEST, copyReminderSaga),
          takeEvery(ACTIONS.REMOVE_REMINDER_REQUEST, removeReminderSaga),
          takeEvery(ACTIONS.PREVIEW_REMINDER_REQUEST, previewReminderSaga),
          takeEvery(ACTIONS.REMINDER_TEMPLATES_REQUEST, reminderTemplatesSaga),
          takeEvery(ACTIONS.CREATE_REMINDER_TEMPLATE_REQUEST, createReminderTemplateSaga),
          takeEvery(ACTIONS.REMOVE_REMINDER_TEMPLATE_REQUEST, removeReminderTemplateSaga),
     ])
}
