import React from 'react';

function ExportIcon() {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="18"
               height="18"
               fill="none"
               viewBox="0 0 18 18"
          >
               <path
                    fill="#606D80"
                    d="M12.525 1.26h3.323l-7.597 7.6a.632.632 0 00.444 1.076.628.628 0 00.445-.183l7.6-7.601v3.323a.63.63 0 101.26 0V.632A.632.632 0 0017.368 0h-4.843a.632.632 0 00-.632.632.633.633 0 00.632.627z"
               ></path>
               <path
                    fill="#606D80"
                    d="M3.778 18h10.44a3.782 3.782 0 003.778-3.778V9.305a.628.628 0 00-.631-.628.628.628 0 00-.628.628v4.913a2.523 2.523 0 01-2.52 2.519H3.779a2.523 2.523 0 01-2.519-2.52V3.783a2.523 2.523 0 012.52-2.519H8.65A.628.628 0 009.28.632.63.63 0 008.651 0H3.778A3.782 3.782 0 000 3.778v10.44A3.785 3.785 0 003.778 18z"
               ></path>
          </svg>
     );
}

export default ExportIcon;
