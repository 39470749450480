import styled from 'styled-components';

export const ModuleDownWrapper = styled.div`
     padding-bottom: 118px;
     .btn-themed-blue {
          margin: 15px 0;
     }

     .content {
          display: flex;
          align-items: start;
          justify-content: space-between;
     }

     @media only screen and (max-width: 1320px) {
          .content {
               flex-direction: column;
               align-items: unset;
          }
     }
`;
