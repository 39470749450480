import React, { memo, lazy, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { Link, useParams } from 'react-router-dom';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { SettingsImage } from '../../../assets/icons/metronic/settings-image';
import { GroupChatImage } from '../../../assets/icons/metronic/group-chat-image';
import { DotImage } from '../../../assets/icons/metronic/dot-image';
import { formatDate } from '../../../utils/date.utils';
import Breadcrumb from '../../common/breadcrumb';
import Badge from '../../common/badge';
import { requestOrganizationDetails } from '../../../redux/reducers/organizationReducer';
import {
     requestOrganizationEvents,
     requestEventSearch,
} from '../../../redux/reducers/eventReducer';
import LoadingBar from '../../common/loading-bar';
import { ListItem } from '../../common/collapsible-list/CollapsibleList';
import { EventsTable } from './sub-components/EventsTable';

import { OrganizationDropdown } from '../Organization/sub-components/OrganizationDropdown';
import { ModuleDown } from '../templates/ModuleDown';

import EventsWrapper from './Event.style';
import {EventsEmpty} from "./sub-components/EventsEmpty";

const MainDrawer = lazy(() => import('../../common/drawer'));
const CollapsibleList = lazy(() => import('../../common/collapsible-list'));

const mapStateToProps = (state) => ({
     router: state.router,
     organization: state.organizationReducer.organization,
     pageDetails: state.eventReducer.organizationEventsPageDetails,
     eventsList: state.eventReducer.eventsList,
     message: state.eventReducer.message,
     eventSearchResult: state.eventReducer.eventSearchResult,

     organizationEventsRequested: state.eventReducer.organizationEventsRequested,
     createNewEventRequested: state.eventReducer.createNewEventRequested,
     createNewEventSucceeded: state.eventReducer.createNewEventSucceeded,
     eventDetailsUpdateRequested: state.eventReducer.eventDetailsUpdateRequested,
     eventDetailsUpdateSucceeded: state.eventReducer.eventDetailsUpdateSucceeded,
     moduleDown: state.eventReducer.moduleDown,
});

const mapDispatchToProps = (dispatch) => ({
     redirectToEventsEmpty: (location) => dispatch(replace(location)),
     requestOrganizationDetails: (id) => dispatch(requestOrganizationDetails(id)),
     requestOrganizationEvents: (id) => dispatch(requestOrganizationEvents(id)),
     requestEventSearch: (query) => dispatch(requestEventSearch(query)),
});

export const _Events = memo((props) => {
     const {
          router: {
               location: { pathname },
          },
          requestOrganizationDetails,
          requestOrganizationEvents,
          eventsList,
          pageDetails,
          organizationDetailsUpdateRequested,
          organizationDetailsUpdateSucceeded,
          organization,
          message,
          organizationEventsRequested,
          createNewEventRequested,
          createNewEventSucceeded,
          eventDetailsUpdateRequested,
          eventDetailsUpdateSucceeded,
          moduleDown,
     } = props;

     const { id: organizationId } = useParams();

     const persistedPerPage = JSON.parse(localStorage.getItem(`page_size.events`));
     const [data, setData] = useState([]);
     const [queryString, setQueryString] = useState(null);
     const [sorting, setSorting] = useState({
          column: 'id',
          direction: 'desc',
          is_activated: false,
          columns: ['id', 'name', 'status', 'start'],
          tableColumns: ['name', 'eventDate', 'status'],
          mapColumns: {'id': 'id', 'name': 'name', 'eventDate': 'start', 'status': 'status'}
     });

     const transformEventsData = (events) =>
          events &&
          events.map((event) => ({
               id: event.id,
               name: event.name,
               photos: event.photos,
               eventDate: formatDate({
                    startDate: event.start,
                    endDate: event.end,
               }),
               status: (
                    <Badge
                         variant={
                              event.status === 'complete'
                                   ? 'success'
                                   : event.status === 'incomplete'
                                   ? 'danger'
                                   : 'warning'
                         }
                    >
                         {event.status === 'no_speakers'
                              ? t('status.no_speakers')
                              : t(`status.${event.status}`)}
                    </Badge>
               ),
          }));

     const { t } = useTranslation();

     useEffect(() => {
          if (organizationId) {
               requestOrganizationDetails(organizationId);
          }
     }, [organizationId]);

     useEffect(() => {
          requestOrganizationDetails(organizationId);
          requestOrganizationEvents({
               id: organizationId,
               per_page: persistedPerPage ? persistedPerPage : 50,
          });
     }, [
          organizationDetailsUpdateRequested,
          organizationDetailsUpdateSucceeded,
          createNewEventRequested,
          createNewEventSucceeded,
          eventDetailsUpdateRequested,
          eventDetailsUpdateSucceeded,
          message,
     ]);

     const handlePageSizeChange = (pageSize) => {
          localStorage.setItem(
              `page_size.events`,
              JSON.stringify(pageSize, null, 2)
          );

          requestOrganizationEvents({
               id: organizationId,
               per_page: pageSize,
               query: queryString,
               sort: sorting.column,
               direction: sorting.direction
          });
     };

     const handlePageChange = ({ page, pageSize }) =>
          requestOrganizationEvents({
               id: organizationId,
               per_page: pageSize,
               page,
               query: queryString,
               sort: sorting.column,
               direction: sorting.direction
          });

     const handleSort = (field, direction) => {
          setSorting({
               ...sorting,
               is_activated: true,
               column: field,
               direction
          });
     };

     useEffect(() => {
          if (sorting.is_activated) {
               requestOrganizationEvents({
                    id: organizationId,
                    per_page: pageDetails.per_page,
                    page: pageDetails.page,
                    query: queryString,
                    sort: sorting.column,
                    direction: sorting.direction
               });
          }
     }, [sorting]);

     useEffect(() => {
          if (queryString && queryString.length > 0) {
               requestOrganizationEvents({
                    id: organizationId,
                    per_page: pageDetails.per_page,
                    page: 1,
                    query: queryString,
                    sort: sorting.column,
                    direction: sorting.direction
               });
          }
     }, [queryString]);

     useEffect(() => {
          if (eventsList) {
               setData(transformEventsData(eventsList));
          }
     }, [eventsList]);

     const getLinks = () => {

          const organizationLink = organization && organization.member_scope === 'admin' ? 'settings' : 'events';

          return [
               {
                    to: `/organization/${organizationId}/${organizationLink}`,
                    text: `${organization && organization.name}`,
               },
               {
                    to: `/organization/${organizationId}/events`,
                    text: i18n.t('events'),
               },
          ];
     };

     const handleSearch = ({ target: { value } }) => {
          setQueryString(value);
          if (value.length === 0) {
               requestOrganizationEvents({
                    id: organizationId,
                    per_page: pageDetails.per_page,
                    page: 1,
                    sort: sorting.column,
                    direction: sorting.direction
               });
          }
     };

     return (
          <EventsWrapper>
               <Helmet>
                    <title>
                         {t('events')} | {t('avovent')}
                    </title>
               </Helmet>
               <MainDrawer>
                    <ListItem to="/dashboard">
                         {t('breadcrumb.dashboard')}
                    </ListItem>
                    <CollapsibleList
                         listHeader={organization && organization.name}
                         expanded={
                              window.location.pathname ===
                              `/organization/${organizationId}/events`
                         }
                         listContent={
                              <>
                                   <ListItem
                                        to={`/organization/${organizationId}/events`}
                                   >
                                        <div className="collapsible-list-item">
                                             <GroupChatImage />
                                             <div className="sub-item">
                                                  {t('events')}
                                             </div>
                                        </div>
                                   </ListItem>
                                   <CollapsibleList
                                        listHeader={
                                             <div className="collapsible-list-item">
                                                  <SettingsImage />
                                                  <div className="sub-item">
                                                       {t(
                                                            'organization_settings.organization_settings'
                                                       )}
                                                  </div>
                                             </div>
                                        }
                                        listContent={
                                             <>
                                                  <ListItem
                                                       subItem
                                                       to={`/organization/${organizationId}/settings`}
                                                  >
                                                       <div className="collapsible-list-item">
                                                            <DotImage />
                                                            <div className="sub-item">
                                                                 {t(
                                                                      'organization_settings.organization_settings'
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </ListItem>
                                                  <ListItem
                                                       subItem
                                                       to={`/organization/${organizationId}/members`}
                                                  >
                                                       <div className="collapsible-list-item">
                                                            <DotImage />
                                                            <div className="sub-item">
                                                                 {t(
                                                                      'organization_settings.team_members'
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </ListItem>
                                                  <ListItem
                                                       subItem
                                                       to={`/organization/${organizationId}/email-senders`}
                                                  >
                                                       <div className="collapsible-list-item">
                                                            <DotImage />
                                                            <div className="sub-item">
                                                                 {t(
                                                                      'organization_settings.email_senders'
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </ListItem>
                                             </>
                                        }
                                   />
                              </>
                         }
                    />
               </MainDrawer>
               <div className="content--border">
                    <div
                         className="container-standard"
                         style={{ display: 'flex', alignItems: 'center' }}
                    >
                         <div className="nav">
                              <Link to="/dashboard" className="inactive">
                                   {t('breadcrumb.dashboard')}
                              </Link>
                              <OrganizationDropdown organization={organization}/>
                         </div>
                    </div>
                    <LoadingBar finish={!!organization} />
               </div>
               <div className="container-standard">
                    {organization && (
                         <Breadcrumb title={t('events')} links={getLinks()} />
                    )}
               </div>
               {moduleDown ? (
                    <ModuleDown />
               ) : (
                    <div className="container-standard">
                         <div className="table">
                              {!createNewEventRequested && !organizationEventsRequested && (
                                  <>
                                       {(eventsList && eventsList.length === 0 && queryString === null) ? (
                                           <EventsEmpty/>
                                       ) : eventsList && (
                                           <EventsTable
                                               data={data}
                                               handlePageSizeChange={
                                                    handlePageSizeChange
                                               }
                                               handlePageChange={handlePageChange}
                                               handleSearch={handleSearch}
                                               sorting={sorting}
                                               handleSort={handleSort}
                                           />
                                       )}
                                  </>
                              )}

                         </div>
                    </div>
               )}
          </EventsWrapper>
     );
});

export const Events = connect(mapStateToProps, mapDispatchToProps)(_Events);
