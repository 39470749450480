import React, { memo, useState, useRef } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { bulkAdd } from '../../../../utils/array.utils';

export const BulkAddModal = memo(
     ({ show, handleBulkAddSpeakers, handleClose, languages }) => {
          const { t } = useTranslation();
          const form = useRef();

          const [sendingData, setSendingData] = useState(false);

          const handleSubmit = (values) => {
               handleBulkAddSpeakers(bulkAdd(values.speakers, languages));

               handleClose();
          };

          return (
               <Modal
                    show={show}
                    onHide={handleClose}
                    container={document.getElementById('root')}
               >
                    <Modal.Header closeButton>
                         <Modal.Title>{t('speakers.bulk_add')}</Modal.Title>
                    </Modal.Header>
                    <Formik
                         onSubmit={handleSubmit}
                         initialValues={{ speakers: 'speakers' }}
                    >
                         {({ handleSubmit, handleChange, values }) => (
                              <Form noValidate onSubmit={handleSubmit}>
                                   <Modal.Body>
                                        <p>{t('speakers.bulk_add_hint')}</p>
                                        <Form.Group controlId="speakers">
                                             <Form.Control
                                                  as="textarea"
                                                  rows="6"
                                                  name="speakers"
                                                  placeholder={`${t(
                                                       'organization_settings.name'
                                                  )} ${t(
                                                       'sign_up.placeholders.last_name'
                                                  )}, ${t(
                                                       'organization_settings.email'
                                                  )}, EN`}
                                                  value={values.message}
                                                  onChange={handleChange}
                                                  disabled={sendingData}
                                             />
                                        </Form.Group>
                                   </Modal.Body>
                                   <Modal.Footer>
                                        <Button
                                             variant="secondary"
                                             onClick={handleClose}
                                        >
                                             {t('buttons.close')}
                                        </Button>
                                        <Button
                                             variant="primary"
                                             type="submit"
                                             ref={form}
                                        >
                                             {t('buttons.add')}
                                        </Button>
                                   </Modal.Footer>
                              </Form>
                         )}
                    </Formik>
               </Modal>
          );
     }
);
