import React, {useEffect, useRef, useState} from 'react';
import { useDrag, useDrop } from 'react-dnd';

import IconImage from '../../../../../assets/icons/form-builder/IconImage';
import { DND_TYPES } from '../../ElementTypes';
import { Placeholder } from '../Placeholder';
import { COLORS } from '../../../../../constants/style.constants';
import PictureUploader from '../../../../common/picture-uploader';
import { Button } from 'react-bootstrap';
import TimesIcon from '../../../../../assets/icons/metronic/times-icon';
import defaultPhoto from '../../../../../assets/images/default-photo.svg';
import { Overlay } from '../Overlay';
import {getEmptyImage} from "react-dnd-html5-backend";

export const Image = ({
     id,
     name,
     type,
     label,
     elementCode,
     elementType,
     additionalInformation,
     required,
     index,
     inBuilder,
     isMaster,
     isValid,
     forceDisplay,
     handleElementSelect,
     isSelectedItem,
     handleRemoveElement,
     setActiveSpan,
     activeSpan,
     moveElement
}) => {
     const av = defaultPhoto;
     const [avatar, setAvatar] = useState(av);
     const getFile = (file) => setAvatar(file);
     const getDefault = () => av;

     const ref = useRef(null);

     const [, drop] = useDrop({
          accept: DND_TYPES,
          hover(item, monitor) {
               if (!ref.current || item.index === undefined) {
                    return;
               }

               const dragIndex = item.index;
               const hoverIndex = index;

               if (dragIndex === hoverIndex) {
                    return;
               }

               const hoverBoundingRect = ref.current?.getBoundingClientRect();
               const hoverMiddleY =
                    (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
               const clientOffset = monitor.getClientOffset();
               const hoverClientY = clientOffset.y - hoverBoundingRect.top;

               if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                    return;
               }

               if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                    return;
               }

               moveElement(dragIndex, hoverIndex);

               item.index = hoverIndex;
          },
     });

     const [{ isDragging }, drag, preview] = useDrag({
          item: {
               id,
               name,
               type,
               label,
               elementCode,
               elementType,
               additionalInformation,
               required,
               index,
          },
          isDragging(monitor) {
               return monitor.getItem() && monitor.getItem().id === id;
          },
          collect: (monitor) => ({
               isDragging: monitor.isDragging(),
          }),
     });

     useEffect(() => {
          preview(getEmptyImage(), { captureDraggingState: false });
     }, []);

     drag(drop(ref));

     if (!inBuilder) {
          return (
               <div ref={ref} className="elements-grid--item" role="button">
                    <span className="icon">
                         <IconImage />
                    </span>
                    <span className="text">{name}</span>
               </div>
          );
     }

     if (isDragging && !forceDisplay) {
          return <Placeholder />;
     }

     return (
          <div
               ref={ref}
               className={`selection ${!isValid && !isDragging && !isSelectedItem(id) ? 'is-warning' : ''}`}
               onClick={(e) => handleElementSelect(e, id)}
               style={{
                    display: 'grid',
                    gridTemplateColumns: '195px auto',
                    placeItems: 'center start',
                    backgroundColor: isSelectedItem(id)
                         ? 'rgba(24, 112, 244, 0.05)'
                         : '#fff',
                    position: 'relative',
               }}
          >
               <Overlay />

               <label
                    htmlFor={label}
                    style={{
                         display: 'grid',
                         gridTemplateColumns: '195px calc(100% + 195px - 37px)',
                         margin: 0,
                    }}
               >
                    <span
                         style={{
                              maxWidth: '160px',
                              placeSelf: 'start',
                         }}
                    >
                         {label || name}
                         {required && (
                              <span
                                   style={{
                                        marginLeft: '10px',
                                        color: `${COLORS.RED}`,
                                   }}
                              >
                                   *
                              </span>
                         )}
                    </span>
                    <div>
                         <PictureUploader
                              required={required}
                              picture={avatar}
                              getFile={getFile}
                              getDefault={getDefault}
                              infoText={' '}
                              tabIndex={-1}
                         />
                         {additionalInformation && (
                              <div
                                   className="info-block"
                                   style={{ width: '100%' }}
                              >
                                   {additionalInformation}
                              </div>
                         )}
                    </div>
               </label>
               {isMaster && (
                   <Button
                       autoFocus={isSelectedItem(id)}
                       variant="danger"
                       onClick={() => handleRemoveElement(id)}
                       onMouseEnter={() => setActiveSpan(true)}
                       onMouseLeave={() => setActiveSpan(false)}
                       style={{
                            visibility: isSelectedItem(id) ? 'visible' : 'hidden',
                            position: 'relative',
                            zIndex: 3,
                       }}
                   >
                        <TimesIcon
                            color={`${COLORS.PLACEHOLDER_GREY}`}
                            activeColor={`${COLORS.WHITE}`}
                            hovered={activeSpan}
                        />
                   </Button>
               )}
          </div>
     );
};
