import React from 'react';

function IconDivider() {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="18"
               height="5"
               fill="none"
               viewBox="0 0 18 5"
          >
               <path
                    fill="currentColor"
                    d="M17.341.659a2.25 2.25 0 11-3.182 3.182A2.25 2.25 0 0117.341.659zM10.591.659a2.25 2.25 0 11-3.182 3.182A2.25 2.25 0 0110.591.659zM3.841.659A2.25 2.25 0 11.659 3.841 2.25 2.25 0 013.84.659z"
               ></path>
          </svg>
     );
}

export default IconDivider;
