import React, {useState, useEffect, useRef} from 'react';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { Link, useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import { Button, Row, Col, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import PictureUploader from '../../../common/picture-uploader';
import RangePicker from '../../../common/pickers';
import Input from '../../../common/input';
import eventImage from '../../../../assets/images/event-image.svg';
import TagField from '../../../common/tagfield';
import { requestOrganizationMembers } from '../../../../redux/reducers/organizationReducer';
import {
     requestCreateNewEvent,
     requestUpdateEventDetails,
     requestCancelEvent,
     requestEventPhotoUpload,
     requestRemoveEventAvatar,
     requestEventPhotoTemporaryUpload
} from '../../../../redux/reducers/eventReducer';
import { getFullName } from '../../../../utils/string.utils';
import { formatDateEmpty } from '../../../../utils/date.utils';
import SweetAlert from '../../../common/sweet-alert';
import { EVENT } from '../../../../constants/form.constants';
import {requestUserAvatarGenerateTemp} from "../../../../redux/reducers/userReducer";
import {dataURLtoFile} from "../../../../utils/data.utils";
import {MIME_PHOTOS} from "../../../../utils/mime.utils";
import {AVATAR_FILE_LIMIT} from "../../../../utils/file.utils";
import { event } from 'jquery';

const mapStateToProps = (state, ownProps) => ({
     ...ownProps,
     router: state.router,
     membersList: state.organizationReducer.membersList,
     currentUser: state.userReducer.currentUser,
     photoTemporary: state.eventReducer.photoTemporary,
     uploadSucceeded: state.eventReducer.eventPhotoUploadSucceeded,
     uploadRequested: state.eventReducer.eventPhotoUploadRequested,
     temporaryUploadRequested: state.eventReducer.eventPhotoTemporaryUploadRequested,
     temporaryUploadSucceeded: state.eventReducer.eventPhotoTemporaryUploadSucceeded,
     userGeneratedAvatar: state.userReducer.userGeneratedAvatar,
     userGeneratedAvatarSucceeded: state.userReducer.userAvatarGenerateTempSucceeded
});

const mapDispatchToProps = (dispatch) => ({
     requestOrganizationMembers: (id) => dispatch(requestOrganizationMembers(id)),
     requestCreateNewEvent: (id) => dispatch(requestCreateNewEvent(id)),
     requestUpdateEventDetails: (payload) => dispatch(requestUpdateEventDetails(payload)),
     requestCancelEvent: (payload) => dispatch(requestCancelEvent(payload)),
     redirectToEvents: (location) => dispatch(replace(location)),
     requestEventPhotoUpload: (payload) => dispatch(requestEventPhotoUpload(payload)),
     requestEventPhotoTemporaryUpload: (payload) => dispatch(requestEventPhotoTemporaryUpload(payload)),
     requestRemoveEventAvatar: (payload) => dispatch(requestRemoveEventAvatar(payload)),
     requestUserAvatarGenerate: (values) => dispatch(requestUserAvatarGenerateTemp(values))
});

const EventForm = ({ btnText, ...props }) => {
     const {
          dangerZone = false,
          requestOrganizationMembers,
          membersList,
          requestCreateNewEvent,
          eventName,
          eventDate,
          eventManagers,
          eventPhoto,
          allPhotos,
          start,
          end,
          isPhotoDefault,
          requestUpdateEventDetails,
          requestCancelEvent,
          redirectToEvents,
          requestEventPhotoUpload,
          requestEventPhotoTemporaryUpload,
          photoTemporary,
          userGeneratedAvatar,
          userGeneratedAvatarSucceeded,
          requestUserAvatarGenerate,
          currentUser
     } = props;
     const { id, eventId } = useParams();
     const { pathname } = useLocation();
     const { t } = useTranslation();

     const form = useRef();

     const [startDate, setStartDate] = useState(moment(start) || '');
     const [endDate, setEndDate] = useState(moment(end) || '');
     const [dates, setDates] = useState({
          startDate: '',
          endDate: '',
     });
     const [managerIds, setManagerIds] = useState([]);
     const [avatar, setAvatar] = useState(eventPhoto ? eventPhoto : eventImage);
     const [defaultAvatar, setDefaultAvatar] = useState(null);
     const [previewAvatar, setPreviewAvatar] = useState(null);
     const [fullPreview, setFullPreview] = useState(null);
     const [fileForUpload, setFileForUpload] = useState(null);
     const [temporaryFileForUpload, setTemporaryFileForUpload] = useState(null);
     const [isLoading, setIsLoading] = useState(false);

     const av = eventPhoto ? eventPhoto : eventImage;

     useEffect(() => {
          return function cleanup() {
               setStartDate('');
               setEndDate('');
               setDates({
                    startDate: '',
                    endDate: ''
               });
               setManagerIds([]);
               setAvatar(null);
               setDefaultAvatar(null);
               setPreviewAvatar(null);
               setFileForUpload(null);
               setTemporaryFileForUpload(null);
               setIsLoading(false);
          };
     }, []);

     useEffect(() => {
          setAvatar(av);
          setDefaultAvatar(av);
          setIsLoading(false);
          setFullPreview(allPhotos ? allPhotos : null);
     }, [eventPhoto]);

     useEffect(() => {
          setStartDate(moment(start) || '');
          setEndDate(moment(end) || '');
     }, [start, end]);

     useEffect(() => {
          requestOrganizationMembers({ id, per_page: 20 });
     }, [pathname]);

     useEffect(() => {
          if (userGeneratedAvatarSucceeded && userGeneratedAvatar) {
               setAvatar(userGeneratedAvatar);
               setDefaultAvatar(userGeneratedAvatar);
               setIsLoading(false);
               setFullPreview({'640x640': userGeneratedAvatar});

               const file = dataURLtoFile(userGeneratedAvatar, 'generated.png');

               setFileForUpload({
                    file,
                    crop: {
                         width: 1000,
                         height: 1000,
                         x: 0,
                         y: 0,
                         is_generated: true
                    }
               });
          }
     }, [userGeneratedAvatarSucceeded]);

     useEffect(() => {
          if (photoTemporary) {
               setAvatar(photoTemporary.urls['240x240']);
               setDefaultAvatar(photoTemporary.urls['240x240']);
               setFullPreview(photoTemporary.urls);
               setIsLoading(false);
               setTemporaryFileForUpload(photoTemporary);
          }
     },[photoTemporary]);

     const getRange = () => {
          const drpSelected = document.querySelector('.drp-selected');
          const textContent = drpSelected && drpSelected.textContent;
          const dateRange = textContent && textContent.split(' - ');

          if (dateRange) {
               const [start, end] = dateRange;
               const formattedStart = moment(start).format('YYYY-MM-DD');
               const formattedEnd = moment(end).format('YYYY-MM-DD');

               setStartDate(formattedStart);
               setEndDate(formattedEnd);
          }
     };

     const handleTagsChange = ({ target: { value } }) => {
          const newValue = value.split(',').map((i) => Number(i));

          if (newValue.length > 1) {
               setManagerIds(newValue);
          } else if (defaultManager()) {
               setManagerIds([defaultManager().id]);
          } else {
               setManagerIds(newValue);
          }
     };

     const handleSubmit = (values) => {
          const ids = managerIds.length ? managerIds : eventManagers.map(({ id }) => id);
          const managers = membersList.filter(({user_id}) => ids.includes(user_id));

          const request = {
               organization_id: Number(id),
               name: values.eventName,
               start: startDate,
               end: endDate,
               managers: managers
          };

          if (temporaryFileForUpload) {
               requestCreateNewEvent({ ...request, photo: temporaryFileForUpload.id });
               removeEventPhoto();

               return redirectToEvents(`/organization/${id}/events`);
          }

          requestCreateNewEvent(request);
          redirectToEvents(`/organization/${id}/events`);
     };

     const handleEditSubmit = (values) => {
          const ids = managerIds.length ? managerIds : eventManagers.map(({ id }) => id);
          const managers = membersList.filter(({user_id}) => ids.includes(user_id));

          const request = {
               organization_id: Number(id),
               eventId: Number(eventId),
               name: values.eventName || eventName,
               start: startDate || start,
               end: endDate || end,
               managers: managers
          };

          if (fileForUpload) {
               requestEventPhotoUpload({ eventId, eventPhoto: fileForUpload.file, crop: fileForUpload.crop });
          }

          requestUpdateEventDetails(request);
          redirectToEvents(`/organization/${id}/events`);
     };

     const handleEventCancel = () => {
          SweetAlert({
               title: i18n.t('sweet_alerts.attention'),
               text: i18n.t('sweet_alerts.cancel_event'),
               confirmButtonText: i18n.t('buttons.cancel_event'),
               cancelButtonText: i18n.t('buttons.go_back'),
               showCancelButton: true,
               callback: () => requestCancelEvent({ id, eventId }),
          });
     };

     useEffect(() => {
          if (!dangerZone && fileForUpload) {
               requestEventPhotoTemporaryUpload({
                    photo: fileForUpload.file,
                    crop: fileForUpload.crop
               });
               setIsLoading(true);
          }
     }, [fileForUpload]);

     const getFile = (file, crop) => {
          setPreviewAvatar(crop.canvas);
          setFileForUpload({
               file,
               crop: {
                    width: crop.width,
                    height: crop.height,
                    x: crop.x,
                    y: crop.y
               }
          });
     };

     const getDefault = () => defaultAvatar;

     // Picker
     const handleEvent = (e, { startDate, endDate }) => {
          getRange();

          let newStartDate = startDate || moment(start);
          let newEndDate = endDate || moment(end);

          setDates({
               startDate: newStartDate,
               endDate: newEndDate,
          });
     };

     const transformSuggestions = (suggestions) => {
          return (
               suggestions &&
               suggestions.map(
                    ({ firstname, lastname, user_id }) => ({
                         value: getFullName(firstname, lastname),
                         id: user_id,
                    })
               )
          );
     };

     const suggestions = transformSuggestions(membersList);
     const transformEventManagers = () =>
          eventManagers && eventManagers.map(({ value }) => value).join(', ');

     const defaultManager = () => {
          const owner = eventManagers.find(({is_owner}) => is_owner === true);

          return owner ? owner : (eventManagers ? eventManagers[0] : []);
     };

     const defaultManagerName = () => {
          const manager = defaultManager();

          if (manager) {
               return manager.value;
          }

          return '';
     };

     const isEditingValues =
          eventName && eventDate && start && end && eventManagers;

     const checkIsEditing = () => (dangerZone ? isEditingValues : true);

     const removeEventPhoto = () => {
          if (eventId) {
               setIsLoading(true);
               setFullPreview(null);

               requestUserAvatarGenerate({name: form.current.values.eventName});
          } else {
               setAvatar(eventImage);
               setDefaultAvatar(eventImage);
               setFullPreview(null);
          }
     };

     const removeAvatarPreview = () => {
          setPreviewAvatar(null);
          setFileForUpload(null);
          setFullPreview(null);
     };

     const isNewPhotoUploaded = () => {
          if (eventId) {
               return !isPhotoDefault;
          }

          return avatar !== null && avatar !== av;
     };

     return (
          checkIsEditing() && (
               <Formik
                    validationSchema={EVENT.VALIDATION}
                    enableReinitialize
                    onSubmit={dangerZone ? handleEditSubmit : handleSubmit}
                    initialValues={{
                         eventName,
                         eventDate,
                         eventManagers,
                    }}
                    validateOnBlur={false}
                    innerRef={form}
               >
                    {({
                         handleSubmit,
                         handleChange,
                         values,
                         touched,
                         errors,
                         setFieldValue,
                    }) => (
                         <Form noValidate onSubmit={handleSubmit}>
                              <Form.Group as={Row} controlId="eventName">
                                   <Col sm={3}>
                                        <Form.Label>
                                             {t('event.event_name')}
                                        </Form.Label>
                                   </Col>
                                   <Col sm={9}>
                                        <Input
                                             type="text"
                                             field="eventName"
                                             values={values}
                                             placeholder={t(
                                                  'event.event_name_placeholder'
                                             )}
                                             handleChange={handleChange}
                                             touched={touched}
                                             errors={errors}
                                        />
                                        <div className="info-block">
                                             {t('event.keep_it_short')} "Global
                                             Startup Fair"
                                        </div>
                                   </Col>
                              </Form.Group>
                              <Form.Group as={Row} controlId="eventDate">
                                   <Col sm={3}>
                                        <Form.Label>
                                             {t('event.event_date')}
                                        </Form.Label>
                                   </Col>
                                   <Col sm={9}>
                                        <RangePicker
                                             field="eventDate"
                                             value={values['eventDate']}
                                             minDate={moment()}
                                             startDate={
                                                  dates.startDate ||
                                                  moment(start)
                                             }
                                             endDate={
                                                  dates.endDate || moment(end)
                                             }
                                             handleEvent={handleEvent}
                                             handleApply={(
                                                  e,
                                                  { startDate, endDate }
                                             ) => {
                                                  setFieldValue(
                                                       'eventDate',
                                                       formatDateEmpty({
                                                            startDate,
                                                            endDate,
                                                       })
                                                  );
                                             }}
                                             touched={touched}
                                             errors={errors}
                                        />
                                   </Col>
                              </Form.Group>
                              <div className="relative">
                                   <Form.Group
                                        as={Row}
                                        controlId="eventManagers"
                                   >
                                        <Col sm={3}>
                                             <Form.Label>
                                                  {t('event.event_managers')}
                                             </Form.Label>
                                        </Col>
                                        <Col sm={9}>
                                             {membersList && (
                                                  <TagField
                                                       withIds
                                                       readOnlyValues={[
                                                            defaultManagerName(),
                                                       ]}
                                                       placeholder={t(
                                                            'event.add_managers'
                                                       )}
                                                       value={
                                                            dangerZone
                                                                 ? transformEventManagers(
                                                                        values.eventManagers
                                                                   )
                                                                 : defaultManagerName()
                                                       }
                                                       suggestions={suggestions}
                                                       onChange={
                                                            handleTagsChange
                                                       }
                                                  />
                                             )}
                                             <div className="info-block">
                                                  {t('event.select_managers')}
                                             </div>
                                        </Col>
                                   </Form.Group>
                              </div>
                              <Form.Group
                                   as={Row}
                                   controlId="eventImage"
                                   className="file_upload"
                              >
                                   <Col sm={3}>
                                        <Form.Label>
                                             {t('event.event_image')}
                                        </Form.Label>
                                   </Col>
                                   <Col sm={9}>
                                        <PictureUploader
                                            preview={previewAvatar}
                                            picture={avatar}
                                            getFile={getFile}
                                            getDefault={getDefault}
                                            isLoading={isLoading}
                                            isDefault={!isNewPhotoUploaded()}
                                            handleRemovePicture={removeEventPhoto}
                                            handleRemovePreview={removeAvatarPreview}
                                            accept={MIME_PHOTOS.join(',')}
                                            fileLimit={AVATAR_FILE_LIMIT}
                                            fullPreview={fullPreview}
                                        />
                                   </Col>
                              </Form.Group>
                              <hr className="body-divider" />
                              {dangerZone && (currentUser.organizations[id] === 'admin' || currentUser.events[eventId] === true) && (
                                   <>
                                        <Form.Group
                                             as={Row}
                                             controlId="dangerZone"
                                             className="danger-zone"
                                        >
                                             <Col sm={3}>
                                                  <Form.Label>
                                                       {t(
                                                            'buttons.danger_zone'
                                                       )}
                                                  </Form.Label>
                                             </Col>
                                             <Col sm={9}>
                                                  <Button
                                                       variant="danger"
                                                       onClick={() =>
                                                            handleEventCancel()
                                                       }
                                                  >
                                                       {t('event.cancel_event')}
                                                  </Button>
                                                  <div className="info-block">
                                                       {t(
                                                            'alerts.danger.cannot_be_undone'
                                                       )}
                                                  </div>
                                             </Col>
                                        </Form.Group>
                                        <hr className="body-divider" />
                                   </>
                              )}

                              <div className="form-actions">
                                   <Link to={`/organization/${id}/events`}>
                                        <Button
                                             variant="default"
                                        >
                                             {t('buttons.back')}
                                        </Button>
                                   </Link>
                                   <Button
                                        variant="success"
                                        size="sm"
                                        type="submit"
                                        disabled={isLoading}
                                   >
                                        {btnText}
                                   </Button>
                              </div>
                         </Form>
                    )}
               </Formik>
          )
     );
};

export default connect(mapStateToProps, mapDispatchToProps)(EventForm);
