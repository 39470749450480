import styled from 'styled-components';
import {
     COLORS,
     FORM_PLACEHOLDER,
     SIZE,
} from '../../../constants/style.constants';

export const CustomTimePickerWrapper = styled.div`
     position: relative;

     .timepicker-widget {
          background-color: #fff;
          /* min-width: 196px; */
          display: flex;
          flex-direction: row;
          justify-content: center;
          border: none !important;
          box-shadow: 0px 0px 20px 0px rgba(82, 63, 105, 0.15);
          border-radius: 4px;
          padding: 4px;
          position: absolute;
          top: 45px;
          z-index: 1;
          .timepicker-item {
               display: flex;
               flex-direction: column;
               justify-items: center;
               width: 54px;

               span,
               input {
                    text-align: center;
               }
          }

          .timepicker-separator {
               font-size: ${SIZE.NORMAL};
               color: ${COLORS.DARK_BLUE};
               align-self: center;
          }

          .timepicker-input {
               border: none;
               font-size: ${SIZE.NORMAL};
               color: ${COLORS.DARK_BLUE};
               padding: 2px;

               &:focus {
                    outline: none;
               }
          }
     }
     .form-control {
          background-color: transparent;
          color: ${COLORS.TEXT_GREY} !important;
          
          &::placeholder {
               ${FORM_PLACEHOLDER};
               color: ${COLORS.PLACEHOLDER_GREY} !important;
          }
          
          &:hover {
               background-color: transparent !important;
               color: ${COLORS.TEXT_GREY} !important;
          }
     }

     .action-button {
          border-radius: 4px;
          padding: 8px 0;

          &:hover {
               background-color: ${COLORS.PALE_BLUE};
          }

          i {
               color: ${COLORS.TEXT_GREY};
          }
     }
`;
