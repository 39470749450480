import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Alert from '../../../common/alert';
import illustration from '../../../../assets/images/illustration-server.svg';
import Breadcrumb from '../../../common/breadcrumb';
import { ModuleDownWrapper } from './ModuleDown.style';

import i18n from 'i18next';
const links = [{ to: '/dashboard', text: i18n.t('breadcrumb.dashboard') }];

export const ModuleDown = memo(() => {
     const { t } = useTranslation();

     const handleReload = () => window.location.reload();

     return (
          <ModuleDownWrapper>
               <div className="content--border">
                    <div className="container-standard">
                         <Link to="/dashboard">
                              <Button className="btn-themed-blue btn-navigational">
                                   {t('breadcrumb.dashboard')}
                              </Button>
                         </Link>
                    </div>
               </div>
               <div className="container-standard">
                    <Breadcrumb
                         title={t('breadcrumb.dashboard')}
                         links={links}
                    />
               </div>
               <div className="content container-standard">
                    <div>
                         <Alert>{t('alerts.primary.unexpected_error')}</Alert>
                         <Button variant="success" onClick={handleReload}>
                              {t('module_down.button')}
                         </Button>
                    </div>
                    <img
                         src={illustration}
                         alt={t('module_down.illustration')}
                    />
               </div>
          </ModuleDownWrapper>
     );
});
