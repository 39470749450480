import React, { memo, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import i18n from 'i18next';
import {
     string as yupString,
     object as yupObject,
     mixed as yupMixed,
} from 'yup';
import { Form, Row, Col, Dropdown } from 'react-bootstrap';
import TagField from '../../../common/tagfield';
import CustomToggle from '../../../common/custom-toggle';
import ChevronDownIcon from '../../../../assets/icons/metronic/chevron-down-icon';
import { capitalize } from '../helpers';
import FormBuilderWrapper from '../FormBuilder.style';
import {isEmpty} from "underscore";

export const FormSettings = memo(
     ({
          readonly,
          form,
          handleFormChange,
          handleSetAccessLevel,
          mandatoryLanguage,
          setMandatoryLanguage,
          currentLanguage,
          multiLanguage,
          multiLanguageSelected,
          handleSetCurrentLanguage,
          toggleMultiLanguage,
          handleMultiLanguageChange,
          languages,
          isMandatoryLanguageActive,
          confirmationMessage,
          handleConfirmationMessage
     }) => {
          const [state, setState] = useState();
          const [innerState, setInnerState] = useState({
               languageOptions: {},
               suggestions: {},
               accessLevelOptions: [
                    { value: i18n.t('form_builder.select_access_level'), key: '' },
                    { value: i18n.t('dashboard.organization'), key: 'organization' },
                    { value: i18n.t('form_builder.event_access_level'), key: 'event' },
                    // { value: i18n.t('form_builder.me') },
               ],
               mandatoryLanguage: null,
               multiLanguage: false,
               currentLanguage: null,
               currentAccessLevel:
                    (form.accessLevel &&
                         capitalize({ value: form.accessLevel, split: '' })) ||
                    i18n.t('form_builder.select_access_level'),
               reload: false,
          });

          const { state: locationState } = useLocation();

          const transformLanguageValues = () => {
               const values = [];
               multiLanguageSelected && innerState.languageOptions &&
                    multiLanguageSelected.forEach((id) => {
                         values.push(
                              innerState.languageOptions.find(
                                   (lang) => lang.id === id
                              ).name
                         );
                    });

               return values.join(', ');
          };

          const schema = yupObject().shape({
               formName: yupString().required(),
               accessLevel: yupMixed()
                    .oneOf(['organization', 'event'])
                    .required(),
          });

          useEffect(() => {

               return function cleanup() {
                    setState(null);
                    setInnerState({
                         languageOptions: {},
                         suggestions: {},
                         accessLevelOptions: [
                              { value: i18n.t('form_builder.select_access_level'), key: '' },
                              { value: i18n.t('dashboard.organization'), key: 'organization' },
                              { value: i18n.t('form_builder.event_access_level'), key: 'event' },
                              // { value: i18n.t('form_builder.me') },
                         ],
                         mandatoryLanguage: null,
                         multiLanguage: false,
                         currentLanguage: null,
                         currentAccessLevel:
                             (form.accessLevel &&
                                 capitalize({ value: form.accessLevel, split: '' })) ||
                             i18n.t('form_builder.select_access_level'),
                         reload: false,
                    });
               };
          }, []);

          useEffect(() => {
               setState(form);
          }, [form]);

          const validateForm = () => {
               const { formName, accessLevel } = form;

               return schema
                    .validate(
                         {
                              formName,
                              accessLevel,
                         },
                         { abortEarly: false }
                    )
                    .catch(({ errors }) => setState({ ...state, errors }));
          };

          useEffect(() => {
               if (locationState) {
                    validateForm();
               }
          }, [locationState]);

          useEffect(() => {
               if (languages.length > 0) {
                    const mandatoryLang = languages.find(
                         (lang) => lang.id === mandatoryLanguage
                    );
                    const currentLang = languages.find(
                         (lang) => lang.id === currentLanguage
                    );

                    const mulSelected = languages.filter((lang) => multiLanguageSelected.includes(lang.id));

                    setInnerState({
                         ...innerState,
                         mandatoryLanguage: mandatoryLang,
                         currentLanguage: currentLang,
                         languageOptions: languages,
                         suggestions: languages.map((language) => {
                              return { id: language.id, value: language.name };
                         }),
                         multiLanguage: multiLanguage,
                         multiLanguageSelected: mulSelected
                    });
               }
          }, [
               mandatoryLanguage,
               currentLanguage,
               languages,
               multiLanguageSelected,
               multiLanguage,
          ]);

          const handleMultiLanguage = () => {
               setInnerState({
                    ...innerState,
                    multiLanguage: !innerState.multiLanguage,
               });

               toggleMultiLanguage();
          };

          const handleLanguageChange = (value) => {
               const lang = languages.find((lang) => lang.id === value);
               setInnerState({
                    ...innerState,
                    currentLanguage: lang,
                    mandatoryLanguage: lang,
               });

               handleSetCurrentLanguage(lang.id);
               setMandatoryLanguage(lang.id);
          };

          const handleChange = (name, { target: { value } }) => {
               handleFormChange({ name, value });
          };

          const handleAccessLevelChange = (value) => {
               setInnerState({
                    ...innerState,
                    currentAccessLevel: value,
               });

               handleSetAccessLevel(value);
          };

          const handleMessageChange = (value) => {
               setState({
                    ...form,
                    message: value
               });
               handleConfirmationMessage(value);
          };

          const fieldErrors = ({ fieldName, message }) => {
               const err =
                    state.errors &&
                    state.errors.find((err) => err.includes(fieldName));

               if (err) {
                    return message;
               }
          };

          const handleLanguageTag = ({ target: { value } }) => {
               const values = value.split(',').map((i) => Number(i));
               handleMultiLanguageChange(values);
          };

          const getCurrentAccessLevel = key => {
               if (key === 'organization') {

                    return i18n.t('dashboard.organization');
               }

               if (key === 'event') {

                    return i18n.t('form_builder.event_access_level');
               }

               return i18n.t('form_builder.select_access_level');
          };

          return (
               state && (
                    <FormBuilderWrapper>
                         {isMandatoryLanguageActive() && (
                             <>
                                  <Form.Group
                                      as={Col}
                                      controlId="name"
                                      className="mb-20"
                                  >
                                       <Row xs={3}>
                                            <Form.Label>
                                                 {i18n.t('form_builder.form_name')}
                                            </Form.Label>
                                       </Row>
                                       <Row xs={9}>
                                            <Form.Control
                                                readOnly={readonly}
                                                isInvalid={
                                                     !!fieldErrors({
                                                          fieldName: 'formName',
                                                          message: i18n.t(
                                                              'form_builder.form_name_required'
                                                          ),
                                                     })
                                                }
                                                className={`form-row-wrap ${state && isEmpty(state.formName) ? 'is-warning' : ''}`}
                                                type="text"
                                                field="name"
                                                placeholder={i18n.t(
                                                    'form_builder.form_name_placeholder'
                                                )}
                                                value={state.formName}
                                                onChange={(e) =>
                                                    handleChange('formName', e)
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                 {fieldErrors({
                                                      fieldName: 'formName',
                                                      message: i18n.t(
                                                          'form_builder.form_name_required'
                                                      ),
                                                 })}
                                            </Form.Control.Feedback>
                                       </Row>
                                  </Form.Group>
                                  {!readonly && (
                                      <Form.Group
                                          controlId="accessLevel"
                                          className="mb-20"
                                      >
                                           <Form.Label>
                                                {i18n.t('form_builder.access_level')}
                                           </Form.Label>
                                           <div className="input-group zi5">
                                                <Dropdown className={
                                                     (fieldErrors({
                                                          fieldName: 'accessLevel',
                                                          message: i18n.t('form_builder.form_access_level_required') })
                                                         ? 'is-invalid'
                                                         : ''
                                                     )
                                                }>
                                                     <Dropdown.Toggle
                                                         className={`select-dropdown-toggle form-control`}
                                                         isWarning={isEmpty(innerState.currentAccessLevel) || innerState.currentAccessLevel === '' || innerState.currentAccessLevel === undefined ? 'is-warning' : ''}
                                                         as={CustomToggle}
                                                     >
                                                          {getCurrentAccessLevel(innerState.currentAccessLevel)}
                                                          <span className="suffix">
                                                            <ChevronDownIcon />
                                                          </span>
                                                     </Dropdown.Toggle>
                                                     <Dropdown.Menu>
                                                          {innerState.accessLevelOptions.map(
                                                              ({ value, key }) => (
                                                                  <Dropdown.Item
                                                                      key={key}
                                                                      onClick={() =>
                                                                          handleAccessLevelChange(
                                                                              key
                                                                          )
                                                                      }
                                                                  >
                                                                       {value}
                                                                  </Dropdown.Item>
                                                              )
                                                          )}
                                                     </Dropdown.Menu>
                                                </Dropdown>
                                                <div className="invalid-feedback">
                                                     {i18n.t('form_builder.form_access_level_required')}
                                                </div>
                                           </div>
                                      </Form.Group>
                                  )}
                                  {multiLanguage ? (
                                      <>
                                           <Form.Group
                                               as={Col}
                                               controlId="mandatory-language"
                                           >
                                                <Row>
                                                     <Form.Label>
                                                          {i18n.t(
                                                              'form_builder.mandatory_language'
                                                          )}
                                                     </Form.Label>
                                                </Row>
                                                <Row>
                                                     <div className="input-group zi4">
                                                          <Dropdown>
                                                               <Dropdown.Toggle
                                                                   className="select-dropdown"
                                                                   as={
                                                                        CustomToggle
                                                                   }
                                                               >
                                                                    {
                                                                         innerState.mandatoryLanguage && innerState.mandatoryLanguage.name
                                                                    }
                                                               </Dropdown.Toggle>
                                                               <Dropdown.Menu>
                                                                    {innerState.multiLanguageSelected.map(
                                                                        (lang) => (
                                                                            <Dropdown.Item
                                                                                key={
                                                                                     lang.id
                                                                                }
                                                                                onClick={() => {
                                                                                     setInnerState(
                                                                                         {
                                                                                              ...innerState,
                                                                                              mandatoryLanguage: lang,
                                                                                         }
                                                                                     );
                                                                                     setMandatoryLanguage(lang.id);
                                                                                }}
                                                                            >
                                                                                 {
                                                                                      lang.name
                                                                                 }
                                                                            </Dropdown.Item>
                                                                        )
                                                                    )}
                                                               </Dropdown.Menu>
                                                          </Dropdown>
                                                          <span className="suffix">
                                                                <ChevronDownIcon />
                                                           </span>
                                                     </div>
                                                </Row>
                                           </Form.Group>
                                           <div className="mb-20">
                                                <div
                                                    className="form-group col"
                                                    style={{
                                                         marginBottom: '0',
                                                    }}
                                                >
                                                     <Row>
                                                          <Form.Label>
                                                               {i18n.t(
                                                                   'form_builder.languages'
                                                               )}
                                                          </Form.Label>
                                                     </Row>
                                                </div>
                                                <div className="tags-container">
                                                     <TagField
                                                         withIds={true}
                                                         suggestions={
                                                              innerState.suggestions
                                                         }
                                                         value={transformLanguageValues()}
                                                         readOnlyValues={[
                                                              innerState.mandatoryLanguage && innerState.mandatoryLanguage.name,
                                                         ]}
                                                         onChange={handleLanguageTag}
                                                     />
                                                </div>
                                           </div>
                                      </>
                                  ) : (
                                      <Form.Group
                                          as={Col}
                                          controlId="language"
                                          className="mb-20"
                                      >
                                           <Row xs={3}>
                                                <Form.Label>
                                                     {i18n.t('form_builder.language')}
                                                </Form.Label>
                                           </Row>
                                           <Row xs={9}>
                                                <div className="input-group zi4">
                                                     <Dropdown
                                                         style={{
                                                              pointerEvents: readonly
                                                                  ? 'none'
                                                                  : 'auto',
                                                         }}
                                                     >
                                                          <Dropdown.Toggle
                                                              className="select-dropdown-toggle"
                                                              as={CustomToggle}
                                                          >
                                                               {
                                                                    innerState.currentLanguage && innerState.currentLanguage.name
                                                               }
                                                               <span className="suffix">
                                                            <ChevronDownIcon />
                                                       </span>
                                                          </Dropdown.Toggle>
                                                          <Dropdown.Menu>
                                                               {innerState
                                                                   .languageOptions
                                                                   .length > 0 &&
                                                               innerState.languageOptions.map(
                                                                   ({
                                                                         id,
                                                                         name,
                                                                    }) => (
                                                                       <Dropdown.Item
                                                                           key={
                                                                                name
                                                                           }
                                                                           onClick={() =>
                                                                               handleLanguageChange(
                                                                                   id
                                                                               )
                                                                           }
                                                                       >
                                                                            {
                                                                                 name
                                                                            }
                                                                       </Dropdown.Item>
                                                                   )
                                                               )}
                                                          </Dropdown.Menu>
                                                     </Dropdown>
                                                </div>
                                           </Row>
                                      </Form.Group>
                                  )}
                                  {!readonly && (
                                      <Form.Group
                                          as={Row}
                                          xs={3}
                                          controlId="multiLanguage"
                                      >
                                           <Col sm={9}>
                                                <Form.Label
                                                    className="checkbox-solid-blue"
                                                    onClick={handleMultiLanguage}
                                                >
                                                     <input
                                                         type="checkbox"
                                                         checked={multiLanguage}
                                                         onChange={handleMultiLanguage}
                                                     />
                                                     <span />
                                                     <div className="label-text">
                                                          {i18n.t(
                                                              'form_builder.multi_language_form'
                                                          )}
                                                     </div>
                                                </Form.Label>
                                           </Col>
                                      </Form.Group>
                                  )}
                             </>
                         )}
                         <Form.Group
                              controlId="confirmationMessage"
                              as={Col}
                              className="mb-20"
                         >
                              <Row xs={2}>
                                   <Form.Label>
                                        {i18n.t(
                                             'form_builder.confirmation_message'
                                        )}
                                   </Form.Label>
                              </Row>
                              <Row xs={9}>
                                   <Form.Control
                                        as="textarea"
                                        className={`textarea ${state && isEmpty(confirmationMessage.message) ? 'is-warning' : ''}`}
                                        rows="6"
                                        field="confirmationMessage"
                                        placeholder={i18n.t(
                                             'form_builder.confirmation_message_placeholder'
                                        )}
                                        value={confirmationMessage.message}
                                        onChange={(e) =>
                                            handleMessageChange(e.target.value)
                                        }
                                   />
                                   <Form.Control.Feedback type="invalid">
                                        {fieldErrors({
                                             fieldName: 'confirmationMessage',
                                             message: i18n.t(
                                                  'form_builder.confirmation_message_required'
                                             ),
                                        })}
                                   </Form.Control.Feedback>
                              </Row>
                         </Form.Group>
                    </FormBuilderWrapper>
               )
          );
     }
);
