import styled from 'styled-components';
import { COLORS, FONT, SIZE } from '../../../constants/style.constants';
import { appScrollbar } from '../../../utils/style.utils';

const FormBuilderWrapper = styled.div`
     .checkbox-group {
          label {
               margin-bottom: 0;
               padding-bottom: 0;
          }
     }

     .radioinputs-group {
          label {
               margin: 0;
               padding: 0;
          }
     }

     .show,
     .dropdown {
          box-shadow: 0 0 0 2px #fff;
          width: 100%;

          .dropdown-item {
               color: ${COLORS.DARK_BLUE};
               font-size: ${SIZE.NORMAL};

               &:active {
                    color: ${COLORS.DARK_BLUE};
                    background-color: ${COLORS.PALE_BLUE};
               }
          }
     }

     .select-dropdown-toggle {
          display: flex;
          align-items: center;
          background-color: transparent;
          text-decoration: none;
          border-radius: 4px;
          border: 1px solid #ced4da;
          width: 100%;
     }

     .form-label {
          cursor: pointer;
          display: flex;
          position: relative;
          transition: all 0.15s ease;
          line-height: 1.3;

          &:not(:first-child) {
               margin-left: 26px;
          }

          .circle-outer {
               height: 18px;
               width: 18px;
               margin-right: 13px;
               background-color: ${COLORS.LIGHTER_BLUE};
               border-radius: 50%;
               z-index: 1;

               .circle-inner {
                    position: absolute;
                    background-color: ${COLORS.LIGHTER_BLUE};
                    border-radius: 50%;
                    height: 6px;
                    width: 6px;
                    top: 6px;
                    left: 6px;
               }
          }

          input:checked ~ .circle-outer {
               position: relative;
               background-color: ${COLORS.BLUISH};

               .circle-inner {
                    background-color: ${COLORS.WHITE};
               }
          }
     }

     input[type='radio'] {
          position: absolute;
          opacity: 0;
          z-index: -1;
     }

     .card-body {
          padding: 30px 25px !important;
          transition: all 1s ease;

          &.--preview {
               max-height: 555px;
          }

          &.toolbar {
               max-height: 555px !important;
               overflow-y: auto;
               transition: all 0.15 ease;

               ${appScrollbar}
          }
     }

     .information-box {
          text-align: center;
          width: 100%;
          background-color: rgba(24, 112, 244, 0.12);
          border-radius: 4px;
          color: ${COLORS.BLUE};
          font-weight: ${FONT.MEDIUM};
          font-size: ${SIZE.NORMAL};
     }
     
     .form-builder-grid {
        .bottom-navigation {
            position: sticky;
            position: --webkit-sticky;
            bottom: 0px;
            z-index: 9;
            background-color: ${COLORS.WHITE};
            padding-bottom: 20px;
        }
        
        .form-left-side {
            position: sticky;
            position: --webkit-sticky;
            top: 10px;
            z-index: 3;
        }
     }

     .form-builder-zone {
          min-height: 500px !important;
          transition: all 0.15 ease;

          .title {
            margin-bottom: 0px !important;
          }
          
          .is-warning {
                border: none;
          }
     }

     .nav-link {
          font-weight: ${FONT.MEDIUM};
          font-size: ${SIZE.NORMAL};
          line-height: 18px;
          padding: 21px 0;
          color: ${COLORS.DARK_BLUE};

          &.active {
               border-bottom: 2px solid ${COLORS.BLUE};
               color: ${COLORS.BLUE};
          }

          &:focus {
               outline: none;
          }
          
          &:hover {
               color: ${COLORS.BLUE};
          }
          
          &.warning {
            color: ${COLORS.YELLOW};
            
            &.active {
                border-bottom: 2px solid ${COLORS.YELLOW};
            }
          }
     }

     .nav-link,
     .nav-tabs {
          border: none;
     }
     .card-header-tabs {
          margin: 0;
          border-bottom: 1px solid ${COLORS.LIGHTER_BLUE};
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          padding: 0 25px;
     }

     .elements-grid {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 10px;
          grid-row-gap: 10px;

          .elements-grid--item {
               border: 1px solid ${COLORS.LIGHTER_BLUE};
               border-radius: 3px;
               color: ${COLORS.TEXT_GREY};
               display: flex;
               align-items: center;
               padding: 15px;

               &:active {
                    background-color: rgba(24, 112, 244, 0.12);
                    border: 1px solid transparent;
                    color: ${COLORS.BLUE};
               }

               .icon {
                    margin-right: 15px;
                    line-height: 18px;
               }

               .text {
                    font-size: ${SIZE.NORMAL};
                    line-height: 18px;
               }
          }
     }

     /* Form builder elements styling */
     .title,
     .paragraph {
          word-break: break-word;
     }

     .title {
          font-weight: ${FONT.MEDIUM};
          font-size: ${SIZE.BIG};
          line-height: 22px;
          color: ${COLORS.DARK_BLUE};
     }

     .paragraph {
          font-weight: ${FONT.REGULAR};
          font-size: ${SIZE.NORMAL};
          line-height: 21px;
          color: ${COLORS.DARK_BLUE};
     }

     /* Checkbox Blue */
     .label-text {
          padding-left: 12px;
          word-break: break-word;
     }

     .checkbox-solid-blue {
          display: flex;
          align-content: center;
          cursor: pointer;
          position: relative;
          padding-left: 20px;
          transition: all 0.15s ease;
          line-height: 1.3;

          .label {
               margin: 0 12px;
          }

          &.form-label {
               margin-bottom: 0 !important;
          }

          span {
               background-color: ${COLORS.LIGHTER_BLUE};
               border-radius: 2px;
               position: absolute;
               top: 1px;
               left: 0;
               height: 18px;
               width: 18px;

               &:after {
                    display: none;
               }
          }

          input[type='checkbox'] {
               position: absolute;
               transform: scale(1.5);
               top: 3px;
               left: 0;
               z-index: 1;
               opacity: 0;
          }

          input[type='checkbox']:checked ~ span {
               background-color: ${COLORS.BLUE};

               &:after {
                    content: '';
                    position: absolute;
                    display: none;
                    top: 50%;
                    left: 50%;
                    margin-left: -2px;
                    margin-top: -6px;
                    width: 5px;
                    height: 10px;
                    border-width: 0 2px 2px 0 !important;
                    transform: rotate(45deg);
                    border: solid ${COLORS.WHITE};
                    display: block;
               }
          }
     }

     .radio-group {
          transition: all 0.15s ease;
          display: flex;
          align-items: center;
          line-height: 1;

          .form-label {
               cursor: pointer;
               display: flex;
               position: relative;
               margin: 0 !important;

               &:not(:first-child) {
                    margin-left: 26px !important;
               }

               .circle-outer {
                    height: 18px;
                    width: 18px;
                    margin-right: 13px;
                    background-color: ${COLORS.LIGHTER_BLUE};
                    border-radius: 50%;

                    .circle-inner {
                         position: absolute;
                         background-color: ${COLORS.LIGHTER_BLUE};
                         border-radius: 50%;
                         height: 6px;
                         width: 6px;
                         top: 6px;
                         left: 6px;
                    }
               }

               input:checked ~ .circle-outer {
                    position: relative;
                    background-color: ${COLORS.BLUISH};

                    .circle-inner {
                         background-color: ${COLORS.WHITE};
                    }
               }
          }

          input[type='radio'] {
               position: absolute;
               opacity: 0;
               z-index: -1;
          }
     }

     .mb-20 {
          margin-bottom: 20px;
          .radio-group {
               display: flex;
               flex-direction: column;
          }
     }

     .las,
     .la {
          color: ${COLORS.TEXT_GREY};
     }

     label {
          font-weight: ${FONT.REGULAR};
          font-size: ${SIZE.NORMAL};
          line-height: 18px;
          color: ${COLORS.DARK_BLUE};
     }

     .form-control {
          background-color: transparent;
          border-radius: 4px !important;
          padding: 12px 42px 12px 15px !important;
          min-height: 41px;
          font-weight: ${FONT.REGULAR} !important;
          color: ${COLORS.TEXT_GREY} !important;
          font-size: ${SIZE.NORMAL} !important;
          z-index: 1;

          &::placeholder {
               color: ${COLORS.PLACEHOLDER_GREY} !important;
               font-size: ${SIZE.NORMAL} !important;
               opacity: 1 !important;
          }
     }

     .input-group {
          background-color: transparent;
          /* z-index: 1; */

          input,
          .dropdown-item {
               border-radius: 4px !important;
               background-color: transparent;
          }
          
          .is-invalid.dropdown {
               .form-control {
                   border-color: ${COLORS.RED};
               }
               
               .suffix {
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
                    background-repeat: no-repeat;
                    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
                    background-position: center;
                    right: 12px;
               }
               
               .suffix svg {
                    color: transparent;
                    width: 15px;
                    height: 15px;
               }
          }

          .suffix {
               background-color: transparent;
               border-radius: 4px !important;
               display: flex;
               align-items: center;
               position: absolute;
               top: 1px;
               right: 15px;
               height: 39px;
               /* z-index: -1; */

               svg {
                    color: ${COLORS.PLACEHOLDER_GREY};
               }
          }
     }

     .selection {
          border-radius: 3px;
          cursor: pointer;
          display: grid;
          grid-template-columns: calc(100% - 37px) 37px;
          align-items: center;
          justify-content: space-between;
          padding: 12px 20px;
          margin-top: 2px;
          margin-bottom: 2px;

          .info-block {
               word-break: break-word;
               max-width: inherit;
          }

          .info-block-wrapper {
               display: grid;
               grid-template-columns: 195px auto;
          }

          .btn-danger {
               place-self: end;
               align-self: center;
               padding: 7px;
               margin-top: 0;
               margin-left: 12px;
               height: 25px;
               width: 25px;
               display: flex;
               align-content: center;

               background-color: transparent;
               border: 1px solid ${COLORS.LIGHTER_BLUE};
               border-radius: 2px;

               svg {
                    height: 10px;
                    width: 10px;
               }

               &:hover {
                    background-color: ${COLORS.RED};
                    border: 1px solid ${COLORS.RED};
               }

               &:disabled {
                    background-color: transparent;
                    border: 1px solid ${COLORS.LIGHTER_BLUE};
               }
          }
     }

     .element-settings {
          .btn-outline-primary {
               border: 1px solid #e1e4ec;
               border-radius: 4px;
               color: ${COLORS.TEXT_GREY};
               padding: 14px 33px;

               &:hover {
                    color: ${COLORS.WHITE};
                    border: 1px solid ${COLORS.BLUE};
                    background-color: ${COLORS.BLUE};
               }
          }
          input::placeholder,
          textarea::placeholder {
               font-weight: ${FONT.REGULAR};
               font-size: ${SIZE.NORMAL};
               color: ${COLORS.PLACEHOLDER_GREY};
          }

          .setting-divider {
               border-top: 1px dashed ${COLORS.LIGHTER_BLUE};
               margin: 30px 0;
          }
          .choice {
               display: flex;
               flex-direction: row;
               align-items: center;
               justify-content: space-between;

               &:not(:last-child) {
                    margin-bottom: 10px;
               }
               
               .form-label {
                    margin-bottom: 0;
               }

               .minus-button {
                    border: none;
                    background-color: ${COLORS.LIGHTER_BLUE};
                    cursor: pointer;
                    font-size: ${SIZE.TINY};
                    height: 20px;
                    width: 20px;
                    border-radius: 50%;
                    position: relative;

                    &:disabled {
                         opacity: 0.3;
                    }

                    &:hover {
                         background-color: #b9d4fc;
                    }

                    &:hover:after,
                    &:hover:before {
                         background-color: ${COLORS.BLUE};
                    }

                    &:after,
                    &:before {
                         content: '';
                         display: block;
                         background-color: ${COLORS.PLACEHOLDER_GREY};
                         position: absolute;
                         top: 50%;
                         left: 50%;
                         transform: translate(-50%, -50%);
                    }

                    &:after {
                         border-radius: 3px;
                         height: 0.2em;
                         width: 1em;
                    }

                    &:focus {
                         outline: none;
                    }
               }

               .plus-button {
                    border: none;
                    background-color: ${COLORS.LIGHTER_BLUE};
                    font-size: ${SIZE.TINY};
                    height: 20px;
                    width: 20px;
                    border-radius: 50%;
                    position: relative;

                    &:hover {
                         background-color: #b9d4fc;
                    }

                    &:hover:after,
                    &:hover:before {
                         background-color: ${COLORS.BLUE};
                    }

                    &:after,
                    &:before {
                         content: '';
                         display: block;
                         background-color: ${COLORS.PLACEHOLDER_GREY};
                         position: absolute;
                         top: 50%;
                         left: 50%;
                         transform: translate(-50%, -50%);
                    }

                    &:before {
                         border-radius: 3px;
                         height: 1em;
                         width: 0.2em;
                    }

                    &:after {
                         border-radius: 3px;
                         height: 0.2em;
                         width: 1em;
                    }

                    &:focus {
                         outline: none;
                    }
               }

               .form-control {
                    width: 249px;
               }
          }
     }

     .card-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;

          .language-buttons {
               position: absolute;
               right: 25px;

               .btn-outline-primary {
                    border: 1px solid #e1e4ec;
                    border-radius: 3px;
                    color: ${COLORS.TEXT_GREY};
                    padding: 9px 20px;

                    &:hover {
                         color: ${COLORS.WHITE};
                         border: 1px solid ${COLORS.BLUE};
                         background-color: ${COLORS.BLUE};
                    }
                    &:not(:first-child) {
                         margin-left: 7px;
                    }
               }

               .btn-primary {
                    padding: 9px 20px;

                    &:not(:first-child) {
                         margin-left: 7px;
                    }
               }
               .btn-warning {
                    background-color: ${COLORS.YELLOW};
                    border: 1px solid ${COLORS.YELLOW};
                    border-radius: 3px;
                    color: ${COLORS.WHITE};
                    padding: 9px 20px;
                    &:hover {
                         color: ${COLORS.WHITE};
                         border: 1px solid ${COLORS.DARKER_YELLOW};
                         background-color: ${COLORS.DARKER_YELLOW};
                    }
                    &:not(:first-child) {
                         margin-left: 7px;
                    }
               }

               .btn-outline-warning {
                    background-color: ${COLORS.LIGHTER_YELLOW};
                    border: 1px solid ${COLORS.LIGHTER_YELLOW};
                    border-radius: 3px;
                    color: ${COLORS.YELLOW};
                    padding: 9px 20px;
                    &:hover {
                         color: ${COLORS.WHITE};
                         border: 1px solid ${COLORS.YELLOW};
                         background-color: ${COLORS.YELLOW};
                    }
                    &:not(:first-child) {
                         margin-left: 7px;
                    }
               }
          }
     }

     .bottom-navigation {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 30px 0 0;
          border-top: 1px solid ${COLORS.LIGHTER_BLUE};
          .btn-outline-primary {
               border: 1px solid #e1e4ec;
               border-radius: 3px;
               color: ${COLORS.TEXT_GREY};
               padding: 13px 27px;

               &:hover {
                    color: ${COLORS.WHITE};
                    border: 1px solid ${COLORS.BLUE};
                    background-color: ${COLORS.BLUE};
               }
               &:not(:last-child) {
                    margin-right: 15px;
               }
          }
          .btn-success {
               padding: 13px 27px;
          }
     }
     
     .is-warning {
        background-color: ${COLORS.FORM_BUILDER_WARNING} !important;
        border: 1px solid ${COLORS.YELLOW};
        border-radius: 4px;
     }
`;

export default FormBuilderWrapper;
