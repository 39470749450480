import styled from 'styled-components';

const AuthWrapper = styled.section`
     min-height: 100vh;
     display: flex;

     @media screen and (max-width: 1366px) {
          flex-grow: 1;
          min-height: 0 !important;
          margin-bottom: 60px;
     }
`;

export default AuthWrapper;
