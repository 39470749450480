import * as ACTIONS from '../actions';

export const reminderClearMessages = () => ({
     type: ACTIONS.REMINDER_CLEAR_MESSAGES,
});

export const requestEventReminders = (payload) => ({
     type: ACTIONS.EVENT_REMINDERS_REQUEST,
     payload,
});

export const successEventReminders = (payload) => ({
     type: ACTIONS.EVENT_REMINDERS_SUCCESS,
     payload,
});

export const failEventReminders = (error) => ({
     type: ACTIONS.EVENT_REMINDERS_FAILURE,
     error,
});

export const getRemindersPageDetails = (payload) => ({
     type: ACTIONS.GET_REMINDERS_PAGE_DETAILS,
     payload,
});

export const requestCreateReminder = (payload) => ({
     type: ACTIONS.CREATE_REMINDER_REQUEST,
     payload,
});

export const successCreateReminder = (payload) => ({
     type: ACTIONS.CREATE_REMINDER_SUCCESS,
     payload,
});

export const failCreateReminder = (error) => ({
     type: ACTIONS.CREATE_REMINDER_FAILURE,
     error,
});

export const requestSearchReminders = (payload) => ({
     type: ACTIONS.SEARCH_REMINDERS_REQUEST,
     payload,
});

export const successSearchReminders = (payload) => ({
     type: ACTIONS.SEARCH_REMINDERS_SUCCESS,
     payload,
});

export const failSearchReminders = (error) => ({
     type: ACTIONS.SEARCH_REMINDERS_FAILURE,
     error,
});

export const requestReminderDetails = (payload) => ({
     type: ACTIONS.REMINDER_DETAILS_REQUEST,
     payload,
});

export const successReminderDetails = (payload) => ({
     type: ACTIONS.REMINDER_DETAILS_SUCCESS,
     payload,
});

export const failReminderDetails = (error) => ({
     type: ACTIONS.REMINDER_DETAILS_FAILURE,
     error,
});

export const requestCopyReminder = (payload) => ({
     type: ACTIONS.COPY_REMINDER_REQUEST,
     payload,
});

export const successCopyReminder = (payload) => ({
     type: ACTIONS.COPY_REMINDER_SUCCESS,
     payload,
});

export const failCopyReminder = (error) => ({
     type: ACTIONS.COPY_REMINDER_FAILURE,
     error,
});

export const requestRemoveReminder = (payload) => ({
     type: ACTIONS.REMOVE_REMINDER_REQUEST,
     payload,
});

export const successRemoveReminder = (payload) => ({
     type: ACTIONS.REMOVE_REMINDER_SUCCESS,
     payload,
});

export const failRemoveReminder = (error) => ({
     type: ACTIONS.REMOVE_REMINDER_FAILURE,
     error,
});

export const requestPreviewReminder = (payload) => ({
     type: ACTIONS.PREVIEW_REMINDER_REQUEST,
     payload,
});

export const successPreviewReminder = (payload) => ({
     type: ACTIONS.PREVIEW_REMINDER_SUCCESS,
     payload,
});

export const failPreviewReminder = (error) => ({
     type: ACTIONS.PREVIEW_REMINDER_FAILURE,
     error,
});

export const requestReminderTemplates = (payload) => ({
     type: ACTIONS.REMINDER_TEMPLATES_REQUEST,
     payload,
});

export const successReminderTemplates = (payload) => ({
     type: ACTIONS.REMINDER_TEMPLATES_SUCCESS,
     payload,
});

export const failReminderTemplates = (error) => ({
     type: ACTIONS.REMINDER_TEMPLATES_FAILURE,
     error,
});

export const requestCreateReminderTemplate = (payload) => ({
     type: ACTIONS.CREATE_REMINDER_TEMPLATE_REQUEST,
     payload,
});

export const successCreateReminderTemplate = (payload) => ({
     type: ACTIONS.CREATE_REMINDER_TEMPLATE_SUCCESS,
     payload,
});

export const failCreateReminderTemplate = (error) => ({
     type: ACTIONS.CREATE_REMINDER_TEMPLATE_FAILURE,
     error,
});

export const requestRemoveReminderTemplate = (payload) => ({
     type: ACTIONS.REMOVE_REMINDER_TEMPLATE_REQUEST,
     payload,
});

export const successRemoveReminderTemplate = (payload) => ({
     type: ACTIONS.REMOVE_REMINDER_TEMPLATE_SUCCESS,
     payload,
});

export const failRemoveReminderTemplate = (error) => ({
     type: ACTIONS.REMOVE_REMINDER_TEMPLATE_FAILURE,
     error,
});

export const INITIAL_STATE = {
     eventRemindersRequested: false,
     eventRemindersSucceeded: false,
     eventRemindersFailed: false,
     createReminderRequested: false,
     createReminderSucceeded: false,
     createReminderFailed: false,
     searchRemindersRequested: false,
     searchRemindersSucceeded: false,
     searchRemindersFailed: false,
     reminderDetailsRequested: false,
     reminderDetailsSucceeded: false,
     reminderDetailsFailed: false,
     copyReminderRequested: false,
     copyReminderSucceeded: false,
     copyReminderFailed: false,
     removeReminderRequested: false,
     removeReminderSucceeded: false,
     removeReminderFailed: false,
     previewReminderRequestedId: false,
     previewReminderRequested: false,
     previewReminderSucceeded: false,
     previewReminderFailed: false,
     reminderTemplatesRequested: false,
     reminderTemplatesSucceeded: false,
     reminderTemplatesFailed: false,
     createReminderTemplateRequested: false,
     createReminderTemplateSucceeded: false,
     createReminderTemplateFailed: false,
     removeReminderTemplateRequested: false,
     removeReminderTemplateSucceeded: false,
     removeReminderTemplateFailed: false,
     remindersPageDetails: {},
     remindersList: null,
     remindersSearchResult: null,
     reminderDetailsData: null,
     reminderPreview: null,
     reminderTemplatesList: null,
     message: null,
     error: null,
};

export default function reminderReducer(state = INITIAL_STATE, action) {
     switch (action.type) {
          case ACTIONS.REMINDER_CLEAR_MESSAGES:
               return {
                    ...state,
                    message: null,
                    error: null,
               };
          case ACTIONS.EVENT_REMINDERS_REQUEST:
               return {
                    ...state,
                    eventRemindersRequested: true,
                    eventRemindersSucceeded: false,
                    eventRemindersFailed: false,
               };
          case ACTIONS.EVENT_REMINDERS_SUCCESS:
               return {
                    ...state,
                    eventRemindersRequested: false,
                    eventRemindersSucceeded: true,
                    eventRemindersFailed: false,
                    remindersList: action.payload,
                    error: null,
               };
          case ACTIONS.EVENT_REMINDERS_FAILURE:
               return {
                    ...state,
                    eventRemindersRequested: false,
                    eventRemindersSucceeded: false,
                    eventRemindersFailed: true,
                    error: action.error,
                    remindersList: null,
               };
          case ACTIONS.GET_REMINDERS_PAGE_DETAILS:
               return {
                    ...state,
                    remindersPageDetails: action.payload,
               };
          case ACTIONS.CREATE_REMINDER_REQUEST:
               return {
                    ...state,
                    createReminderRequested: true,
                    createReminderSucceeded: false,
                    createReminderFailed: false,
                    message: null,
                    error: null,
               };
          case ACTIONS.CREATE_REMINDER_SUCCESS:
               return {
                    ...state,
                    createReminderRequested: false,
                    createReminderSucceeded: true,
                    createReminderFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.CREATE_REMINDER_FAILURE:
               return {
                    ...state,
                    createReminderRequested: false,
                    createReminderSucceeded: false,
                    createReminderFailed: true,
                    error: action.error,
                    message: null,
               };
          case ACTIONS.SEARCH_REMINDERS_REQUEST:
               return {
                    ...state,
                    searchRemindersRequested: true,
                    searchRemindersSucceeded: false,
                    searchRemindersFailed: false,
                    remindersSearchResult: null,
                    error: null,
               };
          case ACTIONS.SEARCH_REMINDERS_SUCCESS:
               return {
                    ...state,
                    searchRemindersRequested: false,
                    searchRemindersSucceeded: true,
                    searchRemindersFailed: false,
                    remindersSearchResult: action.payload,
                    error: null,
               };
          case ACTIONS.SEARCH_REMINDERS_FAILURE:
               return {
                    ...state,
                    searchRemindersRequested: false,
                    searchRemindersSucceeded: false,
                    searchRemindersFailed: true,
                    error: action.error,
                    remindersSearchResult: null,
               };
          case ACTIONS.REMINDER_DETAILS_REQUEST:
               return {
                    ...state,
                    reminderDetailsRequested: true,
                    reminderDetailsSucceeded: false,
                    reminderDetailsFailed: false,
               };
          case ACTIONS.REMINDER_DETAILS_SUCCESS:
               return {
                    ...state,
                    reminderDetailsRequested: false,
                    reminderDetailsSucceeded: true,
                    reminderDetailsFailed: false,
                    reminderDetailsData: action.payload,
                    error: null,
               };
          case ACTIONS.REMINDER_DETAILS_FAILURE:
               return {
                    ...state,
                    reminderDetailsRequested: false,
                    reminderDetailsSucceeded: false,
                    reminderDetailsFailed: true,
                    error: action.error,
                    reminderDetailsData: null,
               };
          case ACTIONS.COPY_REMINDER_REQUEST:
               return {
                    ...state,
                    copyReminderRequested: true,
                    copyReminderSucceeded: false,
                    copyReminderFailed: false,
                    message: null,
                    error: null,
               };
          case ACTIONS.COPY_REMINDER_SUCCESS:
               return {
                    ...state,
                    copyReminderRequested: false,
                    copyReminderSucceeded: true,
                    copyReminderFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.COPY_REMINDER_FAILURE:
               return {
                    ...state,
                    copyReminderRequested: false,
                    copyReminderSucceeded: false,
                    copyReminderFailed: true,
                    error: action.error,
                    message: null,
               };
          case ACTIONS.REMOVE_REMINDER_REQUEST:
               return {
                    ...state,
                    removeReminderRequested: true,
                    removeReminderSucceeded: false,
                    removeReminderFailed: false,
                    message: null,
                    error: null,
               };
          case ACTIONS.REMOVE_REMINDER_SUCCESS:
               return {
                    ...state,
                    removeReminderRequested: false,
                    removeReminderSucceeded: true,
                    removeReminderFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.REMOVE_REMINDER_FAILURE:
               return {
                    ...state,
                    removeReminderRequested: false,
                    removeReminderSucceeded: false,
                    removeReminderFailed: true,
                    error: action.error,
                    message: null,
               };
          case ACTIONS.PREVIEW_REMINDER_REQUEST:
               return {
                    ...state,
                    previewReminderRequested: true,
                    previewReminderSucceeded: false,
                    previewReminderFailed: false,
                    previewReminderLoading: true,
               };
          case ACTIONS.PREVIEW_REMINDER_SUCCESS:
               return {
                    ...state,
                    previewReminderRequested: false,
                    previewReminderSucceeded: true,
                    previewReminderFailed: false,
                    reminderPreview: action.payload,
                    previewReminderLoading: false,
                    error: null,
               };
          case ACTIONS.PREVIEW_REMINDER_FAILURE:
               return {
                    ...state,
                    previewReminderRequested: false,
                    previewReminderSucceeded: false,
                    previewReminderFailed: true,
                    error: action.error,
                    reminderPreview: null,
               };
          case ACTIONS.REMINDER_TEMPLATES_REQUEST:
               return {
                    ...state,
                    reminderTemplatesRequested: true,
                    reminderTemplatesSucceeded: false,
                    reminderTemplatesFailed: false,
               };
          case ACTIONS.REMINDER_TEMPLATES_SUCCESS:
               return {
                    ...state,
                    reminderTemplatesRequested: false,
                    reminderTemplatesSucceeded: true,
                    reminderTemplatesFailed: false,
                    reminderTemplatesList: action.payload,
                    error: null,
               };
          case ACTIONS.REMINDER_TEMPLATES_FAILURE:
               return {
                    ...state,
                    reminderTemplatesRequested: false,
                    reminderTemplatesSucceeded: false,
                    reminderTemplatesFailed: true,
                    error: action.error,
                    reminderTemplatesList: null,
               };
          case ACTIONS.CREATE_REMINDER_TEMPLATE_REQUEST:
               return {
                    ...state,
                    createReminderTemplateRequested: true,
                    createReminderTemplateSucceeded: false,
                    createReminderTemplateFailed: false,
                    message: null,
                    error: null,
               };
          case ACTIONS.CREATE_REMINDER_TEMPLATE_SUCCESS:
               return {
                    ...state,
                    createReminderTemplateRequested: false,
                    createReminderTemplateSucceeded: true,
                    createReminderTemplateFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.CREATE_REMINDER_TEMPLATE_FAILURE:
               return {
                    ...state,
                    createReminderTemplateRequested: false,
                    createReminderTemplateSucceeded: false,
                    createReminderTemplateFailed: true,
                    error: action.error,
                    message: null,
               };
          case ACTIONS.REMOVE_REMINDER_TEMPLATE_REQUEST:
               return {
                    ...state,
                    removeReminderTemplateRequested: true,
                    removeReminderTemplateSucceeded: false,
                    removeReminderTemplateFailed: false,
                    message: null,
                    error: null,
               };
          case ACTIONS.REMOVE_REMINDER_TEMPLATE_SUCCESS:
               return {
                    ...state,
                    removeReminderTemplateRequested: false,
                    removeReminderTemplateSucceeded: true,
                    removeReminderTemplateFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.REMOVE_REMINDER_TEMPLATE_FAILURE:
               return {
                    ...state,
                    removeReminderTemplateRequested: false,
                    removeReminderTemplateSucceeded: false,
                    removeReminderTemplateFailed: true,
                    error: action.error,
                    message: null,
               };
          default:
               return state;
     }
}
