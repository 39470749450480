/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { formatDate } from '../../../../utils/date.utils';
import OrganizationBlock from '../../../common/organization-block';
import { requestInvitationDetailsBasic } from '../../../../redux/reducers/speakerReducer';
import { requestUserProfile } from '../../../../redux/reducers/userReducer';
import { InfoWrapper } from '../../../auth/auth-styles/RightBlock.style';

const mapStateToProps = (state) => ({
     invitationDetailsBasicData: state.speakerReducer.invitationDetailsBasicData,
     currentUser: state.userReducer.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
     requestInvitationDetailsBasic: (token) => dispatch(requestInvitationDetailsBasic(token)),
     requestUserProfile: () => dispatch(requestUserProfile()),
});

export const _SpeakerFormResend = memo((props) => {
     const {
          invitationDetailsBasicData,
          requestInvitationDetailsBasic,
          requestUserProfile,
          currentUser,
     } = props;
     const { t } = useTranslation();
     const { invitationToken } = useParams();
     const history = useHistory();

     const [invitationDetails, setInvitationDetails] = useState(null);

     useEffect(() => {
          if (invitationToken) {
               requestInvitationDetailsBasic(invitationToken);
               requestUserProfile();
          }
     }, [invitationToken]);

     useEffect(() => {
          if (invitationDetailsBasicData) {
               setInvitationDetails(invitationDetailsBasicData);
          }
     }, [invitationDetailsBasicData]);

     const redirectToForm = () => {
          if (!currentUser) {
               history.replace('/auth/login');
          } else {
               history.replace(`/events/${invitationDetails.event.id}/speaker-form-loggedin/${invitationDetails.speaker_id}`);
          }
     };

     if (!invitationDetails) return <></>;

     if (invitationDetails && invitationDetails.is_connected === false) {
          history.replace(`/speaker-form/${invitationToken}`);

          return <></>;
     }

     return (
          <InfoWrapper>
               <Helmet>
                    <title>
                         {t('avovent')}
                    </title>
               </Helmet>
               <OrganizationBlock
                    picture={invitationDetails.event.photos['180x180']}
                    pictureAltText={t('speaker_resend_form.logo')}
                    title={invitationDetails.event.name}
                    subtitle={formatDate({
                         startDate: invitationDetails.event.start,
                         endDate: invitationDetails.event.end,
                    })}
               >
                    <p>
                         {t('speaker_resend_form.may_already_have_account')}
                         <br />
                         {t('speaker_resend_form.please_login')}
                    </p>
               </OrganizationBlock>
               <Button
                    className="form-actions"
                    variant="primary"
                    onClick={() => redirectToForm()}
               >
                    {t('link_to_account.button')}
               </Button>
          </InfoWrapper>
     );
});

export const SpeakerFormResend = connect(
     mapStateToProps,
     mapDispatchToProps
)(_SpeakerFormResend);
