import React from 'react';
import { useTranslation } from 'react-i18next';
import PolicyLinksWrapper from './PolicyLinks.style';

export const PolicyLinks = () => {
     const { t } = useTranslation();
     return (
          <PolicyLinksWrapper>
               <a href='https://avovent.com/terms-of-service/' className="footer-link">
                    {t('policy_links.terms')}
               </a>
               <a href="https://avovent.com/privacy-policy/" className="footer-link">
                    {t('policy_links.privacy')}
               </a>
               <a href="https://avovent.com/cookie-policy/" className="footer-link">
                    {t('policy_links.cookie')}
               </a>
               <a href="https://avovent.com/sub-processors/" className="footer-link">
                    {t('policy_links.subprocessors')}
               </a>
              <a href="https://avovent.com/data-protection/" className="footer-link">
                  {t('policy_links.data_protection')}
              </a>
          </PolicyLinksWrapper>
     );
};
