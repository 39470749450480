import instance, { getUrl, getToken } from './instance';
import {delay} from "../utils/delay.utils";

export const authenticateUser = async (payload) => {
     try {
          return await instance.post(`${getUrl()}/user/auth/login`, {
               ...payload,
          });
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const logoutUser = async () => {
     try {
          return await instance.get(`${getUrl()}/user/auth/logout`, {
               headers: {
                    common: {
                         Authorization:
                              `Bearer ${getToken()}` || 'Bearer token',
                    },
               },
          });
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const signupUser = async (payload) => {
     try {
          return await instance.post(`${getUrl()}/user/auth/register `, {
               ...payload,
          });
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const forgottenPasswordUser = async (payload) => {
     try {
          return await instance.post(
               `${getUrl()}/user/auth/forgotten/password `,
               {
                    ...payload,
               }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const resetPasswordUser = async (payload) => {
     try {
          return await instance.post(`${getUrl()}/user/auth/forgotten/reset`, {
               ...payload,
          });
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const fetchUserAvatar = async () => {
     let avatar = null;

     while (!avatar) {
          await delay(200);

          const response =  await instance.get(`${getUrl()}/user/auth/who`, {
               headers: {
                    common: {
                         Authorization:
                             `Bearer ${getToken()}` || 'Bearer token',
                    },
               },
          });

          if (response.data.data && response.data.data.avatars) {
               avatar = response.data.data;
          }
     }

     return avatar;
};
