import React from 'react';

function IconTextArea() {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="18"
               height="18"
               fill="none"
               viewBox="0 0 18 18"
          >
               <path
                    fill="currentColor"
                    d="M6 16H4v2h2v-2zM14 16h-2v2h2v-2zM18 16h-2v2h2v-2zM10 16H8v2h2v-2zM18 12h-2v2h2v-2z"
               ></path>
               <path
                    fill="currentColor"
                    d="M0 0v18h2V2h16V0H0zM18 8h-2v2h2V8z"
               ></path>
               <path fill="currentColor" d="M18 4h-2v2h2V4z"></path>
          </svg>
     );
}

export default IconTextArea;
