import React from 'react';

function IconDropdown() {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="18"
               height="18"
               fill="none"
               viewBox="0 0 18 18"
          >
               <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M13.248 6.579a.9.9 0 010 1.269l-3.6 3.6A.9.9 0 019 11.7a.9.9 0 01-.639-.261l-3.6-3.6A.9.9 0 016.039 6.57L9 9.522l2.97-2.952a.9.9 0 011.278.009zM18 2.7v12.6a2.7 2.7 0 01-2.7 2.7H2.7A2.7 2.7 0 010 15.3V2.7A2.7 2.7 0 012.7 0h12.6A2.7 2.7 0 0118 2.7zm-1.8 0a.9.9 0 00-.9-.9H2.7a.9.9 0 00-.9.9v12.6a.9.9 0 00.9.9h12.6a.9.9 0 00.9-.9V2.7z"
                    clipRule="evenodd"
               ></path>
          </svg>
     );
}

export default IconDropdown;
