/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {memo, useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { LoginBlockForm } from '../forms/LoginBlockForm';
import { useTransition } from '../../../hooks';
import { requestUserProfile } from '../../../redux/reducers/userReducer';
import {
     requestDisplayJoinInvitation,
     requestJoinOrganization,
     requestOrganizationDetails
} from '../../../redux/reducers/organizationReducer';
import OrganizationBlock from '../../common/organization-block';

import { InfoWrapper } from '../auth-styles/RightBlock.style';
import toastr from "toastr";
import i18n from "i18next";

export const LoggedInUser = memo(() => {
     const { t } = useTranslation();
     const dispatch = useDispatch();
     const history = useHistory();
     const user = useSelector((state) => state.userReducer.currentUser);
     const organizationInvite = useSelector((state) => state.organizationReducer.organizationInvite);
     const success = useSelector((state) => state.userReducer.userProfileSucceeded);
     const organization = useSelector((state) => state.organizationReducer.organization);
     const { joinToken } = useParams();
     const { transition, toggleTransition } = useTransition();
     const [displayInvitation, setDisplayInvitation] = useState(false);

     const handleJoin = () => {
          dispatch(requestJoinOrganization(joinToken));
     };

     useEffect(() => {
          dispatch(requestUserProfile());
          dispatch(requestDisplayJoinInvitation(joinToken));
     }, [requestUserProfile, requestDisplayJoinInvitation]);

     useEffect(() => {
          if (organizationInvite) {
              dispatch(requestOrganizationDetails(organizationInvite.organization.id));

          }
     }, [organizationInvite]);

     useEffect(() => {
          if (organization) {
               const isAlreadyMember = organization.member_scope;

               if (!(isAlreadyMember.toString() === 'none')) {
                    toastr.success(i18n.t('alerts.success.you_are_already_member_of_organization'));
                    history.push(`/dashboard`);
               } else {
                    setDisplayInvitation(true);
               }
          }
     }, [organization]);

     const renderLoggedInUser = () => (
          <div className={transition ? 'from-hidden' : 'from-visible'}>
               <Helmet>
                    <title>
                         {t('login.header')} | {t('avovent')}
                    </title>
               </Helmet>
               {success && (
                    <InfoWrapper>
                         <OrganizationBlock
                              picture={
                                   organizationInvite &&
                                   organizationInvite.organization.photos[
                                        '180x180'
                                   ]
                              }
                              pictureAltText={t('invitation.avatar')}
                              title={
                                   organizationInvite &&
                                   organizationInvite.organization.name
                              }
                         >
                              <p>
                                   <b>
                                        {organizationInvite &&
                                             `${organizationInvite.user.firstname} ${organizationInvite.user.lastname}`}
                                   </b>{' '}
                                   {t('invitation.invite')}{' '}
                                   <b>
                                        {organizationInvite &&
                                             organizationInvite.organization
                                                  .name}
                                   </b>{' '}
                                   {t('invitation.organization')}
                              </p>
                         </OrganizationBlock>
                         <Button
                              variant="primary"
                              onClick={handleJoin}
                              className="form-actions"
                              style={{marginTop: '27px'}}
                         >
                              {`${t('invitation.join')} ${
                                   (user && user.firstname) || ''
                              } ${(user && user.lastname) || ''}`}
                         </Button>
                         {/* </div> */}
                         <div className="link-block" style={{marginTop: '30px'}}>
                              {t('invitation.not_you')}{' '}
                              <a href="#" onClick={toggleTransition}>
                                   {t('invitation.login_other_account')}
                              </a>
                         </div>
                    </InfoWrapper>
               )}
          </div>
     );
     const renderLogin = () => (
          <div className={transition ? 'to-visible' : 'to-hidden'}>
               <InfoWrapper>
                    <LoginBlockForm />
               </InfoWrapper>
          </div>
     );
     return (
         displayInvitation && (
              <div className="transition-group-wrapper center">
                   {renderLoggedInUser()}
                   {renderLogin()}
              </div>
          )
     );
});
