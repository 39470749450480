import styled from 'styled-components';
import { Badge } from 'react-bootstrap';
import { COLORS, FONT, SIZE } from '../../../constants/style.constants';

export const StyledBadge = styled(Badge)`
     padding: 10px 12px;
     background-color: transparent;
     border-radius: 2px;
     font-weight: ${FONT.BOLD};
     font-size: ${SIZE.MICRO};
     line-height: 14px;
     text-transform: uppercase;

     &.badge-success {
          color: ${COLORS.GREEN};
          border: 1px solid ${COLORS.GREEN};
     }

     &.badge-danger {
          color: ${COLORS.RED};
          border: 1px solid ${COLORS.RED};
     }

     &.badge-warning {
          color: ${COLORS.YELLOW};
          border: 1px solid ${COLORS.YELLOW};
     }
`;
