import React, { memo, lazy, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import Alert from '../../../common/alert';
import Breadcrumb from '../../../common/breadcrumb';
import { ListItem } from '../../../common/collapsible-list/CollapsibleList';
import illustration from '../../../../assets/images/illustration-speakers.svg';
import { SettingsImage } from '../../../../assets/icons/metronic/settings-image';
import SpeakersIcon from '../../../../assets/icons/metronic/speakers-icon';
import { GroupChatImage } from '../../../../assets/icons/metronic/group-chat-image';
import { DotImage } from '../../../../assets/icons/metronic/dot-image';
import { EventDropdown } from '../../Event/sub-components/EventDropdown';
import { OrganizationDropdown } from '../../Organization/sub-components/OrganizationDropdown';
import { requestOrganizationDetails } from '../../../../redux/reducers/organizationReducer';
import { requestEventDetails } from '../../../../redux/reducers/eventReducer';
import LoadingBar from '../../../common/loading-bar';
import SpeakersWrapper from '../Speakers.style';

const MainDrawer = lazy(() => import('../../../common/drawer'));
const CollapsibleList = lazy(() => import('../../../common/collapsible-list'));

const mapStateToProps = (state) => ({
     router: state.router,
     organization: state.organizationReducer.organization,
     eventData: state.eventReducer.eventData,
});

const mapDispatchToProps = (dispatch) => ({
     requestOrganizationDetails: (id) =>
          dispatch(requestOrganizationDetails(id)),
     requestEventDetails: (payload) => dispatch(requestEventDetails(payload)),
});

const _SpeakersEmpty = memo((props) => {
     const {
          requestOrganizationDetails,
          requestEventDetails,
          organization,
          eventData,
          router: {
               location: { pathname },
          },
     } = props;

     const { t } = useTranslation();
     const { id, eventId } = useParams();

     useEffect(() => {
          requestOrganizationDetails(id);
          requestEventDetails({ organizationId: id, eventId });
     }, []);

     const links = [
          {
               to: `/organization/${id}/settings`,
               text: `${organization && organization.name}`,
          },
          { to: `/organization/${id}/events`, text: i18n.t('events') },
          {
               to: `/organization/${id}/events/${eventId}/event-edit`,
               text: `${eventData && eventData.name}`,
          },
          {
               to: `/organization/${id}/events/${eventId}/speakers`,
               text: i18n.t('event.speakers'),
          },
     ];

     return (
          <SpeakersWrapper>
               <MainDrawer>
                    <ListItem to="/dashboard">
                         {t('breadcrumb.dashboard')}
                    </ListItem>
                    <CollapsibleList
                         listHeader={organization && organization.name}
                         listContent={
                              <>
                                   <ListItem
                                        to={`/organization/${id}/events/${eventId}/event-edit`}
                                   >
                                        <div className="collapsible-list-item">
                                             <GroupChatImage />
                                             <div className="sub-item">
                                                  {t('events')}
                                             </div>
                                        </div>
                                   </ListItem>
                                   <CollapsibleList
                                        listHeader={
                                             <div className="collapsible-list-item">
                                                  <SettingsImage />
                                                  <div className="sub-item">
                                                       {t(
                                                            'organization_settings.organization_settings'
                                                       )}
                                                  </div>
                                             </div>
                                        }
                                        listContent={
                                             <>
                                                  <ListItem
                                                       subItem
                                                       to={`/organization/${id}/settings`}
                                                  >
                                                       <div className="collapsible-list-item">
                                                            <DotImage />
                                                            <div className="sub-item">
                                                                 {t(
                                                                      'organization_settings.organization_settings'
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </ListItem>
                                                  <ListItem
                                                       subItem
                                                       to={`/organization/${id}/members`}
                                                  >
                                                       <div className="collapsible-list-item">
                                                            <DotImage />
                                                            <div className="sub-item">
                                                                 {t(
                                                                      'organization_settings.team_members'
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </ListItem>
                                                  <ListItem
                                                       subItem
                                                       to={`/organization/${id}/email-senders`}
                                                  >
                                                       <div className="collapsible-list-item">
                                                            <DotImage />
                                                            <div className="sub-item">
                                                                 {t(
                                                                      'organization_settings.email_senders'
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </ListItem>
                                             </>
                                        }
                                   />
                              </>
                         }
                    />
                    <CollapsibleList
                         listHeader={eventData && eventData.name}
                         expanded={
                              window.location.pathname ===
                              `/organization/${id}/events/${eventId}/speakers`
                         }
                         listContent={
                              <>
                                   <ListItem
                                        to={`/organization/${id}/events/${eventId}/speakers`}
                                   >
                                        <div className="collapsible-list-item">
                                             <SpeakersIcon />
                                             <div className="sub-item">
                                                  {t('event.speakers')}
                                             </div>
                                        </div>
                                   </ListItem>
                                   <ListItem
                                        to={`/organization/${id}/events/${eventId}/event-edit`}
                                   >
                                        <div className="collapsible-list-item">
                                             <SettingsImage />
                                             <div className="sub-item">
                                                  {t('event.event_settings')}
                                             </div>
                                        </div>
                                   </ListItem>
                              </>
                         }
                    />
               </MainDrawer>
               <div className="content--border">
                    <div
                         className="container-standard"
                         style={{ display: 'flex', alignItems: 'center' }}
                    >
                         <div className="nav">
                              <Link to="/dashboard" className="inactive">
                                   {t('breadcrumb.dashboard')}
                              </Link>
                              <OrganizationDropdown organization={organization} inactive/>
                              <EventDropdown />
                         </div>
                    </div>
                    <LoadingBar finish={!!organization && !!eventData} />
               </div>
               <div className="container-standard">
                    <Breadcrumb title={t('event.speakers')} links={links} />
               </div>
               <div className="content container-standard">
                    <div className="actions">
                         <Alert>{t('alerts.primary.speakers_empty')}</Alert>
                         <Link
                              to={`/organization/${id}/events/${eventId}/invite-speakers`}
                         >
                              <Button variant="success">
                                   {t('speakers.invite_speakers')}
                              </Button>
                         </Link>
                    </div>
                    <img
                         src={illustration}
                         alt={t('speakers.illustration_speakers')}
                    />
               </div>
          </SpeakersWrapper>
     );
});

export const SpeakersEmpty = connect(
     mapStateToProps,
     mapDispatchToProps
)(_SpeakersEmpty);
