import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {SignUpForm} from "../../main/UserSettings/forms/SignUpForm";
import {requestUserProfile} from "../../../redux/reducers/userReducer";
import toastr from "toastr";
import i18n from "i18next";
import { useTransition } from '../../../hooks';
import { CreateUserOrganizationForm } from './CreateUserOrganizationForm';

const mapStateToProps = (state) => ({
     auth: state.authReducer,
     signupSucceeded: state.authReducer.signupSucceeded,
     router: state.router,
     user: state.userReducer.currentUser,
     userProfileRequested: state.userReducer.userProfileRequested,
     userProfileFailed: state.userReducer.userProfileFailed,
     languages: state.userReducer.languages,
});

const mapDispatchToProps = (dispatch) => ({
     requestUserProfile: () => dispatch(requestUserProfile()),
});

export const _Register = (props) => {
     const {
          user,
          requestUserProfile,
          userProfileRequested,
          signupSucceeded,
          languages
     } = props;

     const history = useHistory();
     const { code } = useParams();
     const [ tierId, setTierId ] = useState(null);
     const { transition, toggleTransition } = useTransition();

     const tiers = [
          { id: 1, code: '4pnHpGpe' },
          { id: 2, code: 'pkKrtz7I' },
          { id: 3, code: 'RkenhwA8' },
          { id: 4, code: 'Hnt4ACZv' }
     ];

     useEffect(() => {
          if (code) {
               const tier = tiers.find(tier => tier.code === code);
               if (tier) {
                    setTierId(tier.id);
               } else {
                    history.push(`/auth/login`);
               }
          }
     }, [code]);

     useEffect(() => {
          requestUserProfile();

          if (user) {
               toastr.success(i18n.t('alerts.success.you_already_have_an_account'));
               history.push(`/dashboard`);
          }
     }, [user, history]);

     useEffect(() => {
          if (signupSucceeded) {
               toggleTransition();
          }
     }, [signupSucceeded]);

     return tierId && !userProfileRequested && !user && (
               <div className="transition-group-wrapper center">
                    <div className={transition ? 'from-hidden' : 'from-visible'}>
                         <SignUpForm alreadyHaveAccount={true} tierId={tierId} />
                    </div>
                    
                    <div className={transition ? 'to-visible' : 'to-hidden'}>
                         <CreateUserOrganizationForm tierId={tierId} languages={languages} />
                    </div>
               </div>
          );
};

export const Register = connect(
     mapStateToProps,
     mapDispatchToProps
)(_Register);
