import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import {AvatarLoader} from "../loader/AvatarLoader";

export const OrganizationPhoto = memo(({ src, width = 90, height = 90, showLoader = false, ...restProps }) => {
     const { t } = useTranslation();

     if (!src && showLoader) {
          return (<AvatarLoader width={width} height={height} {...restProps} />);
     }

     return (
          <img src={src} alt={t('dashboard.alts.organization_logo')} width={width} height={height} {...restProps} />
     );
});
