import React from 'react';

export default function SpeakersIcon() {
     return (
          <svg
               width="24"
               height="24"
               viewBox="0 -3 27 27"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
               className="kt-svg-icon"
          >
               <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <rect x="0" y="0" width="24" height="24" />
                    <path
                         d="M18 11C16.3431 11 15 9.65685 15 8C15 6.34315 16.3431 5 18 5C19.6569 5 21 6.34315 21 8C21 9.65685 19.6569 11 18 11ZM9 8C6.79086 8 5 6.20914 5 4C5 1.79086 6.79086 0 9 0C11.2091 0 13 1.79086 13 4C13 6.20914 11.2091 8 9 8Z"
                         fill="#000000"
                         fillRule="nonzero"
                         opacity="0.3"
                    />
                    <path
                         d="M17.6012 12.0006C21.0077 12.0379 23.7892 13.7601 23.9985 17.4C24.0069 17.5466 23.9985 18 23.4559 18H19.6C19.6 15.7491 18.8563 13.6718 17.6012 12.0006ZM0.000651684 17.1992C0.388259 12.4265 4.26191 10 8.98334 10C13.7712 10 17.7049 12.2932 17.9979 17.2C18.0096 17.3955 17.9979 18 17.2467 18C13.5411 18 8.03472 18 0.727502 18C0.476712 18 -0.0204618 17.4592 0.000651684 17.1992Z"
                         fill="#606d80"
                         fillRule="nonzero"
                    />
               </g>
          </svg>
     );
}
