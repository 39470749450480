/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useTranslation } from 'react-i18next';

import InstagramIcon from '../../../../assets/icons/instagram';
import FacebookIcon from '../../../../assets/icons/facebook';
import TwitterIcon from '../../../../assets/icons/twitter';
import LinkedinIcon from '../../../../assets/icons/linkedin';
import YoutubeIcon from '../../../../assets/icons/youtube';
import PinterestIcon from '../../../../assets/icons/pinterest';
import { COLORS } from '../../../../constants/style.constants';

const grey = COLORS.LINKS_GREY;
const white = COLORS.WHITE;

export const BottomNav = () => {
     const { t } = useTranslation();

     return (
          <div className="bg-theme-dark">
               <div className="container-standard">
                    <div className="bottom-nav-wrapper">
                         <div className="column">
                              <h3>{t('bottom_nav.headers.about')}</h3>
                              <div className="text">{t('bottom_nav.text')}</div>
                         </div>
                         <div className="column">
                              <h3>{t('bottom_nav.headers.quick_links')}</h3>
                              <div className="quick-links">
                                   <div className="bottomnav-links">
                                        <a href="https://avovent.com/about/" className="footer-link">
                                             {t('bottom_nav.links.about')}
                                        </a>
                                        <a href="https://avovent.com/contact/" className="footer-link">
                                             {t('bottom_nav.links.contact')}
                                        </a>
                                   </div>
                                   <div className="bottomnav-links">
                                        <a href="https://avovent.com/blog/" className="footer-link">
                                             {t('bottom_nav.links.blog')}
                                        </a>
                                   </div>
                              </div>
                         </div>
                         <div className="column">
                              <h3>{t('bottom_nav.headers.social')}</h3>
                              <div className="subfooter-links">
                                   <a target="_blank" href="https://www.facebook.com/avovent">
                                        <FacebookIcon
                                             color={grey}
                                             activeColor={white}
                                             variant="icon"
                                        />
                                   </a>
                                   <a target="_blank" href="https://twitter.com/avovent">
                                        <TwitterIcon
                                             color={grey}
                                             activeColor={white}
                                             variant="icon"
                                        />
                                   </a>
                                   <a target="_blank" href="https://www.linkedin.com/company/avovent/">
                                        <LinkedinIcon
                                             color={grey}
                                             activeColor={white}
                                             variant="icon"
                                        />
                                   </a>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     );
};
