import React, { useMemo, useEffect, useState, useCallback } from 'react';

import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useTable, usePagination } from 'react-table';
import {Pagination, Dropdown, Button, Image} from 'react-bootstrap';
import ChevronDownIcon from '../../../../assets/icons/metronic/chevron-down-icon';
import MoreIcon from '../../../../assets/icons/metronic/more-icon';
import EditIcon from '../../../../assets/icons/metronic/edit-icon';
import { COLORS, FONT } from '../../../../constants/style.constants';
import { TablePanel } from '../../../common/filterable-table/TablePanel';
import { PAGE_SIZES } from '../../../../constants/page.constants';

import FilterableTableWrapper from '../../../common/filterable-table/FilterableTable.style';
import {
     StyledDropdown,
     TableDropdownWrapper,
} from '../../../common/dropdown/Dropdown.style';
import {inArray} from "../../../../utils/array.utils";
import {Sorting} from "../../../common/sorting/Sorting";
import {AvatarCircleLoader} from "../../../common/loader";

const ActionsDropdown = ({ item, eventId }) => {
     const { id } = useParams();
     const [dropdownShown, setDropdownShown] = useState(false);

     const toggleDropdown = useCallback(() => {
          setDropdownShown(!dropdownShown);
     }, [dropdownShown]);

     return (
          <TableDropdownWrapper>
               <StyledDropdown
                    onToggle={toggleDropdown}
                    show={dropdownShown}
                    alignRight
               >
                    <StyledDropdown.Toggle variant="default">
                         <MoreIcon className="icon" />
                    </StyledDropdown.Toggle>
                    <StyledDropdown.Menu>
                         <StyledDropdown.Item
                              as="div"
                              style={{
                                   margin: 0,
                                   padding: 0,
                              }}
                         >
                              <Link
                                   to={`/organization/${id}/events/${eventId}/event-edit`}
                                   className="dropdown-item"
                              >
                                   <EditIcon />
                                   <span className="edit">
                                        {i18n.t('buttons.edit')}
                                   </span>
                              </Link>
                         </StyledDropdown.Item>
                    </StyledDropdown.Menu>
               </StyledDropdown>
          </TableDropdownWrapper>
     );
};

const displayAvatar = (avatar) => {
    if (avatar) {
        return (
            <Image
                src={avatar['76x76']}
                height={40}
                width={40}
                roundedCircle
            />
        );
    }

    return (<AvatarCircleLoader width={40} height={40} />);
};

const mapStateToProps = (state, ...ownProps) => ({
     ...ownProps,
     router: state.router,
     pageDetails: state.eventReducer.organizationEventsPageDetails,
     createNewEventRequested: state.eventReducer.createNewEventRequested,
     createNewEventSucceeded: state.eventReducer.createNewEventSucceeded,
     eventDetailsUpdateRequested:
          state.eventReducer.eventDetailsUpdateRequested,
     eventDetailsUpdateSucceeded:
          state.eventReducer.eventDetailsUpdateSucceeded,
});

export const _EventsTable = ({ data, updateCallback, ...props }) => {
     const {
          router: {
               location: { pathname },
          },
          handleSearch,
          pageDetails,
          handlePageSizeChange,
          handlePageChange,
          createNewEventRequested,
          createNewEventSucceeded,
          eventDetailsUpdateRequested,
          eventDetailsUpdateSucceeded,
          sorting,
          handleSort
     } = props;

     const { id: organizationId } = useParams();
     const { t } = useTranslation();

     const columns = useMemo(
          () => [
               {
                    Header: i18n.t('event.event_name'),
                    accessor: 'name',
               },
               {
                    Header: i18n.t('event.event_date'),
                    accessor: 'eventDate',
               },
               {
                    Header: i18n.t('event.status'),
                    accessor: 'status',
               },
               {
                    Header: i18n.t('organization_settings.actions'),
                    accessor: 'actions',
               },
          ],
          []
     );

     const [showModal, setShowModal] = useState(false);
     const [transformedData, setTransformedData] = useState([]);

     const transformData = (data) =>
          data.map((item) => ({
               ...item,
               name: (
                   <>
                       <span style={{ marginRight: '7px' }}>
                             {displayAvatar(item.photos)}
                       </span>
                        <span
                             style={{
                                  fontWeight: `${FONT.MEDIUM}`,
                                  paddingLeft: 0,
                             }}
                        >
                             <Link
                                  to={`/organization/${organizationId}/events/${item.id}/speakers`}
                                  style={{
                                       color: 'inherit',
                                       textDecoration: 'none',
                                  }}
                             >
                                  {item.name}
                             </Link>
                        </span>
                   </>
               ),
               date: <span style={{ marginRight: '7px' }}>{item.status}</span>,
               actions: (
                    <ActionsDropdown
                         item={item}
                         eventId={item.id}
                         updateCallback={updateCallback}
                    />
               ),
          }));

     useEffect(() => {
          setTransformedData(transformData(data));

          if (!transformedData) return;
     }, [
          data,
          createNewEventRequested,
          createNewEventSucceeded,
          eventDetailsUpdateRequested,
          eventDetailsUpdateSucceeded,
     ]);

     const {
          getTableProps,
          getTableBodyProps,
          headerGroups,
          rows,
          prepareRow,
          page,
          canPreviousPage,
          canNextPage,
          pageOptions,
          pageCount,
          gotoPage,
          nextPage,
          previousPage,
          setPageSize,
          state: { pageIndex, pageSize },
     } = useTable(
          {
               columns,
               data: transformedData,
               manualPagination: true,
               pageCount: pageDetails.last_page,
               initialState: {
                    pageSize: pageDetails.per_page,
               },
          },
          usePagination
     );

     const alignText = useCallback((id) => {
          const config = {
               actions: 'center',
          };

          return config[id] || 'left';
     }, []);

    const transformColumnWidth = useCallback(
        (id) => {
            const config = {
                actions: '100px'
            };

            return config[id] || 'inherit';
        },
        []);

     return (
          <FilterableTableWrapper>
               <TablePanel
                    title={t('event.your_events')}
                    total={pageDetails.total}
                    handleSearch={handleSearch}
                    actions={
                         <Link
                              to={`/organization/${organizationId}/events/new-event`}
                         >
                              <Button
                                   variant="success"
                                   onClick={() => setShowModal(true)}
                              >
                                   {t('event.new_event')}
                              </Button>
                         </Link>
                    }
               />
               <div
                    className="table-container"
                    style={{ marginBottom: '30px' }}
               >
                    <table
                         {...getTableProps()}
                         data={data}
                         className="table"
                         style={{ width: '100%', minWidth: '500px' }}
                    >
                         <thead>
                              {headerGroups.map((headerGroup) => (
                                   <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => {
                                             return (
                                                  <th
                                                       className="th"
                                                       {...column.getHeaderProps()}
                                                       style={{
                                                            padding: '20px 30px',
                                                            verticalAlign: 'middle',
                                                            textAlign: alignText(column.id),
                                                            width: transformColumnWidth(column.id)
                                                       }}
                                                  >
                                                       {column.render('Header')}
                                                      {
                                                          inArray(sorting.tableColumns, column.id) &&
                                                          (<Sorting
                                                              field={sorting.mapColumns[column.id]}
                                                              activeField={sorting.column}
                                                              direction={sorting.direction}
                                                              onSort={handleSort}
                                                          />)
                                                      }
                                                  </th>
                                             );
                                        })}
                                   </tr>
                              ))}
                         </thead>
                         <tbody {...getTableBodyProps()}>
                              {page.slice(0, pageSize).map((row, i) => {
                                   prepareRow(row);
                                   return (
                                        <tr
                                             {...row.getRowProps()}
                                             style={{
                                                  borderTop: `1px solid ${COLORS.PALE_BLUE}`,
                                             }}
                                        >
                                             {row.cells.map((cell) => {
                                                  return (
                                                       <td
                                                            {...cell.getCellProps()}
                                                            className="td"
                                                            style={{
                                                                 width: 'inherit',
                                                                 padding: '20px 30px',
                                                                 verticalAlign: 'middle',
                                                                 textAlign: alignText(cell.column.id),
                                                            }}
                                                       >
                                                            {cell.render(
                                                                 'Cell'
                                                            )}
                                                       </td>
                                                  );
                                             })}
                                        </tr>
                                   );
                              })}
                         </tbody>
                    </table>
                    <div className="datatable__pager">
                         <div className="pagination">
                              <Pagination.First
                                   onClick={() => {
                                        gotoPage(0);
                                        handlePageChange({
                                             page: 1,
                                             pageSize,
                                        });
                                   }}
                                   disabled={!canPreviousPage}
                              />
                              <Pagination.Prev
                                   onClick={() => {
                                        previousPage();
                                        handlePageChange({
                                             page: pageDetails.current_page - 1,
                                             pageSize,
                                        });
                                   }}
                                   disabled={!canPreviousPage}
                              />
                              <ul className="pagination">
                                   {pageOptions.map((option) => (
                                        <li
                                             key={option}
                                             className={`page-item ${
                                                  pageIndex === option
                                                       ? 'active'
                                                       : ''
                                             }`}
                                             style={{ cursor: 'pointer' }}
                                             onClick={() =>
                                                  handlePageChange({
                                                       page: option + 1,
                                                       pageSize,
                                                  })
                                             }
                                        >
                                             <span
                                                  className="page-link"
                                                  onClick={() =>
                                                       gotoPage(option)
                                                  }
                                             >
                                                  <span>{option + 1}</span>
                                             </span>
                                        </li>
                                   ))}
                              </ul>
                              <Pagination.Next
                                   onClick={() => {
                                        nextPage();
                                        handlePageChange({
                                             page: pageDetails.current_page + 1,
                                             pageSize,
                                        });
                                   }}
                                   disabled={!canNextPage}
                              />
                              <Pagination.Last
                                   onClick={() => {
                                        gotoPage(pageCount - 1);
                                        handlePageChange({
                                             page: pageDetails.last_page,
                                             pageSize,
                                        });
                                   }}
                                   disabled={!canNextPage}
                              />
                         </div>
                         <div className="tabel__pager-info">
                              <Dropdown>
                                   <Dropdown.Toggle variant="light">
                                        <span>{pageSize}</span>
                                        <ChevronDownIcon />
                                   </Dropdown.Toggle>
                                   <Dropdown.Menu style={{ minWidth: '0' }}>
                                        {PAGE_SIZES.map((i) => (
                                             <Dropdown.Item
                                                  key={i}
                                                  onClick={() => {
                                                       setPageSize(i);
                                                       handlePageSizeChange(i);
                                                  }}
                                             >
                                                  <span>{i}</span>
                                             </Dropdown.Item>
                                        ))}
                                   </Dropdown.Menu>
                              </Dropdown>
                              <span className="tabel__pager-info--text">
                                   {`${i18n.t(
                                        'organization_settings.showing'
                                   )} ${pageDetails.from} - ${
                                        pageDetails.to
                                   } ${i18n.t('organization_settings.of')} ${
                                        pageDetails.total
                                   }`}
                              </span>
                         </div>
                    </div>
               </div>
          </FilterableTableWrapper>
     );
};

export const EventsTable = connect(mapStateToProps, null)(_EventsTable);
