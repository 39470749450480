import React, { memo, useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { LanguageSelect } from '../main/templates/Header/header-toolbar/LanguageSelect';
import { LoginBlockForm } from './forms/LoginBlockForm';
import { ResetPasswordForm } from './forms/ResetPasswordForm';
import {LoggedInUser, Invitation, SpeakerRegister} from './invitation-screens';
import { LinkToAccount, LinkedForm, LoginToForm } from './linked-screens';
import RightBlockWrapper from './auth-styles/RightBlock.style';
import { SpeakerFormResend } from '../main/Speakers';
import { Register } from './registration';

const EnhancedLoginRoute = memo(({ loggedIn }) => {
     const history = useHistory();

     useEffect(() => {
          if (loggedIn) {
               history.replace('/dashboard');
          }
     }, [history, loggedIn]);

     return !loggedIn && <LoginBlockForm />;
});

export const RightBlock = memo(({ alreadyLoggedIn }) => {
     return (
          <RightBlockWrapper>
               <span className="lang-button">
                    <LanguageSelect className="login-page-icon" />
               </span>
               <Switch>
                    <Route path="/registration/:code">
                         <Register />
                    </Route>
                    <Route path="/auth/speaker-form/:invitationToken/resend">
                         <SpeakerFormResend />
                    </Route>
                    <Route path="/auth/login">
                         <EnhancedLoginRoute loggedIn={alreadyLoggedIn} />
                    </Route>
                    <Route path="/auth/reset-password/:resetToken">
                         <ResetPasswordForm />
                    </Route>
                    <Route path="/auth/invitation/:joinToken">
                         <Invitation />
                    </Route>
                    <Route path="/auth/logged-in-user/:joinToken">
                         <LoggedInUser />
                    </Route>
                    <Route path="/auth/link-to-account/:joinToken">
                         <LinkToAccount />
                    </Route>
                    <Route path="/auth/linked-form">
                         <LinkedForm />
                    </Route>
                    <Route path="/auth/login-to-form/:invitationToken">
                         <LoginToForm />
                    </Route>
                    <Route path="/auth/speaker/register/:invitationToken">
                         <SpeakerRegister />
                    </Route>
                    <Route path="*">
                         <LoginBlockForm />
                    </Route>
               </Switch>
          </RightBlockWrapper>
     );
});
