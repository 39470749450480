
import React, { useRef, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import IconText from '../../../../../assets/icons/form-builder/IconText';
import { DND_TYPES } from '../../ElementTypes';
import { Button } from 'react-bootstrap';
import TimesIcon from '../../../../../assets/icons/metronic/times-icon';
import { COLORS } from '../../../../../constants/style.constants';
import { Placeholder } from '../Placeholder';
import { Overlay } from '../Overlay';
import { getEmptyImage } from 'react-dnd-html5-backend';

export const Title = ({
     id,
     name,
     type,
     value,
     index,
     inBuilder,
     forceDisplay,
     handleElementSelect,
     selectedElement,
     isSelectedItem,
     isMaster,
     isValid,
     handleRemoveElement,
     setActiveSpan,
     activeSpan,
     moveElement
}) => {
     const ref = useRef(null);

     const [{ isDragging }, drag, preview] = useDrag({
          item: { id, name, type, value, index },
          isDragging(monitor) {
               return monitor.getItem() && monitor.getItem().id === id;
          },
          collect: (monitor) => ({
               isDragging: monitor.isDragging(),
          }),
     });

     const [, drop] = useDrop({
          accept: DND_TYPES,
          hover(item, monitor) {
               // if (item.index === undefined && index !== undefined) {
               //      const newIndex = handleDrawElements(item, index);
               //      if (newIndex !== null && newIndex >= 0) {
               //           item.index = newIndex;
               //      }
               // }

               if (!ref.current || item.index === undefined) {
                    return;
               }

               const dragIndex = item.index;
               const hoverIndex = index;

               if (dragIndex === hoverIndex) {
                    return;
               }

               const hoverBoundingRect = ref.current?.getBoundingClientRect();
               const hoverMiddleY =
                    (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
               const clientOffset = monitor.getClientOffset();
               const hoverClientY = clientOffset.y - hoverBoundingRect.top;

               if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                    return;
               }

               if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                    return;
               }

               moveElement(dragIndex, hoverIndex);

               item.index = hoverIndex;
          },
     });

     useEffect(() => {
          preview(getEmptyImage(), { captureDraggingState: false });
     }, []);

     drag(drop(ref));

     if (!inBuilder) {
          return (
               <div ref={ref} className="elements-grid--item" role="button">
                    <span className="icon">
                         <IconText />
                    </span>
                    <span className="text">{name}</span>
               </div>
          );
     }

     if (isDragging && !forceDisplay) {
          return <Placeholder />;
     }

     return (
          <div
               ref={ref}
               className={`selection ${!isValid && !isDragging && !isSelectedItem(id) ? 'is-warning' : ''}`}
               onClick={(e) => handleElementSelect(e, id)}
               style={{
                    backgroundColor:
                         selectedElement && selectedElement.id === id
                              ? 'rgba(24, 112, 244, 0.05)'
                              : '#fff',
                    position: 'relative',
                    wordBreak: 'break-word',
               }}
          >
               <Overlay />

               <h3 className="title">{value || name}</h3>
               {isMaster && (
                   <Button
                       autoFocus={isSelectedItem(id)}
                       variant="danger"
                       onClick={() => handleRemoveElement(id)}
                       onMouseEnter={() => setActiveSpan(true)}
                       onMouseLeave={() => setActiveSpan(false)}
                       style={{
                            visibility: isSelectedItem(id) ? 'visible' : 'hidden',
                            position: 'relative',
                            zIndex: 3,
                       }}
                   >
                        <TimesIcon
                            color={`${COLORS.PLACEHOLDER_GREY}`}
                            activeColor={`${COLORS.WHITE}`}
                            hovered={activeSpan}
                        />
                   </Button>
               )}
          </div>
     );
};
