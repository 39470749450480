/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../utils/date.utils';
import { requestUserAuthorized } from '../../../redux/reducers/userReducer';
import { requestSignup } from '../../../redux/reducers/authReducer';
import { requestInvitationDetails } from '../../../redux/reducers/speakerReducer';
import OrganizationBlock from '../../common/organization-block';
import { LoginBlockForm } from '../forms/LoginBlockForm';
import { useTransition } from '../../../hooks';
import { InfoWrapper } from '../auth-styles/RightBlock.style';
import {SignUpForm} from "../../main/UserSettings/forms/SignUpForm";

const mapStateToProps = (state) => ({
     invitationDetailsData: state.speakerReducer.invitationDetailsData,
     authorized: state.userReducer.authorized,
     auth: state.authReducer,
});

const mapDispatchToProps = (dispatch) => ({
     requestInvitationDetails: (token) =>
          dispatch(requestInvitationDetails(token)),
     requestUserAuthorized: () => dispatch(requestUserAuthorized()),
     requestSignup: (values) => dispatch(requestSignup(values)),
     redirectToLinkToAccount: (token) =>
          dispatch(replace(`/auth/link-to-account/${token}`)),
});

const _LoginToForm = memo(
     ({
          invitationDetailsData,
          authorized,
          requestInvitationDetails,
          requestUserAuthorized,
          redirectToLinkToAccount,
     }) => {
          const { t } = useTranslation();
          const { invitationToken } = useParams();
          const [invitationDetails, setInvitationDetails] = useState();
          const { transition, toggleTransition } = useTransition();

          useEffect(() => {
               if (!invitationDetailsData) {
                    requestInvitationDetails(invitationToken);
               }
          }, [invitationDetailsData, invitationToken]);

          useEffect(() => {
               if (!authorized) {
                    requestUserAuthorized();
               }

               if (!!authorized && authorized !== null) {
                    redirectToLinkToAccount(invitationToken);
               }
          }, [authorized]);

          useEffect(() => {
               if (invitationDetailsData) {
                    if (
                         invitationDetailsData.data &&
                         invitationDetailsData.data.length
                    ) {
                         return setInvitationDetails(
                              invitationDetailsData.data
                         );
                    }

                    setInvitationDetails(invitationDetailsData);
               }
          }, [invitationDetailsData]);

          if (!invitationDetails) return <></>;
          if (authorized === null) return <></>;

          const renderSignUpForm = () => (
               <div className={transition ? 'to-visible' : 'to-hidden'}>
                    <SignUpForm 
                         invitationToken={invitationToken} 
                         backLink={`/auth/login-to-form/${invitationToken}`} 
                         toggleTransition={true}
                    />
               </div>
          );

          const renderInfoBlock = () => (
               <div className={transition ? 'from-hidden' : 'from-visible'}>
                    <InfoWrapper>
                         <OrganizationBlock
                              picture={
                                   invitationDetails.event.photos['180x180']
                              }
                              pictureAltText={t('login_to_form.logo')}
                              title={invitationDetails.event.name}
                              subtitle={formatDate({
                                   startDate: invitationDetails.event.start,
                                   endDate: invitationDetails.event.end,
                              })}
                         >
                              <p>
                                   {t('login_to_form.login_and_link')}{' '}
                                   <b>{invitationDetails.event.name}</b>{' '}
                                   {t('login_to_form.invitation')}
                              </p>
                         </OrganizationBlock>
                         <div className="link-block">
                              <LoginBlockForm />
                         </div>
                         <div className="link-block">
                              {t('login_to_form.no_account')}{' '}
                              <a href="#sign-up" onClick={toggleTransition}>
                                   {t('login_to_form.create_account')}
                              </a>
                         </div>
                    </InfoWrapper>
               </div>
          );

          return (
               <div className="transition-group-wrapper center">
                    {renderInfoBlock()}
                    {renderSignUpForm()}
               </div>
          );
     }
);

export const LoginToForm = connect(
     mapStateToProps,
     mapDispatchToProps
)(_LoginToForm);
