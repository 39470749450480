import React, { memo } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { Helmet } from 'react-helmet';

import Alert from '../../../common/alert';
import Breadcrumb from '../../../common/breadcrumb';
import MainDrawer from '../../../common/drawer';
import { ListItem } from '../../../common/collapsible-list';
import picture from '../../../../assets/images/illustration-ufo.svg';

import { RemovalNotificationsWrapper } from './RemovalNotifications.style';

const links = [{ to: '/dashboard', text: i18n.t('breadcrumb.dashboard') }];

export const PersonRemoved = memo(() => {
     const { t } = useTranslation();

     return (
          <RemovalNotificationsWrapper>
               <Helmet>
                    <title>
                         {t('breadcrumb.dashboard')} | {t('avovent')}
                    </title>
               </Helmet>
               <MainDrawer>
                    <ListItem to="/dashboard">
                         {t('breadcrumb.dashboard')}
                    </ListItem>
               </MainDrawer>
               <section className="person-notification-block">
                    <div className="content--border not-sr">
                         <div className="container-standard">
                              <Link to="/dashboard">
                                   <Button className="btn-themed-blue btn-navigational">
                                        {t('breadcrumb.dashboard')}
                                   </Button>
                              </Link>
                         </div>
                    </div>
                    <div className="container-standard">
                         <Breadcrumb
                              title={t('breadcrumb.dashboard')}
                              links={links}
                         />
                    </div>
                    <div className="content container-standard">
                         <Alert>{t('alerts.primary.nothing_to_see')}</Alert>
                         <img
                              src={picture}
                              alt={t('removal_notifications.picture')}
                         />
                    </div>
               </section>
          </RemovalNotificationsWrapper>
     );
});
