import React, {memo, useEffect, useState} from 'react';

import { InvitationFormEmpty } from './Step2InvitationFormEmpty';
import { InvitationForm } from './Step2InvitationForm';

export const InvitationForms = memo(
    ({
         prepareData,
         handleAction,
         id,
         forms,
         queryString,
         searchIsUsed,
         eventId,
         handlePageSizeChange,
         handlePageChange,
         handleSearch,
         handleNewFormAction,
         handleEditFormAction,
         handleChangeCurrentStep,
         selectedForms,
         handleFormSelect,
         isSpeakerAndFormsValid,
         isFormLanguagesValid,
         formLanguageDuplicates,
         languages,
         handleSelectDuplicatedForm
    }) => {

        if (forms && forms.length === 0 && queryString === '' && !searchIsUsed) {

            return (
                <InvitationFormEmpty
                    id={id}
                    eventId={eventId}
                    handleNewFormAction={handleNewFormAction}
                    handleChangeCurrentStep={handleChangeCurrentStep}
                />
            );
        }

        return (
            <InvitationForm
                id={id}
                eventId={eventId}
                handlePageSizeChange={handlePageSizeChange}
                handlePageChange={handlePageChange}
                handleSearch={handleSearch}
                prepareData={prepareData}
                handleAction={handleAction}
                handleNewFormAction={handleNewFormAction}
                handleEditFormAction={handleEditFormAction}
                handleChangeCurrentStep={handleChangeCurrentStep}
                selectedForms={selectedForms}
                handleFormSelect={handleFormSelect}
                isSpeakerAndFormsValid={isSpeakerAndFormsValid}
                isFormLanguagesValid={isFormLanguagesValid}
                formLanguageDuplicates={formLanguageDuplicates}
                languages={languages}
                handleSelectDuplicatedForm={handleSelectDuplicatedForm}
            />
        );
    }
);