import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Switch, Route, Redirect } from 'react-router-dom';

import Header from './templates/Header';
import Footer from './templates/Footer';
import NotFoundPage from './templates/NotFound';

import {
     PersonRemoved,
     SpeakerRemoved,
} from './templates/RemovalNotifications';
import { ModuleDown } from './templates/ModuleDown';
import { EventsEmpty, NewEvent, Events, EditEvent } from './Event';
import { Speakers, SpeakerFormLoggedIn, SpeakerDetailsBoard } from './Speakers';
import {
     Reminders,
     NewReminder,
     CopyReminder,
} from './Reminders';
import WizardForm from './Speakers/wizard-form';
import { OrganizationSettings } from '../main/Organization';
import UserSettings from './UserSettings';
import Dashboard from './Dashboard';
import FormBuilder from './FormBuilder';
import { ProtectedRoute } from '../../utils/router.utils';
import { requestUserProfile } from '../../redux/reducers/userReducer';
import {validateSupport} from "../../utils/browser.utils";
import SocketConnection from "../socket";

const mapStateToProps = (state) => ({
     router: state.router,
     user: state.userReducer.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
     redirectToDashboard: () => dispatch(push('/dashboard')),
     requestUserProfile: () => dispatch(requestUserProfile()),
});

const _MainPage = memo((props) => {
     const {
          router: {
               location: { pathname },
          },
          user,
          redirectToDashboard,
          requestUserProfile,
     } = props;

     useEffect(() => {
          validateSupport();
     }, [pathname]);

     useEffect(() => {
          if (!user) {
               requestUserProfile();
          }
     }, []);

     useEffect(() => {
          if (pathname === '/') {
               redirectToDashboard();
          }
     }, [pathname, redirectToDashboard]);

     const complete = !!user;

     const renderMain = () => (
          <>
               <SocketConnection/>
               <Header />
               <div className="content">
                    <Switch>
                         <Route exact path="/" />

                         <ProtectedRoute path="/dashboard">
                              <Dashboard />
                         </ProtectedRoute>
                         <ProtectedRoute path="/user-settings">
                              <UserSettings />
                         </ProtectedRoute>
                         <ProtectedRoute path="/person-removed">
                              <PersonRemoved />
                         </ProtectedRoute>
                         <ProtectedRoute path="/speaker-removed">
                              <SpeakerRemoved />
                         </ProtectedRoute>

                         <ProtectedRoute path="/module-down">
                              <ModuleDown />
                         </ProtectedRoute>
                         <ProtectedRoute path="/organization/:id/events/events-empty">
                              <EventsEmpty />
                         </ProtectedRoute>
                         <ProtectedRoute path="/organization/:id/events/new-event">
                              <NewEvent />
                         </ProtectedRoute>
                         <ProtectedRoute path="/organization/:id/events/:eventId/event-edit">
                              <EditEvent />
                         </ProtectedRoute>
                         <ProtectedRoute exact path="/organization/:id/events">
                              <Events />
                         </ProtectedRoute>
                         <ProtectedRoute path="/organization/:id/events/:eventId/invite-speakers">
                              <WizardForm />
                         </ProtectedRoute>
                         <ProtectedRoute path="/organization/:id/events/:eventId/form-builder">
                              <FormBuilder />
                         </ProtectedRoute>
                         <ProtectedRoute path="/organization/:id/events/:eventId/speakers/archive/:token">
                              <Speakers />
                         </ProtectedRoute>
                         <ProtectedRoute path="/organization/:id/events/:eventId/speakers">
                              <Speakers />
                         </ProtectedRoute>
                         <ProtectedRoute path="/organization/:id/events/:eventId/speaker/:speakerId">
                              <SpeakerDetailsBoard />
                         </ProtectedRoute>
                         <ProtectedRoute path="/events/:eventId/speaker-form-loggedin/:id">
                              <SpeakerFormLoggedIn />
                         </ProtectedRoute>
                         <ProtectedRoute path="/organization/:id/events/:eventId/reminders/new-reminder">
                              <NewReminder />
                         </ProtectedRoute>
                         <ProtectedRoute path="/organization/:id/events/:eventId/reminders/copy-reminder/:reminderId">
                              <CopyReminder />
                         </ProtectedRoute>
                         <ProtectedRoute path="/organization/:id/events/:eventId/reminders">
                              <Reminders />
                         </ProtectedRoute>
                         <ProtectedRoute path="/organization/:id">
                              <OrganizationSettings />
                         </ProtectedRoute>

                         <Route path="*">
                              <Redirect
                                   to={{
                                        pathname: '/not-found-404',
                                        state: {
                                             from: window.location.pathname,
                                             notFound: true,
                                        },
                                   }}
                              >
                                   <NotFoundPage />
                              </Redirect>
                         </Route>
                    </Switch>
               </div>
               <Footer />
          </>
     );

     return complete ? renderMain() : <></>;
});

export const MainPage = connect(mapStateToProps, mapDispatchToProps)(_MainPage);
