import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import Alert from '../../../common/alert';
import picture from '../../../../assets/images/illustration-ufo.svg';
import logo from '../../../../assets/images/logo_bg-white.svg';
import NotFoundWrapper from './NotFoundPage.style';

export const NotFoundPage = memo(() => {
     const { t } = useTranslation();

     return (
          <section className="container-standard content">
               <Helmet>
                    <title>{t('avovent')}</title>
               </Helmet>
               <NotFoundWrapper>
                    <img src={logo} alt={t('logo')} className="logo" />
                    <h1>{t('removal_notifications.header')}</h1>
                    <Alert>{t('alerts.primary.page_missing')}</Alert>
                    <div className="btn-container">
                         <Link to="/contact-us">
                              <Button variant="default">
                                   {t('removal_notifications.contact_us')}
                              </Button>
                         </Link>
                         <Link to="/dashboard">
                              <Button variant="success">
                                   {t('removal_notifications.go_to_dashboard')}
                              </Button>
                         </Link>
                    </div>
                    <img
                         src={picture}
                         alt={t('removal_notifications.picture')}
                         className="picture"
                    />
               </NotFoundWrapper>
          </section>
     );
});
