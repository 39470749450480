import React, { memo, useState, useEffect, useRef } from 'react';
import { Dropdown, Button } from 'react-bootstrap';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

import CustomToggle from '../../../common/custom-toggle';
import ChevronDownIcon from '../../../../assets/icons/metronic/chevron-down-icon';
import { DefaultFieldsTypes, DefaultFieldsToTrans } from '../DefaultFields';
import {isEmpty} from "underscore";
import {DEFAULT_ELEMENT_LENGTH} from "../../../../utils/formBuilder.utils";
import {Autosugestion} from "../../../common/autosugestion";

export const TextFieldSettings = memo(
     ({
          selectedElement,
          handleSettingChange,
          handleRemoveElement,
          getMasterValue,
          isValidCode,
          fieldsList
     }) => {
          const { t } = useTranslation();

          const [state, setState] = useState();
          const ref = useRef();

          const DEFAULT_TYPE_VALUE = i18n.t(
               'form_builder.select_element_type'
          );

          const DEFAULT_TYPE_OPTION = 'select';

          const defaultTypeOptions = [
               { value: DEFAULT_TYPE_VALUE, option: DEFAULT_TYPE_OPTION },
               { value: i18n.t('form_builder.type_options.company_name'), option: 'company_name' },
               { value: i18n.t('form_builder.type_options.phone'), option: 'phone' },
               { value: i18n.t('form_builder.type_options.website'), option: 'website' },
          ];

          useEffect(() => {
               setState(selectedElement);

               if (DefaultFieldsTypes.includes(selectedElement.elementType)) {
                   if (selectedElement.elementType === 'first_name') {
                       setInnerState({
                           ...innerState,
                           typeOptions: defaultTypeOptions.concat({
                               value: i18n.t(
                                   'form_builder.type_options.first_name'
                               ),
                               option: 'first_name'
                           }),
                       });
                   }

                   if (selectedElement.elementType === 'last_name') {
                       setInnerState({
                           ...innerState,
                           typeOptions: defaultTypeOptions.concat({
                               value: i18n.t(
                                   'form_builder.type_options.last_name'
                               ),
                               option: 'last_name'
                           }),
                       });
                   }

                   if (selectedElement.elementType === 'email') {
                       setInnerState({
                           ...innerState,
                           typeOptions: defaultTypeOptions.concat({
                               value: i18n.t('form_builder.type_options.email'),
                               option: 'email'
                           }),
                       });
                   }
               } else {
                   setInnerState({
                       ...innerState,
                       typeOptions: defaultTypeOptions,
                   });
               }


          }, [selectedElement]);

          const [innerState, setInnerState] = useState({
               typeOptions: defaultTypeOptions,
               currentTypeOption: DEFAULT_TYPE_OPTION,
          });

          const handleElementTypeChange = (value) => {
               if (value === DEFAULT_TYPE_OPTION) {
                    value = '';
               }

               setState({ ...state, elementType: value });
               handleSettingChange({
                    id: state.id,
                    name: 'elementType',
                    value,
               });
          };

          const handleChange = (name, { target: { value } }) => {
               setState({ ...state, [name]: value });
               handleSettingChange({
                    id: state.id,
                    name,
                    value,
               });
          };

          const handleCheckboxChange = ({ target: { checked } }) => {
               setState({ ...state, required: checked });
               handleSettingChange({
                    id: state.id,
                    name: 'required',
                    value: checked,
               });
          };

          const handleRemoveItself = () => {
               setState(selectedElement);
               handleRemoveElement(state.id);
          };

          return (
               <>
                    <div className="mb-20">
                         <label>{t('form_builder.element_label')}</label>
                         <input
                              ref={ref}
                              className={`form-control ${state && ((!state.is_valid && isEmpty(state.label)) || (!state.is_translated && state.missing_translations.includes('label'))) ? 'is-warning' : ''}`}
                              type="text"
                              field="label"
                              value={state && state.label}
                              placeholder={state && getMasterValue(state.internal_id, 'label', null, t(
                                  'form_builder.setting_label_placeholder'
                              ))}
                              onChange={(e) => handleChange('label', e)}
                              tabIndex={0}
                              maxLength={DEFAULT_ELEMENT_LENGTH}
                         />

                    </div>
                   {state && state.is_master && (
                       <>
                           <div className="mb-20">
                               <label>{t('form_builder.element_code')}</label>
                               <Autosugestion
                                   field="elementCode"
                                   handleChange={(value) => handleChange('elementCode', { target: { value } })}
                                   maxLength={DEFAULT_ELEMENT_LENGTH}
                                   placeholder={t('form_builder.setting_element_code_placeholder')}
                                   value={state && state.elementCode}
                                   suggestions={fieldsList}
                                   className={`form-control ${!state.is_valid && isEmpty(state.elementCode) ? 'is-warning' : ''} ${!state.is_valid_code && !isEmpty(selectedElement.elementCode) ? 'is-invalid' : ''}`}
                               />
                               {!state.is_valid_code && !isEmpty(selectedElement.elementCode) && (
                                   <div className="invalid-feedback" style={{display: 'block'}}>{t('validations.element_code_unique')}</div>
                               )}
                           </div>
                           {state &&
                           !DefaultFieldsTypes.includes(
                               state.elementType
                           ) && (
                               <div className="mb-20">
                                   <label>{t('form_builder.element_type')}</label>
                                   <div className="input-group" tabIndex={-1}>
                                       <Dropdown>
                                           <Dropdown.Toggle tabIndex={0}
                                               className="select-dropdown"
                                               as={CustomToggle}
                                           >
                                               {(state && DefaultFieldsToTrans[state.elementType]) ||
                                               DefaultFieldsToTrans[innerState.currentTypeOption]}
                                               <span className="suffix">
                                             <ChevronDownIcon />
                                        </span>
                                           </Dropdown.Toggle>
                                           <Dropdown.Menu>
                                               {innerState.typeOptions.map(
                                                   ({ value, option }) => (
                                                       <Dropdown.Item
                                                           key={option}
                                                           onClick={() => {
                                                               handleElementTypeChange(
                                                                   option
                                                               );
                                                               setInnerState({
                                                                   ...innerState,
                                                                   currentTypeOption: option,
                                                               });
                                                           }}
                                                       >
                                                           {value}
                                                       </Dropdown.Item>
                                                   )
                                               )}
                                           </Dropdown.Menu>
                                       </Dropdown>
                                       <div className="info-block">
                                           {t('form_builder.optional')}
                                       </div>
                                   </div>
                               </div>
                           )}
                       </>
                   )}
                    <div className="mb-20">
                         <label>{t('form_builder.placeholder')}</label>
                         <input
                              ref={ref}
                              className={`form-control ${state && !state.is_translated && state.missing_translations.includes('placeholder') ? 'is-warning' : ''}`}
                              type="text"
                              field="placeholder"
                              value={state && state.placeholder}
                              placeholder={state && getMasterValue(state.internal_id, 'placeholder', null, t(
                                  'form_builder.setting_placeholder_placeholder'
                              ))}
                              onChange={(e) => handleChange('placeholder', e)}
                              tabIndex={0}
                              maxLength={DEFAULT_ELEMENT_LENGTH}
                         />
                         <div className="info-block">
                              {t('form_builder.optional')}
                         </div>
                    </div>
                    <div className="mb-20">
                         <label>{t('form_builder.value')}</label>
                         <input
                              ref={ref}
                              className={`form-control ${state && !state.is_translated && state.missing_translations.includes('value') ? 'is-warning' : ''}`}
                              type="text"
                              field="value"
                              value={state && state.value}
                              placeholder={state && getMasterValue(state.internal_id, 'value', null, t(
                                  'form_builder.setting_value_placeholder'
                              ))}
                              onChange={(e) => handleChange('value', e)}
                              tabIndex={0}
                              maxLength={DEFAULT_ELEMENT_LENGTH}
                         />
                         <div className="info-block">
                              {t('form_builder.optional')}
                         </div>
                    </div>
                    <div className="mb-20">
                         <label>
                              {t('form_builder.additional_information')}
                         </label>
                         <input
                              ref={ref}
                              className={`form-control ${state && !state.is_translated && state.missing_translations.includes('additionalInformation') ? 'is-warning' : ''}`}
                              type="text"
                              field="additionalInformation"
                              value={state && state.additionalInformation}
                              placeholder={state && getMasterValue(state.internal_id, 'additionalInformation', null, t(
                                  'form_builder.setting_additional_information_placeholder'
                              ))}
                              onChange={(e) =>
                                   handleChange('additionalInformation', e)
                              }
                              tabIndex={0}
                         />
                         <div className="info-block">
                              {t('form_builder.optional')}
                         </div>
                    </div>
                   {state && state.is_master && !DefaultFieldsTypes.includes(state.elementType) &&  (
                       <>
                           <label className="checkbox-solid-blue">
                               <input
                                   type="checkbox"
                                   checked={state && state.required}
                                   onChange={handleCheckboxChange}
                               />
                               <span />
                               <div className="label">
                                   {t('form_builder.required')}
                               </div>
                           </label>
                           <hr className="setting-divider" />
                           <Button
                               disabled={DefaultFieldsTypes.includes(state.elementType)}
                               variant="danger"
                               onClick={handleRemoveItself}
                           >
                               {t('form_builder.delete_element')}
                           </Button>
                       </>
                   )}
               </>
          );
     }
);
