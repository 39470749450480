import React, {useEffect, useState} from 'react';
import Autosuggest from 'react-autosuggest';
import theme from 'react-autosuggest/dist/theme'
import {AutosuggestionWrapper} from "./Autosuggestion.styles";


export const Autosugestion = ({
                          value,
                          placeholder,
                          handleChange,
                          suggestions,
                          className = 'form-control',
                          disabled = false,
                          ignore = ['Name', 'Status']
                      }) => {

    const [innerState, setInnerState] = useState({
        suggestions: [],
        value: ''
    });

    useEffect(() => {
        if (suggestions.length > 0) {
            const withoutIgnored = suggestions.filter(suggestion => !ignore.includes(suggestion));
            setInnerState({
                ...innerState,
                suggestions: withoutIgnored
            });
        }
    }, [suggestions]);

    useEffect(() => {
        setInnerState({
            ...innerState,
            value: value
        });
    }, [value]);

    const getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        const withoutIgnored = suggestions.filter(suggestion => !ignore.includes(suggestion));

        return inputLength === 0 ? [] : withoutIgnored.filter(suggestion =>
            suggestion.toLowerCase().slice(0, inputLength) === inputValue
        );
    };

    const renderSuggestion = suggestion => (
        <div>
            {suggestion}
        </div>
    );

    const onChange = (e, { newValue }) => {
        handleChange(newValue);
    };

    const getSuggestionValue = suggestion => suggestion;

    const onSuggestionsFetchRequested = ({ value }) => {
        setInnerState({
            ...innerState,
            suggestions: getSuggestions(value)
        });
    };

    const onSuggestionsClearRequested = () => {
        setInnerState({
            ...innerState,
            suggestions: []
        });
    };

    const inputProps = {
        placeholder: placeholder,
        value: innerState.value,
        onChange: onChange,
        className,
        disabled
    };

    return (
        (
            <AutosuggestionWrapper>
                <Autosuggest
                    theme={theme}
                    suggestions={innerState.suggestions}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}
                />
            </AutosuggestionWrapper>
        )
    );

};
