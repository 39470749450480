import React, {memo, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Alert from '../../../common/alert';

export const InvitationFormEmpty = memo(({
    id,
    eventId,
    handleChangeCurrentStep,
    handleNewFormAction
}) => {
     const { t } = useTranslation();

    useEffect(() => {
        handleChangeCurrentStep(2);
    });

     return (
          <>
               <div className="headline">{t('speakers.invitation_form')}</div>
               <Alert>{t('alerts.primary.invitation_forms_empty')}</Alert>
               <Link
                    to={`/organization/${id}/events/${eventId}/form-builder/add-element`}
               >
                    <Button variant="success" onClick={handleNewFormAction}>
                         {t('speakers.create_form')}
                    </Button>
               </Link>
          </>
     );
});
