import React, { useState } from 'react';
import i18n from 'i18next';
import { Card } from 'react-bootstrap';
import {DND_TYPES, ElementTypes} from './ElementTypes';

import * as Elements from './Elements/Builder';
import {useDrop} from "react-dnd";

export const BuilderDropZone = ({
     readonly,
     elements,
     language,
     currentLanguage,
     selectedElement,
     isSelectedItem,
     handleElementSelect,
     handleRemoveElement,
     moveElement,
     handleDrawElements,
}) => {
     const [activeSpan, setActiveSpan] = useState(false);

     const [, drop] = useDrop({
          accept: DND_TYPES,
          hover(item, monitor) {

               const indexToSetup = elements
                   .filter(({ lang }) => lang === currentLanguage).length;

               if (item.index === undefined) {
                    const newIndex = handleDrawElements(item, indexToSetup);
                    if (newIndex !== null && newIndex >= 0) {
                         item.index = newIndex;
                    }
               }
          },
     });

     const displayLayout = (layout) => {
          switch (layout) {
               case 'once_column':
                    return '100%';
                    break;
               case 'two_column':
                    return '1fr 1fr';
                    break;
               case 'three_column':
                    return '1fr 1fr 1fr';
                    break;
               default:
                    return '100%';
          }
     };

     const drawElements = (item, index) => {
          let context;

          switch (item.type) {
               case ElementTypes.TITLE:
                    context = (
                         <Elements.Title
                              key={item.id}
                              index={index}
                              id={item.id}
                              name={i18n.t('form_builder.elements.title')}
                              type={ElementTypes.TITLE}
                              value={item.value}
                              isMaster={item.is_master}
                              isValid={item.is_valid && item.is_valid_code && item.is_translated}
                              inBuilder={true}
                              forceDisplay={false}
                              handleElementSelect={handleElementSelect}
                              selectedElement={selectedElement}
                              isSelectedItem={isSelectedItem}
                              handleRemoveElement={handleRemoveElement}
                              setActiveSpan={setActiveSpan}
                              activeSpan={activeSpan}
                              moveElement={moveElement}
                              handleDrawElements={handleDrawElements}
                         />
                    );
                    break;
               case ElementTypes.PARAGRAPH:
                    context = (
                         <Elements.Paragraph
                              key={item.id}
                              index={index}
                              id={item.id}
                              name={i18n.t('form_builder.elements.paragraph')}
                              type={ElementTypes.PARAGRAPH}
                              text={item.text}
                              isMaster={item.is_master}
                              isValid={item.is_valid && item.is_valid_code && item.is_translated}
                              inBuilder={true}
                              forceDisplay={false}
                              handleElementSelect={handleElementSelect}
                              selectedElement={selectedElement}
                              isSelectedItem={isSelectedItem}
                              handleRemoveElement={handleRemoveElement}
                              setActiveSpan={setActiveSpan}
                              activeSpan={activeSpan}
                              moveElement={moveElement}
                              handleDrawElements={handleDrawElements}
                         />
                    );
                    break;
               case ElementTypes.TEXT_FIELD:
                    context = (
                         <Elements.TextField
                              key={item.id}
                              id={item.id}
                              name={i18n.t('form_builder.elements.text_field')}
                              type={ElementTypes.TEXT_FIELD}
                              label={item.label}
                              elementCode={item.elementCode}
                              placeholder={item.placeholder}
                              value={item.value}
                              isMaster={item.is_master}
                              additionalInformation={item.additionalInformation}
                              required={item.required}
                              elementType={item.elementType}
                              readonly={item.readonly}
                              index={index}
                              inBuilder={true}
                              forceDisplay={false}
                              isValid={item.is_valid && item.is_valid_code && item.is_translated}
                              handleElementSelect={handleElementSelect}
                              selectedElement={selectedElement}
                              isSelectedItem={isSelectedItem}
                              handleRemoveElement={handleRemoveElement}
                              setActiveSpan={setActiveSpan}
                              activeSpan={activeSpan}
                              moveElement={moveElement}
                              handleDrawElements={handleDrawElements}
                         />
                    );
                    break;
               case ElementTypes.TEXT_AREA:
                    context = (
                         <Elements.TextArea
                              key={item.id}
                              id={item.id}
                              name={i18n.t('form_builder.elements.text_area')}
                              type={ElementTypes.TEXT_AREA}
                              label={item.label}
                              elementCode={item.elementCode}
                              placeholder={item.placeholder}
                              text={item.text}
                              isMaster={item.is_master}
                              isValid={item.is_valid && item.is_valid_code && item.is_translated}
                              additionalInformation={item.additionalInformation}
                              required={item.required}
                              index={index}
                              inBuilder={true}
                              handleElementSelect={handleElementSelect}
                              selectedElement={selectedElement}
                              isSelectedItem={isSelectedItem}
                              handleRemoveElement={handleRemoveElement}
                              setActiveSpan={setActiveSpan}
                              activeSpan={activeSpan}
                              moveElement={moveElement}
                              handleDrawElements={handleDrawElements}
                         />
                    );
                    break;
               case ElementTypes.CHECKBOX:
                    context = (
                         <Elements.Checkbox
                              key={item.id}
                              id={item.id}
                              name={i18n.t('form_builder.elements.checkbox')}
                              type={ElementTypes.CHECKBOX}
                              label={item.label}
                              elementCode={item.elementCode}
                              layout={item.layout}
                              multipleSelections={item.multipleSelections}
                              choices={item.choices}
                              isMaster={item.is_master}
                              isValid={item.is_valid && item.is_valid_code && item.is_translated}
                              additionalInformation={item.additionalInformation}
                              required={item.required}
                              index={index}
                              inBuilder={true}
                              displayLayout={displayLayout}
                              handleElementSelect={handleElementSelect}
                              selectedElement={selectedElement}
                              isSelectedItem={isSelectedItem}
                              handleRemoveElement={handleRemoveElement}
                              setActiveSpan={setActiveSpan}
                              activeSpan={activeSpan}
                              moveElement={moveElement}
                              handleDrawElements={handleDrawElements}
                         />
                    );
                    break;
               case ElementTypes.RADIO:
                    context = (
                         <Elements.RadioSelection
                              key={item.id}
                              id={item.id}
                              name={i18n.t('form_builder.elements.radio')}
                              type={ElementTypes.RADIO}
                              label={item.label}
                              elementCode={item.elementCode}
                              layout={item.layout}
                              choices={item.choices}
                              isMaster={item.is_master}
                              isValid={item.is_valid && item.is_valid_code && item.is_translated}
                              additionalInformation={item.additionalInformation}
                              required={item.required}
                              inBuilder={true}
                              index={index}
                              displayLayout={displayLayout}
                              handleElementSelect={handleElementSelect}
                              selectedElement={selectedElement}
                              isSelectedItem={isSelectedItem}
                              handleRemoveElement={handleRemoveElement}
                              setActiveSpan={setActiveSpan}
                              activeSpan={activeSpan}
                              moveElement={moveElement}
                              handleDrawElements={handleDrawElements}
                         />
                    );
                    break;
               case ElementTypes.IMAGE:
                    context = (
                         <Elements.Image
                              key={item.id}
                              id={item.id}
                              name={i18n.t('form_builder.elements.image')}
                              type={ElementTypes.IMAGE}
                              label={item.label}
                              elementCode={item.elementCode}
                              elementType={item.elementType}
                              isMaster={item.is_master}
                              isValid={item.is_valid && item.is_valid_code && item.is_translated}
                              additionalInformation={item.additionalInformation}
                              required={item.required}
                              inBuilder={true}
                              index={index}
                              handleElementSelect={handleElementSelect}
                              selectedElement={selectedElement}
                              isSelectedItem={isSelectedItem}
                              handleRemoveElement={handleRemoveElement}
                              setActiveSpan={setActiveSpan}
                              activeSpan={activeSpan}
                              moveElement={moveElement}
                              handleDrawElements={handleDrawElements}
                         />
                    );
                    break;
               case ElementTypes.FILE_UPLOAD:
                    context = (
                         <Elements.FileUpload
                              id={item.id}
                              index={index}
                              key={item.id}
                              name={i18n.t('form_builder.elements.file_upload')}
                              type={ElementTypes.FILE_UPLOAD}
                              label={item.label}
                              elementCode={item.elementCode}
                              elementType={item.elementType}
                              additionalInformation={item.additionalInformation}
                              required={item.required}
                              isMaster={item.is_master}
                              isValid={item.is_valid && item.is_valid_code && item.is_translated}
                              inBuilder={true}
                              language={language}
                              handleElementSelect={handleElementSelect}
                              selectedElement={selectedElement}
                              isSelectedItem={isSelectedItem}
                              handleRemoveElement={handleRemoveElement}
                              setActiveSpan={setActiveSpan}
                              activeSpan={activeSpan}
                              moveElement={moveElement}
                              handleDrawElements={handleDrawElements}
                         />
                    );
                    break;
               case ElementTypes.DATE:
                    context = (
                         <Elements.Date
                              id={item.id}
                              index={index}
                              key={item.id}
                              name={i18n.t('form_builder.elements.date')}
                              type={ElementTypes.DATE}
                              label={item.label}
                              elementCode={item.elementCode}
                              placeholder={item.placeholder}
                              isMaster={item.is_master}
                              isValid={item.is_valid && item.is_valid_code && item.is_translated}
                              additionalInformation={item.additionalInformation}
                              required={item.required}
                              inBuilder={true}
                              handleElementSelect={handleElementSelect}
                              selectedElement={selectedElement}
                              isSelectedItem={isSelectedItem}
                              handleRemoveElement={handleRemoveElement}
                              setActiveSpan={setActiveSpan}
                              activeSpan={activeSpan}
                              moveElement={moveElement}
                              handleDrawElements={handleDrawElements}
                         />
                    );
                    break;
               case ElementTypes.TIME:
                    context = (
                         <Elements.Time
                              id={item.id}
                              index={index}
                              key={item.id}
                              name={i18n.t('form_builder.elements.time')}
                              type={ElementTypes.TIME}
                              label={item.label}
                              elementCode={item.elementCode}
                              timeFormat={item.timeFormat}
                              placeholder={item.placeholder}
                              isMaster={item.is_master}
                              isValid={item.is_valid && item.is_valid_code && item.is_translated}
                              additionalInformation={item.additionalInformation}
                              required={item.required}
                              inBuilder={true}
                              handleElementSelect={handleElementSelect}
                              selectedElement={selectedElement}
                              isSelectedItem={isSelectedItem}
                              handleRemoveElement={handleRemoveElement}
                              setActiveSpan={setActiveSpan}
                              activeSpan={activeSpan}
                              moveElement={moveElement}
                              handleDrawElements={handleDrawElements}
                         />
                    );
                    break;
               case ElementTypes.DROPDOWN:
                    context = (
                         <Elements.DropdownElement
                              id={item.id}
                              index={index}
                              key={item.id}
                              name={i18n.t('form_builder.elements.dropdown')}
                              type={ElementTypes.DROPDOWN}
                              label={item.label}
                              elementCode={item.elementCode}
                              choices={item.choices}
                              isMaster={item.is_master}
                              isValid={item.is_valid && item.is_valid_code && item.is_translated}
                              additionalInformation={item.additionalInformation}
                              required={item.required}
                              inBuilder={true}
                              language={language}
                              handleElementSelect={handleElementSelect}
                              selectedElement={selectedElement}
                              isSelectedItem={isSelectedItem}
                              handleRemoveElement={handleRemoveElement}
                              setActiveSpan={setActiveSpan}
                              activeSpan={activeSpan}
                              moveElement={moveElement}
                              handleDrawElements={handleDrawElements}
                         />
                    );
                    break;
               case ElementTypes.DIVIDER:
                    context = (
                         <Elements.Divider
                              key={item.id}
                              index={index}
                              id={item.id}
                              name={i18n.t('form_builder.elements.divider')}
                              type={ElementTypes.DIVIDER}
                              title={item.title}
                              text={item.text}
                              invert={item.invert}
                              isMaster={item.is_master}
                              isValid={item.is_translated}
                              inBuilder={true}
                              handleElementSelect={handleElementSelect}
                              selectedElement={selectedElement}
                              isSelectedItem={isSelectedItem}
                              handleRemoveElement={handleRemoveElement}
                              setActiveSpan={setActiveSpan}
                              activeSpan={activeSpan}
                              moveElement={moveElement}
                              handleDrawElements={handleDrawElements}
                         />
                    );
                    break;
          }

          return context;
     };

     const nonDefaultElements = elements.length > 0 && elements.filter((el) => el && !el.is_default);

     return (
          <div
               ref={drop}
               style={{
                    minHeight: '500px',
                    maxWidth: '910px',
               }}
               className={'form-builder-zone'}
          >
               {elements
                    .filter(({ lang }) => lang === currentLanguage)
                    .map((element, idx) => drawElements(element, idx))}

               {!readonly && nonDefaultElements.length === 0 && (
                    <Card.Body
                         className="information-box"
                         style={{ marginTop: '12px' }}
                    >
                         {i18n.t('alerts.primary.form_builder_start')}
                    </Card.Body>
               )}
          </div>
     );
};
