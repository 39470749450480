import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import Alert from '../../../common/alert';
import picture from '../../../../assets/images/illustration-ufo.svg';
import { RemovalNotificationsWrapper } from './RemovalNotifications.style';

export const SpeakerRemoved = memo(() => {
     const { t } = useTranslation();

     return (
          <RemovalNotificationsWrapper>
               <Helmet>
                    <title>Uh oh... | {t('avovent')}</title>
               </Helmet>
               <section className="speaker-notification-block container-standard">
                    <span>
                         <Alert>{t('alerts.primary.nothing_to_see')}</Alert>
                    </span>
                    <img
                         src={picture}
                         alt={t('removal_notifications.picture')}
                    />
               </section>
          </RemovalNotificationsWrapper>
     );
});
