import React, {useEffect, useRef} from 'react';
import { connect } from 'react-redux';
import {errorsRefresh} from "../../../../redux/reducers/errorsReducer";
import {requestSignup} from "../../../../redux/reducers/authReducer";
import {SIGNUP} from "../../../../constants/form.constants";
import {FormWrapper} from "../../../auth/auth-styles/RightBlock.style";
import {Button, Form} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Formik} from "formik";
import {useTranslation} from "react-i18next";
import {Input} from "../../../common/input/Input";
import Spinner from "react-bootstrap/Spinner";

const mapStateToProps = (state) => ({
     signupRequested: state.authReducer.signupRequested,
     router: state.router,
     user: state.userReducer,
     errors: state.errorsReducer.errors
});

const mapDispatchToProps = (dispatch) => ({
     requestSignup: (values) => dispatch(requestSignup(values)),
     refreshErrors: () => dispatch(errorsRefresh())
});

const _SignUpForm = ({
      errors,
      joinToken,
      invitationToken,
      refreshErrors,
      requestSignup,
      signupRequested,
      backLink = false,
      preFillData = null,
      toggleTransition = null,
      alreadyHaveAccount = false,
      tierId = null
     }) => {
     const signUpForm = useRef();
     const { t } = useTranslation();

     const handleSignupSubmit = (values) => {
          const parameters = {
               firstname: values.firstname,
               lastname: values.lastname,
               username: values.email,
               email: values.email,
               password: values.password,
               password_confirmation: values.password_confirmation
          };

          if (joinToken) {
               parameters.join = joinToken;
          }

          if (invitationToken) {
               parameters.invitation = invitationToken;
          }

          if (tierId) {
               parameters.tier_id = tierId;
          }

          requestSignup(parameters);
     };

     useEffect(() => {
          if (errors && Object.keys(errors).length > 0) {
               signUpForm.current.setSubmitting(false);
               signUpForm.current.setErrors(errors);

               refreshErrors();
          }
     }, [errors]);

     useEffect(() => {
          if (preFillData) {
               signUpForm.current.setFieldValue('firstname', preFillData.firstname);
               signUpForm.current.setFieldValue('lastname', preFillData.lastname);
               signUpForm.current.setFieldValue('email', preFillData.email);
          }
     }, [preFillData]);

     return (
         <Formik
             innerRef={signUpForm}
             validationSchema={SIGNUP.VALIDATION}
             onSubmit={handleSignupSubmit}
             initialValues={SIGNUP.INITIAL_VALUES}
             validateOnBlur={false}
         >
              {({
                     handleSubmit,
                     handleChange,
                     values,
                     touched,
                     errors,
                }) => (
                  <FormWrapper>
                       <h1>{t('sign_up.header')}</h1>
                       <Form noValidate onSubmit={(e) => { e.preventDefault(); handleSubmit(e); }}>
                            <Form.Group controlId="firstname">
                                 <Input
                                     type="text"
                                     field="firstname"
                                     values={values}
                                     placeholder={t('sign_up.placeholders.first_name')}
                                     handleChange={handleChange}
                                     touched={touched}
                                     errors={errors}
                                     disabled={signupRequested}
                                 />
                            </Form.Group>
                            <Form.Group controlId="lastname">
                                 <Input
                                     type="text"
                                     field="lastname"
                                     values={values}
                                     placeholder={t('sign_up.placeholders.last_name')}
                                     handleChange={handleChange}
                                     touched={touched}
                                     errors={errors}
                                     disabled={signupRequested}
                                 />
                            </Form.Group>
                            <Form.Group controlId="email">
                                 <Input
                                     type="email"
                                     field="email"
                                     values={values}
                                     placeholder={t('sign_up.placeholders.email')}
                                     handleChange={handleChange}
                                     touched={touched}
                                     errors={errors}
                                     disabled={signupRequested}
                                 />
                            </Form.Group>
                            <Form.Group controlId="password">
                                 <Input
                                     type="password"
                                     field="password"
                                     values={values}
                                     placeholder={t('sign_up.placeholders.password')}
                                     handleChange={handleChange}
                                     touched={touched}
                                     errors={errors}
                                     disabled={signupRequested}
                                 />
                            </Form.Group>
                            <Form.Group controlId="password_confirmation">
                                 <Input
                                     type="password"
                                     field="password_confirmation"
                                     values={values}
                                     placeholder={t('sign_up.placeholders.password_confirmation')}
                                     handleChange={handleChange}
                                     touched={touched}
                                     errors={errors}
                                     disabled={signupRequested}
                                 />
                            </Form.Group>
                            <hr className="divider" />
                            <div
                                className="form-actions"
                                style={{ justifyContent: backLink || alreadyHaveAccount ? 'space-between' : 'flex-end' }}
                            >
                                 {backLink && (
                                     <Link
                                         to={backLink}
                                     >
                                          <Button
                                              type="button"
                                              variant="default"
                                              onClick={toggleTransition ? toggleTransition : null}
                                          >
                                               {t('sign_up.buttons.back')}
                                          </Button>
                                     </Link>
                                 )}
                                   {alreadyHaveAccount && (
                                        <Link
                                             to={'/auth/login'}
                                        >
                                             {t('sign_up.buttons.already_have_account')}
                                        </Link>
                                   )}

                                 <Button
                                     type="submit"
                                     disabled={signupRequested}
                                 >
                                      {t('sign_up.buttons.create')}
                                      {signupRequested && (<Spinner animation="border" className="m-0 ml-2 p-0" size="sm" />)}
                                 </Button>
                            </div>
                       </Form>
                  </FormWrapper>
              )}
         </Formik>
     );
};

export const SignUpForm = connect(mapStateToProps, mapDispatchToProps)(_SignUpForm);
