/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { formatDate } from '../../../utils/date.utils';
import OrganizationBlock from '../../common/organization-block';
import {
     requestSpeakerConnect,
     requestInvitationDetails,
} from '../../../redux/reducers/speakerReducer';
import { requestUserProfile } from '../../../redux/reducers/userReducer';
import { InfoWrapper } from '../auth-styles/RightBlock.style';

const mapStateToProps = (state) => ({
     invitationDetailsData: state.speakerReducer.invitationDetailsData,
     currentUser: state.userReducer.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
     requestInvitationDetails: (token) =>
          dispatch(requestInvitationDetails(token)),
     requestSpeakerConnect: (token) => dispatch(requestSpeakerConnect(token)),
     requestUserProfile: () => dispatch(requestUserProfile()),
});

export const _LinkToAccount = memo((props) => {
     const {
          requestSpeakerConnect,
          invitationDetailsData,
          requestInvitationDetails,
          requestUserProfile,
          currentUser,
     } = props;
     const { t } = useTranslation();
     const { joinToken } = useParams();

     const [invitationDetails, setInvitationDetails] = useState();

     useEffect(() => {
          if (!invitationDetailsData) {
               requestInvitationDetails(joinToken);
          }
     }, [invitationDetailsData, joinToken]);

     useEffect(() => {
          if (!currentUser) {
               requestUserProfile();
          }
     }, [currentUser]);

     useEffect(() => {
          if (invitationDetailsData) {
               if (
                    invitationDetailsData.data &&
                    invitationDetailsData.data.length
               ) {
                    return setInvitationDetails(invitationDetailsData.data);
               }

               setInvitationDetails(invitationDetailsData);
          }
     }, [invitationDetailsData]);

     if (!invitationDetails) return <></>;

     return (
          <InfoWrapper>
               <Helmet>
                    <title>
                         {t('link_to_user_account')} | {t('avovent')}
                    </title>
               </Helmet>
               <OrganizationBlock
                    picture={invitationDetails.event.photos['180x180']}
                    pictureAltText={t('link_to_account.logo')}
                    title={invitationDetails.event.name}
                    subtitle={formatDate({
                         startDate: invitationDetails.event.start,
                         endDate: invitationDetails.event.end,
                    })}
               >
                    <p>
                         {t('link_to_account.want_to_link')}{' '}
                         <b>{invitationDetails.event.name}</b>{' '}
                         {t('link_to_account.invitation')}{' '}
                         <b>{currentUser && currentUser.email}</b>{' '}
                         {t('link_to_account.account')}
                    </p>
               </OrganizationBlock>
               <Button
                    className="form-actions"
                    variant="primary"
                    onClick={() => requestSpeakerConnect(joinToken)}
               >
                    {t('link_to_account.button')}
               </Button>
               <div className="link-block">
                    {t('link_to_account.not_link_invitation_to_account')}{' '}
                    <a href="#">{t('link_to_account.guest')}</a>
               </div>
          </InfoWrapper>
     );
});

export const LinkToAccount = connect(
     mapStateToProps,
     mapDispatchToProps
)(_LinkToAccount);
