import React from 'react';

function IconParagraph() {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="18"
               height="12"
               fill="none"
               viewBox="0 0 18 12"
          >
               <path
                    fill="currentColor"
                    d="M18 10H0v2h18v-2zM18 5H0v2h18V5zM18 0H0v2h18V0z"
               ></path>
          </svg>
     );
}

export default IconParagraph;
