import { fork } from 'redux-saga/effects';
import { watchAuth } from './authSaga';
import { watchUser } from './userSaga';
import { watchOrganization } from './organizationSaga';
import { watchEvent } from './eventSaga';
import { watchFormBuilder } from './formBuilderSaga';
import { watchSpeaker } from './speakerSaga';
import { watchReminder } from './reminderSaga';
import { watchEmailTemplates } from './emailTemplatesSaga';

export default function* rootSaga() {
     yield fork(watchAuth);
     yield fork(watchUser);
     yield fork(watchOrganization);
     yield fork(watchEvent);
     yield fork(watchFormBuilder);
     yield fork(watchSpeaker);
     yield fork(watchReminder);
     yield fork(watchEmailTemplates);
}
