import React, { useState, useRef, useEffect } from 'react';
import i18n from 'i18next';
import { findDOMNode } from 'react-dom';
import { CustomTimePickerWrapper } from './CustomTimePicker.style';
import * as moment from "moment";

export const CustomTimePicker = ({
     timeFormat = 12,
     placeholderText = '',
     handleChange = null,
     required = false,
     value = null,
     isInvalid = false,
     languageCode
}) => {
     const timepicker = useRef();

     const formatMinutes = 'mm';
     const format12Hours = 'hh';
     const format24Hours = 'HH';
     const format12 = `${format12Hours}:${formatMinutes} a`;
     const format24 = `${format24Hours}:${formatMinutes}`;

     const [state, setState] = useState({
          initializing: true,
          showPicker: false,
          // hours: timeFormat === 12 ? '12' : '00',
          // minutes: '00',
          // meridian: 'AM',
          hours: null,
          minutes: null,
          meridian: null,
     });

     useEffect(() => {
          document.addEventListener('click', handleClickOutside, false);

          return () =>
               document.removeEventListener('click', handleClickOutside, false);
     });

     useEffect(() => {
          if (value) {
               const time = moment(value.toLowerCase(), timeFormat === 12 ? format12 : format24);

               setState({
                    ...state,
                    hours: time.format(timeFormat === 12 ? format12Hours : format24Hours),
                    minutes: time.format(formatMinutes),
                    meridian: time.format('A')
               });
          }
     }, [value]);

     useEffect(() => {
          if (handleChange && !state.initializing) {
               const value = timeFormat === 24
                   ? `${state.hours}:${state.minutes}`
                   : `${state.hours}:${state.minutes} ${state.meridian}`;

               handleChange(value);
          }
     }, [state]);

     const toggleTimePicker = () =>
          setState({
               ...state,
               showPicker: !state.showPicker,
               initializing: false,
               hours: state.hours ? state.hours : (timeFormat === 12 ? '12' : '00'),
               minutes: state.minutes ? state.minutes : '00',
               meridian: state.meridian ? state.meridian : 'AM',
          });

     const handleClickOutside = (e) => {
          const domNode = findDOMNode(timepicker.current);

          if (showPicker && (!domNode || !domNode.contains(e.target))) {
               setState({
                    ...state,
                    showPicker: false,
               });
          }
     };

     const incrementHours = () => {
          const { hours, meridian } = state;
          let newHours = 0;

          if (timeFormat === 12 && hours >= 12) {
               return setState({
                    ...state,
                    hours: (newHours + 1).toString(),
                    meridian: meridian === 'AM' ? 'PM' : 'AM',
               });
          }

          if (timeFormat === 24 && hours >= 23) {
               newHours = '00';

               return setState({
                    ...state,
                    hours: newHours,
               });
          }

          return setState({
               ...state,
               hours: Number(state.hours) + 1,
          });
     };

     const incrementMinutes = () => {
          const { minutes, meridian } = state;
          let newHours = Number(state.hours);

          if (timeFormat === 12 && Number(minutes) >= 45) {
               return setState({
                    ...state,
                    minutes: '00',
                    hours: newHours >= 12 ? '1' : newHours + 1,
                    meridian:
                         meridian === 'AM' && Number(state.hours >= timeFormat)
                              ? 'PM'
                              : 'AM',
               });
          }

          if (timeFormat === 24 && Number(minutes) >= 45) {
               return setState({
                    ...state,
                    minutes: '00',
                    hours: newHours >= 23 ? '00' : newHours + 1,
               });
          }

          return setState({
               ...state,
               minutes: (Number(state.minutes) + 15).toString(),
          });
     };

     const decrementHours = () => {
          const { hours, meridian } = state;
          let newHours = Number(hours);

          if (timeFormat === 12 && hours <= 1) {
               return setState({
                    ...state,
                    hours: newHours <= 1 ? '12' : newHours - 1,
                    meridian: meridian === 'AM' ? 'PM' : 'AM',
               });
          }

          if (timeFormat === 24 && hours === 1) {
               const h = newHours <= 1 ? '00' : newHours - 1;

               return setState({
                    ...state,
                    hours: h,
               });
          }

          if (timeFormat === 24 && hours <= 0) {
               const h = '23';

               return setState({
                    ...state,
                    hours: h,
               });
          }

          return setState({
               ...state,
               hours: Number(state.hours) - 1,
          });
     };

     const decrementMinutes = () => {
          const { minutes, meridian } = state;
          let newHours = Number(state.hours);
          let newMinutes = 60;

          if (timeFormat === 12 && Number(minutes) <= 1) {
               return setState({
                    ...state,
                    minutes: (newMinutes - 15).toString(),
                    hours: newHours <= 1 ? timeFormat : newHours - 1,
                    meridian:
                         meridian === 'AM' && Number(state.hours <= 1)
                              ? 'PM'
                              : 'AM',
               });
          }

          if (timeFormat === 24 && Number(minutes) <= 1) {
               const transformHours = () => {
                    if (newHours === 1 && Number(minutes) <= 1) {
                         return '00';
                    }

                    if (newHours <= 0) {
                         return '23';
                    }

                    return newHours - 1;
               };

               return setState({
                    ...state,
                    minutes: (newMinutes - 15).toString(),
                    hours: transformHours(),
               });
          }

          return setState({
               ...state,
               minutes:
                    minutes === '15' ? '00' : (Number(minutes) - 15).toString(),
          });
     };

     const toggleMeridian = () =>
          setState({
               ...state,
               meridian: state.meridian === 'AM' ? 'PM' : 'AM',
          });

     const handleInputChange = ({ target: { name, value } }) => {
          let updatingValue;

          if (name === 'meridian' && timeFormat === 12) {
               updatingValue = value.slice(-1);

               value =
                   updatingValue === 'a' && updatingValue !== 'p'
                       ? 'AM'
                       : 'PM';

               return setState({
                    ...state,
                    meridian: value,
               });
          }

          if (name !== 'meridian' && value && value.length <= 2) {
               return setState({
                    ...state,
                    [name]: value,
               });
          }
     };

     const { initializing, showPicker, hours, meridian, minutes } = state;

     const formatPickerValue = () => {
          if (minutes && hours) {
               let m = minutes;
               let h = hours;

               if (m < 10 && m !== '00') {
                    m = `0${m}`;
               }

               return timeFormat === 12
                   ? `${hours}:${m} ${meridian}`
                   : `${hours}:${m}`;
          }

          return null;
     };

     return (
          <CustomTimePickerWrapper>
               <div
                    className="input-group"
                    // onBlur={toggleTimePicker}
               >
                    <input
                         className={`form-control ${isInvalid ? "is-invalid" : ""}`}
                         value={formatPickerValue()}
                         onClick={toggleTimePicker}
                         onChange={() => {}}
                         placeholder={placeholderText || i18n.t('time_picker.select_time', { lng: languageCode })}
                    />
                    {showPicker && (
                         <div
                              ref={timepicker}
                              className="timepicker-widget"
                              style={{ zIndex: 10 }}
                         >
                              <div className="timepicker-item">
                                   <span
                                        className="action-button"
                                        role="button"
                                        onClick={incrementHours}
                                   >
                                        <i className="la la-angle-up" />
                                   </span>
                                   <input
                                        required={required}
                                        className="timepicker-input"
                                        name="hours"
                                        type="text"
                                        value={hours}
                                        onChange={handleInputChange}
                                   />
                                   <span
                                        className="action-button"
                                        role="button"
                                        onClick={decrementHours}
                                   >
                                        <i className="la la-angle-down" />
                                   </span>
                              </div>
                              <span className="timepicker-separator">:</span>
                              <div className="timepicker-item">
                                   <span
                                        className="action-button"
                                        role="button"
                                        onClick={incrementMinutes}
                                   >
                                        <i className="la la-angle-up" />
                                   </span>
                                   <input
                                        required={required}
                                        className="timepicker-input"
                                        name="minutes"
                                        type="text"
                                        value={minutes}
                                        onChange={handleInputChange}
                                   />
                                   <span
                                        className="action-button"
                                        role="button"
                                        onClick={decrementMinutes}
                                   >
                                        <i className="la la-angle-down" />
                                   </span>
                              </div>
                              <span className="timepicker-separator"> </span>
                              {timeFormat === 12 && (
                                   <div className="timepicker-item">
                                        <span
                                             className="action-button"
                                             role="button"
                                             onClick={toggleMeridian}
                                        >
                                             <i className="la la-angle-up" />
                                        </span>
                                        <input
                                             required={required}
                                             className="timepicker-input"
                                             name="meridian"
                                             type="text"
                                             value={meridian}
                                             onChange={handleInputChange}
                                        />
                                        <span
                                             className="action-button"
                                             role="button"
                                             onClick={toggleMeridian}
                                        >
                                             <i className="la la-angle-down" />
                                        </span>
                                   </div>
                              )}
                         </div>
                    )}
                    {!isInvalid && (
                        <span className="suffix">
                         <i className="la la-clock-o"></i>
                    </span>
                    )}
               </div>
          </CustomTimePickerWrapper>
     );
};
