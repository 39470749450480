import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import {SignUpForm} from "../../main/UserSettings/forms/SignUpForm";
import {requestInvitationDetails} from "../../../redux/reducers/speakerReducer";
import {requestUserProfile} from "../../../redux/reducers/userReducer";
import {InfoWrapper} from "../auth-styles/RightBlock.style";
import toastr from "toastr";
import i18n from "i18next";
import { Trans } from 'react-i18next';
import {setLocale} from "../../../services/instance";

const mapStateToProps = (state) => ({
     auth: state.authReducer,
     router: state.router,
     invitationDetailsData: state.speakerReducer.invitationDetailsData,
     invitationDetailsFailed: state.speakerReducer.invitationDetailsFailed,
     user: state.userReducer.currentUser,
     userProfileFailed: state.userReducer.userProfileFailed,
     languages: state.userReducer.languages,
});

const mapDispatchToProps = (dispatch) => ({
     requestInvitationDetails: (token) => dispatch(requestInvitationDetails(token)),
     requestUserProfile: () => dispatch(requestUserProfile()),
});

export const _SpeakerRegister = (props) => {
     const {
          user,
          invitationDetailsData,
          requestInvitationDetails,
          invitationDetailsFailed,
          userProfileFailed,
          requestUserProfile,
          languages
     } = props;

     const { invitationToken } = useParams();
     const history = useHistory();
     const [preFillData, setPreFillData] = useState({});

     useEffect(() => {
          requestUserProfile();

          if (user) {
               toastr.success(i18n.t('alerts.success.you_already_have_an_account'));
               history.push(`/dashboard`);
          }
     }, [user, history]);

     useEffect(() => {
          if (invitationDetailsFailed) {
               history.push(`/`);
          }
     }, [invitationDetailsFailed]);

     useEffect(() => {
          if (!user && invitationToken) {
               requestInvitationDetails(invitationToken);
          }
     }, [invitationToken]);

     useEffect(() => {
          if (invitationDetailsData) {
               const invitationLanguage = languages.find((lang) => lang.id === invitationDetailsData.language_id);

               setLocale(invitationLanguage);
               setPreFillData(invitationDetailsData.basic);
          }
     }, [invitationDetailsData]);

     return (
         userProfileFailed && !invitationDetailsFailed &&
         !user && (
             <>
              <InfoWrapper>
                   <div className="link-block bottom">
                        <div>
                        <Trans i18nKey='sign_up.speaker.create_a_free_account' />
                        </div>
                        <div>
                         <Trans i18nKey='sign_up.speaker.create_a_password_to_finish' />
                        </div>
                   </div>
              </InfoWrapper>
               <div className="transition-group-wrapper center">
                    <SignUpForm preFillData={preFillData} invitationToken={invitationToken} />
               </div>
             </>
          )
     );
};

export const SpeakerRegister = connect(
     mapStateToProps,
     mapDispatchToProps
)(_SpeakerRegister);
