export const ElementTypes = {
     TITLE: 'title',
     PARAGRAPH: 'paragraph',
     TEXT_FIELD: 'text',
     TEXT_AREA: 'text_area',
     CHECKBOX: 'checkbox',
     RADIO: 'radio_selection',
     IMAGE: 'image',
     FILE_UPLOAD: 'file',
     DATE: 'date',
     TIME: 'time',
     DROPDOWN: 'dropdown',
     DIVIDER: 'divider',
};

export const DND_TYPES = [
     ElementTypes.TITLE,
     ElementTypes.PARAGRAPH,
     ElementTypes.TEXT_FIELD,
     ElementTypes.TEXT_AREA,
     ElementTypes.CHECKBOX,
     ElementTypes.RADIO,
     ElementTypes.IMAGE,
     ElementTypes.FILE_UPLOAD,
     ElementTypes.DATE,
     ElementTypes.TIME,
     ElementTypes.DROPDOWN,
     ElementTypes.DIVIDER,
];
