// Errors
export const ERRORS_REFRESH = 'ERRORS_REFRESH';
export const ERRORS_DISPLAY = 'ERRORS_DISPLAY';

// Auth
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const CLEAR_LOGIN = 'CLEAR_LOGIN';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';
export const FORGOTTEN_PASSWORD_REQUEST = 'FORGOTTEN_PASSWORD_REQUEST';
export const FORGOTTEN_PASSWORD_SUCCESS = 'FORGOTTEN_PASSWORD_SUCCESS';
export const FORGOTTEN_PASSWORD_FAILURE = 'FORGOTTEN_PASSWORD_FAILURE';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const FETCH_USER_AVATAR_AFTER_REGISTRATION = 'FETCH_USER_AVATAR_AFTER_REGISTRATION';
// User
export const RESET_USER_TO_INITIAL_STATE = 'RESET_USER_TO_INITIAL_STATE';
export const USER_CLEAR_MESSAGES = 'USER_CLEAR_MESSAGES';
export const USER_AUTHORIZED_REQUEST = 'USER_AUTHORIZED_REQUEST';
export const USER_AUTHORIZED_SUCCESS = 'USER_AUTHORIZED_SUCCESS';
export const USER_AUTHORIZED_FAILURE = 'USER_AUTHORIZED_FAILURE';
export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST';
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';
export const USER_PROFILE_FAILURE = 'USER_PROFILE_FAILURE';
export const USER_AVATAR_REQUEST = 'USER_AVATAR_REQUEST';
export const USER_AVATAR_SUCCESS = 'USER_AVATAR_SUCCESS';
export const USER_AVATAR_FAILURE = 'USER_AVATAR_FAILURE';
export const USER_AVATAR_UPLOAD_REQUEST = 'USER_AVATAR_UPLOAD_REQUEST';
export const USER_AVATAR_UPLOAD_SUCCESS = 'USER_AVATAR_UPLOAD_SUCCESS';
export const USER_AVATAR_UPLOAD_FAILURE = 'USER_AVATAR_UPLOAD_FAILURE';
export const UPDATE_USER_PROFILE_REQUEST = 'UPDATE_USER_PROFILE_REQUEST';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAILURE = 'UPDATE_USER_PROFILE_FAILURE';
export const CHANGE_USER_PASSWORD_REQUEST = 'CHANGE_USER_PASSWORD_REQUEST';
export const CHANGE_USER_PASSWORD_SUCCESS = 'CHANGE_USER_PASSWORD_SUCCESS';
export const CHANGE_USER_PASSWORD_FAILURE = 'CHANGE_USER_PASSWORD_FAILURE';
export const CHANGE_USER_EMAIL_REQUEST = 'CHANGE_USER_EMAIL_REQUEST';
export const CHANGE_USER_EMAIL_SUCCESS = 'CHANGE_USER_EMAIL_SUCCESS';
export const CHANGE_USER_EMAIL_FAILURE = 'CHANGE_USER_EMAIL_FAILURE';
export const REFRESH_CURRENT_USER_AVATAR = 'REFRESH_CURRENT_USER_AVATAR';
export const REMOVE_AVATAR_REQUEST = 'REMOVE_AVATAR_REQUEST';
export const REMOVE_AVATAR_SUCCESS = 'REMOVE_AVATAR_SUCCESS';
export const REMOVE_AVATAR_FAILURE = 'REMOVE_AVATAR_FAILURE';
export const AVAILABLE_LANGUAGES_REQUEST = 'AVAILABLE_LANGUAGES_REQUEST';
export const AVAILABLE_LANGUAGES_SUCCESS = 'AVAILABLE_LANGUAGES_SUCCESS';
export const AVAILABLE_LANGUAGES_FAILURE = 'AVAILABLE_LANGUAGES_FAILURE';
export const CONTACT_US_REQUEST = 'CONTACT_US_REQUEST';
export const CONTACT_US_SUCCESS = 'CONTACT_US_SUCCESS';
export const CONTACT_US_FAILURE = 'CONTACT_US_FAILURE';
export const USER_AVATAR_GENERATE_TEMP_REQUEST = 'USER_AVATAR_GENERATE_TEMP_REQUEST';
export const USER_AVATAR_GENERATE_TEMP_SUCCESS = 'USER_AVATAR_GENERATE_TEMP_SUCCESS';
export const USER_AVATAR_GENERATE_TEMP_FAILURE = 'USER_AVATAR_GENERATE_TEMP_FAILURE';
//Organization
export const ORGANIZATION_CLEAR_MESSAGES = 'ORGANIZATION_CLEAR_MESSAGES';
export const USER_ORGANIZATION_REQUEST = 'USER_ORGANIZATION_REQUEST';
export const USER_ORGANIZATION_SUCCESS = 'USER_ORGANIZATION_SUCCESS';
export const USER_ORGANIZATION_FAILURE = 'USER_ORGANIZATION_FAILURE';
export const ORGANIZATION_DETAILS_REQUEST = 'ORGANIZATION_DETAILS_REQUEST';
export const ORGANIZATION_DETAILS_SUCCESS = 'ORGANIZATION_DETAILS_SUCCESS';
export const ORGANIZATION_DETAILS_FAILURE = 'ORGANIZATION_DETAILS_FAILURE';
export const ORGANIZATION_CREATION_REQUEST = 'ORGANIZATION_CREATION_REQUEST';
export const ORGANIZATION_CREATION_SUCCESS = 'ORGANIZATION_CREATION_SUCCESS';
export const ORGANIZATION_CREATION_FAILURE = 'ORGANIZATION_CREATION_FAILURE';
export const UPDATE_ORGANIZATION_DETAILS_REQUEST =
     'UPDATE_ORGANIZATION_DETAILS_REQUEST';
export const UPDATE_ORGANIZATION_DETAILS_SUCCESS =
     'UPDATE_ORGANIZATION_DETAILS_SUCCESS';
export const UPDATE_ORGANIZATION_DETAILS_FAILURE =
     'UPDATE_ORGANIZATION_DETAILS_FAILURE';
export const ORGANIZATION_PHOTO_UPLOAD_REQUEST =
     'ORGANIZATION_PHOTO_UPLOAD_REQUEST';
export const ORGANIZATION_PHOTO_UPLOAD_SUCCESS =
     ' ORGANIZATION_PHOTO_UPLOAD_SUCCESS';
export const ORGANIZATION_PHOTO_UPLOAD_FAILURE =
     'ORGANIZATION_PHOTO_UPLOAD_FAILURE';
export const ORGANIZATION_MEMBERS_REQUEST = 'ORGANIZATION_MEMBERS_REQUEST';
export const ORGANIZATION_MEMBERS_SUCCESS = 'ORGANIZATION_MEMBERS_SUCCESS';
export const ORGANIZATION_MEMBERS_FAILURE = 'ORGANIZATION_MEMBERS_FAILURE';
export const GET_MEMBERS_PAGE_DETAILS = 'GET_MEMBERS_PAGE_DETAILS';
export const UPDATE_MEMBER_SCOPE_REQUEST = 'UPDATE_MEMBER_SCOPE_REQUEST';
export const UPDATE_MEMBER_SCOPE_SUCCESS = 'UPDATE_MEMBER_SCOPE_SUCCESS';
export const UPDATE_MEMBER_SCOPE_FAILURE = 'UPDATE_MEMBER_SCOPE_FAILURE';
export const INVITE_MEMBERS_REQUEST = 'INVITE_MEMBERS_REQUEST';
export const INVITE_MEMBERS_SUCCESS = 'INVITE_MEMBERS_SUCCESS';
export const INVITE_MEMBERS_FAILURE = 'INVITE_MEMBERS_FAILURE';
export const SEARCH_MEMBERS_REQUEST = 'SEARCH_MEMBERS_REQUEST';
export const SEARCH_MEMBERS_SUCCESS = 'SEARCH_MEMBERS_SUCCESS';
export const SEARCH_MEMBERS_FAILURE = 'SEARCH_MEMBERS_FAILURE';
export const REMOVE_MEMBER_REQUEST = 'REMOVE_MEMBER_REQUEST';
export const REMOVE_MEMBER_SUCCESS = 'REMOVE_MEMBER_SUCCESS';
export const REMOVE_MEMBER_FAILURE = 'REMOVE_MEMBER_FAILURE';
export const ORGANIZATION_MEMBERS_INVITATIONS_REQUEST = 'ORGANIZATION_MEMBERS_INVITATIONS_REQUEST';
export const ORGANIZATION_MEMBERS_INVITATIONS_SUCCESS = 'ORGANIZATION_MEMBERS_INVITATIONS_SUCCESS';
export const ORGANIZATION_MEMBERS_INVITATIONS_FAILURE = 'ORGANIZATION_MEMBERS_INVITATIONS_FAILURE';
export const GET_MEMBERS_INVITATIONS_PAGE_DETAILS = 'GET_MEMBERS_INVITATIONS_PAGE_DETAILS';
export const ORGANIZATION_MEMBERS_INVITATIONS_RESEND_REQUEST = 'ORGANIZATION_MEMBERS_INVITATIONS_RESEND_REQUEST';
export const ORGANIZATION_MEMBERS_INVITATIONS_RESEND_SUCCESS = 'ORGANIZATION_MEMBERS_INVITATIONS_RESEND_SUCCESS';
export const ORGANIZATION_MEMBERS_INVITATIONS_RESEND_FAILURE = 'ORGANIZATION_MEMBERS_INVITATIONS_RESEND_FAILURE';
export const ORGANIZATION_MEMBERS_INVITATIONS_REMOVE_REQUEST = 'ORGANIZATION_MEMBERS_INVITATIONS_REMOVE_REQUEST';
export const ORGANIZATION_MEMBERS_INVITATIONS_REMOVE_SUCCESS = 'ORGANIZATION_MEMBERS_INVITATIONS_REMOVE_SUCCESS';
export const ORGANIZATION_MEMBERS_INVITATIONS_REMOVE_FAILURE = 'ORGANIZATION_MEMBERS_INVITATIONS_REMOVE_FAILURE';
export const ORGANIZATION_EMAIL_SENDERS_REQUEST =
     'ORGANIZATION_EMAIL_SENDERS_REQUEST';
export const ORGANIZATION_EMAIL_SENDERS_SUCCESS =
     'ORGANIZATION_EMAIL_SENDERS_SUCCESS';
export const ORGANIZATION_EMAIL_SENDERS_FAILURE =
     'ORGANIZATION_EMAIL_SENDERS_FAILURE';
export const ORGANIZATION_EMAIL_SENDERS_ALL_REQUEST =
    'ORGANIZATION_EMAIL_SENDERS_ALL_REQUEST';
export const ORGANIZATION_EMAIL_SENDERS_ALL_SUCCESS =
    'ORGANIZATION_EMAIL_SENDERS_ALL_SUCCESS';
export const ORGANIZATION_EMAIL_SENDERS_ALL_FAILURE =
    'ORGANIZATION_EMAIL_SENDERS_ALL_FAILURE';
export const GET_EMAIL_SENDERS_PAGE_DETAILS = 'GET_EMAIL_SENDERS_PAGE_DETAILS';
export const CREATE_EMAIL_SENDER_REQUEST = 'CREATE_EMAIL_SENDER_REQUEST';
export const CREATE_EMAIL_SENDER_SUCCESS = 'CREATE_EMAIL_SENDER_SUCCESS';
export const CREATE_EMAIL_SENDER_FAILURE = 'CREATE_EMAIL_SENDER_FAILURE';
export const VERIFY_EMAIL_SENDER_REQUEST = 'VERIFY_EMAIL_SENDER_REQUEST';
export const VERIFY_EMAIL_SENDER_SUCCESS = 'VERIFY_EMAIL_SENDER_SUCCESS';
export const VERIFY_EMAIL_SENDER_FAILURE = 'VERIFY_EMAIL_SENDER_FAILURE';
export const VERIFY_RESEND_EMAIL_SENDER_REQUEST = 'VERIFY_RESEND_EMAIL_SENDER_REQUEST';
export const VERIFY_RESEND_EMAIL_SENDER_SUCCESS = 'VERIFY_RESEND_EMAIL_SENDER_SUCCESS';
export const VERIFY_RESEND_EMAIL_SENDER_FAILURE = 'VERIFY_RESEND_EMAIL_SENDER_FAILURE';
export const REMOVE_EMAIL_SENDER_REQUEST = 'REMOVE_EMAIL_SENDER_REQUEST';
export const REMOVE_EMAIL_SENDER_SUCCESS = 'REMOVE_EMAIL_SENDER_SUCCESS';
export const REMOVE_EMAIL_SENDER_FAILURE = 'REMOVE_EMAIL_SENDER_FAILURE';
export const SEARCH_EMAIL_SENDERS_REQUEST = 'SEARCH_EMAIL_SENDERS_REQUEST';
export const SEARCH_EMAIL_SENDERS_SUCCESS = 'SEARCH_EMAIL_SENDERS_SUCCESS';
export const SEARCH_EMAIL_SENDERS_FAILURE = 'SEARCH_EMAIL_SENDERS_FAILURE';
export const REFRESH_ORGANIZATION_AVATAR = 'REFRESH_ORGANIZATION_AVATAR';
export const REMOVE_ORGANIZATION_AVATAR_REQUEST =
     'REMOVE_ORGANIZATION_AVATAR_REQUEST';
export const REMOVE_ORGANIZATION_AVATAR_SUCCESS =
     'REMOVE_ORGANIZATION_AVATAR_SUCCESS';
export const REMOVE_ORGANIZATION_AVATAR_FAILURE =
     'REMOVE_ORGANIZATION_AVATAR_FAILURE';
//Event
export const EVENT_CLEAR_MESSAGES = 'EVENT_CLEAR_MESSAGES';
export const ORGANIZATION_EVENTS_REQUEST = 'ORGANIZATION_EVENTS_REQUEST';
export const ORGANIZATION_EVENTS_SUCCESS = 'ORGANIZATION_EVENTS_SUCCESS';
export const ORGANIZATION_EVENTS_FAILURE = 'ORGANIZATION_EVENTS_FAILURE';
export const GET_ORGANIZATION_EVENTS_PAGE_DETAILS =
     'GET_ORGANIZATION_EVENTS_PAGE_DETAILS';
export const CREATE_NEW_EVENT_REQUEST = 'CREATE_NEW_EVENT_REQUEST';
export const CREATE_NEW_EVENT_SUCCESS = 'CREATE_NEW_EVENT_SUCCESS';
export const CREATE_NEW_EVENT_FAILURE = 'CREATE_NEW_EVENT_FAILURE';
export const EVENT_DETAILS_REQUEST = 'EVENT_DETAILS_REQUEST';
export const EVENT_DETAILS_SUCCESS = 'EVENT_DETAILS_SUCCESS';
export const EVENT_DETAILS_FAILURE = 'EVENT_DETAILS_FAILURE';
export const UPDATE_EVENT_DETAILS_REQUEST = 'UPDATE_EVENT_DETAILS_REQUEST';
export const UPDATE_EVENT_DETAILS_SUCCESS = 'UPDATE_EVENT_DETAILS_SUCCESS';
export const UPDATE_EVENT_DETAILS_FAILURE = 'UPDATE_EVENT_DETAILS_FAILURE';
export const SEARCH_EVENT_REQUEST = 'SEARCH_EVENT_REQUEST';
export const SEARCH_EVENT_SUCCESS = 'SEARCH_EVENT_SUCCESS';
export const SEARCH_EVENT_FAILURE = 'SEARCH_EVENT_FAILURE';
export const CANCEL_EVENT_REQUEST = 'CANCEL_EVENT_REQUEST';
export const CANCEL_EVENT_SUCCESS = 'CANCEL_EVENT_SUCCESS';
export const CANCEL_EVENT_FAILURE = 'CANCEL_EVENT_FAILURE';
export const UPLOAD_EVENT_PHOTO_REQUEST = 'UPLOAD_EVENT_PHOTO_REQUEST';
export const UPLOAD_EVENT_PHOTO_SUCCESS = 'UPLOAD_EVENT_PHOTO_SUCCESS';
export const UPLOAD_EVENT_PHOTO_FAILURE = 'UPLOAD_EVENT_PHOTO_FAILURE';
export const UPLOAD_EVENT_PHOTO_TEMPORARY_REQUEST = 'UPLOAD_EVENT_PHOTO_TEMPORARY_REQUEST';
export const UPLOAD_EVENT_PHOTO_TEMPORARY_SUCCESS = 'UPLOAD_EVENT_PHOTO_TEMPORARY_SUCCESS';
export const UPLOAD_EVENT_PHOTO_TEMPORARY_FAILURE = 'UPLOAD_EVENT_PHOTO_TEMPORARY_FAILURE';
export const TEMPORARY_PHOTO_RESET = 'TEMPORARY_PHOTO_RESET';
export const DISPLAY_JOIN_INVITATION_REQUEST =
     'DISPLAY_JOIN_INVITATION_REQUEST';
export const DISPLAY_JOIN_INVITATION_SUCCESS =
     'DISPLAY_JOIN_INVITATION_SUCCESS';
export const DISPLAY_JOIN_INVITATION_FAILURE =
     'DISPLAY_JOIN_INVITATION_FAILURE';
export const JOIN_ORGANIZATION_REQUEST = 'JOIN_ORGANIZATION_REQUEST';
export const JOIN_ORGANIZATION_SUCCESS = 'JOIN_ORGANIZATION_SUCCESS';
export const JOIN_ORGANIZATION_FAILURE = 'JOIN_ORGANIZATION_FAILURE';
export const REFRESH_EVENT_AVATAR = 'REFRESH_EVENT_AVATAR';
export const REMOVE_EVENT_AVATAR_REQUEST = 'REMOVE_EVENT_AVATAR_REQUEST';
export const REMOVE_EVENT_AVATAR_SUCCESS = 'REMOVE_EVENT_AVATAR_SUCCESS';
export const REMOVE_EVENT_AVATAR_FAILURE = 'REMOVE_EVENT_AVATAR_FAILURE';
export const MODULE_DOWN = 'MODULE_DOWN';
//Form-builder
export const FORM_BUILDER_CLEAR_MESSAGES = 'FORM_BUILDER_CLEAR_MESSAGES';
export const CREATE_FORM_REQUEST = 'CREATE_FORM_REQUEST';
export const CREATE_FORM_SUCCESS = 'CREATE_FORM_SUCCESS';
export const CREATE_FORM_FAILURE = 'CREATE_FORM_FAILURE';
export const CLEAN_CREATED_FORM_DATA = 'CLEAN_CREATED_FORM_DATA';
export const EVENT_FORMS_REQUEST = 'EVENT_FORMS_REQUEST';
export const EVENT_FORMS_SUCCESS = 'EVENT_FORMS_SUCCESS';
export const EVENT_FORMS_FAILURE = 'EVENT_FORMS_FAILURE';
export const GET_EVENT_fORMS_PAGE_DETAILS = 'GET_EVENT_fORMS_PAGE_DETAILS';
export const SEARCH_FORMS_REQUEST = 'SEARCH_FORMS_REQUEST';
export const SEARCH_FORMS_SUCCESS = 'SEARCH_FORMS_SUCCESS';
export const SEARCH_FORMS_FAILURE = 'SEARCH_FORMS_FAILURE';
export const REMOVE_FORM_REQUEST = 'REMOVE_FORM_REQUEST';
export const REMOVE_FORM_SUCCESS = 'REMOVE_FORM_SUCCESS';
export const REMOVE_FORM_FAILURE = 'REMOVE_FORM_FAILURE';
export const FORM_FIELDS_REQUEST = 'FORM_FIELDS_REQUEST';
export const FORM_FIELDS_SUCCESS = 'FORM_FIELDS_SUCCESS';
export const FORM_FIELDS_FAILURE = 'FORM_FIELDS_FAILURE';
export const FORM_DETAILS_REQUEST = 'FORM_DETAILS_REQUEST';
export const FORM_DETAILS_SUCCESS = 'FORM_DETAILS_SUCCESS';
export const FORM_DETAILS_FAILURE = 'FORM_DETAILS_FAILURE';
export const FORM_BASIC_DETAILS_REQUEST = 'FORM_BASIC_DETAILS_REQUEST';
export const FORM_BASIC_DETAILS_SUCCESS = 'FORM_BASIC_DETAILS_SUCCESS';
export const FORM_BASIC_DETAILS_FAILURE = 'FORM_BASIC_DETAILS_FAILURE';
export const UPDATE_FORM_REQUEST = 'UPDATE_FORM_REQUEST';
export const UPDATE_FORM_SUCCESS = 'UPDATE_FORM_SUCCESS';
export const UPDATE_FORM_FAILURE = 'UPDATE_FORM_FAILURE';
export const SAVE_CUSTOM_FORM_REQUEST = 'SAVE_CUSTOM_FORM_REQUEST';
export const SAVE_CUSTOM_FORM_SUCCESS = 'SAVE_CUSTOM_FORM_SUCCESS';
export const SAVE_CUSTOM_FORM_FAILURE = 'SAVE_CUSTOM_FORM_FAILURE';
//Speaker
export const SPEAKERS_CLEAR_MESSAGES = 'SPEAKERS_CLEAR_MESSAGES';
export const SPEAKER_INVITE_REQUEST = 'SPEAKER_INVITE_REQUEST';
export const SPEAKER_INVITE_SUCCESS = 'SPEAKER_INVITE_SUCCESS';
export const SPEAKER_INVITE_FAILURE = 'SPEAKER_INVITE_FAILURE';
export const INVITATION_DETAILS_REQUEST = 'INVITATION_DETAILS_REQUEST';
export const INVITATION_DETAILS_SUCCESS = 'INVITATION_DETAILS_SUCCESS';
export const INVITATION_DETAILS_FAILURE = 'INVITATION_DETAILS_FAILURE';
export const INVITATION_DETAILS_BASIC_REQUEST = 'INVITATION_DETAILS_BASIC_REQUEST';
export const INVITATION_DETAILS_BASIC_SUCCESS = 'INVITATION_DETAILS_BASIC_SUCCESS';
export const INVITATION_DETAILS_BASIC_FAILURE = 'INVITATION_DETAILS_BASIC_FAILURE';
export const SPEAKER_EVENTS_REQUEST = 'SPEAKER_EVENTS_REQUEST';
export const SPEAKER_EVENTS_SUCCESS = 'SPEAKER_EVENTS_SUCCESS';
export const SPEAKER_EVENTS_FAILURE = 'SPEAKER_EVENTS_FAILURE';
export const EVENT_SPEAKERS_REQUEST = 'EVENT_SPEAKERS_REQUEST';
export const EVENT_SPEAKERS_SUCCESS = 'EVENT_SPEAKERS_SUCCESS';
export const EVENT_SPEAKERS_FAILURE = 'EVENT_SPEAKERS_FAILURE';
export const GET_SPEAKERS_PAGE_DETAILS = 'GET_SPEAKERS_PAGE_DETAILS';
export const UPDATE_SPEAKER_ANONYMOUSLY_REQUEST =
     'UPDATE_SPEAKER_ANONYMOUSLY_REQUEST';
export const UPDATE_SPEAKER_ANONYMOUSLY_SUCCESS =
     'UPDATE_SPEAKER_ANONYMOUSLY_SUCCESS';
export const UPDATE_SPEAKER_ANONYMOUSLY_FAILURE =
     'UPDATE_SPEAKER_ANONYMOUSLY_FAILURE';
export const SPEAKER_DETAILS_REQUEST = 'SPEAKER_DETAILS_REQUEST';
export const SPEAKER_DETAILS_SUCCESS = 'SPEAKER_DETAILS_SUCCESS';
export const SPEAKER_DETAILS_FAILURE = 'SPEAKER_DETAILS_FAILURE';
export const CONNECT_SPEAKER_REQUEST = 'CONNECT_SPEAKER_REQUEST';
export const CONNECT_SPEAKER_SUCCESS = 'CONNECT_SPEAKER_SUCCESS';
export const CONNECT_SPEAKER_FAILURE = 'CONNECT_SPEAKER_FAILURE';
export const UPDATE_SPEAKER_DETAILS_REQUEST = 'UPDATE_SPEAKER_DETAILS_REQUEST';
export const UPDATE_SPEAKER_DETAILS_SUCCESS = 'UPDATE_SPEAKER_DETAILS_SUCCESS';
export const UPDATE_SPEAKER_DETAILS_FAILURE = 'UPDATE_SPEAKER_DETAILS_FAILURE';
export const RESEND_SPEAKER_INVITATION_REQUEST = 'RESEND_SPEAKER_INVITATION_REQUEST';
export const RESEND_SPEAKER_INVITATION_SUCCESS = 'RESEND_SPEAKER_INVITATION_SUCCESS';
export const RESEND_SPEAKER_INVITATION_FAILURE = 'RESEND_SPEAKER_INVITATION_FAILURE';
export const FIELDS_SPEAKER_VIEW_REQUEST = 'FIELDS_SPEAKER_VIEW_REQUEST';
export const FIELDS_SPEAKER_VIEW_SUCCESS = 'FIELDS_SPEAKER_VIEW_SUCCESS';
export const FIELDS_SPEAKER_VIEW_FAILURE = 'FIELDS_SPEAKER_VIEW_FAILURE';
export const FILTER_SPEAKERS_VIEW_REQUEST = 'FILTER_SPEAKERS_VIEW_REQUEST';
export const FILTER_SPEAKERS_VIEW_SUCCESS = 'FILTER_SPEAKERS_VIEW_SUCCESS';
export const FILTER_SPEAKERS_VIEW_FAILURE = 'FILTER_SPEAKERS_VIEW_FAILURE';
export const EXPORT_SPEAKERS_REQUEST = 'EXPORT_SPEAKERS_REQUEST';
export const EXPORT_SPEAKERS_SUCCESS = 'EXPORT_SPEAKERS_SUCCESS';
export const EXPORT_SPEAKERS_FAILURE = 'EXPORT_SPEAKERS_FAILURE';
export const ARCHIVE_SPEAKERS_REQUEST = 'ARCHIVE_SPEAKERS_REQUEST';
export const ARCHIVE_SPEAKERS_SUCCESS = 'ARCHIVE_SPEAKERS_SUCCESS';
export const ARCHIVE_SPEAKERS_FAILURE = 'ARCHIVE_SPEAKERS_FAILURE';
export const ARCHIVE_DOWNLOAD_SPEAKERS_REQUEST = 'ARCHIVE_DOWNLOAD_SPEAKERS_REQUEST';
export const ARCHIVE_DOWNLOAD_SPEAKERS_SUCCESS = 'ARCHIVE_DOWNLOAD_SPEAKERS_SUCCESS';
export const ARCHIVE_DOWNLOAD_SPEAKERS_FAILURE = 'ARCHIVE_DOWNLOAD_SPEAKERS_FAILURE';
export const DOWNLOAD_MEDIA_FILE_REQUEST = 'DOWNLOAD_MEDIA_FILE_REQUEST';
export const DOWNLOAD_MEDIA_FILE_SUCCESS = 'DOWNLOAD_MEDIA_FILE_SUCCESS';
export const DOWNLOAD_MEDIA_FILE_FAILURE = 'DOWNLOAD_MEDIA_FILE_FAILURE';
export const SEARCH_SPEAKERS_FILTERED_WITH_VIEW_REQUEST =
     'SEARCH_SPEAKERS_FILTERED_WITH_VIEW_REQUEST';
export const SEARCH_SPEAKERS_FILTERED_WITH_VIEW_SUCCESS =
     'SEARCH_SPEAKERS_FILTERED_WITH_VIEW_SUCCESS';
export const SEARCH_SPEAKERS_FILTERED_WITH_VIEW_FAILURE =
     'SEARCH_SPEAKERS_FILTERED_WITH_VIEW_FAILURE';
export const DELETE_SPEAKER_FROM_EVENT_REQUEST = 'DELETE_SPEAKER_FROM_EVENT_REQUEST';
export const DELETE_SPEAKER_FROM_EVENT_SUCCESS = 'DELETE_SPEAKER_FROM_EVENT_SUCCESS';
export const DELETE_SPEAKER_FROM_EVENT_FAILURE = 'DELETE_SPEAKER_FROM_EVENT_FAILURE';
export const BULK_DELETE_SPEAKER_FROM_EVENT_REQUEST = 'BULK_DELETE_SPEAKER_FROM_EVENT_REQUEST';
export const BULK_DELETE_SPEAKER_FROM_EVENT_SUCCESS = 'BULK_DELETE_SPEAKER_FROM_EVENT_SUCCESS';
export const BULK_DELETE_SPEAKER_FROM_EVENT_FAILURE = 'BULK_DELETE_SPEAKER_FROM_EVENT_FAILURE';
export const UPLOAD_SPEAKER_PHOTO_REQUEST = 'UPLOAD_SPEAKER_PHOTO_REQUEST';
export const UPLOAD_SPEAKER_PHOTO_SUCCESS = 'UPLOAD_SPEAKER_PHOTO_SUCCESS';
export const UPLOAD_SPEAKER_PHOTO_FAILURE = 'UPLOAD_SPEAKER_PHOTO_FAILURE';
export const UPLOAD_SPEAKER_FILE_REQUEST = 'UPLOAD_SPEAKER_FILE_REQUEST';
export const UPLOAD_SPEAKER_FILE_SUCCESS = 'UPLOAD_SPEAKER_FILE_SUCCESS';
export const UPLOAD_SPEAKER_FILE_FAILURE = 'UPLOAD_SPEAKER_FILE_FAILURE';
export const SPEAKER_PHOTO_REQUEST = 'SPEAKER_PHOTO_REQUEST';
export const SPEAKER_PHOTO_SUCCESS = 'SPEAKER_PHOTO_SUCCESS';
export const SPEAKER_PHOTO_FAILURE = 'SPEAKER_PHOTO_FAILURE';
export const SPEAKER_PHOTO_REMOVE_REQUEST = 'SPEAKER_PHOTO_REMOVE_REQUEST';
export const SPEAKER_PHOTO_REMOVE_SUCCESS = 'SPEAKER_PHOTO_REMOVE_SUCCESS';
export const SPEAKER_PHOTO_REMOVE_FAILURE = 'SPEAKER_PHOTO_REMOVE_FAILURE';
export const SPEAKER_FILE_REQUEST = 'SPEAKER_FILE_REQUEST';
export const SPEAKER_FILE_SUCCESS = 'SPEAKER_FILE_SUCCESS';
export const SPEAKER_FILE_FAILURE = 'SPEAKER_FILE_FAILURE';
export const SPEAKER_FILE_REMOVE_REQUEST = 'SPEAKER_FILE_REMOVE_REQUEST';
export const SPEAKER_FILE_REMOVE_SUCCESS = 'SPEAKER_FILE_REMOVE_SUCCESS';
export const SPEAKER_FILE_REMOVE_FAILURE = 'SPEAKER_FILE_REMOVE_FAILURE';
export const SPEAKER_GENERATED_DATA = 'SPEAKER_GENERATED_DATA';
//Reminder
export const REMINDER_CLEAR_MESSAGES = 'REMINDER_CLEAR_MESSAGES';
export const EVENT_REMINDERS_REQUEST = 'EVENT_REMINDERS_REQUEST';
export const EVENT_REMINDERS_SUCCESS = 'EVENT_REMINDERS_SUCCESS';
export const EVENT_REMINDERS_FAILURE = 'EVENT_REMINDERS_FAILURE';
export const GET_REMINDERS_PAGE_DETAILS = 'GET_REMINDERS_PAGE_DETAILS';
export const CREATE_REMINDER_REQUEST = 'CREATE_REMINDER_REQUEST';
export const CREATE_REMINDER_SUCCESS = 'CREATE_REMINDER_SUCCESS';
export const CREATE_REMINDER_FAILURE = 'CREATE_REMINDER_FAILURE';
export const SEARCH_REMINDERS_REQUEST = 'SEARCH_REMINDERS_REQUEST';
export const SEARCH_REMINDERS_SUCCESS = 'SEARCH_REMINDERS_SUCCESS';
export const SEARCH_REMINDERS_FAILURE = 'SEARCH_REMINDERS_FAILURE';
export const REMINDER_DETAILS_REQUEST = 'REMINDER_DETAILS_REQUEST';
export const REMINDER_DETAILS_SUCCESS = 'REMINDER_DETAILS_SUCCESS';
export const REMINDER_DETAILS_FAILURE = 'REMINDER_DETAILS_FAILURE';
export const COPY_REMINDER_REQUEST = 'COPY_REMINDER_REQUEST';
export const COPY_REMINDER_SUCCESS = 'COPY_REMINDER_SUCCESS';
export const COPY_REMINDER_FAILURE = 'COPY_REMINDER_FAILURE';
export const REMOVE_REMINDER_REQUEST = 'REMOVE_REMINDER_REQUEST';
export const REMOVE_REMINDER_SUCCESS = 'REMOVE_REMINDER_SUCCESS';
export const REMOVE_REMINDER_FAILURE = 'REMOVE_REMINDER_FAILURE';
export const PREVIEW_REMINDER_REQUEST = 'PREVIEW_REMINDER_REQUEST';
export const PREVIEW_REMINDER_SUCCESS = 'PREVIEW_REMINDER_SUCCESS';
export const PREVIEW_REMINDER_FAILURE = 'PREVIEW_REMINDER_FAILURE';
export const REMINDER_TEMPLATES_REQUEST = 'REMINDER_TEMPLATES_REQUEST';
export const REMINDER_TEMPLATES_SUCCESS = 'REMINDER_TEMPLATES_SUCCESS';
export const REMINDER_TEMPLATES_FAILURE = 'REMINDER_TEMPLATES_FAILURE';
export const CREATE_REMINDER_TEMPLATE_REQUEST =
     'CREATE_REMINDER_TEMPLATE_REQUEST';
export const CREATE_REMINDER_TEMPLATE_SUCCESS =
     'CREATE_REMINDER_TEMPLATE_SUCCESS';
export const CREATE_REMINDER_TEMPLATE_FAILURE =
     'CREATE_REMINDER_TEMPLATE_FAILURE';
export const REMOVE_REMINDER_TEMPLATE_REQUEST =
     'REMOVE_REMINDER_TEMPLATE_REQUEST';
export const REMOVE_REMINDER_TEMPLATE_SUCCESS =
     'REMOVE_REMINDER_TEMPLATE_SUCCESS';
export const REMOVE_REMINDER_TEMPLATE_FAILURE =
     'REMOVE_REMINDER_TEMPLATE_FAILURE';
//Email templates
export const EVENT_EMAIL_TEMPLATES_REQUEST = 'EVENT_EMAIL_TEMPLATES_REQUEST';
export const EVENT_EMAIL_TEMPLATES_SUCCESS = 'EVENT_EMAIL_TEMPLATES_SUCCESS';
export const EVENT_EMAIL_TEMPLATES_FAILURE = 'EVENT_EMAIL_TEMPLATES_FAILURE';
export const CREATE_EMAIL_TEMPLATE_REQUEST = 'CREATE_EMAIL_TEMPLATE_REQUEST';
export const CREATE_EMAIL_TEMPLATE_SUCCESS = 'CREATE_EMAIL_TEMPLATE_SUCCESS';
export const CREATE_EMAIL_TEMPLATE_FAILURE = 'CREATE_EMAIL_TEMPLATE_FAILURE';
export const REMOVE_EMAIL_TEMPLATE_REQUEST = 'REMOVE_EMAIL_TEMPLATE_REQUEST';
export const REMOVE_EMAIL_TEMPLATE_SUCCESS = 'REMOVE_EMAIL_TEMPLATE_SUCCESS';
export const REMOVE_EMAIL_TEMPLATE_FAILURE = 'REMOVE_EMAIL_TEMPLATE_FAILURE';
export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';
export const FILES_DATA_REQUEST = 'FILES_DATA_REQUEST';
export const FILES_DATA_SUCCESS = 'FILES_DATA_SUCCESS';
export const FILES_DATA_FAILURE = 'FILES_DATA_FAILURE';
export const FILES_RESET_DATA_REQUEST = 'FILES_RESET_DATA_REQUEST';
// export const UPLOAD_MULTIPLE_FILES_REQUEST = 'UPLOAD_MULTIPLE_FILES_REQUEST';
// export const UPLOAD_MULITPLE_FILES_SUCCESS = 'UPLOAD_MULITPLE_FILES_SUCCESS';
// export const UPLOAD_MULTIPLE_FILES_FAILURE = 'UPLOAD_MULTIPLE_FILES_FAILURE';

// UI
export const SHOW_MAIN_DRAWER = 'SHOW_MAIN_DRAWER';
export const HIDE_MAIN_DRAWER = 'HIDE_MAIN_DRAWER';
export const SHOW_TOOLBAR = 'SHOW_TOOLBAR';
export const HIDE_TOOLBAR = 'HIDE_TOOLBAR';
export const SHOW_DRAWER = 'SHOW_DRAWER';
export const HIDE_DRAWER = 'HIDE_DRAWER';
