import React, {memo, lazy, useEffect, useState, useRef} from 'react';
import _, {isEmpty} from 'underscore';
import cloneDeep from 'lodash/cloneDeep';
import { string as yupString, object as yupObject } from 'yup';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import {
     Link,
     Route,
     Switch,
     NavLink,
     useParams,
     useHistory,
} from 'react-router-dom';
import {Tab, Nav, Button} from 'react-bootstrap';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import toastr from 'toastr';
import { InvitationForms } from './InvitationForms';
import Breadcrumb from '../../../common/breadcrumb';
import LoadingBar from '../../../common/loading-bar';
import SweetAlert from '../../../common/sweet-alert';
import { ListItem } from '../../../common/collapsible-list/CollapsibleList';
import { InviteSpeakers } from './Step1InviteSpeakers';
import { InvitationEmail } from './Step3InvitationEmail';
import { EventDropdown } from '../../Event/sub-components/EventDropdown';
import { OrganizationDropdown } from '../../Organization/sub-components/OrganizationDropdown';
import { getRandomId } from '../../../../utils/string.utils';
import { debounce } from '../../../../utils/debounce.utils';
import { SettingsImage } from '../../../../assets/icons/metronic/settings-image';
import SpeakersIcon from '../../../../assets/icons/metronic/speakers-icon';
import { GroupChatImage } from '../../../../assets/icons/metronic/group-chat-image';
import { DotImage } from '../../../../assets/icons/metronic/dot-image';
import {
     requestOrganizationDetails,
     requestOrganizationEmailSendersAll,
} from '../../../../redux/reducers/organizationReducer';
import { requestEventDetails } from '../../../../redux/reducers/eventReducer';
import {
     requestEventForms,
     requestRemoveForm
} from '../../../../redux/reducers/formBuilderReducer';
import { requestSpeakerInvite } from '../../../../redux/reducers/speakerReducer';
import {
     requestEventEmailTemplates, requestFilesData,
     requestRemoveEmailTemplate, requestUploadFile,
} from '../../../../redux/reducers/emailTemplatesReducer';
import SpeakersWrapper from '../Speakers.style';
import {ReminderIcon} from "../../../../assets/icons/metronic/reminders-icon";
import {convertBytesToMb, isValidFileSize, EMAILS_FILE_LIMIT} from "../../../../utils/file.utils";
import {convertMimesToExtensions, isValidMimeType, MIME_EMAILS} from "../../../../utils/mime.utils";

const MainDrawer = lazy(() => import('../../../common/drawer'));
const CollapsibleList = lazy(() => import('../../../common/collapsible-list'));

const mapStateToProps = (state) => ({
     router: state.router,
     eventData: state.eventReducer.eventData,
     organization: state.organizationReducer.organization,
     forms: state.formBuilderReducer.forms,
     createFormRequested: state.formBuilderReducer.createFormRequested,
     createFormSucceeded: state.formBuilderReducer.createFormSucceeded,
     updateFormSucceeded: state.formBuilderReducer.updateFormSucceeded,
     removeFormSucceeded: state.formBuilderReducer.removeFormSucceeded,
     emailSendersList: state.organizationReducer.emailSendersList,
     currentUser: state.userReducer.currentUser,
     emailTemplatesList: state.emailTemplatesReducer.emailTemplatesList,
     uploadFileRequested: state.emailTemplatesReducer.uploadFileRequested,
     languages: state.userReducer.languages,
     fileData: state.emailTemplatesReducer.code,
     filesData: state.emailTemplatesReducer.filesData,
     speakerInviteRequested: state.speakerReducer.speakerInviteRequested
});

const mapDispatchToProps = (dispatch) => ({
     redirectToFirstStep: (id, eventId) =>
          dispatch(
               replace(
                    `/organization/${id}/events/${eventId}/invite-speakers/step-1`
               )
          ),
     redirectToSecondStep: (id, eventId) =>
         dispatch(
             replace(
                 `/organization/${id}/events/${eventId}/invite-speakers/step-2`
             )
         ),
     redirectToNewForm: (id, eventId) => dispatch(
         replace(
             `/organization/${id}/events/${eventId}/form-builder/add-element`
         )
     ),
     requestOrganizationDetails: (id) => dispatch(requestOrganizationDetails(id)),
     requestEventDetails: (payload) => dispatch(requestEventDetails(payload)),
     requestEventForms: (id) => dispatch(requestEventForms(id)),
     requestRemoveForm: (payload) => dispatch(requestRemoveForm(payload)),
     requestOrganizationEmailSendersAll: (payload) => dispatch(requestOrganizationEmailSendersAll(payload)),
     requestSpeakerInvite: (payload) => dispatch(requestSpeakerInvite(payload)),
     requestEventEmailTemplates: (payload) => dispatch(requestEventEmailTemplates(payload)),
     requestRemoveEmailTemplate: (payload) => dispatch(requestRemoveEmailTemplate(payload)),
     requestUploadFile: (payload) => dispatch(requestUploadFile(payload)),
     requestFilesData: (payload) => dispatch(requestFilesData(payload)),
});

const _WizardForm = memo((props) => {
     const { t } = useTranslation();
     const { id, eventId } = useParams();
     const organizationId = id;

     const {
          router: {
               location: { pathname },
          },
          redirectToFirstStep,
          redirectToSecondStep,
          redirectToNewForm,
          eventData,
          organization,
          requestOrganizationDetails,
          requestEventDetails,
          requestEventForms,
          forms,
          createFormRequested,
          createFormSucceeded,
          updateFormSucceeded,
          removeFormSucceeded,
          requestRemoveForm,
          emailSendersList,
          requestOrganizationEmailSendersAll,
          currentUser,
          requestSpeakerInvite,
          emailTemplatesList,
          requestEventEmailTemplates,
          requestRemoveEmailTemplate,
          languages,
          requestUploadFile,
          fileData,
          filesData,
          requestFilesData,
          speakerInviteRequested,
          uploadFileRequested
     } = props;

     const history = useHistory();

     const persistedPerPage = JSON.parse(localStorage.getItem(`page_size.speakers.wizard.forms`));

     const defaultFormPerPage = () => {
          const activateSavedState = localStorage.getItem('invitation_flow_activate_saved_state');
          const savedFormPerPage = localStorage.getItem('invitation_flow_forms_per_page');

          if (activateSavedState && savedFormPerPage) {
               return savedFormPerPage;
          }

          return persistedPerPage ? persistedPerPage : 50;
     };

     const [queryString, setQueryString] = useState('');
     const [searchIsUsed, setSearchIsUsed] = useState(false);
     const [duplicatedForms, setDuplicatedForms] = useState([]);
     const [selectedForms, setSelectedForms] = useState([]);
     const [currentStep, setCurrentStep] = useState(1);
     const [formsData, setFormsData] = useState([]);
     const [message, setMessage] = useState('');
     const [speakers, setSpeakers] = useState([]);
     const [runValidations, setRunValidations] = useState(false);
     const [speakersInvalid, setSpeakersInvalid] = useState(false);
     const [multilingual, setMultilingual] = useState(false);
     const [mandatoryLanguage, setMandatoryLanguage] = useState(null);
     const [multilingualSelected, setMultilingualSelected] = useState([]);
     const [quillMessage, setQuillMessage] = useState('');
     const [quillError, setQuillError] = useState('');
     const [language, setLanguage] = useState(
          organization && organization.language.id
     );
     const [files, setFiles] = useState([]);
     const [emailTemplate, setEmailTemplate] = useState(null);
     const [loadEmailTemplate, setLoadEmailTemplate] = useState(null);
     const [nameOfTemplate, setNameOfTemplate] = useState('');
     const [accessType, setAccessType] = useState('');
     const [saveAsTemplate, setSaveAsTemplate] = useState(false);

     const [emailData, setEmailData] = useState([]);
     const [formPerPage, setFormPerPage] = useState(defaultFormPerPage());

     const templateRef = useRef();

     const links = [
          {
               to: `/organization/${id}/settings`,
               text: `${organization && organization.name}`,
          },
          { to: `/organization/${id}/events`, text: i18n.t('events') },
          {
               to: `/organization/${id}/events/${eventId}/speakers`,
               text: `${eventData && eventData.name}`,
          },
          {
               to: `/organization/${id}/events/${eventId}/speakers`,
               text: i18n.t('event.speakers'),
          },
          {
               to: `/organization/${id}/events/${eventId}/invite-speakers/step-1`,
               text: i18n.t('speakers.invite_speakers'),
          },
     ];

     useEffect(() => {
          setRunValidations(false);
          setSpeakersInvalid(false);

          if (!pathname.includes('stop-redirect') && !pathname.includes('step')) {
               redirectToFirstStep(id, eventId);
          }

     }, [pathname, speakers]);

     useEffect(() => {
          if (organization) {
               setLanguage(organization.language.id);
               setMandatoryLanguage(organization.language.id);

               if (speakers.length === 0) {
                    setSpeakers([
                         {
                              id: getRandomId(),
                              name: '',
                              email: '',
                              language_id: organization.language.id,
                              errors: []
                         }
                    ]);
               }
          }
     }, [organization]);

     useEffect(() => {
          requestOrganizationDetails(id);
          requestEventDetails({ organizationId: id, eventId });
          requestEventForms({ eventId, organizationId: id, per_page: formPerPage });
          requestOrganizationEmailSendersAll({ id });
          requestEventEmailTemplates(eventId);
     }, [
          createFormRequested,
          createFormSucceeded,
          updateFormSucceeded,
          removeFormSucceeded,
     ]);

     useEffect(() => {
          if (emailData.length === 0 && organization && languages.length > 0) {
               const activateSavedState = localStorage.getItem('invitation_flow_activate_saved_state');

               if (activateSavedState) {
                    localStorage.removeItem('invitation_flow_activate_saved_state');
                    const persistedSpeakers = JSON.parse(
                        localStorage.getItem('invitation_flow_speakers')
                    );

                    if (persistedSpeakers && persistedSpeakers.length > 0) {
                         setSpeakers(persistedSpeakers);
                         localStorage.removeItem('invitation_flow_speakers');
                    }

                    const persistedEmailData = JSON.parse(
                        localStorage.getItem('invitation_flow_email_data')
                    );

                    const persistedEmailMultilingual = JSON.parse(
                        localStorage.getItem('invitation_flow_email_multilingual')
                    );

                    const persistedEmailMultilingualSelected = JSON.parse(
                        localStorage.getItem('invitation_flow_email_multilingual_selected')
                    );

                    if (persistedEmailData && persistedEmailData.length > 0) {
                         setEmailData(persistedEmailData);
                         setMultilingual(persistedEmailMultilingual);
                         setMultilingualSelected(persistedEmailMultilingualSelected);
                         localStorage.removeItem('invitation_flow_email_data');
                    }
               } else {
                    const defaultEmailData = [];
                    languages.forEach((lang) => {
                         defaultEmailData.push({
                              is_valid: false,
                              subject: '',
                              message: '',
                              language_id: lang.id,
                              sender: {
                                   name: currentUser && `${currentUser.firstname} ${currentUser.lastname}`,
                                   email: currentUser && currentUser.email
                              },
                              files: [],
                              files_data: []
                         });
                    });

                    setEmailData(defaultEmailData);
                    setMultilingual(false);
                    setMultilingualSelected([languages.find(lang => lang.id === organization.language.id)]);
               }

               setLanguage(organization.language.id);
          }
     }, [organization, languages]);

     useEffect(() => {
          if (emailTemplate && loadEmailTemplate) {
               const newData = cloneDeep(emailTemplate.data);

               let requestFilesDataPayload = [];
               newData.forEach((d) => {
                    if (d.files.length > 0) {
                         requestFilesDataPayload = requestFilesDataPayload.concat(d.files);
                    }
               });

               requestFilesData({files: requestFilesDataPayload});

               newData.map((data) => {
                    const existingEmailData = emailData.find((d) => d.language_id === data.language_id);

                   data.is_valid = true;
                   data.sender = existingEmailData ? existingEmailData.sender : data.sender;
                   data.files_data = requestFilesDataPayload.length > 0 && existingEmailData ? existingEmailData.files_data : [];

                   return data;
               });

               const currentData = newData.find((d) => d.language_id === language);

               setEmailData(newData);
               setQuillMessage(currentData?.message);
               setLoadEmailTemplate(false);
          }
     }, [emailTemplate, loadEmailTemplate]);

     useEffect(() => {
          const currentReminderData = emailData;

          currentReminderData.map((d) => {
               if (d.language_id === language) {
                    d.files = d.files.concat(prepareFilesCode(fileData));
                    d.files_data = d.files_data.concat(prepareFilesData(fileData));
               }

               return validateEmailData(d);
          });

          setEmailData(currentReminderData);
     }, [fileData]);

     useEffect(() => {
          if (filesData !== null) {
               const currentEmailData = emailData;

               currentEmailData.map((data) => {
                    if (data.files.length > 0) {
                         const dataFilesForUpdate = [];

                         data.files.forEach((file) => {
                              const fileData = filesData.find((fd) => fd.code === file);
                              if (fileData) {
                                   dataFilesForUpdate.push(fileData);
                              }
                         });

                         data.files_data = dataFilesForUpdate;
                    }

                    return data;
               });

               setEmailData(currentEmailData);
          }
     }, [filesData]);

     const prepareFilesCode = (uploadedFiles) => {
          const result = [];

          if (uploadedFiles) {
               uploadedFiles.forEach((file) => {
                    result.push(file.code);
               });
          }

          return result;
     };

     const prepareFilesData = (uploadedFiles) => {
          const result = [];

          if (uploadedFiles) {
               uploadedFiles.forEach((file) => {
                    result.push({
                         code: file.code,
                         name: file.name
                    });
               });
          }

          return result;
     };

     const exportEmailData = (languageId) => {
          const langId = languageId ? languageId : language;
          if (langId) {
               const existing = emailData.find(({language_id}) => language_id === langId);
               if (existing) {
                    return existing;
               }
          }

          return {
               is_valid: false,
               subject: '',
               message: '',
               language_id: null,
               sender: {
                    name: '',
                    email: ''
               },
               files: [],
               files_data: []
          };
     };

     const handleFormSelect = (form) => {
          const currentForms = selectedForms;
          const currentFormsIds = _.pluck(currentForms, 'id');

          let forUpdate = [];
          if (currentFormsIds.includes(form.id)) {
               forUpdate = currentForms.filter((f) => f.id !== form.id);
          } else {
               delete form.language_use; // if exists
               forUpdate = [...currentForms, form];
          }

          setSelectedForms(forUpdate);
     };

     const handleSelectDuplicatedForm = (lang, form) => {
          const speakerIds = _.unique(_.pluck(speakers, 'language_id'));

          let updatedForms = selectedForms.filter(selectedForm => {
               if (!selectedForm.language.includes(lang.id) || selectedForm.multilingual) {
                    return selectedForm;
               }

               if (selectedForm.id === form.id) {

                    return selectedForm;
               }
          });

          updatedForms = updatedForms.filter(selectedForm => {
               if (!selectedForm.language.includes(lang.id) || !selectedForm.multilingual) {
                    return selectedForm;
               }

               if (selectedForm.id === form.id) {
                    selectedForm.language_use = lang.id;
               }

               return selectedForm;
          });

          updatedForms = updatedForms.filter(selectedForm => {
               if (!selectedForm.language.includes(lang.id) || !selectedForm.multilingual) {
                    return selectedForm;
               }

               if (form.multilingual) {
                    if (selectedForm.id !== form.id) {
                         const useOther = languages.find(l => l.id !== lang.id);
                         if (useOther) {
                              selectedForm.language_use = useOther.id;
                         }
                    }

                    if (speakerIds.includes(selectedForm.language_use)) {
                         return selectedForm;
                    }
               } else {
                    if (selectedForm.id !== form.id && selectedForm.language_use && selectedForm.language_use !== lang.id) {
                         const useOther = languages.find(l => l.id !== lang.id);
                         if (useOther) {
                              selectedForm.language_use = useOther.id;
                         }

                         if (speakerIds.includes(selectedForm.language_use)) {
                              return selectedForm;
                         }
                    }
               }
          });

          setSelectedForms(updatedForms);
     };

     const checkIfShouldRedirectBack = (step) => {
          const persistedSpeakers = JSON.parse(localStorage.getItem('invitation_flow_speakers'));
          const speakersEmpty = speakers.length === 0 || (isEmpty(speakers[0].name) && isEmpty(speakers[0].email));

          const storedSpeakerEmpty = !(persistedSpeakers && persistedSpeakers.length > 0);
          const areSpeakerEmpty = speakersEmpty && storedSpeakerEmpty;

          if (step === 3) {
               if (areSpeakerEmpty) {
                    redirectToFirstStep(id, eventId);
               } else if (selectedForms.length === 0) {
                    redirectToSecondStep(id, eventId);
               }
          }

          if (step === 2) {
               if (areSpeakerEmpty) {
                    redirectToFirstStep(id, eventId);
               }
          }
     };

     const handleChangeCurrentStep = (step) => {
          checkIfShouldRedirectBack(step);
          setCurrentStep(step);
     };

     const isStepActivated = (step) => {
          return step === currentStep;
     };

     const handlePageSizeChange = (pageSize) => {
          requestEventForms({
               organizationId: id,
               eventId,
               query: queryString,
               per_page: pageSize,
          });
          setFormPerPage(pageSize);
          localStorage.setItem(
              `page_size.speakers.wizard.forms`,
              JSON.stringify(pageSize, null, 2)
          );
     };

     const handlePageChange = ({ page, pageSize }) =>
          requestEventForms({
               organizationId: id,
               eventId,
               per_page: pageSize,
               query: queryString,
               page,
          });

     const handleSearch = ({ target: { value } }) => {
          setQueryString(value);
          if (value.length === 0) {
               requestEventForms({
                    organizationId: id,
                    eventId,
                    per_page: formPerPage,
                    page: 1
               });
          }
          if (!searchIsUsed) {
               setSearchIsUsed(true);
          }
     };

     useEffect(() => {
          if (queryString.length > 0) {
               requestEventForms({
                    organizationId: id,
                    eventId,
                    per_page: formPerPage,
                    page: 1,
                    query: queryString
               });
          }
     }, [queryString]);

     useEffect(() => {
          if (forms) {
               setFormsData(prepareData(forms));
          }
     }, [forms]);

     const handleMultilingual = (value) => {
          setMultilingual(value);
     };

     const handleMultilingualSelected = (value) => {
          setMultilingualSelected(value);
     };

     const handleMandatoryLanguage = (lang) => {
          setMandatoryLanguage(lang.id);
     };

     const validateEmailData = (data) => {
          const cleanMessage = data.message.replace(/<\/?[^>]+(>|$)/g, ""); // escaped html tags

          if (
              _.isEmpty(data.subject) ||
              _.isEmpty(cleanMessage) ||
              _.isEmpty(data.sender.name) ||
              _.isEmpty(data.sender.email)
          ) {
               data.is_valid = false;

               return data;
          }

          data.is_valid = true;

          return data;
     };

     const handleQuillMessage = (value) => {
          setQuillMessage(value);

          const currentEmailData = emailData;
          currentEmailData.map((data) => {
               if (data.language_id === language) {
                    data.message = value;
               }

               return validateEmailData(data);
          });

          setEmailData(currentEmailData);
     };

     const handleEmailSubjectChanged = (value) => {
          const currentEmailData = emailData;
          currentEmailData.map((data) => {
               if (data.language_id === language) {
                    data.subject = value;
               }

               return validateEmailData(data);
          });

          setEmailData(currentEmailData);
     };

     const handleCustomSender = (value) => {
          const currentEmailData = emailData;
          currentEmailData.map((data) => {
               data.sender.name = value.name;
               data.sender.email = value.email;

               return validateEmailData(data);
          });

          setEmailData(currentEmailData);
     };

     const handleSenderName = (value, languageId) => {
          const currentEmailData = emailData;
          currentEmailData.map((data) => {
               if (data.language_id === languageId) {
                    data.sender.name = value;
               }

               return validateEmailData(data);
          });

          setEmailData(currentEmailData);
     };

     const handleSenderEmail = (value, languageId) => {
          const currentEmailData = emailData;
          currentEmailData.map((data) => {
               if (data.language_id === languageId) {
                    data.sender.email = value;
               }

               return validateEmailData(data);
          });

          setEmailData(currentEmailData);
     };

     const isEnteredLinks = () => {
          let valid = true;
          if (isValidEmailData()) {
               const selectedLanguages = _.pluck(multilingualSelected, 'id');

               emailData.forEach((data) => {
                    if (
                        selectedLanguages.includes(data.language_id) &&
                        !(data.message.includes('invitation_link') || data.message.includes('invitation_button'))
                    ) {
                         valid = false;
                    }
               });
          }

          return valid;
     };

     const isValidEmailData = () => {
          let valid = true;
          const languageIds = multilingual ? _.pluck(multilingualSelected, 'id') : [language];

          emailData.forEach((data) => {
               if (languageIds.includes(data.language_id) && data.is_valid === false) {
                    valid = false;
               }
          });

          return valid;
     };

     const isSpeakerAndEmailLangValid = () => {
          let languageIds = _.unique(multilingual ? _.pluck(multilingualSelected, 'id') : [language]);
          let speakerIds = _.unique(_.pluck(speakers, 'language_id'));

          return _.difference(speakerIds, languageIds).length === 0;
     };

     const isSpeakerAndFormsValid = () => {
          let allLanguageIds = [];

          selectedForms.forEach(form => {
               if (form.language_use) {
                    allLanguageIds.push(form.language_use);
               } else {
                    form.language.forEach(lId => {
                         allLanguageIds.push(lId);
                    });
               }
          });

          const languageIds = _.unique(allLanguageIds);
          const speakerIds = _.unique(_.pluck(speakers, 'language_id'));

          return _.difference(speakerIds, languageIds).length === 0;
     };

     const isFormLanguagesValid = () => {
          const duplicates = formLanguageDuplicates();

          return duplicates.length === 0;
     };

     const isValidLanguage = (langId) => {
          return exportEmailData(langId).is_valid
     };

     const getLanguageClassName = (langId) => {
          if (isValidLanguage(langId)) {
               return language === langId ? 'primary' : 'outline-primary';
          }

          return language === langId ? 'warning' : 'outline-warning';
     };

     const shouldFilesBeTranslated = () => {
          if (multilingual && mandatoryLanguage !== language) {
               const mandatoryFiles = exportEmailData(mandatoryLanguage && mandatoryLanguage);
               const currentLanguage = exportEmailData(language);

               if (
                   mandatoryFiles.files &&
                   mandatoryFiles.files.length > 0 &&
                   currentLanguage.files &&
                   currentLanguage.files.length === 0
               ) {
                    return true;
               }
          }

          return false;
     };

     const formLanguageDuplicates = () => {
          let allLanguageIds = [];
          const speakerIds = _.unique(_.pluck(speakers, 'language_id'));

          selectedForms.forEach(form => {
               if (form.language_use) {
                    allLanguageIds = [...allLanguageIds, form.language_use];
               } else {
                    form.language.forEach(lId => {
                         if (speakerIds.includes(lId)) {
                              allLanguageIds = [...allLanguageIds, lId];
                         }
                    });
               }
          });

          return _.unique(_.filter(allLanguageIds, (val, i, iteratee) => _.includes(iteratee, val, i + 1)));
     };

     useEffect(() => {
          isFormLanguagesValid();
     }, [selectedForms]);

     const handleLanguageSelect = (value) => {
          setLanguage(value);
     };

     const handleAttachFiles = (payload) => {
          const files = Array.from(payload);
          const filesForUpload = [];

          files.forEach(file => {
               const isValidSize = isValidFileSize(file, EMAILS_FILE_LIMIT);
               const isValidMime = isValidMimeType(file, MIME_EMAILS);

               if (isValidSize && isValidMime) {
                    filesForUpload.push(file);
               } else {
                    if (!isValidSize) {
                         toastr.error(t('picture_uploader.file_size_limit_hit', {limit: convertBytesToMb(EMAILS_FILE_LIMIT)}));
                    }
                    if (!isValidMime) {
                         toastr.error(t('picture_uploader.file_invalid_mime', {
                              mimes: convertMimesToExtensions(MIME_EMAILS.join(','))
                         }));
                    }
               }
          });

          if (filesForUpload.length > 0) {
               requestUploadFile({ files: filesForUpload });
          }
     };

     const handleSelectEmailTemplate = (value, defaultTemplate) => {
          const template = emailTemplatesList && emailTemplatesList.find((t) => t.id === value);
          if (template) {
               setLoadEmailTemplate(true);
               setEmailTemplate(template);
          }
          if (value === 0 && defaultTemplate) {
               setLoadEmailTemplate(true);
               setEmailTemplate(defaultTemplate);
          }
     };

     const handleSetAccessType = (value) => {
          setAccessType(value);
     };

     const handleSetNameOfTemplate = (value) => {
          setNameOfTemplate(value);
     };

     const handleSaveTemplate = (value) => {
          setSaveAsTemplate(value);
     };

     const handleNewFormAction = () => {
          saveCurrentState();
          redirectToNewForm(id, eventId);
     };

     const handleEditFormAction = () => {
          saveCurrentState();
     };

     const saveCurrentState = () => {
          localStorage.setItem(
              'invitation_flow_speakers',
              JSON.stringify(speakers, null, 2)
          );
          localStorage.setItem(
              'invitation_flow_email_data',
              JSON.stringify(emailData, null, 2)
          );
          localStorage.setItem(
              'invitation_flow_email_multilingual',
              JSON.stringify(multilingual, null, 2)
          );
          localStorage.setItem(
              'invitation_flow_email_multilingual_selected',
              JSON.stringify(multilingualSelected, null, 2)
          );
          localStorage.setItem('invitation_flow_forms_per_page', formPerPage);
     };

     const prepareData = () =>
          forms &&
          forms.map((f) => ({
               name: f.name,
               access_type: f.access_type,
               multilingual: f.multilingual,
               language: f.language,
               id: f.id,
               is_valid: f.is_valid,
               is_translated: f.is_translated
          }));

     const prepareEmailData = () => {
          const languageIds = multilingual ? _.pluck(multilingualSelected, 'id') : [language];
          const mandatoryData = exportEmailData(mandatoryLanguage);
          const filtered = emailData.filter(data => languageIds.includes(data.language_id));

          return filtered.map(data => {
               if (data.language_id !== mandatoryLanguage && data.files.length === 0) {
                    data.files = mandatoryData.files;
               }

               return data;
          });
     };

     const handleAction = ({ id, type }) => {
          if (type === 'remove') {

               SweetAlert({
                    title: i18n.t('sweet_alerts.attention'),
                    text: i18n.t('sweet_alerts.delete_invitation_form'),
                    confirmButtonText: i18n.t('buttons.delete'),
                    cancelButtonText: i18n.t('buttons.cancel'),
                    showCancelButton: true,
                    callback: () => {
                         requestRemoveForm({
                              organizationId,
                              eventId,
                              id,
                         });
                         const updatedList =
                              forms &&
                              forms.filter(({ form_id }) => form_id !== id);

                         return (
                              updatedList &&
                              setFormsData(prepareData(updatedList))
                         );
                    },
               });
          }
     };

     const handleRemoveAttachedFile = (code) => {
          const currentEmailData = emailData;
          currentEmailData.map((d) => {
               if (d.language_id === language) {
                    d.files = d.files.filter(d => d !== code);
                    d.files_data = d.files_data.filter(d => d.code !== code);
               }

               return validateEmailData(d);
          });

          setEmailData(currentEmailData);
     };

     const handleRemoveTemplate = (id) => {
          SweetAlert({
               title: i18n.t('sweet_alerts.warning'),
               text: i18n.t('sweet_alerts.delete_template'),
               confirmButtonText: i18n.t('buttons.remove'),
               cancelButtonText: i18n.t('buttons.cancel'),
               showCancelButton: true,
               callback: () => {
                    requestRemoveEmailTemplate({
                         eventId,
                         emailTemplateId: id,
                    });

                    const defaultEmailData = [];
                    languages.forEach((lang) => {
                         defaultEmailData.push({
                              is_valid: false,
                              subject: '',
                              message: '',
                              language_id: lang.id,
                              sender: {
                                   name: currentUser && `${currentUser.firstname} ${currentUser.lastname}`,
                                   email: currentUser && currentUser.email
                              },
                              files: [],
                              files_data: []
                         });
                    });

                    setEmailData(defaultEmailData);
                    setQuillMessage('');
               },
          });
     };

     const handleAddSpeaker = () => {
          setSpeakers([
               ...speakers,
               {
                    id: getRandomId(),
                    name: '',
                    email: '',
                    language_id: organization && organization.language.id,
                    errors: []
               },
          ]);
     };

     const removeSpeaker = (id) => {
          let newSpeakers = speakers.filter((speaker) => {
               return speaker.id !== id;
          });

          setSpeakers(newSpeakers);
     };

     const handleRemoveSpeaker = (id) => {
          SweetAlert({
               title: i18n.t('sweet_alerts.warning'),
               text: i18n.t('sweet_alerts.remove_speaker'),
               confirmButtonText: i18n.t('buttons.remove'),
               cancelButtonText: i18n.t('buttons.cancel'),
               showCancelButton: true,
               callback: () => {
                    removeSpeaker(id);
                    toastr.success(i18n.t('alerts.success.speaker_removed'));
               },
          });
     };

     const handleBulkAddSpeakers = async (newSpeakers) => {
          await setSpeakers([
               ...speakers.filter(
                    ({ name, email }) => name.length > 0 || email.length > 0
               ),
               ...newSpeakers.map((s) => ({ ...s, errors: [], bulk: true })),
          ]);

          setMessage(
               newSpeakers.length === 1
                    ? toastr.success(i18n.t('alerts.success.speaker_added'))
                    : toastr.success(
                           `${newSpeakers.length} ${i18n.t(
                                'alerts.success.speakers_added'
                           )}`
                      )
          );
     };

     const handleFieldChange = ({ id, newSpeaker, index }) => {
          let items = [...speakers];
          items[index] = newSpeaker;
          setSpeakers([...items]);
     };

     const setInvalidSpeakers = (invalidSpeakers) => {
          let items = [...speakers];

          invalidSpeakers.forEach((speaker) => {
               items[speaker.index] = speaker.newSpeaker;
          });

          setSpeakers([...items]);
     };

     const schema = yupObject().shape({
          name: yupString()
              .matches(/\s/, t('validations.enter_full_name'))
              .required(t('validations.name_required')),
          email: yupString()
              .email(t('validations.invalid_email'))
              .required(t('validations.email_required')),
     });

     useEffect(() => {
          validateSpeakers();
     }, [runValidations, speakersInvalid]);

     const validateSpeakers = () => {
          let itemProcessed = 0;
          let invalidSpeakers = [];

          const redirect = () => {
               history.push(
                   `/organization/${id}/events/${eventId}/invite-speakers/step-2`
               );
               document
                   .querySelector('.App')
                   .scrollIntoView({ behavior: 'smooth' });
          };

          const isDone = (proceseed, total) => {
               if (proceseed === total && invalidSpeakers.length === 0) {
                    redirect();
               } else {
                    setInvalidSpeakers(invalidSpeakers);
               }
          };

          speakers.forEach((speaker, index, array) => {
               const {name, email} = speaker;

               schema
                   .validate(
                       {
                            name,
                            email,
                       },
                       {abortEarly: false}
                   )
                   .then(() => {
                        itemProcessed++;
                        isDone(itemProcessed, array.length);
                   })
                   .catch((err) => {
                        let errors = [];
                        err.inner.forEach((error) => {
                             errors[error.path] = error.errors;
                        });
                        invalidSpeakers.push({id: speaker.id, newSpeaker: {...speaker, errors}, index});
                        itemProcessed++;
                        isDone(itemProcessed, array.length);
                   });
          });
     };

     const selectSpeakerForm = (langId) => {
          const form = selectedForms.find(form => {
               if (form.multilingual) {
                    if (form.language_use && form.language_use === langId) {
                         return true;
                    } else if (form.language_use === undefined && form.language.includes(langId)) {
                         return true;
                    }
               } else if (form.language.includes(langId)) {
                    return true;
               }

               return false;
          });

          if (form) {
               return form.id;
          }

          return null;
     };

     const handleSendIvitation = (values) => {
          let invitationPayload;
          let emailTemplatePayload;

          if (!speakerInviteRequested) {
               invitationPayload = {
                    language_id: mandatoryLanguage,
                    event_id: eventId,
                    speakers: speakers.map(({ name, email, language_id }) => ({
                         name,
                         email,
                         language: language_id,
                         form_id: selectSpeakerForm(language_id)
                    })),
                    email_template: {},
                    email: {
                         multilingual,
                         data: prepareEmailData(),
                    },
               };

               emailTemplatePayload = {
                    language_id: mandatoryLanguage,
                    name: values.nameOfTemplate,
                    access_type: accessType.toLocaleLowerCase(),
                    multilingual,
                    data: prepareEmailData(),
               };

               requestSpeakerInvite({
                    id,
                    eventId,
                    saveAsTemplate,
                    invitationPayload,
                    emailTemplatePayload,
               });
          }
     };

     return (
          <SpeakersWrapper>
               <Helmet>
                    <title>
                         {t('speakers.invite_speakers')} | {t('avovent')}
                    </title>
               </Helmet>
               <MainDrawer>
                    <ListItem to="/dashboard">
                         {t('breadcrumb.dashboard')}
                    </ListItem>
                    <CollapsibleList
                         listHeader={organization && organization.name}
                         listContent={
                              <>
                                   <ListItem
                                        to={`/organization/${id}/events/${eventId}/event-edit`}
                                   >
                                        <div className="collapsible-list-item">
                                             <GroupChatImage />
                                             <div className="sub-item">
                                                  {t('events')}
                                             </div>
                                        </div>
                                   </ListItem>
                                   <CollapsibleList
                                        listHeader={
                                             <div className="collapsible-list-item">
                                                  <SettingsImage />
                                                  <div className="sub-item">
                                                       {t(
                                                            'organization_settings.organization_settings'
                                                       )}
                                                  </div>
                                             </div>
                                        }
                                        listContent={
                                             <>
                                                  <ListItem
                                                       subItem
                                                       to={`/organization/${id}/settings`}
                                                  >
                                                       <div className="collapsible-list-item">
                                                            <DotImage />
                                                            <div className="sub-item">
                                                                 {t(
                                                                      'organization_settings.organization_settings'
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </ListItem>
                                                  <ListItem
                                                       subItem
                                                       to={`/organization/${id}/members`}
                                                  >
                                                       <div className="collapsible-list-item">
                                                            <DotImage />
                                                            <div className="sub-item">
                                                                 {t(
                                                                      'organization_settings.team_members'
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </ListItem>
                                                  <ListItem
                                                       subItem
                                                       to={`/organization/${id}/email-senders`}
                                                  >
                                                       <div className="collapsible-list-item">
                                                            <DotImage />
                                                            <div className="sub-item">
                                                                 {t(
                                                                      'organization_settings.email_senders'
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </ListItem>
                                             </>
                                        }
                                   />
                              </>
                         }
                    />
                    <CollapsibleList
                         listHeader={eventData && eventData.name}
                         listContent={
                              <>
                                   <ListItem
                                        to={`/organization/${id}/events/${eventId}/speakers`}
                                   >
                                        <div className="collapsible-list-item">
                                             <SpeakersIcon />
                                             <div className="sub-item">
                                                  {t('event.speakers')}
                                             </div>
                                        </div>
                                   </ListItem>
                                   <ListItem
                                       to={`/organization/${id}/events/${eventId}/speakers`}
                                   >
                                        <div className="collapsible-list-item">
                                             <ReminderIcon />
                                             <div className="sub-item">
                                                  {t('reminders.reminders')}
                                             </div>
                                        </div>
                                   </ListItem>
                                   <ListItem
                                        to={`/organization/${id}/events/${eventId}/event-edit`}
                                   >
                                        <div className="collapsible-list-item">
                                             <SettingsImage />
                                             <div className="sub-item">
                                                  {t('event.event_settings')}
                                             </div>
                                        </div>
                                   </ListItem>
                              </>
                         }
                    />
               </MainDrawer>
               <Route
                    exact
                    path={`/organization/${id}/events/${eventId}/invite-speakers`}
               />
               <div className="content--border">
                    <div
                         className="container-standard"
                         style={{ display: 'flex', alignItems: 'center' }}
                    >
                         <div className="nav">
                              <Link to="/dashboard" className="inactive">
                                   {t('breadcrumb.dashboard')}
                              </Link>
                              <OrganizationDropdown organization={organization} inactive/>
                              <EventDropdown />
                         </div>
                    </div>
                    <LoadingBar finish={!!organization && !!eventData} />
               </div>
               <div className="container-standard">
                    {organization && eventData && (
                         <Breadcrumb
                              title={t('speakers.invite_speakers')}
                              links={links}
                         />
                    )}
               </div>
               <div className="content container-standard">
                    <div className="wizard-form">
                         <Nav variant="tabs">
                              <Nav.Item>
                                   <NavLink
                                        className="nav-link"
                                        role="tab"
                                        to={`/organization/${id}/events/${eventId}/invite-speakers/step-1`}
                                   >
                                        <div
                                            style={{
                                                 diplay: 'flex',
                                                 alignItems: 'flexEnd',
                                            }}
                                        >
                                             <span className="step">1</span>
                                             <span className="label">
                                                  {t(
                                                      'speakers.invite_speakers'
                                                  )}
                                             </span>
                                             <div className="nav-border-bottom" />
                                        </div>
                                   </NavLink>
                              </Nav.Item>
                              <Nav.Item>
                                   <NavLink
                                       className={`nav-link ${isStepActivated(2) && (!isSpeakerAndFormsValid() || !isFormLanguagesValid()) ? 'warning' : ''}`}
                                        role="tab"
                                        to={`/organization/${id}/events/${eventId}/invite-speakers/step-2`}
                                   >
                                        <div
                                            style={{
                                                 diplay: 'flex',
                                                 alignItems: 'flexEnd',
                                            }}
                                        >
                                             <span className="step">2</span>
                                             <span className="label">
                                                  {t(
                                                      'speakers.invitation_form'
                                                  )}
                                             </span>
                                             <div className="nav-border-bottom" />
                                        </div>
                                   </NavLink>
                              </Nav.Item>
                              <Nav.Item>
                                   <NavLink
                                        className={`nav-link ${isStepActivated(3) && (!isValidEmailData() || !isSpeakerAndEmailLangValid()) ? 'warning' : ''}`}
                                        role="tab"
                                        to={`/organization/${id}/events/${eventId}/invite-speakers/step-3`}
                                   >
                                        <div
                                             style={{
                                                  diplay: 'flex',
                                                  alignItems: 'flexEnd',
                                             }}
                                        >
                                             <span className="step">3</span>
                                             <span className="label">
                                                  {t(
                                                       'speakers.invitation_email'
                                                  )}
                                             </span>
                                             <div className="nav-border-bottom" />
                                        </div>
                                   </NavLink>
                              </Nav.Item>
                         </Nav>
                         {currentStep === 3 && multilingual && (
                             <div className="language-holder">
                                  <div className="language-selector">
                                       <div className="language-buttons">
                                            {multilingualSelected.map(
                                                (lang) => {
                                                     return (
                                                    <Button
                                                        variant={getLanguageClassName(lang.id)}
                                                        key={
                                                             lang.id
                                                        }
                                                        onClick={() => {
                                                             handleLanguageSelect(lang.id)
                                                        }}
                                                    >
                                                         {lang.name}
                                                    </Button>
                                                )}
                                            )}
                                       </div>
                                  </div>
                             </div>
                         )}
                         <Switch>
                              <Route
                                   path={`/organization/${id}/events/${eventId}/invite-speakers/step-1`}
                              >
                                   <Tab.Pane>
                                        <InviteSpeakers
                                            id={id}
                                            eventId={eventId}
                                            speakers={speakers}
                                            handleAddSpeaker={handleAddSpeaker}
                                            organizationLanguage={
                                                 organization &&
                                                 organization.language.id
                                            }
                                            handleFieldChange={
                                                 handleFieldChange
                                            }
                                            handleBulkAddSpeakers={
                                                 handleBulkAddSpeakers
                                            }
                                            handleRemoveSpeaker={
                                                 handleRemoveSpeaker
                                            }
                                            validateSpeakers={validateSpeakers}
                                            runValidations={runValidations}
                                            setRunValidations={
                                                 setRunValidations
                                            }
                                            speakersInvalid={speakersInvalid}
                                            setSpeakersInvalid={
                                                 setSpeakersInvalid
                                            }
                                            handleChangeCurrentStep={handleChangeCurrentStep}
                                            languages={languages}
                                        />
                                   </Tab.Pane>
                              </Route>
                              <Route
                                   path={`/organization/${id}/events/${eventId}/invite-speakers/step-2`}
                              >
                                   <Tab.Pane>
                                        <InvitationForms
                                             id={id}
                                             forms={forms}
                                             queryString={queryString}
                                             searchIsUsed={searchIsUsed}
                                             eventId={eventId}
                                             handlePageSizeChange={handlePageSizeChange}
                                             handlePageChange={handlePageChange}
                                             handleSearch={handleSearch}
                                             prepareData={formsData}
                                             handleAction={handleAction}
                                             handleNewFormAction={handleNewFormAction}
                                             handleEditFormAction={handleEditFormAction}
                                             handleChangeCurrentStep={handleChangeCurrentStep}
                                             selectedForms={selectedForms}
                                             handleFormSelect={handleFormSelect}
                                             isSpeakerAndFormsValid={isSpeakerAndFormsValid}
                                             isFormLanguagesValid={isFormLanguagesValid}
                                             formLanguageDuplicates={formLanguageDuplicates}
                                             languages={languages}
                                             handleSelectDuplicatedForm={handleSelectDuplicatedForm}
                                        />
                                   </Tab.Pane>
                              </Route>
                              <Route
                                   path={`/organization/${id}/events/${eventId}/invite-speakers/step-3`}
                              >
                                   <Tab.Pane>
                                        <InvitationEmail
                                             id={id}
                                             eventId={eventId}
                                             emailTemplatesList={
                                                  emailTemplatesList
                                             }
                                             language={language}
                                             handleRemoveTemplate={
                                                  handleRemoveTemplate
                                             }
                                             emailSendersList={emailSendersList}
                                             handleMultilingual={
                                                  handleMultilingual
                                             }
                                             handleMultilingualSelected={
                                                  handleMultilingualSelected
                                             }
                                             handleQuillMessage={
                                                  handleQuillMessage
                                             }
                                             quillError={quillError}
                                             handleLanguageSelect={
                                                  handleLanguageSelect
                                             }
                                             handleSelectEmailTemplate={
                                                  handleSelectEmailTemplate
                                             }
                                             handleSetAccessType={
                                                  handleSetAccessType
                                             }
                                             handleAttachFiles={handleAttachFiles}
                                             handleSendIvitation={
                                                  handleSendIvitation
                                             }
                                             handleSaveTemplate={
                                                  handleSaveTemplate
                                             }
                                             emailData={exportEmailData}
                                             currentUser={currentUser}
                                             languages={languages}
                                             multilingual={multilingual}
                                             multilingualSelected={multilingualSelected}
                                             currentLanguage={language && languages.find((lang) => lang.id === language)}
                                             mandatoryLanguage={mandatoryLanguage && languages.find((lang) => lang.id === mandatoryLanguage)}
                                             handleSetNameOfTemplate={handleSetNameOfTemplate}
                                             isValidEmailData={isValidEmailData}
                                             isSpeakerAndEmailLangValid={isSpeakerAndEmailLangValid}
                                             handleSubjectChange={handleEmailSubjectChanged}
                                             handleCustomSender={handleCustomSender}
                                             handleSenderName={handleSenderName}
                                             handleSenderEmail={handleSenderEmail}
                                             handleChangeCurrentStep={handleChangeCurrentStep}
                                             handleMandatoryLanguage={handleMandatoryLanguage}
                                             isEnteredLinks={isEnteredLinks}
                                             fileData={fileData}
                                             filesData={filesData}
                                             handleRemoveAttachedFile={handleRemoveAttachedFile}
                                             shouldFilesBeTranslated={shouldFilesBeTranslated}
                                             speakerInviteRequested={speakerInviteRequested}
                                             uploadFileRequested={uploadFileRequested}
                                        />
                                   </Tab.Pane>
                              </Route>
                         </Switch>
                    </div>
               </div>
          </SpeakersWrapper>
     );
});

export const WizardForm = connect(
     mapStateToProps,
     mapDispatchToProps
)(_WizardForm);
