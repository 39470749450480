import React, {memo, useCallback, useEffect, useState} from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
    requestDownloadMediaFile
} from "../../../redux/reducers/speakerReducer";
import {connect} from "react-redux";
import {ImagePreviewLoader} from "../loader/ImagePreviewLoader";
import _ from 'underscore';

const mapDispatchToProps = (dispatch) => ({
    downloadMediaFile: (payload) => dispatch(requestDownloadMediaFile(payload)),
});

export const _ImagePreviewModal = memo(
    ({ show, media, name, downloadMediaFile, handleClose, handleUpload }) => {
        const { t } = useTranslation();

        const [url, setUrl] = useState(null);
        const [loading, setLoading] = useState(true);

        useEffect(() => {
            // Disable scrolling on main page
            document.body.style.overflow = show ? 'hidden' : 'unset';
        }, [show]);

        useEffect(() => {
            if (media && _.isObject(media)) {
                setUrl(`${media['640x640']}?t=${Date.now()}`);
            } else if (media) {
                setUrl(media);
            }
        }, [media]);

        const handleDownload = useCallback(() => {
            if (name !== null) {
                downloadMediaFile({file: media['original'], name});
            }
        }, [downloadMediaFile, media, name]);

        return (
            <Modal
                show={show}
                onHide={handleClose}
                container={document.getElementById('root')}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t('picture_uploader.preview')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading && (
                        <ImagePreviewLoader style={{ width: '100%' }} />
                    )}
                    <img
                        alt="preview"
                        style={{ display: loading ? 'none' : 'block', margin: '0 auto' }}
                        src={url} width="640px"
                        onLoad={() => { setLoading(false) }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        style={{ justifySelf: 'flex-start', marginRight: 'auto' }}
                        onClick={() => { handleUpload() }}
                    >
                        {t('buttons.upload_new')}
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        {t('buttons.cancel')}
                    </Button>
                    {name !== null && (
                        <Button
                            variant="primary"
                            type="button"
                            onClick={handleDownload}
                        >
                            {t('buttons.download')}
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        );
    }
);

export const ImagePreviewModal = connect(null, mapDispatchToProps)(_ImagePreviewModal);
