import avatar from '../assets/mock-img/avatar-basic.svg';
import eventLogo from '../assets/mock-img/event-logo-base.svg';
import eventLogo2 from '../assets/mock-img/event-logo.svg';

// TODO: Remove these mocked data once backend is ready.
/**
 * Users
 */
export const User1 = {
     avatar,
     username: 'Pied Piper',
     email: 'piedpiper@example.com',
};

export const User2 = {
     avatar,
     name: 'Richard',
     lastname: ' Hendricks',
     email: 'richhendricks@example.com',
};

/**
 * Events
 */
export const Event1 = {
     eventLogo,
     name: 'Techcrunch Disrupt 2014',
     date: 'Sep 6 - Sep 10, 2020',
};

export const Event2 = {
     eventLogo2,
     name: 'Game Hackaton',
     date: 'Jun 2 - Jun 3, 2020',
};
