import { createGlobalStyle } from 'styled-components';
import {
     COLORS,
     FLEX_ROW_CENTER,
     FONT,
     BORDER_THEMED,
     FORM_PLACEHOLDER,
     SIZE,
} from './constants/style.constants';
import { appScrollbar } from './utils/style.utils';

const GlobalStyles = createGlobalStyle`
     html, body, * {
          font-family: 'IBM Plex Sans', sans-serif;
          margin: 0;
     }

     body {
          #toast-container > div {
               position: relative;
               pointer-events: auto;
               overflow: hidden;
               margin: 0 0 6px;
               padding: 15px 20px 15px 20px;
               width: 300px;
               border-radius: 3px 3px 3px 3px;
               box-shadow: none;
               opacity: 1;
          }
          #toast-container > div:hover {
               -moz-box-shadow: none;
               -webkit-box-shadow: none;
               box-shadow: none;
          }
          #toast-container > :hover {
               -moz-box-shadow: none;
               -webkit-box-shadow: none;
               box-shadow: none;
          }
          #toast-container > .toast {
               background-image: none !important;
          }
          .toast {
               border: 0;
               max-width: 350px;
               font-size: 0.875rem;
               backdrop-filter: blur(10px);
          }
          .toast-success {
               background: none;
               background-color: ${COLORS.BOOTSTRAP_GREEN};
               
               .toast-message {
                    color: ${COLORS.WHITE};
               }
          }
          .toast-error {
               background: none;
               background-color: ${COLORS.RED};
               
               .toast-message {
                    color: ${COLORS.WHITE};
               }
          }
          .toast-warning {
               background: none;
               background-color: ${COLORS.YELLOW};
               
               .toast-message {
                    color: ${COLORS.WHITE};
               }
          }
          .toast-info {
               background: none;
               background-color: ${COLORS.BLUE};
               
               .toast-message {
                    color: ${COLORS.WHITE};
               }
          }
     }
     
     .spec-table {
          display: block;
          overflow-x: auto;
          white-space: nowrap;
          
          tbody {
            display: table;
            width: 100%;
          }
          
          thead {
            display: table;
            width: 100%;
          }
        }
        
     .App {
          min-height: 100vh;
          display: flex;
          flex-direction: column;
     }

     .toolbar-expanded {
          background-color: #fff;
          border-bottom: 1px solid ${COLORS.LIGHTER_BLUE};
          visibility: hidden;
          position: absolute;
          left: 0;
          top: 0;
          padding-top: 10px !important;
          padding-bottom: 10px !important;
          display: flex;
          flex-direction: row-reverse;
          width: 100%;
          z-index: 600;

          &.toolbar-shown {
               visibility: visible;
               top: 101px;
               transition: all 0.15s ease;
          }
     }

     /* Forms */
     .form-control:focus {
          color: ${COLORS.TEXT_GREY};
          background-color: transparent;
          border-color: ${COLORS.SKY_BLUE};
          outline: 0 !important;
          /* because Metronic uses that */
          box-shadow: none !important;
          z-index: 0 !important;
     }

     .flex-center {
          width: 100%;
          ${FLEX_ROW_CENTER};
     }

     .footer-link {
          color: ${COLORS.LINKS_GREY};
     }

     .subfooter-links {
          display: flex;
          align-content: flex-start;
          a:not(:last-child) {
               padding-right: 15px;
          }
     }

    /* Swal */
    .swal2-title {
          color: ${COLORS.DARK_BLUE};
          font-weight: ${FONT.MEDIUM};
          font-size: ${SIZE.HUGE};
          line-height: 23px;
          text-align: center;
     }

     .swal2-content {
          color: ${COLORS.TEXT_GREY};
          font-weight: ${FONT.REGULAR};
          font-size: ${SIZE.INCREASED};
          line-height: 19px;
          text-align: center;
               .swal2-checkbox {
                    cursor: pointer;
                    display: flex;
                    align-content: center;

                    height: 20px !important;

                    .swal2-label:after {
                         display: none;
                    }

                    input[type='checkbox'] {
                         position: relative !important;
                         outline: none !important;
                         opacity: 1 !important;
                         margin-top: -3px;
                         margin-left: 0 !important;
                         margin-right: 12px;
                    }

                    input[type='checkbox']:checked ~ span {
                         transition: all 0.15s ease;
                         border: none !important;
                    }
               }
          
     }

     .swal2-actions {
          width: 100%;

          .btn {
               margin: 0 10px !important;
          }

          .btn-primary {
               background-color: ${COLORS.BLUE};
               &:hover {
                    background-color: ${COLORS.BOOTSTRAP_BLUE};
                    border-color: ${COLORS.BOOTSTRAP_BORDER_BLUE};
               }
          }

          .btn-light {
               color: ${COLORS.TEXT_GREY};
               &:hover {
                    background-color: ${COLORS.LIGHTER_BLUE};
               }
          }

     }

     /* Buttons */
     .btn {
          font-weight: ${FONT.BOLD};
          font-size: ${SIZE.MICRO};
          line-height: 14px;
          text-transform: uppercase;
          text-align: center;
          border-radius: 3px;
          padding: 0.75rem 1.45rem;

          &.btn-themed-blue {
               background-color: rgba(24, 112, 244, 0.12);
               border: 1px solid transparent !important;
               color: ${COLORS.BLUE};
               font-weight: ${FONT.MEDIUM};
               font-size: ${SIZE.NORMAL};
               line-height: 18px;
               margin-top: 15px;
               margin-bottom: 15px;
               padding: 12px 25px;

               &:active,
               &:hover {
                    background-color: rgba(24, 112, 244, 0.22);
                    border: 1px solid transparent !important;
                    color: ${COLORS.BLUE};
                    font-weight: ${FONT.MEDIUM};
                    margin-top: 15px;
                    margin-bottom: 15px;
               }
          }

          &.btn-navigational {
               text-transform: unset;
          }

          &.btn-sm {
               margin-left: 10px !important;
               min-width: 86px !important;
               min-height: 37px !important;
          }

          &.btn-lg {
               min-width: 123px;
               min-height: 50px;
          }

          &.btn-success {
               background-color: ${COLORS.GREEN};
               border: ${COLORS.GREEN};

               &:hover {
                    background-color: ${COLORS.DARKER_GREEN};
                    border: ${COLORS.BORDER_GREEN};
               }
          }

          &.btn-light {
               background-color: transparent;
               ${BORDER_THEMED};
               color: ${COLORS.TEXT_GREY};
          }

          &.btn-default {
               border: 0;
               background-color: transparent;
               color: ${COLORS.PLACEHOLDER_GREY};
               ${BORDER_THEMED};

               &:hover {
                    background-color: ${COLORS.LIGHTER_BLUE};
               }
          }
          &.btn-danger {
               padding: 14px 50px;
               background-color: ${COLORS.LIGHT_RED};
               color: ${COLORS.RED};
               border: none;
               &:hover,
               :active {
                    background-color: ${COLORS.RED};
                    color: ${COLORS.WHITE};
               }
          }
          
     }

     .btn:focus {
          outline: none !important;
          box-shadow: none !important;
     }

     .content {
          flex: 1 0 auto;
     }

     .container-standard {
          max-width: 1320px;
          width: 100%; 
          margin: auto;
          /* padding: 0 30px; */
     }

     .content-body {
          display: flex;
          flex-direction: column;
          align-self: center;
          ${BORDER_THEMED};
          box-sizing: border-box;
          border-radius: 4px;
          /* margin-top: 20px; */
          margin-bottom: 91px;

          .heading {
               padding: 21px 25px;
               border-bottom: 1px solid ${COLORS.LIGHTER_BLUE};
          }

          h1 {
               font-weight: ${FONT.MEDIUM};
               font-size: ${SIZE.BIG};
               /* line-height: 22px; */
               color: ${COLORS.DARK_BLUE};
          }

          p {
               font-weight: ${FONT.REGULAR};
               font-size: ${SIZE.NORMAL};
               /* line-height: 21px; */
               color: ${COLORS.DARK_BLUE};
          }
     }

     footer {
          flex-shrink: 0;
     }

     /* Grids */
     .app-grid {
          display: grid;
          grid-template-columns: 350px auto;
          grid-column-gap: 20px;
          align-items: flex-start;
          margin-bottom: 30px;
     }

     .form-builder-grid {
          display: grid;
          grid-template-columns: 390px auto;
          grid-column-gap: 20px;
          align-items: flex-start;
          margin-bottom: 30px;
     }

     /* RangePicker */
     .show-calendar {
          margin: 0 !important;
          border: none !important;
          box-shadow: 0px 0px 20px 0px rgba(82, 63, 105, 0.15) !important;
     }

     .show-calendar:before,
     .show-calendar:after {
          display: none !important;
     }

     .drp-calendar {
          padding: 14px 21px !important;
          font-size: ${SIZE.NORMAL} !important;
     }

     th,
     td,
     .month {
          font-size: ${SIZE.NORMAL} !important;
     }

     tr:nth-child(2) > th {
          font-weight: ${FONT.MEDIUM} !important;
          color: ${COLORS.DARK_BLUE} !important;
     }

     td.active,
     td.active:hover {
          background-color: ${COLORS.BLUISH} !important;
          color: ${COLORS.WHITE} !important;
     }

     .today,
     .today:hover,
     .today.in-range.available,
     .today.in-range.available:hover {
          border-radius: 4px !important;
          background: rgba(14, 108, 247, 0.7) !important;
          color: ${COLORS.WHITE} !important;
     }

     .in-range {
          background-color: ${COLORS.PALE_BLUE} !important;
     }

     .month {
          color: ${COLORS.TEXT_GREY} !important;
          font-weight: 300 !important;
     }

     .available > span {
          border-width: 0 1px 1px 0 !important;
          border-color: ${COLORS.TEXT_GREY} !important;
     }

     .drp-buttons {
          padding: 14px 25px !important;
     }

     .drp-buttons > button {
          padding: 7px 14px !important;
     }

     .applyBtn {
          background-color: ${COLORS.BLUISH} !important;
          border-color: ${COLORS.BLUISH} !important;
     }

     .applyBtn:hover {
          background-color: ${COLORS.SAPPHIRE_BLUE} !important;
          border-color: ${COLORS.SAPPHIRE_BLUE} !important;
     }

     .cancelBtn {
          background-color: transparent !important;
          border: 1px solid ${COLORS.SILVER} !important;
          color: ${COLORS.TEXT_GREY} !important;
     }

     .cancelBtn:hover {
          background-color: ${COLORS.WHITE_SMOKE} !important;
     }


     /* Modal styles */
     .selection-modal {
          .modal-body {
               padding: 30px 30px 10px 30px !important;
          }
     }

     .modal-header,
     .modal-footer {
          padding: 20px !important;
     }

     .modal-body {
          padding: 30px !important;
     }
     
     .modal-content {
          border: 1px solid ${COLORS.BLUISH_WHITE};
          border-radius: 4px;
          /* width: 500px; */
     }

     .modal-header{
          display: flex;
          align-items: center;
          .modal-title {
               font-weight: ${FONT.MEDIUM};
               font-size: ${SIZE.BIG};
               line-height: 22px;
               color: ${COLORS.DARK_BLUE};
          }
     }

     .close {
          color: ${COLORS.TEXT_GREY};
          font-family: "LineAwesome";
          font-size: ${SIZE.LARGER};
          font-weight: ${FONT.BOLD};
          opacity: .5;

          span {
               font-weight: ${FONT.LIGHT};
               font-size: 30px;
          }
     }
     
     .close:hover,
     .close:active {
          color: ${COLORS.BLUISH};
     }

     .close:focus {
          outline: none !important;
          
     }

     .info-block {
          margin-top: 10px;
          font-weight: ${FONT.REGULAR};
          font-size: ${SIZE.SMALL};
          line-height: 17px;
          color: ${COLORS.PLACEHOLDER_GREY};
          word-break: break-word;
     }     

     .preview {
          padding: 30px;
          overflow-y: scroll;

          .row{
               margin-bottom: 20px;

               .col-3 {
                    margin-top: 7px;
               }

               .form-label {
                    font-weight: ${FONT.REGULAR};
                    font-size: ${SIZE.NORMAL};
                    line-height: 18px;
                    color: ${COLORS.DARK_BLUE};
               }

               .col-9{
                    input::placeholder {
                    ${FORM_PLACEHOLDER};
                    color: ${COLORS.PLACEHOLDER_GREY};
                    }
               }
          }
          
          .input-group {
               input {
                    border-radius: 4px !important;
               }
               .suffix {
                    background-color: ${COLORS.WHITE};
                    border-radius: 4px !important;
                    display: flex;
                    align-items: center;
                    position: absolute;
                    top: 1px;
                    right: 1px;
                    padding: 10px;
                    max-height: 39px;

                    .la-calendar-check{
                         color: ${COLORS.TEXT_GREY};
                    }
               }
          }

          .file_upload {
               margin-bottom: 0;
          }
          
     }

     /* Scroller */
     .toolbar {
          max-height: 555px !important;
          overflow-y: auto;
          transition: all 0.15 ease;

          ${appScrollbar}
     }

     .form-control {
          &.is-invalid {
               background-position: right 12px center;
               padding-right: 30px;
          }
     }

     .modal-body {
          /*cropper */
          .img-container {
               position: relative;
               margin: 0 auto;
               width: 100%;
               height: 500px;
               
               img {
                  max-width: 812px;
                  max-height: 500px;
                  margin: 0 auto;
               }
               
               &.dont-show-image {
                  opacity: 0;
               }
          }
          .img-tooltip {
               background-color: rgba(0, 0, 0, 0.6);
               border-radius: 3px;
               color: #fff;
               font-size: ${SIZE.MICRO};
               font-weight: ${FONT.BOLD};
               padding: 8px 18px;
               position: absolute;
               top: -20%;
               left: -50%;
               text-transform: uppercase;

               &.hidden {
                    visibility: hidden;
               }
          }
          .cropper-center {
               display: flex;
               flex-wrap: wrap;
               border-radius: 3px;
               color: #fff;
               font-size: ${SIZE.MICRO};
               font-weight: ${FONT.BOLD};
               padding: 8px 18px;
               height: 50px;
               width: 150px;
               position: absolute;
               top: 50%;
               left: 50%;
               text-transform: uppercase;
               &:before,
               &:after {
                    content: none;
               }
          }
          .cropper-modal {
               background-color: ${COLORS.WHITE};
               opacity: 0.5;
          }

          .minus-button {
               margin-right: 20px;
               border: none;
               background-color: ${COLORS.LIGHTER_BLUE};
               cursor: pointer;
               font-size: ${SIZE.TINY};
               height: 20px;
               width: 20px;
               border-radius: 50%;
               position: relative;

               &:disabled {
                    opacity: 0.3;
               }

               &:hover {
                     background-color: #b9d4fc;
               }

               &:hover:after,
               &:hover:before {
                    background-color: ${COLORS.BLUE};
               }

               &:after,
               &:before {
                    content: '';
                    display: block;
                    background-color: ${COLORS.PLACEHOLDER_GREY};
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
               }

               &:after {
                    border-radius: 3px;
                    height: 0.2em;
                    width: 1em;
               }

               &:focus {
                    outline: none;
               }
          }

          .plus-button {
               margin-left: 20px;
               border: none;
               background-color: ${COLORS.LIGHTER_BLUE};
               font-size: ${SIZE.TINY};
               height: 20px;
               width: 20px;
               border-radius: 50%;
               position: relative;

               &:hover {
                    background-color: #b9d4fc;
               }

               &:hover:after,
               &:hover:before {
                    background-color: ${COLORS.BLUE};
               }

               &:after,
               &:before {
                    content: '';
                    display: block;
                    background-color: ${COLORS.PLACEHOLDER_GREY};
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
               }

               &:before {
                    border-radius: 3px;
                    height: 1em;
                    width: 0.2em;
               }

               &:after {
                    border-radius: 3px;
                    height: 0.2em;
                    width: 1em;
               }

               &:focus {
                    outline: none;
               }
          }
          .zoom-slider {
               display: flex;
               flex-direction: row;
               justify-content: center;
               align-items: center;
               margin-top: 30px;
          }

          input[type='range'] {
               min-width: 50%;
               /* min-width: 457px; */
          }

          .input-group {
               .form-control {
                    border-radius: 4px !important;
                    min-height: 41px;
               }
               span {
                    display: flex;
                    align-items: center;
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding: 15px;
                    max-height: 41px;
               }
          }

          padding: 18px;
          p {
               margin-bottom: 20px;
          }

          .form-label {
               margin-bottom: 10px;
          }
          p, .form-label {
               cursor: pointer;
               font-weight: ${FONT.REGULAR};
               font-size: ${SIZE.NORMAL};
               line-height: 18px;
               color: ${COLORS.DARK_BLUE};
          }

          .form-group {
               margin: 0;

               
          }
          .form-control {
               font-weight: ${FONT.REGULAR};
               font-size: ${SIZE.SMALL};
               /* line-height: 17px; */
               color: ${COLORS.TEXT_GREY};
               padding: 12px 15px;
               /* padding: 12px 35px 12px 15px; */
          }

          .checkbox-solid {
               display: flex;
               align-content: center;
               cursor: pointer;
               position: relative;
               padding-left: 30px;
               transition: all 0.15s ease;

               span {
                    background-color: ${COLORS.LIGHTER_BLUE};
                    border-radius: 2px;
                    position: absolute;
                    top: 1px;
                    left: 0;
                    height: 18px;
                    width: 18px;
                    padding-right: 10px;

                    &:after {
                         display: none;
                    }
               }

               .form-label {
                    line-height: 1.5 !important;
                    margin-bottom: 0 !important;
               }
          }

          input[type='checkbox'] {
               position: absolute;
               z-index: -1;
               opacity: 0;
          }

          input[type='checkbox']:checked ~ span {
               background-color: ${COLORS.BLUE};

               &:after {
                    content: '';
                    position: absolute;
                    display: none;
                    top: 50%;
                    left: 50%;
                    margin-left: -2px;
                    margin-top: -6px;
                    width: 5px;
                    height: 10px;
                    border-width: 0 2px 2px 0 /*rtl:ignore*/ !important;
                    transform: rotate(45deg) /*rtl:ignore*/;
                    border: solid ${COLORS.WHITE};
                    display: block;
               }
          }

          .radio-group {
               transition: all 0.15s ease;
               display: flex;
               align-items: center;

               &:first-child {
                    padding-top: 12px;
                    padding-bottom: 20px;
               }

               .form-label {
                    cursor: pointer;
                    display: flex;
                    position: relative;
                    margin-bottom: 0;

                    &:not(:first-child) {
                         margin-left: 26px;
                    }

                    .circle-outer {
                         min-height: 18px;
                         min-width: 18px;
                         margin-right: 13px;
                         background-color: ${COLORS.LIGHTER_BLUE};
                         border-radius: 50%;
                         z-index: 1;

                         .circle-inner {
                              position: absolute;
                              background-color: ${COLORS.LIGHTER_BLUE};
                              border-radius: 50%;
                              min-height: 6px;
                              min-width: 6px;
                              top: 6px;
                              left: 6px;
                         }
                    }

                    input:checked ~ .circle-outer {
                         position: relative;
                         background-color: ${COLORS.BLUISH};

                         .circle-inner {
                              background-color: ${COLORS.WHITE};
                         }
                    }
               }             

               input[type="radio"] {
                    position: absolute;
                    opacity: 0;
                    z-index: -1;
               }
          }

          .separating-line{
               margin: 0;
               border-top: 1px dashed ${COLORS.LIGHTER_BLUE};
          }

          tags{
               min-height: 41px;
          }
     }

     .modal-footer {
          padding: 14px;
          border-top: 1px solid ${COLORS.BLUISH_WHITE};
          .btn-secondary {
          background-color: transparent;
          border: 1px solid ${COLORS.SILVER};
          color: ${COLORS.TEXT_GREY} !important;
          }

          .btn-secondary:hover,
          .btn-secondary:active {
               border-color: ${COLORS.SILVER};
               background-color: ${COLORS.WHITE_SMOKE};
          }
          .btn-primary {
               background-color: ${COLORS.BLUISH};
               border-color: ${COLORS.BLUISH};
               color: ${COLORS.WHITE};
          }
          .btn-primary:hover,
          .btn-primary:active {
               color: ${COLORS.WHITE};
               background-color: ${COLORS.SAPPHIRE_BLUE};
               border-color: ${COLORS.EGYPTIAN_BLUE};
          }
     }

     input[type="checkbox"],
     input[type="radio"] {
          cursor: pointer;
     }
     
     /* Daterangepicker */
     .daterangepicker {
        
        .drp-buttons {
            
            .btn {
                margin-left: 8px !important;
                font-size: 11px;
                font-weight: bold;
                padding: 4px 8px !important;
                line-height: 14px;
                text-align: center;
                vertical-align: middle;
                min-height: 34px !important;
            }
            
        }
        
     }

     /* Tegfield styles */

     .tags {
          border-radius: 0.25rem;
          padding: 0.176rem;
          font-size: ${SIZE.NORMAL};
          width: inherit;
     }

     .tagify,
     .tagify--focus,
     .tagify__input {
          font-family: 'IBM Plex Sans', sans-serif !important;
          /* padding: 7px !important; */
          margin: 0 !important;
          /* height: calc(1.5em + 1.3rem + 2px) !important; */
          /* min-height: 41px !important; */

          overflow: visible;
          flex-wrap: wrap;

          display: flex;
          align-items: center;
          white-space: pre-wrap;

          &::placeholder {
               color: transparent !important;
          }

          &::before {
               color: ${COLORS.DARK_BLUE};
          }
          
          &:empty::before {
               transition: .2s ease-out;
               opacity: .5;
               -ms-transform: none;
               transform: none;
               width: auto;
          }
          
          &::after {
               display: flex;
               align-items: center;
               white-space: pre-wrap;
               height: 16px;
          }

          --tag-bg: rgba(14, 108, 247, 0.1);
          --tags-border-color: #ced4da;
          --tag-text-color: #0e6cf7;
          --tag-remove-bg: var(--tag-hover);
          --tag-hover: rgba(14, 108, 247, 0.1);
          --tag-remove-btn-bg: rgba(14, 108, 247, 0.1);
          --tag-remove-btn-bg--hover: rgba(14, 108, 247, 0.1);
          --input-color: ${COLORS.GREY};

          .tagify__tag__removeBtn {
               font: 14px/16px Serif;
               background: transparent;
               color: var(--tag-text-color);
               width: 14px;
               height: 14px;
               text-align: center;
               cursor: pointer;
               transition: 0.2s ease-out;

               &:after {
                    font-size: ${SIZE.LARGER};
               }
          }

          .tagify--notAllowed {
               --tag-text-color: ${COLORS.RED} !important;
               --tag-invalid-color: ${COLORS.RED} !important;
               --tag-invalid-bg: ${COLORS.LIGHT_RED} !important;
               --tag-remove-btn-color: ${COLORS.RED} !important;
          }

          .tagify__tag.tagify--notAllowed div > span {
               opacity: 1;
          }

          &.form-control {
               margin: 0 !important;
               padding: 0 !important;
          }
     }

     /* Navigational */
     .content--border {
          border-bottom: 1px solid ${COLORS.LIGHTER_BLUE};
          margin-bottom: 20px;
          position: relative;
     }

     a.inactive {
          font-weight: ${FONT.MEDIUM};
          font-size: ${SIZE.NORMAL};
          line-height: 18px;
          display: flex;
          align-items: center;
          text-align: center;
          color: ${COLORS.DARK_BLUE};
          margin-top: 15px;
          margin-bottom: 15px;
          text-decoration: none;
          background-color: transparent;
          border: 1px solid transparent;
          border-radius: 4px;
          padding: 12px 25px;
          cursor: pointer;
          &:hover,
          :active {
               background-color: #f2f3f8;
               border: 1px solid transparent;
               border-radius: 4px;
               padding: 12px 25px;
          }
     }

     /* Checkbox Grey */
     .checkbox-solid {
          cursor: pointer;
          position: relative;
          padding-left: 20px;
          margin-bottom: 20px;
          transition: all 0.15s ease;

          span {
               background-color: ${COLORS.LIGHTER_BLUE};
               border-radius: 2px;
               position: absolute;
               top: 1px;
               left: 0;
               height: 18px;
               width: 18px;

               outline: none !important;

               &:after {
                    display: none;
               }
          }
     }

     input[type='checkbox'] {
          position: absolute;
          transform: scale(1.5);
          top: 3px;
          left: 3px;
          z-index: 1;
          opacity: 0;
     }

     input[type='checkbox']:checked ~ span {

          &:after {
               content: '';
               position: absolute;
               display: none;
               top: 50%;
               left: 50%;
               margin-left: -2px;
               margin-top: -6px;
               width: 5px;
               height: 10px;
               border-width: 0 2px 2px 0 !important;
               transform: rotate(45deg);
               border: solid #99a6bf;
               display: block;
          }
     }
     
     .alert {
        width: 100%;
     }
     
     .alert-outline-danger {
        background-color: ${COLORS.LIGHT_RED};
        color: ${COLORS.RED};
        font-size: 14px;
        font-weight: 500;
     }
     
     .alert-outline-warning {
        background-color: ${COLORS.LIGHTER_YELLOW};
        color: ${COLORS.YELLOW};
        font-size: 14px;
        font-weight: 500;
     }
     
     .alert-outline-info {
        background-color: ${COLORS.LIGHT_GREEN};
        color: ${COLORS.GREEN};
        font-size: 14px;
        font-weight: 500;
     }
     
     .alert-outline-primary {
        background-color: ${COLORS.BRAND_HIGHLIGHT};
        color: ${COLORS.BLUE};
        font-size: 14px;
        font-weight: 500;
     }
     
     .zi1 {
        z-index: 1 !important;
     }
     
     .zi2 {
        z-index: 2 !important;
     }
     
     .zi3 {
        z-index: 3 !important;
     }
     
     .zi4 {
        z-index: 4 !important;
     }
     
     .zi5 {
        z-index: 5 !important;
     }
     
     .zi6 {
        z-index: 6 !important;
     }
     
     .zi7 {
        z-index: 7 !important;
     }
     
     .zi8 {
        z-index: 8 !important;
     }
     
     .zi9 {
        z-index: 9 !important;
     }
     
     .zi10 {
        z-index: 10 !important;
     }
     
     .language-buttons {

           .btn-outline-primary {
                border: 1px solid #e1e4ec;
                border-radius: 3px;
                color: ${COLORS.TEXT_GREY};
                padding: 9px 20px;

                &:hover {
                     color: ${COLORS.WHITE};
                     border: 1px solid ${COLORS.BLUE};
                     background-color: ${COLORS.BLUE};
                }
                &:not(:first-child) {
                     margin-left: 7px;
                }
           }

           .btn-primary {
                padding: 9px 20px;

                &:not(:first-child) {
                     margin-left: 7px;
                }
           }
           .btn-warning {
                background-color: ${COLORS.YELLOW};
                border: 1px solid ${COLORS.YELLOW};
                border-radius: 3px;
                color: ${COLORS.WHITE};
                padding: 9px 20px;
                &:hover {
                     color: ${COLORS.WHITE};
                     border: 1px solid ${COLORS.DARKER_YELLOW};
                     background-color: ${COLORS.DARKER_YELLOW};
                }
                &:not(:first-child) {
                     margin-left: 7px;
                }
           }

           .btn-outline-warning {
                background-color: ${COLORS.LIGHTER_YELLOW};
                border: 1px solid ${COLORS.LIGHTER_YELLOW};
                border-radius: 3px;
                color: ${COLORS.YELLOW};
                padding: 9px 20px;
                &:hover {
                     color: ${COLORS.WHITE};
                     border: 1px solid ${COLORS.YELLOW};
                     background-color: ${COLORS.YELLOW};
                }
                &:not(:first-child) {
                     margin-left: 7px;
                }
           }
      }
     
     .picker {
        .input-group:focus-within .input-group-prepend .input-group-text,
        .form-control:focus ~ .input-group-append .input-group-text {
          border-color: ${COLORS.SKY_BLUE};
        }
     }
     
     .language-buttons {

           .btn-outline-primary {
                border: 1px solid #e1e4ec;
                border-radius: 3px;
                color: ${COLORS.TEXT_GREY};
                padding: 9px 20px;

                &:hover {
                     color: ${COLORS.WHITE};
                     border: 1px solid ${COLORS.BLUE};
                     background-color: ${COLORS.BLUE};
                }
                &:not(:first-child) {
                     margin-left: 7px;
                }
           }

           .btn-primary {
                padding: 9px 20px;

                &:not(:first-child) {
                     margin-left: 7px;
                }
           }
           .btn-warning {
                background-color: ${COLORS.YELLOW};
                border: 1px solid ${COLORS.YELLOW};
                border-radius: 3px;
                color: ${COLORS.WHITE};
                padding: 9px 20px;
                &:hover {
                     color: ${COLORS.WHITE};
                     border: 1px solid ${COLORS.DARKER_YELLOW};
                     background-color: ${COLORS.DARKER_YELLOW};
                }
                &:not(:first-child) {
                     margin-left: 7px;
                }
           }

           .btn-outline-warning {
                background-color: ${COLORS.LIGHTER_YELLOW};
                border: 1px solid ${COLORS.LIGHTER_YELLOW};
                border-radius: 3px;
                color: ${COLORS.YELLOW};
                padding: 9px 20px;
                &:hover {
                     color: ${COLORS.WHITE};
                     border: 1px solid ${COLORS.YELLOW};
                     background-color: ${COLORS.YELLOW};
                }
                &:not(:first-child) {
                     margin-left: 7px;
                }
           }
      }
     
     .tooltip {
          position: absolute;
          z-index: 1070;
          display: block;
          margin-top: 5px;
          margin: 0;
          font-family: IBM Plex Sans, Helvetica, sans-serif;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5;
          text-align: left;
          text-align: start;
          text-decoration: none;
          text-shadow: none;
          text-transform: none;
          letter-spacing: normal;
          word-break: normal;
          word-spacing: normal;
          white-space: normal;
          line-break: auto;
          font-size: 0.9rem;
          word-wrap: break-word;
          opacity: 0; }
          .tooltip.show {
            opacity: 1; }
          .tooltip .arrow {
            position: absolute;
            display: block;
            width: 0.8rem;
            height: 0.4rem; }
            .tooltip .arrow::before {
              position: absolute;
              content: "";
              border-color: transparent;
              border-style: solid; }
        
        .bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
          padding: 0.4rem 0; }
          .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
            bottom: 0; }
            .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
              top: 0;
              border-width: 0.4rem 0.4rem 0;
              border-top-color: #ffffff; }
        
        .bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
          padding: 0 0.4rem; }
          .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
            left: 0;
            width: 0.4rem;
            height: 0.8rem; }
            .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
              right: 0;
              border-width: 0.4rem 0.4rem 0.4rem 0;
              border-right-color: #ffffff; }
        
        .bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
          padding: 0.4rem 0; }
          .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
            top: 0; }
            .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
              bottom: 0;
              border-width: 0 0.4rem 0.4rem;
              border-bottom-color: #ffffff; }
              
        .bs-tooltip-bottom-start, .bs-tooltip-auto[x-placement^="bottom"] {
          padding: 0.4rem 0; }
          .bs-tooltip-bottom-start .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
            top: 0; }
            .bs-tooltip-bottom-start .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
              bottom: 0;
              border-width: 0 0.4rem 0.4rem;
              border-bottom-color: #ffffff; }
        
        .bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
          padding: 0 0.4rem; }
          .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
            right: 0;
            width: 0.4rem;
            height: 0.8rem; }
            .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
              left: 0;
              border-width: 0.4rem 0 0.4rem 0.4rem;
              border-left-color: #ffffff; }
        
        .tooltip-inner {
          max-width: 200px;
          padding: 0.75rem 1rem;
          color: #001737;
          text-align: center;
          background-color: #ffffff;
          border-radius: 3px;
          box-shadow: 0 0 16px #cccccc;
          font-size: 12px;
          color: ${COLORS.DARK_BLUE};
          font-family: 
          }
          
     .attached-files {
        width: 100%;
        margin-top: 5px;
     
        .item {
            border-radius: 4px;
            color: ${COLORS.DARK_BLUE};
            font-size: 0.9rem;
            margin-top: 5px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 15px;
            background-color: ${COLORS.LIGHT_GREY};
            
            .file {
                word-break: break-all;
            
                a {
                    color: ${COLORS.DARK_BLUE};
                    text-decoration: none;
                }
                
                a:hover {
                    text-decoration: underline;
                }
            }
            
            .file-toolbar {
                text-align: center;
                
                button {
                    font-size: 1.5rem;
                    font-family: serif;
                    font-weight: 400;
                    color: ${COLORS.DARK_BLUE};
                    margin: 0;
                    padding: 0;
                    border: 0;
                    margin-top: -7px;
                }
                
                button:hover {
                    text-decoration: none;
                    color: ${COLORS.BLUE};
                }
            }
        }
     }
     
     .unverified {
        color: ${COLORS.YELLOW};
     }
     
     .invalid-feedback {
        p {
            margin: 0;
        }
     }
     
     .input-group {
          background-color: transparent;
           background-color: transparent;
           z-index: 1;
          
          input:disabled {
            background-color: ${COLORS.LIGHT_GREY};
          }

          input,
          a {
               border-radius: 4px !important;
               z-index: 1;
          }

          background-color: transparent;


          input,
          .dropdown-item {
               border-radius: 4px !important;
               background-color: transparent;
               z-index: 3;
               
               &:hover {
                    color: ${COLORS.DARK_BLUE};
                    background-color: ${COLORS.PALE_BLUE};
               }
          }
          
          .suffix {
               background-color: transparent;
               border-radius: 4px !important;
               display: flex;
               align-items: center;
               position: absolute;
               top: 1px;
               right: 15px;
               height: 39px;
               z-index: 2;

               svg {
                    color: ${COLORS.PLACEHOLDER_GREY};
               }
          }
     }     

     @media only screen and (max-width: 1320px) and (min-width: 1023px) {
          .container-standard {
               padding: 0 30px;
          }
     }

     @media only screen and (max-width: 1023px) {
          .app-grid {
               display: flex;
               flex-direction: column;
          }

          .container-standard {
               padding: 0 15px;
          }
          
          .content--border,
          .not-sr {
               display: none;
          }

          .heading {
               font-size: 14px !important;
               padding: 15px !important;
          }

          .form-label {
               font-size: ${SIZE.TINY};
          }

          input,
          input::placeholder,
          .tagify__input,
          .tagify__tag-text,
          .tagify__dropdown__item {
               font-size: ${SIZE.TINY};
          }

          p {
               font-size: ${SIZE.TINY} !important;
          }

          .info-block {
               font-size: ${SIZE.MICRO} !important;
          }
     }
`;

export default GlobalStyles;
