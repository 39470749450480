import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Alert, Button, Row, Col, Form, Dropdown } from 'react-bootstrap';
import ChevronDownIcon from '../../../../assets/icons/metronic/chevron-down-icon';
import { ORGANIZATION_SETTINGS } from '../../../../constants/form.constants';
import PictureUploader from '../../../common/picture-uploader';
import CustomToggle from '../../../common/custom-toggle';
import Input from '../../../common/input';
import {
     requestOrganizationDetailsUpdate,
     requestOrganizationPhotoUpload,
     requestRemoveOrganizationAvatar,
} from '../../../../redux/reducers/organizationReducer';
import {requestUserAvatarGenerateTemp} from "../../../../redux/reducers/userReducer";
import {dataURLtoFile} from "../../../../utils/data.utils";
import {MIME_PHOTOS} from "../../../../utils/mime.utils";
import {AVATAR_FILE_LIMIT} from "../../../../utils/file.utils";

const mapStateToProps = (state) => ({
     router: state.router,
     refreshOrganizationAvatar: state.organizationReducer.refreshOrganizationAvatar,
     organizationDetailsUpdateRequested: state.organizationReducer.organizationDetailsUpdateRequested,
     organizationDetailsSucceeded: state.organizationReducer.organizationDetailsSucceeded,
     organizationDetailsFailed: state.organizationReducer.organizationDetailsFailed,
     uploadStarted: state.organizationReducer.organizationPhotoUploadRequested,
     uploadSucceeded: state.organizationReducer.organizationPhotoUploadSucceeded,
     removePhotoStarted: state.organizationReducer.removeOrganizationAvatarRequested,
     removePhotoSucceeded: state.organizationReducer.removeOrganizationAvatarSucceeded,
     languages: state.userReducer.languages,
     userGeneratedAvatar: state.userReducer.userGeneratedAvatar,
     userGeneratedAvatarSucceeded: state.userReducer.userAvatarGenerateTempSucceeded
});

const mapDispatchToProps = (dispatch) => ({
     requestOrganizationPhotoUpload: (values) => dispatch(requestOrganizationPhotoUpload(values)),
     requestOrganizationDetailsUpdate: (values) => dispatch(requestOrganizationDetailsUpdate(values)),
     requestRemoveOrganizationAvatar: (payload) => dispatch(requestRemoveOrganizationAvatar(payload)),
     requestUserAvatarGenerate: (values) => dispatch(requestUserAvatarGenerateTemp(values)),
});

export const _OrganizationSettingsForm = ({
     organization,
     requestOrganizationPhotoUpload,
     requestOrganizationDetailsUpdate,
     organizationDetailsUpdateRequested,
     organizationDetailsSucceeded,
     organizationDetailsFailed,
     uploadSucceeded,
     removePhotoSucceeded,
     languages,
     userGeneratedAvatar,
     userGeneratedAvatarSucceeded,
     requestUserAvatarGenerate
}) => {
     const { t } = useTranslation();
     const { id: organizationId } = useParams();
     /* eslint-disable-next-line */
     const [organizationAvatar, setOrganizationAvatar] = useState(null);
     // /* eslint-disable-next-line */
     const [fullPreview, setFullPreview] = useState(null);
     const [defaultAvatar, setDefaultAvatar] = useState(null);
     const [previewAvatar, setPreviewAvatar] = useState(null);
     const [fileForUpload, setFileForUpload] = useState(null);
     const [isLoading, setIsLoading] = useState(false);
     const [innerState, setInnerState] = useState({
          languageOptions: [],
          currentLanguage: {
               id: null,
               name: null,
               code: null
          }
     });

     const currentLanguage = () => {
          return languages.find(lang => lang.id === organization.language.id);
     };

     useEffect(() => {
          if (languages.length > 0) {
               setInnerState({
                    languageOptions: languages,
                    currentLanguage: currentLanguage()
               });
          }
     }, [organization]);

     const fetching = useMemo(() => {
          return (
               organizationDetailsUpdateRequested &&
               (!organizationDetailsSucceeded || !organizationDetailsFailed)
          );
     }, [
          organizationDetailsUpdateRequested,
          organizationDetailsSucceeded,
          organizationDetailsFailed,
     ]);

     useEffect(() => {
          const photo = organization.photos['240x240'];
          setOrganizationAvatar(photo);
          setDefaultAvatar(photo);
          setFullPreview(organization.is_avatar_default ? null : organization.photos);
     }, [organization]);

     useEffect(() => {
          if (uploadSucceeded) {
               const photo = organization.photos['240x240'];
               setOrganizationAvatar(photo);
               setDefaultAvatar(photo);
               setFullPreview(organization.is_avatar_default ? null : organization.photos);
               setIsLoading(false);
          }
     }, [uploadSucceeded]);

     useEffect(() => {
          if (removePhotoSucceeded) {
               const photo = organization.photos['240x240'];
               setOrganizationAvatar(photo);
               setDefaultAvatar(photo);
               setFullPreview(organization.is_avatar_default ? null : organization.photos);
               setIsLoading(false);
          }
     }, [removePhotoSucceeded]);

     useEffect(() => {
          if (userGeneratedAvatarSucceeded && userGeneratedAvatar) {
               setOrganizationAvatar(userGeneratedAvatar);
               setDefaultAvatar(userGeneratedAvatar);
               setFullPreview(null);
               setIsLoading(false);

               const file = dataURLtoFile(userGeneratedAvatar, 'generated.png');

               setFileForUpload({
                    file,
                    crop: {
                         width: 1000,
                         height: 1000,
                         x: 0,
                         y: 0,
                         is_generated: true
                    }
               });
          }
     }, [userGeneratedAvatarSucceeded]);

     const getFile = (file, crop) => {
          setPreviewAvatar(crop.canvas);
          setFileForUpload({
               file,
               crop: {
                    width: crop.width,
                    height: crop.height,
                    x: crop.x,
                    y: crop.y
               }
          });
     };
     const getDefault = () => defaultAvatar;

     const handleOrganizationsSettingsFormSubmit = (values) => {
          const organizationId = organization.id;

          if (fileForUpload) {
               requestOrganizationPhotoUpload({
                    organizationId,
                    organizationPhoto: fileForUpload.file,
                    crop: fileForUpload.crop
               });
               setIsLoading(true);
               setFileForUpload(null);
               setPreviewAvatar(null);
          }

          requestOrganizationDetailsUpdate({
               organizationId,
               name: values.organizationName,
               language_id: innerState.currentLanguage.id,
               photo_refresh: !fileForUpload,
          });
     };

     const removeOrganizationPhoto = () => {
          setIsLoading(true);
          setDefaultAvatar(null);
          setOrganizationAvatar(null);
          setFullPreview(null);

          requestUserAvatarGenerate({name: organization.name});
     };

     const removeAvatarPreview = () => {
          setPreviewAvatar(null);
          setFileForUpload(null);
     };

     return (
          !organization ? <></> : (
               <Formik
                    validationSchema={ORGANIZATION_SETTINGS.VALIDATION}
                    onSubmit={handleOrganizationsSettingsFormSubmit}
                    initialValues={{
                         organizationName: organization.name,
                         language_id: innerState.currentLanguage,
                    }}
                    validateOnBlur={false}
               >
                    {({
                         handleSubmit,
                         handleChange,
                         values,
                         touched,
                         errors,
                    }) => (
                         <Form noValidate onSubmit={handleSubmit}>
                              {organization.status === 'suspended' && (
                                   <Alert
                                        key={'organization-suspended'}
                                        variant={'outline-danger'}
                                   >
                                        {t('organization_settings.your_organization_have_been_suspended')}
                                   </Alert>
                              )}
                              <Form.Group as={Row} controlId="organizationName">
                                   <Col sm={3}>
                                        <Form.Label>
                                             {t(
                                                  'organization_settings.organization_name'
                                             )}
                                        </Form.Label>
                                   </Col>
                                   <Col sm={9}>
                                        <Input
                                             type="text"
                                             field="organizationName"
                                             values={values}
                                             handleChange={handleChange}
                                             touched={touched}
                                             errors={errors}
                                             disabled={fetching}
                                        />
                                   </Col>
                              </Form.Group>
                              <Form.Group
                                   as={Row}
                                   controlId="language"
                                   className="language"
                              >
                                   <Col sm={3}>
                                        <Form.Label>
                                             {t(
                                                  'organization_settings.default_language'
                                             )}
                                        </Form.Label>
                                   </Col>
                                   <Col sm={9}>
                                        <div className="input-group">
                                             <Dropdown
                                                  style={{ width: '100%' }}
                                             >
                                                  <Dropdown.Toggle
                                                       className="select-dropdown"
                                                       as={CustomToggle}
                                                  >
                                                       {
                                                            innerState.currentLanguage.name
                                                       }
                                                  </Dropdown.Toggle>
                                                  <Dropdown.Menu
                                                       style={{ width: '100%' }}
                                                  >
                                                       {innerState.languageOptions.map(
                                                            (lang) => (
                                                                 <Dropdown.Item
                                                                      key={
                                                                           lang.id
                                                                      }
                                                                      onClick={() =>
                                                                           setInnerState(
                                                                                {
                                                                                     ...innerState,
                                                                                     currentLanguage: lang,
                                                                                }
                                                                           )
                                                                      }
                                                                 >
                                                                      {lang.name}
                                                                 </Dropdown.Item>
                                                            )
                                                       )}
                                                  </Dropdown.Menu>
                                             </Dropdown>
                                             <span className="suffix">
                                                  <ChevronDownIcon />
                                             </span>
                                        </div>
                                   </Col>
                              </Form.Group>
                              <section className="avatar-section">
                                   <Form.Group
                                        as={Row}
                                        controlId="photo"
                                        className="file_upload"
                                   >
                                        <Col sm={3}>
                                             <Form.Label>
                                                  {t(
                                                       'organization_settings.organization_logo'
                                                  )}
                                             </Form.Label>
                                        </Col>
                                        <Col sm={9}>
                                             <PictureUploader
                                                 preview={previewAvatar}
                                                 picture={organizationAvatar}
                                                 getFile={getFile}
                                                 getDefault={getDefault}
                                                 isLoading={isLoading}
                                                 isDefault={organization.is_photo_default}
                                                 handleRemovePicture={removeOrganizationPhoto}
                                                 handleRemovePreview={removeAvatarPreview}
                                                 accept={MIME_PHOTOS.join(',')}
                                                 fileLimit={AVATAR_FILE_LIMIT}
                                                 fullPreview={fullPreview}
                                             />
                                        </Col>
                                   </Form.Group>
                              </section>
                              <hr className="body-divider" />
                              <div className="form-actions">
                                   <Button
                                        disabled={fetching}
                                        variant="success"
                                        size="lg"
                                        type="submit"
                                   >
                                        {t('buttons.save')}
                                   </Button>
                              </div>
                         </Form>
                    )}
               </Formik>
          )
     );
};

export const OrganizationSettingsForm = connect(
     mapStateToProps,
     mapDispatchToProps
)(_OrganizationSettingsForm);
