import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import OrganizationBlock from '../../common/organization-block';
import { LoginBlockForm } from '../forms/LoginBlockForm';
import { InfoWrapper } from '../auth-styles/RightBlock.style';
import { maskEmail } from '../../../utils/string.utils';

import { Event1, User2 } from '../../../mocks';

export const LinkedForm = memo(() => {
     const { t } = useTranslation();

     return (
          <>
               <InfoWrapper>
                    <OrganizationBlock
                         picture={Event1.eventLogo}
                         pictureAltText={t('linked_form.logo')}
                         title={Event1.name}
                         subtitle={Event1.date}
                    >
                         <p>
                              {t('linked_form.linked_with')}{' '}
                              <b>{maskEmail(User2.email)}</b>{' '}
                              {t('linked_form.account')}
                         </p>
                    </OrganizationBlock>
                    <div className="link-block">
                         <LoginBlockForm />
                    </div>
               </InfoWrapper>
          </>
     );
});
