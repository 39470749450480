import React, {
     memo,
     lazy,
     useEffect,
     useState,
     useCallback,
     useRef,
} from 'react';
import { connect } from 'react-redux';
import { push, replace } from 'connected-react-router';
import {
     Link,
     NavLink,
     Switch,
     Route,
     useParams,
     useLocation,
} from 'react-router-dom';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { Tab, Button, Nav, Card } from 'react-bootstrap';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Helmet } from 'react-helmet';
import toastr from 'toastr';
import {
     string as yupString,
     object as yupObject,
     mixed as yupMixed,
} from 'yup';

import Breadcrumb from '../../common/breadcrumb';
import Portlet from '../../common/portlet';
import LoadingBar from '../../common/loading-bar';
import { AddElementStep } from './SideBlock/AddElementStep';
import { BuilderDropZone } from './BuilderDropZone';
import { FormSettings } from './SideBlock/FormSettings';
import { ElementSettings } from './SideBlock/ElementSettings';
import SweetAlert from '../../common/sweet-alert';
import { ListItem } from '../../common/collapsible-list/CollapsibleList';
import { EventDropdown } from '../Event/sub-components/EventDropdown';
import { SettingsImage } from '../../../assets/icons/metronic/settings-image';
import SpeakersIcon from '../../../assets/icons/metronic/speakers-icon';
import { GroupChatImage } from '../../../assets/icons/metronic/group-chat-image';
import { DotImage } from '../../../assets/icons/metronic/dot-image';
import { requestOrganizationDetails } from '../../../redux/reducers/organizationReducer';
import { requestEventDetails } from '../../../redux/reducers/eventReducer';
import {requestFieldsSpeakerView, requestSpeakerDetails} from '../../../redux/reducers/speakerReducer';
import {
     requestCreateForm,
     requestUpdateForm,
     requestFormDetails,
     requestFormBasicDetails,
     requestSaveCustomForm,
     cleanCreatedSavedForm
} from '../../../redux/reducers/formBuilderReducer';
import { OrganizationDropdown } from '../Organization/sub-components/OrganizationDropdown';
import update from 'immutability-helper';
import { DefaultFields } from './DefaultFields';
import { RandomNumber } from '../../../utils/random.utils';
import { DragLayer } from './Elements/DragLayer';
import _ from "underscore";

import FormBuilderWrapper from './FormBuilder.style';
import {
     MasterLanguageValue,
     UpdateChildLanguages,
     ValidateLanguageFields
} from "./Elements/ChildLanguages";
import {ElementTypes} from "./ElementTypes";
import {ReminderIcon} from "../../../assets/icons/metronic/reminders-icon";

const MainDrawer = lazy(() => import('../../common/drawer'));
const CollapsibleList = lazy(() => import('../../common/collapsible-list'));

const mapStateToProps = (state) => ({
     router: state.router,
     organization: state.organizationReducer.organization,
     eventData: state.eventReducer.eventData,
     formDetails: state.formBuilderReducer.formDetails,
     formBasicDetails: state.formBuilderReducer.formBasicDetails,
     formDetailsSucceeded: state.formBuilderReducer.formDetailsSucceeded,
     formBasicDetailsSucceeded: state.formBuilderReducer.formBasicDetailsSucceeded,
     savedForm: state.formBuilderReducer.savedForm,
     updateFormSucceeded: state.formBuilderReducer.updateFormSucceeded,
     saveCustomFormSucceeded: state.formBuilderReducer.saveCustomFormSucceeded,
     speakerDetailsData: state.speakerReducer.speakerDetailsData,
     message: state.formBuilderReducer.message,
     languages: state.userReducer.languages,
     updateFormRequested: state.formBuilderReducer.updateFormRequested,
     saveCustomFormRequested: state.formBuilderReducer.saveCustomFormRequested,
     createFormRequested: state.formBuilderReducer.createFormRequested,
     fieldsList: state.speakerReducer.fieldsList
});

const mapDispatchToProps = (dispatch) => ({
     redirectToFirstStep: (location) => dispatch(replace(location)),
     redirectToLastStep: (location) => dispatch(push(location, { state: 'afterValidation' })),
     redirectToSpeakersInvite: (location) => dispatch(push(location)),
     redirectToLocation: (location) => dispatch(push(location)),
     requestOrganizationDetails: (id) => dispatch(requestOrganizationDetails(id)),
     requestEventDetails: (payload) => dispatch(requestEventDetails(payload)),
     requestCreateForm: (payload) => dispatch(requestCreateForm(payload)),
     requestUpdateForm: (payload) => dispatch(requestUpdateForm(payload)),
     requestSaveCustomForm: (payload) => dispatch(requestSaveCustomForm(payload)),
     requestFormDetails: (payload) => dispatch(requestFormDetails(payload)),
     requestFormBasicDetails: (payload) => dispatch(requestFormBasicDetails(payload)),
     requestSpeakerDetails: (payload) => dispatch(requestSpeakerDetails(payload)),
     cleanCreatedSavedForm: () => dispatch(cleanCreatedSavedForm()),
     requestFieldsSpeakerView: (eventId) => dispatch(requestFieldsSpeakerView(eventId)),
});

export const _FormBuilder = memo((props) => {
     const { id: organizationId, eventId } = useParams();
     const { search } = useLocation();
     const { t } = useTranslation();

     const {
          router: {
               location: { pathname },
          },
          redirectToFirstStep,
          redirectToLastStep,
          redirectToSpeakersInvite,
          redirectToLocation,
          requestCreateForm,
          requestUpdateForm,
          requestOrganizationDetails,
          formDetailsSucceeded,
          updateFormSucceeded,
          requestSaveCustomForm,
          saveCustomFormSucceeded,
          requestEventDetails,
          requestFormDetails,
          requestFormBasicDetails,
          savedForm,
          organization,
          eventData,
          formDetails,
          formBasicDetails,
          speakerDetailsData,
          requestSpeakerDetails,
          message: successMessage,
          languages,
          cleanCreatedSavedForm,
          updateFormRequested,
          saveCustomFormRequested,
          createFormRequested,
          requestFieldsSpeakerView,
          fieldsList

     } = props;

     const [form, setForm] = useState({
          formName: '',
          accessLevel: '',
     });

     const [builderId, setBuilderId] = useState();
     const [speakerId, setSpeakerId] = useState();
     const [elements, setElements] = useState([]);
     const [selectedElement, setSelectedElement] = useState(null);
     const [currentLanguage, setCurrentLanguage] = useState();
     const [mandatoryLanguage, setMandatoryLanguage] = useState(null);
     const [multiLanguage, setMultilanguage] = useState(false);
     const [multiLanguageSelected, setMultiLanguageSelected] = useState([]);
     const [message, setMessage] = useState('');
     const [confirmationMessage, setConfirmationMessage] = useState([]);
     const [speakerData, setSpeakerData] = useState([]);
     const [formLoaded, setFormLoaded] = useState(false);

     const ref = useRef(null);
     const elementSettingsRef = useRef(null);
     const elementSettingsLinkRef = useRef(null);
     const formSettingsElementRef = useRef(null);
     const formSettingsElementLinkRef = useRef(null);

     useEffect(() => {

          return function cleanup() {
               setBuilderId(null);
               setSpeakerId(null);
               setElements([]);
               setSelectedElement(null);
               setCurrentLanguage(null);
               setMandatoryLanguage(null);
               setMultilanguage(false);
               setMultiLanguageSelected([]);
               setMessage('');
               setConfirmationMessage([]);
               setSpeakerData([]);
               cleanCreatedSavedForm();
          };
     }, []);

     useEffect(() => {
          if (formLoaded && !formSettingsElementRef.current && !(updateFormRequested || saveCustomFormRequested || createFormRequested)) {

               if (selectedElement) {
                    redirectToLocation(constructLink('elementSettings'));
               } else if (mandatoryLanguage && !isMandatoryLanguageActive()) {
                    redirectToLocation(constructLink('formSettings'));
               } else {
                    redirectToLocation(constructLink('addElement'));
               }
          }
     }, [selectedElement]);

     useEffect(() => {
          if (savedForm && savedForm.id) {
               setBuilderId(savedForm.id);
          }
     }, [savedForm]);

     useEffect(() => {
          if (!formDetails && !builderId && !speakerId && mandatoryLanguage) {
               if (elements.length === 0 && languages.length > 0) {
                    let defElements = [];
                    let internalIds = [];
                    languages.forEach((lang) => {
                         DefaultFields.forEach((field, index) => {
                              if (!internalIds[index]) {
                                   internalIds[index] = RandomNumber(
                                       10000000,
                                       99999999
                                   );
                              }

                              let newField = Object.assign({}, field);
                              newField.id = RandomNumber(
                                   100000000000,
                                   999999999999
                              );
                              newField.internal_id = internalIds[index];
                              newField.index = index;
                              newField.lang = lang.id;
                              newField.is_master = lang.id === mandatoryLanguage;
                              newField.is_valid = true;
                              newField.is_valid_code = true;
                              newField.is_translated = true;
                              newField.elementCode = i18n.t(field.elementCode, { lng: 'en' });
                              newField.label = i18n.t(field.label, { lng: lang.code });
                              defElements = defElements.concat([newField]);
                         });
                    });

                    let defConfirmationMessages =[];
                    languages.forEach((lang) => {
                         defConfirmationMessages.push({
                              language_id: lang.id,
                              message: '',
                              is_valid: false
                         });
                    });

                    setElements(defElements);
                    setConfirmationMessage(defConfirmationMessages);
                    setFormLoaded(true);
               }
          }
     });

     useEffect(() => {
          if (formBasicDetails && !builderId && speakerId && speakerDetailsData) {
               if (formBasicDetails.id === speakerDetailsData.form_id) {
                    let incommingElements = speakerDetailsData.form
                        .map((field) => [
                             {
                                  ...field,
                                  id: field.id,
                                  elementCode: field.code,
                                  elementType: field.option,
                                  placeholder: field.placeholder,
                                  multipleSelections: field.multiple_choices,
                                  layout: field.layout,
                                  timeFormat: field.format,
                                  lang: field.language_id,
                                  type: field.type,
                                  value: field.value,
                                  additionalInformation:
                                  field.additional_information,
                                  index: field.order,
                                  is_master: true,
                                  is_default: ['first_name', 'last_name', 'email'].includes(field.option)
                             },
                        ])
                        .flat();

                    ValidateLanguageFields(incommingElements, currentLanguage);

                    let incomingConfirmationMessages = [speakerDetailsData.confirmation_message];
                    incomingConfirmationMessages.map((message) => {
                         return validateConfirmationMessage(message);
                    });

                    setForm({
                         formName: speakerId ? i18n.t('form_builder.custom_form') : formBasicDetails.name,
                         accessLevel: formBasicDetails.access_type
                    });
                    setElements(incommingElements);
                    setConfirmationMessage(incomingConfirmationMessages);
                    setFormLoaded(true);
               }
          }
     }, [formBasicDetails]);

     useEffect(() => {
          if (formDetailsSucceeded && !formLoaded && formDetails && builderId && !speakerId) {
               if (formDetails.id === builderId) {
                        let incommingElements = formDetails.fields
                        .map((field) => [
                             {
                                  ...field,
                                  id: field.id,
                                  elementCode: field.code,
                                  elementType: field.option,
                                  placeholder: field.placeholder,
                                  multipleSelections: field.multiple_choices,
                                  layout: field.layout,
                                  timeFormat: field.format,
                                  lang: field.language_id,
                                  type: field.type,
                                  value: field.value,
                                  additionalInformation:
                                  field.additional_information,
                                  index: field.order,
                                  is_master: !formDetails.multilingual ? true : field.language_id === formDetails.language_id,
                                  is_default: ['first_name', 'last_name', 'email'].includes(field.option)
                             },
                        ])
                        .flat();

                    languages.forEach((lang) => {
                         const filtered = incommingElements.filter(
                             (el) => el.lang === lang.id
                         );
                         if (filtered.length === 0) {
                              const tmp = incommingElements
                                  .map((field) => {
                                       // create a copy of existing element
                                       return {
                                            ...field,
                                            id: RandomNumber(100000000000, 999999999999),
                                            elementCode: field.code,
                                            elementType: field.option,
                                            placeholder: '',
                                            multipleSelections: field.multiple_choices,
                                            layout: field.layout,
                                            timeFormat: field.format,
                                            language_id: lang.id,
                                            lang: lang.id,
                                            type: field.type,
                                            value: '',
                                            text: '',
                                            label: '',
                                            additionalInformation: '',
                                            index: field.order,
                                            is_master: false,
                                            is_default: ['first_name', 'last_name', 'email'].includes(field.option)
                                       };
                                  });
                              incommingElements = incommingElements.concat(tmp);
                         }
                    });

                    languages.forEach((lang) => {
                         ValidateLanguageFields(incommingElements, lang.id);
                    });

                    let incomingConfirmationMessages = formDetails.confirmation_message;
                    incomingConfirmationMessages.map((message) => {
                         return validateConfirmationMessage(message);
                    });

                    languages.forEach((lang) => {
                         const filtered = incomingConfirmationMessages.filter(
                             (el) => el.language_id === lang.id
                         );

                         if (filtered.length === 0) {
                              incomingConfirmationMessages.push({
                                   language_id: lang.id,
                                   message: '',
                                   is_valid: false
                              });
                         }
                    });

                    setForm({
                         formName: formDetails.name,
                         accessLevel: formDetails.access_type
                    });
                    setMandatoryLanguage(formDetails.language_id);
                    setCurrentLanguage(formDetails.language_id);
                    setMultiLanguageSelected(formDetails.language);
                    setMultilanguage(formDetails.multilingual);
                    setElements(incommingElements);
                    setConfirmationMessage(incomingConfirmationMessages);
                    setFormLoaded(true);
               }
          }
     }, [formDetailsSucceeded, builderId]);

     useEffect(() => {
          setMessage(successMessage);
     }, [successMessage]);

     useEffect(() => {
          if (saveCustomFormSucceeded && speakerId) {
               requestSpeakerDetails({ eventId, id: speakerId });
          }
     }, [saveCustomFormSucceeded]);

     useEffect(() => {
          if (search) {
               const params = new URLSearchParams(search);
               const id = parseInt(params.get('builder-id'), 10);
               const sId = parseInt(params.get('speaker-id'), 10);
               sId && setSpeakerId(sId);

               if (id && id !== 'NaN') {
                    setBuilderId(id);
                    requestFormDetails({
                         organizationId,
                         eventId,
                         id,
                    });
               }
          }
     }, [search, updateFormSucceeded, updateFormRequested]);

     useEffect(() => {
          if (speakerId && speakerId !== 'NaN') {
               requestSpeakerDetails({ eventId, id: speakerId });
          }
     }, [speakerId]);

     useEffect(() => {
          if (speakerDetailsData && currentLanguage) {
               const speakerLanguageId = speakerDetailsData.language_id;
               setCurrentLanguage(speakerLanguageId);
               setMandatoryLanguage(speakerLanguageId);
               setForm({ ...form, formName: speakerId ? i18n.t('form_builder.custom_form') : "" });
               setSpeakerData(speakerDetailsData.data);

               const speakerFormId = speakerDetailsData.form_id;
               const speakerEventId = speakerDetailsData.event_id;

               requestFormBasicDetails({
                    organizationId: organizationId,
                    eventId: speakerEventId,
                    id: speakerFormId,
               });
          }
     }, [speakerDetailsData]);

     useEffect(() => {
          requestOrganizationDetails(organizationId);
          requestEventDetails({ organizationId, eventId });
          setMessage(null);
          requestFieldsSpeakerView({organizationId, eventId});
     }, [pathname]);

     useEffect(() => {
          if (organization) {
               if (!mandatoryLanguage) {
                    setMandatoryLanguage(organization.language.id);
               }
               if (!currentLanguage) {
                    setCurrentLanguage(organization.language.id);
               }
               if (multiLanguageSelected.length === 0) {
                    setMultiLanguageSelected([organization.language.id]);
               }
          }
     }, [organization]);

     useEffect(() => {
          validateElements();
     }, [multiLanguage, multiLanguageSelected, currentLanguage, mandatoryLanguage, elements]);

     useEffect(() => {
          if (!multiLanguage) {
               setMultiLanguageSelected([currentLanguage]);
          }

          const allElements = [...elements];
          allElements.map((el) => {
               el.is_master = mandatoryLanguage === el.lang;

               return el;
          });

          setElements(allElements);

          if (selectedElement) {
               const element = elements.find(
                   (el) => (el.id === selectedElement.id || (el.elementCode && el.elementCode === selectedElement.elementCode) || (el.internal_id && el.internal_id === selectedElement.internal_id)) && el.lang === currentLanguage
               );
               setSelectedElement(element);
          }

     }, [currentLanguage]);

     const getMandatoryLanguage = () => {
          if (mandatoryLanguage && languages) {
               return languages.find((lang) => lang.id === mandatoryLanguage);
          }

          return null;
     };

     const validateElements = async () => {
          await multiLanguageSelected.forEach((langId) => {
               ValidateLanguageFields(elements, langId);
          });

          if (selectedElement) {
               const el = elements.find(
                   ({ id: _id, lang }) => _id === selectedElement.id && lang === currentLanguage
               );
               el && setSelectedElement(el);
          }
     };

     const handleDrawElements = (item, index) => {
          setMessage('');

          if (index !== undefined) {
               const exists = elements.filter(({ id }) => {
                    return id === item.id;
               });
               if (exists.length === 0 && item !== undefined) {
                    const updatedElementsList = [...elements];

                    const internalId = RandomNumber(
                        10000000,
                        99999999
                    );
                    languages.forEach((lang) => {
                         updatedElementsList.push({
                              ...item,
                              index: index,
                              lang: lang.id,
                              is_master: lang.id === mandatoryLanguage,
                              internal_id: internalId,
                              is_valid: item.type === ElementTypes.DIVIDER,
                              is_translated: item.type === ElementTypes.DIVIDER
                         });
                    });

                    const reorderingElements = [];
                    languages.forEach((lang) => {
                         if (!speakerId || (speakerId && currentLanguage === lang.id)) {
                              const filtered = updatedElementsList.filter(
                                  (item) => item.lang === lang.id
                              );

                              if (filtered.length > 0) {
                                   const dragElement = filtered[filtered.length - 1];
                                   reorderingElements.push(
                                       update(filtered, {
                                            $splice: [
                                                 [filtered.length - 1, 1],
                                                 [index, 0, dragElement],
                                            ],
                                       })
                                   );
                              }
                         }
                    });
                    let updatedElements = [];
                    reorderingElements.forEach((movedElements) => {
                         updatedElements = updatedElements.concat(
                              movedElements.map((el, index) => {
                                   el.index = index;
                                   return el;
                              })
                         );
                    });

                    setElements(updatedElements);

                    return index;
               }
          }

          return null;
     };

     const handleSetCurrentLanguage = (langIndex) => {
          setCurrentLanguage(langIndex);
     };

     const toggleMultiLanguage = () => {
          setMultilanguage(!multiLanguage);
     };

     const handleMultiLanguageChange = (values) => {
          setMultiLanguageSelected(values);
     };

     const isMandatoryLanguageActive = () => {
          if (mandatoryLanguage && currentLanguage) {
               return mandatoryLanguage === currentLanguage;
          }

          return null;
     };

     const links = [
          {
               to: `/organization/${organizationId}/settings`,
               text: `${organization && organization.name}`,
          },
          {
               to: `/organization/${organizationId}/events`,
               text: i18n.t('events'),
          },
          {
               to: `/organization/${organizationId}/events/${eventId}/speakers`,
               text: `${eventData && eventData.name}`,
          },
          {
               to: `/organization/${organizationId}/events/${eventId}/speakers`,
               text: i18n.t('event.speakers'),
          },
          {
               to: `/organization/${organizationId}/events/${eventId}/invite-speakers/step-1`,
               text: i18n.t('speakers.invite_speakers'),
          },
          {
               to: `/organization/${organizationId}/events/${eventId}/invite-speakers/step-2`,
               text: i18n.t('form_builder.form_builder'),
          },
     ];

     const handleElementSelect = useCallback((e, id) => {
          e.stopPropagation();

          if (selectedElement && selectedElement.id !== id) {
               setSelectedElement(null);
          }

          const el = elements.find(
               ({ id: _id, lang }) => _id === id && lang === currentLanguage
          );
          el && setSelectedElement(el);
     });

     const isSelectedItem = useCallback(
          (id) => {
               return selectedElement && selectedElement.id === id;
          },
          [selectedElement]
     );

     const removeElement = (id) => {
          let updatedList = [...elements];
          const element = updatedList.find(({ id: _id }) => _id === id);
          updatedList = updatedList.filter(({ id: _id, index }) => _id !== id);
          updatedList = updatedList.filter(
               ({ id: _id, index }) => index !== element.index
          );

          setSelectedElement(null);
          setElements(updatedList);

          const builderUrl = builderId ? `?builder-id=${builderId}` : '';
          const speakerUrl = speakerId ? `?speaker-id=${speakerId}` : '';

          if (updatedList.length === 0) {
               redirectToFirstStep(
                    `/organization/${organizationId}/events/${eventId}/form-builder/add-element` +
                         builderUrl + speakerUrl
               );
          }
     };

     const moveElement = useCallback(
          (dragIndex, hoverIndex) => {
               let elementsForUpdate = [];

               languages.forEach((lang) => {
                    const filtered = elements.filter(
                         (item) => item.lang === lang.id
                    );

                    if (filtered.length > 0) {
                         const dragElement = filtered[dragIndex];
                         elementsForUpdate.push(
                              update(filtered, {
                                   $splice: [
                                        [dragIndex, 1],
                                        [hoverIndex, 0, dragElement],
                                   ],
                              })
                         );
                    }
               });

               let updateElements = [];
               elementsForUpdate.forEach((movedElements) => {
                    updateElements = updateElements.concat(movedElements);
               });

               setElements(updateElements);
          },
          [elements]
     );

     const exportConfirmationMessage = (languageId) => {
          const langId = languageId ? languageId : currentLanguage;

          if (langId) {
               const existing = confirmationMessage.find(({language_id}) => language_id === langId);

               if (existing) {
                    return existing;
               }
          }

          return {
               is_valid: false,
               message: '',
               language_id: null,
          };
     };

     const handleConfirmationMessage = (message) => {
          const currentConfirmationMessage = confirmationMessage;
          currentConfirmationMessage.map((data) => {
               if (data.language_id === currentLanguage) {
                    data.message = message;
               }

               return validateConfirmationMessage(data);
          });

          setConfirmationMessage(currentConfirmationMessage);
     };

     const validateConfirmationMessage = (data) => {
          if (
              _.isEmpty(data.message)
          ) {
               data.is_valid = false;

               return data;
          }

          data.is_valid = true;

          return data;
     };

     const isValidConfirmationMessage = (languageId) => {
          const isValid = exportConfirmationMessage(languageId).is_valid;

          if (formLoaded) {
               return isValid;
          }

          return true;
     };

     const isValidLanguage = (languageId) => {
          let valid = true;
          elements.forEach((element) => {
               if (
                   (
                       element.lang === languageId
                       && element.is_valid !== undefined
                       && !element.is_valid
                   ) ||
                   (
                       element.lang === languageId && !element.is_translated
                   )
               ) {
                    valid = false;
               }
          });

          confirmationMessage.forEach((message) => {
               if (message.language_id === languageId && message.is_valid !== undefined && !message.is_valid) {
                    valid = false;
               }
          });

          return valid;
     };

     const isValidForm = () => {
          let valid = true;
          multiLanguageSelected.forEach((langId) => {
               if (!isValidLanguage(langId)) {
                    valid = false;
               }
          });

          return valid;
     };

     const isValidLanguageCode = (languageId) => {
          let valid = true;
          elements.forEach((element) => {
               if (element.lang === languageId && element.is_valid_code !== undefined && !element.is_valid_code) {
                    valid = false;
               }
          });

          confirmationMessage.forEach((message) => {
               if (message.language_id === languageId && message.is_valid_code !== undefined && !message.is_valid_code) {
                    valid = false;
               }
          });

          return valid;
     };

     const isValidFormCodes = () => {
          let valid = true;
          multiLanguageSelected.forEach((langId) => {
               if (!isValidLanguageCode(langId)) {
                    valid = false;
               }
          });

          return valid;
     };

     const handleRemoveElement = (id) => {
          SweetAlert({
               title: i18n.t('sweet_alerts.warning'),
               text: i18n.t('sweet_alerts.delete_element'),
               confirmButtonText: i18n.t('buttons.delete'),
               cancelButtonText: i18n.t('buttons.cancel'),
               showCancelButton: true,
               callback: () => {
                    removeElement(id);
                    toastr.success(i18n.t('alerts.success.element_removed'));
               },
          });
     };

     const handleSettingChange = ({ id, name, value }) => {
          let updatedList = elements.map((element) => {
               if (element.id === id && element.lang === currentLanguage) {
                    return {
                         ...element,
                         [name]: value,
                    };
               }

               return element;
          });

          const element = updatedList.find((e) => e.id === id && e.lang === currentLanguage);
          if (element.is_master) {
               let updatedChildList = UpdateChildLanguages(updatedList, element, name, value);

               return setElements(updatedChildList);
          } else {
               return setElements(updatedList);
          }
     };

     const getMasterValue = (internalId, field, subId, defaultValue) => {
          const result = MasterLanguageValue(elements, internalId, field, subId);

          if (result) {
               return result;
          }

          return defaultValue;
     };

     const handleLanguageChange = (langId) => {
          setCurrentLanguage(langId);
     };

     const handleLinkClose = (link) => {
          displayCancelBuilderModal(() => {
               localStorage.setItem('invitation_flow_activate_saved_state', true);
               redirectToLocation(link);
          });
     };

     const handleCancel = () => {
          displayCancelBuilderModal(() => {
               if (speakerId) {
                    redirectToSpeakersInvite(
                        `/organization/${organizationId}/events/${eventId}/speakers`
                    );
               } else {
                    localStorage.setItem('invitation_flow_activate_saved_state', true);
                    redirectToSpeakersInvite(
                        `/organization/${organizationId}/events/${eventId}/invite-speakers/step-2`
                    );
               }
          });
     };

     const changeElementTab = (e, link) => {
          e.preventDefault();
          redirectToLocation(link);
     };

     const displayCancelBuilderModal = (callback) => {
          SweetAlert({
               title: i18n.t('sweet_alerts.attention'),
               text: i18n.t('sweet_alerts.close_form_builder'),
               confirmButtonText: i18n.t('form_builder.close_the_builder'),
               cancelButtonText: i18n.t('buttons.cancel'),
               showCancelButton: true,
               callback: callback
          });
     };

     const getClassName = (langId) => {
          if (isValidLanguage(langId)) {
               return currentLanguage === langId ? 'primary' : 'outline-primary';
          }

          return currentLanguage === langId ? 'warning' : 'outline-warning';
     };

     const getLanguageName = (languageId) => {
          const language = languages.find((l) => l.id === languageId);

          if (language) {
               return language.name;
          }

          return null;
     };

     const handleFormChange = ({ name, value }) => {
          const updatedForm = {
               ...form,
               [name]: value,
          };

          setForm(updatedForm);
     };

     const handleSetAccessLevel = (value) => {
          setForm({ ...form, accessLevel: value });
     };


     const prepareFields = useCallback(() => {
          if (multiLanguage) {
               let elementsForSaving = [];
               multiLanguageSelected.forEach((langId) => {
                    const tmpElements = elements
                         .filter((el) => el.lang === langId)
                         .map((el, index) => {
                              const { lang, ...rest } = el;

                              return {
                                   ...rest,
                                   additional_information: el.additionalInformation,
                                   multiple_choices: el.multipleSelections,
                                   code: el.elementCode,
                                   layout: el.layout && el.layout,
                                   option: el.elementType,
                                   format: el.timeFormat,
                                   language_id: el.lang,
                                   order: index,
                                   is_master: langId === mandatoryLanguage
                              };
                         });
                    elementsForSaving = elementsForSaving.concat(tmpElements);
               });

               return elementsForSaving;
          }

          return elements
               .filter((el) => el.lang === currentLanguage)
               .map((el, index) => {
                    const { lang, ...rest } = el;

                    return {
                         ...rest,
                         additional_information: el.additionalInformation,
                         multiple_choices: el.multipleSelections,
                         code: el.elementCode,
                         layout: el.layout && el.layout,
                         option: el.elementType,
                         format: el.timeFormat,
                         language_id: el.lang,
                         order: index,
                         is_master: el.lang === mandatoryLanguage
                    };
               });
     }, [multiLanguage, mandatoryLanguage, multiLanguageSelected, elements]);

     const prepareConfirmationMessages = useCallback(() => {
          return confirmationMessage.filter(data => multiLanguageSelected.includes(data.language_id));
     }, [multiLanguage, mandatoryLanguage, multiLanguageSelected, confirmationMessage]);

     const schema = yupObject().shape({
          formName: yupString().required(),
          accessLevel: yupMixed()
               .notOneOf(['Select the access level', ''])
               .required()
     });

     const saveForm = async (redirect = false) => {
          const { formName, accessLevel } = form;
          return schema
              .isValid({ formName, accessLevel })
              .then((valid) => {
                   if (valid) {
                        const values = multiLanguage
                            ? {
                                 name: formName,
                                 language_id: mandatoryLanguage,
                                 multilingual: multiLanguage,
                                 languages: multiLanguageSelected,
                                 access_type: accessLevel
                                     .split(' ')
                                     .map((w) => w.toLowerCase())
                                     .join('_'),
                                 confirmation_message: prepareConfirmationMessages(),
                                 fields: prepareFields()
                            }
                            : {
                                 name: formName,
                                 language_id: mandatoryLanguage,
                                 multilingual: multiLanguage,
                                 language: currentLanguage,
                                 access_type: accessLevel
                                     .split(' ')
                                     .map((w) => w.toLowerCase())
                                     .join('_'),
                                 confirmation_message: prepareConfirmationMessages(),
                                 fields: prepareFields()
                            };

                        if (!redirect) {
                             return builderId
                                 ? requestUpdateForm({
                                      organizationId,
                                      eventId,
                                      id: builderId,
                                      ...values,
                                 })
                                 : requestCreateForm({ eventId, organizationId, ...values });
                        }

                        if (redirect) {
                             localStorage.setItem('invitation_flow_activate_saved_state', true);

                             return builderId
                                 ? requestUpdateForm({
                                      eventId,
                                      organizationId,
                                      redirect: true,
                                      organizationId,
                                      id: builderId,
                                      ...values,
                                 })
                                 : requestCreateForm({
                                      eventId,
                                      redirect: true,
                                      organizationId,
                                      ...values,
                                 });
                        }
                   }

                   redirectToLastStep(
                       `/organization/${organizationId}/events/${eventId}/form-builder/form-settings`
                   );
              });
     };

     const handleSaveForm = async (redirect = false) => {
          if (!isValidFormCodes()) {
               SweetAlert({
                    title: i18n.t('sweet_alerts.attention') + '!',
                    text: i18n.t('sweet_alerts.form_contains_duplicate_element_codes'),
                    confirmButtonText: i18n.t('buttons.ok'),
                    callback: () => {},
               });
          } else {
               if (isValidForm()) {
                    await saveForm(redirect);
               } else {
                    SweetAlert({
                         title: i18n.t('sweet_alerts.attention') + '!',
                         text: i18n.t('sweet_alerts.form_contain_errors'),
                         confirmButtonText: i18n.t('buttons.continue'),
                         cancelButtonText: i18n.t('buttons.cancel'),
                         showCancelButton: true,
                         callback: () => {
                              saveForm(redirect);
                         },
                    });
               }
          }
     };

     const handleUpdateSpeaker = async (redirect = false) => {
          if (formBasicDetails) {
               const values = {
                    redirect,
                    speakerId,
                    organizationId,
                    shouldCreate: formBasicDetails.access_type !== 'custom',
                    eventId: eventId,
                    id: formBasicDetails.id,
                    name: 'Custom form',
                    language_id: mandatoryLanguage,
                    multilingual: false,
                    language: currentLanguage,
                    access_type: 'custom',
                    confirmation_message: prepareConfirmationMessages(),
                    fields: prepareFields(),
                    original_form_id: formBasicDetails.id
               };

               requestSaveCustomForm(values);
          }
     };

     const constructLink = useCallback(
          (type) => {
               const links = {
                    addElement: `/organization/${organizationId}/events/${eventId}/form-builder/add-element`,
                    elementSettings: `/organization/${organizationId}/events/${eventId}/form-builder/element-settings`,
                    formSettings: `/organization/${organizationId}/events/${eventId}/form-builder/form-settings`,
               };

               if (builderId) {
                    links[type] += `?builder-id=${builderId}`;
               }

               if (speakerId) {
                    links[type] += `?speaker-id=${speakerId}`;
               }

               return links[type];
          },
          [builderId, speakerId]
     );

     const renderPageTitle = () => {
          if (speakerId) {
               return `${t('edit_speaker')} | ${t('avovent')}`;
          }

          return `${t('form_builder.form_builder')} | ${t('avovent')}`;
     };

     const renderLanguageSelector = useCallback(() => {
          return (
              <div className="language-buttons">
                   {multiLanguageSelected.map(
                       (langId) => (
                           <Button
                               variant={getClassName(
                                   langId
                               )}
                               key={
                                    langId
                               }
                               onClick={() =>
                                   handleLanguageChange(
                                       langId
                                   )
                               }
                           >
                                {getLanguageName(langId)}
                           </Button>
                       )
                   )}
              </div>
          );
     }, [multiLanguage, multiLanguageSelected, mandatoryLanguage, currentLanguage, elements, selectedElement]);

     const clickListener = useCallback(
          (e) => {
               if (
                    e.target &&
                    e.target.className instanceof SVGAnimatedString
               ) {
                    return;
               }

               if (
                   e.target &&
                   elementSettingsLinkRef.current &&
                   elementSettingsLinkRef.current.contains(e.target) &&
                   !e.target.className.includes('btn')
               ) {
                    return;
               }

               if (
                    e.target &&
                    elementSettingsRef.current &&
                    elementSettingsRef.current.contains(e.target) &&
                    !e.target.className.includes('btn')
               ) {
                    return;
               }

               if (
                    e.target &&
                    formSettingsElementRef.current &&
                    formSettingsElementRef.current.contains(e.target) &&
                    !e.target.className.includes('btn')
               ) {
                    return;
               }

               if (e.target && e.target.className === 'selection-element') {
                    return;
               }

               if (e.target && e.target.className.includes('nav-link')) {
                    if (
                         formSettingsElementLinkRef.current.contains(
                              e.target
                         ) ||
                         elementSettingsLinkRef.current.contains(e.target)
                    ) {
                         return;
                    }
               }

               if (e.target.type !== 'button') {
                    setSelectedElement(null);
               }
          },
          [ref.current]
     );

     useEffect(() => {
          document.getElementsByClassName('form-builder-zone')[0].addEventListener('click', clickListener);
          return () => {
               document.getElementsByClassName('form-builder-zone')[0].removeEventListener('click', clickListener);
          };
     }, []);

     const getFullCurrentLanguage = () => {
          if (languages.length > 0 && currentLanguage) {
               const lang = languages.find((l) => l.id === currentLanguage);

               if (lang) {

                    return lang;
               }
          }

          return null;
     };

     return (
          <>
               <Helmet>
                    <title>{renderPageTitle()}</title>
               </Helmet>
               <MainDrawer>
                    <ListItem to="/dashboard">
                         {t('breadcrumb.dashboard')}
                    </ListItem>
                    <CollapsibleList
                         listHeader={organization && organization.name}
                         listContent={
                              <>
                                   <ListItem
                                        to={`/organization/${organizationId}/events/${eventId}/event-edit`}
                                   >
                                        <div className="collapsible-list-item">
                                             <GroupChatImage />
                                             <div className="sub-item">
                                                  {t('events')}
                                             </div>
                                        </div>
                                   </ListItem>
                                   <CollapsibleList
                                        listHeader={
                                             <div className="collapsible-list-item">
                                                  <SettingsImage />
                                                  <div className="sub-item">
                                                       {t(
                                                            'organization_settings.organization_settings'
                                                       )}
                                                  </div>
                                             </div>
                                        }
                                        listContent={
                                             <>
                                                  <ListItem
                                                       subItem
                                                       to={`/organization/${organizationId}/settings`}
                                                  >
                                                       <div className="collapsible-list-item">
                                                            <DotImage />
                                                            <div className="sub-item">
                                                                 {t(
                                                                      'organization_settings.organization_settings'
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </ListItem>
                                                  <ListItem
                                                       subItem
                                                       to={`/organization/${organizationId}/members`}
                                                  >
                                                       <div className="collapsible-list-item">
                                                            <DotImage />
                                                            <div className="sub-item">
                                                                 {t(
                                                                      'organization_settings.team_members'
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </ListItem>
                                                  <ListItem
                                                       subItem
                                                       to={`/organization/${organizationId}/email-senders`}
                                                  >
                                                       <div className="collapsible-list-item">
                                                            <DotImage />
                                                            <div className="sub-item">
                                                                 {t(
                                                                      'organization_settings.email_senders'
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </ListItem>
                                             </>
                                        }
                                   />
                              </>
                         }
                    />
                    <CollapsibleList
                         listHeader={eventData && eventData.name}
                         listContent={
                              <>
                                   <ListItem
                                        to={`/organization/${organizationId}/events/${eventId}/speakers`}
                                   >
                                        <div className="collapsible-list-item">
                                             <SpeakersIcon />
                                             <div className="sub-item">
                                                  {t('event.speakers')}
                                             </div>
                                        </div>
                                   </ListItem>
                                   <ListItem
                                       to={`/organization/${organizationId}/events/${eventId}/speakers`}
                                   >
                                        <div className="collapsible-list-item">
                                             <ReminderIcon />
                                             <div className="sub-item">
                                                  {t('reminders.reminders')}
                                             </div>
                                        </div>
                                   </ListItem>
                                   <ListItem
                                        to={`/organization/${organizationId}/events/${eventId}/event-edit`}
                                   >
                                        <div className="collapsible-list-item">
                                             <SettingsImage />
                                             <div className="sub-item">
                                                  {t('event.event_settings')}
                                             </div>
                                        </div>
                                   </ListItem>
                              </>
                         }
                    />
               </MainDrawer>
               <DndProvider backend={HTML5Backend}>
                    <Route
                         path={`/organization/${organizationId}/events/${eventId}/form-builder${
                              builderId ? `?builder-id=${builderId}` : ''
                         }`}
                    />
                    <div className="content--border">
                         <div
                              className="container-standard"
                              style={{
                                   display: 'flex',
                                   alignItems: 'center',
                              }}
                         >
                              <div
                                   className="nav"
                                   style={{
                                        flexWrap: 'nowrap',
                                   }}
                              >
                                   <Link to="/dashboard" className="inactive">
                                        {t('breadcrumb.dashboard')}
                                   </Link>
                                   <OrganizationDropdown organization={organization} inactive/>
                                   <EventDropdown />
                              </div>
                         </div>
                         <LoadingBar finish={!!organization && !!eventData} />
                    </div>
                    <div className="container-standard">
                         {organization && eventData && (
                              <Breadcrumb
                                   title={t('form_builder.form_builder')}
                                   links={links}
                                   callback={handleLinkClose}
                              />
                         )}
                    </div>
                    <FormBuilderWrapper>
                         <div className="container-standard">
                              <div className="form-builder-grid">
                                   <Card className="form-left-side">
                                        <Nav variant="tabs">
                                             <Nav.Item>
                                                  <NavLink
                                                       className={`nav-link`}
                                                       role="tab"
                                                       onClick={(e) => {changeElementTab(e, constructLink('addElement'))}}
                                                       to={constructLink(
                                                            'addElement'
                                                       )}
                                                  >
                                                       {t(
                                                            'form_builder.add_element'
                                                       )}
                                                  </NavLink>
                                             </Nav.Item>
                                             <Nav.Item
                                                  ref={elementSettingsLinkRef}
                                             >
                                                  <NavLink
                                                       className="nav-link"
                                                       role="tab"
                                                       onClick={(e) => {changeElementTab(e, constructLink('elementSettings'))}}
                                                       to={constructLink(
                                                            'elementSettings'
                                                       )}
                                                  >
                                                       {t(
                                                            'form_builder.element_settings'
                                                       )}
                                                  </NavLink>
                                             </Nav.Item>
                                             <Nav.Item
                                                  ref={
                                                       formSettingsElementLinkRef
                                                  }
                                             >
                                                  <NavLink
                                                       className={`nav-link ${!isValidConfirmationMessage(currentLanguage) ? 'warning' : ''}`}
                                                       role="tab"
                                                       onClick={(e) => {changeElementTab(e, constructLink('formSettings'))}}
                                                       to={constructLink(
                                                            'formSettings'
                                                       )}
                                                  >
                                                       {t(
                                                            'form_builder.form_settings'
                                                       )}
                                                  </NavLink>
                                             </Nav.Item>
                                        </Nav>

                                        <Card.Body className="toolbar">
                                             <Switch>
                                                  <Route
                                                       path={`/organization/${organizationId}/events/${eventId}/form-builder/add-element`}
                                                  >
                                                       <Tab.Pane>
                                                            <AddElementStep
                                                                 readonly={
                                                                      !!speakerId
                                                                 }
                                                                 handleDrawElements={
                                                                      handleDrawElements
                                                                 }
                                                                 handleElementSelect={
                                                                      handleElementSelect
                                                                 }
                                                                 handleRemoveElement={
                                                                      handleRemoveElement
                                                                 }
                                                                 selectedElement={
                                                                      selectedElement
                                                                 }
                                                                 moveElement={
                                                                      moveElement
                                                                 }
                                                                 isSelectedItem={
                                                                      isSelectedItem
                                                                 }
                                                                 isMandatoryLanguageActive={
                                                                      isMandatoryLanguageActive
                                                                 }
                                                                 mandatoryLanguage={getMandatoryLanguage()}
                                                            />
                                                       </Tab.Pane>
                                                  </Route>
                                                  <Route
                                                       path={`/organization/${organizationId}/events/${eventId}/form-builder/element-settings`}
                                                  >
                                                       <Tab.Pane
                                                            ref={
                                                                 elementSettingsRef
                                                            }
                                                       >
                                                            <ElementSettings
                                                                 selectedElement={
                                                                      selectedElement
                                                                 }
                                                                 handleSettingChange={
                                                                      handleSettingChange
                                                                 }
                                                                 handleRemoveElement={
                                                                      handleRemoveElement
                                                                 }
                                                                 getMasterValue={getMasterValue}
                                                                 fieldsList={fieldsList}
                                                            />
                                                       </Tab.Pane>
                                                  </Route>
                                                  <Route
                                                       path={`/organization/${organizationId}/events/${eventId}/form-builder/form-settings`}
                                                  >
                                                       <Tab.Pane
                                                            ref={
                                                                 formSettingsElementRef
                                                            }
                                                       >
                                                            <FormSettings
                                                                 readonly={
                                                                      !!speakerId
                                                                 }
                                                                 form={form}
                                                                 handleFormChange={
                                                                      handleFormChange
                                                                 }
                                                                 handleSetAccessLevel={
                                                                      handleSetAccessLevel
                                                                 }
                                                                 mandatoryLanguage={
                                                                      mandatoryLanguage
                                                                 }
                                                                 setMandatoryLanguage={
                                                                      setMandatoryLanguage
                                                                 }
                                                                 currentLanguage={
                                                                      currentLanguage
                                                                 }
                                                                 multiLanguage={
                                                                      multiLanguage
                                                                 }
                                                                 handleSetCurrentLanguage={
                                                                      handleSetCurrentLanguage
                                                                 }
                                                                 toggleMultiLanguage={
                                                                      toggleMultiLanguage
                                                                 }
                                                                 languages={
                                                                      languages
                                                                 }
                                                                 multiLanguageSelected={
                                                                      multiLanguageSelected
                                                                 }
                                                                 handleMultiLanguageChange={
                                                                      handleMultiLanguageChange
                                                                 }
                                                                 isMandatoryLanguageActive={isMandatoryLanguageActive}
                                                                 confirmationMessage={exportConfirmationMessage()}
                                                                 handleConfirmationMessage={handleConfirmationMessage}
                                                            />
                                                       </Tab.Pane>
                                                  </Route>
                                             </Switch>
                                        </Card.Body>
                                   </Card>
                                   <Portlet
                                        title={
                                             <span>
                                                  {t(
                                                       'form_builder.form_builder'
                                                  )}
                                             </span>
                                        }
                                        sticky={multiLanguage}
                                        customTitle={
                                             multiLanguage && renderLanguageSelector()
                                        }
                                        content={
                                             <>
                                                  <div>
                                                       <BuilderDropZone
                                                            readonly={
                                                                 !!speakerId
                                                            }
                                                            elements={elements}
                                                            language={getFullCurrentLanguage()}
                                                            currentLanguage={
                                                                 currentLanguage
                                                            }
                                                            handleElementSelect={
                                                                 handleElementSelect
                                                            }
                                                            handleRemoveElement={
                                                                 handleRemoveElement
                                                            }
                                                            selectedElement={
                                                                 selectedElement
                                                            }
                                                            moveElement={
                                                                 moveElement
                                                            }
                                                            handleDrawElements={
                                                                 handleDrawElements
                                                            }
                                                            isSelectedItem={
                                                                 isSelectedItem
                                                            }
                                                       />
                                                       <DragLayer
                                                            handleElementSelect={
                                                                 handleElementSelect
                                                            }
                                                            handleRemoveElement={
                                                                 handleRemoveElement
                                                            }
                                                            selectedElement={
                                                                 selectedElement
                                                            }
                                                            moveElement={
                                                                 moveElement
                                                            }
                                                            handleDrawElements={
                                                                 handleDrawElements
                                                            }
                                                            isSelectedItem={
                                                                 isSelectedItem
                                                            }
                                                       />
                                                  </div>

                                                  {elements.length > 0 && (
                                                       <div className="bottom-navigation">
                                                            <Button
                                                                 variant="outline-primary"
                                                                 onClick={() =>
                                                                      handleCancel()
                                                                 }
                                                            >
                                                                 {t(
                                                                      'buttons.close'
                                                                 )}
                                                            </Button>
                                                            <span className="saving=actions">
                                                                 <Button
                                                                      variant="outline-primary"
                                                                      onClick={() =>
                                                                           speakerId
                                                                                ? handleUpdateSpeaker()
                                                                                : handleSaveForm()
                                                                      }
                                                                 >
                                                                      {t(
                                                                           'buttons.save'
                                                                      )}
                                                                 </Button>
                                                                 <Button
                                                                      variant="success"
                                                                      onClick={() =>
                                                                           speakerId
                                                                                ? handleUpdateSpeaker('redirect')
                                                                                : handleSaveForm(
                                                                                       'redirect'
                                                                                  )
                                                                      }
                                                                 >
                                                                      {t(
                                                                           'buttons.save_and_close'
                                                                      )}
                                                                 </Button>
                                                            </span>
                                                       </div>
                                                  )}
                                             </>
                                        }
                                   />
                              </div>
                         </div>
                    </FormBuilderWrapper>
               </DndProvider>
          </>
     );
});

export const FormBuilder = connect(
     mapStateToProps,
     mapDispatchToProps
)(_FormBuilder);
