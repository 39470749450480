import React, { memo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Alert from '../../common/alert';
import illustration from '../../../assets/images/illustration-notifications.svg';
import RemindersWrapper from './Reminders.style';

export const NoActiveReminders = memo(() => {
     const { id, eventId } = useParams();
     const { t } = useTranslation();

     return (
          <RemindersWrapper>
               <div className="content container-standard">
                    <div className="actions">
                         <Alert>{t('alerts.primary.reminders_empty')}</Alert>
                         <Link
                              to={`/organization/${id}/events/${eventId}/reminders/new-reminder`}
                         >
                              <Button variant="success">
                                   {t('reminders.new_reminder')}
                              </Button>
                         </Link>
                    </div>
                    <img
                         src={illustration}
                         alt={t('reminders.illustration_notifications')}
                    />
               </div>
          </RemindersWrapper>
     );
});
