import React, { memo, useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Dropdown } from 'react-bootstrap';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

import PictureUploader from '../../../common/picture-uploader';
import CustomToggle from '../../../common/custom-toggle';
import { CustomDatePicker } from '../../../common/pickers/CustomDatePicker';
import { CustomTimePicker } from '../../../common/pickers/CustomTimePicker';
import { COLORS } from '../../../../constants/style.constants';
import { requestFormFields} from '../../../../redux/reducers/formBuilderReducer';
import { ElementTypes } from '../../FormBuilder/ElementTypes';
import ChevronDownIcon from '../../../../assets/icons/metronic/chevron-down-icon';
import defaultPhoto from '../../../../assets/images/default-photo.svg';
import FormBuilderWrapper from '../../FormBuilder/FormBuilder.style';

const mapStateToProps = (state) => ({
     formPreview: state.formBuilderReducer.formPreview
});

const mapDispatchToProps = (dispatch) => ({
     requestFormFields: (id, locale) => dispatch(requestFormFields({ id, locale })),
});

const _PreviewFormModal = memo(
     ({
          show,
          handleClose,
          formId,
          language,
          allLangauges,
          formPreview,
          requestFormFields
     }) => {
          const { t } = useTranslation();
          const form = useRef();

         const [selectedLanguage, setSelectedLanguage] = useState(null);

         useEffect(() => {
             // Disable scrolling on main page
             document.body.style.overflow = show ? 'hidden' : 'unset';
         }, [show]);

          useEffect(() => {
               if (formId && selectedLanguage) {
                    requestFormFields(formId, selectedLanguage.code);
               }
          }, [formId, selectedLanguage]);

          useEffect(() => {
              if (language) {
                  setSelectedLanguage(language);

                  setInnerState({
                      ...innerState,
                      currentTemplateOption: i18n.t('form_builder.select_something', { lng: language?.code })
                  });
              }
          }, [formId, language]);

          const closeModal = () => {

              setSelectedLanguage(null);
              handleClose();
          };

          const dropdownRef = useRef();

          const DEFAULT_TEMPLATE_OPTION = i18n.t('form_builder.select_something', { lng: language?.code });

          const [innerState, setInnerState] = useState({
               templateOptions: [
                    { value: DEFAULT_TEMPLATE_OPTION },
                    { value: 1 },
                    { value: 2 },
                    { value: 3 },
                    { value: 4 },
                    { value: 5 },
               ],
               currentTemplateOption: DEFAULT_TEMPLATE_OPTION,
          });

          const av = defaultPhoto;

          const [avatar, setAvatar] = useState(av);

          const getFile = (file) => setAvatar(file);

          const getDefault = () => av;

          const displayLayout = (layout) => {
               switch (layout) {
                    case 'one_column':
                         return '1fr';
                         break;
                    case 'two_column':
                         return '1fr 1fr';
                         break;
                    case 'three_column':
                         return '1fr 1fr 1fr';
                         break;
                    default:
                         return '1fr';
               }
          };

         const displayDropdown = (item) => {
             const fieldValue = item.choices.find((c) => !!c.preChecked);

             if (fieldValue) {

                 return fieldValue.value;
             }

             return t('form_builder.select_something', { lng: selectedLanguage?.code });
         };

          const drawElements = (item) => {
               let context;

               switch (item.type) {
                    case ElementTypes.TITLE:
                         context = (
                              <h3 className="title mb-20">
                                   {item.value || item.name}
                              </h3>
                         );
                         break;
                    case ElementTypes.PARAGRAPH:
                         context = (
                              <div className="paragraph mb-20">
                                   {item.text || item.name}
                              </div>
                         );
                         break;
                    case ElementTypes.TEXT_FIELD:
                         context = (
                              <div className="mb-20">
                                   <label
                                        htmlFor={item.label}
                                        style={{
                                             display: 'grid',
                                             gridTemplateColumns: '195px auto',
                                             placeItems: 'start start',
                                        }}
                                   >
                                        <span
                                             style={{
                                                  maxWidth: '160px',
                                                  wordBreak: 'break-all'
                                             }}
                                        >
                                             {item.label || item.name}
                                             {item.required && (
                                                  <span
                                                       style={{
                                                            marginLeft: '10px',
                                                            color: `${COLORS.RED}`,
                                                       }}
                                                  >
                                                       *
                                                  </span>
                                             )}
                                        </span>
                                        <div style={{ width: '100%' }}>
                                            <input
                                                required={item.required}
                                                className="form-control"
                                                id={item.label}
                                                name={item.elementName}
                                                type={item.elementType}
                                                placeholder={item.placeholder}
                                                value={item.value}
                                                onChange={() => {}}
                                            />
                                            <div className="info-block">
                                                {item.additional_information}
                                            </div>
                                        </div>
                                   </label>
                              </div>
                         );
                         break;
                    case ElementTypes.TEXT_AREA:
                         context = (
                              <div className="mb-20">
                                   <label
                                        htmlFor={item.label}
                                        style={{
                                             display: 'grid',
                                             gridTemplateColumns: '195px auto',
                                             placeItems: 'start start',
                                        }}
                                   >
                                        <span
                                             style={{
                                                  maxWidth: '160px',
                                                  placeSelf: 'start',
                                                  wordBreak: 'break-all'
                                             }}
                                        >
                                             {item.label || item.name}
                                             {item.required && (
                                                  <span
                                                       style={{
                                                            marginLeft: '10px',
                                                            color: `${COLORS.RED}`,
                                                       }}
                                                  >
                                                       *
                                                  </span>
                                             )}
                                        </span>
                                        <div style={{ width: '100%' }}>
                                            <textarea
                                                required={item.required}
                                                className="form-control textarea"
                                                type="textarea"
                                                rows="9"
                                                id={item.label}
                                                name={item.elementName}
                                                placeholder={item.placeholder}
                                                value={item.text}
                                                onChange={() => {}}
                                            />
                                            <div className="info-block">
                                                {item.additional_information}
                                            </div>
                                        </div>
                                   </label>
                              </div>
                         );
                         break;
                    case ElementTypes.CHECKBOX:
                         context = (
                              <div
                                   style={{
                                        display: 'grid',
                                        gridTemplateColumns: '195px 1fr 37px',
                                        placeItems: 'start',
                                        position: 'relative',
                                   }}
                              >
                                   <label
                                        style={{
                                             maxWidth: '160px',
                                             justifySelf: 'start',
                                             wordBreak: 'break-all'
                                        }}
                                   >
                                        {item.label || item.name}
                                        {item.required && (
                                             <span
                                                  style={{
                                                       marginLeft: '10px',
                                                       color: `${COLORS.RED}`,
                                                  }}
                                             >
                                                  *
                                             </span>
                                        )}
                                   </label>

                                  <div>
                                      <div
                                          style={{
                                              display: 'grid',
                                              width: '100%',
                                              gridTemplateColumns: displayLayout(
                                                  item.layout
                                              ),
                                              gridColumnGap: '12px',
                                              gridTemplateRows:
                                                  item.layout ===
                                                  'one_column'
                                                      ? '1fr 1fr 1fr'
                                                      : '1fr',
                                              placeItems: 'start',
                                          }}
                                      >
                                          {item.choices.map((choice, idx) => (
                                              <label
                                                  key={idx}
                                                  className="checkbox-solid-blue"
                                              >
                                                  <input
                                                      required={item.required}
                                                      type="checkbox"
                                                      className="checkbox-input"
                                                      checked={
                                                          choice.preChecked
                                                      }
                                                  />

                                                  <span />
                                                  <div className="label-text">
                                                      {choice.label ||
                                                      t('form_builder.choice', { lng: selectedLanguage?.code })}
                                                  </div>
                                              </label>
                                          ))}
                                      </div>
                                      <div className="info-block mb-20">
                                          {item.additional_information}
                                      </div>
                                  </div>
                              </div>
                         );
                         break;
                    case ElementTypes.RADIO:
                         context = (
                              <div
                                   style={{
                                        display: 'grid',
                                        gridTemplateColumns: '195px 1fr 37px',
                                        placeItems: 'start',
                                        position: 'relative',
                                   }}
                              >
                                   <label
                                        style={{
                                             maxWidth: '160px',
                                             justifySelf: 'start',
                                            wordBreak: 'break-all'
                                        }}
                                   >
                                        {item.label || item.name}
                                        {item.required && (
                                             <span
                                                  style={{
                                                       marginLeft: '10px',
                                                       color: `${COLORS.RED}`,
                                                  }}
                                             >
                                                  *
                                             </span>
                                        )}
                                   </label>
                                  <div>
                                       <div
                                            className="checkbox-group"
                                            style={{
                                                 display: 'grid',
                                                 width: '100%',
                                                 gridTemplateColumns: displayLayout(
                                                      item.layout
                                                 ),
                                                 gridColumnGap: '12px',
                                                 gridTemplateRows:
                                                      item.layout ===
                                                      'one_column'
                                                           ? '1fr 1fr 1fr'
                                                           : '1fr',
                                                 placeItems: 'start',
                                            }}
                                       >
                                            {item.choices.map((choice, idx) => (
                                                 <label
                                                      key={idx}
                                                      className="form-label"
                                                      style={{
                                                          marginLeft: 0,
                                                          marginBottom: '0.5em'
                                                      }}
                                                 >
                                                      <input
                                                           required={item.required}
                                                           type="radio"
                                                           checked={
                                                                choice.preChecked
                                                           }
                                                      />
                                                      <div
                                                           className="circle-outer"
                                                           style={{ minWidth: 18 }}
                                                      >
                                                           <div className="circle-inner" />
                                                      </div>
                                                      <div
                                                           style={{
                                                                wordBreak:
                                                                     'break-word',
                                                           }}
                                                      >
                                                           {choice.label ||
                                                                i18n.t('form_builder.choice', { lng: selectedLanguage?.code })}
                                                      </div>
                                                 </label>
                                            ))}
                                       </div>
                                      <div className="info-block mb-20">
                                          {item.additional_information}
                                      </div>
                                  </div>
                              </div>
                         );
                         break;
                    case ElementTypes.IMAGE:
                         context = (
                              <label
                                   className="mb-20"
                                   htmlFor={item.label}
                                   style={{
                                        display: 'grid',
                                        gridTemplateColumns: '195px auto',
                                   }}
                              >
                                   <span
                                        style={{
                                             maxWidth: '160px',
                                             placeSelf: 'start',
                                             wordBreak: 'break-all'
                                        }}
                                   >
                                        {item.label || item.name}
                                        {item.required && (
                                             <span
                                                  style={{
                                                       marginLeft: '10px',
                                                       color: `${COLORS.RED}`,
                                                  }}
                                             >
                                                  *
                                             </span>
                                        )}
                                   </span>
                                   <div>
                                        <PictureUploader
                                             required={item.required}
                                             picture={avatar}
                                             getFile={getFile}
                                             getDefault={getDefault}
                                             infoText={' '}
                                        />
                                        {item.additional_information && (
                                             <div
                                                  className="info-block"
                                                  style={{ width: '100%' }}
                                             >
                                                  {item.additional_information}
                                             </div>
                                        )}
                                   </div>
                              </label>
                         );
                         break;
                    case ElementTypes.FILE_UPLOAD:
                         context = (
                              <div
                                   style={{
                                        display: 'grid',
                                        placeItems: 'center start',
                                        position: 'relative',
                                   }}
                              >
                                   <label
                                        className="mb-20"
                                        htmlFor={item.label}
                                        style={{
                                             display: 'grid',
                                             gridTemplateColumns: '195px auto',
                                        }}
                                   >
                                        <span
                                             style={{
                                                  maxWidth: '160px',
                                                  placeSelf: 'start',
                                                  wordBreak: 'break-all'
                                             }}
                                        >
                                             {item.label || item.name}
                                             {item.required && (
                                                  <span
                                                       style={{
                                                            marginLeft: '10px',
                                                            color: `${COLORS.RED}`,
                                                       }}
                                                  >
                                                       *
                                                  </span>
                                             )}
                                        </span>
                                        <div>
                                             <Button
                                                  className="btn-themed-blue"
                                                  style={{
                                                       margin: 0,
                                                  }}
                                             >
                                                  {t('form_builder.attach_files', { lng: selectedLanguage?.code })}
                                             </Button>
                                             <input
                                                  required={item.required}
                                                  multiple
                                                  type="file"
                                                  ref={null}
                                                  onChange={() => {}}
                                                  style={{
                                                       display: 'none',
                                                  }}
                                             />
                                             <div
                                                  className="info-block"
                                                  style={{
                                                       wordBreak: 'break-word',
                                                  }}
                                             >
                                                  {item.additional_information}
                                             </div>
                                        </div>
                                   </label>
                              </div>
                         );
                         break;
                    case ElementTypes.DATE:
                         context = (
                              <label
                                   className="mb-20"
                                   htmlFor={item.label}
                                   style={{
                                        display: 'grid',
                                        gridTemplateColumns: '195px auto',
                                        placeItems: 'start start',
                                   }}
                              >
                                   <span
                                        style={{
                                             maxWidth: '160px',
                                             placeSelf: 'start start',
                                             wordBreak: 'break-all'
                                        }}
                                   >
                                        {item.label || item.name}
                                        {item.required && (
                                             <span
                                                  style={{
                                                       marginLeft: '10px',
                                                       color: `${COLORS.RED}`,
                                                  }}
                                             >
                                                  *
                                             </span>
                                        )}
                                   </span>
                                   <div style={{ width: '100%' }}>
                                        <CustomDatePicker
                                             required={item.required}
                                             placeholderText={item.placeholder}
                                             style={{
                                                  zIndex: '2',
                                             }}
                                        />
                                        <div className="info-block">
                                             {item.additional_information}
                                        </div>
                                   </div>
                              </label>
                         );
                         break;
                    case ElementTypes.TIME:
                         context = (
                              <label
                                   className="mb-20"
                                   htmlFor={item.label}
                                   style={{
                                        display: 'grid',
                                        gridTemplateColumns: '195px auto',
                                        placeItems: 'start start',
                                   }}
                              >
                                   <span
                                        style={{
                                             maxWidth: '160px',
                                             placeSelf: 'start start',
                                             wordBreak: 'break-all'
                                        }}
                                   >
                                        {item.label || item.name}
                                        {item.required && (
                                             <span
                                                  style={{
                                                       marginLeft: '10px',
                                                       color: `${COLORS.RED}`,
                                                  }}
                                             >
                                                  *
                                             </span>
                                        )}
                                   </span>
                                   <div style={{ width: '100%' }}>
                                        <CustomTimePicker
                                             required={item.required}
                                             timeFormat={item.timeFormat}
                                             placeholderText={item.placeholder}
                                        />
                                        <div className="info-block">
                                             {item.additional_information}
                                        </div>
                                   </div>
                              </label>
                         );
                         break;
                    case ElementTypes.DROPDOWN:
                         context = (
                              <label
                                   className="mb-20"
                                   htmlFor={item.label}
                                   style={{
                                        display: 'grid',
                                        gridTemplateColumns: '195px auto',
                                        placeItems: 'start start',
                                   }}
                              >
                                   <span
                                        style={{
                                             maxWidth: '160px',
                                             placeSelf: 'start start',
                                             wordBreak: 'break-all'
                                        }}
                                   >
                                        {item.label || item.name}
                                        {item.required && (
                                             <span
                                                  style={{
                                                       marginLeft: '10px',
                                                       color: `${COLORS.RED}`,
                                                  }}
                                             >
                                                  *
                                             </span>
                                        )}
                                   </span>
                                   <div
                                        className="input-group"
                                        style={{ width: '100%' }}
                                   >
                                        <Dropdown
                                             ref={dropdownRef}
                                             style={{
                                                  pointerEvents: 'none',
                                             }}
                                        >
                                             <Dropdown.Toggle
                                                  className="select-dropdown"
                                                  as={CustomToggle}
                                             >
                                                  {
                                                      displayDropdown(item)
                                                  }
                                             </Dropdown.Toggle>
                                             <Dropdown.Menu>
                                                  {item.choices.map(
                                                       ({ value, id }) => (
                                                            <Dropdown.Item
                                                                 key={id}
                                                                 style={{
                                                                      pointerEvents:
                                                                           'none',
                                                                 }}
                                                                 onClick={() =>
                                                                      setInnerState(
                                                                           {
                                                                                templateOptions: [
                                                                                     ...item.choices,
                                                                                ],
                                                                                currentTemplateOption:
                                                                                     value ||
                                                                                     DEFAULT_TEMPLATE_OPTION,
                                                                           }
                                                                      )
                                                                 }
                                                            >
                                                                 {value ||
                                                                      t('form_builder.choice', { lng: selectedLanguage?.code })}
                                                            </Dropdown.Item>
                                                       )
                                                  )}
                                             </Dropdown.Menu>
                                        </Dropdown>
                                        <span className="suffix">
                                             <ChevronDownIcon />
                                        </span>
                                        <div className="info-block">
                                             {item.additional_information}
                                        </div>
                                   </div>
                              </label>
                         );
                         break;
                    case ElementTypes.DIVIDER:
                         context = (
                              <div
                                   className="mb-20"
                                   style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                   }}
                              >
                                   {item.invert ? (
                                        <hr className="divider" />
                                   ) : ''}
                                   <h3 className="title">
                                        {item.title || item.name}
                                   </h3>
                                   <div className="paragraph">
                                        {item.text || item.name}
                                   </div>
                                   {!item.invert ? <hr className="divider" /> : ''}
                              </div>
                         );
                         break;
               }

               return context;
          };

          return (
               <Modal
                    scrollable
                    show={show}
                    onHide={closeModal}
                    container={document.getElementById('root')}
                    size="lg"
               >
                    <Modal.Header closeButton>
                         <Modal.Title className="container">
                             <div className="row h-100">
                                 <div className="col-6 my-auto">
                                    {t('speakers.preview')}
                                 </div>
                                 <div className="col-6 text-right language-buttons">
                                     {allLangauges && allLangauges.length > 1 && allLangauges.map(
                                         (lang) => {
                                             return (
                                                 <Button
                                                     className="btn ml-2"
                                                     size="sm"
                                                     variant={
                                                         selectedLanguage && lang.id === selectedLanguage.id
                                                             ? 'primary'
                                                             : 'outline-primary'
                                                     }
                                                     key={
                                                         lang.id
                                                     }
                                                     onClick={() => {
                                                         setSelectedLanguage(lang);
                                                     }}
                                                 >
                                                     {lang.name}
                                                 </Button>
                                             )}
                                     )}
                                 </div>
                             </div>
                         </Modal.Title>
                    </Modal.Header>

                    <Modal.Body bsPrefix="preview" className="toolbar">
                         <FormBuilderWrapper style={{ pointerEvents: 'none' }}>
                              {
                                  formPreview.id === parseInt(formId) &&
                                  formPreview.fields &&
                                  formPreview.fields.map((element, idx) => (
                                        <React.Fragment key={idx}>
                                             {drawElements(element)}
                                        </React.Fragment>
                                   ))}
                         </FormBuilderWrapper>
                    </Modal.Body>

                    <Modal.Footer>
                         <Button
                              variant="primary"
                              onClick={handleClose}
                              type="button"
                              ref={form}
                         >
                              {t('buttons.close')}
                         </Button>
                    </Modal.Footer>
               </Modal>
          );
     }
);

export const PreviewFormModal = connect(
     mapStateToProps,
     mapDispatchToProps
)(_PreviewFormModal);
