import instance, {getToken, getUrl, getLocale} from './instance';
import {refreshAvatars} from './commonService';
import mime from "mime-types";

export const speakerInvite = async ({
     eventId,
     saveAsTemplate,
     invitationPayload,
     emailTemplatePayload,
}) => {
     try {
          if (saveAsTemplate) {
               await instance.post(
                    `${getUrl()}/email-template/${eventId}`,
                    emailTemplatePayload,
                    {
                         headers: {
                              Authorization:
                                   `Bearer ${getToken()}` || 'Bearer token',
                         },
                    }
               );
          }

          return await instance.post(
              `${getUrl()}/speaker/invite`,
              invitationPayload,
              {
                   headers: {
                        Authorization:
                            `Bearer ${getToken()}` || 'Bearer token',
                   },
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const getInvitationDetails = async (payload) => {
     try {
          return await instance.get(
              `${getUrl()}/speaker/invite/${payload}`
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const getInvitationDetailsBasic = async (payload) => {
     try {
          return await instance.get(
              `${getUrl()}/speaker/invite/${payload}/basic`
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const connectLoggedInSpeaker = async (payload) => {
     try {
          return await instance.post(
               `${getUrl()}/speaker/connect/${payload}`,
               null,
               {
                    headers: {
                         Authorization:
                              `Bearer ${getToken()}` || 'Bearer token',
                    },
               }
          );
     } catch (error) {
          if (error.response) {
               return error.response.data;
          }

          throw Error(error.message);
     }
};

export const getSpeakerEvent = async () => {
     try {
          return await instance.get(`${getUrl()}/speaker/events`, {
               headers: {
                    common: {
                         Authorization:
                             `Bearer ${getToken()}` || 'Bearer token',
                    },
               },
          });
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const getEventSpeakers = async (payload) => {
     try {
          if (payload.query) {
               return await instance.post(
                   `${getUrl()}/speaker/${payload.eventId}/search`,
                   { query: payload.query },
                   {
                        headers: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                        params: {
                             per_page: payload.per_page,
                             page: payload.page,
                             sort: payload.sort,
                             direction: payload.direction
                        },
                   }
               );
          }

          return await instance.get(
              `${getUrl()}/speaker/${payload.eventId}`,
              {
                   headers: {
                        common: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                   },
                   params: {
                        per_page: payload.per_page,
                        page: payload.page,
                        sort: payload.sort,
                        direction: payload.direction
                   },
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const updateSpeakerAnonymously = async (payload) => {
     const { token, locale, ...rest } = payload;

     try {
          return await instance.post(
               `${getUrl()}/speaker/${token}/anonymously`,
               rest,
              {
                   headers: {
                        'A-Locale': `${getLocale(locale)}`,
                        common: {
                             Authorization: `Bearer ${getToken()}` || 'Bearer token'
                        },
                   },
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const getSpeakerDetails = async (payload) => {
     const { eventId, id } = payload;
     try {
          return await instance.get(
               `${getUrl()}/speaker/${eventId}/${id}`,
               {
                    headers: {
                         common: {
                              Authorization:
                                   `Bearer ${getToken()}` || 'Bearer token',
                         },
                    },
               }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const resendSpeakerInvitation = async (payload) => {
     const { eventId, id, locale, ...rest } = payload;

     try {
          return await instance.post(
              `${getUrl()}/speaker/${eventId}/${id}/resend`,
              rest,
              {
                   headers: {
                        common: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token'
                        },
                   },
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const updateSpeakerDetails = async (payload) => {
     const { eventId, id, locale, ...rest } = payload;

     try {
          return await instance.post(
              `${getUrl()}/speaker/${eventId}/${id}`,
              rest,
              {
                   headers: {
                        'A-Locale': `${getLocale(locale)}`,
                        common: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token'
                        },
                   },
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const updateSpeakerForm = async (payload) => {
     const { formId, id } = payload;

     try {
          return await instance.post(
              `${getUrl()}/speaker/change/form/${id}/${formId}`,
              {},
              {
                   headers: {
                        common: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                   },
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const getFieldsSpeakerView = async ({organizationId, eventId}) => {
     try {
          return await instance.get(`${getUrl()}/form-builder/${organizationId}/${eventId}/fields/names/speakers`, {
               headers: {
                    common: {
                         Authorization:
                              `Bearer ${getToken()}` || 'Bearer token',
                    },
               },
          });
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const filterSpeakersView = async (payload) => {
     const { eventId, ...rest } = payload;

     try {
          return await instance.post(
               `${getUrl()}/speaker/${eventId}/view`,
               rest,
               {
                    headers: {
                         Authorization:
                              `Bearer ${getToken()}` || 'Bearer token',
                    },
               }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const exportSpeakers = async (payload) => {
     const { eventId, ...rest } = payload;

     try {
          const response = await instance.post(
               `${getUrl()}/speaker/${eventId}/export`,
               rest,
               {
                    headers: {
                         Authorization:
                              `Bearer ${getToken()}` || 'Bearer token',
                    },

                    responseType: 'blob',
               }
          );

          const { format } = payload;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `file.${format}`);
          document.body.appendChild(link);
          link.click();

          return response;
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const generateArchive = async (payload) => {
     const { eventId, ...rest } = payload;

     try {
          return await instance.post(
              `${getUrl()}/speaker/${eventId}/archive`,
              rest,
              {
                   headers: {
                        Authorization:
                            `Bearer ${getToken()}` || 'Bearer token',
                   },
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const downloadArchive = async (payload) => {
     const { eventId, token } = payload;

     try {
          const response = await instance.get(
              `${getUrl()}/speaker/${eventId}/archive/download/${token}`,
              {
                   headers: {
                        Authorization:
                            `Bearer ${getToken()}` || 'Bearer token',
                   },

                   responseType: 'blob',
              }
          );

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${token}.zip`);
          document.body.appendChild(link);
          link.click();

          return response;
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const downloadMediaFile = async (payload) => {
     const { file, name } = payload;

     try {
          const response = await instance.get(file,
              {
                   headers: {
                        Authorization:
                            `Bearer ${getToken()}` || 'Bearer token',
                   },

                   responseType: 'blob',
              }
          );

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', name);
          document.body.appendChild(link);
          link.click();

          return response;
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const searchSpeakersFilteredWithView = async (payload) => {
     const { eventId, ...rest } = payload;

     try {
          return await instance.post(
               `${getUrl()}/speaker/${eventId}/search/view`,
               rest,
               {
                    headers: {
                         Authorization:
                              `Bearer ${getToken()}` || 'Bearer token',
                    },
               }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const deleteSpeakerFromEvent = async ({ eventId, id }) => {
     try {
          return await instance.delete(
              `${getUrl()}/speaker/${eventId}/${id}`,
              {
                   headers: {
                        common: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                   },
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }
          throw Error(error.message);
     }
};

export const bulkDeleteSpeakerFromEvent = async ({eventId, ids}) => {
     try {
          return await instance.post(
              `${getUrl()}/speaker/${eventId}/remove/bulk`,
              ids,
              {
                   headers: {
                        common: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                   },
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }
          throw Error(error.message);
     }
};

export const speakerPhotoUpload = async ({
     field,
     photo,
     crop,
     speakerId,
     formId,
}) => {
     const formData = new FormData();
     try {
          formData.append(field, photo);
          return await instance.post(
               `${getUrl()}/media/speaker/${formId}/${speakerId}/image/${field}`,
               formData,
               {
                    headers: {
                         Authorization:
                              `Bearer ${getToken()}` || 'Bearer token',
                         accept: 'image/*',
                    },
                    params: crop
               }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const speakerPhotoRemove = async ({
      field,
      speakerId,
      formId,
 }) => {
     try {
          return await instance.delete(
              `${getUrl()}/media/speaker/${formId}/${speakerId}/image/${field}`,
              {
                   headers: {
                        Authorization:
                            `Bearer ${getToken()}` || 'Bearer token',
                        accept: 'image/*',
                   }
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const speakerFileRemove = async ({
                                              field,
                                              speakerId,
                                              formId,
                                         }) => {
     try {
          return await instance.delete(
              `${getUrl()}/media/speaker/${formId}/${speakerId}/file/${field}`,
              {
                   headers: {
                        Authorization:
                            `Bearer ${getToken()}` || 'Bearer token',
                        accept: 'image/*',
                   }
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const speakerFileUpload = async ({
     field,
     files,
     speakerId,
     formId,
}) => {
     const formData = new FormData();
     try {
          formData.append(field, files);
          return await instance.post(
               `${getUrl()}/media/speaker/${formId}/${speakerId}/file/${field}`,
               formData,
               {
                    headers: {
                         Authorization:
                              `Bearer ${getToken()}` || 'Bearer token',
                         'Content-Type': 'multipart/form-data',
                         // accept: `application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                         // text/plain, application/pdf`,
                    },
               }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const getSpeakerPhoto = async (payload) => {
     const { speakerId, field, size } = payload;
     try {
          return await instance.get(
               `${getUrl()}/media/speaker/${speakerId}/image/${field}/${size}`,
               {
                    headers: {
                         common: {
                              Authorization:
                                   `Bearer ${getToken()}` || 'Bearer token',
                         },
                    },
               }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const getSpeakerFile = async (payload) => {
     const { speakerId, field } = payload;
     try {
          return await instance.get(
               `${getUrl()}/media/speaker/${speakerId}/file/${field}`,
               {
                    headers: {
                         common: {
                              Authorization:
                                   `Bearer ${getToken()}` || 'Bearer token',
                         },
                    },
               }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const refreshAvatarsForEvents = (list) => {
     const hasEvents = list.every((s) => !!s.event);

     if (!hasEvents) return [];

     return list.map((speaker) => {
          speaker.event.photos = refreshAvatars(speaker.event.photos);

          return speaker;
     });
};

// export const refreshAvatars = (photos) => {
//      const keys = Object.keys(photos);
//      const time = moment();

//      keys.forEach((key, index) => {
//           photos[key] = photos[key] + '?t=' + time.unix();
//      });

//      return photos;
// };
