import React, {useState, useCallback, useEffect} from 'react';
import i18n from 'i18next';

import { LANGUAGE_FLAGS } from '../../../../../constants';
import {
     StyledDropdown,
     LanguageSelectWrapper,
} from '../../../../common/dropdown/Dropdown.style';
import {connect} from "react-redux";
import {getLocale, setLocale} from "../../../../../services/instance";

const mapStateToProps = (state) => ({
     languages: state.userReducer.languages,
});

export const _LanguageSelect = (props) => {
     const { languages } = props;

     const [currentTranslation, setCurrentTranslation] = useState(getLocale());
     const [dropdownShown, setDropdownShown] = useState(false);
     const [currentLanguage, setCurrentLanguage] = useState(null);

     setTimeout(() => {
          if (currentTranslation !== getLocale()) {
               setCurrentTranslation(getLocale()); // force update to display a valid language flag
          }
     }, 300);

     useEffect(() => {
          if (currentTranslation) {
               setCurrentLanguage(currentTranslation);
          }
     }, [currentTranslation]);

     const toggleDropdown = useCallback(() => {
          setDropdownShown(!dropdownShown);
     }, [dropdownShown]);

     const handleSelectLanguage = (lang) => {
          setLocale(lang);
     };

     const getCurrentLangFlag = () => {
          if (currentLanguage in LANGUAGE_FLAGS) {
               return LANGUAGE_FLAGS[currentLanguage];
          }
     };

     const getCurrentLangAlt = () => {
          const currentLang = languages.find(lang => lang.code === currentLanguage);

          if (currentLang) {
               return 'Flag ' + i18n.t('header_toolbar.languages.' + currentLang.name);
          }
     };

     const prepareOptions = () => {
          return languages.map((lang) => ({
               ...lang,
               icon: LANGUAGE_FLAGS[lang.code],
               label: i18n.t('header_toolbar.languages.' + lang.name),
               active: currentLanguage === lang.code,
          }));
     };

     return (
         <LanguageSelectWrapper onToggle={toggleDropdown} show={dropdownShown}>
              <StyledDropdown.Toggle>
                   <img
                       src={getCurrentLangFlag()}
                       alt={getCurrentLangAlt()}
                   />
              </StyledDropdown.Toggle>
              <StyledDropdown.Menu>
                   {prepareOptions().map((item) => (
                       <StyledDropdown.Item
                           active={item.active}
                           key={item.icon}
                       >
                            <div
                                onClick={() =>
                                    handleSelectLanguage(item)
                                }
                            >
                                   <span>
                                        <img
                                            src={item.icon}
                                            alt={`Flag ${i18n.t('header_toolbar.languages.' + item.name)}`}
                                        />
                                   </span>
                                 <span
                                     className="language-select-dropdown-text"
                                     key={item.name}
                                 >
                                        {item.label}
                                   </span>
                            </div>
                       </StyledDropdown.Item>
                   ))}
              </StyledDropdown.Menu>
         </LanguageSelectWrapper>
     );
};

export const LanguageSelect = connect(
    mapStateToProps,
)(_LanguageSelect);
