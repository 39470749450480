import React from 'react';
import { useHover } from '../../hooks';

export default function TwitterIcon({ color, activeColor }) {
     const [hovered, handleHover] = useHover();

     return (
         <svg
             width="25"
             height="25"
             viewBox="0 0 25 25"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
             onMouseEnter={handleHover}
             onMouseLeave={handleHover}
         >
              <path
                  fill={hovered ? activeColor : color}
                  d="M3.66211 0C1.63958 0 0 1.63958 0 3.66211V21.3379C0 23.3604 1.63958 25 3.66211 25H21.3379C23.3604 25 25 23.3604 25 21.3379V3.66211C25 1.63958 23.3604 0 21.3379 0H3.66211ZM17.813 8.6284C18.3889 8.5552 18.9414 8.40445 19.4599 8.17701C19.0712 8.75562 18.5946 9.25841 18.0273 9.661C18.0334 9.78212 18.0378 9.90412 18.0378 10.0305C18.0378 13.8071 15.1622 18.1641 9.90243 18.1641C8.28426 18.1641 6.78371 17.6979 5.51758 16.8814C5.7424 16.9101 5.96722 16.9206 6.20162 16.9206C7.53834 16.9206 8.77311 16.4622 9.74558 15.7015C8.496 15.6745 7.4451 14.8423 7.07476 13.7104C7.24817 13.7487 7.4329 13.7592 7.6159 13.7592C7.87906 13.7592 8.13263 13.7287 8.36529 13.6651C7.06605 13.3993 6.07527 12.2456 6.07527 10.8566V10.8252C6.45694 11.0352 6.90745 11.1685 7.36668 11.1772C6.60682 10.6692 6.09706 9.79171 6.09706 8.8018C6.09706 8.27461 6.24171 7.78489 6.48831 7.35703C7.90084 9.09198 10.0035 10.2309 12.3798 10.3468C12.3328 10.1394 12.3136 9.92154 12.3136 9.69498C12.3136 8.12037 13.5876 6.83594 15.17 6.83594C15.9856 6.83594 16.7333 7.18188 17.2579 7.74044C17.9079 7.61061 18.5179 7.37794 19.0704 7.04856C18.8578 7.71866 18.4055 8.27461 17.813 8.6284Z"
              />
         </svg>
     );
}
