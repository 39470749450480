/* eslint-disable */
import React, { memo, lazy, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import Breadcrumb from '../../../common/breadcrumb';
import LoadingBar from '../../../common/loading-bar';
import { ListItem } from '../../../common/collapsible-list/CollapsibleList';
import EventForm from './EventForm';
import { OrganizationDropdown } from '../../Organization/sub-components/OrganizationDropdown';
import { requestOrganizationDetails } from '../../../../redux/reducers/organizationReducer';
import { SettingsImage } from '../../../../assets/icons/metronic/settings-image';
import { GroupChatImage } from '../../../../assets/icons/metronic/group-chat-image';
import { DotImage } from '../../../../assets/icons/metronic/dot-image';
import eventImage from '../../../../assets/images/event-image.svg';

import EventsWrapper from '../Event.style';
import {getFullName} from "../../../../utils/string.utils";

const MainDrawer = lazy(() => import('../../../common/drawer'));
const CollapsibleList = lazy(() => import('../../../common/collapsible-list'));

const mapStateToProps = (state) => ({
     organization: state.organizationReducer.organization,
     currentUser: state.userReducer.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
     requestOrganizationDetails: (id) =>
          dispatch(requestOrganizationDetails(id)),
});

const _NewEvent = (props) => {
     const { t } = useTranslation();

     const { requestOrganizationDetails, organization, currentUser } = props;

     const { id: organizationId } = useParams();

     useEffect(() => {
          requestOrganizationDetails(organizationId);
     }, []);

     const links = [
          {
               to: `/organization/${organizationId}/settings`,
               text: `${organization && organization.name}`,
          },
          {
               to: `/organization/${organizationId}/events`,
               text: i18n.t('events'),
          },
          {
               to: `/organization/${organizationId}/events/new-event`,
               text: i18n.t('event.new_event'),
          },
     ];

     const eventName = '';
     const eventDate = '';
     const eventManagers = [
          {
               is_owner: true,
               value: getFullName(currentUser.firstname, currentUser.lastname),
               id: currentUser.id,
          }
     ];

     return (
          <EventsWrapper>
               <Helmet>
                    <title>
                         {t('event.new_event')} | {t('avovent')}
                    </title>
               </Helmet>
               <MainDrawer>
                    <ListItem to="/dashboard">
                         {t('breadcrumb.dashboard')}
                    </ListItem>
                    <CollapsibleList
                         listHeader={organization && organization.name}
                         listContent={
                              <>
                                   <ListItem
                                        to={`/organization/${organizationId}/events`}
                                   >
                                        <div className="collapsible-list-item">
                                             <GroupChatImage />
                                             <div className="sub-item">
                                                  {t('events')}
                                             </div>
                                        </div>
                                   </ListItem>
                                   <CollapsibleList
                                        listHeader={
                                             <div className="collapsible-list-item">
                                                  <SettingsImage />
                                                  <div className="sub-item">
                                                       {t(
                                                            'organization_settings.organization_settings'
                                                       )}
                                                  </div>
                                             </div>
                                        }
                                        listContent={
                                             <>
                                                  <ListItem
                                                       subItem
                                                       to={`/organization/${organizationId}/settings`}
                                                  >
                                                       <div className="collapsible-list-item">
                                                            <DotImage />
                                                            <div className="sub-item">
                                                                 {t(
                                                                      'organization_settings.organization_settings'
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </ListItem>
                                                  <ListItem
                                                       subItem
                                                       to={`/organization/${organizationId}/members`}
                                                  >
                                                       <div className="collapsible-list-item">
                                                            <DotImage />
                                                            <div className="sub-item">
                                                                 {t(
                                                                      'organization_settings.team_members'
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </ListItem>
                                                  <ListItem
                                                       subItem
                                                       to={`/organization/${organizationId}/email-senders`}
                                                  >
                                                       <div className="collapsible-list-item">
                                                            <DotImage />
                                                            <div className="sub-item">
                                                                 {t(
                                                                      'organization_settings.email_senders'
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </ListItem>
                                             </>
                                        }
                                   />
                              </>
                         }
                    />
               </MainDrawer>
               <div className="new-event">
                    <div className="content--border">
                         <div
                              className="container-standard"
                              style={{ display: 'flex', alignItems: 'center' }}
                         >
                              <div className="nav">
                                   <Link to="/dashboard" className="inactive">
                                        {t('breadcrumb.dashboard')}
                                   </Link>
                                   <OrganizationDropdown organization={organization}/>
                              </div>
                         </div>
                         <LoadingBar finish={!!organization} />
                    </div>
                    <div className="container-standard">
                         {organization && (
                              <Breadcrumb
                                   title={t('event.new_event')}
                                   links={links}
                              />
                         )}
                    </div>
                    <div className="container-standard">
                         <div className="user-content-body card">
                              <h3 className="card-title">
                                   {t('event.create_event')}
                              </h3>
                              <section>
                                   <div className="card-body">
                                        <EventForm
                                             btnText={t('buttons.create')}
                                             eventName={eventName}
                                             eventDate={eventDate}
                                             eventManagers={eventManagers}
                                             isPhotoDefault={true}
                                             eventPhoto={eventImage}
                                        />
                                   </div>
                              </section>
                         </div>
                    </div>
               </div>
          </EventsWrapper>
     );
};

export const NewEvent = connect(mapStateToProps, mapDispatchToProps)(_NewEvent);
