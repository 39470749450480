import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Alert from '../../../common/alert';
import illustration from '../../../../assets/images/illustration-events.svg';
import { requestOrganizationDetails } from '../../../../redux/reducers/organizationReducer';

import EventsWrapper from '../Event.style';

const mapStateToProps = (state) => ({
     router: state.router,
     organization: state.organizationReducer.organization,
});

const mapDispatchToProps = (dispatch) => ({
     requestOrganizationDetails: (id) =>
          dispatch(requestOrganizationDetails(id)),
});

export const _EventsEmpty = memo((props) => {
     const { t } = useTranslation();

     const {
          requestOrganizationDetails,
          organizationDetailsUpdateRequested,
          organizationDetailsUpdateSucceeded
     } = props;

     const { id: organizationId } = useParams();

     useEffect(() => {
          requestOrganizationDetails(organizationId);
     }, [
          organizationDetailsUpdateRequested,
          organizationDetailsUpdateSucceeded,
     ]);

     return (
          <EventsWrapper>
               <Helmet>
                    <title>
                         {t('events')} | {t('avovent')}
                    </title>
               </Helmet>
               <div className="events-empty overflow-hidden">
                    <div className="row">
                         <div className="col-md-6 col-sm-12 mb-5">
                              <div className="actions">
                                   <Alert>{t('alerts.primary.events_empty')}</Alert>
                                   <Link
                                       to={`/organization/${organizationId}/events/new-event`}
                                   >
                                        <Button variant="success">
                                             {t('event.new_event')}
                                        </Button>
                                   </Link>
                              </div>
                         </div>
                         <div className="col-md-6 col-sm-12 p-0 text-center">
                              <img
                                  src={illustration}
                                  alt={t('event.illustration_events')}
                                  style={{ maxWidth: '627px', width: '80%' }}
                              />
                         </div>
                    </div>
               </div>
          </EventsWrapper>
     );
});

export const EventsEmpty = connect(
     mapStateToProps,
     mapDispatchToProps
)(_EventsEmpty);
