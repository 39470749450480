import styled from 'styled-components';
import {
     COLORS,
     FORM_TEXT,
     FORM_PLACEHOLDER,
     FONT,
     SIZE,
} from '../../../constants/style.constants';

const OrganizationWrapper = styled.div`
     .file_upload {
          align-items: start !important;

          .col-3 {
               margin-top: 15px;
          }
     }

     .show,
     .dropdown {
          .dropdown-item {
               color: ${COLORS.DARK_BLUE};
               font-size: ${SIZE.NORMAL};

               &:active {
                    color: ${COLORS.DARK_BLUE};
                    background-color: ${COLORS.PALE_BLUE};
               }
          }
     }

     .select-dropdown-toggle {
          display: flex;
          align-items: center;
          background-color: transparent;
          text-decoration: none;
          border-radius: 4px;
          border: 1px solid #ced4da;
          width: 100%;
     }

     .input-group {
          background-color: transparent;
          z-index: 1;

          .show,
          .dropdown {
               box-shadow: 0 0 0 2px #fff;
          }

          input,
          a {
               border-radius: 4px !important;
               background-color: transparent;
               z-index: 1;
          }

          .suffix {
               border-radius: 4px !important;
               display: flex;
               align-items: center;
               position: absolute;
               top: 1px;
               right: 15px;
               height: 39px;
               /* width: 20px; */
               z-index: -1;

               svg,
               .la-calendar-check:before {
                    color: ${COLORS.PLACEHOLDER_GREY};
               }
          }
     }

     form {
          max-width: 640px;
          margin: 60px auto;
          ${FORM_TEXT};
          input::placeholder {
               ${FORM_PLACEHOLDER};
               color: ${COLORS.PLACEHOLDER_GREY};
          }

          .col-3 {
               label {
                    margin: 0;
               }
          }

          .row {
               display: flex;
               align-items: center;
          }

          .form-control {
               border-radius: 4px !important;
               padding: 12px 42px 12px 15px !important;
               height: 41px;
               font-weight: ${FONT.REGULAR} !important;
               color: ${COLORS.TEXT_GREY} !important;
               font-size: ${SIZE.NORMAL};
          }

          .body-divider {
               margin: 20px 0 25px;
          }

          .form-actions {
               display: flex;
               flex-direction: row-reverse;
          }

          .btn {
               margin: 0;
          }
     }
     /* Email senders empty style */
     .senders-empty {
          padding: 39px 55px 319px;
     }

     @media only screen and (max-width: 1320px) {
          .senders-empty {
               padding: 0;
          }
     }

     @media only screen and (max-width: 1023px) {
          p,
          .form-control,
          input::placeholder {
               font-size: ${SIZE.TINY} !important;
          }
     }
`;

export default OrganizationWrapper;
