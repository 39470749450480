import styled from 'styled-components';
import { COLORS, FONT, SIZE } from '../../../../constants/style.constants';

const NotFoundWrapper = styled.div`
     display: flex;
     flex-direction: column;
     align-items: center;
     padding-bottom: 128px;
     padding-top: 128px;

     .logo {
          position: absolute;
          top: 45px;
          left: 50px;
     }

     /* .alert {
          text-align: center;
          min-width: 50vw;
          color: ${COLORS.BLUE};
          font-weight: ${FONT.MEDIUM};
          font-size: ${SIZE.NORMAL};
          line-height: 21px;
          padding: 13px 118px;
          margin-bottom: 20px;
     } */

     h1 {
          padding-bottom: 30px;
     }

     .btn {
          min-width: 151px;
          min-height: 37px;
          margin-bottom: 60px;

          &:last-child {
               margin-left: 15px;
          }
     }

     @media only screen and (max-width: 1320px) {
          align-items: unset;
     }
`;

export default NotFoundWrapper;
