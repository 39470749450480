import styled from 'styled-components';
import {
     COLORS,
     THEME,
     FONT,
     FLEX_ROW_CENTER,
     SIZE,
} from '../../../../constants/style.constants';
import { isAuthLocation } from '../../../../utils/style.utils';

const FooterWrapper = styled.footer`
     width: 100%;
     .bg-theme-dark {
          background-color: ${THEME.DARK};
     }

     .bg-theme-darker {
          display: ${() => isAuthLocation() && 'none'};
          background-color: ${THEME.DARKER};
     }

     .bottom-nav-wrapper {
          padding: 56px 0;
          display: flex;
          align-items: start;

          h3,
          .text,
          a {
               font-weight: ${FONT.REGULAR};
          }

          h3 {
               color: ${COLORS.WHITE};
               font-size: 19.6px;
               line-height: 26px;
               text-transform: capitalize;
               margin-bottom: 7px;
               padding-bottom: 14px;
          }

          .text,
          a {
               color: ${COLORS.LINKS_GREY};
               font-size: ${SIZE.NORMAL};
               line-height: 21px;
          }

          .column {
               max-width: 376px;
               padding-right: 60px;
          }

          .icon {
               height: 25px;
          }

          .quick-links,
          .bottomnav-links {
               display: flex;
          }

          .bottomnav-links {
               flex-direction: column;
               padding-right: 30px;

               a {
                    text-decoration: none;
               }

               a:hover {
                    color: ${COLORS.WHITE};
               }

               a:not(:last-child) {
                    padding-bottom: 10px;
               }
          }
     }

     .subfooter-wrapper {
          background-color: ${THEME.DARKER};
          padding: 28px 0;
          ${FLEX_ROW_CENTER};
          font-weight: ${FONT.REGULAR};
          font-size: ${SIZE.NORMAL};
          line-height: 17px;

          a:hover {
               color: ${COLORS.WHITE};
               text-decoration: none;
          }

          .subfooter-logo {
               color: ${COLORS.LINKS_GREY};
          }

          @media only screen and (max-width: 580px) and (min-width: 320px) {
               flex-direction: column-reverse;
          }
     }

     @media only screen and (max-width: 1025px) and (min-width: 320px) {
          h3 {
               font-size: ${SIZE.LARGE} !important;
               margin-bottom: 6px !important;
               padding-bottom: 12px !important;
          }

          .text,
          .subfooter-logo,
          a {
               font-size: ${SIZE.TINY} !important;
          }

          .bottom-nav-wrapper {
               position: relative;
               flex-direction: column;
               z-index: 99;
          }

          .column {
               min-width: 100%;
               padding-bottom: 20px !important;

               .bottomnav-links {
                    width: 100%;
               }
          }
     }

     @media only screen and (max-width: 1366px) and (min-width: 320px) {
          .bg-theme-darker {
               display: block;
          }
     }
`;

export default FooterWrapper;
