import React from "react";
import { useDragLayer } from 'react-dnd';
import {ElementTypes} from '../ElementTypes';
import i18n from "i18next";
import * as Elements from "./Builder";

const layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
};

function getItemStyles(initialOffset, currentOffset) {
    if (!initialOffset || !currentOffset) {
        return {
            display: 'none',
        };
    }
    let { x, y } = currentOffset;
    const transform = `translate(${x}px, ${y}px)`;
    return {
        transform,
        WebkitTransform: transform,
        width: '858px',
        opacity: 0.7
    };
}

const displayLayout = (layout) => {
    switch (layout) {
        case i18n.t('form_builder.one_column'):
            return '100%';
            break;
        case i18n.t('form_builder.two_column'):
            return '1fr 1fr';
            break;
        case i18n.t('form_builder.three_column'):
            return '1fr 1fr 1fr';
            break;
        default:
            return '100%';
    }
};

export const DragLayer = ({
      selectedElement,
      isSelectedItem,
      handleElementSelect,
      handleRemoveElement,
      moveElement,
      handleDrawElements,
}) => {
    const { itemType, isDragging, item, initialOffset, currentOffset, } = useDragLayer((monitor) => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    }));

    function renderItem() {
        switch (itemType) {
            case ElementTypes.TITLE:
                return (<Elements.Title
                    key={item.id}
                    index={item.index}
                    id={item.id}
                    name={i18n.t('form_builder.elements.title')}
                    type={ElementTypes.TITLE}
                    value={item.value}
                    inBuilder={true}
                    forceDisplay={true}
                    handleElementSelect={handleElementSelect}
                    selectedElement={selectedElement}
                    isSelectedItem={isSelectedItem}
                    handleRemoveElement={handleRemoveElement}
                    setActiveSpan={false}
                    activeSpan={false}
                    moveElement={moveElement}
                    handleDrawElements={handleDrawElements}
                />);
            case ElementTypes.PARAGRAPH:
                return (
                    <Elements.Paragraph
                        key={item.id}
                        index={item.index}
                        id={item.id}
                        name={i18n.t('form_builder.elements.paragraph')}
                        type={ElementTypes.PARAGRAPH}
                        text={item.text}
                        inBuilder={true}
                        forceDisplay={true}
                        handleElementSelect={handleElementSelect}
                        selectedElement={selectedElement}
                        isSelectedItem={isSelectedItem}
                        handleRemoveElement={handleRemoveElement}
                        setActiveSpan={false}
                        activeSpan={false}
                        moveElement={moveElement}
                        handleDrawElements={handleDrawElements}
                    />
                );
            case ElementTypes.TEXT_FIELD:
                return (
                    <Elements.TextField
                        key={item.id}
                        id={item.id}
                        name={i18n.t('form_builder.elements.text_field')}
                        type={ElementTypes.TEXT_FIELD}
                        label={item.label}
                        elementCode={item.elementCode}
                        placeholder={item.placeholder}
                        value={item.value}
                        additionalInformation={item.additionalInformation}
                        required={item.required}
                        elementType={item.elementType}
                        readonly={item.readonly}
                        index={item.index}
                        inBuilder={true}
                        forceDisplay={true}
                        handleElementSelect={handleElementSelect}
                        selectedElement={selectedElement}
                        isSelectedItem={isSelectedItem}
                        handleRemoveElement={handleRemoveElement}
                        setActiveSpan={false}
                        activeSpan={false}
                        moveElement={moveElement}
                        handleDrawElements={handleDrawElements}
                    />
                );
            case ElementTypes.TEXT_AREA:
                return (
                    <Elements.TextArea
                        key={item.id}
                        id={item.id}
                        name={i18n.t('form_builder.elements.text_area')}
                        type={ElementTypes.TEXT_AREA}
                        label={item.label}
                        elementCode={item.elementCode}
                        placeholder={item.placeholder}
                        value={item.value}
                        additionalInformation={item.additionalInformation}
                        required={item.required}
                        index={item.index}
                        inBuilder={true}
                        forceDisplay={true}
                        handleElementSelect={handleElementSelect}
                        selectedElement={selectedElement}
                        isSelectedItem={isSelectedItem}
                        handleRemoveElement={handleRemoveElement}
                        setActiveSpan={false}
                        activeSpan={false}
                        moveElement={moveElement}
                        handleDrawElements={handleDrawElements}
                    />
                );
            case ElementTypes.CHECKBOX:
                return (
                    <Elements.Checkbox
                        key={item.id}
                        id={item.id}
                        name={i18n.t('form_builder.elements.checkbox')}
                        type={ElementTypes.CHECKBOX}
                        label={item.label}
                        elementCode={item.elementCode}
                        layout={item.layout}
                        multipleSelections={item.multipleSelections}
                        choices={item.choices}
                        additionalInformation={item.additionalInformation}
                        required={item.required}
                        index={item.index}
                        inBuilder={true}
                        forceDisplay={true}
                        displayLayout={displayLayout}
                        handleElementSelect={handleElementSelect}
                        selectedElement={selectedElement}
                        isSelectedItem={isSelectedItem}
                        handleRemoveElement={handleRemoveElement}
                        setActiveSpan={false}
                        activeSpan={false}
                        moveElement={moveElement}
                        handleDrawElements={handleDrawElements}
                    />
                );
            case ElementTypes.RADIO:
                return (
                    <Elements.RadioSelection
                        key={item.id}
                        id={item.id}
                        name={i18n.t('form_builder.elements.radio')}
                        type={ElementTypes.RADIO}
                        label={item.label}
                        elementCode={item.elementCode}
                        layout={item.layout}
                        choices={item.choices}
                        additionalInformation={item.additionalInformation}
                        required={item.required}
                        inBuilder={true}
                        forceDisplay={true}
                        index={item.index}
                        displayLayout={displayLayout}
                        handleElementSelect={handleElementSelect}
                        selectedElement={selectedElement}
                        isSelectedItem={isSelectedItem}
                        handleRemoveElement={handleRemoveElement}
                        setActiveSpan={false}
                        activeSpan={false}
                        moveElement={moveElement}
                        handleDrawElements={handleDrawElements}
                    />
                );
            case ElementTypes.IMAGE:
                return (
                    <Elements.Image
                        key={item.id}
                        id={item.id}
                        name={i18n.t('form_builder.elements.image')}
                        type={ElementTypes.IMAGE}
                        label={item.label}
                        elementCode={item.elementCode}
                        elementType={item.elementType}
                        additionalInformation={item.additionalInformation}
                        required={item.required}
                        inBuilder={true}
                        forceDisplay={true}
                        index={item.index}
                        handleElementSelect={handleElementSelect}
                        selectedElement={selectedElement}
                        isSelectedItem={isSelectedItem}
                        handleRemoveElement={handleRemoveElement}
                        setActiveSpan={false}
                        activeSpan={false}
                        moveElement={moveElement}
                        handleDrawElements={handleDrawElements}
                    />
                );
            case ElementTypes.FILE_UPLOAD:
                return (
                    <Elements.FileUpload
                        id={item.id}
                        index={item.index}
                        key={item.id}
                        name={i18n.t('form_builder.elements.file_upload')}
                        type={ElementTypes.FILE_UPLOAD}
                        label={item.label}
                        elementCode={item.elementCode}
                        elementType={item.elementType}
                        additionalInformation={item.additionalInformation}
                        required={item.required}
                        inBuilder={true}
                        forceDisplay={true}
                        handleElementSelect={handleElementSelect}
                        selectedElement={selectedElement}
                        isSelectedItem={isSelectedItem}
                        handleRemoveElement={handleRemoveElement}
                        setActiveSpan={false}
                        activeSpan={false}
                        moveElement={moveElement}
                        handleDrawElements={handleDrawElements}
                    />
                );
            case ElementTypes.DATE:
                return (
                    <Elements.Date
                        id={item.id}
                        index={item.index}
                        key={item.id}
                        name={i18n.t('form_builder.elements.date')}
                        type={ElementTypes.DATE}
                        label={item.label}
                        elementCode={item.elementCode}
                        placeholder={item.placeholder}
                        additionalInformation={item.additionalInformation}
                        required={item.required}
                        inBuilder={true}
                        forceDisplay={true}
                        handleElementSelect={handleElementSelect}
                        selectedElement={selectedElement}
                        isSelectedItem={isSelectedItem}
                        handleRemoveElement={handleRemoveElement}
                        setActiveSpan={false}
                        activeSpan={false}
                        moveElement={moveElement}
                        handleDrawElements={handleDrawElements}
                    />
                );
            case ElementTypes.TIME:
                return (
                    <Elements.Time
                        id={item.id}
                        index={item.index}
                        key={item.id}
                        name={i18n.t('form_builder.elements.time')}
                        type={ElementTypes.TIME}
                        label={item.label}
                        elementCode={item.elementCode}
                        timeFormat={item.timeFormat}
                        placeholder={item.placeholder}
                        additionalInformation={item.additionalInformation}
                        required={item.required}
                        inBuilder={true}
                        forceDisplay={true}
                        handleElementSelect={handleElementSelect}
                        selectedElement={selectedElement}
                        isSelectedItem={isSelectedItem}
                        handleRemoveElement={handleRemoveElement}
                        setActiveSpan={false}
                        activeSpan={false}
                        moveElement={moveElement}
                        handleDrawElements={handleDrawElements}
                    />
                );
            case ElementTypes.DROPDOWN:
                return (
                    <Elements.DropdownElement
                        id={item.id}
                        index={item.index}
                        key={item.id}
                        name={i18n.t('form_builder.elements.dropdown')}
                        type={ElementTypes.DROPDOWN}
                        label={item.label}
                        elementCode={item.elementCode}
                        choices={item.choices}
                        additionalInformation={item.additionalInformation}
                        required={item.required}
                        inBuilder={true}
                        forceDisplay={true}
                        handleElementSelect={handleElementSelect}
                        selectedElement={selectedElement}
                        isSelectedItem={isSelectedItem}
                        handleRemoveElement={handleRemoveElement}
                        setActiveSpan={false}
                        activeSpan={false}
                        moveElement={moveElement}
                        handleDrawElements={handleDrawElements}
                    />
                );
            case ElementTypes.DIVIDER:
                return (
                    <Elements.Divider
                        key={item.id}
                        index={item.index}
                        id={item.id}
                        name={i18n.t('form_builder.elements.divider')}
                        type={ElementTypes.DIVIDER}
                        title={item.title}
                        text={item.text}
                        invert={item.invert}
                        inBuilder={true}
                        forceDisplay={true}
                        handleElementSelect={handleElementSelect}
                        selectedElement={selectedElement}
                        isSelectedItem={isSelectedItem}
                        handleRemoveElement={handleRemoveElement}
                        setActiveSpan={false}
                        activeSpan={false}
                        moveElement={moveElement}
                        handleDrawElements={handleDrawElements}
                    />
                );
            default:
                return null;
        }
    }

    if (!isDragging) {
        return null;
    }

    return (
        <div style={layerStyles}>
            <div style={getItemStyles(initialOffset, currentOffset)}>
                {renderItem()}
            </div>
        </div>
    );
};
