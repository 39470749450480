import instance, {getToken, getUrl} from './instance';

export const getEventReminders = async (payload) => {
     try {
          if (payload.query) {
               return await instance.post(
                   `${getUrl()}/reminder/${payload.eventId}/search`,
                   { query: payload.query },
                   {
                        headers: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                        params: {
                             per_page: payload.per_page,
                             page: payload.page,
                             sort: payload.sort,
                             direction: payload.direction
                        }
                   }
               );
          }

          return await instance.get(
              `${getUrl()}/reminder/${payload.eventId}`,
              {
                   headers: {
                        common: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                   },
                   params: {
                        per_page: payload.per_page,
                        page: payload.page,
                        sort: payload.sort,
                        direction: payload.direction
                   },
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const createReminder = async ({
     eventId,
     saveAsTemplate,
     reminderPayload,
     reminderTemplatePayload,
}) => {
     try {
          if (saveAsTemplate) {
               await instance.post(
                    `${getUrl()}/reminder/${eventId}/templates`,
                    reminderTemplatePayload,
                    {
                         headers: {
                              Authorization:
                                   `Bearer ${getToken()}` || 'Bearer token',
                         },
                    }
               );
          }

          return await instance.post(
              `${getUrl()}/reminder/${eventId}`,
              reminderPayload,
              {
                   headers: {
                        Authorization:
                            `Bearer ${getToken()}` || 'Bearer token',
                   },
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const getReminderDetails = async (payload) => {
     const { eventId, reminderId } = payload;
     try {
          const response = await instance.get(
               `${getUrl()}/reminder/${eventId}/${reminderId}`,
               {
                    headers: {
                         common: {
                              Authorization:
                                   `Bearer ${getToken()}` || 'Bearer token',
                         },
                    },
               }
          );

          return response;
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const copyReminder = async ({
     eventId,
     reminderId,
     saveAsTemplate,
     reminderPayload,
     reminderTemplatePayload,
}) => {
     try {
          if (saveAsTemplate) {
               await instance.post(
                    `${getUrl()}/reminder/${eventId}/templates`,
                    reminderTemplatePayload,
                    {
                         headers: {
                              Authorization:
                                   `Bearer ${getToken()}` || 'Bearer token',
                         },
                    }
               );
          }

          const response = await instance.post(
               `${getUrl()}/reminder/${eventId}`,
               reminderPayload,
               {
                    headers: {
                         Authorization:
                              `Bearer ${getToken()}` || 'Bearer token',
                    },
               }
          );

          return response;
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const removeReminder = async (payload) => {
     const { eventId, reminderId } = payload;

     try {
          return await instance.delete(
               `${getUrl()}/reminder/${eventId}/${reminderId}`,
               {
                    headers: {
                         Authorization:
                              `Bearer ${getToken()}` || 'Bearer token',
                    },
               }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const getReminderPreview = async (payload) => {
     const { eventId, reminderId, languageId } = payload;

     try {
          const response = await instance.get(
               `${getUrl()}/reminder/${eventId}/${reminderId}/${languageId}/preview`,
               {
                    headers: {
                         common: {
                              Authorization:
                                   `Bearer ${getToken()}` || 'Bearer token',
                         },
                    },
               }
          );

          return response;
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const getReminderTemplates = async (payload) => {
     try {
          const response = await instance.get(
               `${getUrl()}/reminder/${payload}/templates`,
               {
                    headers: {
                         common: {
                              Authorization:
                                   `Bearer ${getToken()}` || 'Bearer token',
                         },
                    },
               }
          );

          return response;
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const createReminderTemplate = async (payload) => {
     const { eventId, ...rest } = payload;

     try {
          return await instance.post(
               `${getUrl()}/reminder/${eventId}/templates`,
               { ...rest },
               {
                    headers: {
                         Authorization:
                              `Bearer ${getToken()}` || 'Bearer token',
                    },
               }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const removeReminderTemplate = async (payload) => {
     const { eventId, templateId } = payload;

     try {
          return await instance.delete(
               `${getUrl()}/reminder/${eventId}/templates/${templateId}`,
               {
                    headers: {
                         Authorization:
                              `Bearer ${getToken()}` || 'Bearer token',
                    },
               }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};
