import React from 'react';
import { useSelector } from 'react-redux';
import { MainDrawer as Drawer } from './MainDrawer';

export default function MainDrawer({ children, ...restProps }) {
     const { mainDrawerShown } = useSelector((state) => state.ui);

     return mainDrawerShown ? (
          <Drawer {...restProps}>{children}</Drawer>
     ) : (
          <></>
     );
}
