import * as ACTIONS from '../actions';

export const organizationClearMessages = () => ({
     type: ACTIONS.ORGANIZATION_CLEAR_MESSAGES,
});

export const requestUserOrganization = () => ({
     type: ACTIONS.USER_ORGANIZATION_REQUEST,
});

export const successUserOrganization = (payload) => ({
     type: ACTIONS.USER_ORGANIZATION_SUCCESS,
     payload,
});

export const failUserOrganization = (error) => ({
     type: ACTIONS.USER_ORGANIZATION_FAILURE,
     error,
});

export const requestOrganizationDetails = (payload) => ({
     type: ACTIONS.ORGANIZATION_DETAILS_REQUEST,
     payload,
});

export const successOrganizationDetails = (payload) => ({
     type: ACTIONS.ORGANIZATION_DETAILS_SUCCESS,
     payload,
});

export const failOrganizationDetails = (error) => ({
     type: ACTIONS.ORGANIZATION_DETAILS_FAILURE,
     error,
});

export const requestOrganizationCreation = (payload) => ({
     type: ACTIONS.ORGANIZATION_CREATION_REQUEST,
     payload,
});

export const successOrganizationCreation = (payload) => ({
     type: ACTIONS.ORGANIZATION_CREATION_SUCCESS,
     payload,
});

export const failOrganizationCreation = (error) => ({
     type: ACTIONS.ORGANIZATION_CREATION_FAILURE,
     error,
});

export const requestOrganizationDetailsUpdate = (payload) => ({
     type: ACTIONS.UPDATE_ORGANIZATION_DETAILS_REQUEST,
     payload,
});

export const successOrganizationDetailsUpdate = (payload) => ({
     type: ACTIONS.UPDATE_ORGANIZATION_DETAILS_SUCCESS,
     payload,
});

export const failOrganizationDetailsUpdate = (error) => ({
     type: ACTIONS.UPDATE_ORGANIZATION_DETAILS_FAILURE,
     error,
});

export const requestOrganizationPhotoUpload = (payload) => ({
     type: ACTIONS.ORGANIZATION_PHOTO_UPLOAD_REQUEST,
     payload,
});

export const successOrganizationPhotoUpload = (payload) => ({
     type: ACTIONS.ORGANIZATION_PHOTO_UPLOAD_SUCCESS,
     payload,
});

export const failOrganizationPhotoUpload = (error) => ({
     type: ACTIONS.ORGANIZATION_PHOTO_UPLOAD_FAILURE,
     error,
});

export const requestOrganizationMembers = (payload) => ({
     type: ACTIONS.ORGANIZATION_MEMBERS_REQUEST,
     payload,
});

export const successOrganizationMembers = (payload) => ({
     type: ACTIONS.ORGANIZATION_MEMBERS_SUCCESS,
     payload,
});

export const failOrganizationMembers = (error) => ({
     type: ACTIONS.ORGANIZATION_MEMBERS_FAILURE,
     error,
});

export const requestOrganizationMembersInvitations = (payload) => ({
     type: ACTIONS.ORGANIZATION_MEMBERS_INVITATIONS_REQUEST,
     payload,
});

export const successOrganizationMembersInvitations = (payload) => ({
     type: ACTIONS.ORGANIZATION_MEMBERS_INVITATIONS_SUCCESS,
     payload,
});

export const failOrganizationMembersInvitations = (error) => ({
     type: ACTIONS.ORGANIZATION_MEMBERS_INVITATIONS_FAILURE,
     error,
});

export const getMembersInvitationsPageDetails = (payload) => ({
     type: ACTIONS.GET_MEMBERS_INVITATIONS_PAGE_DETAILS,
     payload,
});

export const requestOrganizationMembersInvitationsResend = (payload) => ({
     type: ACTIONS.ORGANIZATION_MEMBERS_INVITATIONS_RESEND_REQUEST,
     payload,
});

export const successOrganizationMembersInvitationsResend = (payload) => ({
     type: ACTIONS.ORGANIZATION_MEMBERS_INVITATIONS_RESEND_SUCCESS,
     payload,
});

export const failOrganizationMembersInvitationsResend = (error) => ({
     type: ACTIONS.ORGANIZATION_MEMBERS_INVITATIONS_RESEND_FAILURE,
     error,
});

export const requestOrganizationMembersInvitationsRemove = (payload) => ({
     type: ACTIONS.ORGANIZATION_MEMBERS_INVITATIONS_REMOVE_REQUEST,
     payload,
});

export const successOrganizationMembersInvitationsRemove = (payload) => ({
     type: ACTIONS.ORGANIZATION_MEMBERS_INVITATIONS_REMOVE_SUCCESS,
     payload,
});

export const failOrganizationMembersInvitationsRemove = (error) => ({
     type: ACTIONS.ORGANIZATION_MEMBERS_INVITATIONS_REMOVE_FAILURE,
     error,
});

export const getMembersPageDetails = (payload) => ({
     type: ACTIONS.GET_MEMBERS_PAGE_DETAILS,
     payload,
});

export const requestUpdateMemberScope = (payload) => ({
     type: ACTIONS.UPDATE_MEMBER_SCOPE_REQUEST,
     payload,
});

export const successUpdateMemberScope = (payload) => ({
     type: ACTIONS.UPDATE_MEMBER_SCOPE_SUCCESS,
     payload,
});

export const failUpdateMemberScope = (error) => ({
     type: ACTIONS.UPDATE_MEMBER_SCOPE_FAILURE,
     error,
});

export const requestMembersInvite = (payload) => ({
     type: ACTIONS.INVITE_MEMBERS_REQUEST,
     payload,
});

export const successMembersInvite = (payload) => ({
     type: ACTIONS.INVITE_MEMBERS_SUCCESS,
     payload,
});

export const failMembersInvite = (error) => ({
     type: ACTIONS.INVITE_MEMBERS_FAILURE,
     error,
});

export const requestMembersSearch = (payload) => ({
     type: ACTIONS.SEARCH_MEMBERS_REQUEST,
     payload,
});

export const successMembersSearch = (payload) => ({
     type: ACTIONS.SEARCH_MEMBERS_SUCCESS,
     payload,
});

export const failMembersSearch = (error) => ({
     type: ACTIONS.SEARCH_MEMBERS_FAILURE,
     error,
});

export const requestRemoveMember = (payload) => ({
     type: ACTIONS.REMOVE_MEMBER_REQUEST,
     payload,
});

export const successRemoveMember = (payload) => ({
     type: ACTIONS.REMOVE_MEMBER_SUCCESS,
     payload,
});

export const failRemoveMember = (error) => ({
     type: ACTIONS.REMOVE_MEMBER_FAILURE,
     error,
});

export const requestOrganizationEmailSenders = (payload) => ({
     type: ACTIONS.ORGANIZATION_EMAIL_SENDERS_REQUEST,
     payload,
});

export const successOrganizationEmailSenders = (payload) => ({
     type: ACTIONS.ORGANIZATION_EMAIL_SENDERS_SUCCESS,
     payload,
});

export const failOrganizationEmailSenders = (error) => ({
     type: ACTIONS.ORGANIZATION_EMAIL_SENDERS_FAILURE,
     error,
});

export const requestOrganizationEmailSendersAll = (payload) => ({
     type: ACTIONS.ORGANIZATION_EMAIL_SENDERS_ALL_REQUEST,
     payload,
});

export const successOrganizationEmailSendersAll = (payload) => ({
     type: ACTIONS.ORGANIZATION_EMAIL_SENDERS_ALL_SUCCESS,
     payload,
});

export const failOrganizationEmailSendersAll = (error) => ({
     type: ACTIONS.ORGANIZATION_EMAIL_SENDERS_ALL_FAILURE,
     error,
});

export const getEmailSendersPageDetails = (payload) => ({
     type: ACTIONS.GET_EMAIL_SENDERS_PAGE_DETAILS,
     payload,
});

export const requestCreateEmailSender = (payload) => ({
     type: ACTIONS.CREATE_EMAIL_SENDER_REQUEST,
     payload,
});

export const successCreateEmailSender = (payload) => ({
     type: ACTIONS.CREATE_EMAIL_SENDER_SUCCESS,
     payload,
});

export const failCreateEmailSender = (error) => ({
     type: ACTIONS.CREATE_EMAIL_SENDER_FAILURE,
     error,
});

export const requestVerifyEmailSender = (payload) => ({
     type: ACTIONS.VERIFY_EMAIL_SENDER_REQUEST,
     payload,
});

export const successVerifyEmailSender = (payload) => ({
     type: ACTIONS.VERIFY_EMAIL_SENDER_SUCCESS,
     payload,
});

export const failVerifyEmailSender = (error) => ({
     type: ACTIONS.VERIFY_EMAIL_SENDER_FAILURE,
     error,
});

export const requestVerifyResendEmailSender = (payload) => ({
     type: ACTIONS.VERIFY_RESEND_EMAIL_SENDER_REQUEST,
     payload,
});

export const successVerifyResendEmailSender = (payload) => ({
     type: ACTIONS.VERIFY_RESEND_EMAIL_SENDER_SUCCESS,
     payload,
});

export const failVerifyResendEmailSender = (error) => ({
     type: ACTIONS.VERIFY_RESEND_EMAIL_SENDER_FAILURE,
     error,
});

export const requestRemoveEmailSender = (payload) => ({
     type: ACTIONS.REMOVE_EMAIL_SENDER_REQUEST,
     payload,
});

export const successRemoveEmailSender = (payload) => ({
     type: ACTIONS.REMOVE_EMAIL_SENDER_SUCCESS,
     payload,
});

export const failRemoveEmailSender = (error) => ({
     type: ACTIONS.REMOVE_EMAIL_SENDER_FAILURE,
     error,
});

export const requestEmailSendersSearch = (payload) => ({
     type: ACTIONS.SEARCH_EMAIL_SENDERS_REQUEST,
     payload,
});

export const successEmailSendersSearch = (payload) => ({
     type: ACTIONS.SEARCH_EMAIL_SENDERS_SUCCESS,
     payload,
});

export const failEmailSendersSearch = (error) => ({
     type: ACTIONS.SEARCH_EMAIL_SENDERS_FAILURE,
     error,
});

export const requestDisplayJoinInvitation = (payload) => ({
     type: ACTIONS.DISPLAY_JOIN_INVITATION_REQUEST,
     payload,
});

export const successDisplayJoinInvitation = (payload) => {
     return { type: ACTIONS.DISPLAY_JOIN_INVITATION_SUCCESS, payload };
};

export const failDisplayJoinInvitation = (error) => ({
     type: ACTIONS.DISPLAY_JOIN_INVITATION_FAILURE,
     error,
});

export const requestJoinOrganization = (payload) => ({
     type: ACTIONS.JOIN_ORGANIZATION_REQUEST,
     payload,
});

export const successJoinOrganization = (payload) => {
     return { type: ACTIONS.JOIN_ORGANIZATION_SUCCESS, payload };
};

export const failJoinOrganization = (error) => ({
     type: ACTIONS.JOIN_ORGANIZATION_FAILURE,
     error,
});

export const refreshOrganizationAvatars = (payload) => ({
     type: ACTIONS.REFRESH_ORGANIZATION_AVATAR,
     payload,
});

export const requestRemoveOrganizationAvatar = (payload) => ({
     type: ACTIONS.REMOVE_ORGANIZATION_AVATAR_REQUEST,
     payload,
});

export const successRemoveOrganizationAvatar = (payload) => ({
     type: ACTIONS.REMOVE_ORGANIZATION_AVATAR_SUCCESS,
     payload,
});

export const failRemoveOrganizationAvatar = (error) => ({
     type: ACTIONS.REMOVE_ORGANIZATION_AVATAR_FAILURE,
     error,
});

export const INITIAL_STATE = {
     userOrganizationRequested: false,
     userOrganizationSucceeded: false,
     userOrganizationFailed: false,
     organizationDetailsRequested: false,
     organizationDetailsSucceeded: false,
     organizationDetailsFailed: false,
     organizationCreationRequested: false,
     organizationCreationSucceeded: false,
     organizationCreationFailed: false,
     organizationDetailsUpdateRequested: false,
     organizationDetailsUpdateSucceeded: false,
     organizationDetailsUpdateFailed: false,
     organizationMembersRequested: false,
     membersPageDetails: {},
     organizationMembersSucceeded: false,
     organizationMembersFailed: false,
     membersInvitationsPageDetails: {},
     organizationMembersInvitationsRequested: false,
     organizationMembersInvitationSucceeded: false,
     organizationMembersInvitationFailed: false,
     organizationMembersInvitationsResendRequested: false,
     organizationMembersInvitationResendSucceeded: false,
     organizationMembersInvitationResendFailed: false,
     organizationMembersInvitationsRemoveRequested: false,
     organizationMembersInvitationRemoveSucceeded: false,
     organizationMembersInvitationRemoveFailed: false,
     updateMemberScopeRequested: false,
     updateMemberScopeSucceeded: false,
     updateMemberScopeFailed: false,
     membersInviteRequested: false,
     membersInviteSucceeded: false,
     membersInviteFailed: false,
     membersSearchRequested: false,
     membersSearchSucceeded: false,
     membersSearchFailed: false,
     removeMemberRequested: false,
     removeMemberSucceeded: false,
     removeMemberFailed: false,
     organizationEmailSendersRequested: false,
     emailSendersPageDetails: {},
     organizationEmailSendersSucceeded: false,
     organizationEmailSendersFailed: false,
     createEmailSenderRequested: false,
     createEmailSenderSucceeded: false,
     createEmailSenderFailed: false,
     verifyEmailSenderRequested: false,
     verifyEmailSenderSucceeded: false,
     verifyEmailSenderFailed: false,
     verifyResendEmailSenderRequested: false,
     verifyResendEmailSenderSucceeded: false,
     verifyResendEmailSenderFailed: false,
     removeEmailSenderRequested: false,
     removeEmailSenderSucceeded: false,
     removeEmailSenderFailed: false,
     emailSendersSearchRequested: false,
     emailSendersSearchSucceeded: false,
     emailSendersSearchFailed: false,
     displayJoinInvitationRequested: false,
     displayJoinInvitationSucceeded: false,
     displayJoinInvitationFailed: false,
     joinOrganizationRequested: false,
     joinOrganizationSucceeded: false,
     joinOrganizationFailed: false,
     organizationPhotoUploadRequested: false,
     organizationPhotoUploadSucceeded: false,
     organizationPhotoUploadFailed: false,
     refreshOrganizationAvatar: false,
     removeOrganizationAvatarRequested: false,
     removeOrganizationAvatarSucceeded: false,
     removeOrganizationAvatarFailed: false,

     organizationList: null,
     organization: null,
     organizationInvite: null,
     memberSearchResult: null,
     message: null,
     membersList: null,
     membersInvitationsList: null,
     emailSendersList: null,
     emailSendersSearchResult: null,
     error: null,
};

export default function organizationReducer(state = INITIAL_STATE, action) {
     switch (action.type) {
          case ACTIONS.ORGANIZATION_CLEAR_MESSAGES:
               return {
                    ...state,
                    message: null,
                    error: null,
               };
          case ACTIONS.USER_ORGANIZATION_REQUEST:
               return {
                    ...state,
                    userOrganizationRequested: true,
                    userOrganizationSucceeded: false,
                    userOrganizationFailed: false,
               };
          case ACTIONS.USER_ORGANIZATION_SUCCESS:
               return {
                    ...state,
                    userOrganizationRequested: false,
                    userOrganizationSucceeded: true,
                    userOrganizationFailed: false,
                    organizationList: action.payload,
                    error: null,
               };
          case ACTIONS.USER_ORGANIZATION_FAILURE:
               return {
                    ...state,
                    userOrganizationRequested: false,
                    userOrganizationSucceeded: false,
                    userOrganizationFailed: true,
                    error: action.error,
                    organizationList: null,
               };
          case ACTIONS.ORGANIZATION_DETAILS_REQUEST:
               return {
                    ...state,
                    organizationDetailsRequested: true,
                    organizationDetailsSucceeded: false,
                    organizationDetailsFailed: false,
               };
          case ACTIONS.ORGANIZATION_DETAILS_SUCCESS:
               return {
                    ...state,
                    organizationDetailsRequested: false,
                    organizationDetailsSucceeded: true,
                    organizationDetailsFailed: false,
                    organization: action.payload,
                    error: null,
               };
          case ACTIONS.ORGANIZATION_DETAILS_FAILURE:
               return {
                    ...state,
                    organizationDetailsRequested: false,
                    organizationDetailsSucceeded: false,
                    organizationDetailsFailed: true,
                    error: action.error,
                    organization: null,
               };
               case ACTIONS.ORGANIZATION_CREATION_REQUEST:
                    return {
                         ...state,
                         organizationCreationRequested: true,
                         organizationCreationSucceeded: false,
                         organizationCreationFailed: false,
                    };
               case ACTIONS.ORGANIZATION_CREATION_SUCCESS:
                    return {
                         ...state,
                         organizationCreationRequested: false,
                         organizationCreationSucceeded: true,
                         organizationCreationFailed: false,
                         error: null,
                    };
               case ACTIONS.ORGANIZATION_CREATION_FAILURE:
                    return {
                         ...state,
                         organizationCreationRequested: false,
                         organizationCreationSucceeded: false,
                         organizationCreationFailed: true,
                         error: action.error
                    };
          case ACTIONS.UPDATE_ORGANIZATION_DETAILS_REQUEST:
               return {
                    ...state,
                    organizationDetailsUpdateRequested: true,
                    organizationDetailsUpdateSucceeded: false,
                    organizationDetailsUpdateFailed: false,
                    message: null,
                    error: null,
               };
          case ACTIONS.UPDATE_ORGANIZATION_DETAILS_SUCCESS:
               return {
                    ...state,
                    organizationDetailsUpdateRequested: false,
                    organizationDetailsUpdateSucceeded: true,
                    organizationDetailsUpdateFailed: false,
                    refreshOrganizationAvatar: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.UPDATE_ORGANIZATION_DETAILS_FAILURE:
               return {
                    ...state,
                    organizationDetailsUpdateRequested: false,
                    organizationDetailsUpdateSucceeded: false,
                    organizationDetailsUpdateFailed: true,
                    refreshOrganizationAvatar: false,
                    error: action.error,
                    message: null,
               };
          case ACTIONS.ORGANIZATION_MEMBERS_REQUEST:
               return {
                    ...state,
                    organizationMembersRequested: true,
                    organizationMembersSucceeded: false,
                    organizationMembersFailed: false,
               };
          case ACTIONS.ORGANIZATION_MEMBERS_SUCCESS:
               return {
                    ...state,
                    organizationMembersRequested: false,
                    organizationMembersSucceeded: true,
                    organizationMembersFailed: false,
                    membersList: action.payload,
                    error: null,
               };
          case ACTIONS.ORGANIZATION_MEMBERS_FAILURE:
               return {
                    ...state,
                    organizationMembersRequested: false,
                    organizationMembersSucceeded: false,
                    organizationMembersFailed: true,
                    error: action.error,
                    membersList: null,
               };
          case ACTIONS.GET_MEMBERS_PAGE_DETAILS:
               return {
                    ...state,
                    membersPageDetails: action.payload,
               };
          case ACTIONS.GET_MEMBERS_INVITATIONS_PAGE_DETAILS:
               return {
                    ...state,
                    membersInvitationsPageDetails: action.payload,
               };
          case ACTIONS.ORGANIZATION_MEMBERS_INVITATIONS_REQUEST:
               return {
                    ...state,
                    organizationMembersInvitationsRequested: true,
                    organizationMembersInvitationsSucceeded: false,
                    organizationMembersInvitationsFailed: false,
               };
          case ACTIONS.ORGANIZATION_MEMBERS_INVITATIONS_SUCCESS:
               return {
                    ...state,
                    organizationMembersInvitationsRequested: false,
                    organizationMembersInvitationsSucceeded: true,
                    organizationMembersInvitationsFailed: false,
                    membersInvitationsList: action.payload,
                    error: null,
               };
          case ACTIONS.ORGANIZATION_MEMBERS_INVITATIONS_FAILURE:
               return {
                    ...state,
                    organizationMembersInvitationsRequested: false,
                    organizationMembersInvitationsSucceeded: false,
                    organizationMembersInvitationsFailed: true,
                    error: action.error,
                    membersInvitationsList: null,
               };
          case ACTIONS.ORGANIZATION_MEMBERS_INVITATIONS_RESEND_REQUEST:
               return {
                    ...state,
                    organizationMembersInvitationsResendRequested: true,
                    organizationMembersInvitationsResendSucceeded: false,
                    organizationMembersInvitationsResendFailed: false,
               };
          case ACTIONS.ORGANIZATION_MEMBERS_INVITATIONS_RESEND_SUCCESS:
               return {
                    ...state,
                    organizationMembersInvitationsResendRequested: false,
                    organizationMembersInvitationsResendSucceeded: true,
                    organizationMembersInvitationsResendFailed: false,
                    error: null,
               };
          case ACTIONS.ORGANIZATION_MEMBERS_INVITATIONS_RESEND_FAILURE:
               return {
                    ...state,
                    organizationMembersInvitationsResendRequested: false,
                    organizationMembersInvitationsResendSucceeded: false,
                    organizationMembersInvitationsResendFailed: true,
                    error: action.error
               };
          case ACTIONS.ORGANIZATION_MEMBERS_INVITATIONS_REMOVE_REQUEST:
               return {
                    ...state,
                    organizationMembersInvitationsRemoveRequested: true,
                    organizationMembersInvitationsRemoveSucceeded: false,
                    organizationMembersInvitationsRemoveFailed: false,
               };
          case ACTIONS.ORGANIZATION_MEMBERS_INVITATIONS_REMOVE_SUCCESS:
               return {
                    ...state,
                    organizationMembersInvitationsRemoveRequested: false,
                    organizationMembersInvitationsRemoveSucceeded: true,
                    organizationMembersInvitationsRemoveFailed: false,
                    error: null,
               };
          case ACTIONS.ORGANIZATION_MEMBERS_INVITATIONS_REMOVE_FAILURE:
               return {
                    ...state,
                    organizationMembersInvitationsRemoveRequested: false,
                    organizationMembersInvitationsRemoveSucceeded: false,
                    organizationMembersInvitationsRemoveFailed: true,
                    error: action.error
               };
          case ACTIONS.UPDATE_MEMBER_SCOPE_REQUEST:
               return {
                    ...state,
                    updateMemberScopeRequested: true,
                    updateMemberScopeSucceeded: false,
                    updateMemberScopeFailed: false,
                    message: null,
                    error: null,
               };
          case ACTIONS.UPDATE_MEMBER_SCOPE_SUCCESS:
               return {
                    ...state,
                    updateMemberScopeRequested: false,
                    updateMemberScopeSucceeded: true,
                    updateMemberScopeFailed: false,
                    message: '',
                    error: null,
                    membersList: state.membersList.map((member) => {
                         if (member.user_id === action.payload.user_id) {
                              member.scope = action.payload.scope;
                         }

                         return member;
                    }),
               };
          case ACTIONS.UPDATE_MEMBER_SCOPE_FAILURE:
               return {
                    ...state,
                    updateMemberScopeRequested: false,
                    updateMemberScopeSucceeded: false,
                    updateMemberScopeFailed: true,
                    error: action.error,
                    message: null,
               };
          case ACTIONS.INVITE_MEMBERS_REQUEST:
               return {
                    ...state,
                    membersInviteRequested: true,
                    membersInviteSucceeded: false,
                    membersInviteFailed: false,
                    message: null,
                    error: null,
               };
          case ACTIONS.INVITE_MEMBERS_SUCCESS:
               return {
                    ...state,
                    membersInviteRequested: false,
                    membersInviteSucceeded: true,
                    membersInviteFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.INVITE_MEMBERS_FAILURE:
               return {
                    ...state,
                    membersInviteRequested: false,
                    membersInviteSucceeded: false,
                    membersInviteFailed: true,
                    error: action.error,
                    message: null,
               };
          case ACTIONS.SEARCH_MEMBERS_REQUEST:
               return {
                    ...state,
                    membersSearchRequested: true,
                    membersSearchSucceeded: false,
                    membersSearchFailed: false,
                    memberSearchResult: null,
                    error: null,
               };
          case ACTIONS.SEARCH_MEMBERS_SUCCESS:
               return {
                    ...state,
                    membersSearchRequested: false,
                    membersSearchSucceeded: true,
                    membersSearchFailed: false,
                    memberSearchResult: action.payload,
                    error: null,
               };
          case ACTIONS.SEARCH_MEMBERS_FAILURE:
               return {
                    ...state,
                    membersSearchRequested: false,
                    membersSearchSucceeded: false,
                    membersSearchFailed: true,
                    error: action.error,
                    memberSearchResult: null,
               };
          case ACTIONS.REMOVE_MEMBER_REQUEST:
               return {
                    ...state,
                    removeMemberRequested: true,
                    removeMemberSucceeded: false,
                    removeMemberFailed: false,
               };
          case ACTIONS.REMOVE_MEMBER_SUCCESS:
               return {
                    ...state,
                    removeMemberRequested: false,
                    removeMemberSucceeded: true,
                    removeMemberFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.REMOVE_MEMBER_FAILURE:
               return {
                    ...state,
                    removeMemberRequested: false,
                    removeMemberSucceeded: false,
                    removeMemberFailed: true,
                    error: action.error,
                    message: null,
               };
          case ACTIONS.ORGANIZATION_EMAIL_SENDERS_REQUEST:
               return {
                    ...state,
                    organizationEmailSendersRequested: true,
                    organizationEmailSendersSucceeded: false,
                    organizationEmailSendersFailed: false,
               };
          case ACTIONS.ORGANIZATION_EMAIL_SENDERS_SUCCESS:
               return {
                    ...state,
                    organizationEmailSendersRequested: false,
                    organizationEmailSendersSucceeded: true,
                    organizationEmailSendersFailed: false,
                    emailSendersList: action.payload,
                    error: null,
               };
          case ACTIONS.ORGANIZATION_EMAIL_SENDERS_FAILURE:
               return {
                    ...state,
                    organizationEmailSendersRequested: false,
                    organizationEmailSendersSucceeded: false,
                    organizationEmailSendersFailed: true,
                    error: action.error,
                    emailSendersList: null,
               };
          case ACTIONS.ORGANIZATION_EMAIL_SENDERS_ALL_REQUEST:
               return {
                    ...state,
                    organizationEmailSendersRequested: true,
                    organizationEmailSendersSucceeded: false,
                    organizationEmailSendersFailed: false,
               };
          case ACTIONS.ORGANIZATION_EMAIL_SENDERS_ALL_SUCCESS:
               return {
                    ...state,
                    organizationEmailSendersRequested: false,
                    organizationEmailSendersSucceeded: true,
                    organizationEmailSendersFailed: false,
                    emailSendersList: action.payload,
                    error: null,
               };
          case ACTIONS.ORGANIZATION_EMAIL_SENDERS_ALL_FAILURE:
               return {
                    ...state,
                    organizationEmailSendersRequested: false,
                    organizationEmailSendersSucceeded: false,
                    organizationEmailSendersFailed: true,
                    error: action.error,
                    emailSendersList: null,
               };
          case ACTIONS.GET_EMAIL_SENDERS_PAGE_DETAILS:
               return {
                    ...state,
                    emailSendersPageDetails: action.payload,
               };
          case ACTIONS.CREATE_EMAIL_SENDER_REQUEST:
               return {
                    ...state,
                    createEmailSenderRequested: true,
                    createEmailSenderSucceeded: false,
                    createEmailSenderFailed: false,
                    message: null,
                    error: null,
               };
          case ACTIONS.CREATE_EMAIL_SENDER_SUCCESS:
               return {
                    ...state,
                    createEmailSenderRequested: false,
                    createEmailSenderSucceeded: true,
                    createEmailSenderFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.CREATE_EMAIL_SENDER_FAILURE:
               return {
                    ...state,
                    createEmailSenderRequested: false,
                    createEmailSenderSucceeded: false,
                    createEmailSenderFailed: true,
                    error: action.error,
                    message: null,
               };
          case ACTIONS.VERIFY_EMAIL_SENDER_REQUEST:
               return {
                    ...state,
                    verifyEmailSenderRequested: true,
                    verifyEmailSenderSucceeded: false,
                    verifyEmailSenderFailed: false,
                    message: null,
                    error: null,
               };
          case ACTIONS.VERIFY_EMAIL_SENDER_SUCCESS:
               return {
                    ...state,
                    verifyEmailSenderRequested: false,
                    verifyEmailSenderSucceeded: true,
                    verifyEmailSenderFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.VERIFY_EMAIL_SENDER_FAILURE:
               return {
                    ...state,
                    verifyEmailSenderRequested: false,
                    verifyEmailSenderSucceeded: false,
                    verifyEmailSenderFailed: true,
                    error: action.error,
                    message: null,
               };
          case ACTIONS.VERIFY_RESEND_EMAIL_SENDER_REQUEST:
               return {
                    ...state,
                    verifyResendEmailSenderRequested: true,
                    verifyResendEmailSenderSucceeded: false,
                    verifyResendEmailSenderFailed: false,
                    message: null,
                    error: null,
               };
          case ACTIONS.VERIFY_RESEND_EMAIL_SENDER_SUCCESS:
               return {
                    ...state,
                    verifyResendEmailSenderRequested: false,
                    verifyResendEmailSenderSucceeded: true,
                    verifyResendEmailSenderFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.VERIFY_RESEND_EMAIL_SENDER_FAILURE:
               return {
                    ...state,
                    verifyResendEmailSenderRequested: false,
                    verifyResendEmailSenderSucceeded: false,
                    verifyResendEmailSenderFailed: true,
                    error: action.error,
                    message: null,
               };
          case ACTIONS.REMOVE_EMAIL_SENDER_REQUEST:
               return {
                    ...state,
                    removeEmailSenderRequested: true,
                    removeEmailSenderSucceeded: false,
                    removeEmailSenderFailed: false,
               };
          case ACTIONS.REMOVE_EMAIL_SENDER_SUCCESS:
               return {
                    ...state,
                    removeEmailSenderRequested: false,
                    removeEmailSenderSucceeded: true,
                    removeEmailSenderFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.REMOVE_EMAIL_SENDER_FAILURE:
               return {
                    ...state,
                    removeEmailSenderRequested: false,
                    removeEmailSenderSucceeded: false,
                    removeEmailSenderFailed: true,
                    error: action.error,
                    message: null,
               };
          case ACTIONS.SEARCH_EMAIL_SENDERS_REQUEST:
               return {
                    ...state,
                    emailSendersSearchRequested: true,
                    emailSendersSearchSucceeded: false,
                    emailSendersSearchFailed: false,
                    emailSendersSearchResult: null,
                    error: null,
               };
          case ACTIONS.SEARCH_EMAIL_SENDERS_SUCCESS:
               return {
                    ...state,
                    emailSendersSearchRequested: false,
                    emailSendersSearchSucceeded: true,
                    emailSendersSearchFailed: false,
                    emailSendersSearchResult: action.payload,
                    error: null,
               };
          case ACTIONS.SEARCH_EMAIL_SENDERS_FAILURE:
               return {
                    ...state,
                    emailSendersSearchRequested: false,
                    emailSendersSearchSucceeded: false,
                    emailSendersSearchFailed: true,
                    error: action.error,
                    emailSendersSearchResult: null,
               };
          case ACTIONS.DISPLAY_JOIN_INVITATION_REQUEST:
               return {
                    displayJoinInvitationRequested: true,
                    displayJoinInvitationSucceeded: false,
                    displayJoinInvitationFailed: false,
               };
          case ACTIONS.DISPLAY_JOIN_INVITATION_SUCCESS:
               return {
                    displayJoinInvitationRequested: false,
                    displayJoinInvitationSucceeded: true,
                    displayJoinInvitationFailed: false,
                    organizationInvite: action.payload,
               };
          case ACTIONS.DISPLAY_JOIN_INVITATION_FAILURE:
               return {
                    displayJoinInvitationRequested: false,
                    displayJoinInvitationSucceeded: false,
                    displayJoinInvitationFailed: true,
                    error: action.error,
               };
          case ACTIONS.JOIN_ORGANIZATION_REQUEST:
               return {
                    ...state,
                    joinOrganizationRequested: true,
                    joinOrganizationSucceeded: false,
                    joinOrganizationFailed: false,
                    message: null,
                    error: null,
               };
          case ACTIONS.JOIN_ORGANIZATION_SUCCESS:
               return {
                    ...state,
                    joinOrganizationRequested: false,
                    joinOrganizationSucceeded: true,
                    joinOrganizationFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.JOIN_ORGANIZATION_FAILURE:
               return {
                    ...state,
                    joinOrganizationRequested: false,
                    joinOrganizationSucceeded: false,
                    joinOrganizationFailed: true,
                    error: action.error,
                    message: null,
               };
          case ACTIONS.ORGANIZATION_PHOTO_UPLOAD_REQUEST:
               return {
                    ...state,
                    organizationPhotoUploadRequested: true,
                    organizationPhotoUploadSucceeded: false,
                    organizationPhotoUploadFailed: false,
                    message: null,
                    error: null,
               };
          case ACTIONS.ORGANIZATION_PHOTO_UPLOAD_SUCCESS:
               return {
                    ...state,
                    organizationPhotoUploadRequested: false,
                    organizationPhotoUploadSucceeded: true,
                    organizationPhotoUploadFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.ORGANIZATION_PHOTO_UPLOAD_FAILURE:
               return {
                    ...state,
                    organizationPhotoUploadRequested: false,
                    organizationPhotoUploadSucceeded: false,
                    organizationPhotoUploadFailed: true,
                    error: action.error,
                    message: null,
               };
          case ACTIONS.REFRESH_ORGANIZATION_AVATAR:
               return {
                    ...state,
                    refreshOrganizationAvatar: true,
                    organization: {
                         ...state.organization,
                         photos:
                             state.organization && state.organization.id === action.payload.id
                                 ? action.payload.photos
                                 : (state.organization ? state.organization.photos : null),
                         is_photo_default:
                             state.organization && state.organization.id === action.payload.id
                                 ? action.payload.is_photo_default
                                 : (state.organization ? state.organization.is_photo_default : false)
                    },
                    organizationList:
                        state.organizationList &&
                        state.organizationList.map(organization => {
                             if (organization.id === action.payload.id) {
                                  return {
                                       ...organization,
                                       photos: action.payload.photos
                                  };
                             }

                             return organization;
                        })
               };
          case ACTIONS.REMOVE_ORGANIZATION_AVATAR_REQUEST:
               return {
                    ...state,
                    removeOrganizationAvatarRequested: true,
                    removeOrganizationAvatarSucceeded: false,
                    removeOrganizationAvatarFailed: false,
               };
          case ACTIONS.REMOVE_ORGANIZATION_AVATAR_SUCCESS:
               return {
                    ...state,
                    removeOrganizationAvatarRequested: false,
                    removeOrganizationAvatarSucceeded: true,
                    removeOrganizationAvatarFailed: false,
                    message: action.payload,
                    error: null,
               };
          case ACTIONS.REMOVE_ORGANIZATION_AVATAR_FAILURE:
               return {
                    ...state,
                    removeOrganizationAvatarRequested: false,
                    removeOrganizationAvatarSucceeded: false,
                    removeOrganizationAvatarFailed: true,
                    error: action.error,
                    message: null,
               };
          default:
               return state;
     }
}
