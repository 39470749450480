import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useTable, usePagination } from 'react-table';
import { Pagination, Dropdown, Button } from 'react-bootstrap';

import ChevronDownIcon from '../../../assets/icons/metronic/chevron-down-icon';
import MoreIcon from '../../../assets/icons/metronic/more-icon';
import CopyIcon from '../../../assets/icons/metronic/copy-icon';
import TrashCanIcon from '../../../assets/icons/metronic/trash-can-icon';
import { PreviewReminderModal } from './PreviewReminderModal';
import { COLORS, FONT } from '../../../constants/style.constants';
import { TablePanel } from '../../common/filterable-table/TablePanel';
import { PAGE_SIZES } from '../../../constants/page.constants';
import FilterableTableWrapper from '../../common/filterable-table/FilterableTable.style';
import {
     StyledDropdown,
     TableDropdownWrapper,
} from '../../common/dropdown/Dropdown.style';
import {inArray} from "../../../utils/array.utils";
import {Sorting} from "../../common/sorting/Sorting";
import Badge from "../../common/badge";

const ActionsDropdown = ({ reminderId, handleRemoveReminders }) => {
     const { id, eventId } = useParams();
     const [dropdownShown, setDropdownShown] = useState(false);

     const toggleDropdown = useCallback(() => {
          setDropdownShown(!dropdownShown);
     }, [dropdownShown]);

     return (
          <TableDropdownWrapper>
               <StyledDropdown
                    onToggle={toggleDropdown}
                    show={dropdownShown}
                    alignRight
               >
                    <StyledDropdown.Toggle variant="default">
                         <MoreIcon className="icon" />
                    </StyledDropdown.Toggle>
                    <StyledDropdown.Menu>
                         <StyledDropdown.Item
                              as="div"
                              style={{
                                   margin: 0,
                                   padding: 0,
                              }}
                         >
                              <Link
                                   to={`/organization/${id}/events/${eventId}/reminders/copy-reminder/${reminderId}`}
                                   className="dropdown-item"
                              >
                                   <CopyIcon />
                                   <span className="edit">
                                        {i18n.t('buttons.copy')}
                                   </span>
                              </Link>
                         </StyledDropdown.Item>
                         <StyledDropdown.Item
                              as="div"
                              style={{
                                   margin: 0,
                                   padding: 0,
                              }}
                         >
                              <Link to="#" className="dropdown-item">
                                   <TrashCanIcon />
                                   <span
                                        className="remove"
                                        onClick={() =>
                                             handleRemoveReminders({
                                                  id: reminderId,
                                             })
                                        }
                                   >
                                        {i18n.t('buttons.delete')}
                                   </span>
                              </Link>
                         </StyledDropdown.Item>
                    </StyledDropdown.Menu>
               </StyledDropdown>
          </TableDropdownWrapper>
     );
};

export const RemindersTable = ({
     data,
     pageDetails,
     handlePageChange,
     handlePageSizeChange,
     handleSearch,
     handleRemoveReminders,
     sorting,
     handleSort
}) => {
     const { t } = useTranslation();
     const { id, eventId } = useParams();

     const [tableData, setTableData] = useState([]);
     const [showModal, setShowModal] = useState(false);
     const [previewItem, setPreviewItem] = useState(null);

     const columns = useMemo(
          () => [
               {
                    Header: i18n.t('reminders.subject_line'),
                    accessor: 'subjectLine',
               },
               {
                    Header: i18n.t('reminders.condition'),
                    accessor: 'condition',
               },
               {
                  Header: i18n.t('reminders.status'),
                  accessor: 'status',
               },
               {
                    Header: i18n.t('organization_settings.actions'),
                    accessor: 'actions',
               },
          ],
          []
     );

     const transformData = (data) =>
          data.map((item) => ({
               ...item,
               subjectLine: (
                    <span
                         style={{
                              fontWeight: `${FONT.MEDIUM}`,
                              paddingLeft: 0,
                         }}
                    >
                         {item.subjectLine}
                    </span>
               ),
               condition: (
                    <span style={{ marginRight: '7px' }}>{item.condition}</span>
               ),
               status: (
                   <Badge
                       variant={
                           item.originalStatus === 'sent'
                               ? 'success'
                               : item.originalStatus === 'pending'
                               ? 'warning'
                               : 'danger'
                       }
                   >
                       {item.status}
                   </Badge>
               ),
               actions: (
                    <div style={{ display: 'flex' }}>
                         <>
                              <Button
                                   variant="outline-primary"
                                   onClick={() => {
                                        setShowModal(true);
                                        setPreviewItem(item);
                                   }}
                              >
                                   {t('reminders.preview')}
                              </Button>
                         </>
                         <ActionsDropdown
                              reminderId={item.id}
                              handleRemoveReminders={handleRemoveReminders}
                         />
                    </div>
               ),
          }));

     useEffect(() => {
          setTableData(transformData(data));
     }, [data]);

     const {
          getTableProps,
          getTableBodyProps,
          headerGroups,
          rows,
          prepareRow,
          page,
          canPreviousPage,
          canNextPage,
          pageOptions,
          pageCount,
          gotoPage,
          nextPage,
          previousPage,
          setPageSize,
          state: { pageIndex, pageSize },
     } = useTable(
          {
               columns,
               data: tableData,
               manualPagination: true,
               pageCount: pageDetails.last_page,
               initialState: {
                    pageSize: pageDetails.per_page,
               },
          },
          usePagination
     );

    const alignText = useCallback((id) => {
        const config = {
            actions: 'center',
        };

        return config[id] || 'left';
    }, []);

    const transformColumnWidth = useCallback(
        (id) => {
            const config = {
                actions: '100px'
            };

            return config[id] || 'inherit';
        },
        []);

     return (
          <FilterableTableWrapper>
               {showModal && (
                    <PreviewReminderModal
                         previewItem={previewItem}
                         eventId={eventId}
                         show={showModal}
                         handleClose={() => { setShowModal(false); setPreviewItem(null); }}
                    />
               )}
               <TablePanel
                    title={t('reminders.active_reminders')}
                    total={pageDetails.total}
                    handleSearch={handleSearch}
                    actions={
                         <Link
                              to={`/organization/${id}/events/${eventId}/reminders/new-reminder`}
                         >
                              <Button variant="success">
                                   {t('reminders.new_reminder')}
                              </Button>
                         </Link>
                    }
               />
               <div
                    className="table-container"
                    style={{ marginBottom: '30px' }}
               >
                    <table
                         {...getTableProps()}
                         data={data}
                         className="table"
                         style={{ width: '100%' }}
                    >
                         <thead>
                              {headerGroups.map((headerGroup) => (
                                   <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => {
                                             return (
                                                  <th
                                                       className="th"
                                                       {...column.getHeaderProps()}
                                                       style={{
                                                            padding: '20px 30px',
                                                            verticalAlign: 'middle',
                                                            textAlign: alignText(column.id),
                                                            width: transformColumnWidth(column.id)
                                                       }}
                                                  >
                                                       {column.render('Header')}
                                                      {
                                                          inArray(sorting.tableColumns, column.id) &&
                                                          (<Sorting
                                                              field={sorting.mapColumns[column.id]}
                                                              activeField={sorting.column}
                                                              direction={sorting.direction}
                                                              onSort={handleSort}
                                                          />)
                                                      }
                                                  </th>
                                             );
                                        })}
                                   </tr>
                              ))}
                         </thead>
                         <tbody {...getTableBodyProps()}>
                              {page.slice(0, pageSize).map((row, i) => {
                                   prepareRow(row);
                                   return (
                                        <tr
                                             {...row.getRowProps()}
                                             style={{
                                                  borderTop: `1px solid ${COLORS.PALE_BLUE}`,
                                             }}
                                        >
                                             {row.cells.map((cell) => {
                                                  return (
                                                       <td
                                                            {...cell.getCellProps()}
                                                            className="td"
                                                            style={{
                                                                 width:
                                                                      'inherit',
                                                                 padding:
                                                                      '20px 30px',
                                                                 verticalAlign:
                                                                      'middle',
                                                            }}
                                                       >
                                                            {cell.render(
                                                                 'Cell'
                                                            )}
                                                       </td>
                                                  );
                                             })}
                                        </tr>
                                   );
                              })}
                         </tbody>
                    </table>
                    <div className="datatable__pager">
                         <div className="pagination">
                              <Pagination.First
                                   onClick={() => {
                                        gotoPage(0);
                                        handlePageChange({ page: 1, pageSize });
                                   }}
                                   disabled={!canPreviousPage}
                              />
                              <Pagination.Prev
                                   onClick={() => {
                                        previousPage();
                                        handlePageChange({
                                             page: pageDetails.current_page - 1,
                                             pageSize,
                                        });
                                   }}
                                   disabled={!canPreviousPage}
                              />
                              <ul className="pagination">
                                   {pageOptions.map((option) => (
                                        <li
                                             key={option}
                                             className={`page-item ${
                                                  pageIndex === option
                                                       ? 'active'
                                                       : ''
                                             }`}
                                             style={{ cursor: 'pointer' }}
                                             onClick={() =>
                                                  handlePageChange({
                                                       page: option + 1,
                                                       pageSize,
                                                  })
                                             }
                                        >
                                             <span
                                                  className="page-link"
                                                  onClick={() =>
                                                       gotoPage(option)
                                                  }
                                             >
                                                  <span>{option + 1}</span>
                                             </span>
                                        </li>
                                   ))}
                              </ul>
                              <Pagination.Next
                                   onClick={() => {
                                        nextPage();
                                        handlePageChange({
                                             page: pageDetails.current_page + 1,
                                             pageSize,
                                        });
                                   }}
                                   disabled={!canNextPage}
                              />
                              <Pagination.Last
                                   onClick={() => {
                                        gotoPage(pageCount - 1);
                                        handlePageChange({
                                             page: pageDetails.last_page,
                                             pageSize,
                                        });
                                   }}
                                   disabled={!canNextPage}
                              />
                         </div>
                         <div className="tabel__pager-info">
                              <Dropdown>
                                   <Dropdown.Toggle variant="light">
                                        <span>{pageSize}</span>
                                        <ChevronDownIcon />
                                   </Dropdown.Toggle>
                                   <Dropdown.Menu style={{ minWidth: '0' }}>
                                        {PAGE_SIZES.map((i) => (
                                             <Dropdown.Item
                                                  key={i}
                                                  onClick={() => {
                                                       setPageSize(i);
                                                       handlePageSizeChange(i);
                                                  }}
                                             >
                                                  <span>{i}</span>
                                             </Dropdown.Item>
                                        ))}
                                   </Dropdown.Menu>
                              </Dropdown>
                              <span className="tabel__pager-info--text">
                                   {`${i18n.t(
                                        'organization_settings.showing'
                                   )} ${pageDetails.from} - ${
                                        pageDetails.to
                                   } ${i18n.t('organization_settings.of')} ${
                                        pageDetails.total
                                   }`}
                              </span>
                         </div>
                    </div>
               </div>
          </FilterableTableWrapper>
     );
};
