import React, {memo, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { requestPreviewReminder } from '../../../redux/reducers/reminderReducer';

const mapStateToProps = (state) => ({
     reminderPreview: state.reminderReducer.reminderPreview,
     previewReminderLoading: state.reminderReducer.previewReminderLoading,
     languages: state.userReducer.languages,
});

const mapDispatchToProps = (dispatch) => ({
     requestPreviewReminder: (payload) => dispatch(requestPreviewReminder(payload))
});

export const _PreviewReminderModal = memo(
     ({
          show,
          handleClose,
          eventId,
          previewItem,
          previewReminderLoading,
          reminderPreview,
          requestPreviewReminder,
          languages
     }) => {
          const { t } = useTranslation();
          const [reminderId, setReminderId] = useState(false);
          const [languageId, setLanguageId] = useState(false);
          const [multilingual, setMultilingual] = useState(false);
          const [reminderLanguages, setReminderLanguages] = useState(false);
          const [data, setData] = useState(null);

          const closeDialog = () => {
               handleClose();
               setData(null);
               setReminderId(false);
               setLanguageId(false);
               setMultilingual(false);
               setReminderLanguages(false);
          };

          useEffect(() => {
               if (reminderPreview) {
                    setData(reminderPreview);
               }
          }, [reminderPreview]);

          useEffect(() => {
               // Disable scrolling on main page
               document.body.style.overflow = show ? 'hidden' : 'unset';
          }, [show]);

          useEffect(() => {
               if (previewItem) {
                    setReminderId(previewItem.id);
                    setLanguageId(previewItem.languageId);
                    setMultilingual(previewItem.multilingual);
                    setReminderLanguages(previewItem.languages);
               }
          }, [previewItem]);

          useEffect(() => {
               if (eventId  && reminderId && languageId) {
                    requestPreviewReminder({ eventId, reminderId, languageId });
               }
          }, [reminderId, languageId, eventId]);

          if (!data) return <></>;

          const createPreview = () => {
               return { __html: data };
          };

          return (
               <Modal
                    scrollable
                    show={show}
                    onHide={() => closeDialog()}
                    container={document.getElementById('root')}
                    size="lg"
               >
                    <Modal.Header closeButton>
                         <Modal.Title className="container">
                              <div className="row h-100">
                                   <div className="col-6 my-auto">
                                        {t('speakers.preview')}
                                   </div>
                                   <div className="col-6 text-right language-buttons">
                                        {multilingual && reminderLanguages.length > 1 && languages.map(
                                            (lang) => {
                                                 return (
                                                     <Button
                                                         className="btn ml-2"
                                                         size="sm"
                                                         variant={
                                                              languageId && lang.id === languageId
                                                                  ? 'primary'
                                                                  : 'outline-primary'
                                                         }
                                                         key={
                                                              lang.id
                                                         }
                                                         onClick={() => {
                                                              setLanguageId(lang.id);
                                                         }}
                                                     >
                                                          {lang.name}
                                                     </Button>
                                                 )}
                                        )}
                                   </div>
                              </div>
                         </Modal.Title>
                    </Modal.Header>

                    <Modal.Body bsPrefix="preview" className="toolbar">
                         {!previewReminderLoading && (
                              <div
                                   style={{ pointerEvents: 'none' }}
                                   dangerouslySetInnerHTML={createPreview()}
                              />
                         )}
                    </Modal.Body>

                    <Modal.Footer>
                         <Button
                              variant="primary"
                              onClick={() => closeDialog()}
                              type="button"
                         >
                              {t('buttons.close')}
                         </Button>
                    </Modal.Footer>
               </Modal>
          );
     }
);

export const PreviewReminderModal = connect(
     mapStateToProps,
     mapDispatchToProps
)(_PreviewReminderModal);
