import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import socketIOClient from "socket.io-client";
import {successUserProfile} from "../../redux/reducers/userReducer";
import {speakerGeneratedData} from "../../redux/reducers/speakerReducer";
import {refreshOrganizationAvatars} from "../../redux/reducers/organizationReducer";
import {refreshEventAvatars} from "../../redux/reducers/eventReducer";

const mapStateToProps = (state) => ({
    router: state.router,
    currentUser: state.userReducer.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
    successUserProfile: (payload) => dispatch(successUserProfile(payload)),
    speakerGeneratedData: (payload) => dispatch(speakerGeneratedData(payload)),
    refreshOrganizationAvatars: (payload) => dispatch(refreshOrganizationAvatars(payload)),
    refreshEventAvatars: (payload) => dispatch(refreshEventAvatars(payload)),
});

const _SocketConnection = (props) => {

    const {
        currentUser,
        successUserProfile,
        speakerGeneratedData,
        refreshOrganizationAvatars,
        refreshEventAvatars
    } = props;

    const socketEndpoint = process.env.REACT_APP_SOCKET_URL;

    useEffect(() => {
        const socket = socketIOClient(socketEndpoint);

        socket.on("user", data => {
            const user = JSON.parse(atob(data.user));

            if (user.id === currentUser.id) {
                successUserProfile(user);
            }
        });

        socket.on("speaker.generated", data => {
            speakerGeneratedData(data);
        });

        socket.on("organization", data => {
            refreshOrganizationAvatars(data.organization);
        });

        socket.on("event", data => {
            refreshEventAvatars(data.event);
        });

        return () => socket.disconnect();

    }, [socketEndpoint]);

    return (<></>);
};

export const SocketConnection = connect(mapStateToProps, mapDispatchToProps)(_SocketConnection);
