import React, {useCallback, useRef} from 'react';
import { useTranslation } from 'react-i18next';
import {Button} from "react-bootstrap";
import {convertBytesToMb, isValidFileSize} from "../../../utils/file.utils";
import toastr from "toastr";
import {convertMimesToExtensions, isValidMimeType} from "../../../utils/mime.utils";
import {requestDownloadMediaFile} from "../../../redux/reducers/speakerReducer";
import {connect} from "react-redux";

const mapDispatchToProps = (dispatch) => ({
    downloadMediaFile: (payload) => dispatch(requestDownloadMediaFile(payload)),
});

export const _FileUploader = ({
        field,
        file,
        preview,
        handleUploadFile,
        handleRemoveFile,
        handlePreviewRemoveFile,
        downloadMediaFile,
        accept = null,
        fileLimit = null,
        additionalInfo = '',
        isRequired = false,
        allFiles = false,
        isLoading = false
    }) => {
    const { t } = useTranslation();

    const buttonRef = useRef();

    const handleUpload = () => buttonRef.current.click();

    const handleDownload = useCallback((e) => {
        e.preventDefault();
        downloadMediaFile({file: file.original, name: file.meta.name});
    }, [downloadMediaFile, file]);

    const displayAttachedFile = (field) => {
        return file && !preview && (<div className="attached-files">
            <div className="item" key={field}>
                <div className="file col-10">
                    <a href="#null" onClick={(e) => handleDownload(e)} title={field} target='__blank'>{file && file.meta && file.meta.name}</a>
                </div>
                <div className="file-toolbar col-2">
                    <Button
                        variant={'link'}
                        onClick={() => { handleRemoveFile(field) }}
                    >
                        ×
                    </Button>
                </div>
            </div>
        </div>);
    };

    const displayPreviewFile = (field) => {
        return preview && (<div className="attached-files">
            <div className="item" key={field}>
                <div className="file col-10">
                    {preview.name}
                </div>
                <div className="file-toolbar col-2">
                    <Button
                        variant={'link'}
                        onClick={() => { handlePreviewRemoveFile(field) }}
                    >
                        ×
                    </Button>
                </div>
            </div>
        </div>);
    };

    const uploadFiles = (files) => {
        if (allFiles) {
            files.forEach(file => {
                const isValidSize = fileLimit ? isValidFileSize(file, fileLimit) : true;
                const isValidMime = accept ? isValidMimeType(file, accept) : true;
                if (isValidSize && isValidMime) {
                    handleUploadFile(field, files);
                } else {
                    if (!isValidSize) {
                        toastr.error(t('picture_uploader.file_size_limit_hit', {limit: convertBytesToMb(fileLimit)}));
                    }
                    if (!isValidMime) {
                        toastr.error(t('picture_uploader.file_invalid_mime', {
                            mimes: convertMimesToExtensions(accept)
                        }));
                    }
                }
            });

        } else {
            const isValidSize = fileLimit ? isValidFileSize(files[0], fileLimit) : true;
            const isValidMime = accept ? isValidMimeType(files[0], accept) : true;
            if (isValidSize && isValidMime) {
                handleUploadFile(field, files[0]);
            } else {
                if (!isValidSize) {
                    toastr.error(t('picture_uploader.file_size_limit_hit', {limit: convertBytesToMb(fileLimit)}));
                }
                if (!isValidMime) {
                    toastr.error(t('picture_uploader.file_invalid_mime', {
                        mimes: convertMimesToExtensions(accept)
                    }));
                }
            }
        }

    };

    return (
        <div>
            <Button
                className="btn-themed-blue"
                style={{
                    margin: 0,
                }}
                onClick={handleUpload}
            >
                {t(
                    'form_builder.attach_files'
                )}

                {isLoading && (
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{marginLeft: '5px'}} />
                )}
            </Button>
            <input
                required={isRequired}
                multiple={!!allFiles}
                type="file"
                value=""
                ref={buttonRef}
                accept={accept}
                onChange={({
                   target: { files },
                }) => {
                    uploadFiles(files);
                }}
                style={{display: 'none'}}
            />
            {displayPreviewFile(field)}
            {displayAttachedFile(field)}
            <div
                className="info-block"
                style={{
                    wordBreak: 'break-word',
                }}
            >
                {additionalInfo}
            </div>
        </div>
    );
};

export const FileUploader = connect(null, mapDispatchToProps)(_FileUploader);
