import * as React from "react";
import { ModuleDown } from "../../main/templates/ModuleDown";
import Header from "../../main/templates/Header";
import Footer from "../../main/templates/Footer";

export class GeneralError extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <>
                    <Header />
                    <div className="content">
                        <ModuleDown />
                    </div>
                    <Footer />
                </>
            );
        }

        return this.props.children;
    }
}
