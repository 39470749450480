import React, {memo, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import {Alert, Button, Col, Dropdown, Form, Row} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { InvitationFormTable } from './InvitationFormTable';
import CustomToggle from "../../../common/custom-toggle";
import ChevronDownIcon from "../../../../assets/icons/metronic/chevron-down-icon";

export const InvitationForm = memo(
     ({
          prepareData,
          handleAction,
          id,
          eventId,
          handlePageSizeChange,
          handlePageChange,
          handleSearch,
          handleNewFormAction,
          handleEditFormAction,
          handleChangeCurrentStep,
          selectedForms,
          handleFormSelect,
          isSpeakerAndFormsValid,
          isFormLanguagesValid,
          formLanguageDuplicates,
          languages,
          handleSelectDuplicatedForm
     }) => {
          const { t } = useTranslation();

          const [duplicatedLanguages, setDuplicatedLanguages] = useState([]);

         useEffect(() => {
             handleChangeCurrentStep(2);
         });

         useEffect(() => {
             let foundLanguages = [];

             formLanguageDuplicates().forEach(id => {
                 foundLanguages.push(languages.find(lang => lang.id === id));
             });

             setDuplicatedLanguages(foundLanguages);

         }, [formLanguageDuplicates]);

          return (
               <div className="invitation-form">
                    <div className="headline">
                         {t('speakers.invitation_form')}
                    </div>
                    <span className="hint">
                         {t('speakers.invitation_form_hint')}
                    </span>
                    <div className="table">
                         <InvitationFormTable
                              data={prepareData}
                              handlePageSizeChange={handlePageSizeChange}
                              handlePageChange={handlePageChange}
                              id={id}
                              eventId={eventId}
                              handleAction={handleAction}
                              handleSearch={handleSearch}
                              handleNewFormAction={handleNewFormAction}
                              handleEditFormAction={handleEditFormAction}
                              selectedForms={selectedForms}
                              handleFormSelect={handleFormSelect}
                         />
                    </div>
                    <div className="actions-section">
                        {!isSpeakerAndFormsValid() && (
                            <Row>
                                <Alert
                                    key={'mismatch-languages'}
                                    variant={'outline-warning'}
                                >
                                    {t('speakers.invitation_form_mismatched_languages')}
                                </Alert>
                            </Row>
                        )}
                        {!isFormLanguagesValid() && (
                            <Row>
                                <Alert
                                    key={'duplicated-languages'}
                                    variant={'outline-danger'}
                                >
                                    {t('speakers.invitation_form_duplicated_languages')}
                                </Alert>
                            </Row>
                        )}
                        {!isFormLanguagesValid() && (
                            duplicatedLanguages.map((lang, index) => (
                                <Row key={`row-${index}`}>
                                    <Form.Group
                                        key={lang.id}
                                        as={Col}
                                        controlId="language"
                                    >
                                        <Col sm={3}>
                                            <Form.Label>
                                                {lang.name}
                                            </Form.Label>
                                        </Col>
                                        <Col sm={12}>
                                            <div className={`input-group zi${10-index}`}>
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        className="select-dropdown"
                                                        as={
                                                            CustomToggle
                                                        }
                                                    >
                                                        {t(
                                                            'speakers.invitation_from_select_from_for_use'
                                                        )}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {selectedForms.map(
                                                            (form) => {
                                                                if (
                                                                    (form.language_use && form.language_use === lang.id) ||
                                                                    (form.language_use === undefined && form.language.includes(lang.id))
                                                                ) {
                                                                    return (
                                                                        <Dropdown.Item
                                                                            key={
                                                                                form.id
                                                                            }
                                                                            onClick={() =>
                                                                                handleSelectDuplicatedForm(lang, form)
                                                                            }
                                                                        >
                                                                            {
                                                                                form.name
                                                                            }
                                                                        </Dropdown.Item>
                                                                    );
                                                                }
                                                            }
                                                        )}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <span className="suffix">
                                                                 <ChevronDownIcon />
                                                            </span>
                                            </div>
                                        </Col>
                                    </Form.Group>
                                </Row>
                            ))
                        )}
                        <Row className={'buttons'}>
                            <Link
                                to={`/organization/${id}/events/${eventId}/invite-speakers/step-1`}
                                onClick={() => {
                                    document
                                        .querySelector('.App')
                                        .scrollIntoView({ behavior: 'smooth' });
                                }}
                            >
                                <Button variant="default">
                                    {t('buttons.previous')}
                                </Button>
                            </Link>
                            <Link
                                to={`/organization/${id}/events/${eventId}/invite-speakers/step-3`}
                                onClick={() => {
                                    document
                                        .querySelector('.App')
                                        .scrollIntoView({ behavior: 'smooth' });
                                }}
                            >
                                <Button
                                    variant="success"
                                    disabled={!isSpeakerAndFormsValid() || !isFormLanguagesValid()}
                                >
                                    {t('buttons.next')}
                                </Button>
                            </Link>
                        </Row>
                    </div>
               </div>
          );
     }
);
