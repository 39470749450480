import styled from 'styled-components';
import {COLORS} from "../../../constants/style.constants";

export const AutosuggestionWrapper = styled('div')`
     .react-autosuggest__container {
        position: relative;
     }
     
     .react-autosuggest__input--focused {
      outline: none;
    }
    
    .react-autosuggest__input--open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    
    .react-autosuggest__suggestions-container {
      display: none;
    }
    
    .react-autosuggest__suggestions-container--open {
      display: block;
      position: absolute;
      width: 100%;
      top: 43px;
      border: 1px solid #ced4da;
      background-color: ${COLORS.WHITE};
      font-family: 'IBM Plex Sans',sans-serif;
      font-weight: 300;
      font-size: 14px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      z-index: 99;
    }
    
    .react-autosuggest__suggestions-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
    
    .react-autosuggest__suggestion {
      cursor: pointer;
      padding: 10px 20px;
    }
    
    .react-autosuggest__suggestion--highlighted {
      background-color: ${COLORS.PALE_BLUE};
    }
`;
