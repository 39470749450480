import React from "react";

export const Placeholder = () => {
    return (
        <div
            style={{
                display: 'block',
                border: '3px dashed rgba(24, 112, 244, 0.12)',
                borderRadius: '5px',
                height: '50px',
                margin: '10px'
            }}
        >

        </div>
    );
};
