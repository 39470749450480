import moment from 'moment';

export const CURRENT_YEAR = new Date().getFullYear();

export const formatDate = ({ startDate, endDate }) => {
     const start = moment(startDate);
     const end = moment(endDate);
     const startFormat = start.format('YYYY') === end.format('YYYY') ? 'MMM DD' : 'MMM DD, YYYY';
     const endFormat = 'MMM DD, YYYY';

     return `${start.format(startFormat)} - ${end.format(endFormat)}`;
};


export const formatDateEmpty = ({startDate, endDate}) => {
     if (startDate !== '' && endDate !== '') {
          const start = moment(startDate);
          const end = moment(endDate);
          const startFormat = start.format('YYYY') === end.format('YYYY') ? 'MMM DD' : 'MMM DD, YYYY';
          const endFormat = 'MMM DD, YYYY';

          return `${start.format(startFormat)} - ${end.format(endFormat)}`;
     }

     return '';
};
