import i18n from 'i18next';
import toastr from 'toastr';
import {call, put, select, all, takeEvery} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
     successOrganizationEvents,
     failOrganizationEvents,
     getOrganizationEventsPageDetails,
     successCreateNewEvent,
     failCreateNewEvent,
     successEventPhotoUpload,
     failEventPhotoUpload,
     successEventDetails,
     failEventDetails,
     successUpdateEventDetails,
     failUpdateEventDetails,
     successCancelEvent,
     failCancelEvent,
     refreshEventAvatars,
     successRemoveEventAvatar,
     failRemoveEventAvatar,
     moduleDown,
     successEventPhotoTemporaryUpload,
     failEventPhotoTemporaryUpload,
     requestTemporaryPhotoReset,
} from '../reducers/eventReducer';
import * as ACTIONS from '../actions';
import * as Service from '../../services';
import { handleErrors } from '../../utils/sagaUtils';

export function* organizationEventsSaga({ payload }) {
     const response = yield call(Service.getOrganizationEvents, payload);

     if (response.status === 200) {
          const { data, ...pageDetails } = response.data;
          yield put(getOrganizationEventsPageDetails(pageDetails));
          return yield put(successOrganizationEvents(data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     if (response.status === 403 || response.status === 404) {
          return yield put(push('/not-found-404'));
     }

     if (response.status === 500) {
          return yield put(moduleDown());
     }

     yield put(failOrganizationEvents(response.data.message));
}

export function* createNewEventSaga({ payload }) {
     const response = yield call(Service.createNewEvent, payload);

     if (response.status === 201) {
          toastr.success(i18n.t('alerts.success.event_created'));

          yield put(successCreateNewEvent('success'));
          return yield put(
               push(`/organization/${payload.organization_id}/events`)
          );
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     if (response.status === 402) {
          toastr.error(i18n.t('alerts.error.tier_max_events_reached'));
     }

     if (response.status === 423) {
          toastr.error(i18n.t('organization_settings.your_organization_have_been_suspended'));
     }

     response.errors && handleErrors(response.errors);
     yield put(failCreateNewEvent(response.message));
}

export function* eventDetailsSaga({ payload }) {
     const response = yield call(Service.getEventDetails, payload);

     if (response.status === 200) {
          return yield put(successEventDetails(response.data.data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     if (response.status === 403 || response.status === 404) {
          return yield put(push('/not-found-404'));
     }

     yield put(failEventDetails(response.data.message));
}

export function* updateEventDetailsSaga({ payload }) {
     const response = yield call(Service.updateEventDetails, payload);

     if (response.status === 200) {
          toastr.success(i18n.t('alerts.success.changes_saved'));
          const event = response.data.data;
          event.photos = Service.refreshAvatars(event.photos);
          yield put(refreshEventAvatars(event));

          return yield put(successUpdateEventDetails('success'));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }
     response.errors && handleErrors(response.errors);
     yield put(failUpdateEventDetails(response.message));
}

export function* cancelEventSaga({ payload }) {
     const response = yield call(Service.cancelEvent, payload);

     if (response.status === 200) {
          toastr.success(i18n.t('alerts.success.event_cancelled'));
          yield put(successCancelEvent('success'));
          return yield put(push(`/organization/${payload.id}/events`));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     if (response.status === 403) {
          toastr.error(i18n.t('alerts.error.event_cancelled_fail'));
     }

     yield put(failCancelEvent(response.message));
}

export function* eventPhotoUploadSaga({ payload }) {
     const response = yield call(Service.eventPhotoUpload, payload);

     if (response.status === 200) {
          return yield put(successEventPhotoUpload(response.data.data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }
     response.errors && handleErrors(response.errors);
     yield put(failEventPhotoUpload(response.message));
}

export function* eventPhotoUploadTemporarySaga({ payload }) {
     const response = yield call(Service.eventPhotoUploadTemporary, payload);

     if (response.status === 200) {
          yield put(successEventPhotoTemporaryUpload(response.data.data));

          return yield put(requestTemporaryPhotoReset());
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }
     response.errors && handleErrors(response.errors);
     yield put(failEventPhotoTemporaryUpload(response.message));
}

export function* removeEventAvatarSaga({ payload }) {
     const response = yield call(Service.removeEventAvatar, payload);

     if (response.status === 200) {
          return yield put(successRemoveEventAvatar(response.data.data));
     }

     if (response.status === 401) {
          return yield put(push('/auth/login'));
     }

     yield put(failRemoveEventAvatar(response.message));
}

export function* watchEvent() {
     yield all([
          takeEvery(ACTIONS.ORGANIZATION_EVENTS_REQUEST, organizationEventsSaga),
          takeEvery(ACTIONS.CREATE_NEW_EVENT_REQUEST, createNewEventSaga),
          takeEvery(ACTIONS.EVENT_DETAILS_REQUEST, eventDetailsSaga),
          takeEvery(ACTIONS.UPDATE_EVENT_DETAILS_REQUEST, updateEventDetailsSaga),
          takeEvery(ACTIONS.CANCEL_EVENT_REQUEST, cancelEventSaga),
          takeEvery(ACTIONS.UPLOAD_EVENT_PHOTO_REQUEST, eventPhotoUploadSaga),
          takeEvery(ACTIONS.UPLOAD_EVENT_PHOTO_TEMPORARY_REQUEST, eventPhotoUploadTemporarySaga),
          takeEvery(ACTIONS.REMOVE_EVENT_AVATAR_REQUEST, removeEventAvatarSaga),
     ])
}
