import { useCallback } from 'react';

export const useOrganizationPhotos = ({
     organizationList,
     organizationId,
     organization,
     dependencies,
}) => {
     let photos;

     const getOrganizationCheck = useCallback(() => {
          if (organizationList) {
               const output = organizationList.find(
                    ({ id: _id }) => _id === Number(organizationId)
               );

               return output.photos;
          }

          return organization && organization.photos;
     }, [organizationList, organization, ...dependencies]);

     photos = getOrganizationCheck();

     return { photos };
};
