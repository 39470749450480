import React, {memo, lazy, useEffect, useCallback, useState} from 'react';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import LoadingBar from '../../../common/loading-bar';
import Breadcrumb from '../../../common/breadcrumb';
import Portlet from '../../../common/portlet';
import { ListItem } from '../../../common/collapsible-list/CollapsibleList';
import { EventDropdown } from '../../Event/sub-components/EventDropdown';
import { SpeakerForm } from './SpeakerForm';
import { SettingsImage } from '../../../../assets/icons/metronic/settings-image';
import SpeakersIcon from '../../../../assets/icons/metronic/speakers-icon';
import { GroupChatImage } from '../../../../assets/icons/metronic/group-chat-image';
import { DotImage } from '../../../../assets/icons/metronic/dot-image';
import { requestOrganizationDetails } from '../../../../redux/reducers/organizationReducer';
import { requestEventDetails } from '../../../../redux/reducers/eventReducer';
import {
     requestSpeakerDetails, requestSpeakerFileRemove, requestSpeakerFileUpload,
     requestSpeakerPhoto, requestSpeakerPhotoRemove, requestSpeakerPhotoUpload,
     requestUpdateSpeakerDetails,
} from '../../../../redux/reducers/speakerReducer';
import { OrganizationDropdown } from '../../Organization/sub-components/OrganizationDropdown';
import SpeakersWrapper from '../Speakers.style';

const MainDrawer = lazy(() => import('../../../common/drawer'));
const CollapsibleList = lazy(() => import('../../../common/collapsible-list'));

const mapStateToProps = (state) => ({
     router: state.router,
     uploadSpeakerPhotoRequested: state.speakerReducer.uploadSpeakerPhotoRequested,
     uploadSpeakerFileRequested: state.speakerReducer.uploadSpeakerFileRequested,
     locationPathname: state.router.location.pathname,
     organization: state.organizationReducer.organization,
     eventData: state.eventReducer.eventData,
     speakerDetailsData: state.speakerReducer.speakerDetailsData,
     speakerPhoto: state.speakerReducer.speakerPhoto,
     languages: state.userReducer.languages
});

const mapDispatchToProps = (dispatch) => ({
     requestOrganizationDetails: (id) => dispatch(requestOrganizationDetails(id)),
     requestEventDetails: (payload) => dispatch(requestEventDetails(payload)),
     requestSpeakerDetails: (payload) => dispatch(requestSpeakerDetails(payload)),
     requestUpdateSpeakerDetails: (payload) => dispatch(requestUpdateSpeakerDetails(payload)),
     requestSpeakerPhoto: (payload) => dispatch(requestSpeakerPhoto(payload)),
     requestSpeakerPhotoUpload: (payload) => dispatch(requestSpeakerPhotoUpload(payload)),
     requestSpeakerFileUpload: (payload) => dispatch(requestSpeakerFileUpload(payload)),
     requestSpeakerPhotoRemove: (payload) => dispatch(requestSpeakerPhotoRemove(payload)),
     requestSpeakerFileRemove: (payload) => dispatch(requestSpeakerFileRemove(payload)),
});

export const _SpeakerDetailsBoard = memo((props) => {
     const { t } = useTranslation();
     const { id: organizationId, eventId, speakerId } = useParams();

     const {
          router: {
               location: { pathname },
          },
          requestOrganizationDetails,
          requestEventDetails,
          organization,
          eventData,
          speakerDetailsData,
          requestSpeakerDetails,
          locationPathname,
          requestSpeakerPhoto,
          speakerPhoto,
          requestUpdateSpeakerDetails,
          requestSpeakerPhotoUpload,
          requestSpeakerFileUpload,
          requestSpeakerPhotoRemove,
          requestSpeakerFileRemove,
          languages,
          uploadSpeakerPhotoRequested,
          uploadSpeakerFileRequested
     } = props;

     const [formState, setFormState] = useState();
     const [invitationLanguage, setInvitationLanguage] = useState();
     const [displayConfirmation, setDisplayConfirmation] = useState(false);

     useEffect(() => {
          requestOrganizationDetails(organizationId);
          requestEventDetails({ organizationId, eventId });
     }, [organizationId, eventId, pathname]);

     useEffect(() => {
          if (speakerDetailsData) {
               const lang = languages.find((lang) => lang.id === speakerDetailsData.language_id);

               setInvitationLanguage(lang);
          }
     }, [speakerDetailsData]);

     useEffect(() => {
          if (!(uploadSpeakerPhotoRequested || uploadSpeakerFileRequested)) {
               setDisplayConfirmation(false);
          } else if (uploadSpeakerFileRequested || uploadSpeakerPhotoRequested) {
               setDisplayConfirmation(true);
          }
     }, [displayConfirmation, uploadSpeakerPhotoRequested, uploadSpeakerFileRequested]);

     const getImageName = useCallback(() => {
          if (speakerDetailsData) {
               const image = speakerDetailsData.form.find(
                    ({ type }) => type === 'image'
               );

               return image && image.name;
          }
     }, [speakerDetailsData]);

     const imageName = getImageName();

     useEffect(() => {
          requestSpeakerDetails({ eventId, id: speakerId });
     }, [locationPathname, eventId, speakerId]);

     useEffect(() => {
          if (speakerDetailsData && imageName) {
               requestSpeakerPhoto({
                    speakerId,
                    field: imageName,
                    size: '240x240',
               });
          }
     }, [speakerId, imageName]);

     const handleValueChange = (code, value) => {
          if (Boolean(value) === false) {
               const newState = {...formState};
               delete newState[code];

               setFormState(newState);
          } else {
               setFormState({ ...formState, [code]: value });
          }
     };

     const handleMultipleChange = (code, value, label) => {
          if (Boolean(value) === false) {
               const values = [...formState[code]];
               const filtered = values.filter((v) => v !== label);

               setFormState({
                    ...formState,
                    [code]: filtered,
               });
          } else {
               setFormState({
                    ...formState,
                    [code]: [...formState[code], value],
               });
          }
     };

     const handleInitialFormValues = (values) => {
          setFormState(values);
     };

     const handleSubmit = (e, mediaForUpload, mediaForRemove) => {
          e.preventDefault();
          const language = languages.find((l) => l.id === speakerDetailsData.language_id);

          requestUpdateSpeakerDetails({
               eventId,
               id: speakerId,
               locale: language.code,
               ...formState,
          });

          mediaForUpload.forEach(({field, file, crop, speakerId, formId, type}) => {
               if (type === 'photo') {
                    requestSpeakerPhotoUpload({field, photo: file, crop, speakerId, formId});
               } else if (type === 'file') {
                    requestSpeakerFileUpload({field, files: file, speakerId, formId});
               }
          });

          mediaForRemove.forEach(({field, speakerId, formId, type}) => {
               if (type === 'photo') {
                    requestSpeakerPhotoRemove({field, speakerId, formId});
               } else if (type === 'file') {
                    requestSpeakerFileRemove({field, speakerId, formId});
               }
          });
     };

     const links = [
          {
               to: `/organization/${organizationId}/settings`,
               text: `${organization && organization.name}`,
          },
          {
               to: `/organization/${organizationId}/events`,
               text: i18n.t('events'),
          },
          {
               to: `/organization/${organizationId}/events/${eventId}/event-edit`,
               text: `${eventData && eventData.name}`,
          },
          {
               to: `/organization/${organizationId}/events/${eventId}/speakers`,
               text: i18n.t('event.speakers'),
          },
          {
               to: `/organization/${organizationId}/events/${eventId}/speaker/${speakerId}`,
               text: `${speakerDetailsData && speakerDetailsData.data.Name}`,
          },
     ];

     return (
          <SpeakersWrapper>
               {speakerDetailsData && (
                    <Helmet>
                         <title>
                              {`${speakerDetailsData.data.Name} |
                         ${t('avovent')}`}
                         </title>
                    </Helmet>
               )}
               <MainDrawer>
                    <ListItem to="/dashboard">
                         {t('breadcrumb.dashboard')}
                    </ListItem>
                    <CollapsibleList
                         listHeader={organization && organization.name}
                         listContent={
                              <>
                                   <ListItem
                                        to={`/organization/${organizationId}/events/${eventId}/event-edit`}
                                   >
                                        <div className="collapsible-list-item">
                                             <GroupChatImage />
                                             <div className="sub-item">
                                                  {t('events')}
                                             </div>
                                        </div>
                                   </ListItem>
                                   <CollapsibleList
                                        listHeader={
                                             <div className="collapsible-list-item">
                                                  <SettingsImage />
                                                  <div className="sub-item">
                                                       {t(
                                                            'organization_settings.organization_settings'
                                                       )}
                                                  </div>
                                             </div>
                                        }
                                        listContent={
                                             <>
                                                  <ListItem
                                                       subItem
                                                       to={`/organization/${organizationId}/settings`}
                                                  >
                                                       <div className="collapsible-list-item">
                                                            <DotImage />
                                                            <div className="sub-item">
                                                                 {t(
                                                                      'organization_settings.organization_settings'
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </ListItem>
                                                  <ListItem
                                                       subItem
                                                       to={`/organization/${organizationId}/members`}
                                                  >
                                                       <div className="collapsible-list-item">
                                                            <DotImage />
                                                            <div className="sub-item">
                                                                 {t(
                                                                      'organization_settings.team_members'
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </ListItem>
                                                  <ListItem
                                                       subItem
                                                       to={`/organization/${organizationId}/email-senders`}
                                                  >
                                                       <div className="collapsible-list-item">
                                                            <DotImage />
                                                            <div className="sub-item">
                                                                 {t(
                                                                      'organization_settings.email_senders'
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </ListItem>
                                             </>
                                        }
                                   />
                              </>
                         }
                    />
                    <CollapsibleList
                         listHeader={eventData && eventData.name}
                         listContent={
                              <>
                                   <ListItem
                                        to={`/organization/${organizationId}/events/${eventId}/speakers`}
                                   >
                                        <div className="collapsible-list-item">
                                             <SpeakersIcon />
                                             <div className="sub-item">
                                                  {t('event.speakers')}
                                             </div>
                                        </div>
                                   </ListItem>
                                   <ListItem
                                        to={`/organization/${organizationId}/events/${eventId}/event-edit`}
                                   >
                                        <div className="collapsible-list-item">
                                             <SettingsImage />
                                             <div className="sub-item">
                                                  {t('event.event_settings')}
                                             </div>
                                        </div>
                                   </ListItem>
                              </>
                         }
                    />
               </MainDrawer>
               <div className="speakers">
                    <div className="content--border">
                         <div
                              className="container-standard"
                              style={{
                                   display: 'flex',
                                   alignItems: 'center',
                              }}
                         >
                              <div className="nav">
                                   <Link to="/dashboard" className="inactive">
                                        {t('breadcrumb.dashboard')}
                                   </Link>
                                   <OrganizationDropdown organization={organization} inactive/>
                                   <EventDropdown />
                              </div>
                         </div>
                         <LoadingBar
                              finish={
                                   !!speakerDetailsData &&
                                   !!organization &&
                                   !!eventData
                              }
                         />
                    </div>
                    <div
                         className="container-standard"
                         style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                         }}
                    >
                         {organization && eventData && speakerDetailsData && (
                              <Breadcrumb
                                   title={
                                        speakerDetailsData &&
                                        speakerDetailsData.data.Name
                                   }
                                   links={links}
                              />
                         )}
                    </div>
                    <div className="container-standard speaker-details-board">
                         {speakerDetailsData && (
                              <Portlet
                                   title={
                                        speakerDetailsData &&
                                        speakerDetailsData.data.Name
                                   }
                                   content={
                                        <SpeakerForm
                                             speakerPhoto={speakerPhoto}
                                             data={speakerDetailsData}
                                             uploadSpeakerPhotoRequested={uploadSpeakerPhotoRequested}
                                             uploadSpeakerFileRequested={uploadSpeakerFileRequested}
                                             handleValueChange={handleValueChange}
                                             handleMultipleChange={handleMultipleChange}
                                             handleSubmit={handleSubmit}
                                             handleInitialFormValues={handleInitialFormValues}
                                             style={{
                                                  maxWidth: '790px',
                                                  margin: '60px auto',
                                             }}
                                             languageCode={invitationLanguage && invitationLanguage.code}
                                        />
                                   }
                              />
                         )}
                    </div>
               </div>
          </SpeakersWrapper>
     );
});

export const SpeakerDetailsBoard = connect(
     mapStateToProps,
     mapDispatchToProps
)(_SpeakerDetailsBoard);
