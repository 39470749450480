import React from 'react';

import PolicyLinks from '../../../common/policy-links';
import { CURRENT_YEAR } from '../../../../utils/date.utils';

const SubFooter = () => {
     return (
          <div className="bg-theme-darker">
               <div className="container-standard">
                    <div className="subfooter-wrapper">
                         <div className="subfooter-logo">
                              © {CURRENT_YEAR} Avovent
                         </div>
                         <PolicyLinks />
                    </div>
               </div>
          </div>
     );
};

export default SubFooter;
