import React, { memo } from 'react';
import i18n from 'i18next';
import { Card } from 'react-bootstrap';
import { ElementTypes } from '../ElementTypes';
import * as Settings from '../Settings';
import FormBuilderWrapper from '../FormBuilder.style';

export const ElementSettings = memo(
     ({ selectedElement, handleSettingChange, handleRemoveElement, getMasterValue, fieldsList }) => {
          const empty = (
               <Card.Body className="information-box">
                    {i18n.t('form_builder.elements_empty')}
               </Card.Body>
          );
          let context;

          const renderElementSetting = (type) => {
               switch (type) {
                    case ElementTypes.TITLE:
                         context = (
                              <Settings.TitleSettings
                                   selectedElement={selectedElement}
                                   handleSettingChange={handleSettingChange}
                                   handleRemoveElement={handleRemoveElement}
                                   getMasterValue={getMasterValue}
                                   fieldsList={fieldsList}
                              />
                         );
                         break;
                    case ElementTypes.PARAGRAPH:
                         context = (
                              <Settings.ParagraphSettings
                                   selectedElement={selectedElement}
                                   handleSettingChange={handleSettingChange}
                                   handleRemoveElement={handleRemoveElement}
                                   getMasterValue={getMasterValue}
                                   fieldsList={fieldsList}
                              />
                         );
                         break;
                    case ElementTypes.TEXT_FIELD:
                         context = (
                              <Settings.TextFieldSettings
                                   selectedElement={selectedElement}
                                   handleSettingChange={handleSettingChange}
                                   handleRemoveElement={handleRemoveElement}
                                   getMasterValue={getMasterValue}
                                   fieldsList={fieldsList}
                              />
                         );
                         break;
                    case ElementTypes.TEXT_AREA:
                         context = (
                              <Settings.TextAreaSettings
                                   selectedElement={selectedElement}
                                   handleSettingChange={handleSettingChange}
                                   handleRemoveElement={handleRemoveElement}
                                   getMasterValue={getMasterValue}
                                   fieldsList={fieldsList}
                              />
                         );
                         break;
                    case ElementTypes.CHECKBOX:
                         context = (
                              <Settings.CheckboxSettings
                                   selectedElement={selectedElement}
                                   handleSettingChange={handleSettingChange}
                                   handleRemoveElement={handleRemoveElement}
                                   getMasterValue={getMasterValue}
                                   fieldsList={fieldsList}
                              />
                         );
                         break;
                    case ElementTypes.RADIO:
                         context = (
                              <Settings.RadioSelectionSettings
                                   selectedElement={selectedElement}
                                   handleSettingChange={handleSettingChange}
                                   handleRemoveElement={handleRemoveElement}
                                   getMasterValue={getMasterValue}
                                   fieldsList={fieldsList}
                              />
                         );
                         break;
                    case ElementTypes.IMAGE:
                         context = (
                              <Settings.ImageSettings
                                   selectedElement={selectedElement}
                                   handleSettingChange={handleSettingChange}
                                   handleRemoveElement={handleRemoveElement}
                                   getMasterValue={getMasterValue}
                                   fieldsList={fieldsList}
                              />
                         );
                         break;
                    case ElementTypes.FILE_UPLOAD:
                         context = (
                              <Settings.FileUploadSettings
                                   selectedElement={selectedElement}
                                   handleSettingChange={handleSettingChange}
                                   handleRemoveElement={handleRemoveElement}
                                   getMasterValue={getMasterValue}
                                   fieldsList={fieldsList}
                              />
                         );
                         break;
                    case ElementTypes.DATE:
                         context = (
                              <Settings.DateSettings
                                   selectedElement={selectedElement}
                                   handleSettingChange={handleSettingChange}
                                   handleRemoveElement={handleRemoveElement}
                                   getMasterValue={getMasterValue}
                                   fieldsList={fieldsList}
                              />
                         );
                         break;
                    case ElementTypes.TIME:
                         context = (
                              <Settings.TimeSettings
                                   selectedElement={selectedElement}
                                   handleSettingChange={handleSettingChange}
                                   handleRemoveElement={handleRemoveElement}
                                   getMasterValue={getMasterValue}
                                   fieldsList={fieldsList}
                              />
                         );
                         break;
                    case ElementTypes.DROPDOWN:
                         context = (
                              <Settings.DropdownSettings
                                   selectedElement={selectedElement}
                                   handleSettingChange={handleSettingChange}
                                   handleRemoveElement={handleRemoveElement}
                                   getMasterValue={getMasterValue}
                                   fieldsList={fieldsList}
                              />
                         );
                         break;
                    case ElementTypes.DIVIDER:
                         context = (
                              <Settings.DividerSettings
                                   selectedElement={selectedElement}
                                   handleSettingChange={handleSettingChange}
                                   handleRemoveElement={handleRemoveElement}
                                   getMasterValue={getMasterValue}
                                   fieldsList={fieldsList}
                              />
                         );
                         break;
               }

               return context;
          };

          return (
               <FormBuilderWrapper>
                    <div className="element-settings">
                         {selectedElement
                              ? renderElementSetting(selectedElement.type)
                              : empty}
                    </div>
               </FormBuilderWrapper>
          );
     }
);
