import React from 'react';

function IconText() {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="18"
               height="15"
               fill="none"
               viewBox="0 0 18 15"
          >
               <path
                    fill="currentColor"
                    d="M0 7.579h2.842v6.631h2.842V7.58h2.842V4.737H0v2.842z"
               ></path>
               <path
                    fill="currentColor"
                    d="M5.684 0v2.842h4.737V14.21h2.842V2.842H18V0H5.684z"
               ></path>
          </svg>
     );
}

export default IconText;
