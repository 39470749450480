import React from 'react';

export const ReminderIcon = ({ inverse = false, marginRight = '20px' }) => {

    if (inverse) {

        return (
            <svg
                width="19"
                height="21"
                viewBox="0 0 19 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight }}
            >
                 <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <rect x="0" y="0" width="19" height="21" />
                      <path fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.40543 16.915C4.93345 17.319 5.51327 17.6587 6.1332 17.9224L5.15428 19.6179C4.87814 20.0962 4.26655 20.2601 3.78825 19.984C3.30996 19.7078 3.14609 19.0962 3.42223 18.6179L4.40543 16.915ZM12.3989 17.9191C13.0186 17.6548 13.598 17.3145 14.1257 16.9099L15.0943 18.5876C15.3704 19.0659 15.2065 19.6775 14.7283 19.9536C14.25 20.2298 13.6384 20.0659 13.3622 19.5876L12.3989 17.9191Z"
                            fill="#1870F4"
                      />
                      <path
                          opacity="0.3"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.26203 18.5573C4.84375 18.5573 1.26203 14.9755 1.26203 10.5573C1.26203 6.13898 4.84375 2.55726 9.26203 2.55726C13.6803 2.55726 17.262 6.13898 17.262 10.5573C17.262 14.9755 13.6803 18.5573 9.26203 18.5573ZM16.3308 0.811338L18.0802 2.56066C18.666 3.14645 18.666 4.09619 18.0802 4.68198C17.4944 5.26777 16.5446 5.26777 15.9588 4.68198L14.2095 2.93266C13.6237 2.34687 13.6237 1.39712 14.2095 0.811338C14.7953 0.225551 15.7451 0.225551 16.3308 0.811338ZM2.56066 0.43934C3.14645 -0.146447 4.09619 -0.146447 4.68198 0.43934C5.26777 1.02513 5.26777 1.97487 4.68198 2.56066L2.56066 4.68198C1.97487 5.26777 1.02513 5.26777 0.43934 4.68198C-0.146447 4.09619 -0.146447 3.14645 0.43934 2.56066L2.56066 0.43934Z"
                          fill="#1870F4"
                      />
                      <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.22529 5.05737H9.30978C9.56666 5.05737 9.78174 5.25202 9.8073 5.50762L10.2623 10.0574L13.5103 11.9134C13.6661 12.0024 13.7623 12.1681 13.7623 12.3475V12.5574C13.7623 12.7683 13.5913 12.9393 13.3804 12.9393C13.3464 12.9393 13.3126 12.9347 13.2799 12.9258L8.66094 11.6661C8.42956 11.603 8.27557 11.3845 8.29397 11.1454L8.72676 5.51902C8.7468 5.25853 8.96402 5.05737 9.22529 5.05737Z"
                          fill="#1870F4"
                      />
                 </g>
            </svg>
        );
    }

     return (
         <svg
             width="19"
             height="21"
             viewBox="0 0 19 21"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
             style={{ marginRight }}
         >
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                   <rect x="0" y="0" width="19" height="21" />
               <path
                   fillRule="evenodd"
                   clipRule="evenodd"
                   d="M4.40543 16.915C4.93345 17.319 5.51327 17.6587 6.1332 17.9224L5.15428 19.6179C4.87814 20.0962 4.26655 20.2601 3.78825 19.984C3.30996 19.7078 3.14609 19.0962 3.42223 18.6179L4.40543 16.915ZM12.3989 17.9191C13.0186 17.6548 13.598 17.3145 14.1257 16.9099L15.0943 18.5876C15.3704 19.0659 15.2065 19.6775 14.7283 19.9536C14.25 20.2298 13.6384 20.0659 13.3622 19.5876L12.3989 17.9191Z"
                   fill="#B8BFCC"
               />
               <path
                   opacity="0.3"
                   fillRule="evenodd"
                   clipRule="evenodd"
                   d="M9.26203 18.5573C4.84375 18.5573 1.26203 14.9755 1.26203 10.5573C1.26203 6.13898 4.84375 2.55726 9.26203 2.55726C13.6803 2.55726 17.262 6.13898 17.262 10.5573C17.262 14.9755 13.6803 18.5573 9.26203 18.5573ZM16.3308 0.811338L18.0802 2.56066C18.666 3.14645 18.666 4.09619 18.0802 4.68198C17.4944 5.26777 16.5446 5.26777 15.9588 4.68198L14.2095 2.93266C13.6237 2.34687 13.6237 1.39712 14.2095 0.811338C14.7953 0.225551 15.7451 0.225551 16.3308 0.811338ZM2.56066 0.43934C3.14645 -0.146447 4.09619 -0.146447 4.68198 0.43934C5.26777 1.02513 5.26777 1.97487 4.68198 2.56066L2.56066 4.68198C1.97487 5.26777 1.02513 5.26777 0.43934 4.68198C-0.146447 4.09619 -0.146447 3.14645 0.43934 2.56066L2.56066 0.43934Z"
                   fill="#B8BFCC"
               />
              <path
                  fillRule="evenodd"
                  clipRule="evenodd" d="M9.22529 5.05737H9.30978C9.56666 5.05737 9.78174 5.25202 9.8073 5.50762L10.2623 10.0574L13.5103 11.9134C13.6661 12.0024 13.7623 12.1681 13.7623 12.3475V12.5574C13.7623 12.7683 13.5913 12.9393 13.3804 12.9393C13.3464 12.9393 13.3126 12.9347 13.2799 12.9258L8.66094 11.6661C8.42956 11.603 8.27557 11.3845 8.29397 11.1454L8.72676 5.51902C8.7468 5.25853 8.96402 5.05737 9.22529 5.05737Z"
                  fill="#B8BFCC"
              />
              </g>
         </svg>
     );
};
