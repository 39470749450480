import instance, {getToken, getUrl} from './instance';

export const getOrganizationEvents = async (payload) => {
     try {
          if (payload.query) {
               return await instance.post(
                   `${getUrl()}/event/${payload.id}/search`,
                   { query: payload.query },
                   {
                        headers: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                        params: {
                             per_page: payload.per_page,
                             page: payload.page,
                             sort: payload.sort,
                             direction: payload.direction
                        }
                   },
               );
          }

          return await instance.get(
              `${getUrl()}/event/${payload.id}`,
              {
                   headers: {
                        common: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                   },
                   params: {
                        per_page: payload.per_page,
                        page: payload.page,
                        sort: payload.sort,
                        direction: payload.direction
                   }
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const createNewEvent = async (payload) => {
     const { eventPhoto, ...event } = payload;

     const { organization_id } = event;

     try {
          return await instance.post(
              `${getUrl()}/event/${organization_id}`,
              event,
              {
                   headers: {
                        Authorization:
                            `Bearer ${getToken()}` || 'Bearer token',
                   },
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const getEventDetails = async (payload) => {
     const { organizationId, eventId } = payload;
     try {
          return await instance.get(
              `${getUrl()}/event/${organizationId}/${eventId}`,
              {
                   headers: {
                        common: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                   },
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const updateEventDetails = async (payload) => {
     const { eventPhoto, eventId, ...event } = payload;
     const { organization_id } = event;

     try {
          return await instance.post(
              `${getUrl()}/event/${organization_id}/${eventId}`,
              event,
              {
                   headers: {
                        Authorization:
                            `Bearer ${getToken()}` || 'Bearer token',
                   },
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const cancelEvent = async ({ id, eventId }) => {
     try {
          return await instance.delete(
              `${getUrl()}/event/${id}/${eventId}/cancel`,
              {
                   headers: {
                        common: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                   },
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const eventPhotoUpload = async (payload) => {
     const formData = new FormData();
     try {
          formData.append('photo', payload.eventPhoto);
          return await instance.post(
               `${getUrl()}/media/event/${payload.eventId}`,
               formData,
               {
                    headers: {
                         Authorization:
                              `Bearer ${getToken()}` || 'Bearer token',
                         accept: 'image/*',
                    },
                    params: payload.crop
               }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const eventPhotoUploadTemporary = async (payload) => {
     const formData = new FormData();
     try {
          formData.append('photo', payload.photo);
          return await instance.post(
              `${getUrl()}/media/event/temporary`,
              formData,
              {
                   headers: {
                        Authorization:
                            `Bearer ${getToken()}` || 'Bearer token',
                        accept: 'image/*',
                   },
                   params: payload.crop
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};

export const removeEventAvatar = async ({ eventId }) => {
     try {
          return await instance.delete(
              `${getUrl()}/media/event/${eventId}`,
              {
                   headers: {
                        common: {
                             Authorization:
                                 `Bearer ${getToken()}` || 'Bearer token',
                        },
                   },
              }
          );
     } catch (error) {
          if (error.response) {
               return error.response;
          }

          throw Error(error.message);
     }
};
