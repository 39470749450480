import {Fields} from './Fields';
import _ from 'underscore';


export const UpdateChildLanguages = (elements, element, name, value) => {
    if (element.is_master && Fields[element.type] && Fields[element.type].UPDATE_VALUE.includes(name)) {
        return elements.map((el) => {

            if (!el.is_master && el.internal_id === element.internal_id) {

                if (name === 'choices') {
                    const updatedValues = [];

                    value.forEach((val) => {
                        const existingVal = el.choices && el.choices.find((ex) => ex.id === val.id);

                        updatedValues.push({
                            id: val.id,
                            name: val.name,
                            type: val.type,
                            label: existingVal ? existingVal.label : val.label,
                            value: existingVal ? existingVal.value : val.value,
                            preChecked: val.preChecked
                        });
                    });

                    return {
                        ...el,
                        [name]: updatedValues
                    };
                }

                return {
                    ...el,
                    [name]: value,
                };
            }

            return el;
        });
    }

    return elements;
};

export const MasterLanguageValue = (elements, internalId, field, subId) => {
    let result = null;

    elements.forEach((element) => {
        if (element.is_master && element.internal_id === internalId) {

            if (field === 'choices' && element[field]) {
                const subField = element[field].find((e) => e.id === subId);

                if (subField) {
                    result = subField.name === 'dropdown' ? subField.value : subField.label;
                }
            } else {
                result = element[field] ? element[field] : null;
            }
        }
    });

    return result;
};

export const ValidateLanguageFields = (elements, languageId) => {
    let uniqueCodes = [];

    return elements.map((element) => {
        if (element.lang === languageId && Fields[element.type]) {
            let valid = true;
            if (Fields[element.type].VALIDATION) {
                const fieldForValidation = element.is_master
                    ? Fields[element.type].VALIDATION.MASTER
                    : Fields[element.type].VALIDATION.CHILD;

                fieldForValidation.forEach((field) => {
                    if (field === 'choices') {
                        let choicesValid = true;
                        element[field].forEach((choice) => {
                            const value = choice.name === 'dropdown' ? choice.value : choice.label;

                            if (_.isEmpty(value)) {
                                choicesValid = false;
                            }
                        });

                        if (!choicesValid) {
                            valid = false;
                        }
                    } else {
                        if (_.isEmpty(element[field])) {
                            valid = false;
                        }
                    }
                });
            }

            let isTranslated = true;
            let missingTranslations = [];
            if (Fields[element.type].VALIDATE_TRANSLATIONS && !element.is_master) {
                const id = element.id;
                const elementCode = element.elementCode;
                const internalId = element.internal_id;
                const masterElement = elements.find(
                    (el) => (el.id === id || (el.elementCode && el.elementCode === elementCode) || (el.internal_id && el.internal_id === internalId)) && el.is_master === true
                );

                const validateFields = Fields[element.type].VALIDATE_TRANSLATIONS;
                validateFields.forEach(field => {
                    if (masterElement && (_.isEmpty(element[field]) && !_.isEmpty(masterElement[field]))) {
                        isTranslated = false;
                        missingTranslations.push(field);
                    }
                });
            }

            let uniqueCodeValid = true;

            if (Fields[element.type].validateCode) {
                if (uniqueCodes[element.elementCode]) {
                    uniqueCodeValid = false;
                } else {
                    uniqueCodes[element.elementCode] = true;
                }
            }

            element.is_valid_code = uniqueCodeValid;
            element.is_valid = valid;
            element.is_translated = isTranslated;
            element.missing_translations = missingTranslations;
        }

        return element;
    });
};
