import React from 'react';

function PasteIcon() {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="13"
               height="14"
               fill="none"
               viewBox="0 0 13 14"
          >
               <path
                    fill="#8492A5"
                    d="M12.47 9.843a.53.53 0 00.53-.53V6.87c0-.877-.714-1.591-1.592-1.591h-1.034V3.184c0-1.17-.953-2.123-2.123-2.123H6.99A1.537 1.537 0 005.527 0h-.68c-.671 0-1.258.431-1.463 1.061H2.122C.952 1.061 0 2.013 0 3.184v8.277c0 1.17.952 2.123 2.122 2.123h2.335a.53.53 0 000-1.062H2.122a1.062 1.062 0 01-1.06-1.06V3.183c0-.585.475-1.062 1.06-1.062h.054v1.566c0 .293.237.53.53.53H7.72a.53.53 0 00.531-.53V2.122c.585 0 1.061.477 1.061 1.062V5.28H7.588c-.878 0-1.592.714-1.592 1.591v5.121c0 .878.714 1.592 1.592 1.592l3.82-.002c.878 0 1.592-.714 1.592-1.592a.53.53 0 10-1.061 0c0 .293-.238.53-.531.53l-3.82.002a.531.531 0 01-.53-.53v-5.12c0-.293.237-.531.53-.531h3.82c.293 0 .53.238.53.53v2.441c0 .293.238.53.531.53zM7.19 3.157H3.237V2.122h.557a.53.53 0 00.515-.401l.074-.297a.478.478 0 01.464-.363h.68c.22 0 .41.15.464.363l.074.297a.53.53 0 00.515.401h.61v1.035z"
               ></path>
               <path
                    fill="#8492A5"
                    d="M10.347 7.402H8.649a.53.53 0 100 1.061h1.698a.53.53 0 100-1.06zM10.347 9.26H8.649a.53.53 0 100 1.06h1.698a.53.53 0 100-1.06z"
               ></path>
          </svg>
     );
}

export default PasteIcon;
