import React from 'react';
import { useTranslation } from 'react-i18next';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

import moment from 'moment';
import IconCalendar from "../../../assets/icons/IconCalendar";
import {Form} from "react-bootstrap";

export const RangePicker = ({ field, value, startDate, endDate, handleEvent, handleApply, minDate, touched, errors }) => {
     const { t } = useTranslation();

     const formatValue = (value) => {
          if (!value.includes('Invalid date')) {
               return value;
          }

          return '';
     };

     return (
          <DateRangePicker
               minDate={minDate || moment()}
               startDate={startDate || moment()}
               endDate={endDate}
               containerClass="picker"
               parentEl={document.getElementById('root')}
               containerStyles={{ width: '100%' }}
               styles={{ td: { color: 'red' } }}
               onEvent={handleEvent}
               onApply={handleApply}
               locale={{ applyLabel: t('buttons.apply'), cancelLabel: t('buttons.cancel')} }
          >
               <div className="input-group">
                    <Form.Control
                        isInvalid={touched[`${field}`] && !!errors[`${field}`]}
                        type="text"
                        className="form-control"
                        placeholder={t('date_range_picker.select_date_range')}
                        readOnly
                        value={formatValue(value)}
                        name={field}
                        style={{ zIndex: 0 }}
                    />
                    {!(touched[`${field}`] && !!errors[`${field}`]) && (
                        <span className="suffix">
                              <IconCalendar />
                         </span>
                    )}
                    <Form.Control.Feedback
                        type="invalid"
                        style={{ marginTop: '2px' }}
                    >
                         {errors[`${field}`]}
                    </Form.Control.Feedback>
               </div>
          </DateRangePicker>
     );
};
